import { Component, Input } from '@angular/core';
import { PaxOverviewType } from 'src/app/crew-management-user/enums/pax-overview-type.enum';

@Component({
  selector: 'app-pax-template',
  templateUrl: './pax-template.component.html',
})
export class PaxTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: () => void };
  paxOverviewType = PaxOverviewType;
  dateFormat = 'yyyy-MM-dd';
  dateTimeFormat = 'yyyy-MM-dd HH:mm';

  trackBy = (index: number) => index;
}
