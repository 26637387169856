import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdName } from 'src/app/shared/models/id-name';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ModuleApiService {
  private get baseUrl(): string {
    return `${environment.apiUrl}/api/Options/ModuleOptionTypes`;
  }

  constructor(private http: HttpClient) {}

  getModules(): Observable<IdName[]> {
    return this.http.get<IdName[]>(this.baseUrl);
  }
}
