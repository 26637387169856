import { UpsertCustomFormDefinitionModel } from 'src/app/custom-forms/models/upsert-custom-form-definition.model';

export interface ICustomFormDefinitionModel {
  id: string;
  definition: string;
}

export function map(model: UpsertCustomFormDefinitionModel): ICustomFormDefinitionModel {
  return <ICustomFormDefinitionModel>{
    id: model.formId,
    definition: model.definition,
  };
}
