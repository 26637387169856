import { getTenantId } from 'src/app/services/store-snapshot.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAccess, IAccessContext, IRole } from '../models/role.model';
import { environment } from '../../../environments/environment';

const headers = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClient) {}

  getAllRoles(): Observable<IRole[]> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/roles`;
    return this.http.get<IRole[]>(url);
  }

  getAccesses(): Observable<IAccess[]> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/roles`;
    const endpoint = `${url}/Accesses`;
    return this.http.get<IAccess[]>(endpoint);
  }

  getAccessContexts(): Observable<IAccessContext[]> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/roles`;
    const endpoint = `${url}/Accesses/Structured`;
    return this.http.get<IAccessContext[]>(endpoint);
  }

  createRole(role: IRole): Observable<IRole> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/roles`;
    return this.http.post<IRole>(url, role, { headers });
  }

  updateRole(update: IRole): Observable<IRole> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/roles/${update.id}`;
    return this.http.put<IRole>(url, update, { headers });
  }

  getRoleById(id: string): Observable<IRole> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/roles/${id}`;
    return this.http.get<IRole>(url);
  }

  deleteRole(id: string): Observable<void> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/roles/${id}`;
    return this.http.delete<void>(url);
  }
}
