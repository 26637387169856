import { MatDateFormats } from '@angular/material/core';
import { InjectionToken } from '@angular/core';

export const CCOMX_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'yyyy-MM-DD',
  },
  display: {
    dateInput: 'yyyy-MM-DD',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export const DATE_WITH_TIME_DISPLAY = {
  ...CCOMX_DATE_FORMATS,
  parse: {
    dateInput: 'yyyy-MM-DD HH:mm',
  },
  display: {
    ...CCOMX_DATE_FORMATS.display,
    dateInput: 'yyyy-MM-DD HH:mm',
  },
};

export const USE_DATE_TIME_FORMAT = new InjectionToken<boolean>('Use date-time format token');
