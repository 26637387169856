<mat-form-field class="example-chip-list" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipList attr.aria-label="{{ label }}" [disabled]="disabled">
    <mat-chip-row *ngFor="let item of selectedItems" [value]="item" (removed)="remove(item)">
      {{ item }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      placeholder="{{ 'newItemPlaceholder' | translate }}"
      #inputField
      [formControl]="inputControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addKeywordFromInput($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item.name">
      {{ item.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
