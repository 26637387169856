/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IProductCategory } from '../models/product-category.model';

export class LoadProductCategories {
  static readonly type = '[Product Category] Load Product Categories via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadProductCategoryById {
  static readonly type = '[Product Category] Load a Product Category By Id';
  constructor(public id: string) {}
}

export class CreateProductCategory {
  static readonly type = '[Product Category] Create Product Category';
  constructor(public productCategory: IProductCategory) {}
}

export class UpdateProductCategory {
  static readonly type = '[Product Category] Update Product Category';
  constructor(public productCategory: IProductCategory) {}
}

export class DeleteProductCategory {
  static readonly type = '[Product Category] Delete Product Category';
  constructor(public id: string) {}
}
