import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { AdminAlertRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from '../../shared/models/id-name';
import { IAlertResponse } from '../models/alert.model';
import { AlertApiService } from '../services/alert-api.service';
import { CreateAlert, DeleteAlert, LoadAlerts, LoadAllAlerts, UpdateAlert } from './alert.actions';

export interface AlertStateModel {
  alerts: IAlertResponse[];
  alertsTotalPages: number;
  allAlerts: IdName[];
}

const ALERT_STATE_TOKEN = new StateToken<AlertStateModel>('alerts');

@State<AlertStateModel>({
  name: ALERT_STATE_TOKEN,
  defaults: {
    alerts: [],
    alertsTotalPages: 0,
    allAlerts: [],
  },
})
@Injectable()
export class AlertState {
  constructor(private service: AlertApiService) {}

  @Action(LoadAlerts, { cancelUncompleted: true })
  loadAlerts({ getState, setState }: StateContext<AlertStateModel>, { filter }: LoadAlerts) {
    return this.service.getAlerts(filter).pipe(
      tap((response: ApiResponse<IAlertResponse[]>) => {
        const state = getState();
        setState({
          ...state,
          alerts: response.data,
          alertsTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllAlerts, { cancelUncompleted: true })
  loadAllAlerts(ctx: StateContext<AlertStateModel>) {
    return this.service.getAllAlerts().pipe(
      tap((alerts: IdName[]) => {
        ctx.setState(patch({ allAlerts: alerts }));
      })
    );
  }

  @Action(CreateAlert, { cancelUncompleted: true })
  @OnSuccess({ url: AdminAlertRoutes.Main, message: 'alertCreatedSuccessfully' })
  createAlert(ctx: StateContext<AlertStateModel>, { alert }: CreateAlert) {
    return this.service.createAlert(alert);
  }

  @Action(UpdateAlert, { cancelUncompleted: true })
  @OnSuccess({ url: AdminAlertRoutes.Main, message: 'alertUpdatedSuccessfully' })
  updateAlert(ctx: StateContext<AlertStateModel>, { alert }: UpdateAlert) {
    return this.service.updateAlert(alert);
  }

  @Action(DeleteAlert, { cancelUncompleted: true })
  @OnSuccess({ url: AdminAlertRoutes.Main, message: 'alertDeletedSuccessfully' })
  deleteAlert(ctx: StateContext<AlertStateModel>, { alertId }: DeleteAlert) {
    return this.service.deleteAlert(alertId);
  }

  @Selector()
  static getAlerts(state: AlertStateModel): IAlertResponse[] {
    return state.alerts;
  }

  @Selector()
  static getAllAlerts(state: AlertStateModel): IdName[] {
    return state.allAlerts;
  }

  @Selector()
  static getAlertsTotalPages(state: AlertStateModel): number {
    return state.alertsTotalPages;
  }

  @Selector()
  static getAlertById(state: any) {
    return (id: string) => {
      return state.alerts.find((alert: IAlertResponse) => alert.id === id);
    };
  }

  static getAlertsByModule(module: string) {
    return createSelector([AlertState], (state: AlertStateModel) => {
      return state.alerts.filter((a) =>
        a.moduleNames.map((n) => n.toLocaleLowerCase()).includes(module.toLocaleLowerCase())
      );
    });
  }
}
