import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { AdminMedicineRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { getDepartmentId } from 'src/app/services/store-snapshot.service';
import { IMedicineCategory } from '../models/medicine-category.model';
import { MedicineCategoryApiService } from '../services/medicine-category-api.service';
import {
  CreateMedicineCategory,
  DeleteMedicineCategory,
  LoadDepartmentMedicineCategories,
  LoadMedicineCategories,
  LoadMedicineCategoryById,
  UpdateMedicineCategory,
} from './medicine-category.actions';

export interface MedicineCategoryStateModel {
  medicineCategories: IMedicineCategory[];
  medicineCategoriesTotalPages: number;
}

const MEDICINE_CATEGORY_STATE_TOKEN = new StateToken<MedicineCategoryStateModel>(
  'medicineCategory'
);

@State<MedicineCategoryStateModel>({
  name: MEDICINE_CATEGORY_STATE_TOKEN,
  defaults: {
    medicineCategories: [],
    medicineCategoriesTotalPages: 0,
  },
})
@Injectable()
export class MedicineCategoryState {
  constructor(private service: MedicineCategoryApiService) {}

  @Action(LoadMedicineCategories)
  loadMedicineCategories(
    { getState, setState }: StateContext<MedicineCategoryStateModel>,
    { filter }: LoadMedicineCategories
  ) {
    return this.service.loadMedicineCategories(filter).pipe(
      tap((response: ApiResponse<IMedicineCategory[]>) => {
        const state = getState();
        setState({
          ...state,
          medicineCategories: response.data,
          medicineCategoriesTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadDepartmentMedicineCategories)
  loadDepartmentMedicineCategories({
    getState,
    setState,
  }: StateContext<MedicineCategoryStateModel>) {
    return this.service.loadDepartmentMedicineCategories().pipe(
      tap((response: IMedicineCategory[]) => {
        const state = getState();
        setState({
          ...state,
          medicineCategories: response,
          medicineCategoriesTotalPages: 1,
        });
      })
    );
  }

  @Action(LoadMedicineCategoryById, { cancelUncompleted: true })
  loadMedicineCategoryById(
    { getState, setState }: StateContext<MedicineCategoryStateModel>,
    { id }: LoadMedicineCategoryById
  ) {
    const { medicineCategories } = getState();
    const exist = medicineCategories.some((i) => i.id === id);
    return this.service.loadMedicineCategoryById(id).pipe(
      tap((medicineCategory: IMedicineCategory) => {
        setState(
          patch({
            medicineCategories: exist
              ? updateItem<IMedicineCategory>((toUpdate) => toUpdate?.id === id, medicineCategory)
              : insertItem<IMedicineCategory>(medicineCategory),
          })
        );
      })
    );
  }

  @Action(CreateMedicineCategory)
  @OnSuccess({ url: AdminMedicineRoutes.Categories, message: 'medicineCategoryAdded' })
  createMedicineCategory(
    { setState }: StateContext<MedicineCategoryStateModel>,
    { medicineCategory }: CreateMedicineCategory
  ) {
    return this.service.createMedicineCategory(medicineCategory).pipe(
      tap((response: IMedicineCategory) => {
        setState(
          patch({
            medicineCategories: insertItem<IMedicineCategory>(response),
          })
        );
      })
    );
  }

  @Action(UpdateMedicineCategory)
  @OnSuccess({ url: AdminMedicineRoutes.Categories, message: 'medicineCategoryUpdated' })
  updateMedicineCategory(
    { setState }: StateContext<MedicineCategoryStateModel>,
    { medicineCategory }: UpdateMedicineCategory
  ) {
    return this.service.updateMedicineCategory(medicineCategory).pipe(
      tap(() => {
        setState(
          patch({
            medicineCategories: updateItem<IMedicineCategory>(
              (toUpdate) => toUpdate?.id === medicineCategory.id,
              medicineCategory
            ),
          })
        );
      })
    );
  }

  @Action(DeleteMedicineCategory, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMedicineRoutes.Categories, message: 'medicineCategoryDeleted' })
  deleteMedicineCategory(
    ctx: StateContext<MedicineCategoryStateModel>,
    { id }: DeleteMedicineCategory
  ) {
    return this.service.deleteMedicineCategory(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            medicineCategories: removeItem<IMedicineCategory>(
              (toDelete: IMedicineCategory | undefined) => toDelete?.id === id
            ),
          })
        );
      })
    );
  }

  @Selector()
  static getMedicineCategoriesTotalPages(state: MedicineCategoryStateModel) {
    return state.medicineCategoriesTotalPages;
  }

  @Selector()
  static medicineCategories(state: MedicineCategoryStateModel) {
    return state.medicineCategories;
  }

  @Selector()
  static medicineCategoriesForCurrentDepartment(state: MedicineCategoryStateModel) {
    return state.medicineCategories.filter((medicineCategory) =>
      medicineCategory.departments.find((department) => department.id === getDepartmentId())
    );
  }

  @Selector()
  static getMedicineCategoryById(state: MedicineCategoryStateModel) {
    return (id: string) => {
      return state.medicineCategories.find(
        (medicineCategory: IMedicineCategory) => medicineCategory.id === id
      );
    };
  }
}
