import { ModuleName } from 'src/app/shared/enums/enums';
import { ModuleNumbers } from './module-numbers.enum';

export enum RoutesPartsEnum {
  New = 'new',
}

export enum RoutesEnum {
  DueList = 'due-list',
  Messages = 'messages',
  ShiftAndManning = 'shift-and-manning',
  Certificate = 'certificates',
  Documents = 'documents',
  RiskAssessments = 'risk-assessments',
  Audits = 'audits',
  NonConformance = 'non-conformances',
  Purchase = 'purchases',
  RunTime = 'run-time',
  Maintenance = 'maintenance',
  CrewManagement = 'crew-management',
  Emissions = 'emissions',
  Storage = 'storage',
  Medicines = 'medicines',
  Drills = 'drills',
  Checklists = 'tasks',
  JobPosition = 'job-positions',
  Responses = 'responses',
  Profile = 'profile',
  Users = 'users',
}

export interface IRouteMetadata {
  number: ModuleNumbers | null;
  name: ModuleName | RoutesEnum | null;
}

export const RouteMetadata: Record<string, IRouteMetadata> = {
  [RoutesEnum.DueList]: {
    number: null,
    name: ModuleName.DUE_LIST,
  },
  [RoutesEnum.Messages]: {
    number: null,
    name: ModuleName.MESSAGES,
  },
  [RoutesEnum.ShiftAndManning]: {
    number: ModuleNumbers.CrewManagement,
    name: ModuleName.CREWMANAGEMENT,
  },
  [RoutesEnum.Certificate]: {
    number: ModuleNumbers.Certificate,
    name: ModuleName.CERTIFICATE,
  },
  [RoutesEnum.Documents]: {
    number: ModuleNumbers.Document,
    name: ModuleName.DOCUMENT,
  },
  [RoutesEnum.RiskAssessments]: {
    number: ModuleNumbers.RiskAssessment,
    name: ModuleName.RISKASSESSMENT,
  },
  [RoutesEnum.Audits]: {
    number: ModuleNumbers.Audit,
    name: ModuleName.AUDIT,
  },
  [RoutesEnum.NonConformance]: {
    number: ModuleNumbers.NonConformance,
    name: ModuleName.NONCONFORMANCE,
  },
  [RoutesEnum.Purchase]: {
    number: ModuleNumbers.Order,
    name: ModuleName.ORDER,
  },
  [RoutesEnum.RunTime]: {
    number: null,
    name: null,
  },
  [RoutesEnum.Maintenance]: {
    number: ModuleNumbers.Maintenance,
    name: ModuleName.MAINTENANCE,
  },
  [RoutesEnum.CrewManagement]: {
    number: ModuleNumbers.CrewManagement,
    name: ModuleName.CREWMANAGEMENT,
  },
  [RoutesEnum.Emissions]: {
    number: null,
    name: null,
  },
  [RoutesEnum.Storage]: {
    number: ModuleNumbers.Storage,
    name: ModuleName.STORAGE,
  },
  [RoutesEnum.Medicines]: {
    number: ModuleNumbers.Medicine,
    name: ModuleName.MEDICINE,
  },
  [RoutesEnum.Drills]: {
    number: ModuleNumbers.Drill,
    name: ModuleName.DRILL,
  },
  [RoutesEnum.Checklists]: {
    number: ModuleNumbers.Task,
    name: ModuleName.TASK,
  },
  [RoutesEnum.JobPosition]: {
    number: ModuleNumbers.Competence,
    name: ModuleName.COMPETENCE,
  },
  [RoutesEnum.Responses]: {
    number: null,
    name: RoutesEnum.Responses,
  },
  [RoutesEnum.Profile]: {
    number: null,
    name: ModuleName.PROFILE,
  },
  [RoutesEnum.Users]: {
    number: null,
    name: RoutesEnum.Users,
  },
};

export enum AdminRoutesEnum {
  Admin = 'admin',
  Alerts = 'alerts',
  Audit = 'audit',
  Certificate = 'certificate',
  CrewManagement = 'crew-management',
  Competences = 'competence',
  Department = 'department',
  Document = 'document',
  Drills = 'drills',
  Tasks = 'tasks',
  Checklists = 'tasks',
  Forms = 'forms-library',
  Maintenance = 'maintenance',
  Medicine = 'medicine',
  NonConformance = 'non-conformance',
  Purchase = 'purchase',
  ProductOwner = 'product-owner',
  QuantityTypes = 'quantity-types',
  ProductOwnerUsers = 'po-users',
  RiskAssessment = 'risk-assessment',
  Role = 'role',
  Storage = 'storage',
  Tenant = 'tenant',
  Users = 'users-and-pax',
  WorkAndRest = 'work-and-rest',
}

export const AdminRouteMetadata: Record<string, IRouteMetadata> = {
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Alerts}`]: {
    number: null,
    name: ModuleName.ALERT,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Audit}`]: {
    number: ModuleNumbers.Audit,
    name: ModuleName.AUDIT,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Certificate}`]: {
    number: ModuleNumbers.Certificate,
    name: ModuleName.CERTIFICATE,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.CrewManagement}`]: {
    number: ModuleNumbers.CrewManagement,
    name: ModuleName.CREWMANAGEMENT,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Competences}`]: {
    number: ModuleNumbers.Competence,
    name: ModuleName.COMPETENCE,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Department}`]: {
    number: null,
    name: ModuleName.DEPARTMENT,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Document}`]: {
    number: ModuleNumbers.Document,
    name: ModuleName.DOCUMENT,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Drills}`]: {
    number: ModuleNumbers.Drill,
    name: ModuleName.DRILL,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Tasks}`]: {
    number: ModuleNumbers.Task,
    name: ModuleName.TASK,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Checklists}`]: {
    number: ModuleNumbers.Task,
    name: ModuleName.TASK,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Forms}`]: {
    number: null,
    name: ModuleName.FORMS,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Maintenance}`]: {
    number: ModuleNumbers.Maintenance,
    name: ModuleName.MAINTENANCE,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Medicine}`]: {
    number: ModuleNumbers.Medicine,
    name: ModuleName.MEDICINE,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.NonConformance}`]: {
    number: ModuleNumbers.NonConformance,
    name: ModuleName.NONCONFORMANCE,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Purchase}`]: {
    number: ModuleNumbers.Order,
    name: ModuleName.ORDER,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.ProductOwner}`]: {
    number: null,
    name: ModuleName.PRODUCTOWNER,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.QuantityTypes}`]: {
    number: null,
    name: ModuleName.QUANTITYTYPES,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.ProductOwnerUsers}`]: {
    number: null,
    name: ModuleName.PRODUCTOWNERUSER,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.RiskAssessment}`]: {
    number: ModuleNumbers.RiskAssessment,
    name: ModuleName.RISKASSESSMENT,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Role}`]: {
    number: null,
    name: ModuleName.ROLE,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Storage}`]: {
    number: ModuleNumbers.Storage,
    name: ModuleName.STORAGE,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Tenant}`]: {
    number: null,
    name: ModuleName.TENANT,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Users}`]: {
    number: null,
    name: ModuleName.USER,
  },
  [`${AdminRoutesEnum.Admin}/${AdminRoutesEnum.WorkAndRest}`]: {
    number: null,
    name: ModuleName.WORKANDREST,
  },
};

export enum SupportRoutes {
  LoginFailed = 'login-failed',
  GlobalSearchResult = 'global-search-result/:searchTerm',
  Help = 'help',
}

// USER SUB MODULES
// ________________________________________________________________

export enum UserCertificateRoutes {
  Main = '/certificates',
  MainActive = '/certificates/active',
  MainDeleted = '/certificates/deleted',
  New = '/certificates/new',
  Renew = '/certificates/renew/',
  Overview = '/certificates/overview',
}

export enum UserDocumentsRoutes {
  Main = '/documents',
  Overview = '/documents/overview',
  NewDocument = '/documents/add',
}

export const shiftAndManningOutletName = 'shiftAndManning';
export enum UserCrewManagementOutletRoutes {
  CurrentPersonnel = 'current-personnel',
  PlannedActivities = 'planned-activities',
  PaxOverview = 'pax-overview',
  Trips = 'trips',
}

export const UserCrewManagementRoutes = {
  Main: '/shift-and-manning/dashboard',
  CurrentPersonnel: `/shift-and-manning/dashboard/(${shiftAndManningOutletName}:${UserCrewManagementOutletRoutes.CurrentPersonnel})`,
  PaxOverview: `/shift-and-manning/dashboard/(${shiftAndManningOutletName}:${UserCrewManagementOutletRoutes.PaxOverview})`,
  PlannedActivities: `/shift-and-manning/dashboard/(${shiftAndManningOutletName}:${UserCrewManagementOutletRoutes.PlannedActivities})`,
  TripsDashboard: `/shift-and-manning/dashboard/(${shiftAndManningOutletName}:${UserCrewManagementOutletRoutes.Trips})`,
  PlannedActivitiesNew: '/shift-and-manning/planned-activities/new',
  Trips: `/shift-and-manning/trips`,
};

export enum UserNonConformancesRoutes {
  Main = '/non-conformances',
  Open = '/non-conformances/open',
  New = '/non-conformances/new',
  EditReport = '/non-conformances/:id/edit-report',
  EditDetails = '/non-conformances/:id/edit-details',
  ViewReport = '/non-conformances/:id/view-report/:rid',
  ShowOverview = '/non-conformances/:id/overview',
}

export enum FormsResponsesRoutes {
  List = '/responses/form-responses/:id',
  Fill = '/responses/form-responses/fill/',
}

export enum UserMaintenanceRoutes {
  Main = '/maintenance',
  Component = '/maintenance/component',
  JobAdd = '/maintenance/component/:componentId/jobs/add',
  JobEdit = '/maintenance/component/:componentId/jobs/:id',
  JobPerform = '/maintenance/component/:componentId/jobs/:id/perform',
  JobPerformDetails = '/maintenance/component/:componentId/jobs/:id/perform/details/:performId',
  ServiceReportEdit = '/maintenance/component/:componentId/service-report/:srId',
  ServiceReportNew = '/maintenance/component/:componentId/service-report/new',
  CorrectiveActionEdit = '/maintenance/component/:componentId/corrective-action/:caId',
  CorrectiveActionNew = '/maintenance/component/:componentId/corrective-action/new',
}

export enum UserRunTimeRoutes {
  Main = '/run-time',
  Add = '/run-time/add',
  Edit = '/run-time/edit',
}

export enum UserProfileRoutes {
  CompetenceUserNew = '/profile/competence/new/',
  CompetenceUser = '/profile/competence/user/',
  CompetenceUserRegister = '/profile/competence/register/',
  CompetenceUserRenew = '/profile/competence/renew/',
}

export enum UserDrillsRoutes {
  Main = '/drills',
  Overview = '/drills/overview',
  Add = '/drills/add',
  Edit = '/drills/edit',
  Perform = '/drills/perform',
  PerformView = '/drills/perform/view',
}

export enum UserTasksRoutes {
  Main = '/tasks',
  Overview = '/tasks/overview',
  Add = '/tasks/add',
  Edit = '/tasks/edit',
  Perform = '/tasks/perform',
  PerformView = '/tasks/perform/view',
}

export enum UserOrderRoutes {
  Main = '/purchases/',
  Orders = '/purchases/drafts',
  OrdersNew = '/purchases/drafts/new',
  Dashboard = '/purchases/dashboard',
  Draft = '/purchases/draft',
  ForApproval = '/purchases/forApproval',
  Approved = '/purchases/approved',
  SentToSupplier = '/purchases/sentToSupplier',
  ReceivedPartially = '/purchases/receivedPartially',
  Received = '/purchases/received',
  Cancelled = '/purchases/cancelled',
}

export enum UserMedicineRoutes {
  Main = '/medicines',
  New = '/medicines/new',
  Edit = '/medicines/edit',
  List = '/medicines/list',
  Overview = '/medicines/overview',
  ViewStock = '/medicines/overview/:id/stock/:vid',
}

export enum UserStorageRoutes {
  Main = '/storage',
  New = '/storage/new',
  Edit = '/storage/edit',
  List = '/storage/list',
  Overview = '/storage/overview',
  ViewStock = '/storage/overview/:id/stock/:vid',
}

export enum UserRiskAssessmentRoutes {
  Main = '/risk-assessments',
  New = '/risk-assessments/new',
  List = '/risk-assessments/list',
  Overview = '/risk-assessments/overview',
  ViewPerform = '/risk-assessments/overview/:id/version/:versionId',
  Template = '/risk-assessments/template',
}

// ADMIN SUB MODULES
// ________________________________________________________________

export enum AdminDepartmentRoutes {
  Main = '/admin/department/main',
  MainEdit = '/admin/department/main/edit',
  Groups = '/admin/department/groups',
  MainNew = '/admin/department/new',
  GroupsNew = '/admin/department/groups/new',
}

export enum AdminAlertRoutes {
  Main = '/admin/alerts',
  New = '/admin/alerts/new',
  Edit = '/admin/alerts/',
}

export enum AdminUsersRoutes {
  Users = '/admin/users-and-pax/users',
  UsersNew = '/admin/users-and-pax/users/new',
  AllUsersAllTenants = '/admin/po-users',
  NewEmployment = '/admin/po-users/new-employment',
  Paxes = '/admin/users-and-pax/pax',
  PaxesNew = '/admin/users-and-pax/pax/new',
  CompetenceUserNew = '/admin/users-and-pax/competence/new/',
  CompetenceUser = '/admin/users-and-pax/competence/user/',
  CompetenceUserRegister = '/admin/users-and-pax/competence/register/',
  CompetenceUserRenew = '/admin/users-and-pax/competence/renew/',
}

export enum AdminCompetenceRoutes {
  Main = '/admin/competence/main',
  MainEdit = '/admin/competence/main/',
  Groups = '/admin/competence/groups',
  JobPositions = '/admin/competence/job-positions',
  JobPositionsNew = '/admin/competence/job-positions/new',
  JobPositionGroups = '/admin/competence/job-position-groups',
  JobPositionGroupsNew = '/admin/competence/job-position-groups/new',
  JobPositionGroupsEdit = '/admin/competence/job-position-groups/',
  Requirements = '/admin/competence/requirements/',
  RequirementsNew = '/admin/competence/requirements/new',
  PrivilegeTemplates = '/admin/competence/privilege-templates',
  PrivilegeTemplatesNew = '/admin/competence/privilege-templates/add',
  PrivilegeTemplatesEdit = '/admin/competence/privilege-templates/edit',
  Matrix = '/admin/competence/matrix',
}

export enum AdminCertificateRoutes {
  Structure = 'admin/certificate/structure',
  CertificateTypes = '/admin/certificate/certificate-types',
  CertificateTypeNew = '/admin/certificate/certificate-types/add',
  CertificateTypeEdit = '/admin/certificate/certificate-types/edit',
}

export enum AdminNonConformanceRoutes {
  Types = '/admin/non-conformance/types',
  TypesAdd = '/admin/non-conformance/types/add',
  TypesEdit = '/admin/non-conformance/types/edit/',
  SeverityTypes = '/admin/non-conformance/severity-types',
  SeverityTypesAdd = '/admin/non-conformance/severity-types/add',
  SeverityTypesEdit = '/admin/non-conformance/severity-types/edit/',
  SeaState = '/admin/non-conformance/sea-states',
  SeaStateNew = '/admin/non-conformance/sea-states/add',
  SeaStateEdit = '/admin/non-conformance/sea-states/edit',
  WeatherInformation = '/admin/non-conformance/weather-information',
  WeatherInformationNew = '/admin/non-conformance/weather-information/add',
  WeatherInformationEdit = '/admin/non-conformance/weather-information/edit',
  LocationOnDepartment = '/admin/non-conformance/location-on-department',
  LocationOnDepartmentNew = '/admin/non-conformance/location-on-department/add',
  LocationOnDepartmentEdit = '/admin/non-conformance/location-on-department/edit',
  Cause = '/admin/non-conformance/causes',
  CauseNew = '/admin/non-conformance/causes/add',
  CauseEdit = '/admin/non-conformance/causes/edit',
}

export enum AdminFormsRoutes {
  Main = '/admin/forms-library',
  New = '/admin/forms-library/new',
  Form = '/admin/forms-library/:id',
  FormId = '/admin/forms-library/form/:id',
  Details = '/admin/forms-library/form/details',
  DetailsId = '/admin/forms-library/form/details/:id',
}

export enum AdminTenantRoutes {
  Main = '/admin/tenant',
  Add = '/admin/tenant/new',
  Edit = '/admin/tenant/',
}

export enum AdminQuantityTypesRoutes {
  Main = '/admin/quantity-types',
  Add = '/admin/quantity-types/new',
  Edit = '/admin/quantity-types/edit/',
}

export enum AdminProductOwnerRoutes {
  Main = '/admin/product-owner',
  New = '/admin/product-owner/new',
  Edit = '/admin/product-owner/',
}

export enum AdminRoleRoutes {
  Main = '/admin/role',
  New = '/admin/role/new',
  Edit = '/admin/role/',
}

export enum AdminMaintenanceRoutes {
  Main = '/admin/maintenance',
  JobTypes = '/admin/maintenance/job-types',
  JobTypesNew = '/admin/maintenance/job-types/new',
  JobTypesEdit = '/admin/maintenance/job-types/edit',
  Components = '/admin/maintenance/components',
  ComponentDetail = '/admin/maintenance/components/component-detail',
  ComponentGroupDetail = '/admin/maintenance/components/component-group-detail',
}

export enum AdminCrewManagementRoutes {
  Main = '/admin/crew-management',
  Activities = '/admin/crew-management/activities',
  ActivitiesNew = '/admin/crew-management/activities/new',
  Shifts = '/admin/crew-management/shifts',
  ShiftsNew = '/admin/crew-management/shifts/new',
}

export enum AdminDocumentsRoutes {
  Main = '/admin/document',
  Structure = '/admin/document/structure',
  NewDocument = '/admin/document/add',
  Stages = '/admin/document/stages',
  Status = '/admin/document/status',
  ReadConfirmation = '/admin/document/read-confirmation',
  Settings = '/admin/document/settings',
  DocumentDraft = '/admin/document/draft/',
  DocumentForApproval = '/admin/document/forApproval/',
  DocumentStageDraft = '/admin/document/stages/draft/',
  DocumentStageForApproval = '/admin/document/stages/forApproval/',
}

export enum AdminDrillsRoutes {
  Main = '/admin/drills',
}

export enum AdminTasksRoutes {
  Main = '/admin/tasks',
}

export enum AdminMedicineRoutes {
  Main = '/admin/medicine',
  Structure = '/admin/medicine/structure',
  Categories = '/admin/medicine/categories',
  CategoriesNew = '/admin/medicine/categories/new',
  CategoriesEdit = '/admin/medicine/categories/:id',
}

export enum AdminStorageRoutes {
  Main = '/admin/storage',
  Structure = '/admin/storage/structure',
  Categories = '/admin/storage/categories',
  CategoriesNew = '/admin/storage/categories/new',
  ProductTypes = '/admin/storage/product-types',
  ProductTypesNew = '/admin/storage/product-types/new',
}

export enum AdminWorkAndRestRoutes {
  Main = '/admin/work-and-rest',
  WorkSchedule = '/admin/work-and-rest/work-schedule',
  WorkPlan = '/admin/work-and-rest/work-plan',
  OvertimeTypes = '/admin/work-and-rest/overtime-types',
  OvertimeTypesNew = '/admin/work-and-rest/overtime-types/new',
  OvertimeTypesEdit = '/admin/work-and-rest/overtime-types/:id',
}

export enum AdminOrderRoutes {
  Main = '/admin/purchase',
  Accounts = '/admin/purchase/accounts',
  AccountsNew = '/admin/purchase/accounts/new',
  AccountsEdit = '/admin/purchase/accounts/:id',
  AccountGroups = '/admin/purchase/account-groups',
  AccountGroupsNew = '/admin/purchase/account-groups/new',
  AccountGroupsEdit = '/admin/purchase/account-groups/:id',
  DeliveryConditions = '/admin/purchase/delivery-conditions',
  DeliveryConditionsNew = '/admin/purchase/delivery-conditions/new',
  DeliveryConditionsEdit = '/admin/purchase/delivery-conditions/:id',
  OrderTypes = '/admin/purchase/order-types',
  OrderTypesNew = '/admin/purchase/order-types/new',
  Suppliers = '/admin/purchase/suppliers',
  SuppliersNew = '/admin/purchase/suppliers/new',
  Purchasers = '/admin/purchase/purchasers',
  PurchasersNew = '/admin/purchase/purchasers/new',
  Settings = '/admin/purchase/settings',
}

export enum AdminRiskAssessmentRoutes {
  Main = '/admin/risk-assessment',
  Structure = '/admin/risk-assessment/structure',
  Hazards = '/admin/risk-assessment/hazards',
  Consequences = '/admin/risk-assessment/consequences',
  Locations = '/admin/risk-assessment/locations',
  MitigatingMeasures = '/admin/risk-assessment/mitigatingMeasures',
  OperationTypes = '/admin/risk-assessment/operationTypes',
  Settings = '/admin/risk-assessment/settings',
}

export enum DueListRoutes {
  Main = '/due-list',
}
