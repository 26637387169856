import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { AdminDepartmentRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { IDepartmentGroupModel } from 'src/app/department/models/department-group.model';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from '../../shared/models/id-name';
import { IDepartmentGroupModelWithJobPositions } from '../models/department-group-with-job-positions.model';
import { DepartmentGroupApiService } from '../services/department-group-api.service';
import {
  AddDepartmentGroups,
  DeleteDepartmentGroup,
  GetDepartmentGroupsById,
  LoadAllDepartmentGroups,
  LoadDepartmentGroups,
  LoadDepartmentGroupsForUser,
  LoadDepartmentGroupsWithDepartments,
  UpdateDepartmentGroups,
} from './department-group.actions';
import { IDepartmentGroupsWithDepartments } from '../models/department-group-with-departments.model';

export interface DepartmentGroupStateModel {
  departmentGroups: IDepartmentGroupModel[];
  allDepartmentGroups: IDepartmentGroupModel[];
  departmentGroupsTotalPages: number;
  departmentGroupsForUser: IDepartmentGroupModelWithJobPositions[];
  departmentGroupsWithDepartments: IDepartmentGroupsWithDepartments | undefined;
}

const DEPARTMENT_GROUP_STATE_TOKEN = new StateToken<DepartmentGroupStateModel>('departmentGroups');

@State<DepartmentGroupStateModel>({
  name: DEPARTMENT_GROUP_STATE_TOKEN,
  defaults: {
    departmentGroups: [],
    allDepartmentGroups: [],
    departmentGroupsTotalPages: 0,
    departmentGroupsForUser: [],
    departmentGroupsWithDepartments: undefined,
  },
})
@Injectable()
export class DepartmentGroupState {
  constructor(private service: DepartmentGroupApiService) {}

  @Action(LoadDepartmentGroups, { cancelUncompleted: true })
  loadDepartmentGroups(
    { getState, setState }: StateContext<DepartmentGroupStateModel>,
    { filter }: LoadDepartmentGroups
  ) {
    return this.service.getDepartmentGroups(filter).pipe(
      tap((response: ApiResponse<IDepartmentGroupModel[]>) => {
        const state = getState();
        setState({
          ...state,
          departmentGroups: response.data,
          departmentGroupsTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllDepartmentGroups, { cancelUncompleted: true })
  loadAllDepartmentGroups(ctx: StateContext<DepartmentGroupStateModel>) {
    return this.service.getAllDepartmentGroups().pipe(
      tap((allDepartmentGroups) => {
        ctx.setState(patch({ allDepartmentGroups }));
      })
    );
  }

  @Action(LoadDepartmentGroupsForUser, { cancelUncompleted: true })
  loadDepartmentGroupsForUser({ getState, setState }: StateContext<DepartmentGroupStateModel>) {
    return this.service.getDepartmentGroupsForUser().pipe(
      tap((response: IDepartmentGroupModelWithJobPositions[]) => {
        const state = getState();
        setState({
          ...state,
          departmentGroupsForUser: response,
        });
      })
    );
  }

  @Action(LoadDepartmentGroupsWithDepartments, { cancelUncompleted: true })
  loadDepartmentGroupsWithDepartments(
    { getState, setState }: StateContext<DepartmentGroupStateModel>,
    { departmentIds, departmentGroupIds }: LoadDepartmentGroupsWithDepartments
  ) {
    return this.service.getDepartmentGroupsWithDepartments(departmentIds, departmentGroupIds).pipe(
      tap((response: IDepartmentGroupsWithDepartments) => {
        const state = getState();
        setState({
          ...state,
          departmentGroupsWithDepartments: response,
        });
      })
    );
  }

  @Action(AddDepartmentGroups, { cancelUncompleted: true })
  @OnSuccess({ url: AdminDepartmentRoutes.Groups, message: 'departmentGroupCreatedSuccessfully' })
  addDepartmentGroups(
    ctx: StateContext<DepartmentGroupStateModel>,
    { toAdd }: AddDepartmentGroups
  ) {
    return this.service.addDepartmentGroups(toAdd).pipe(
      tap((response: IDepartmentGroupModel) => {
        ctx.setState(
          patch({
            departmentGroups: insertItem<IDepartmentGroupModel>(response),
          })
        );
      })
    );
  }

  @Action(GetDepartmentGroupsById, { cancelUncompleted: true })
  getDepartmentGroupsById(
    ctx: StateContext<DepartmentGroupStateModel>,
    { id }: GetDepartmentGroupsById
  ) {
    // getting every existing department group from state
    const departmentGroupsFromState = ctx.getState().departmentGroups;
    // if a departmentGroups exist then just get updated (latest) from api
    if (departmentGroupsFromState.find((dg: IDepartmentGroupModel) => dg.id === id)) {
      return this.service.getDepartmentGroupsById(id).pipe(
        tap((response: IDepartmentGroupModel) => {
          ctx.setState(
            patch({
              departmentGroups: updateItem<IDepartmentGroupModel>(
                (toGet: IDepartmentGroupModel | undefined) => toGet?.id === response.id,
                response
              ),
            })
          );
        })
      );
    }
    // if it does not exist then fetch it from the api
    return this.service.getDepartmentGroupsById(id).pipe(
      tap((response: IDepartmentGroupModel) => {
        ctx.setState(
          patch({
            departmentGroups: insertItem<IDepartmentGroupModel>(response),
          })
        );
      })
    );
  }

  @Action(UpdateDepartmentGroups, { cancelUncompleted: true })
  @OnSuccess({ url: AdminDepartmentRoutes.Groups, message: 'departmentGroupUpdatedSuccessfully' })
  updateDepartmentGroups(
    ctx: StateContext<DepartmentGroupStateModel>,
    { toUpdate }: UpdateDepartmentGroups
  ) {
    return this.service.updateDepartmentGroups(toUpdate).pipe(
      tap((response: IDepartmentGroupModel) => {
        ctx.setState(
          patch({
            departmentGroups: updateItem<IDepartmentGroupModel>(
              (departmentGroup: IDepartmentGroupModel | undefined) =>
                departmentGroup?.id === response.id,
              response
            ),
          })
        );
      })
    );
  }

  @Action(DeleteDepartmentGroup, { cancelUncompleted: true })
  @OnSuccess({ url: AdminDepartmentRoutes.Groups, message: 'departmentGroupDeletedSuccessfully' })
  deleteDepartmentGroup(
    ctx: StateContext<DepartmentGroupStateModel>,
    { departmentGroupId }: DeleteDepartmentGroup
  ) {
    return this.service.deleteDepartmentGroup(departmentGroupId).pipe(
      tap(() => {
        ctx.setState(
          patch({
            departmentGroups: removeItem<IDepartmentGroupModel>(
              (departmentGroup: IDepartmentGroupModel | undefined) =>
                departmentGroup?.id === departmentGroupId
            ),
          })
        );
      })
    );
  }

  @Selector()
  static getDepartmentGroupsTotalPages(state: DepartmentGroupStateModel) {
    return state.departmentGroupsTotalPages;
  }

  @Selector()
  static loadListDepartmentGroups(state: DepartmentGroupStateModel) {
    return state.departmentGroups;
  }

  @Selector()
  static getAllDepartmentGroups(state: DepartmentGroupStateModel) {
    return state.allDepartmentGroups;
  }

  @Selector()
  static getDepartmentGroupsForUser(state: DepartmentGroupStateModel) {
    return state.departmentGroupsForUser;
  }

  @Selector()
  static getDepartmentGroupsWithDepartments(state: DepartmentGroupStateModel) {
    return state.departmentGroupsWithDepartments;
  }

  @Selector()
  static getDepartmentGroupsById(state: DepartmentGroupStateModel) {
    return (id: string) => {
      return state.departmentGroups.find(
        (departmentGroup: IDepartmentGroupModel) => departmentGroup.id === id
      );
    };
  }
}
