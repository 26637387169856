import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IdName } from 'src/app/shared/models/id-name';
import { Router } from '@angular/router';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { ROOT_MAINTENANCE_COMPONENT } from '../constants';
import { ModuleNumbers } from '../enums/module-numbers.enum';
import { MenuItemModel } from '../models/menu-id.model';
import { CreateUpdateDeleteRecursiveArrayService } from '../services/create-update-delete-recursive-array.service';
import { MenuListLogicService } from '../services/menu-list-logic.service';
import { MenuTreeStructureApiService } from '../services/menu-tree-structure-api.service';
import {
  CopyMenuStructure,
  CreateMenuStructure,
  DeleteMenuStructure,
  GetMenuStructureElementById,
  LoadDocumentMenuRootStructures,
  LoadDocumentMenuStructure,
  LoadFlatMenuStructureByDepartmentAndModule,
  LoadMenuStructure,
  LoadSpecificMenuStructure,
  LoadSpecificMenuStructureForList,
  SaveMenuStructureIdNameList,
  SetActiveMenuElement,
  UpdateMenuStructure,
  UpdateMenuStructureOrder,
} from './menu-tree-structure.actions';
import { IMenuPlacement } from '../models/menu-placement.model';

export interface MenuTreeStructureStateModel {
  loaded: boolean;
  menuStructure: MenuItemModel[];
  menuStructureForList: MenuItemModel[];
  menuStructureIdName: IdName[];
  menuStructureDetailed: MenuItemModel | undefined;
  currentTableData: any[];
  displayName: string;
  documentParentId: string;
  currentModule: ModuleNumbers;
  activeMenuElementId: string;
  menuFlatStructureByDepartamentAndModule: IMenuPlacement[];
}

const MENU_TREE_STRUCTURE_STATE_TOKEN = new StateToken<MenuTreeStructureStateModel>(
  'menuTreeStructure'
);

const MENU_MODULES_WITH_ID_ROUTING = [
  ModuleNumbers.Certificate,
  ModuleNumbers.Document,
  ModuleNumbers.Maintenance,
  ModuleNumbers.Storage,
  ModuleNumbers.Medicine,
  ModuleNumbers.Drill,
  ModuleNumbers.Task,
  ModuleNumbers.RiskAssessment,
];

@State<MenuTreeStructureStateModel>({
  name: MENU_TREE_STRUCTURE_STATE_TOKEN,
  defaults: {
    loaded: false,
    menuStructure: [],
    menuStructureForList: [],
    menuStructureIdName: [ROOT_MAINTENANCE_COMPONENT],
    menuStructureDetailed: undefined,
    currentTableData: [],
    displayName: '',
    documentParentId: '',
    currentModule: 0,
    activeMenuElementId: '',
    menuFlatStructureByDepartamentAndModule: [],
  },
})
@Injectable()
export class MenuTreeStructureState {
  constructor(
    private menuListLogicService: MenuListLogicService,
    private service: MenuTreeStructureApiService,
    private createUpdateDeleteRecursiveArrayService: CreateUpdateDeleteRecursiveArrayService,
    private router: Router
  ) {}

  @Action(LoadMenuStructure, { cancelUncompleted: true })
  loadMenuStructure({ getState, setState }: StateContext<MenuTreeStructureStateModel>) {
    Promise.resolve().then(() => setState(patch({ loaded: false }))); // TODO Illia: deal with this later, it's a hack
    return this.service.getEntireMenuTreeStructure().pipe(
      tap((menuStructure: MenuItemModel[]) => {
        const state = getState();
        setState({
          ...state,
          loaded: true,
          menuStructure,
        });
      })
    );
  }

  @Action(LoadSpecificMenuStructure, { cancelUncompleted: true })
  loadSpecificMenuStructure(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { module, displayName, nodeId }: LoadSpecificMenuStructure
  ) {
    Promise.resolve().then(() => ctx.setState(patch({ loaded: false })));
    return this.service
      .getSpecificMenuTreeStructure(module)
      .pipe(tap(this.onSpecificMenuLoad(ctx, displayName, nodeId, module)));
  }

  @Action(LoadSpecificMenuStructureForList, { cancelUncompleted: true })
  loadSpecificMenuStructureForList(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { module }: LoadSpecificMenuStructureForList
  ) {
    return this.service.getSpecificMenuTreeStructure(module).pipe(
      tap((menuStructureForList) => {
        ctx.setState(
          patch({
            menuStructureForList,
          })
        );
      })
    );
  }

  @Action(LoadDocumentMenuRootStructures, { cancelUncompleted: true })
  loadDocumentMenuRootStructures(ctx: StateContext<MenuTreeStructureStateModel>) {
    Promise.resolve().then(() => ctx.setState(patch({ loaded: false })));
    return this.service.getDocumentMenuRootStructure().pipe(
      tap((documentMenuRootStructures: MenuItemModel[]) => {
        ctx.setState(patch({ menuStructure: documentMenuRootStructures, loaded: true }));
      })
    );
  }

  @Action(LoadDocumentMenuStructure, { cancelUncompleted: true })
  loadDocumentMenuStructure(ctx: StateContext<MenuTreeStructureStateModel>) {
    Promise.resolve().then(() => ctx.setState(patch({ loaded: false })));
    return this.service.getMenuByDepartment(ModuleNumbers.Document).pipe(
      tap((documentMenuStructureElement: MenuItemModel[]) => {
        ctx.setState(patch({ menuStructure: documentMenuStructureElement, loaded: true }));
        ctx.dispatch(new SaveMenuStructureIdNameList(documentMenuStructureElement));
      })
    );
  }

  @Action(SaveMenuStructureIdNameList, { cancelUncompleted: true })
  saveMenuStructureIdNameList(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { structure }: SaveMenuStructureIdNameList
  ) {
    const list = this.menuListLogicService.getIdNameFlatListOfPossibleLocations(structure);
    list.unshift(ROOT_MAINTENANCE_COMPONENT);

    ctx.setState(
      patch({
        menuStructureIdName: list,
      })
    );
  }

  private getUrl(module: number, nodeId: string) {
    if (MENU_MODULES_WITH_ID_ROUTING.includes(module)) {
      return `${this.menuListLogicService.getModuleUrl(module)}/${nodeId}`;
    }

    return this.menuListLogicService.getModuleUrl(module);
  }

  @Action(GetMenuStructureElementById, { cancelUncompleted: true })
  getMenuStructureElementById(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { id }: GetMenuStructureElementById
  ) {
    return this.service.getMenuStructureElementById(id).pipe(
      tap((menuStructureDetailed: MenuItemModel[]) => {
        ctx.setState(
          patch({
            menuStructureDetailed: menuStructureDetailed[0],
            menuStructure: menuStructureDetailed[0].children,
            documentParentId: menuStructureDetailed[0].id,
            currentModule: ModuleNumbers.Document,
          })
        );
        ctx.dispatch(new SaveMenuStructureIdNameList(menuStructureDetailed));
      })
    );
  }

  @Action(LoadFlatMenuStructureByDepartmentAndModule, { cancelUncompleted: true })
  loadFlatMenuStructureByDepartmentAndModule(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { departmentId, module }: LoadFlatMenuStructureByDepartmentAndModule
  ) {
    return this.service.getFlatMenuStructureByDepartmentAndModule(departmentId, module).pipe(
      tap((flatMenuStructure: IMenuPlacement[]) => {
        ctx.setState(
          patch({
            menuFlatStructureByDepartamentAndModule: flatMenuStructure,
          })
        );
      })
    );
  }

  @Action(CreateMenuStructure, { cancelUncompleted: true })
  createMenuStructure(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { menuStructure, mainRoot }: CreateMenuStructure
  ) {
    if (
      !mainRoot &&
      menuStructure.module === ModuleNumbers.Document &&
      menuStructure.parentId &&
      menuStructure.parentId.length < 5
    ) {
      menuStructure.parentId = ctx.getState().documentParentId;
    }
    return this.service.createMenuStructure(menuStructure).pipe(
      tap((menuTreeId: string) => {
        if (mainRoot && menuStructure.module === ModuleNumbers.Document) {
          ctx.dispatch(new LoadDocumentMenuRootStructures());
          return of();
        }
        if (!mainRoot && menuStructure.module === ModuleNumbers.Document) {
          ctx.dispatch(new GetMenuStructureElementById(ctx.getState().documentParentId));
          return of();
        }
        ctx.dispatch(new LoadSpecificMenuStructure(menuStructure.module));
        return of();
        // const newMenuTree: MenuItemModel = {
        //   id: menuTreeId,
        //   parentId: menuStructure.parentId,
        //   code: menuStructure.code,
        //   name: menuStructure.name,
        //   module: menuStructure.module,
        //   displayName: menuStructure.displayName,
        //   departments: menuStructure.departments,
        //   icon: menuStructure.icon,
        //   count: 0,
        //   sortNumber: 0,
        //   children: [],
        // };
        // if (!newMenuTree.parentId) {
        //   ctx.setState(
        //     patch({
        //       menuStructure: append([newMenuTree]),
        //     })
        //   );
        //   return;
        // }

        // const state = ctx.getState();
        // const updatedItems =
        //   this.createUpdateDeleteRecursiveArrayService.addNewItemToParentChildrenElements(
        //     state.menuStructure,
        //     menuStructure.parentId,
        //     newMenuTree
        //   );

        // ctx.setState(
        //   patch({
        //     menuStructure: updatedItems,
        //   })
        // );
      })
    );
  }

  @Action(UpdateMenuStructure, { cancelUncompleted: true })
  updateMenuStructure(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { menuStructure, mainRoot }: UpdateMenuStructure
  ) {
    return this.service.updateMenuStructure(menuStructure).pipe(
      tap((menuTreeId: string) => {
        if (mainRoot && menuStructure.module === ModuleNumbers.Document) {
          ctx.dispatch(new LoadDocumentMenuRootStructures());
          return of();
        }
        if (!mainRoot && menuStructure.module === ModuleNumbers.Document) {
          ctx.dispatch(new GetMenuStructureElementById(ctx.getState().documentParentId));
          return of();
        }
        ctx.dispatch(new LoadSpecificMenuStructure(menuStructure.module));
        return of();
        // const updatedMenuTree: MenuItemModel = {
        //   id: menuTreeId,
        //   parentId: menuStructure.parentId,
        //   code: menuStructure.code,
        //   name: menuStructure.name,
        //   module: menuStructure.module,
        //   displayName: menuStructure.displayName,
        //   departments: menuStructure.departments,
        //   icon: menuStructure.icon,
        //   count: 0,
        //   sortNumber: 0,
        //   children: menuStructure.children,
        // };

        // const state = ctx.getState();
        // const updatedItems =
        //   this.createUpdateDeleteRecursiveArrayService.updateItemInParentChildrenElements(
        //     state.menuStructure,
        //     menuStructure.parentId,
        //     updatedMenuTree
        //   );

        // ctx.setState(
        //   patch({
        //     menuStructure: updatedItems,
        //   })
        // );
      })
    );
  }

  @Action(DeleteMenuStructure, { cancelUncompleted: true })
  deleteMenuStructure(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { id, mainRoot }: DeleteMenuStructure
  ) {
    return this.service.deleteMenuStructure(id).pipe(
      tap(() => {
        const { currentModule } = ctx.getState();
        if (mainRoot && currentModule === ModuleNumbers.Document) {
          ctx.dispatch(new LoadDocumentMenuRootStructures());
          return of();
        }
        if (!mainRoot && currentModule === ModuleNumbers.Document) {
          ctx.dispatch(new GetMenuStructureElementById(ctx.getState().documentParentId));
          return of();
        }
        ctx.dispatch(new LoadSpecificMenuStructure(currentModule));
        return of();
        // const state = ctx.getState();
        // const updatedItems = this.createUpdateDeleteRecursiveArrayService.removeItemFromNestedItems(
        //   state.menuStructure,
        //   id
        // );

        // ctx.setState(
        //   patch({
        //     menuStructure: updatedItems,
        //   })
        // );
      })
    );
  }

  @Action(SetActiveMenuElement, { cancelUncompleted: true })
  setActiveMenuElement(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { id }: SetActiveMenuElement
  ) {
    ctx.setState(patch({ activeMenuElementId: id }));
  }

  @Action(CopyMenuStructure, { cancelUncompleted: true })
  @OnSuccess({ message: 'copyingMenuStartedToast' })
  copyMenuStructure(ctx: StateContext<MenuTreeStructureStateModel>, { model }: CopyMenuStructure) {
    return this.service.copyMenuStructure(model);
  }

  @Action(UpdateMenuStructureOrder, { cancelUncompleted: true })
  @OnSuccess({ message: 'menuStructureOrderUpdated' })
  updateMenuStructureOrder(
    ctx: StateContext<MenuTreeStructureStateModel>,
    { order, mainRoot }: UpdateMenuStructureOrder
  ) {
    return this.service
      .updateMenuStructureOrder(order)
      .pipe
      // tap(() => {
      // TODO Illia: works flakey because the update operation is async on the server
      // if (order.module === ModuleNumbers.Document) {
      //   ctx.dispatch(
      //     mainRoot
      //       ? new LoadDocumentMenuRootStructures()
      //       : new GetMenuStructureElementById(ctx.getState().documentParentId)
      //   );
      //   return of();
      // }
      // ctx.dispatch(new LoadSpecificMenuStructure(order.module));
      // return of();
      // })
      ();
  }

  @Selector()
  static menuStructureLoaded(state: MenuTreeStructureStateModel): boolean {
    return state.loaded;
  }

  @Selector()
  static getEntireMenuStructure(state: MenuTreeStructureStateModel) {
    return state.menuStructure;
  }

  @Selector()
  static getEntireMenuStructureForList(state: MenuTreeStructureStateModel) {
    return state.menuStructureForList;
  }

  @Selector()
  static getSelectedPartOfMenuStructure(state: MenuTreeStructureStateModel) {
    return state.currentTableData;
  }

  @Selector()
  static getMenuStructureIdNameList(state: MenuTreeStructureStateModel) {
    return state.menuStructureIdName;
  }

  @Selector()
  static getMenuFlatStructureByDepartamentAndModule(state: MenuTreeStructureStateModel) {
    return state.menuFlatStructureByDepartamentAndModule;
  }

  @Selector()
  static getDetailedMenuStructureElement(state: MenuTreeStructureStateModel) {
    return state.menuStructureDetailed;
  }

  @Selector()
  static getSelectedMenuId(state: MenuTreeStructureStateModel) {
    return state.currentTableData[0].id;
  }

  @Selector()
  static getDisplayName(state: MenuTreeStructureStateModel) {
    return state.displayName;
  }

  @Selector()
  static activeMenuElementId(state: MenuTreeStructureStateModel) {
    return state.activeMenuElementId;
  }

  static getMenuItemById(id: string) {
    return createSelector([MenuTreeStructureState], (state: MenuTreeStructureStateModel) => {
      return CreateUpdateDeleteRecursiveArrayService.getItemThatMatchParentId(
        state.menuStructure,
        id
      );
    });
  }

  private onSpecificMenuLoad(
    ctx: StateContext<MenuTreeStructureStateModel>,
    displayName: string | undefined,
    nodeId: string | undefined,
    module: number
  ) {
    return (menuStructure: MenuItemModel[]) => {
      ctx.setState(patch({ displayName }));
      if (nodeId) {
        this.updateStateWithSelectedMenu(menuStructure, nodeId, ctx, displayName, module);
      } else {
        this.updateStateWhenNoIdWasSelected(ctx, menuStructure, module);
      }
      ctx.dispatch(new SaveMenuStructureIdNameList(menuStructure));
    };
  }

  private updateStateWhenNoIdWasSelected(
    ctx: StateContext<MenuTreeStructureStateModel>,
    menuStructure: MenuItemModel[],
    module: number
  ) {
    const state = ctx.getState();
    const menuIdBelongsToCurrentStructure =
      !!state.currentTableData[0] &&
      !!this.createUpdateDeleteRecursiveArrayService.getNodeWithChildrenOneLevelDeep(
        menuStructure,
        state.currentTableData[0]?.id
      );
    ctx.setState({
      ...state,
      loaded: true,
      menuStructure,
      currentTableData: menuIdBelongsToCurrentStructure ? state.currentTableData : [],
      currentModule: module,
    });
  }

  private updateStateWithSelectedMenu(
    menuStructure: MenuItemModel[],
    nodeId: string,
    ctx: StateContext<MenuTreeStructureStateModel>,
    displayName: string | undefined,
    module: number
  ) {
    const nodeWithChildren =
      this.createUpdateDeleteRecursiveArrayService.getNodeWithChildrenOneLevelDeep(
        menuStructure,
        nodeId
      );
    ctx.setState(
      patch({
        loaded: true,
        menuStructure,
        currentTableData: [nodeWithChildren],
        displayName: displayName ?? '',
      })
    );
    this.router.navigateByUrl(`${this.getUrl(module, nodeId)}`);
  }
}
