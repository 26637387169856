import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { getTenantId, getDepartmentId } from 'src/app/services/store-snapshot.service';
import { environment } from 'src/environments/environment';
import { IMedicineCategory } from '../models/medicine-category.model';

const headers = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root',
})
export class MedicineCategoryApiService {
  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/Medicine/Categories`;
  }

  private get departmentCategoriesUrl(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/Medicine/Categories`;
  }

  constructor(private http: HttpClient) {}

  loadMedicineCategories(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IMedicineCategory[]>> {
    let url = `${this.url}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IMedicineCategory[]>>(url);
  }

  loadDepartmentMedicineCategories(): Observable<IMedicineCategory[]> {
    return this.http.get<IMedicineCategory[]>(this.departmentCategoriesUrl);
  }

  loadMedicineCategoryById(id: string): Observable<IMedicineCategory> {
    const url = `${this.url}/${id}`;
    return this.http.get<IMedicineCategory>(url);
  }

  createMedicineCategory(medicineCategory: IMedicineCategory): Observable<IMedicineCategory> {
    return this.http.post<IMedicineCategory>(this.url, medicineCategory, { headers });
  }

  updateMedicineCategory(medicineCategory: IMedicineCategory): Observable<IMedicineCategory> {
    const url = `${this.url}`;
    return this.http.put<IMedicineCategory>(url, medicineCategory, { headers });
  }

  deleteMedicineCategory(id: string): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }
}
