import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContactForm } from '../../forms/contact-form';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent {
  @Input() contactForm: ContactForm;
}
