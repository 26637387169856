<ng-container [ngSwitch]="specificTemplateName">
  <ng-container *ngSwitchCase="departmentTableNamesEnum.ContactPersonList">
    <app-contact-person-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
      [hideButtons]="hideButtons"
    ></app-contact-person-template>
  </ng-container>
  <ng-container *ngSwitchCase="departmentTableNamesEnum.MainEngineList">
    <app-engine-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
      [hideButtons]="hideButtons"
    ></app-engine-template>
  </ng-container>
  <ng-container *ngSwitchCase="departmentTableNamesEnum.CargoHoldList">
    <app-cargo-hold-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
      [hideButtons]="hideButtons"
    ></app-cargo-hold-template>
  </ng-container>
  <ng-container *ngSwitchCase="departmentTableNamesEnum.CargoGearList">
    <app-cargo-gear-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
      [hideButtons]="hideButtons"
    ></app-cargo-gear-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-departments-template
      [columnName]="columnName"
      [element]="element"
    ></app-departments-template>
  </ng-container>
</ng-container>
