/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IActivityRequest } from '../../models/activity.model';

export class LoadActivities {
  static readonly type = '[Activities] Load Activities via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllActivitiesForDepartment {
  static readonly type = '[Activities] Load All Activities For Department via Service';
  constructor() {}
}

export class LoadActivityById {
  static readonly type = '[Activities] Load Activity By Id via Service';
  constructor(public id: string) {}
}

export class CreateActivity {
  static readonly type = '[Activities] Create Activity';
  constructor(public activity: IActivityRequest) {}
}

export class UpdateActivity {
  static readonly type = '[Activities] Update Activity';
  constructor(public activity: IActivityRequest) {}
}

export class DeleteActivity {
  static readonly type = '[Activities] Delete Activity';
  constructor(public id: string) {}
}
