import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { environment } from 'src/environments/environment';
import { IQuantityType } from '../models/quantity-type.model';

const headers = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root',
})
export class QuantityTypeApiService {
  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/quantitytypes`;
  }

  constructor(private http: HttpClient) {}

  loadQuantityTypes(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IQuantityType[]>> {
    let url = `${this.url}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IQuantityType[]>>(url);
  }

  loadAllQuantityTypes(): Observable<IQuantityType[]> {
    const url = `${this.url}/List`;
    return this.http.get<IQuantityType[]>(url);
  }

  loadQuantityTypeById(id: string): Observable<IQuantityType> {
    const url = `${this.url}/${id}`;
    return this.http.get<IQuantityType>(url);
  }

  createQuantityType(quantityType: IQuantityType): Observable<IQuantityType> {
    return this.http.post<IQuantityType>(this.url, quantityType, { headers });
  }

  updateQuantityType(quantityType: IQuantityType): Observable<IQuantityType> {
    const url = `${this.url}/${quantityType.id}`;
    return this.http.put<IQuantityType>(url, quantityType, { headers });
  }

  deleteQuantityType(id: string): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }
}
