/* eslint-disable max-classes-per-file */
import { ModuleNumbers } from '../enums/module-numbers.enum';

// below when is needed
export class LoadAllPrivileges {
  static readonly type = '[Privileges] Load All Privileges';
  constructor(public departmentIds: Array<string>, public departmentGroupIds: Array<string>) {}
}

export class LoadAllGroupedPrivileges {
  static readonly type = '[Privileges] Load All Grouped Privileges';
  constructor() {}
}

// export class LoadAllPrivileges {
//   static readonly type = '[Privileges] Load All Privileges';
// }

export class LoadPrivilegesForModule {
  static readonly type = '[Privileges] Load Privileges For Module';
  constructor(
    public module: ModuleNumbers,
    public departmentIds: Array<string>,
    public departmentGroupIds: Array<string>
  ) {}
}
