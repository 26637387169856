import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subscription, map, startWith, tap } from 'rxjs';

@Component({
  selector: 'app-ui-chips-autocomplete',
  templateUrl: './ui-chips-autocomplete.component.html',
  styleUrls: ['./ui-chips-autocomplete.component.scss'],
})
export class UiChipsAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label = '';
  @Input() description = '';
  @Input() options: any[] | null = [];
  @Input() disabled: boolean;
  @Input() required = false;
  @Input() control: FormControl = new FormControl([]);
  @Input() autocomplete = true;
  @Input() addNewValueOnBlur = false;
  @Input() addNewValueOnEnter = false;
  @Input() returnWholeObject = false;

  @Output() readonly changeHandler: EventEmitter<MatAutocompleteSelectedEvent> =
    new EventEmitter<MatAutocompleteSelectedEvent>();

  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;

  inputControl = new FormControl('');
  separatorKeysCodes: number[] = [ENTER];
  filteredOptions: any[] = [];
  optionNamesMap: { [key: string]: string } = {};

  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.updateFilteredOptions();
    this.subscriptions.add(
      this.inputControl.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => {
            if (typeof value === 'string') {
              return this.filterOptions(value);
            }
            if (typeof value === 'object') {
              // mat-autocomplete assigns the selected value to the inputControl so we need to handle it
              this.resetInputControl();
              return this.filteredOptions;
            }
            return this.filteredOptions;
          }),
          tap((filteredOptions) => {
            this.filteredOptions = filteredOptions || [];
          })
        )
        .subscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.updateFilteredOptions();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onInputTokenEnd(event: MatChipInputEvent): void {
    if (this.addNewValueOnEnter && this.inputControl.value) {
      this.addKeywordFromInput(this.inputControl.value);
      event.chipInput.clear();
      this.resetInputControl();
    }
  }

  remove(item: string): void {
    const index = this.control.value.indexOf(item);
    if (index >= 0) {
      this.removeFromSelectedItems(index);
    }
  }

  onSelect(event: MatAutocompleteSelectedEvent): void {
    this.addToSelectedItems(event.option.value);
    this.resetInputControl();
    this.changeHandler.emit(event);
  }

  onFocusOut(): void {
    setTimeout(() => {
      if (this.addNewValueOnBlur && this.inputControl.value) {
        this.addKeywordFromInput(this.inputControl.value);
        this.resetInputControl();
      }
    }, 200);
  }

  private addKeywordFromInput(value: string): void {
    const trimmedValue = (value || '').trim();
    if (trimmedValue && trimmedValue.length > 1) {
      this.addToSelectedItems({ name: trimmedValue });
    }
  }

  private filterOptions(value?: string): any[] {
    const filterValue = this.autocomplete && value ? value.toLowerCase() : ''; // only filter out already selected items when autocomplete is disabled
    const options = this.options || [];
    this.optionNamesMap = {};
    options.forEach((option) => {
      this.optionNamesMap[option.id] = option.name;
    });
    return options
      .filter((option) => option.name.toLowerCase().includes(filterValue))
      .filter(
        (option) => !this.control.value.find((cv: any) => cv?.id === option.id || cv === option.id)
      );
  }

  private updateFilteredOptions(): void {
    this.filteredOptions = this.filterOptions();
  }

  private addToSelectedItems(item: any): void {
    const newValue = this.control.value.concat(item);
    this.updateControl(newValue);
  }

  private removeFromSelectedItems(index: number): void {
    const newValue = this.control.value.slice();
    newValue.splice(index, 1);
    this.updateControl(newValue);
  }

  private updateControl(value: any[]): void {
    this.control.setValue(value);
    this.control.updateValueAndValidity();
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.updateFilteredOptions();
  }

  private resetInputControl(): void {
    this.inputControl.setValue('');
    this.inputField.nativeElement.value = '';
  }
}
