/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { CanDeactivate, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface HasForm {
  form: FormGroup | UntypedFormGroup | AbstractControl;
}

export interface HasUnsavedChanges {
  unsavedChanges?: boolean;
}

@Injectable({ providedIn: 'root' })
export class UnsavedDataGuard implements CanDeactivate<HasForm | HasUnsavedChanges> {
  constructor(private translate: TranslateService, private router: Router) {}
  canDeactivate(component: HasForm | HasUnsavedChanges): boolean {
    if (window.history.state.skipUnsavedDataCheck) {
      window.history.state.skipUnsavedDataCheck = false;
      return true;
    }

    // @ts-ignore
    if (component.unsavedChanges) {
      return this.confirmLeaveWithoutSaving();
    }
    // @ts-ignore
    if (!component.form) {
      // eslint-disable-next-line no-console
      console.warn(component.constructor.name, this.translate.instant('noFormProperty'));
      return true;
    }
    // @ts-ignore
    if (component.form.dirty && component.form.enabled) {
      return this.confirmLeaveWithoutSaving();
    }

    return true;
  }

  confirmLeaveWithoutSaving(): boolean {
    // eslint-disable-next-line no-restricted-globals, no-alert
    return confirm(this.translate.instant('confirmNavigationWithUnsavedData'));
  }
}
