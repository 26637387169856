import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { AdminQuantityTypesRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IQuantityType } from '../models/quantity-type.model';
import { QuantityTypeApiService } from '../services/quantity-type-api.service';
import {
  CreateQuantityType,
  DeleteQuantityType,
  LoadQuantityTypeById,
  LoadQuantityTypes,
  UpdateQuantityType,
  LoadAllQuantityTypes,
} from './quantity-type.actions';

export interface QuantityTypeStateModel {
  quantityTypes: IQuantityType[];
  allQuantityTypes: IQuantityType[];
  currentQuantityType: IQuantityType | undefined;
  quantityTypesTotalPages: number;
}

const QUANTITY_TYPE_STATE_TOKEN = new StateToken<QuantityTypeStateModel>('quantityType');

@State<QuantityTypeStateModel>({
  name: QUANTITY_TYPE_STATE_TOKEN,
  defaults: {
    quantityTypes: [],
    allQuantityTypes: [],
    quantityTypesTotalPages: 0,
    currentQuantityType: undefined,
  },
})
@Injectable()
export class QuantityTypeState {
  constructor(private service: QuantityTypeApiService) {}

  @Action(LoadQuantityTypes)
  loadQuantityTypes(
    { getState, setState }: StateContext<QuantityTypeStateModel>,
    { filter }: LoadQuantityTypes
  ) {
    return this.service.loadQuantityTypes(filter).pipe(
      tap((response: ApiResponse<IQuantityType[]>) => {
        const state = getState();
        setState({
          ...state,
          quantityTypes: response.data,
          quantityTypesTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllQuantityTypes)
  loadAllQuantityTypes(ctx: StateContext<QuantityTypeStateModel>) {
    return this.service.loadAllQuantityTypes().pipe(
      tap((allQuantityTypes: IQuantityType[]) => {
        ctx.setState(patch({ allQuantityTypes }));
      })
    );
  }

  @Action(LoadQuantityTypeById, { cancelUncompleted: true })
  loadQuantityTypeById(
    { setState }: StateContext<QuantityTypeStateModel>,
    { id }: LoadQuantityTypeById
  ) {
    return this.service.loadQuantityTypeById(id).pipe(
      tap((quantityType: IQuantityType) => {
        setState(
          patch({
            currentQuantityType: quantityType,
          })
        );
      })
    );
  }

  @Action(CreateQuantityType)
  @OnSuccess({ url: AdminQuantityTypesRoutes.Main, message: 'quantityTypeAdded' })
  createQuantityType(
    { setState }: StateContext<QuantityTypeStateModel>,
    { quantityType }: CreateQuantityType
  ) {
    return this.service.createQuantityType(quantityType).pipe(
      tap((response: IQuantityType) => {
        setState(
          patch({
            quantityTypes: insertItem<IQuantityType>(response),
          })
        );
      })
    );
  }

  @Action(UpdateQuantityType)
  @OnSuccess({ url: AdminQuantityTypesRoutes.Main, message: 'quantityTypeUpdated' })
  updateQuantityType(
    { setState }: StateContext<QuantityTypeStateModel>,
    { quantityType }: UpdateQuantityType
  ) {
    return this.service.updateQuantityType(quantityType).pipe(
      tap(() => {
        setState(
          patch({
            quantityTypes: updateItem<IQuantityType>(
              (toUpdate) => toUpdate?.id === quantityType.id,
              quantityType
            ),
          })
        );
      })
    );
  }

  @Action(DeleteQuantityType, { cancelUncompleted: true })
  @OnSuccess({ url: AdminQuantityTypesRoutes.Main, message: 'quantityTypeDeleted' })
  deleteQuantityType(ctx: StateContext<QuantityTypeStateModel>, { id }: DeleteQuantityType) {
    return this.service.deleteQuantityType(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            quantityTypes: removeItem<IQuantityType>(
              (jtToDelete: IQuantityType | undefined) => jtToDelete?.id === id
            ),
          })
        );
      })
    );
  }

  @Selector()
  static quantityTypesTotalPages(state: QuantityTypeStateModel) {
    return state.quantityTypesTotalPages;
  }

  @Selector()
  static quantityTypes(state: QuantityTypeStateModel) {
    return state.quantityTypes;
  }

  @Selector()
  static allQuantityTypes(state: QuantityTypeStateModel) {
    return state.allQuantityTypes;
  }

  @Selector()
  static getCurrentQuantityType(state: QuantityTypeStateModel) {
    return state.currentQuantityType;
  }

  @Selector()
  static getQuantityTypesByModule(state: QuantityTypeStateModel) {
    return (module: string) => {
      return state.allQuantityTypes?.filter((qt) =>
        qt.modules?.map((m) => m.toLocaleLowerCase()).includes(module)
      );
    };
  }
}
