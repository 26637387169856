/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { INonConformanceTypeRequest } from '../../models/types/non-conformance-type.model';

export class LoadNonConformanceTypes {
  static readonly type = '[Non Conformance Type] Load Non Conformance Types';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllNonConformanceTypes {
  static readonly type = '[Non Conformance Type] Load All Non Conformance Types';
  constructor() {}
}

export class CreateNonConformanceType {
  static readonly type = '[Non Conformance Type] Add Non Conformance Type';
  constructor(public nonConformanceType: INonConformanceTypeRequest) {}
}

export class UpdateNonConformanceType {
  static readonly type = '[Non Conformance Type] Update Non Conformance Type';
  constructor(public nonConformanceType: INonConformanceTypeRequest) {}
}

export class DeleteNonConformanceType {
  static readonly type = '[Non Conformance Type] Delete Non Conformance Type';
  constructor(public id: string) {}
}

export class GetNonConformanceTypeById {
  static readonly type = '[Non Conformance Type] Get Non Conformance Type By Id';
  constructor(public id: string) {}
}
