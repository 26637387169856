import { IAlertResponse } from 'src/app/alert/models/alert.model';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AdminAlertRoutes } from 'src/app/core/enums/routes.enum';
import { ApplySearch } from 'src/app/core/state/main-table-filter.actions';
import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';

import { AlertState } from '../../state/alert.state';
import { LoadAlerts } from '../../state/alert.actions';

@Component({
  selector: 'app-alert-view',
  templateUrl: './alert-view.component.html',
  styleUrls: ['./alert-view.component.scss'],
})
export class AlertViewComponent {
  alerts$: Observable<IAlertResponse[]> = this.store.select(AlertState.getAlerts);
  alertsTotalPages$: Observable<number> = this.store.select(AlertState.getAlertsTotalPages);
  displayedColumns = [
    'name',
    'modules',
    'yellowAlertDays',
    'redAlertDays',
    'yellowAlertHours',
    'redAlertHours',
    'emailReminder',
    'smsReminder',
  ];
  disabledColumnHeaders: string[] = ['modules'];
  routerLink = `${AdminAlertRoutes.Main}`;
  moduleNameForPaging = MainTableFilterModulesEnum.Alerts;
  loadingAction = [LoadAlerts];

  constructor(private store: Store) {}

  filterPredicate = (data: IAlertResponse, filter: string): boolean => {
    const transformedFilter = filter.trim().toLowerCase();
    const namePredicate = data.name.toLowerCase().includes(transformedFilter);
    const moduleNamesPredicate = data.moduleNames.some((moduleName) =>
      moduleName.toLowerCase().includes(transformedFilter)
    );
    return namePredicate || moduleNamesPredicate;
  };

  searchChanged(searchTerm: string): void {
    this.store.dispatch(new ApplySearch(MainTableFilterModulesEnum.Alerts, searchTerm));
  }
}
