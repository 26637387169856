/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IJobType } from '../models/job-type.model';

export class LoadJobTypes {
  static readonly type = '[Job Type] Load JobTypes via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllJobTypes {
  static readonly type = '[Job Type] Load All JobTypes via Service';
  constructor() {}
}

export class LoadJobTypeById {
  static readonly type = '[Job Type] Load a single job type';
  constructor(public id: string) {}
}

export class CreateJobType {
  static readonly type = '[Job Type] Create job type';
  constructor(public jobType: IJobType) {}
}

export class UpdateJobType {
  static readonly type = '[Job Type] Update job type';
  constructor(public jobType: IJobType) {}
}

export class DeleteJobType {
  static readonly type = '[Job Type] Delete job type';
  constructor(public id: string) {}
}
