import {
  AdminRouteMetadata,
  IRouteMetadata,
  RouteMetadata,
  UserMedicineRoutes,
  UserRiskAssessmentRoutes,
  UserStorageRoutes,
  UserTasksRoutes,
} from 'src/app/core/enums/routes.enum';
/* eslint-disable no-magic-numbers */
/* eslint-disable prefer-destructuring */
import { Injectable } from '@angular/core';
import { IdName } from 'src/app/shared/models/id-name';
import {
  UserCertificateRoutes,
  UserDocumentsRoutes,
  UserDrillsRoutes,
  UserMaintenanceRoutes,
} from '../enums/routes.enum';
import {
  MainModulesEnum,
  DepartmentSubModulesEnum,
  CompetencesSubModulesEnum,
  NonConformanceSubModulesEnum,
} from '../enums/modules.enum';
import { MenuStateModel } from '../state/menu-list.state';
import { MenuItemModel } from '../models/menu-id.model';
import { ModuleNumbers } from '../enums/module-numbers.enum';

@Injectable({
  providedIn: 'root',
})
export class MenuListLogicService {
  private urlParts: string[];
  private moduleUrlMapper = new Map([
    [ModuleNumbers.Certificate, UserCertificateRoutes.Overview],
    [ModuleNumbers.Maintenance, UserMaintenanceRoutes.Component],
    [ModuleNumbers.Document, UserDocumentsRoutes.Overview],
    [ModuleNumbers.Storage, UserStorageRoutes.List],
    [ModuleNumbers.Competence, ''],
    [ModuleNumbers.Medicine, UserMedicineRoutes.List],
    [ModuleNumbers.Drill, UserDrillsRoutes.Overview],
    [ModuleNumbers.Task, UserTasksRoutes.Overview],
    [ModuleNumbers.RiskAssessment, UserRiskAssessmentRoutes.List],
  ]);
  private tempParentId = '';

  saveTempParentId(id: string): void {
    this.tempParentId = id;
  }

  getParentId(): string {
    return this.tempParentId;
  }

  getIdNameFlatListOfPossibleLocations(menuItems: MenuItemModel[]): IdName[] {
    let idNameList: IdName[] = [];
    if (menuItems) {
      menuItems.forEach((item: MenuItemModel) => {
        idNameList.push({ id: item.id, name: item.name });
        if (item.children && item.children.length > 0) {
          idNameList = idNameList.concat(this.getIdNameFlatListOfPossibleLocations(item.children));
        }
      });
    }
    return idNameList;
  }

  getModuleUrl(moduleNumber: number): string | undefined {
    return this.moduleUrlMapper.get(moduleNumber);
  }

  getModuleNumberFromUrl(url: string): number | undefined {
    const metadata = this.getModuleMetadata(url);
    return metadata?.number ?? undefined;
  }

  getModuleNameFromUrl(url: string): string | undefined {
    const metadata = this.getModuleMetadata(url);
    return metadata?.name ?? undefined;
  }

  getModuleMetadata(url: string): IRouteMetadata | null {
    const metadata = url.includes('admin') ? AdminRouteMetadata : RouteMetadata;
    const moduleKey = Object.keys(metadata).find((key) => url.includes(key));
    return moduleKey ? metadata[moduleKey] : null;
  }

  getMenuStateModel(url: string): MenuStateModel {
    const urlParts = url.split('/');
    this.urlParts = urlParts;
    let mainModule = '';
    let subModule = '';
    let isInAdmin = false;

    if (urlParts[1] === MainModulesEnum.Admin) {
      // first we check whether first part of the array is the ADMIN module
      isInAdmin = true;
      if (this.urlFragmentPartOfMainModuleEnum(urlParts[2])) {
        // next we check if a main module can be found in 2nd element
        mainModule = urlParts[2];
      } else if (this.urlFragmentPartOfMainModuleEnum(urlParts[3])) {
        // then we check for main module in 3rd element, because sometimes
        // 2nd element is a "tenantId"
        mainModule = urlParts[3];
      }
    } else if (this.urlFragmentPartOfMainModuleEnum(urlParts[1])) {
      // not in admin, then we check for main module in 1st element
      mainModule = urlParts[1];
    } else if (this.urlFragmentPartOfMainModuleEnum(urlParts[2])) {
      // not in admin, then we check again for main module but in 2nd element
      // because sometimes 1st element can be "tenantId"
      mainModule = urlParts[2];
    }

    switch (mainModule) {
      case MainModulesEnum.Department:
        subModule = this.getSubModuleForEnum(DepartmentSubModulesEnum);
        break;
      case MainModulesEnum.Competence:
        subModule = this.getSubModuleForEnum(CompetencesSubModulesEnum);
        break;
      case MainModulesEnum.NonConformance:
        subModule = this.getSubModuleForEnum(NonConformanceSubModulesEnum);
        break;
      default:
        break;
    }

    return {
      mainModule,
      subModule,
      isInAdmin,
    };
  }

  private urlFragmentPartOfMainModuleEnum(urlFragment: string): boolean {
    return (<any>Object).values(MainModulesEnum).includes(urlFragment);
  }

  private getSubModuleForEnum(e: any): string {
    let subModule = '';
    this.urlParts.forEach((urlFragment: string) => {
      if ((<any>Object).values(e).includes(urlFragment)) {
        subModule = urlFragment;
      }
    });
    return subModule;
  }
}
