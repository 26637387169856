<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'location'">
    {{ form?.menu?.value?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'edit'">
    <button mat-button (click)="buttonFunctions.edit()">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'delete'">
    <button mat-button (click)="buttonFunctions.delete()">
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'quantityType'">
    {{ form?.quantityType?.value?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'quantity'">
    {{ form?.quantity?.value }} {{ form?.quantityType?.value?.name }}
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="fixed-width">
      <p [title]="form.get(columnName)?.value">{{ form.get(columnName)?.value }}</p>
    </div>
  </ng-container>
</ng-container>
