import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { AdminCrewManagementRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from 'src/app/shared/models/id-name';
import {
  IShiftResponse,
  IShiftWithDepartment,
  IShiftWithDepartmentResponse,
  map,
} from '../../models/shift.model';
import { ShiftApiService } from '../../services/shift-api.service';
import {
  CreateShift,
  DeleteShift,
  LoadAllShifts,
  LoadAllShiftsForDepartment,
  LoadAllShiftsWithDepartment,
  LoadShiftById,
  LoadShifts,
  UpdateShift,
} from './shift.actions';

export interface ShiftStateModel {
  shifts: IShiftResponse[];
  allShifts: IdName[];
  allShiftsPerDepartment: IdName[];
  allShiftsWithDepartment: IShiftWithDepartment[];
  shiftsTotalPages: number;
  currentShift: IShiftResponse;
}

const SHIFT_STATE_TOKEN = new StateToken<ShiftStateModel>('shifts');

@State<ShiftStateModel>({
  name: SHIFT_STATE_TOKEN,
  defaults: {
    shifts: [],
    allShifts: [],
    allShiftsPerDepartment: [],
    allShiftsWithDepartment: [],
    shiftsTotalPages: 0,
    currentShift: <IShiftResponse>{},
  },
})
@Injectable()
export class ShiftState {
  constructor(private service: ShiftApiService) {}

  @Action(LoadShifts, { cancelUncompleted: true })
  loadShifts({ getState, setState }: StateContext<ShiftStateModel>, { filter }: LoadShifts) {
    return this.service.getShifts(filter).pipe(
      tap((response: ApiResponse<IShiftResponse[]>) => {
        const state = getState();
        setState({
          ...state,
          shifts: response.data,
          shiftsTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllShifts, { cancelUncompleted: true })
  loadAllShifts(ctx: StateContext<ShiftStateModel>) {
    return this.service.getAllShifts().pipe(
      tap((allShifts: IdName[]) => {
        ctx.setState(patch({ allShifts }));
      })
    );
  }

  @Action(LoadAllShiftsForDepartment, { cancelUncompleted: true })
  loadAllShiftsForDepartment(
    { getState, setState }: StateContext<ShiftStateModel>,
    { departmentId }: LoadAllShiftsForDepartment
  ) {
    return this.service.getAllShiftsForDepartment(departmentId).pipe(
      tap((allShiftsPerDepartment: IdName[]) => {
        const state = getState();
        setState({
          ...state,
          allShiftsPerDepartment,
        });
      })
    );
  }

  @Action(LoadAllShiftsWithDepartment, { cancelUncompleted: true })
  loadAllShiftsWithDepartment(
    { getState, setState }: StateContext<ShiftStateModel>,
    { onlyAvailableShifts }: LoadAllShiftsWithDepartment
  ) {
    return this.service.getAllShiftsWithDepartment(onlyAvailableShifts).pipe(
      tap((allShiftsWithDepartment: IShiftWithDepartmentResponse[]) => {
        const state = getState();

        const shifts = allShiftsWithDepartment.map((s: IShiftWithDepartmentResponse) => {
          return {
            id: s.id,
            name: `${s.shiftName} - ${s.departmentName}`,
            departmentId: s.departmentId,
            departmentName: s.departmentName,
          };
        });

        setState({
          ...state,
          allShiftsWithDepartment: shifts,
        });
      })
    );
  }

  @Action(LoadShiftById, { cancelUncompleted: true })
  loadShiftById({ getState, setState }: StateContext<ShiftStateModel>, { id }: LoadShiftById) {
    return this.service.getShiftById(id).pipe(
      tap((response: IShiftResponse) => {
        const state = getState();
        setState({
          ...state,
          currentShift: response,
        });
      })
    );
  }

  @Action(CreateShift, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCrewManagementRoutes.Shifts, message: 'shiftCreated' })
  createShift(ctx: StateContext<ShiftStateModel>, { shift }: CreateShift) {
    return this.service.createShift(shift).pipe(
      tap((data: IShiftResponse) => {
        ctx.setState(
          patch({
            shifts: insertItem<IShiftResponse>(data),
          })
        );
      })
    );
  }

  @Action(UpdateShift, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCrewManagementRoutes.Shifts, message: 'shiftUpdated' })
  updateShift(ctx: StateContext<ShiftStateModel>, { shift }: UpdateShift) {
    return this.service.updateShift(shift).pipe(
      tap((response: IShiftResponse) => {
        ctx.setState(
          patch({
            shifts: updateItem<IShiftResponse>(
              (shiftToUpdate: IShiftResponse | undefined) => shiftToUpdate?.id === shift.id,
              map(shift)
            ),
          })
        );
      })
    );
  }

  @Action(DeleteShift, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCrewManagementRoutes.Shifts, message: 'shiftDeleted' })
  deleteShift(ctx: StateContext<ShiftStateModel>, { shiftId }: DeleteShift) {
    return this.service.deleteShift(shiftId).pipe(
      tap((response: IShiftResponse) => {
        ctx.setState(
          patch({
            shifts: removeItem<IShiftResponse>(
              (shiftToRemove: IShiftResponse | undefined) => shiftToRemove?.id === shiftId
            ),
          })
        );
      })
    );
  }

  @Selector()
  static getShiftsTotalPages(state: ShiftStateModel) {
    return state.shiftsTotalPages;
  }

  @Selector()
  static getShifts(state: ShiftStateModel): IShiftResponse[] {
    return state.shifts;
  }

  @Selector()
  static getAllShifts(state: ShiftStateModel): IdName[] {
    return state.allShifts;
  }

  @Selector()
  static getAllShiftsPerDepartment(state: ShiftStateModel): IdName[] {
    return state.allShiftsPerDepartment;
  }

  @Selector()
  static getAllShiftsWithDepartment(state: ShiftStateModel): IShiftWithDepartment[] {
    return state.allShiftsWithDepartment;
  }

  @Selector()
  static getShiftById(state: any) {
    return state.currentShift;
  }
}
