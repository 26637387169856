import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { btoaUTF8, containsHtmlTag, retry } from '../text-control.helpers';

declare const TXDocumentViewer: any;

@Component({
  selector: 'app-text-control-viewer',
  templateUrl: './text-control-viewer.component.html',
  styleUrls: ['./text-control-viewer.component.scss'],
})
export class TextControlViewerComponent {
  constructor(private appRef: ApplicationRef, private cdr: ChangeDetectorRef) {}
  @Input() set content(content: string) {
    this.contentBase64 = containsHtmlTag(content) ? btoaUTF8(content, false) : content;
    retry(
      () => typeof TXDocumentViewer !== 'undefined',
      () => this.loadTxViewer(),
      50
    );
  }

  @Output() readonly loaded = new EventEmitter<any>();

  url = environment.textControlUrl;
  contentBase64: string;

  loadTxViewer(): void {
    try {
      TXDocumentViewer.init({
        containerID: 'txViewer',
        viewerSettings: {
          signatureSettings: {
            ownerName: '',
            signatureBoxName: 'txsign',
            signerName: '',
            signerInitials: '',
            showSignatureBar: false,
            uniqueId: '',
            redirectUrlAfterSignature: '',
          },
          userNames: [],
          dock: 0,
          documentData: this.contentBase64,
          documentPath: '',
          toolbarDocked: true,
          isSelectionActivated: true,
          showThumbnailPane: true,
          resources: null,
          basePath: this.url,
        },
      }).then(() => {
        this.loaded.emit();
      });
    } catch (e) {
      console.warn('Could not load Text Control Document Viewer', e);
    }
  }
}
