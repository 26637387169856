<div>
  <table mat-table matSort [dataSource]="dataSource" class="main-table-container">
    <ng-container
      *ngFor="let columnName of displayedColumns; let i = index; trackBy: trackBy"
      [matColumnDef]="columnName"
    >
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="columnName === 'expand' ? 'expand-column' : 'standard-column'"
      >
        <app-main-table-module-specific-template
          [module]="moduleName"
          [specificTemplateName]="specificTemplateName"
          [columnName]="columnName"
          [element]="element"
        ></app-main-table-module-specific-template>
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="background"></tr>
  </table>
</div>
