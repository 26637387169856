import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { AdminDepartmentRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { DepartmentOptionsService } from 'src/app/core/services/department-options.service';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from 'src/app/shared/models/id-name';
import { IDepartmentOptions } from 'src/app/shared/user-view/models/user-options.model';
import { IDepartment, IDepartmentUser, IDepartmentWithGroups } from '../models/department.model';
import { DepartmentApiService } from '../services/department-api.service';
import {
  CreateDepartment,
  DeleteDepartment,
  LoadAllDepartments,
  LoadAllDepartmentsForSelectedTenant,
  LoadAllDepartmentsForMenuId,
  LoadDepartmentById,
  LoadDepartmentList,
  LoadDepartmentOptions,
  LoadDepartments,
  LoadDepartmentsForUser,
  LoadUsersForSelectedDepartment,
  UpdateDepartment,
  LoadDepartmentsAccessedByEmployment,
} from './department.actions';

export interface DepartmentStateModel {
  departmentListLoaded: boolean;
  departmentOptionsLoaded: boolean;
  departments: IDepartment[] | undefined;
  allDepartments: IdName[];
  accessedByEmployment: IdName[];
  departmentsForMenuId: IdName[];
  departmentsTotalPages: number;
  departmentsForUser: IDepartmentWithGroups[] | undefined;
  selectedDepartment: IDepartment | undefined;
  departmentList: IdName[];
  departmentUsers: IDepartmentUser[];
  options?: IDepartmentOptions;
  currentDepartment: IDepartment | undefined;

  allDepartmentsForSelectedTenant: IdName[];
}

const DEPARTMENT_STATE_TOKEN = new StateToken<DepartmentStateModel>('department');

@State<DepartmentStateModel>({
  name: DEPARTMENT_STATE_TOKEN,
  defaults: {
    departmentListLoaded: false,
    departmentOptionsLoaded: false,
    departments: undefined,
    allDepartments: [],
    accessedByEmployment: [],
    departmentsForMenuId: [],
    departmentsTotalPages: 0,
    departmentsForUser: undefined,
    selectedDepartment: undefined,
    departmentList: [],
    departmentUsers: [],
    options: undefined,
    currentDepartment: undefined,
    allDepartmentsForSelectedTenant: [],
  },
})
@Injectable()
export class DepartmentState {
  constructor(
    private service: DepartmentApiService,
    private depOptionsService: DepartmentOptionsService
  ) {}

  @Action(LoadDepartments, { cancelUncompleted: true })
  loadDepartments(
    { getState, setState }: StateContext<DepartmentStateModel>,
    { filter }: LoadDepartments
  ) {
    return this.service.getDepartments(filter).pipe(
      tap((response: ApiResponse<IDepartment[]>) => {
        const state = getState();
        setState({
          ...state,
          departments: response.data,
          departmentsTotalPages: response.totalPages,
          selectedDepartment: response.data[0],
        });
      })
    );
  }

  @Action(LoadAllDepartments, { cancelUncompleted: true })
  loadAllDepartments(ctx: StateContext<DepartmentStateModel>) {
    return this.service.getAllDepartments().pipe(
      tap((allDepartments: IdName[]) => {
        ctx.setState(patch({ allDepartments }));
      })
    );
  }

  @Action(LoadDepartmentsAccessedByEmployment, { cancelUncompleted: true })
  loadAccessedByEmployment(ctx: StateContext<DepartmentStateModel>) {
    return this.service.getAccessedByEmployment().pipe(
      tap((accessedByEmployment: IdName[]) => {
        ctx.setState(patch({ accessedByEmployment }));
      })
    );
  }

  @Action(LoadAllDepartmentsForSelectedTenant, { cancelUncompleted: true })
  loadAllDepartmentsForSelectedTenant(
    ctx: StateContext<DepartmentStateModel>,
    { tenantId }: LoadAllDepartmentsForSelectedTenant
  ) {
    return this.service.getAllDepartmentsForSelectedTenant(tenantId).pipe(
      tap((allDepartmentsForSelectedTenant: IdName[]) => {
        ctx.setState(patch({ allDepartmentsForSelectedTenant }));
      })
    );
  }

  @Action(LoadAllDepartmentsForMenuId, { cancelUncompleted: true })
  loadAllDepartmentsForMenuId(
    ctx: StateContext<DepartmentStateModel>,
    { menuId }: LoadAllDepartmentsForMenuId
  ) {
    return this.service.getAllDepartmentsForMenuId(menuId).pipe(
      tap((departmentsForMenuId: IdName[]) => {
        ctx.setState(patch({ departmentsForMenuId }));
      })
    );
  }

  @Action(LoadDepartmentsForUser, { cancelUncompleted: true })
  loadDepartmentsForUser({ getState, setState }: StateContext<DepartmentStateModel>) {
    return this.service.getDepartmentsForUser().pipe(
      tap((response: IDepartmentWithGroups[]) => {
        const state = getState();
        setState({
          ...state,
          departmentsForUser: response,
        });
      })
    );
  }

  @Action(LoadUsersForSelectedDepartment, { cancelUncompleted: true })
  loadUsersForDepartment({ getState, setState }: StateContext<DepartmentStateModel>) {
    return this.service.getSelectedDepartmentUsers(false).pipe(
      tap((response: IDepartmentUser[]) => {
        const state = getState();
        setState({
          ...state,
          departmentUsers: response,
        });
      })
    );
  }

  @Action(LoadDepartmentById, { cancelUncompleted: true })
  loadDepartmentById(
    { getState, setState }: StateContext<DepartmentStateModel>,
    { id }: LoadDepartmentById
  ) {
    const departmentsFromState = getState().departments;
    if (departmentsFromState?.find((department) => department.id === id)) {
      return this.service.getDepartmentById(id).pipe(
        tap((department) => {
          setState(
            patch({
              departments: updateItem<IDepartment>(
                (updatedDepartment: IDepartment | undefined) =>
                  updatedDepartment?.id === department.id,
                department
              ),
              currentDepartment: department,
            })
          );
        })
      );
    }

    return this.service.getDepartmentById(id).pipe(
      tap((department: IDepartment) => {
        setState(
          patch({
            departments: insertItem<IDepartment>(department),
            currentDepartment: department,
          })
        );
      })
    );
  }

  @Action(LoadDepartmentOptions, { cancelUncompleted: true })
  loadDepartmentOptions({ getState, setState }: StateContext<DepartmentStateModel>) {
    return this.depOptionsService.getDepartmentOptions().pipe(
      tap((response: IDepartmentOptions) => {
        const state = getState();
        setState({
          ...state,
          departmentOptionsLoaded: true,
          options: response,
        });
      })
    );
  }

  @Action(LoadDepartmentList, { cancelUncompleted: true })
  loadDepartmentList({ getState, setState }: StateContext<DepartmentStateModel>) {
    return this.depOptionsService.getDepartmentList().pipe(
      tap((response: IdName[]) => {
        const state = getState();
        setState({
          ...state,
          departmentList: response,
        });
      })
    );
  }

  @Action(CreateDepartment, { cancelUncompleted: true })
  @OnSuccess({ url: AdminDepartmentRoutes.Main, message: 'departmentCreated' })
  createDepartment(ctx: StateContext<DepartmentStateModel>, { department }: CreateDepartment) {
    return this.service.createDepartment(department);
  }

  @Action(UpdateDepartment, { cancelUncompleted: true })
  @OnSuccess({ url: AdminDepartmentRoutes.Main, message: 'departmentUpdated' })
  updateDepartment(ctx: StateContext<DepartmentStateModel>, { department }: UpdateDepartment) {
    return this.service.updateDepartment(department);
  }

  @Action(DeleteDepartment, { cancelUncompleted: true })
  @OnSuccess({ url: AdminDepartmentRoutes.Main, message: 'departmentDeleted' })
  deleteDepartment(ctx: StateContext<DepartmentStateModel>, { departmentId }: DeleteDepartment) {
    return this.service.deleteDepartment(departmentId);
  }

  @Selector()
  static departmentsTotalPages(state: DepartmentStateModel) {
    return state.departmentsTotalPages;
  }

  @Selector()
  static departmentListLoaded(state: DepartmentStateModel) {
    return state.departmentListLoaded;
  }

  @Selector()
  static departmentOptionsLoaded(state: DepartmentStateModel) {
    return state.departmentOptionsLoaded;
  }

  @Selector()
  static getDepartments(state: DepartmentStateModel) {
    return state.departments;
  }

  @Selector()
  static getAllDepartments(state: DepartmentStateModel): IdName[] {
    return state.allDepartments;
  }

  @Selector()
  static getDepartmentsAccessedByEmployment(state: DepartmentStateModel): IdName[] {
    return state.accessedByEmployment;
  }

  @Selector()
  static getDepartmentsForMenuId(state: DepartmentStateModel): IdName[] {
    return state.departmentsForMenuId;
  }

  @Selector()
  static getDepartmentsForUser(state: DepartmentStateModel) {
    return state.departmentsForUser;
  }

  @Selector()
  static getSelectedDepartment(state: DepartmentStateModel): IDepartment | undefined {
    return state.selectedDepartment;
  }

  @Selector()
  static getDepartmentsNameList(state: DepartmentStateModel) {
    return state.departmentList;
  }

  @Selector()
  static departmentOptions(state: DepartmentStateModel) {
    return state.options as IDepartmentOptions;
  }

  @Selector()
  static getDepartmentById(state: DepartmentStateModel) {
    return (id: string) => {
      return state.departments?.find((department) => department.id === id);
    };
  }

  @Selector()
  static getCurrentDepartment(state: DepartmentStateModel) {
    return state.currentDepartment;
  }

  @Selector()
  static getUsersForSelectedDepartment(state: DepartmentStateModel) {
    return state.departmentUsers;
  }

  @Selector()
  static getAllDepartmentsForSelectedTenant(state: DepartmentStateModel) {
    return state.allDepartmentsForSelectedTenant;
  }
}
