/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { NonConformanceStatus } from '../enums/non-conformance-status.enum';
import { IDelegateNonConformanceRequest } from '../models/delegate-non-conformance-request';
import { IdComment } from '../models/id-comment.model';
import { INonConformanceRequest } from '../models/non-conformance.model';
import { IPrintNonConformanceModel } from '../models/print-non-conformance.model';
import { ISignNonConformanceModel } from '../models/sign-non-conformance.model';

export class LoadNonConformances {
  static readonly type = '[Non Conformance] Load Non Conformances';
  constructor(public state?: NonConformanceStatus, public filter?: MainTableFilterModel) {}
}

export class LoadNonConformancesForIO {
  static readonly type = '[Non Conformance] Load Non Conformances for IO';
  constructor() {}
}

export class CreateNonConformance {
  static readonly type = '[Non Conformance] Create Non Conformance';
  constructor(public nonConformance: INonConformanceRequest) {}
}

export class UpdateNonConformance {
  static readonly type = '[Non Conformance] Update Non Conformance';
  constructor(public nonConformance: INonConformanceRequest) {}
}

export class GetNonConformanceById {
  static readonly type = '[Non Conformance] Get Non Conformance By Id';
  constructor(public id: string) {}
}

export class DeleteNonConformance {
  static readonly type = '[Non Conformance] Delete Non Conformance';
  constructor(public id: string) {}
}

export class DelegateNonConformance {
  static readonly type = '[Non Conformance] Delegate Non Conformance';
  constructor(public delegateNonConformance: IDelegateNonConformanceRequest) {}
}

export class CloseNonConformance {
  static readonly type = '[Non Conformance] Close Non Conformance';
  constructor(public idComment: IdComment) {}
}

export class ReopenNonConformance {
  static readonly type = '[Non Conformance] Reopen Non Conformance';
  constructor(public idComment: IdComment) {}
}

export class CompleteNonConformance {
  static readonly type = '[Non Conformance] Complete Non Conformance';
  constructor(public idComment: IdComment) {}
}

export class RejectNonConformance {
  static readonly type = '[Non Conformance] Reject Non Conformance';
  constructor(public idComment: IdComment) {}
}

export class RestoreNonConformance {
  static readonly type = '[Non Conformance] Restore Non Conformance';
  constructor(public id: string) {}
}

export class LoadNonConformanceLogs {
  static readonly type = '[Non Conformance] Load Non Conformance Logs';
  constructor(public id: string) {}
}

export class PrintNonConformance {
  static readonly type = '[Non Conformance] Print Non Conformance';
  constructor(public printNonConformance: IPrintNonConformanceModel) {}
}

export class ClearCurrentNonConformance {
  static readonly type = '[Non Conformance] Clear Current Non Conformance';
}

export class SignCompletedNonConformance {
  static readonly type = '[Non Conformance] Sign Completed Non Conformance';
  constructor(public signNonConformance: ISignNonConformanceModel) {}
}
