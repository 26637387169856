/* eslint-disable max-classes-per-file */
import { ICompetenceMatrixNotifications, ICompetenceMatrixPrint } from './competence-matrix.model';

export class LoadCompetenceMatrix {
  static readonly type = '[Competence Matrix] Load Competence Matrix';
  constructor(public params: any = {}, public adminPanel = false) {}
}

export class PrintCompetenceMatrix {
  static readonly type = '[Competence Matrix] Print Competence Matrix';
  constructor(public config: ICompetenceMatrixPrint) {}
}

export class SendCompetenceMatrixNotifications {
  static readonly type = '[Competence Matrix] Send Competence Matrix Notifications';
  constructor(public config: ICompetenceMatrixNotifications) {}
}
