import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { HashtagModel } from '../models/hashtag.model';
import { SearchResultModel } from '../../core/models/search-result.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HashtagApiService {
  private get baseUrl() {
    return `${environment.apiUrl}/api/${getTenantId()}`;
  }

  constructor(private http: HttpClient) {}

  fetchHashtags(): Observable<HashtagModel[]> {
    // TODO: uncomment this when needed
    // const url = `${this.baseUrl}/hashtags`;
    // return this.http.get<HashtagModel[]>(url);
    return of([]);
  }

  getHashtagResultByString(id: string): Observable<SearchResultModel[]> {
    const url = `${this.baseUrl}/hashtags/result/${id}`;
    return this.http.get<SearchResultModel[]>(url);
  }
}
