<div>
  <h3>{{ 'pleaseChooseWhatYouWantToCreate' | translate }}</h3>
  <span>
    <app-ui-button
      [text]="'component' | translate"
      (click)="createComponent()"
      privilege="COMPONENT_CREATE"
    ></app-ui-button
    ><app-ui-button
      [text]="'componentGroup' | translate"
      (click)="createComponentGroup()"
      privilege="COMPONENT-GROUP_CREATE"
    ></app-ui-button>
  </span>
</div>
