import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { maxLengthValidator } from '../functions/max-length-validator';
import { IAddress } from '../models/address.model';

export class AddressForm extends FormGroup {
  readonly country = this.get('country') as FormControl<string>;
  readonly zip = this.get('zip') as FormControl<string>;
  readonly city = this.get('city') as FormControl<string>;
  readonly address = this.get('address') as FormControl<string>;

  constructor(address?: IAddress, readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        country: [address ? address.country : '', maxLengthValidator],
        zip: [address ? address.zip : '', maxLengthValidator],
        city: [address ? address.city : '', maxLengthValidator],
        address: [address ? address.address : '', maxLengthValidator],
      }).controls
    );
  }
}
