export enum AlertStatus {
  Ok = 1,
  Expired = 2,
  Expiring = 3,
}

export const ALERT_STATUS_TITLES: any = {
  [AlertStatus.Ok]: 'ok',
  [AlertStatus.Expired]: 'expired',
  [AlertStatus.Expiring]: 'expiring',
};
