import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserTasksRoutes } from 'src/app/core/enums/routes.enum';
import { TaskActionType } from 'src/app/task-user/components/enum/task-action-type';

@Component({
  selector: 'app-task-log-template',
  templateUrl: './task-log-template.component.html',
  styleUrls: ['./task-log-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskLogTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  taskActionTypes = TaskActionType;
  performViewRoute = UserTasksRoutes.PerformView;
}
