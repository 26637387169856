import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TenantComponent } from './tenant.component';
import { TenantResolver } from './tenant.resolver';
import { TenantDetailComponent } from './components/tenant-detail/tenant-detail.component';
import { TenantViewComponent } from './components/tenant-view/tenant-view.component';

const usersRoutes: Routes = [
  {
    path: '',
    component: TenantComponent,
    resolve: {
      tenants: TenantResolver,
    },
    children: [
      {
        path: '',
        component: TenantViewComponent,
      },
      {
        path: ':id',
        component: TenantDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(usersRoutes)],
  exports: [RouterModule],
})
export class TenantRoutingModule {}
