/* eslint-disable no-case-declarations */
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  UserCertificateRoutes,
  UserDocumentsRoutes,
  UserDrillsRoutes,
  UserMaintenanceRoutes,
  UserMedicineRoutes,
  UserNonConformancesRoutes,
  UserProfileRoutes,
  UserRiskAssessmentRoutes,
  UserRunTimeRoutes,
  UserTasksRoutes,
} from 'src/app/core/enums/routes.enum';
import { IUserAccess } from 'src/app/shared/user-view/models/tenant-user-access.model';
import {
  ALL_DEPS_TOKEN,
  TenantUserAccessState,
} from 'src/app/tenant-selector/state/tenant-user-access.state';
import {
  DOCUMENT_STATUS_LABELS,
  DocumentStatus,
} from 'src/app/document-user/models/document-status.enum';
import { IDueListCompetence, IDueListItem } from '../models/due-list-item.model';
import { ChangeCurrentSelectedDepartment } from '../../tenant-selector/state/tenant-user-access.actions';

@Injectable({
  providedIn: 'root',
})
export class DueListRoutingLogicService {
  userAccess$: Observable<IUserAccess> = this.store.select(TenantUserAccessState.userAccess);
  currentUserId: string;

  constructor(private router: Router, private store: Store) {
    this.userAccess$.subscribe((userAccess: IUserAccess) => {
      this.currentUserId = userAccess?.id;
    });
  }

  findAppropriateItem(id: string, items: IDueListItem[]): void {
    const item = items.find((i: IDueListItem) => i.id === id) as IDueListItem;
    this.routeToAppropriateItem(item);
  }

  routeToAppropriateItem(item: IDueListItem): void {
    switch (item.category) {
      case 'Certificate':
        this.navigateToItem(`${UserCertificateRoutes.Main}/${item.id}`, item.departmentId);
        break;
      case 'Medicine':
        this.navigateToItem(`${UserMedicineRoutes.Overview}/${item.id}`, item.departmentId);
        break;
      case 'MaintenanceJob':
        this.navigateToItem(
          `${UserMaintenanceRoutes.Component}/${item.componentId}/jobs/${item.id}`,
          item.departmentId
        );
        break;
      case 'Runtime':
        this.navigateToItem(`${UserRunTimeRoutes.Edit}/${item.id}`, item.departmentId);
        break;
      case 'Document':
        const statusRoute =
          item.status.toLowerCase() === DOCUMENT_STATUS_LABELS[DocumentStatus.Expired] ||
          item.status.toLowerCase() === DOCUMENT_STATUS_LABELS[DocumentStatus.Expiring]
            ? DOCUMENT_STATUS_LABELS[DocumentStatus.Published]
            : item.status;
        this.navigateToItem(
          `${UserDocumentsRoutes.Main}/${statusRoute}/${item.id}`,
          item.departmentId
        );
        break;
      case 'NonConformance':
        this.navigateToItem(
          UserNonConformancesRoutes.ShowOverview.replace(':id', item.id),
          item.departmentId
        );
        break;
      case 'UserCompetence':
        const route =
          (item as IDueListCompetence).isRegistrationCompleted &&
          (item as IDueListCompetence).hasUserThisCompetence
            ? UserProfileRoutes.CompetenceUser
            : UserProfileRoutes.CompetenceUserRegister;

        this.navigateToItem(`${route}${this.currentUserId}/${item.id}`, item.departmentId);
        break;
      case 'Drill':
        this.navigateToItem(`${UserDrillsRoutes.Edit}/${item.id}`, item.departmentId);
        break;
      case 'Work':
        this.navigateToItem(`${UserTasksRoutes.Edit}/${item.id}`, item.departmentId);
        break;
      case 'RiskAssessment':
        this.navigateToItem(`${UserRiskAssessmentRoutes.Overview}/${item.id}`, item.departmentId);
        break;
      default:
        throw new Error();
    }
  }

  changeSelectedDepartment(departmentId: string) {
    this.store.dispatch(new ChangeCurrentSelectedDepartment(departmentId, false));
  }

  navigateToItem(url: string, departmentId?: string) {
    if (
      this.store.selectSnapshot(TenantUserAccessState.currentDepartmentId) === ALL_DEPS_TOKEN &&
      departmentId
    ) {
      this.changeSelectedDepartment(departmentId);
    }
    this.router.navigateByUrl(url);
  }
}
