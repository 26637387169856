import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export class CopyMenuTreeForm extends FormGroup {
  readonly departmentId = this.get('departmentId') as FormControl<string>;
  readonly targetDepartmentIds = this.get('targetDepartmentIds') as FormControl<string[]>;
  readonly targetTenantId = this.get('targetTenantId') as FormControl<string>;
  readonly module = this.get('module') as FormControl<number>;
  readonly copyContent = this.get('copyContent') as FormControl<boolean>;

  constructor(
    departmentId: string,
    moduleNumber: number,
    menuId?: string,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group({
        menuId: [menuId],
        departmentId: [departmentId, Validators.required],
        targetTenantId: ['', Validators.required],
        targetDepartmentIds: ['', Validators.required],
        module: [moduleNumber, Validators.required],
        copyContent: [false],
      }).controls
    );
  }
}
