import { Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive()
export abstract class AbstractDepartmentTemplateComponent<F extends FormGroup> {
  @Input() columnName: string;
  @Input() set element(value: F) {
    this.form = value;
  }
  @Input() buttonFunctions: { [key: string]: () => void };
  @Input() hideButtons: boolean;

  form: F;
}
