import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { AdminCompetenceRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from 'src/app/shared/models/id-name';
import { IJobPositionGroupModel } from '../model/job-position-group.model';
import { JobPositionGroupApiService } from '../services/job-position-group-api.service';
import {
  AddJobPositionGroup,
  DeleteJobPositionGroup,
  GetJobPositionGroupById,
  LoadAllJobPositionGroups,
  LoadAllJobPositionGroupsForDepartment,
  LoadJobPositionGroups,
  UpdateJobPositionGroup,
} from './job-position-group.actions';

export interface JobPositionGroupStateModel {
  loaded: boolean;
  positionGroups: IJobPositionGroupModel[] | undefined;
  allPositionGroups: IdName[];
  jobPositionGroupsForDepartment: IdName[] | undefined;
  jobPositionGroupsTotalPages: number;
}

const JOB_POSITION_GROUP_STATE_TOKEN = new StateToken<JobPositionGroupStateModel>(
  'jobPositionGroupState'
);

@State<JobPositionGroupStateModel>({
  name: JOB_POSITION_GROUP_STATE_TOKEN,
  defaults: {
    loaded: false,
    positionGroups: undefined,
    allPositionGroups: [],
    jobPositionGroupsForDepartment: undefined,
    jobPositionGroupsTotalPages: 0,
  },
})
@Injectable()
export class JobPositionGroupState {
  constructor(private service: JobPositionGroupApiService) {}

  @Action(LoadJobPositionGroups, { cancelUncompleted: true })
  LoadJobPositionGroups(
    { getState, setState }: StateContext<JobPositionGroupStateModel>,
    { filter }: LoadJobPositionGroups
  ) {
    return this.service.getJobPositionGroups(filter).pipe(
      tap((response: ApiResponse<IJobPositionGroupModel[]>) => {
        const state = getState();
        setState({
          ...state,
          positionGroups: response.data,
          jobPositionGroupsTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllJobPositionGroups, { cancelUncompleted: true })
  loadAllJobPositionGroups(ctx: StateContext<JobPositionGroupStateModel>) {
    return this.service.getAllJobPositionGroups().pipe(
      tap((allPositionGroups: IdName[]) => {
        ctx.setState(patch({ allPositionGroups }));
      })
    );
  }

  @Action(LoadAllJobPositionGroupsForDepartment, { cancelUncompleted: true })
  loadAllJobPositionsForDepartment({
    getState,
    setState,
  }: StateContext<JobPositionGroupStateModel>) {
    return this.service.getAllJobPositionGroupsForDepartment().pipe(
      tap((response: IdName[]) => {
        const state = getState();
        setState({
          ...state,
          jobPositionGroupsForDepartment: response,
        });
      })
    );
  }

  @Action(AddJobPositionGroup, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCompetenceRoutes.JobPositionGroups, message: 'jobPositionGroupCreated' })
  addJobPositionGroup(
    ctx: StateContext<JobPositionGroupStateModel>,
    { jobPositionGroup }: AddJobPositionGroup
  ) {
    return this.service.addJobPositionGroup(jobPositionGroup).pipe(
      tap((response: IJobPositionGroupModel) => {
        ctx.setState(
          patch({
            positionGroups: append([response]),
          })
        );
      })
    );
  }

  @Action(GetJobPositionGroupById, { cancelUncompleted: true })
  getJobPositionGroupById(
    ctx: StateContext<JobPositionGroupStateModel>,
    { id }: GetJobPositionGroupById
  ) {
    // getting every existing job position group from state
    const competenceGroupsFromState = ctx.getState().positionGroups;
    // if a job position group exist then just get updated (latest) from api
    if (competenceGroupsFromState?.find((cg: IJobPositionGroupModel) => cg.id === id)) {
      return this.service.getJobPositionGroupById(id).pipe(
        tap((response: IJobPositionGroupModel) => {
          ctx.setState(
            patch({
              positionGroups: updateItem<IJobPositionGroupModel>(
                (toGet: IJobPositionGroupModel | undefined) => toGet?.id === response.id,
                response
              ),
            })
          );
        })
      );
    }
    // if it does not exist then fetch it from the api
    return this.service.getJobPositionGroupById(id).pipe(
      tap((response: IJobPositionGroupModel) => {
        ctx.setState(
          patch({
            positionGroups: insertItem<IJobPositionGroupModel>(response),
          })
        );
      })
    );
  }

  @Action(UpdateJobPositionGroup, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCompetenceRoutes.JobPositionGroups, message: 'jobPositionGroupUpdated' })
  updateJobPositionGroup(
    ctx: StateContext<JobPositionGroupStateModel>,
    { jobPositionGroup }: UpdateJobPositionGroup
  ) {
    return this.service.updateJobPositionGroup(jobPositionGroup).pipe(
      tap((response: IJobPositionGroupModel) => {
        ctx.setState(
          patch({
            positionGroups: updateItem<IJobPositionGroupModel>(
              (toUpdate: IJobPositionGroupModel | undefined) => toUpdate?.id === response.id,
              response
            ),
          })
        );
      })
    );
  }

  @Action(DeleteJobPositionGroup, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCompetenceRoutes.JobPositionGroups, message: 'jobPositionGroupDeleted' })
  deleteJobPositionGroup(
    ctx: StateContext<JobPositionGroupStateModel>,
    { id }: DeleteJobPositionGroup
  ) {
    return this.service.deleteJobPositionGroup(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            positionGroups: removeItem<IJobPositionGroupModel>(
              (jobPosition: IJobPositionGroupModel | undefined) => jobPosition?.id === id
            ),
          })
        );
      })
    );
  }

  @Selector()
  static jobPositionGroupsLoaded(state: JobPositionGroupStateModel) {
    return state.loaded;
  }

  @Selector()
  static getJobPositionGroupsTotalPages(state: JobPositionGroupStateModel) {
    return state.jobPositionGroupsTotalPages;
  }

  @Selector()
  static getJobPositionGroups(state: JobPositionGroupStateModel) {
    return state.positionGroups;
  }

  @Selector()
  static getAllJobPositionGroups(state: JobPositionGroupStateModel) {
    return state.allPositionGroups;
  }

  @Selector()
  static getJobPositionGroupsForDepartment(state: JobPositionGroupStateModel) {
    return state.jobPositionGroupsForDepartment;
  }

  @Selector()
  static getJobPositionGroupById(state: JobPositionGroupStateModel) {
    return (id: string) => {
      const x = state.positionGroups?.find(
        (positions: IJobPositionGroupModel) => positions.id === id
      );
      return x;
    };
  }
}
