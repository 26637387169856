// eslint-disable-next-line max-classes-per-file
import { IJobPositionGroupModel } from 'src/app/competence/model/job-position-group.model';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadJobPositionGroups {
  static readonly type = '[Job Position Group] Fetch Job Position Groups';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllJobPositionGroups {
  static readonly type = '[Job Position Group] Fetch All Job Position Group';
  constructor() {}
}

export class LoadAllJobPositionGroupsForDepartment {
  static readonly type = '[Job Position Group] Fetch All Job Position Groups For Department';
  constructor() {}
}

export class AddJobPositionGroup {
  static readonly type = '[Job Position Group] Add Job Position Group';
  constructor(public jobPositionGroup: IJobPositionGroupModel) {}
}

export class GetJobPositionGroupById {
  static readonly type = '[Job Position Group] Get Job Position Group By Id';
  constructor(public id: string) {}
}

export class UpdateJobPositionGroup {
  static readonly type = '[Job Position Group] Update Job Position Group';
  constructor(public jobPositionGroup: IJobPositionGroupModel) {}
}

export class DeleteJobPositionGroup {
  static readonly type = '[Job Position Group] Delete Job Position Group';
  constructor(public id: string) {}
}
