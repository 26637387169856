import { Component, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { TenantUserPrivilegesService } from 'src/app/tenant-selector/services/tenant-user-privileges.service';

@Component({
  selector: 'app-ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
})
export class UiButtonComponent implements OnDestroy {
  @Input() text = 'button';
  @Input() color: 'primary' | 'warn' | 'accent' = 'primary';
  @Input() disabled: boolean;
  @Input() buttonType: 'raised' | 'mat' | 'fab' | 'icon-button' | 'stroked' | 'secondary' =
    'raised';
  @Input() icon: string;
  @Input() iconColor?: string;
  @Input() actionType: 'button' | 'submit' = 'button';
  @Input() set privilege(privilege: string | string[]) {
    this.hasPermission = this.checkPermission(privilege);
    this.matTooltip = this.hasPermission ? '' : 'noAccess';
  }
  @Input() privilegeStrategy: 'any' | 'all' = 'any';
  @Input() matTooltip: string;

  private hasPermission = true;

  get isDisabled() {
    return this.disabled || !this.hasPermission;
  }

  constructor(
    private elementRef: ElementRef,
    private tenantUserPrivileges: TenantUserPrivilegesService
  ) {
    this.elementRef.nativeElement.addEventListener('click', this.captureClick, true);
  }

  @HostBinding('class.ui-button-disabled') get disabledClass() {
    return this.isDisabled;
  }

  onClick(event: MouseEvent) {
    this.captureClick(event);
  }

  checkPermission(privilege: string | string[]): boolean {
    if (!privilege || (privilege instanceof Array && privilege.length === 0)) {
      return true;
    }
    return this.privilegeStrategy === 'any'
      ? this.tenantUserPrivileges.userHasPrivilege(privilege)
      : this.tenantUserPrivileges.userHasAllPrivileges(
          Array.isArray(privilege) ? privilege : [privilege]
        );
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.removeEventListener('click', this.captureClick, true);
  }

  private captureClick = (event: MouseEvent) => {
    if (this.isDisabled) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }
    return true;
  };
}
