import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuItemModel } from 'src/app/core/models/menu-id.model';

export class NameIconForm extends FormGroup {
  readonly id = this.get('id') as FormControl<string>;
  readonly name = this.get('name') as FormControl<string>;
  readonly icon = this.get('icon') as FormControl<string>;
  readonly parentId = this.get('parentId') as FormControl<string>;

  constructor(menuItem?: MenuItemModel, readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        id: [menuItem ? menuItem.id : ''],
        name: [menuItem ? menuItem.name : '', Validators.required],
        icon: [menuItem ? menuItem.icon : ''],
        parentId: [
          menuItem &&
          menuItem.parentId &&
          menuItem.parentId !== '00000000-0000-0000-0000-000000000000'
            ? menuItem.parentId
            : '1',
          Validators.required,
        ],
      }).controls
    );
  }
}
