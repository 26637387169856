/* eslint-disable no-magic-numbers */
import { Injectable } from '@angular/core';
import { differenceInDays, isBefore } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class MainTableLogicService {
  constructor() {}
  today = new Date();
  expired = 0;
  soonExpired = 14;

  isExpired(expiryDate: string): boolean {
    const expirtyDateObj = new Date(expiryDate);
    const difference = differenceInDays(expirtyDateObj, this.today);

    return isBefore(expirtyDateObj, this.today) && difference < this.expired;
  }

  isSoonExpired(expiryDate: string): boolean {
    const expirtyDateObj = new Date(expiryDate);
    const difference = differenceInDays(expirtyDateObj, this.today);

    return isBefore(this.today, expirtyDateObj) && difference < this.soonExpired;
  }
}
