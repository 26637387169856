import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-runtime-template',
  templateUrl: './runtime-template.component.html',
  styleUrls: ['./runtime-template.component.scss'],
})
export class RuntimeTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
}
