<ng-container [ngSwitch]="specificTemplateName">
  <ng-container *ngSwitchCase="documentTableNames.DocumentStages">
    <app-document-stages-template
      [columnName]="columnName"
      [element]="element"
    ></app-document-stages-template>
  </ng-container>
  <ng-container *ngSwitchCase="documentTableNames.DocumentOverview">
    <app-document-overview-template
      [columnName]="columnName"
      [element]="element"
    ></app-document-overview-template>
  </ng-container>
  <ng-container *ngSwitchCase="documentTableNames.DocumentRevisions">
    <app-document-revisions-template
      [columnName]="columnName"
      [element]="element"
    ></app-document-revisions-template>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-default-cell [columnName]="columnName" [element]="element"></app-default-cell>
  </ng-container>
</ng-container>
