import { ValidationErrors } from '@angular/forms';

export const validationMessages: ValidationErrors = {
  required: 'required',
  minlength: 'minlength',
  maxlength: 'maxlength',
  pattern: 'forbiddenEntry',
  email: 'emailInvalid',
  date: 'dateInvalid',
  min: 'min',
  max: 'max',
  departmentRequiredValidator: 'departmentRequired',
  whitespace: 'noWhitespace',
};
