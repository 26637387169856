import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { MedicineStatus } from 'src/app/medicine-user/enums/medicine-status.enum';
import { IUseMedicine } from 'src/app/medicine-user/models/use-medicine.model';
import { UseMedicine } from 'src/app/medicine-user/state/medicine.actions';
import { ModuleName } from 'src/app/shared/enums/enums';
import { UiPillStatusEnum } from 'src/app/shared/enums/ui-pill-status.enum';
import { UseStockModalComponent } from 'src/app/shared/stock-module/use-stock-modal/use-stock-modal.component';

@Component({
  selector: 'app-medicine-template',
  templateUrl: './medicine-template.component.html',
  styleUrls: ['./medicine-template.component.scss'],
})
export class MedicineTemplateComponent implements OnInit {
  @Input() columnName: string;
  @Input() element: any;

  statusColor: string;

  trackBy = (index: number) => index;

  private statusMapper = {
    [MedicineStatus.Green]: UiPillStatusEnum.Ok,
    [MedicineStatus.Yellow]: UiPillStatusEnum.Expiring,
    [MedicineStatus.Red]: UiPillStatusEnum.Expired,
  };

  constructor(private dialog: MatDialog, private store: Store) {}

  ngOnInit(): void {
    this.statusColor = this.statusMapper[this.element.status as MedicineStatus];
  }

  use(): void {
    this.dialog
      .open(UseStockModalComponent, {
        width: '30rem',
        data: {
          id: `${this.element.id}`,
          currentStock: `${this.element.currentQuantity}`,
          module: ModuleName.MEDICINE,
        },
      })
      .afterClosed()
      .subscribe((result: IUseMedicine) => {
        if (result) {
          this.store.dispatch(new UseMedicine(result));
        }
      });
  }
}
