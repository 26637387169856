import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { environment } from 'src/environments/environment';
import { UserCompetenceRequirementType } from 'src/app/shared/enums/enums';
import {
  ICompetenceMatrix,
  ICompetenceMatrixNotifications,
  ICompetenceMatrixPrint,
} from './competence-matrix.model';

const DEFAULT_PARAMS = { excludedRequirements: [UserCompetenceRequirementType.notRequired] };

@Injectable({
  providedIn: 'root',
})
export class CompetenceMatrixApiService {
  private get baseUrl(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/Competence/Matrix`;
  }

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) {}

  getMatrix(params: any, adminPanel: boolean): Observable<ICompetenceMatrix> {
    const url = `${this.baseUrl}${adminPanel ? '/admin' : ''}`;
    return this.http.get<ICompetenceMatrix>(url, {
      ...this.httpOptions,
      params: this.processParams(params),
    });
  }

  printMatrixAsPdf(params: ICompetenceMatrixPrint): Observable<Blob> {
    const url = `${this.baseUrl}/pdf`;
    return this.http.get(url, {
      responseType: 'blob',
      params: this.processParams(params),
    });
  }

  sendMatrixNotifications(config: ICompetenceMatrixNotifications): Observable<void> {
    const url = `${this.baseUrl}/notifications`;
    return this.http.post<void>(url, config);
  }

  private processParams(object: any): any {
    const params = { ...object };
    if (Array.isArray(params.excludedRequirements)) {
      params.excludedRequirements.push(UserCompetenceRequirementType.notRequired);
    } else {
      params.excludedRequirements = [UserCompetenceRequirementType.notRequired];
    }

    return params;
  }
}
