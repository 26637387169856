import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { MenuIdModel } from 'src/app/core/models/menu-id.model';
import { MenuPlacementService } from '../service/menu-placement-api.service';
import { LoadMenuPlacement } from './menu-placement-id.actions';

export interface MenuPlacementStateModel {
  menuLoaded: boolean;
  menuIds: MenuIdModel[];
}

const MENU_PLACEMENT_STATE_TOKEN = new StateToken<MenuPlacementStateModel>('menuPlacement');

@State<MenuPlacementStateModel>({
  name: MENU_PLACEMENT_STATE_TOKEN,
  defaults: {
    menuLoaded: false,
    menuIds: [],
  },
})
@Injectable()
export class MenuPlacementIdState {
  constructor(private service: MenuPlacementService) {}

  @Action(LoadMenuPlacement, { cancelUncompleted: true })
  LoadMenuPlacement({ getState, setState }: StateContext<MenuPlacementStateModel>) {
    return this.service.getAllMenuIds().pipe(
      tap((menuIds: MenuIdModel[]) => {
        const state = getState();
        setState({
          ...state,
          menuLoaded: true,
          menuIds,
        });
      })
    );
  }

  @Selector()
  static menuPlacementLoaded(state: MenuPlacementStateModel) {
    return state.menuLoaded;
  }

  @Selector()
  static getAllMenuIds(state: MenuPlacementStateModel) {
    return state.menuIds;
  }
}
