<mat-form-field appearance="outline" class="example-full-width mat-mdc-textarea">
  <mat-label>{{ label }}</mat-label>
  <textarea
    matInput
    [formControl]="control"
    [required]="required"
    [placeholder]="placeholder"
    cdkTextareaAutosize
    cdkAutosizeMinRows="2"
    cdkAutosizeMaxRows="8"
  >
  </textarea>
</mat-form-field>
