/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ITaskCreate, ITaskPerformRequest, ITaskPostpone } from '../models/task.model';

export class LoadTasks {
  static readonly type = '[Tasks] Load Tasks';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadTaskById {
  static readonly type = '[Tasks] Load Task by id';
  constructor(public taskId: string) {}
}

export class LoadTaskLog {
  static readonly type = '[Tasks] Load Task log by id';
  constructor(public taskId: string) {}
}

export class LoadTaskVersion {
  static readonly type = '[Tasks] Load Task version by id';
  constructor(public taskId: string, public taskVersionId: string) {}
}

export class LoadTasksForIO {
  static readonly type = '[Tasks] Load Tasks for IO via Service';
  constructor() {}
}

export class CreateTask {
  static readonly type = '[Tasks] Create Task';
  constructor(public task: ITaskCreate) {}
}

export class UpdateTask {
  static readonly type = '[Tasks] Update Task';
  constructor(public task: ITaskCreate) {}
}

export class DeleteTask {
  static readonly type = '[Tasks] Delete Task';
  constructor(public taskId: string) {}
}

export class PostponeTask {
  static readonly type = '[Tasks] Postpone Task';
  constructor(public taskPostpone: ITaskPostpone) {}
}

export class PerformTask {
  static readonly type = '[Tasks] Perform Task';
  constructor(public task: ITaskPerformRequest) {}
}

export class RestoreTask {
  static readonly type = '[Tasks] Restore Task';
  constructor(public id: string) {}
}

export class LoadTaskDraftPerform {
  static readonly type = '[Tasks] Load Task Draft Perform';
  constructor(public taskId: string) {}
}

export class SaveTaskDraftPerform {
  static readonly type = '[Tasks] Save Task Draft Perform';
  constructor(public task: ITaskPerformRequest) {}
}
