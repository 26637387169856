/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IDeliveryConditionRequest } from '../models/delivery-condition.model';

export class LoadDeliveryConditions {
  static readonly type = '[Delivery Condition] Load delivery conditions via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllDeliveryConditions {
  static readonly type = '[Delivery Condition] Load All delivery conditions via Service';
  constructor(public departmentId?: string) {}
}

export class LoadDeliveryConditionById {
  static readonly type = '[Delivery Condition] Load a single delivery condition';
  constructor(public id: string) {}
}

export class CreateDeliveryCondition {
  static readonly type = '[Delivery Condition] Create delivery condition';
  constructor(public deliveryCondition: IDeliveryConditionRequest) {}
}

export class UpdateDeliveryCondition {
  static readonly type = '[Delivery Condition] Update delivery condition';
  constructor(public deliveryCondition: IDeliveryConditionRequest) {}
}

export class DeleteDeliveryCondition {
  static readonly type = '[Delivery Condition] Delete delivery condition';
  constructor(public id: string) {}
}
