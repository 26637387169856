import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shouldHeaderBeDisplayed',
  pure: true,
  standalone: true,
})
export class ShouldHeaderBeDisplayedPipe implements PipeTransform {
  headersToHide = [
    'edit',
    'delete',
    'expand',
    'color',
    'checkbox',
    'confirm',
    'view',
    'actionButton',
    'markAsReceived',
    'createFromTemplate',
  ];

  transform(header: string): boolean {
    return !this.headersToHide.includes(header);
  }
}
