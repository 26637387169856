<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'formStatus'">
    {{ 'formStatus_' + element.formStatus | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'version'"> V{{ element.version }} </ng-container>
  <ng-container *ngSwitchCase="'formVersion'"> V{{ element.formVersion }} </ng-container>
  <ng-container *ngSwitchCase="'created'">
    {{ element.created | date: 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'lastUpdate'">
    {{ element.lastUpdate | date: 'yyyy-MM-dd hh:mm:ss' }}
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
