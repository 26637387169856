/* eslint-disable no-magic-numbers */
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Component, EventEmitter, Inject, inject, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import {
  CCOMX_DATE_FORMATS,
  DATE_WITH_TIME_DISPLAY,
  USE_DATE_TIME_FORMAT,
} from '../../../core/ccomx-date-formats';
import { validationMessages } from '../../messages';

@Component({
  selector: 'app-shared-ui-datepicker',
  templateUrl: './ui-datepicker.component.html',
  styleUrls: ['./ui-datepicker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useFactory: () => {
        return inject(USE_DATE_TIME_FORMAT) ? DATE_WITH_TIME_DISPLAY : CCOMX_DATE_FORMATS;
      },
    },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: DATE_WITH_TIME_DISPLAY,
    },
  ],
})
export class UiDatepickerComponent {
  @Input() label = '';
  @Input() description = '';
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() messages = validationMessages;
  @Input() required = true;
  @Input() selected: boolean;
  @Input() min: Date | null;
  @Input() max: Date | null;
  @Input() initialTime: number[];
  @Input() tabIndex = 0;

  @Output() readonly changeHandler: EventEmitter<MatDatepickerInputEvent<Moment>> =
    new EventEmitter<MatDatepickerInputEvent<Moment>>();

  constructor(@Inject(USE_DATE_TIME_FORMAT) public useDateTimeFormat: boolean) {
    const date = new Date();
    this.initialTime = [date.getHours(), date.getMinutes(), date.getSeconds()];
  }
}
