<div class="tenant-selector-main-container-mobile">
  <div class="mobile-dialog-heading mat-dialog-mobile-border-bottom">
    <span class="mobile-heading-title">
      {{ 'userProfile' | translate }}
    </span>
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>

  <div
    class="user-profile-details-mobile mat-dialog-mobile-border-top-and-bottom mobile-dialog-item"
  >
    <div class="profile-picture-section">
      <mat-icon class="dropdown-user-icon" color="primary">account_circle</mat-icon>
    </div>
    <div class="profile-person-details-mobile">
      <span class="dropdown-name-label">{{ tenantUserName }}</span>
      <span class="position-label"> ({{ tenantPosition | translate }})</span>
      <div class="mail-section">
        {{ tenantUserEmail }}
      </div>
    </div>
  </div>

  <div
    class="mobile-user-profile-section user-profile-tenant-mobile mat-dialog-mobile-border-top-and-bottom mobile-dialog-item"
  >
    <div class="section-with-title">
      <span class="section-title-label-third">{{ 'tenant' | translate | uppercase }}</span>
    </div>

    <div
      class="mobile-tenant-selector-container"
      *ngIf="{
        currentTenant: currentTenant$ | async
      } as tenant"
      (click)="changeTenant()"
    >
      <div class="tenant-logo-mobile">
        <img
          width="30"
          height="30"
          [src]="tenant.currentTenant?.id ? (tenant.currentTenant?.logo | appendDomain) : ''"
          class="mobile-tenant-sel-picture"
          alt="tenant logo"
        />
      </div>
      <div class="tenant-details-mobile">
        <span class="mobile-dep-text">{{ tenant.currentTenant?.name }}</span>
        <span class="customer-tag-mobile">{{ 'customer' | translate }}</span>
      </div>
      <div class="tenant-icon-mobile">
        <mat-icon class="mobile-tenant-sel-icon">keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </div>

  <div
    class="mobile-user-profile-section user-profile-settings-mobile mat-dialog-mobile-border-top-and-bottom mobile-dialog-item"
  >
    <div class="section-with-title">
      <span class="section-title-label-third">{{ 'settings' | translate | uppercase }}</span>
    </div>
    <div
      class="dark-mode-toggle-container option-section-mobile"
      (click)="onToggleDarkMode($event)"
    >
      <div class="icon-text-container">
        <mat-icon> bedtime</mat-icon>
        <span class="option-text-mobile">{{ 'darkMode' | translate }}</span>
      </div>
      <mat-slide-toggle [checked]="isDarkMode" appStopClickPropagation></mat-slide-toggle>
    </div>
    <div class="option-section-mobile" (click)="onProfileSettings()">
      <div class="icon-text-container">
        <mat-icon>person</mat-icon>
        <span class="option-text-mobile">
          {{ 'profileSettings' | translate }}
        </span>
      </div>
    </div>
    <div class="option-section-mobile" (click)="changeLanguage()">
      <div class="icon-text-container">
        <mat-icon>language</mat-icon>
        <span class="option-text-mobile">
          {{ 'language' | translate }}
        </span>
        <app-language-selector></app-language-selector>
      </div>
    </div>
  </div>

  <div
    class="user-profile-logout-mobile mat-dialog-mobile-border-top-and-bottom mobile-dialog-item"
  >
    <div class="option-section-mobile" (click)="logout()">
      <div class="icon-text-container">
        <mat-icon>logout</mat-icon>
        <span class="option-text-mobile">
          {{ 'logout' | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="mat-dialog-mobile-border-top"></div>
</div>
