import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IModule } from '../models/tenant.model';
import { IdName } from '../../shared/models/id-name';

export class ModuleForm extends FormGroup {
  readonly id = this.get('id') as FormControl<number>;
  readonly module = this.get('module') as FormControl<string>;
  readonly selected = this.get('selected') as FormControl<boolean>;
  readonly soldFor = this.get('soldFor') as FormControl<number>;
  readonly currency = this.get('currency') as FormControl<string>;

  constructor(
    moduleOption: IdName,
    module?: IModule,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group({
        id: [moduleOption.id],
        module: [moduleOption.name],
        selected: [!!module],
        soldFor: [
          module?.soldFor ? module.soldFor : '',
          [Validators.required, Validators.maxLength(11), Validators.min(0)],
        ],
        currency: [''],
      }).controls
    );
    this.soldFor.disable();
  }

  onModuleSelected(): Observable<boolean> {
    return this.selected.valueChanges.pipe(
      tap((value) => {
        if (value) {
          this.soldFor.enable();
        } else {
          this.soldFor.disable();
        }
      })
    );
  }
}
