<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'created'">
    {{ element.created | date : 'yyyy-MM-dd - HH:mm' }}
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    {{ element.status | documentStatusMap | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'documentVersionComment'">
    {{ element.comment | translate }}
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
