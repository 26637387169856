import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quantity-types-template',
  templateUrl: 'quantity-types-template.component.html',
})
export class QuantityTypesTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
}
