<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill [name]="element[columnName]"></app-ui-pill>
  </ng-container>

  <ng-container *ngSwitchCase="'hasAttachments'">
    <mat-icon *ngIf="element.hasAttachments" [attr.aria-label]="'hasAttachments' | translate">
      attach_file
    </mat-icon>
  </ng-container>

  <div *ngSwitchCase="'lastPerformed'">
    {{ !element['lastWorkDate'] ? '-' : (element['lastWorkDate'] | date : 'yyyy-MM-dd') }}
  </div>

  <ng-container *ngSwitchCase="'expiry'">
    {{ element['expiryDate'] | date : 'yyyy-MM-dd' }}
  </ng-container>

  <ng-container *ngSwitchCase="'actions'">
    <app-ui-button
      buttonType="secondary"
      text="{{ 'perform' | translate }}"
      [routerLink]="performRoute + '/' + element['id']"
      privilege="TASK_PERFORM"
    >
    </app-ui-button>
  </ng-container>
  <ng-container *ngSwitchCase="'restore'">
    <app-ui-button
      appConfirmDialog
      confirmTitle="restore"
      confirmMessage="youAreRestoring"
      confirmButton="primary"
      [confirmEntityName]="element['name']"
      (confirmed)="restore()"
      buttonType="secondary"
      text="{{ 'restore' | translate }}"
      appStopClickPropagation
      privilege="TASK_RESTORE"
    >
    </app-ui-button>
  </ng-container>

  <ng-container *ngSwitchCase="'access'">
    <app-positions-cell [element]="element"></app-positions-cell>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
