<form *appLoader="!form || (actions | actionInProgress | async) === true" [formGroup]="form">
  <div class="form-page-header divider-bottom">
    <h2 *ngIf="!form.id.value; else editHeading">{{ 'addNewTenant' | translate }}</h2>
    <ng-template #editHeading>
      <h2>{{ form.value.name }}</h2>
    </ng-template>
    <div class="buttons-container">
      <app-ui-button
        text="{{ 'cancel' | translate }}"
        buttonType="secondary"
        (click)="reloadTenants()"
        [routerLink]="adminTenantRoutes.Main"
      >
      </app-ui-button>
      <app-ui-button
        *ngIf="form.disabled; else editMode"
        text="{{ 'edit' | translate }}"
        color="primary"
        (click)="form.enableForm()"
        icon="edit"
      >
      </app-ui-button>
      <ng-template #editMode>
        <app-ui-button
          *ngIf="form.id.value"
          appConfirmDialog
          [confirmEntityName]="form.value.name"
          (confirmed)="delete()"
          text="{{ 'delete' | translate }}"
          color="warn"
          buttonType="stroked"
          icon="delete"
        >
        </app-ui-button>
        <app-ui-button
          *ngIf="form.id.value"
          appConfirmDialog
          [confirmEntityName]="form.value.name"
          confirmTitle="deactivate"
          confirmMessage="youAreDeactivating"
          confirmButtonText="deactivate"
          (confirmed)="deactivate()"
          text="{{ 'deactivate' | translate }}"
          color="warn"
          buttonType="stroked"
          icon="hourglass_empty"
        >
        </app-ui-button>
        <app-ui-button
          text="{{ 'save' | translate }}"
          color="primary"
          actionType="submit"
          (click)="save()"
        >
        </app-ui-button>
      </ng-template>
    </div>
  </div>

  <div class="form-cards">
    <ng-container *ngIf="tenantId && form.disabled; else formTemplate">
      <mat-tab-group [selectedIndex]="selectedTab">
        <mat-tab label="{{ 'details' | translate | uppercase }}">
          <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
        </mat-tab>
        <mat-tab label="{{ 'thirdPartyIntegrations' | translate | uppercase }}">
          <app-third-party-integration-list
            [chosenTenantIdFromTableRow]="tenantId"
          ></app-third-party-integration-list>
        </mat-tab>
        <mat-tab label="{{ 'log' | translate | uppercase }}">
          <app-tenant-log></app-tenant-log>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #formTemplate>
      <mat-card *ngIf="!form.disabled">
        <app-ui-attachments
          viewMode="embedded"
          addButtonIcon="add"
          addButtonText="{{ 'importPredefinedValues' | translate }}"
          [attachments]="tempPredefinedFile"
          [editMode]="!form.disabled"
          [passiveMode]="true"
          [disabled]="form.disabled"
        >
        </app-ui-attachments>
      </mat-card>
      <mat-card>
        <h2>{{ 'details' | translate }}</h2>

        <div class="default-thumbnail-container">
          <div *ngIf="logo" class="thumbnail">
            <ng-container *ngIf="logo | isAttachment; else newDefaultThumbnail">
              <img [src]="logo.uri" [alt]="logo.fileName" class="thumbnail-img" />
              <mat-icon *ngIf="form.enabled" (click)="removeLogo()" class="remove-photo-btn"
                >close</mat-icon
              >
            </ng-container>
            <ng-template #newDefaultThumbnail>
              <img [src]="logo" alt="'logo'" class="thumbnail-img" />
              <mat-icon *ngIf="form.enabled" (click)="removeLogo()" class="remove-photo-btn"
                >close</mat-icon
              >
            </ng-template>
          </div>
          <div class="add-photo" [ngClass]="form.disabled ? 'disabled' : ''">
            <button
              #addLogo
              *ngIf="!logo"
              class="add-photo-btn"
              type="button"
              [disabled]="form.disabled"
              (click)="logoInput.click()"
            >
              <mat-icon>add</mat-icon>
              <span>{{ 'addLogo' | translate }}</span>
              <input
                type="file"
                class="cdk-visually-hidden"
                accept="image/png, image/jpeg, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .png"
                (change)="onLogoChange($event)"
                #logoInput
                [multiple]="false"
              />
            </button>
          </div>
        </div>
        <div class="row-container">
          <app-ui-input
            label="{{ 'name' | translate }}"
            [control]="form.name"
            [required]="true"
            [description]="'nameOfTenant' | translate"
          >
          </app-ui-input>
          <app-ui-input
            label="{{ 'number' | translate }}"
            [control]="form.organizationNumber"
            [required]="false"
            [description]="'organizationNumber' | translate"
          >
          </app-ui-input>
        </div>
        <div class="row-container">
          <app-shared-ui-checkbox
            class="implementing-checkbox"
            label="{{ 'Implementing' | translate }}"
            [control]="form.isImplementing"
          ></app-shared-ui-checkbox>
        </div>
        <div class="row-container">
          <app-ui-select
            label="{{ 'soldBy' | translate }}"
            [control]="form.soldBy"
            [required]="true"
            [options]="soldByOptions"
            [isMultiple]="false"
            [description]="'whichCCOMSoldTenant' | translate"
          >
          </app-ui-select>
          <app-ui-select
            label="{{ 'accountType' | translate }}"
            [control]="form.accountType"
            [required]="true"
            [options]="accountTypes"
            [isMultiple]="false"
            [description]="'accountTypeForTenant' | translate"
          >
          </app-ui-select>
        </div>
        <div class="row-container">
          <app-ui-slide-toggle label="{{ 'DPAService' | translate }}" [control]="form.isDPAService">
          </app-ui-slide-toggle>
        </div>
        <div class="row-container">
          <app-ui-select
            [required]="form.isDPARequired"
            label="{{ 'selectDPA' | translate }}"
            [options]="productOwners$ | async"
            [control]="form.selectDPA"
            [description]="'whichUserIsDPAonTenant' | translate"
          >
          </app-ui-select>
        </div>
      </mat-card>
      <mat-card>
        <h2>{{ 'modules' | translate }}</h2>
        <div class="container"></div>
        <ng-container *ngFor="let module of form.modules.controls; trackBy: trackBy">
          <div class="row-container">
            <app-shared-ui-checkbox
              [control]="module.selected"
              [label]="module.module.value | translate"
            ></app-shared-ui-checkbox>
            <app-ui-input
              class="module-price-input"
              type="number"
              label="{{ 'soldFor' | translate }}"
              [control]="module.soldFor"
            >
            </app-ui-input>
          </div>
        </ng-container>
      </mat-card>
      <mat-card>
        <h2>{{ 'address' | translate }}</h2>
        <app-address-form
          [addressForm]="form.address"
          [addressDesc]="'tenantAddressDesc' | translate"
          [cityDesc]="'tenantCityDesc' | translate"
          [zipDesc]="'tenantZipDesc' | translate"
          [countryDesc]="'tenantCountryDesc' | translate"
        >
        </app-address-form>
      </mat-card>
      <mat-card>
        <h2>{{ 'contacts' | translate }}</h2>
        <app-contacts-table
          *ngIf="{ form: form, formArrayName: 'contacts' } as formWithName"
          [form]="formWithName"
          [title]="'contacts'"
          (reloadTable)="reloadForm($event)"
        ></app-contacts-table>
      </mat-card>
    </ng-template>
  </div>
</form>
