import { Component, Input } from '@angular/core';

import { UserDrillsRoutes } from '../../../../../core/enums/routes.enum';

@Component({
  selector: 'app-drills-overview-template',
  templateUrl: './drills-overview-template.component.html',
})
export class DrillsOverviewTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  performRoute = UserDrillsRoutes.Perform;

  constructor() {}
}
