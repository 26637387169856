import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MenuListLogicService } from '../services/menu-list-logic.service';
import { SaveNewMenuState } from './menu-list.actions';

export interface MenuStateModel {
  mainModule: string;
  subModule: string;
  isInAdmin: boolean;
}

export interface MenuListStateModel {
  url: string;
  menuState: MenuStateModel | undefined;
  moduleNumber: number | undefined;
  adminModule: string;
}

const MENU_LIST_STATE_TOKEN = new StateToken<MenuListStateModel>('menuList');

@State<MenuListStateModel>({
  name: MENU_LIST_STATE_TOKEN,
  defaults: {
    url: '',
    menuState: undefined,
    moduleNumber: undefined,
    adminModule: '',
  },
})
@Injectable()
export class MenuListState {
  constructor(private service: MenuListLogicService) {}

  @Action(SaveNewMenuState)
  saveNewMenuState(ctx: StateContext<MenuListStateModel>, { url }: SaveNewMenuState) {
    if (url === '/') {
      const menuState = this.service.getMenuStateModel(url);
      ctx.setState(
        patch({
          url,
          menuState,
          moduleNumber: 0,
          adminModule: '-',
        })
      );
    } else {
      const menuState = this.service.getMenuStateModel(url);
      const moduleNumber = this.service.getModuleNumberFromUrl(url);
      const adminModule = menuState.isInAdmin ? this.service.getModuleNameFromUrl(url) : '-';
      ctx.setState(
        patch({
          url,
          menuState,
          moduleNumber,
          adminModule,
        })
      );
    }
  }

  @Selector()
  static getMenuState(state: MenuListStateModel) {
    return state.menuState;
  }

  @Selector()
  static getModuleNumber(state: MenuListStateModel) {
    return state.moduleNumber;
  }

  @Selector()
  static getAdminInnerNavModuleName(state: MenuListStateModel) {
    return state.adminModule;
  }
}
