import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-ui-text-area',
  templateUrl: './ui-text-area.component.html',
  styleUrls: ['./ui-text-area.component.scss'],
})
export class UiTextAreaComponent {
  @Input() label = '';
  @Input() control: UntypedFormControl;
  @Input() required = false;
  @Input() placeholder = '';
}
