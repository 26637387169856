/* eslint-disable max-classes-per-file */
import { FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { WebHookModel, WebHookReqHeader } from '../models/webhook.model';

export function urlValidator(control: FormControl) {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  const isValid = urlPattern.test(control.value);
  return isValid ? null : { invalidUrl: true };
}

export class WebHookForm extends FormGroup {
  readonly topic = this.get('topic') as FormControl<string>;
  readonly callback = this.get('callback') as FormControl<string>;
  readonly requestHeaders = this.get('requestHeaders') as UntypedFormArray;

  constructor(webhook?: WebHookModel, readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        topic: [webhook ? webhook.topic : '', [Validators.required]],
        callback: [webhook ? webhook.callback : '', [Validators.required, urlValidator]],
        requestHeaders: fb.array([]),
      }).controls
    );
  }
}

export class WebHookHeaderForm extends FormGroup {
  readonly name = this.get('name') as FormControl<string>;
  readonly headerValue = this.get('headerValue') as FormControl<string>;

  constructor(webhookReqHeader?: WebHookReqHeader, readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        name: [webhookReqHeader ? webhookReqHeader.name : ''],
        headerValue: [webhookReqHeader ? webhookReqHeader.value : ''],
      }).controls
    );
  }
}
