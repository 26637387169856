import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-overview-template',
  templateUrl: './document-overview-template.component.html',
})
export class DocumentOverviewTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
}
