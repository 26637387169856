import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileRoutes } from 'src/app/core/enums/routes.enum';

@Component({
  selector: 'app-competence-template',
  templateUrl: './competence-template.component.html',
  styleUrls: ['./competence-template.component.scss'],
})
export class CompetenceTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  @Input() urlForRouting: string;

  userProfileRoutes = UserProfileRoutes;

  constructor(private router: Router) {}

  routeTo(): void {
    if (this.urlForRouting?.length > 0) {
      let id: string;
      if (this.element.id) {
        id = this.element.id;
      } else {
        id = this.element.competenceId;
      }

      this.router.navigate([`${this.urlForRouting}`, id]);
    }
  }
}
