<div [matMenuTriggerFor]="language" class="select-button-container">
  <mat-icon>language</mat-icon>
  <span class="language-value">{{ languageValue | uppercase }}</span>
  <mat-icon class="expand-icon">expand_more</mat-icon>
</div>
<mat-menu #language="matMenu">
  <div
    mat-menu-item
    *ngFor="let language of languages; trackBy: trackByValue"
    class="outer-item-container"
    (click)="setLanguage(language.value)"
  >
    <button mat-button mat-menu>
      <div class="inner-item-container">
        <img src="{{ language.iconSource }}" class="flag-icon" alt="language icons" />
        <span>{{ language.label }}</span>
      </div>
    </button>
  </div>
</mat-menu>
