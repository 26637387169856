import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { IdName } from 'src/app/shared/models/id-name';
import { ModuleApiService } from '../services/module-api.service';
import { LoadModules } from './module.actions';

export interface ModuleStateModel {
  modules: IdName[];
}

const MODULE_STATE_TOKEN = new StateToken<ModuleStateModel>('modules');

@State<ModuleStateModel>({
  name: MODULE_STATE_TOKEN,
  defaults: {
    modules: [],
  },
})
@Injectable()
export class ModuleState {
  constructor(private service: ModuleApiService) {}

  @Action(LoadModules, { cancelUncompleted: true })
  loadModules({ getState, setState }: StateContext<ModuleStateModel>) {
    return this.service.getModules().pipe(
      tap((modules: IdName[]) => {
        const state = getState();
        setState({
          ...state,
          modules,
        });
      })
    );
  }

  @Selector()
  static getModules(state: ModuleStateModel): IdName[] {
    return state.modules;
  }
}
