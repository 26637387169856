/* eslint-disable no-magic-numbers */
export enum NonConformanceAction {
  Created = 'Created',
  Edited = 'Edited',
  Delegated = 'Delegated',
  Closed = 'Closed',
  Completed = 'Completed',
  Reopened = 'Reopened',
  ReportUpdated = 'ReportUpdated',
  ReportCreated = 'ReportCreated',
  Legacy = 'Legacy',
}
