import { MedicineEvent } from 'src/app/medicine-user/enums/medicine-event.enum';

export function mapMedicineEventToString(medicineEvent: MedicineEvent): string {
  const mapper = {
    [MedicineEvent.Created]: 'initialStock',
    [MedicineEvent.Add]: 'add',
    [MedicineEvent.Adjust]: 'adjust',
    [MedicineEvent.Use]: 'use',
    [MedicineEvent.Edit]: 'edit',
  };
  return mapper[medicineEvent];
}
