<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'workPhone'">
    <ng-container *ngIf="element?.contactInfo">
      {{ element?.contactInfo.prefix }} {{ element?.contactInfo.phoneNumber }}
    </ng-container>
    <ng-container *ngIf="!element?.contactInfo">
      {{ element?.prefix }} {{ element?.phoneNumber }}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'email'">
    <ng-container *ngIf="element?.contactInfo?.email">
      {{ element?.contactInfo?.email }}
    </ng-container>
    <ng-container *ngIf="!element?.contactInfo?.email">
      {{ element?.email }}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'edit'">
    <button mat-button (click)="buttonFunctions.edit()">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'delete'">
    <button
      mat-button
      appConfirmDialog
      [confirmEntityName]="element?.name"
      (confirmed)="buttonFunctions.delete()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'quantityType'">
    {{ element.quantityTypeName }}
  </ng-container>
  <ng-container *ngSwitchCase="'type'">
    {{ element.purchaserType | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'totalPrice'">
    {{ element.totalPrice | currency : ' ' : 'symbol' : '1.2-2' }}
    {{ element.currencyName }}
  </ng-container>
  <ng-container *ngSwitchCase="'receivers'">
    <div *ngFor="let jobPosition of element.receiversJobPositions; trackBy: trackBy">
      {{ jobPosition.name }}
    </div>
    <div *ngFor="let jobPositionGroup of element.receiversJobPositionGroups; trackBy: trackBy">
      {{ jobPositionGroup.name }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'approvers'">
    <div *ngFor="let jobPosition of element.approversJobPositions; trackBy: trackBy">
      {{ jobPosition.name }}
    </div>
    <div *ngFor="let jobPositionGroup of element.approversJobPositionGroups; trackBy: trackBy">
      {{ jobPositionGroup.name }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'accounts'">
    <div *ngFor="let account of element.accounts; trackBy: trackBy">
      {{ account.name }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'departments'">
    <mat-icon
      *ngIf="element.departmentsAndGroupsInvalid; else departmentsList"
      matTooltip="{{ 'supplierDepartmentChangedErrorDescription' | translate }}"
      matTooltipClass="tooltip"
      class="warn-icon"
      >warning</mat-icon
    >
    <ng-template #departmentsList>
      <div *ngFor="let department of element.departments; trackBy: trackBy">
        {{ department.name }}
      </div>
      <div *ngFor="let departmentGroup of element.departmentGroups; trackBy: trackBy">
        {{ departmentGroup.name }}
      </div>
      <div *ngFor="let department of element.departmentsToDisplay; trackBy: trackBy">
        {{ department.name }}
      </div>
      <div *ngFor="let departmentGroup of element.departmentGroupsToDisplay; trackBy: trackBy">
        {{ departmentGroup.name }}
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
