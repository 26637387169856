/* eslint-disable no-magic-numbers */
import { IAttachment } from '../../attachments/models/attachments.model';

export enum CompetenceStatusEnum {
  Green = 1,
  Yellow = 2,
  Red = 3,
  Orange = 4,
  White = 5,
}

export interface ICompetenceWithStatus {
  competenceId: string;
  competenceName: string;
  userCompetenceId: string;
  statusType: CompetenceStatusEnum;
  expiresAt: string;
  hasAttachments: boolean;
  daysUntilExpired: number;
}

export interface ICompetenceUserModel {
  attachments: IAttachment[];
  id: string;
  statusType: CompetenceStatusEnum;
  competenceName: string;
  competenceCode: string;
  formId: string;
  competenceId: string;
  requirementType: number;
  isExpiring: boolean;
  expiresAt: Date;
  monthsUntilExpired: string;
  alert: string;
  isRegistrationCompleted: boolean;
  comment: string;
  issuedDate: Date;
  userCompetenceId: string;
  daysUntilExpired: 0;
  hasUserThisCompetence: boolean;
  hasAttachments: boolean;
  competencesFittingRequirements: ICompetenceWithStatus[];
  equivalentCompetences: ICompetenceWithStatus[];
}
