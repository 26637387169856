import { Subscription } from 'rxjs';
import {
  ComponentRef,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { TenantUserPrivilegesService } from 'src/app/tenant-selector/services/tenant-user-privileges.service';
import { LoaderComponent } from '../components/loader/loader.component';
import { MissingAccessComponent } from '../components/missing-access/missing-access.component';

@Directive({
  selector: '[appLoader]',
  standalone: true,
})
export class AppLoaderDirective implements OnDestroy {
  loadingComponent: ComponentRef<LoaderComponent>;
  routerSub: Subscription | null = null;

  @Input()
  set appLoader(loading: boolean) {
    this.vcRef.clear();

    if (loading) {
      if (this.canUserBeHere()) {
        // create and embed an instance of the loading component
        this.loadingComponent = this.vcRef.createComponent(LoaderComponent);
      } else {
        this.loadingComponent = this.vcRef.createComponent(MissingAccessComponent);
      }
    } else {
      // embed the contents of the host template
      this.routerSub?.unsubscribe();
      this.vcRef.createEmbeddedView(this.templateRef);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef,
    private router: Router,
    private tenantUserPrivileges: TenantUserPrivilegesService
  ) {}

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
  }

  private canUserBeHere(): boolean {
    const url = this.router.url.split('/')[1];
    switch (url) {
      case 'profile':
        return !!this.checkPermission('USER-COMPETENCE_READ');
      case 'maintenance':
        return !!this.checkPermission('MAINTENANCE-JOB_READ');
      case 'documents':
        return !!this.checkPermission('DOCUMENT_READ');
      case 'drills':
        return !!this.checkPermission('DRILL_READ');
      case 'tasks':
        return !!this.checkPermission('TASK_READ');
      case 'certificates':
        return !!this.checkPermission('CERTIFICATE_READ');
      case 'non-conformances':
        return !!this.checkPermission('NON-CONFORMANCE_READ');
      case 'run-time':
        return !!this.checkPermission('RUNTIME_READ');
      default:
        return true;
    }
  }

  private checkPermission(privilege: string): boolean {
    return this.tenantUserPrivileges.userHasPrivilege(privilege);
  }
}
