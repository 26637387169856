// eslint-disable-next-line max-classes-per-file
import { GlobalErrorModel } from 'src/app/core/models/global-error.model';

export class HandleCodeAndParameters {
  static readonly type = '[Global Error] Handle Code with parameters';
  constructor(public error: GlobalErrorModel) {}
}

export class HandleGenericError {
  static readonly type = '[Global Error] Handle Generic Error';
  constructor(public error?: string, public duration?: number) {}
}

export class HandleMessageAndParameters {
  static readonly type = '[Global Error] Handle Message with Reason';
  constructor(public error: GlobalErrorModel, public duration?: number) {}
}

export class HandleCodeAndMessage {
  static readonly type = '[Global Error] Handle Code with message';
  constructor(public error: GlobalErrorModel, public duration?: number) {}
}

export class HandleAccessError {
  static readonly type = '[Global Error] Handle Access Error';
  constructor(public error: Partial<GlobalErrorModel>) {}
}
