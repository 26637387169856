import { NavigationExtras } from '@angular/router';
import { StaticDependenciesService } from 'src/app/services/static-dependencies.service';

const ON_NAVIGATION_END = () => {
  window.history.state.skipUnsavedDataCheck = false;
};

// This function is used to bypass the unsaved data check when navigating to a different route from a route that has a form with unsaved data.
export function redirectAndBypassUnsavedDataCheck(url: string, extras: NavigationExtras = {}) {
  StaticDependenciesService.zone.run(() => {
    window.history.state.skipUnsavedDataCheck = true;
    if (Object.keys(extras).length === 0) {
      StaticDependenciesService.router.navigateByUrl(url).then(ON_NAVIGATION_END);
      return;
    }
    StaticDependenciesService.router.navigate([url], { ...extras }).then(ON_NAVIGATION_END);
  });
}
