/* eslint-disable no-underscore-dangle */
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { IdName } from '../models/id-name';

type FormWithFormArrayName = { formArrayName: string; form: FormGroup };
export type FormArrayWithName = { name: string; formArray: FormArray };
@Directive()
export abstract class AbstractTable<F extends FormGroup, M> {
  @Input() set form(formWithName: FormWithFormArrayName) {
    const formArray = formWithName.form[
      formWithName.formArrayName as keyof FormGroup
    ] as FormArray<F>;
    this.formName = formWithName.formArrayName;
    this._rows = (formArray as FormArray<F>).controls;
    this._formArray = formArray;
  }
  @Input() set firstOptions(options: IdName[] | undefined) {
    this._firstOptions = options;
  }
  @Input() set secondOptions(options: IdName[] | undefined) {
    this._secondOptions = options;
  }
  @Input() title: string;
  @Output() readonly reloadTable: EventEmitter<FormArrayWithName> =
    new EventEmitter<FormArrayWithName>();
  abstract columnsToDisplay: string[];
  abstract tableNamesEnum: any;
  _rows: AbstractControl<any, any>[];
  _firstOptions: IdName[] | undefined;
  _secondOptions: IdName[] | undefined;
  _formArray: FormArray<F>;
  formName: string;

  constructor(
    protected dialog: MatDialog,
    private FormConstructor: new (...args: any[]) => F,
    private ModalConstructor: new (...args: any[]) => M
  ) {}

  add() {
    const formModal = this.dialog.open<M>(this.ModalConstructor, {
      width: '36rem',
      panelClass: 'no-padding',
      data: {
        form: new this.FormConstructor(),
        title: this.title,
        firstOptions: this._firstOptions,
        secondOptions: this._secondOptions,
      },
    });

    formModal
      .afterClosed()
      .pipe(
        filter((form) => !!form),
        tap((form) => {
          this._formArray.push(form);
          this.refreshTable();
        })
      )
      .subscribe();
  }

  private edit = (i: number) => {
    return () => {
      const formModal = this.dialog.open<M>(this.ModalConstructor, {
        width: '36rem',
        panelClass: 'no-padding',
        data: {
          form: this._formArray.controls[i],
          firstOptions: this._firstOptions,
          secondOptions: this._secondOptions,
        },
      });

      formModal
        .afterClosed()
        .pipe(
          filter((form) => !!form),
          tap(() => {
            this.refreshTable();
          })
        )
        .subscribe();
    };
  };

  private delete = (i: number) => {
    return () => {
      this._formArray.removeAt(i);
      this.refreshTable();
    };
  };

  buttonFunctions = { edit: this.edit, delete: this.delete };

  private refreshTable() {
    this._rows = [...this._formArray.controls];
    this.reloadTable.emit({ formArray: this._formArray, name: this.formName });
  }
}
