import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { AdminMaintenanceRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from 'src/app/shared/models/id-name';
import { IJobType } from '../models/job-type.model';
import { JobTypeApiService } from '../services/job-type-api.service';
import {
  CreateJobType,
  DeleteJobType,
  LoadAllJobTypes,
  LoadJobTypeById,
  LoadJobTypes,
  UpdateJobType,
} from './job-type.actions';

export interface JobTypeStateModel {
  jobTypes: IJobType[];
  allJobTypes: IdName[];
  jobTypesTotalPages: number;
}

const JOB_TYPE_STATE_TOKEN = new StateToken<JobTypeStateModel>('jobType');

@State<JobTypeStateModel>({
  name: JOB_TYPE_STATE_TOKEN,
  defaults: {
    jobTypes: [],
    allJobTypes: [],
    jobTypesTotalPages: 0,
  },
})
@Injectable()
export class JobTypeState {
  constructor(private service: JobTypeApiService) {}

  @Action(LoadJobTypes)
  loadJobTypes({ getState, setState }: StateContext<JobTypeStateModel>, { filter }: LoadJobTypes) {
    return this.service.loadJobTypes(filter).pipe(
      tap((response: ApiResponse<IJobType[]>) => {
        const state = getState();
        setState({
          ...state,
          jobTypes: response.data,
          jobTypesTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllJobTypes, { cancelUncompleted: true })
  loadAllJobTypes(ctx: StateContext<JobTypeStateModel>) {
    return this.service.loadAllJobTypes().pipe(
      tap((allJobTypes: IdName[]) => {
        ctx.setState(patch({ allJobTypes }));
      })
    );
  }

  @Action(LoadJobTypeById, { cancelUncompleted: true })
  loadJobTypeById(
    { getState, setState }: StateContext<JobTypeStateModel>,
    { id }: LoadJobTypeById
  ) {
    const { jobTypes } = getState();
    const exist = jobTypes.some((i) => i.id === id);
    return this.service.loadJobTypeById(id).pipe(
      tap((jobType: IJobType) => {
        setState(
          patch({
            jobTypes: exist
              ? updateItem<IJobType>((toUpdate) => toUpdate?.id === id, jobType)
              : insertItem<IJobType>(jobType),
          })
        );
      })
    );
  }

  @Action(CreateJobType)
  @OnSuccess({ url: AdminMaintenanceRoutes.JobTypes, message: 'jobTypeAdded' })
  createJobType({ setState }: StateContext<JobTypeStateModel>, { jobType }: CreateJobType) {
    return this.service.createJobType(jobType).pipe(
      tap((response: IJobType) => {
        setState(
          patch({
            jobTypes: insertItem<IJobType>(response),
          })
        );
      })
    );
  }

  @Action(UpdateJobType)
  @OnSuccess({ url: AdminMaintenanceRoutes.JobTypes, message: 'jobTypeUpdated' })
  updateJobType({ setState }: StateContext<JobTypeStateModel>, { jobType }: UpdateJobType) {
    return this.service.updateJobType(jobType).pipe(
      tap(() => {
        setState(
          patch({
            jobTypes: updateItem<IJobType>((toUpdate) => toUpdate?.id === jobType.id, jobType),
          })
        );
      })
    );
  }

  @Action(DeleteJobType, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.JobTypes, message: 'jobTypeDeleted' })
  deleteJobType(ctx: StateContext<JobTypeStateModel>, { id }: DeleteJobType) {
    return this.service.deleteJobType(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            jobTypes: removeItem<IJobType>(
              (jtToDelete: IJobType | undefined) => jtToDelete?.id === id
            ),
          })
        );
      })
    );
  }

  @Selector()
  static getJobTypesTotalPages(state: JobTypeStateModel) {
    return state.jobTypesTotalPages;
  }

  @Selector()
  static jobTypes(state: JobTypeStateModel) {
    return state.jobTypes;
  }

  @Selector()
  static allJobTypes(state: JobTypeStateModel): IdName[] {
    return state.allJobTypes;
  }

  @Selector()
  static getJobTypeById(state: JobTypeStateModel) {
    return (id: string) => {
      return state.jobTypes.find((jobType: IJobType) => jobType.id === id);
    };
  }
}
