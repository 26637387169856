import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminTenantRoutes } from 'src/app/core/enums/routes.enum';
import { map } from 'rxjs/operators';
import { ApplySearch } from 'src/app/core/state/main-table-filter.actions';
import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';
import { ITenant } from '../../models/tenant.model';
import { TenantState } from '../../state/tenant.state';
import { SoldBy, SoldByEnum } from '../../enums/sold-by.enum';
import { LoadTenants } from '../../state/tenant.action';
import { MainTableFilterModel } from '../../../shared/models/main-table-filter.model';

@Component({
  selector: 'app-tenant-view',
  templateUrl: './tenant-view.component.html',
  styleUrls: ['./tenant-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantViewComponent implements OnInit {
  tenants$: Observable<ITenant[]> = this.store.select(TenantState.tenants).pipe(
    map((tenants) => {
      return tenants.map((tenant) => {
        let soldBy;
        if (Number.isInteger(tenant.soldBy)) {
          soldBy =
            SoldByEnum.CCOMNorway === tenant.soldBy
              ? SoldBy.CCOMNorway.name
              : SoldBy.CCOMIceland.name;
        } else {
          soldBy = SoldBy[`CCOM${tenant.soldBy}`].name;
        }
        return { ...tenant, soldBy };
      });
    })
  );
  tenantTotalPages$: Observable<number> = this.store.select(TenantState.getTenantTotalPages);
  urlForRow = AdminTenantRoutes.Edit;
  addTenantRoute = AdminTenantRoutes.Add;
  displayedColumns = ['name', 'soldBy', 'amountOfDepartments', 'moduleList', 'status'];
  moduleNameForPaging = MainTableFilterModulesEnum.Tenant;

  constructor(private store: Store) {}

  ngOnInit(): void {
    const tenantFilter = new MainTableFilterModel(this.moduleNameForPaging);
    this.store.dispatch(new LoadTenants(tenantFilter));
  }

  searchChanged(searchTerm: string): void {
    this.store.dispatch(new ApplySearch(this.moduleNameForPaging, searchTerm));
  }
}
