import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TenantSelectorComponent } from 'src/app/tenant-selector/tenant-selector.component';
import { Observable, Subscription } from 'rxjs';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import {
  ITenantUserAccess,
  IUserAccess,
} from 'src/app/shared/user-view/models/tenant-user-access.model';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ToggleDarkMode } from '../../state/ui.actions';
import { ClearFilterDetails } from '../../state/main-table-filter.actions';
import { RoutesEnum } from '../../enums/routes.enum';
import { UiState } from '../../state/ui.state';

@Component({
  selector: 'app-user-profile-dropdown-mobile',
  templateUrl: './user-profile-dropdown-mobile.component.html',
  styleUrls: ['./user-profile-dropdown-mobile.component.scss'],
})
export class UserProfileDropdownMobileComponent implements OnInit, OnDestroy {
  userAccess$: Observable<IUserAccess> = this.store.select(TenantUserAccessState.userAccess);
  userCurrentTenantId$: Observable<string> = this.store.select(
    TenantUserAccessState.currentTenantId
  );
  currentTenant$: Observable<ITenantUserAccess> = this.store.select(
    TenantUserAccessState.currentTenant
  );

  isDarkMode: boolean;
  firstRun = true;
  profileSettingsAvailable = false;
  tenantUserEmail: string;
  tenantUserName: string;
  tenantPosition: string;
  userAccess: IUserAccess;

  private isDarkMode$: Observable<boolean> = this.store.select(UiState.isDarkMode);
  private subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private store: Store,
    private dialogRef: MatDialogRef<UserProfileDropdownMobileComponent>,
    private oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.isDarkMode$.subscribe((mode) => {
        if (this.firstRun) {
          this.firstRun = false;
        }
        this.isDarkMode = mode;
      })
    );

    this.subscription.add(
      this.userAccess$.subscribe((userAccess: IUserAccess) => {
        this.userAccess = userAccess;
        this.updatePopupInfo(getTenantId());
      })
    );

    this.subscription.add(
      this.userCurrentTenantId$.subscribe((tenantId: string) => {
        this.updatePopupInfo(tenantId);
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onToggleDarkMode(event: UIEvent): void {
    this.isDarkMode = !this.isDarkMode;
    this.store.dispatch(new ToggleDarkMode(this.isDarkMode));
    event.stopPropagation();
  }

  onProfileSettings(): void {
    if (this.profileSettingsAvailable) {
      this.store.dispatch(new ClearFilterDetails());
      this.router.navigate([`${RoutesEnum.Profile}/view`]);
    }
  }

  changeLanguage(): void {}

  changeTenant(): void {
    this.dialog
      .open(TenantSelectorComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        autoFocus: false,
      })
      .afterClosed()
      .subscribe();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  logout(): void {
    this.oidcSecurityService.logoff();
  }

  private updatePopupInfo(tenantId: string): void {
    if (this.userAccess) {
      this.profileSettingsAvailable = this.userAccess.tenantInfos.length > 0;
      this.tenantUserName = this.userAccess.name;
      this.tenantUserEmail = this.userAccess?.email
        .replace(/\./g, '\u200B.')
        .replace(/@/, '\u200B@');
      this.tenantPosition = this.userAccess.isProductOwner
        ? 'productOwner'
        : this.userAccess.tenantInfos.find((tenantInfo) => tenantInfo.id === tenantId)?.position
            ?.name || 'user';
    }
  }
}
