/* eslint-disable max-classes-per-file */
import { IMaintenancePrintRequest } from 'src/app/maintenance-user/models/print-jobs-request.model';
import { IMaintenanceComponentGroupCreateUpdate } from '../models/maintenance-component-group.model';
import { IMaintenanceComponentCreateUpdate } from '../models/maintenance-component.model';
import { CopyComponentsModel } from '../models/components-structure-menu.model';
import { UpdateMenuStructureOrderRequest } from 'src/app/core/models/menu-id.model';

// MAINTENANCE COMPONENTS MENU
export class GetMaintenanceMenuStructure {
  static readonly type = '[Maintenance Components Structure] Get Maintenance Menu Structure';
}

// MAINTENANCE COMPONENTS
export class CreateMaintenanceComponent {
  static readonly type = '[Maintenance Components Structure] Create a Maintenance Component';
  constructor(public component: IMaintenanceComponentCreateUpdate) {}
}

export class UpdateMaintenanceComponent {
  static readonly type = '[Maintenance Components Structure] Update a Maintenance Component';
  constructor(public component: IMaintenanceComponentCreateUpdate) {}
}

export class DeleteMaintenanceComponent {
  static readonly type = '[Maintenance Components Structure] Delete a Maintenance Component';
  constructor(public id: string) {}
}

export class GetMaintenanceComponentById {
  static readonly type = '[Maintenance Components Structure] Get Maintenance Component By Id';
  constructor(public id: string) {}
}

export class GetMaintenanceComponents {
  static readonly type = '[Maintenance Components Structure] Get Maintenance Component List';
  constructor() {}
}

export class GetAllMaintenanceComponents {
  static readonly type = '[Maintenance Components Structure] Get All Maintenance Component List';
  constructor() {}
}

export class TransformToComponentsGroup {
  static readonly type = '[Maintenance Components Structure] Transform to Components Group';
  constructor(public id: string) {}
}

// MAINTENANCE COMPONENT GROUPS
export class CreateMaintenanceComponentGroup {
  static readonly type = '[Maintenance Components Structure] Create a Maintenance Component Group';
  constructor(public componentGroup: IMaintenanceComponentGroupCreateUpdate) {}
}

export class UpdateMaintenanceComponentGroup {
  static readonly type = '[Maintenance Components Structure] Update a Maintenance Component Group';
  constructor(public componentGroup: IMaintenanceComponentGroupCreateUpdate) {}
}

export class DeleteMaintenanceComponentGroup {
  static readonly type = '[Maintenance Components Structure] Delete a Maintenance Component Group';
  constructor(public id: string) {}
}

export class GetMaintenanceComponentGroupById {
  static readonly type = '[Maintenance Components Structure] Get Maintenance Component Group By Id';
  constructor(public id: string) {}
}

export class GetMaintenanceComponentGroups {
  static readonly type = '[Maintenance Components Structure] Get Maintenance Component Group List';
  constructor() {}
}

export class PrintComponentList {
  static readonly type = '[Maintenance Components Structure] Print Maintenance Component List';
  constructor(public request: IMaintenancePrintRequest) {}
}

export class CopyComponentStructure {
  static readonly type = '[Maintenance Components Structure] Copy Components Structure';
  constructor(public model: CopyComponentsModel) {}
}

export class TransformToComponent {
  static readonly type = '[Maintenance Components Structure] Transform to Component';
  constructor(public id: string) {}
}

export class UpdateComponentsStructureOrder {
  static readonly type = '[Maintenance Components Structure] Update Components Structure Order';
  constructor(public order: UpdateMenuStructureOrderRequest) {}
}
