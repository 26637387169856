<ng-container *ngIf="listOfObjects; else listOfSimpleStrings">
  <mat-card *ngFor="let element of elements">
    <mat-card-title>{{ element.name }}</mat-card-title>
    <mat-card-content>
      <div class="space-between">{{ element.type }}</div>
      <div class="space-between">{{ element.createAt | date : 'shortDate' }}</div>
    </mat-card-content>
    <mat-divider></mat-divider>
  </mat-card>
</ng-container>

<ng-template #listOfSimpleStrings>
  <mat-card *ngFor="let element of elements">
    {{ element }}
  </mat-card>
</ng-template>
