import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IContact } from '../models/tenant.model';
import { maxLengthValidator } from '../../shared/functions/max-length-validator';
import { onlyIntegersValidator } from '../../shared/functions/only-integers-validator';

export class ContactForm extends FormGroup {
  readonly firstName = this.get('firstName') as FormControl<string>;
  readonly lastName = this.get('lastName') as FormControl<string>;
  readonly position = this.get('position') as FormControl<string>;
  readonly prefix = this.get('prefix') as FormControl<number>;
  readonly phoneNumber = this.get('phoneNumber') as FormControl<number>;
  readonly email = this.get('email') as FormControl<string>;

  constructor(contact?: IContact, readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        firstName: [contact ? contact.firstName : '', [Validators.required, maxLengthValidator]],
        lastName: [contact ? contact.lastName : '', [Validators.required, maxLengthValidator]],
        position: [contact ? contact.position : '', [maxLengthValidator]],
        prefix: [
          contact ? contact.prefix : '',
          [Validators.required, Validators.maxLength(4), Validators.min(0), onlyIntegersValidator],
        ],
        phoneNumber: [
          contact ? contact.phoneNumber : '',
          [Validators.required, Validators.maxLength(10), Validators.min(0), onlyIntegersValidator],
        ],
        email: [contact ? contact.email : '', [Validators.required, Validators.email]],
      }).controls
    );
  }
}
