import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaskTableNamesEnum } from 'src/app/shared/enums/task-table-names.enum';

@Component({
  selector: 'app-task-template',
  templateUrl: './task-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskTemplatesComponent {
  @Input() specificTemplateName: string;
  @Input() columnName: string;
  @Input() element: any;

  taskTemplateNames = TaskTableNamesEnum;
}
