import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, switchMap } from 'rxjs';
import { LoadAllDepartmentsForSelectedTenant } from 'src/app/department/state/department.actions';
import { DepartmentState } from 'src/app/department/state/department.state';
import { TenantState } from 'src/app/tenant/state/tenant.state';
import { ITenant } from 'src/app/tenant/models/tenant.model';
import { LoadAllTenants } from 'src/app/tenant/state/tenant.action';
import { CopyComponentStructure } from 'src/app/maintenance-admin/state/components-structure.actions';
import { IdName } from '../../models/id-name';
import { markControlsAsTouched } from '../../functions/mark-controls-as-touched';
import { CopyComponentsStructureForm } from './copy-components-structure.form';

@Component({
  selector: 'app-copy-components-structure-modal',
  templateUrl: './copy-components-structure-modal.component.html',
})
export class CopyComponentsStructureModalComponent implements OnInit {
  getAllTenants$: Observable<ITenant[]> = this.store.select(TenantState.allTenants);
  getAllDepartments$: Observable<IdName[]> = this.store.select(
    DepartmentState.getAllDepartmentsForSelectedTenant
  );

  form: CopyComponentsStructureForm;
  allTenants: ITenant[];
  allDepartments: IdName[];

  constructor(
    public dialogRef: MatDialogRef<CopyComponentsStructureModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      moduleNumber: number;
      id?: string;
    },
    private store: Store
  ) {
    this.form = new CopyComponentsStructureForm(this.data.id);
  }

  ngOnInit(): void {
    this.store
      .dispatch([new LoadAllTenants()])
      .pipe(switchMap(() => this.getAllTenants$))
      .subscribe((tenants) => {
        this.allTenants = tenants;
      });
  }

  onTargetTenantChange(): void {
    this.store
      .dispatch([new LoadAllDepartmentsForSelectedTenant(this.form.targetTenantId.value)])
      .pipe(switchMap(() => this.getAllDepartments$))
      .subscribe((departments) => {
        this.form.targetDepartmentIds.setValue([]);
        this.allDepartments = departments;
      });
  }

  save(): void {
    markControlsAsTouched(this.form);

    if (!this.form.valid) {
      return;
    }

    this.store.dispatch(new CopyComponentStructure(this.form.value));
  }
}
