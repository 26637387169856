<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'expires'">
    {{ element[columnName] | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'productName'">
    {{ element.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'currentQuantity'">
    {{ element.quantity }}
  </ng-container>
  <ng-container *ngSwitchCase="'quantityType'">
    {{ element.quantityType?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'minQuantity'">
    {{ element.minimumQuantity }}
  </ng-container>
  <ng-container *ngSwitchCase="'chemical'">
    <mat-icon *ngIf="element.isChemical">science</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'critical'"
    ><mat-icon *ngIf="element.isChemical">warning</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'components'">
    <div *ngFor="let component of element.components">
      {{ (component?.sfiCode ? component.sfiCode + ' ' : '') + component?.name ?? '-' }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'description'">
    {{ element.reason }}
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill [name]="statusColor"></app-ui-pill>
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    {{ element.date | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'changedBy'">
    {{ element.changedBy | fullName }}
  </ng-container>
  <ng-container *ngSwitchCase="'event'">
    {{ element.storageProductAction | mapProductAction | translate }}
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
  <ng-container *ngSwitchCase="'actionButton'">
    <app-ui-button
      class="use-button"
      buttonType="secondary"
      text="{{ 'use' | translate }}"
      (click)="use()"
      privilege="STORAGE-PRODUCT_UPDATE"
    >
    </app-ui-button>
  </ng-container>
</ng-container>
