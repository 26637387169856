import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { IAlertResponse } from 'src/app/alert/models/alert.model';
import { AlertState } from 'src/app/alert/state/alert.state';
import { IDueListItem } from 'src/app/due-list/models/due-list-item.model';
import { DueListState } from 'src/app/due-list/state/due-list.state';
import { NonConformanceApiService } from 'src/app/non-conformance-user/services/non-conformance-api.service';
import { IListNonConformanceModel } from 'src/app/non-conformance-user/models/list-non-conformance-model';
import { NonConformanceStatus } from 'src/app/non-conformance-user/enums/non-conformance-status.enum';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { OrderStatus } from 'src/app/order-user/enums/order-status.enum';
import { OrderApiService } from 'src/app/order-user/services/order-api.service';
import { IOrderResponse } from 'src/app/order-user/models/order.model';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { MainTableFilterModulesEnum } from '../../enums/main-table-filter-modules.enum';
import { ApiResponse } from '../../models/api-response';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  dueListWarnings$: Observable<IDueListItem[]> = this.store.select(DueListState.getAllWarnings);
  dueListCompetencies$: Observable<IDueListItem[]> = this.store.select(
    DueListState.getAllDueCompetencies
  );
  alerts$: Observable<IAlertResponse[]> = this.store.select(AlertState.getAlerts);
  currentTenantId$: Observable<string> = this.store.select(TenantUserAccessState.currentTenantId);
  currentDepartmentId$: Observable<string> = this.store.select(
    TenantUserAccessState.currentDepartmentId
  );
  isProductOwner$: Observable<boolean | undefined> = this.store.select(
    TenantUserAccessState.isProductOwner
  );
  userPrivileges$: Observable<any[]> = this.store.select(TenantUserAccessState.getPrivileges);

  // Due List numbers
  expiredWarnings = 0;
  soonExpiringWarnings = 0;
  warningsLoaded = false;
  expiredCompetences = 0;
  soonExpiringCompetences = 0;
  competencesLoaded = false;

  // Alerts
  alertSum = 0;

  // Non-Conformances
  ncOpen = 0;
  ncCompleted = 0;
  canReadNC = false;

  // Orders
  ordersSentToSupplier = 0;
  canReadOrders = false;

  isUserProductOwner = false;
  displayDashboard = false;

  private tenantId: string;
  private departmentId: string;
  private subscription = new Subscription();

  constructor(
    private store: Store,
    private nonConformanceApiService: NonConformanceApiService,
    private orderApiService: OrderApiService
  ) {}

  ngOnInit(): void {
    this.checkIfUserIsProductOwner();
    this.listenToTenantOrDepartmentChanges();
    this.getWarnings();
    this.getCompetences();
    this.getAlerts();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private listenToTenantOrDepartmentChanges(): void {
    this.subscription.add(
      combineLatest([this.currentTenantId$, this.currentDepartmentId$]).subscribe(
        (result: string[]) => {
          const tenantId = result[0];
          const depId = result[1];

          if (tenantId !== undefined) {
            this.displayDashboard = true;
          }

          if (this.tenantId === undefined) {
            this.tenantId = tenantId;
          }
          if (this.departmentId === undefined) {
            this.departmentId = depId;
          }
          if (
            (depId !== 'all_deps' && this.tenantId !== tenantId) ||
            (depId !== 'all_deps' && this.departmentId !== depId)
          ) {
            this.tenantId = tenantId;
            this.departmentId = depId;
            if (this.isUserProductOwner) {
              this.getNonConformances();
              this.getOrders();
            } else {
              if (this.canReadOrders) {
                this.getOrders();
              }
              if (this.canReadNC) {
                this.getNonConformances();
              }
            }
          }
        }
      )
    );
  }

  private getWarnings(): void {
    this.subscription.add(
      this.dueListWarnings$.subscribe((dueListWarnings: IDueListItem[]) => {
        this.expiredWarnings = 0;
        this.soonExpiringWarnings = 0;
        dueListWarnings.forEach((dueListWarning: IDueListItem) => {
          if (dueListWarning.status === 'Expired') {
            this.expiredWarnings++;
          } else if (dueListWarning.status === 'Expiring') {
            this.soonExpiringWarnings++;
          }
        });
        if (dueListWarnings?.length > 0) {
          setTimeout(() => {
            this.warningsLoaded = true;
          });
        } else {
          this.warningsLoaded = false;
        }
      })
    );
  }

  private getCompetences(): void {
    this.subscription.add(
      this.dueListCompetencies$.subscribe((dueListCompetences: IDueListItem[]) => {
        this.expiredCompetences = 0;
        this.soonExpiringCompetences = 0;
        dueListCompetences.forEach((dueListCompetence: IDueListItem) => {
          if (dueListCompetence.status === 'Expired') {
            this.expiredCompetences++;
          } else if (dueListCompetence.status === 'Expiring') {
            this.soonExpiringCompetences++;
          }
        });
        if (dueListCompetences?.length > 0) {
          setTimeout(() => {
            this.competencesLoaded = true;
          });
        } else {
          this.competencesLoaded = false;
        }
      })
    );
  }

  private getAlerts(): void {
    this.subscription.add(
      this.alerts$.subscribe((alerts: IAlertResponse[]) => {
        this.alertSum = alerts.length ?? 0;
      })
    );
  }

  private getNonConformances(): void {
    const tempFilterObj = new MainTableFilterModel(MainTableFilterModulesEnum.NonConformances);
    tempFilterObj.pageIndex = 0;
    // eslint-disable-next-line no-magic-numbers
    tempFilterObj.pageSize = 10000;
    const ncStateOpen = NonConformanceStatus.Open;
    const ncStateCompleted = NonConformanceStatus.Completed;
    this.subscription.add(
      this.nonConformanceApiService
        .getAllNonConformancesByState(ncStateOpen, tempFilterObj)
        .subscribe((apiResponse: ApiResponse<IListNonConformanceModel[]>) => {
          this.ncOpen = apiResponse.data?.length ?? 0;
        })
    );
    this.subscription.add(
      this.nonConformanceApiService
        .getAllNonConformancesByState(ncStateCompleted, tempFilterObj)
        .subscribe((apiResponse: ApiResponse<IListNonConformanceModel[]>) => {
          this.ncCompleted = apiResponse.data?.length ?? 0;
        })
    );
  }

  private getOrders(): void {
    const tempFilterObj = new MainTableFilterModel(MainTableFilterModulesEnum.Orders);
    tempFilterObj.pageIndex = 0;
    // eslint-disable-next-line no-magic-numbers
    tempFilterObj.pageSize = 10000;
    const orderStageSentToSupplier = OrderStatus.SentToSupplier;
    this.subscription.add(
      this.orderApiService
        .getOrders(orderStageSentToSupplier, tempFilterObj)
        .subscribe((apiResponse: ApiResponse<IOrderResponse[]>) => {
          this.ordersSentToSupplier = apiResponse.data?.length ?? 0;
        })
    );
  }

  private checkIfUserIsProductOwner(): void {
    this.subscription.add(
      this.isProductOwner$.subscribe((productOwner: boolean | undefined) => {
        if (productOwner) {
          this.getOrders();
          this.getNonConformances();
          this.isUserProductOwner = true;
        } else {
          this.getPrivileges();
          this.isUserProductOwner = false;
        }
      })
    );
  }

  private getPrivileges(): void {
    this.subscription.add(
      this.userPrivileges$.subscribe((privileges: any[]) => {
        privileges.forEach((privilege: any) => {
          if (privilege.module === 9) {
            privilege.privileges.forEach((priv: string) => {
              if (priv === 'ORDER_READ') {
                this.canReadOrders = true;
                this.getOrders();
              }
            });
          }
          if (privilege.module === 4) {
            privilege.privileges.forEach((priv: string) => {
              if (priv === 'NON-CONFORMANCE_READ') {
                this.canReadNC = true;
                this.getNonConformances();
              }
            });
          }
        });
      })
    );
  }
}
