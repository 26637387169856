/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UiState } from 'src/app/core/state/ui.state';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/modules/material.module';

import { UIElementsModule } from '../../ui-elements/ui-elements.module';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    UIElementsModule,
    MatIconModule,
    MaterialModule,
    MatDialogModule,
    NgIf,
  ],
})
export class DialogContainerComponent implements OnInit {
  isDarkMode$: Observable<boolean> = this.store.select(UiState.isDarkMode);

  @Input() title: string;
  @Input() subtitle: string;
  @Input() entityNameTitle: string;
  @Input() confirmButtonText = 'save';
  @Input() confirmButton: 'primary' | 'warn-secondary' = 'primary';
  @Input() isValid = true;
  @Input() form: FormGroup;
  @Input() createPrivilege: string;
  @Input() editPrivilege: string;
  @Input() deletePrivilege: string;
  @Input() showCancelButton = true;
  @Input() showCloseButton = true;

  @Output() readonly confirmButtonHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly deleteButtonHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly editButtonHandler: EventEmitter<any> = new EventEmitter<any>();

  confirmButtonType: any;
  confirmButtonColor: any;

  constructor(private dialogRef: MatDialogRef<DialogContainerComponent>, private store: Store) {}

  ngOnInit(): void {
    this.confirmButtonColor = this.confirmButton === 'primary' ? 'primary' : 'warn';

    this.isDarkMode$.pipe(take(1)).subscribe((darkMode) => {
      if (this.confirmButton === 'warn-secondary') {
        this.confirmButtonType = darkMode ? 'stroked' : 'raised';
      } else {
        this.confirmButtonType = 'raised';
      }
    });
  }

  confirm(): void {
    if (this.isValid) {
      this.dialogRef.close(true);
    }
    this.confirmButtonHandler.emit(true);
  }

  edit(): void {
    this.editButtonHandler.emit(true);
  }

  delete(): void {
    this.deleteButtonHandler.emit(true);
  }
}
