/* eslint-disable max-classes-per-file */
import { IDepartmentGroupModel } from 'src/app/department/models/department-group.model';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadDepartmentGroups {
  static readonly type = '[DEPARTMENT GROUPS] Fetch Department Groups';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllDepartmentGroups {
  static readonly type = '[DEPARTMENT GROUPS] Fetch All Department Groups';
  constructor() {}
}

export class LoadDepartmentGroupsForUser {
  static readonly type = '[DEPARTMENT GROUPS] Fetch Department Groups For User';
  constructor() {}
}

export class LoadDepartmentGroupsWithDepartments {
  static readonly type = '[DEPARTMENT GROUPS] Fetch Department Groups With Departments';
  constructor(public departmentIds?: string[], public departmentGroupIds?: string[]) {}
}

export class AddDepartmentGroups {
  static readonly type = '[DEPARTMENT GROUPS] Add Department Groups';
  constructor(public toAdd: IDepartmentGroupModel) {}
}

export class GetDepartmentGroupsById {
  static readonly type = '[DEPARTMENT GROUPS] Get Department Groups By Id';
  constructor(public id: string) {}
}

export class UpdateDepartmentGroups {
  static readonly type = '[DEPARTMENT GROUPS] Update Department Groups';
  constructor(public toUpdate: IDepartmentGroupModel) {}
}

export class DeleteDepartmentGroup {
  static readonly type = '[DEPARTMENT GROUPS] Delete Department Groups';
  constructor(public departmentGroupId: string) {}
}
