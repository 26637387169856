<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'confirm'">
    <app-ui-button
      *ngIf="element.type === paxOverviewType.Current; else planned"
      buttonType="icon-button"
      icon="transfer_within_a_station"
      privilege="PAX-OVERVIEW_UPDATE"
      (click)="buttonFunctions.leave()"
    ></app-ui-button>
  </ng-container>
  <ng-template #planned>
    <app-ui-button
      buttonType="icon-button"
      icon="done"
      privilege="PAX-OVERVIEW_UPDATE"
      (click)="buttonFunctions.confirm()"
    ></app-ui-button>
  </ng-template>
  <ng-container *ngSwitchCase="'edit'">
    <app-ui-button
      buttonType="icon-button"
      icon="edit"
      privilege="PAX-OVERVIEW_UPDATE"
      (click)="buttonFunctions.edit()"
    ></app-ui-button>
  </ng-container>
  <ng-container *ngSwitchCase="'arrivalDateTime'">
    {{ element.from | date : dateTimeFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'departureDateTime'">
    {{ element.to | date : dateTimeFormat }}
  </ng-container>

  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
