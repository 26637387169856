import { CommonModule, DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IEquivalentCompetencesTooltip } from '../../models/equivalent-competences-tooltip.model';

@Component({
  selector: 'app-equivalent-competences-tooltip',
  templateUrl: './equivalent-competences-tooltip.component.html',
  styleUrls: ['./equivalent-competences-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  providers: [DatePipe],
})
export class EquivalentCompetencesTooltipComponent implements OnInit {
  @Input() competence: IEquivalentCompetencesTooltip;
  @Input() icon = 'info';
  text = '';

  constructor(private translate: TranslateService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    const equivalentCompetencesLabel = this.translate.instant('equivalentCompetences');
    const expiresLabel = this.translate.instant('expires').toLocaleLowerCase();
    const fittingRequirements =
      this.competence?.competencesFittingRequirements ||
      this.competence?.equivalentCompetences ||
      [];
    if (fittingRequirements?.length >= 1) {
      const equivalentCompetencesToShow = fittingRequirements
        ? fittingRequirements.filter((c: any) => c.competenceId !== this.competence.competenceId)
        : [];
      if (equivalentCompetencesToShow.length >= 1) {
        const competencesInfo = equivalentCompetencesToShow
          .map(
            (c: any) =>
              `${c.competenceName}${
                c.expiresAt
                  ? ` - ${expiresLabel} ${this.datePipe.transform(c.expiresAt, 'yyyy-MM-dd')}`
                  : ''
              }`
          )
          .join(', ');
        this.text = `${equivalentCompetencesLabel}: ${competencesInfo}`;
      }
    }
  }
}
