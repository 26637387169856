<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'departments'">
    <div *ngFor="let department of element.departments; trackBy: trackBy">
      {{ department }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'departmentType'">
    <div *ngIf="element.type === 2; else vessel">
      {{ 'LandBased' | translate }}
    </div>
    <ng-template #vessel>
      <div>
        {{ 'Vessel' | translate }}
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill name="{{ element.status | lowercase }}"></app-ui-pill>
  </ng-container>
  <ng-container *ngSwitchCase="'vesselType'">
    <div>{{ element.vesselType | translate }} <br /></div>
  </ng-container>
  <ng-container *ngSwitchCase="'vesselTypes'">
    <div *ngFor="let vesselTypes of element[columnName]">
      {{ vesselTypes | translate }}
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"> </app-default-cell>
  </ng-container>
</ng-container>
