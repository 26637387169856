import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { IdName } from 'src/app/shared/models/id-name';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { AdminCertificateRoutes } from 'src/app/core/enums/routes.enum';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { ApiResponse } from 'src/app/core/models/api-response';
import { CertificateTypeService } from 'src/app/certificate-admin/service/certificate-type-api.service';
import {
  CreateCertificateType,
  DeleteCertificateType,
  LoadAllCertificateTypes,
  LoadCertificateTypeById,
  LoadCertificateTypes,
  UpdateCertificateType,
} from './certificate-types.action';

export interface CertificateTypeStateModel {
  certificateTypes: IdName[];
  allCertificateTypes: IdName[];
  certificateTypesTotalPages: number;
}

const CERTIFICATE_TYPES_STATE_TOKEN = new StateToken<CertificateTypeStateModel>('certificateTypes');

@State<CertificateTypeStateModel>({
  name: CERTIFICATE_TYPES_STATE_TOKEN,
  defaults: {
    certificateTypes: [],
    allCertificateTypes: [],
    certificateTypesTotalPages: 0,
  },
})
@Injectable()
export class CertificateTypeState {
  constructor(private service: CertificateTypeService) {}

  @Action(LoadCertificateTypes)
  loadCertificateTypes(
    { getState, setState }: StateContext<CertificateTypeStateModel>,
    { filter }: LoadCertificateTypes
  ): any {
    return this.service.loadCertificateTypes(filter).pipe(
      tap((response: ApiResponse<IdName[]>) => {
        const state = getState();
        setState({
          ...state,
          certificateTypes: response.data,
          certificateTypesTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllCertificateTypes, { cancelUncompleted: true })
  loadAllCertificateTypes(ctx: StateContext<CertificateTypeStateModel>) {
    return this.service.loadAllCertificateTypes().pipe(
      tap((allCertificateTypes: IdName[]) => {
        ctx.setState(patch({ allCertificateTypes }));
      })
    );
  }

  @Action(LoadCertificateTypeById, { cancelUncompleted: true })
  loadCertificateTypeById(
    { getState, setState }: StateContext<CertificateTypeStateModel>,
    { id }: LoadCertificateTypeById
  ) {
    const { certificateTypes } = getState();
    const exist = certificateTypes.some((i) => i.id === id);
    return this.service.loadCertificateTypeById(id).pipe(
      tap((certificateType: IdName) => {
        setState(
          patch({
            certificateTypes: exist
              ? updateItem<IdName>((toUpdate) => toUpdate?.id === id, certificateType)
              : insertItem<IdName>(certificateType),
          })
        );
      })
    );
  }

  @Action(CreateCertificateType)
  @OnSuccess({
    url: AdminCertificateRoutes.CertificateTypes,
    message: 'certificateTypeAdded',
  })
  createCertificateType(
    { setState }: StateContext<CertificateTypeStateModel>,
    { certificateType }: CreateCertificateType
  ) {
    return this.service.createCertificateType(certificateType).pipe(
      tap((response: IdName) => {
        setState(
          patch({
            certificateTypes: insertItem<IdName>(response),
          })
        );
      })
    );
  }

  @Action(UpdateCertificateType)
  @OnSuccess({
    url: AdminCertificateRoutes.CertificateTypes,
    message: 'certificateTypeUpdated',
  })
  updateCertificateType(
    { setState }: StateContext<CertificateTypeStateModel>,
    { certificateType }: UpdateCertificateType
  ) {
    return this.service.updateCertificateType(certificateType).pipe(
      tap(() => {
        setState(
          patch({
            certificateTypes: updateItem<IdName>(
              (toUpdate) => toUpdate?.id === certificateType.id,
              certificateType
            ),
          })
        );
      })
    );
  }

  @Action(DeleteCertificateType, { cancelUncompleted: true })
  @OnSuccess({
    url: AdminCertificateRoutes.CertificateTypes,
    message: 'certificateTypeDeleted',
  })
  deleteCertificateType(
    ctx: StateContext<CertificateTypeStateModel>,
    { id }: DeleteCertificateType
  ) {
    return this.service.deleteCertificateType(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            certificateTypes: removeItem<IdName>(
              (wtToDelete: IdName | undefined) => wtToDelete?.id === id
            ),
          })
        );
      })
    );
  }

  @Selector()
  static getCertificateTypesTotalPages(state: CertificateTypeStateModel) {
    return state.certificateTypesTotalPages;
  }

  @Selector()
  static certificateTypes(state: CertificateTypeStateModel) {
    return state.certificateTypes;
  }

  @Selector()
  static allCertificateTypes(state: CertificateTypeStateModel): IdName[] {
    return state.allCertificateTypes;
  }

  @Selector()
  static getCertificateTypeById(state: CertificateTypeStateModel) {
    return (id: string) => {
      return state.certificateTypes.find((CertificateType: IdName) => CertificateType.id === id);
    };
  }
}
