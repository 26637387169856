<div class="filtering-row" [class.cdk-visually-hidden]="!filteringEnabled">
  <ng-content></ng-content>
  <div>
    <app-ui-button
      *ngIf="hasActiveFilters"
      class="mt-0 mb-0"
      buttonType="secondary"
      text="{{ 'clearAllFilters' | translate }}"
      (click)="clearFilters()"
    ></app-ui-button>
    <!-- <app-main-table-search
    [moduleName]="moduleNameForPaging"
    [totalPages]="totalPages"
    [additional]="additional"
  ></app-main-table-search> -->
    <mat-form-field *ngIf="searchingEnabled" appearance="outline">
      <mat-label>{{ 'search' | translate }}</mat-label>
      <input matInput placeholder="{{ 'pleaseStartTypingToFilter' | translate }}" #input />
    </mat-form-field>
  </div>
</div>
<div
  [ngClass]="{ 'mat-elevation-z8': elevationEnabled }"
  class="main-table-container"
  appElementResize
  (elementResized)="onResize($event)"
>
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="main-table-container"
    multiTemplateDataRows
    (matSortChange)="sortData($event)"
  >
    <ng-container *ngIf="showSelector" matColumnDef="multiselect">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null"
          [checked]="multipleSelection.hasValue() && isAllSelected()"
          [indeterminate]="multipleSelection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (change)="$event ? toggleSingle(row) : null"
          (click)="$event.stopPropagation()"
          [indeterminate]="isSomeRowsInGroupSelected(row) && !isAllRowsInGroupSelected(row)"
          [checked]="multipleSelection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container
      *ngFor="let columnName of displayedColumns; let i = index; trackBy: trackBy"
      [matColumnDef]="columnName"
    >
      <ng-container *ngIf="filterConfig?.[columnName]; else filteringDisabledHeader">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="columnName | shouldHeaderBeDisplayed">
            <button
              class="column-filter-button"
              [matMenuTriggerFor]="menu"
              #trigger="matMenuTrigger"
              appStopClickPropagation
            >
              {{ columnName | translate | titlecase }}
              <ng-container
                *ngIf="
                  (orderByColumn | lowercase) ===
                  (getProperHeaderNameForAPI(columnName, moduleName) | lowercase)
                "
              >
                <mat-icon *ngIf="sortDirection" class="active">
                  {{ sortDirection === 'Ascending' ? 'arrow_upward' : 'arrow_downward' }}
                </mat-icon>
              </ng-container>
              <mat-icon [class.active]="columnFilter.hasActiveFilters">filter_list</mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              class="column-filter-menu"
              (closed)="columnFilter.initControlsFromInitialFilters()"
            >
              <app-main-table-column-filter
                #columnFilter="appMainTableColumnFilter"
                [initialFilters]="columnFilters[columnName] ?? []"
                [column]="columnName"
                [filterConfig]="filterConfig ?? {}"
                [sortDirection]="
                  (orderByColumn | lowercase) ===
                  (getProperHeaderNameForAPI(columnName, moduleName) | lowercase)
                    ? sortDirection ?? ''
                    : ''
                "
                [sorting]="sortingEnabled && !(disabledColumnHeaders | contains : columnName)"
                (close)="trigger.closeMenu()"
                (sort)="sortData($event)"
                (filter)="applyColumnFilters(columnName, $event)"
              ></app-main-table-column-filter
            ></mat-menu>
          </ng-container>
        </th>
      </ng-container>
      <ng-template #filteringDisabledHeader>
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="columnName"
          sortActionDescription="{{ 'sortBy' | translate }} {{ columnName }}"
          [ngClass]="columnName === 'expand' ? 'expand-column' : ''"
          [disabled]="disabledColumnHeaders | contains : columnName"
        >
          <ng-container *ngIf="columnName | shouldHeaderBeDisplayed">
            {{ columnName | translate | uppercase }}
          </ng-container>
        </th>
      </ng-template>

      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        [ngClass]="{
          'expired-due-list': element.status === 'Expired',
          'expiring-due-list': element.status === 'Expiring'
        }"
      >
        <ng-container *ngIf="element | isArray; else singularElement">
          <a mat-button>
            <div *ngFor="let singleElement of element.columnName; trackBy: trackBy">
              {{ singleElement }}
            </div>
          </a>
        </ng-container>
        <ng-template #singularElement>
          <ng-container *ngIf="columnName === 'expand'; else standardColumn">
            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_down</mat-icon>
          </ng-container>
          <ng-template #standardColumn>
            <app-main-table-module-specific-template
              [module]="moduleName"
              [columnName]="columnName"
              [element]="element"
              [urlForRouting]="urlForRow"
              [specificTemplateName]="specificTemplateName"
              [buttonFunctions]="buttonFunctions | passArgumentsToObjFunctions : i"
              [hideButtons]="hideButtons"
              (click)="handleColumnClick($event, columnName, element)"
            ></app-main-table-module-specific-template>
          </ng-template>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="subHeader">
      <td mat-cell *matCellDef="let element" [attr.colspan]="allDisplayedColumns.length">
        {{ !!element['address']?.subscribe ? (element['address'] | async) : element['address'] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="maintenanceJobsGroupName">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
        class="maintenance-job-subheader-column"
      >
        <div class="maintenancce-job-shubheader-container">
          <span class="maintenance-job-group-name" [attr.title]="element.name">{{
            element.name
          }}</span>

          <span class="sub-header-badge">{{ element.expiredJobsCount }}</span>
          <span class="sub-header-attributes">
            <mat-icon
              class="material-icons-outlined"
              *ngIf="element.isCriticalComponent; else circleIcon"
              >warning</mat-icon
            >
            <mat-icon *ngIf="element.isE0Component; else circleIcon">visibility</mat-icon>
            <mat-icon
              *ngIf="element.isClassComponent; else circleIcon"
              [matTooltip]="element.classId"
              class="material-icons-outlined"
              >health_and_safety</mat-icon
            >
            <ng-template #circleIcon
              ><mat-icon class="material-icons-outlined">circle</mat-icon></ng-template
            >
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="allDisplayedColumns.length"
        class="no-padding"
      >
        <div
          class="element-detail"
          [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
        >
          <app-main-table-expandable-row
            [displayedColumns]="detailsDisplayedColumns"
            [specificTemplateName]="specificTemplateName"
            [moduleName]="moduleName"
            [parentRow]="element"
            [urlForRow]="currentUrl"
          ></app-main-table-expandable-row>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="showHeaders">
      <tr mat-header-row *matHeaderRowDef="allDisplayedColumns"></tr>
    </ng-container>
    <tr
      *matRowDef="let row; columns: allDisplayedColumns"
      mat-row
      [class.cdk-visually-hidden]="row.isSubHeader"
      [class.pointer-cursor]="
        !rowClickDisabled && (urlForRow.length > 1 || rowHandler.observers.length > 0)
      "
      [class.expanded-row]="expandedElement === row"
      [ngClass]="[trClass || '', trClassMap[row[idFieldNameForRowHandler] || row.id] || '']"
      (click)="handleRowClick(row)"
    ></tr>
    <tr
      *matRowDef="let row; columns: ['expandedDetail']"
      [class.cdk-visually-hidden]="row.isSubHeader || !isExpandable"
      mat-row
      class="detail-row"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: maintenanceSubheaderColumns"
      [class.cdk-visually-hidden]="!row.isMaintenanceJobGroup"
      class="subheader-row subheader-maintenance-job-group"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['subHeader']"
      [class.cdk-visually-hidden]="!row.isSubHeader || row.isMaintenanceJobGroup"
      class="subheader-row"
    ></tr>
    <!-- Row shown when there is no matching data. -->
    <div *ngIf="filteringEnabled">
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="filterValue" class="mat-cell" colspan="4">
          {{ 'noDataMatchingTheFilter' | translate }} "{{ filterValue }}"
        </td>
        <td *ngIf="!filterValue" class="mat-cell" colspan="4">
          {{ 'thereIsCurrentlyNoDataInTheTable' | translate }}
        </td>
      </tr>
    </div>
  </table>
  <app-main-table-paginator
    *ngIf="paginationEnabled"
    aria-label="Select page of items"
    class="paginator"
    [moduleName]="moduleNameForPaging"
    [totalPages]="totalPages"
    [additional]="additional"
    [showPageSizeSelector]="showPageSizeSelector"
    [initialPageSize]="defaultInitialPageSize"
    [ngClass]="{
      'main-table-paginator': viewMode === 'full'
    }"
  ></app-main-table-paginator>
</div>
<ng-container *ngIf="loading">
  <div class="loader" *appLoader="true"></div>
</ng-container>
