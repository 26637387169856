<h1 class="page-title-h1">{{ 'tenants' | translate | titlecase }}</h1>
<app-main-table
  [tableData]="tenants$ | async"
  [displayedColumns]="displayedColumns"
  [urlForRow]="urlForRow"
  [totalPages]="tenantTotalPages$ | async"
  [moduleNameForPaging]="moduleNameForPaging"
  (searchEmitter)="searchChanged($event)"
>
  <app-ui-button
    color="primary"
    icon="add"
    text="{{ 'newTenant' | translate }}"
    [routerLink]="addTenantRoute"
    class="add-new-item-to-table-btn"
  ></app-ui-button>
</app-main-table>
