import { LoadDepartments } from 'src/app/department/state/department.actions';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  StateToken,
  State,
  Action,
  StateContext,
  Selector,
  Actions,
  ofActionSuccessful,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserOptionsApiService } from 'src/app/core/services/user-options-api.service';
import {
  IUserAccess,
  ITenantUserAccess,
  IDepartmentInfo,
} from 'src/app/shared/user-view/models/tenant-user-access.model';
import { LoadAlerts } from 'src/app/alert/state/alert.actions';
import { patch } from '@ngxs/store/operators';
import { LoadDueListItems, LoadMyDueListItems } from 'src/app/due-list/state/due-list.actions';
import { ClearFilterDetails } from 'src/app/core/state/main-table-filter.actions';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';
import { MainTableFilteringHelperService } from 'src/app/shared/main-table-module/services/main-table-filtering-helper.service';
import { IAttachment } from 'src/app/shared/attachments/models/attachments.model';
import {
  ChangeCurrentSelectedDepartment,
  ChangeCurrentSelectedTenant,
  LoadTenantUserAccess,
  PatchProfilePicture,
} from './tenant-user-access.actions';

export interface UserSettingsState {
  loaded: boolean;
  userAccess?: IUserAccess;
  availableTenants: ITenantUserAccess[];
  availableDepartments: IDepartmentInfo[];
  currentTenant?: ITenantUserAccess;
  currentDepartment?: IDepartmentInfo;
  privileges: any[];
  privilegesFlat: string[];
}

const USER_SETTINGS_STATE_TOKEN = new StateToken<UserSettingsState>('userSettings');
const LAST_SELECTED_TENANT_ID = 'lastSelectedTenantId';
const LAST_SELECTED_DEPARTMENT_ID = 'lastSelectedDepartmentId';
export const ALL_DEPS_TOKEN = 'all_deps';

@State<UserSettingsState>({
  name: USER_SETTINGS_STATE_TOKEN,
  defaults: {
    loaded: false,
    userAccess: undefined,
    availableTenants: [],
    availableDepartments: [],
    currentTenant: undefined,
    currentDepartment: undefined,
    privileges: [],
    privilegesFlat: [],
  },
})
@Injectable()
export class TenantUserAccessState {
  constructor(
    private service: UserOptionsApiService,
    private zone: NgZone,
    private router: Router,
    private toastService: ToastService,
    private actions: Actions
  ) {
    this.actions
      .pipe(
        ofActionSuccessful(ChangeCurrentSelectedDepartment, ChangeCurrentSelectedTenant),
        tap(() => {
          MainTableFilteringHelperService.clear();
        })
      )
      .subscribe();
  }

  @Action(LoadTenantUserAccess, { cancelUncompleted: true })
  fetchTimeEntries(ctx: StateContext<UserSettingsState>, { tenantId }: LoadTenantUserAccess) {
    return this.service.getUserAccess(tenantId ?? '').pipe(
      tap((result: IUserAccess) => {
        const state = ctx.getState();

        if (result.tenantInfos.length === 0) {
          ctx.setState({
            ...state,
            loaded: true,
            userAccess: result,
          });
        } else {
          const currentTenant = TenantUserAccessState.getLastSelectedTenantId()
            ? (result.tenantInfos.find(
                ({ id }) => id === TenantUserAccessState.getLastSelectedTenantId()
              ) as ITenantUserAccess)
            : result.tenantInfos[0];

          const currentDepartment = this.getCurrentDepartment(currentTenant);
          ctx.setState({
            ...state,
            loaded: true,
            userAccess: result,
            currentTenant,
            availableTenants: result.tenantInfos,
            availableDepartments: currentTenant.departmentInfos,
            currentDepartment,
            privileges: currentTenant.privileges,
            privilegesFlat: this.getPrivilegesFlatList(currentTenant.privileges),
          });

          ctx.dispatch(new LoadDueListItems());
        }
      })
    );
  }

  @Action(ChangeCurrentSelectedTenant, { cancelUncompleted: true })
  changeCurrentSelectedTenant(
    ctx: StateContext<UserSettingsState>,
    { selectedTenantId }: ChangeCurrentSelectedTenant
  ) {
    TenantUserAccessState.clearSelectedTenant();
    TenantUserAccessState.setSelectedTenant(selectedTenantId);
    const { availableTenants } = ctx.getState();
    const newSelectedTenant = availableTenants.find(
      (tenant: ITenantUserAccess) => tenant.id === selectedTenantId
    );
    if (newSelectedTenant) {
      const alertsFilterAll = new MainTableFilterModel(MainTableFilterModulesEnum.Alerts);
      alertsFilterAll.pageIndex = 0;
      // eslint-disable-next-line no-magic-numbers
      alertsFilterAll.pageSize = 10000;
      const currentDepartment = this.getCurrentDepartment(newSelectedTenant);
      ctx.setState(
        patch({
          currentTenant: newSelectedTenant,
          availableDepartments: newSelectedTenant.departmentInfos,
          currentDepartment,
          privileges: newSelectedTenant.privileges,
          privilegesFlat: this.getPrivilegesFlatList(newSelectedTenant.privileges),
        })
      );
      this.zone.run(() => {
        ctx.dispatch([
          new LoadAlerts(alertsFilterAll),
          new LoadDepartments(),
          new LoadDueListItems(),
          new ClearFilterDetails(),
        ]);
        this.toastService.success('tenantChangedSuccessfully');
        this.router.navigate(['/']);
      });
    } else {
      this.toastService.success('somethingWentWrong');
    }
  }

  @Action(ChangeCurrentSelectedDepartment, { cancelUncompleted: true })
  changeCurrentSelectedDepartment(
    ctx: StateContext<UserSettingsState>,
    { selectedDepartmentId, withNavigation }: ChangeCurrentSelectedDepartment
  ) {
    if (selectedDepartmentId !== ALL_DEPS_TOKEN) {
      this.changeToSelectedDepartment(ctx, selectedDepartmentId);
    } else {
      TenantUserAccessState.clearSelectedDepartment();
      ctx.setState(
        patch({
          currentDepartment: { id: ALL_DEPS_TOKEN } as IDepartmentInfo,
        })
      );
      if (withNavigation) {
        ctx.dispatch([new LoadMyDueListItems(), new ClearFilterDetails()]);
        this.router.navigate(['/due-list']);
      }
    }
  }

  @Action(PatchProfilePicture)
  patchProfilePicture(
    ctx: StateContext<UserSettingsState>,
    { profilePictureLink }: PatchProfilePicture
  ) {
    const state = ctx.getState();
    const profilePictureMiniature: IAttachment = {
      id: '',
      name: '',
      categories: [],
      type: '',
      isNew: false,
      fileSize: 0,
      link: profilePictureLink,
    };
    ctx.setState(
      patch({
        userAccess: {
          ...(state.userAccess as IUserAccess),
          profilePictureMiniature: profilePictureLink ? profilePictureMiniature : undefined,
        },
      })
    );
  }

  @Selector()
  static tenantLoaded(state: UserSettingsState) {
    return state.loaded;
  }

  @Selector()
  static currentTenant(state: UserSettingsState): ITenantUserAccess {
    return state.currentTenant as ITenantUserAccess;
  }

  @Selector()
  static currentDepartment(state: UserSettingsState): IDepartmentInfo {
    return state.currentDepartment as IDepartmentInfo;
  }

  @Selector()
  static userAccess(state: UserSettingsState) {
    return state.userAccess as IUserAccess;
  }

  @Selector()
  static getPrivileges(state: UserSettingsState) {
    return state.privileges as any[];
  }

  @Selector()
  static isProductOwner(state: UserSettingsState) {
    return state.userAccess?.isProductOwner;
  }

  @Selector()
  static getAllAvailableTenants(state: UserSettingsState): ITenantUserAccess[] {
    return state.availableTenants;
  }

  @Selector()
  static getAllAvailableDepartments(state: UserSettingsState): IDepartmentInfo[] {
    return state.availableDepartments;
  }

  @Selector()
  static currentTenantId(state: UserSettingsState): string {
    return state.currentTenant?.id as string;
  }

  @Selector()
  static currentDepartmentId(state: UserSettingsState): string {
    return state.currentDepartment?.id as string;
  }

  @Selector()
  static getPrivilegesFlat(state: UserSettingsState): string[] {
    return state.privilegesFlat;
  }

  private changeToSelectedDepartment(
    ctx: StateContext<UserSettingsState>,
    selectedDepartmentId: string
  ) {
    const { availableDepartments } = ctx.getState();
    const newSelectedDepartment = availableDepartments.find(
      (dep: IDepartmentInfo) => dep.id === selectedDepartmentId
    );
    if (newSelectedDepartment) {
      TenantUserAccessState.setSelectedDepartment(selectedDepartmentId);
      ctx.setState(
        patch({
          currentDepartment: newSelectedDepartment,
        })
      );
      this.zone.run(() => {
        this.toastService.success('departmentChangedSuccessfully');
        ctx.dispatch([new LoadAlerts(), new LoadDueListItems(), new ClearFilterDetails()]);
        this.router.navigate(['/']);
      });
    } else {
      this.toastService.success('somethingWentWrong');
    }
  }

  private getPrivilegesFlatList(privilegesGrouped: any[]): string[] {
    let flatPrivileges: string[] = [];
    privilegesGrouped.forEach((groupedPrivilege: any) => {
      if (groupedPrivilege.privileges?.length > 0) {
        flatPrivileges = flatPrivileges.concat(groupedPrivilege.privileges);
      }
    });
    return flatPrivileges;
  }

  private getCurrentDepartment(tenant: ITenantUserAccess): any {
    return TenantUserAccessState.getLastSelectedDepartmentId()
      ? (tenant.departmentInfos.find(
          ({ id }) => id === TenantUserAccessState.getLastSelectedDepartmentId()
        ) as IDepartmentInfo)
      : tenant.departmentInfos[0];
  }

  static getLastSelectedTenantId(): string {
    return localStorage.getItem(LAST_SELECTED_TENANT_ID) as string;
  }

  static getLastSelectedDepartmentId(): string {
    return localStorage.getItem(LAST_SELECTED_DEPARTMENT_ID) as string;
  }

  static setSelectedTenant(tenantId: string): void {
    localStorage.setItem(LAST_SELECTED_TENANT_ID, tenantId);
  }

  static setSelectedDepartment(departmentId: string): void {
    localStorage.setItem(LAST_SELECTED_DEPARTMENT_ID, departmentId);
  }

  static clearSelectedDepartment(): void {
    localStorage.removeItem(LAST_SELECTED_DEPARTMENT_ID);
  }

  static clearSelectedTenant(): void {
    localStorage.removeItem(LAST_SELECTED_TENANT_ID);
    localStorage.removeItem(LAST_SELECTED_DEPARTMENT_ID);
  }
}
