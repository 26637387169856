import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { getDepartmentId, getTenantId } from 'src/app/services/store-snapshot.service';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { environment } from 'src/environments/environment';
import { IdName } from '../../shared/models/id-name';
import {
  IRunTimeRequest,
  IRunTime,
  IRunTimeCounterUpdateRequest,
  IRunTimeRecordLogs,
} from '../models/run-time.model';

const headers = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root',
})
export class RunTimeApiService {
  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/Runtimes`;
  }

  constructor(private http: HttpClient) {}

  loadRunTimesForDepartment(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IRunTime[]>> {
    let url = `${this.url}/Department`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IRunTime[]>>(url);
  }

  loadAllRunTimesForDepartment(): Observable<IdName[]> {
    const url = `${this.url}/Department/List`;
    return this.http.get<IdName[]>(url);
  }

  loadRunTimeById(id: string): Observable<IRunTime> {
    const url = `${this.url}/${id}`;
    return this.http.get<IRunTime>(url);
  }

  createRunTime(runTime: IRunTimeRequest): Observable<IRunTime> {
    return this.http.post<IRunTime>(this.url, runTime, { headers });
  }

  updateRunTime(runTime: IRunTimeRequest): Observable<IRunTime> {
    const url = `${this.url}/${runTime.id}`;
    return this.http.put<IRunTime>(url, runTime, { headers });
  }

  updateRunTimeCounter(runTime: IRunTimeCounterUpdateRequest): Observable<IRunTime> {
    const url = `${this.url}/${runTime.id}/Counter`;
    return this.http.put<IRunTime>(url, runTime, { headers });
  }

  deleteRunTime(id: string): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }

  changeOffset(runTime: IRunTimeCounterUpdateRequest): Observable<string> {
    const url = `${this.url}/${runTime.id}/Offset`;
    return this.http.put<string>(url, runTime);
  }

  loadLog(id: string): Observable<IRunTimeRecordLogs> {
    const url = `${this.url}/${id}/Log`;
    return this.http.get<IRunTimeRecordLogs>(url);
  }
}
