import { ElementRef, Injectable } from '@angular/core';
import { IdName } from '../shared/models/id-name';
import { IOption } from '../shared/models/option.model';
import { Attachment } from '../shared/models/attachment.model';
import { IAttachment } from '../shared/attachments/models/attachments.model';

@Injectable({ providedIn: 'root' })
export class FormUtilService {
  static isAttachment(obj: any): obj is Attachment {
    return (obj as Attachment)?.uri !== undefined;
  }

  static isIAttachment(obj: any): obj is Attachment {
    return (obj as IAttachment)?.link !== undefined;
  }

  static isIdName(obj: any): obj is IdName {
    return (obj as IdName)?.id !== undefined;
  }

  static mapObjectLiteralToIdName = ({ id, name }: IOption) => {
    return { id, name };
  };

  static isNotNullOrUndefined = (field: any) => {
    return field !== null && field !== undefined;
  };

  static validateImageFileType(file: File, button: ElementRef) {
    const invalidFileTypeClass = 'invalid-file-type';
    const fileName = file.name as string;
    const ext = fileName.substring(fileName.lastIndexOf('.'));
    const { classList } = button.nativeElement as HTMLButtonElement;
    if (!['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png'].includes(ext)) {
      classList.add(invalidFileTypeClass);
      return true;
    }
    classList.remove(invalidFileTypeClass);
    return false;
  }
}
