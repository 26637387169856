/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ICompetenceModel } from '../model/competence.model';

export class LoadCompetences {
  static readonly type = '[Competence] Fetch Competences';
  constructor(public isInUse?: boolean, public filter?: MainTableFilterModel) {}
}

export class LoadAllCompetences {
  static readonly type = '[Competence] Fetch All Competences';
  constructor() {}
}

export class LoadDepartmentsRelatedCompetences {
  static readonly type = '[Competence] Fetch Departments Related Competences';
  constructor(public departmentIds: string[], public departmentGroupIds: string[]) {}
}

export class AddCompetence {
  static readonly type = '[Competence] Add Competence';
  constructor(public competence: ICompetenceModel) {}
}

export class GetCompetenceById {
  static readonly type = '[Competence] Get Competence By Id';
  constructor(public id: string) {}
}

export class GetUserEquivalentCompetences {
  static readonly type = '[Competence] Get User Equivalent Competences';
  constructor(public userId: string, public competenceId: string) {}
}

export class UpdateCompetence {
  static readonly type = '[Competence] Update Competence';
  constructor(public competence: ICompetenceModel) {}
}

export class DeleteCompetence {
  static readonly type = '[Competence] Delete Competence';
  constructor(public id: string) {}
}
