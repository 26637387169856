<app-dialog-container
  title="{{ modalTitle | translate }}"
  (confirmButtonHandler)="save()"
  [isValid]="data.form.valid"
  [confirmButtonText]="saveButtonTexts[data.modalType]"
  [createPrivilege]="'MENU_CREATE'"
  [editPrivilege]="'MENU_UPDATE'"
  [deletePrivilege]="'MENU_DELETE'"
>
  <form [formGroup]="data.form">
    <div class="row-container">
      <app-ui-input
        label="{{ 'name' | translate }}"
        type="text"
        [control]="data.form.name"
        [required]="true"
      >
      </app-ui-input>
      <app-ui-select
        label="{{ 'placement' | translate }}"
        [control]="data.form.parentId"
        [options]="possibleLocationsList"
        [required]="true"
        [isMultiple]="false"
      ></app-ui-select>
      <app-ui-autocomplete
        label="{{ 'icon' | translate }}"
        [options]="icons"
        [initialValue]="data.form.icon.value"
        [searchTerm]="data.form.icon"
        [renderIcons]="true"
      >
      </app-ui-autocomplete>
    </div>
    <mat-card *ngIf="data.modalType === MenuTreeButtonAction.Delete && data?.items?.length">
      <div class="row-container">
        <h2>{{ modalDescription | translate }}</h2>
      </div>
      <div class="row-container">
        <app-menu-tree-single
          *ngFor="let item of data.items"
          [item]="item"
          [showFunctionalButtons]="false"
        ></app-menu-tree-single>
      </div>
    </mat-card>
  </form>
</app-dialog-container>
