import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-text-overflow-label',
  templateUrl: './text-overflow-label.component.html',
  styleUrls: ['./text-overflow-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextOverflowLabelComponent {
  @Input()
  @HostBinding('attr.title')
  text = '';
}
