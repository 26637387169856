<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'activePositionSelect'">
    <app-ui-select
      class="fixed-width"
      panelClass="full-width"
      [control]="element.positionControl"
      [options]="getJobPositionsForDepartment$ | async"
      [isMultiple]="false"
      [required]="true"
      appStopClickPropagation
    ></app-ui-select>
  </ng-container>
  <ng-container *ngSwitchCase="'currentActivityEndDate'">
    {{ element.currentActivityEndDate | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'userName'">
    <mat-icon class="pointer" *ngIf="element.conflictingActivities.length > 0">warning</mat-icon>
    {{ element.userName }}
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div [title]="element[columnName]">
      {{ element[columnName] }}
    </div>
  </ng-container>
</ng-container>
