/* eslint-disable max-classes-per-file */

export class LoadTenantUserAccess {
  static readonly type = '[Tenant User Access] Load tenantuserAccess via Service';
  constructor(public tenantId?: string) {}
}

export class ChangeCurrentSelectedTenant {
  static readonly type = '[Tenant User Access] Change selected Tenant';
  constructor(public selectedTenantId: string) {}
}

export class ChangeCurrentSelectedDepartment {
  static readonly type = '[Tenant User Access] Change selected Department';
  constructor(public selectedDepartmentId: string, public withNavigation: boolean) {}
}

export class PatchProfilePicture {
  static readonly type = '[Tenant User Access] Update Profile Picture';
  constructor(public profilePictureLink: string) {}
}
