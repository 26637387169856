/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IQuantityType } from '../models/quantity-type.model';

export class LoadQuantityTypes {
  static readonly type = '[Quantity Type] Load quantity types via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllQuantityTypes {
  static readonly type = '[Quantity Type] Load all quantity types via Service';
  constructor() {}
}

export class LoadQuantityTypeById {
  static readonly type = '[Quantity Type] Load a single quantity type';
  constructor(public id: string) {}
}

export class CreateQuantityType {
  static readonly type = '[Quantity Type] Create quantity type';
  constructor(public quantityType: IQuantityType) {}
}

export class UpdateQuantityType {
  static readonly type = '[Quantity Type] Update quantity type';
  constructor(public quantityType: IQuantityType) {}
}

export class DeleteQuantityType {
  static readonly type = '[Quantity Type] Delete quantity type';
  constructor(public id: string) {}
}
