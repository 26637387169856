/* eslint-disable no-magic-numbers */
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly snackBarDuration = 5000;

  constructor(
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private ngZone: NgZone
  ) {}

  error(message: string, params?: any): void {
    this.show(message, 'toast-error', this.snackBarDuration, params);
  }

  errorWithDuration(message: string, durationMs: number, params?: any): void {
    this.show(message, 'toast-error', durationMs, params);
  }

  success(message: string, params?: any): void {
    this.show(message, 'toast-success', this.snackBarDuration, params);
  }

  loading(message: string, params?: any): void {
    this.show(message, 'toast-success', 0, params); // currently classes don't matter so we can use success
  }

  private show(message: string, panelClass: string, duration: number, params?: any): void {
    this.ngZone.run(() => {
      this.matSnackBar.open(
        this.translateService.instant(message, params),
        this.translateService.instant('close'),
        {
          duration,
          panelClass,
        }
      );
    });
  }
}
