/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { INCLocationOnDepartmentHttpPostPutModel } from '../../models/location-on-department/nc-location-on-department.model';

export class LoadLocationOnDepartments {
  static readonly type = '[Location On Department] Load Location On Departments via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllLocationOnDepartments {
  static readonly type = '[Location On Department] Load All Location On Departments via Service';
  constructor() {}
}

export class LoadLocationOnDepartmentById {
  static readonly type = '[Location On Department] Load a single Location On Department';
  constructor(public id: string) {}
}

export class CreateLocationOnDepartment {
  static readonly type = '[Location On Department] Create Location On Department';
  constructor(public locationOnDepartment: INCLocationOnDepartmentHttpPostPutModel) {}
}

export class UpdateLocationOnDepartment {
  static readonly type = '[Location On Department] Update Location On Department';
  constructor(public locationOnDepartment: INCLocationOnDepartmentHttpPostPutModel) {}
}

export class DeleteLocationOnDepartment {
  static readonly type = '[Location On Department] Delete Location On Department';
  constructor(public id: string) {}
}
