<h2 mat-dialog-title>{{ 'edit' | translate }}</h2>

<form>
  <app-ui-input class="input" [control]="form.name" color="primary" [label]="'name' | translate">
  </app-ui-input>
  <app-ui-select
    [control]="form.categories"
    [options]="attachmentCategories$ | async"
    color="primary"
    [label]="'attachmentCategories' | translate"
  ></app-ui-select>
</form>
<div class="button-container">
  <app-ui-button
    [text]="'cancel' | translate"
    buttonType="secondary"
    (click)="dialogRef.close()"
  ></app-ui-button>
  <app-ui-button actionType="submit" [text]="'save' | translate" (click)="save()"></app-ui-button>
</div>
