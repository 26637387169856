import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { filter, first, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { TenantState } from './state/tenant.state';
import { LoadTenants } from './state/tenant.action';

@Injectable()
export class TenantResolver implements Resolve<Observable<any>> {
  private tenantsLoaded$: Observable<boolean> = this.store.select(TenantState.tenantsLoaded);

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.tenantsLoaded$.pipe(
      tap((tenantsLoaded) => {
        if (!tenantsLoaded) {
          this.store.dispatch(new LoadTenants());
        }
      }),
      filter((tenantsLoaded) => tenantsLoaded),
      first()
    );
  }
}
