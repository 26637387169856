<div class="column-filter-container" (click)="$event.stopPropagation()">
  <div class="column-filter-header" *ngIf="sorting">
    <button (click)="sortChange('asc')">
      <mat-icon>{{ ascIcons[columnType] }}</mat-icon>
      {{ ascLabels[columnType] | translate }}
      <mat-icon class="check-icon" *ngIf="sortDirection === 'asc' || sortDirection === 'Ascending'"
        >done</mat-icon
      >
    </button>
    <button (click)="sortChange('desc')">
      <mat-icon>{{ descIcons[columnType] }}</mat-icon>
      {{ descLabels[columnType] | translate }}
      <mat-icon
        class="check-icon"
        *ngIf="sortDirection === 'desc' || sortDirection === 'Descending'"
        >done</mat-icon
      >
    </button>
  </div>
  <div class="column-filter-content">
    <app-ui-input
      *ngIf="showSearch && isTextColumn"
      label="{{ 'search' | translate }}"
      [placeholder]="'pleaseStartTypingToFilter' | translate"
      [control]="searchControl"
    ></app-ui-input>
    <ng-container *ngIf="isDateColumn">
      <app-shared-ui-datepicker
        [required]="false"
        [control]="dateFromControl"
        label="{{ 'from' | translate | titlecase }}"
      >
      </app-shared-ui-datepicker>
      <app-shared-ui-datepicker
        [required]="false"
        [control]="dateToControl"
        label="{{ 'to' | translate | titlecase }}"
      >
      </app-shared-ui-datepicker>
    </ng-container>

    <ng-container *ngIf="isNumberColumn">
      <div class="numeric-range">
        <app-ui-input
          label="{{ 'from' | translate | titlecase }}"
          [control]="fromControl"
          type="number"
        ></app-ui-input>
        <app-ui-input
          label="{{ 'to' | translate | titlecase }}"
          [control]="toControl"
          type="number"
        ></app-ui-input>
      </div>
    </ng-container>
    <ng-container *ngIf="isBooleanColumn">
      <app-shared-ui-radio
        [control]="booleanControl"
        [options]="booleanControlOptions"
      ></app-shared-ui-radio>
    </ng-container>
    <div class="option-list">
      <app-shared-ui-checkbox
        *ngFor="let control of optionControlMap"
        label="{{ control.label || control.value | translate | titlecase }}"
        [control]="control.control"
      ></app-shared-ui-checkbox>
    </div>
  </div>
  <div class="column-filter-footer">
    <app-ui-button
      buttonType="secondary"
      [text]="'cancel' | translate"
      (click)="cancel()"
    ></app-ui-button>
    <app-ui-button
      buttonType="secondary"
      [text]="'clear' | translate"
      (click)="clear()"
    ></app-ui-button>
    <app-ui-button
      color="primary"
      [text]="'apply' | translate"
      [disabled]="!controlsValid"
      (click)="apply()"
    ></app-ui-button>
  </div>
</div>
