import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { environment } from 'src/environments/environment';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { getTenantId, getDepartmentId } from 'src/app/services/store-snapshot.service';
import { IdName } from 'src/app/shared/models/id-name';
import { IDelegateNonConformanceRequest } from '../models/delegate-non-conformance-request';
import { IDelegateNonConformanceModel } from '../models/delegate-non-conformance.model';
import { IListNonConformanceModel } from '../models/list-non-conformance-model';
import { INonConformanceModel, INonConformanceRequest } from '../models/non-conformance.model';
import { IPrintNonConformanceModel } from '../models/print-non-conformance.model';
import { NonConformanceStatus } from '../enums/non-conformance-status.enum';
import { IdComment } from '../models/id-comment.model';
import { INonConformanceVersion } from '../models/non-conformance-version.model';
import { FormDataService } from '../../shared/attachments/service/form-data.service';
import { ISignNonConformanceModel } from '../models/sign-non-conformance.model';

@Injectable({
  providedIn: 'root',
})
export class NonConformanceApiService {
  private readonly headers = { 'content-type': 'application/json' };
  private get baseUrl(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}`;
  }

  private get nonConformancesForIOUrl(): string {
    return `${this.baseUrl}/NonConformance/Interconnected`;
  }

  constructor(private http: HttpClient, private store: Store) {}

  getAllNonConformancesByState(
    state: NonConformanceStatus,
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IListNonConformanceModel[]>> {
    let params = new HttpParams();

    if (currentFilter) {
      params = params
        .set('Page', currentFilter.pageIndex.toString())
        .set('PageSize', currentFilter.pageSize.toString());
    }

    if (currentFilter?.searchTerm) {
      params = params.set('GlobalSearch', currentFilter.searchTerm);
    }

    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      params = params.set(`OrderBy[${currentFilter.orderBy}]`, currentFilter.ascDesc);
    }
    let url = '';
    if (state === NonConformanceStatus.Deleted) {
      params = params.set('OnlyDeleted', 'true');
      url = `${this.baseUrl}/NonConformance`;
    } else {
      params = params.set('State', state);
      url = `${this.baseUrl}/NonConformance/State`;
    }

    return this.http.get<ApiResponse<IListNonConformanceModel[]>>(url, { params });
  }

  getNonConformanceById(id: string): Observable<INonConformanceModel> {
    const url = `${this.baseUrl}/NonConformance/${id}`;
    return this.http.get<INonConformanceModel>(url);
  }

  getNonConformanceForIO(): Observable<IdName[]> {
    return this.http.get<IdName[]>(this.nonConformancesForIOUrl);
  }

  createNonConformance(nonConformance: INonConformanceRequest): Observable<INonConformanceModel> {
    const url = `${this.baseUrl}/NonConformance`;
    const formData = FormDataService.mapToFormData(nonConformance);
    return this.http.post<INonConformanceModel>(url, formData);
  }

  updateNonConformance(nonConformance: INonConformanceRequest): Observable<INonConformanceModel> {
    const url = `${this.baseUrl}/NonConformance`;
    const formData = FormDataService.mapToFormData(nonConformance);
    return this.http.put<INonConformanceModel>(url, formData);
  }

  deleteNonConformance(id: string): Observable<void> {
    const url = `${this.baseUrl}/NonConformance/${id}`;
    return this.http.delete<void>(url);
  }

  delegateNonConformance(
    delegateNonConformance: IDelegateNonConformanceRequest
  ): Observable<IDelegateNonConformanceModel> {
    const url = `${this.baseUrl}/NonConformance/Delegate`;
    return this.http.put<IDelegateNonConformanceModel>(url, delegateNonConformance);
  }

  closeNonConformance(request: IdComment): Observable<void> {
    const url = `${this.baseUrl}/NonConformance/Close`;
    return this.http.put<void>(url, request);
  }

  reopenNonConformance(request: IdComment): Observable<void> {
    const url = `${this.baseUrl}/NonConformance/Reopen`;
    return this.http.put<void>(url, request);
  }

  restoreNonConformance(id: string): Observable<void> {
    const url = `${this.baseUrl}/NonConformance/${id}/Restore`;
    return this.http.put<void>(url, {});
  }

  completeNonConformance(request: IdComment): Observable<void> {
    const url = `${this.baseUrl}/NonConformance/Complete`;
    return this.http.put<void>(url, request);
  }

  rejectNonConformance(request: IdComment): Observable<void> {
    const url = `${this.baseUrl}/NonConformance/Reject`;
    return this.http.put<void>(url, request);
  }

  getNonConformanceLogs(id: string): Observable<INonConformanceVersion[]> {
    const url = `${this.baseUrl}/NonConformance/${id}/Log`;
    return this.http.get<INonConformanceVersion[]>(url);
  }

  signCompletedNonConformance(request: ISignNonConformanceModel): Observable<void> {
    const url = `${this.baseUrl}/NonConformance/Sign`;
    return this.http.put<void>(url, request);
  }

  downloadNonConformanceFile(printNonConformance: IPrintNonConformanceModel): Observable<Blob> {
    let url = `${this.baseUrl}/NonConformance/${printNonConformance.id}/Print`;
    if (
      printNonConformance.includeComments ||
      printNonConformance.includeDetails ||
      printNonConformance.listAttachments
    ) {
      url += `?IncludeDetails=${printNonConformance.includeDetails}&IncludeComments=${printNonConformance.includeComments}&IncludeAttachments=${printNonConformance.listAttachments}`;
    }
    return this.http.get(url, { responseType: 'blob' });
  }
}
