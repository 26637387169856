import { Component, Inject, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogContainerComponent } from '../dialog-container/dialog-container.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [DialogContainerComponent, TranslateModule, MatDialogModule],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      entityName: string;
      message: string;
      confirmButtonText: string;
      confirmButton: 'primary' | 'warn-secondary';
    }
  ) {
    this.data.title = this.data.title ?? this.translate.instant('delete');
    this.data.message = this.data.message ?? 'youAreDeleting';
    this.data.confirmButtonText = this.data.confirmButtonText ?? this.translate.instant('confirm');
    this.data.confirmButton = this.data.confirmButton ?? 'warn-secondary';
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('30rem');
  }
}
