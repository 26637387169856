/* eslint-disable max-classes-per-file */
import { IRole } from '../models/role.model';

export class LoadRoles {
  static readonly type = '[Role] Load Roles';
  constructor() {}
}

export class LoadAccessContext {
  static readonly type = '[Role] Load Access Context';
  constructor() {}
}

export class LoadAccesses {
  static readonly type = '[Role] Load Accesses';
  constructor() {}
}

export class CreateRole {
  static readonly type = '[Role] Create Role';
  constructor(public role: IRole) {}
}

export class UpdateRole {
  static readonly type = '[Role] Update Role';
  constructor(public role: IRole) {}
}

export class DeleteRole {
  static readonly type = '[Role] Delete Role';
  constructor(public id: string) {}
}
