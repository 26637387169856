export enum PersonOnBoardType {
  Personnel,
  PAX,
}
export interface IPersonnelWithMissingConfirmation {
  id: string;
  userId: string;
  name: string;
  position: string;
  paxId: string;
  plannedStartDate: Date | null;
  plannedEndDate: Date | null;
  type: PersonOnBoardType;
}
