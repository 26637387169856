import { Injectable, NgZone } from '@angular/core';
import {
  StateToken,
  State,
  StateContext,
  Selector,
  Action,
  createSelector,
  Store,
} from '@ngxs/store';
import { patch, updateItem, insertItem, removeItem } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { UserRunTimeRoutes } from 'src/app/core/enums/routes.enum';
import { IdName } from '../../shared/models/id-name';
import { ApiResponse } from '../../core/models/api-response';
import { IRunTime, IRunTimeRecordLog, IRunTimeRecordLogs } from '../models/run-time.model';
import { RunTimeApiService } from '../services/run-time-api.service';
import {
  LoadRunTimesForDepartment,
  LoadRunTimeById,
  CreateRunTime,
  UpdateRunTime,
  DeleteRunTime,
  UpdateRunTimeCounter,
  LoadAllRunTimesForDepartment,
  LoadRunTimeLog,
  UpdateOffset,
} from './run-time.actions';

export interface RunTimeStateModel {
  runTimesLoaded: boolean;
  runTimes: IRunTime[];
  runTimeLogs: IRunTimeRecordLog[];
  allRunTimes: IdName[];
  departmenRunTimeMap: { [key: string]: IRunTime[] };
  departmentRunTimeTotalPages: number;
}

const MAINTENANCE_JOB_STATE_TOKEN = new StateToken<RunTimeStateModel>('runTime');

@State<RunTimeStateModel>({
  name: MAINTENANCE_JOB_STATE_TOKEN,
  defaults: {
    runTimesLoaded: false,
    runTimes: [],
    runTimeLogs: [],
    allRunTimes: [],
    departmenRunTimeMap: {},
    departmentRunTimeTotalPages: 0,
  },
})
@Injectable()
export class RunTimeState {
  constructor(private service: RunTimeApiService, private zone: NgZone, private store: Store) {}

  @Action(LoadRunTimesForDepartment)
  loadRunTimesForDepartment(
    { getState, setState }: StateContext<RunTimeStateModel>,
    { filter }: LoadRunTimesForDepartment
  ) {
    return this.service.loadRunTimesForDepartment(filter).pipe(
      tap((response: ApiResponse<IRunTime[]>) => {
        const currentDepartmentId = this.store.selectSnapshot(
          TenantUserAccessState.currentDepartmentId
        );
        const state = getState();
        const { runTimes } = state;
        const existingIds = runTimes.map((runTime) => runTime.id);
        const runTimeToAdd = response.data.filter((rt) => !existingIds.includes(rt.id));
        setState({
          ...state,
          runTimesLoaded: true,
          runTimes: [...runTimes, ...runTimeToAdd],
          departmenRunTimeMap: {
            ...state.departmenRunTimeMap,
            [currentDepartmentId]: response.data,
          },
          departmentRunTimeTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllRunTimesForDepartment, { cancelUncompleted: true })
  loadAllRunTimesForDepartment(ctx: StateContext<RunTimeStateModel>) {
    return this.service.loadAllRunTimesForDepartment().pipe(
      tap((allRunTimes: IdName[]) => {
        ctx.setState(patch({ allRunTimes }));
      })
    );
  }

  @Action(LoadRunTimeById, { cancelUncompleted: true })
  loadRunTimeById(
    { getState, setState }: StateContext<RunTimeStateModel>,
    { id }: LoadRunTimeById
  ) {
    const { runTimes } = getState();
    const exist = runTimes.some((i) => i.id === id);
    return this.service.loadRunTimeById(id).pipe(
      tap((runTime: IRunTime) => {
        setState(
          patch({
            runTimes: exist
              ? updateItem<IRunTime>((toUpdate) => toUpdate?.id === id, runTime)
              : insertItem<IRunTime>(runTime),
          })
        );
      })
    );
  }

  @Action(CreateRunTime)
  @OnSuccess({ url: UserRunTimeRoutes.Main, message: 'runTimeAdded' })
  createRunTime({ setState }: StateContext<RunTimeStateModel>, { runTime }: CreateRunTime) {
    return this.service.createRunTime(runTime);
  }

  @Action(UpdateRunTime)
  @OnSuccess({ url: UserRunTimeRoutes.Main, message: 'runTimeUpdated' })
  updateRunTime({ setState }: StateContext<RunTimeStateModel>, { runTime }: UpdateRunTime) {
    return this.service.updateRunTime(runTime);
  }

  @Action(UpdateRunTimeCounter)
  @OnSuccess({ message: 'runTimeCounterUpdated' })
  updateRunTimeCounter(
    { getState, dispatch }: StateContext<RunTimeStateModel>,
    { runTime }: UpdateRunTimeCounter
  ) {
    const { departmenRunTimeMap } = getState();
    const departmentId = Object.keys(departmenRunTimeMap).find((dId) =>
      departmenRunTimeMap[dId].some(({ id }) => id === runTime.id)
    );
    return this.service.updateRunTimeCounter(runTime).pipe(
      tap(() => {
        if (departmentId) {
          dispatch(new LoadRunTimesForDepartment());
        }
        this.zone.run(() => {
          this.store.dispatch(new LoadRunTimeById(runTime.id));
        });
      })
    );
  }

  @Action(UpdateOffset)
  @OnSuccess({ message: 'offsetUpdated' })
  updateOffset({ getState, dispatch }: StateContext<RunTimeStateModel>, { runTime }: UpdateOffset) {
    const { departmenRunTimeMap } = getState();
    const departmentId = Object.keys(departmenRunTimeMap).find((dId) =>
      departmenRunTimeMap[dId].some(({ id }) => id === runTime.id)
    );
    return this.service.changeOffset(runTime).pipe(
      tap(() => {
        if (departmentId) {
          dispatch(new LoadRunTimesForDepartment());
        }
        this.zone.run(() => {
          this.store.dispatch(new LoadRunTimeById(runTime.id));
        });
      })
    );
  }

  @Action(DeleteRunTime, { cancelUncompleted: true })
  @OnSuccess({ url: UserRunTimeRoutes.Main, message: 'runTimeDeleted' })
  deleteRunTime(ctx: StateContext<RunTimeStateModel>, { id }: DeleteRunTime) {
    return this.service.deleteRunTime(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            runTimes: removeItem<IRunTime>(
              (jtToDelete: IRunTime | undefined) => jtToDelete?.id === id
            ),
          })
        );
      })
    );
  }

  @Action(LoadRunTimeLog, { cancelUncompleted: true })
  loadRunTimeLog(ctx: StateContext<RunTimeStateModel>, { id }: LoadRunTimeLog) {
    return this.service.loadLog(id).pipe(
      tap((logData: IRunTimeRecordLogs) => {
        ctx.setState(patch({ runTimeLogs: logData.records }));
      })
    );
  }

  @Selector()
  static runTimes(state: RunTimeStateModel) {
    return state.runTimes;
  }

  @Selector()
  static getLogData(state: RunTimeStateModel): IRunTimeRecordLog[] {
    return state.runTimeLogs;
  }

  @Selector()
  static allRunTimes(state: RunTimeStateModel): IdName[] {
    return state.allRunTimes;
  }

  @Selector()
  static getRunTimeById(state: RunTimeStateModel) {
    return (id: string) => {
      return state.runTimes.find((runTime: IRunTime) => runTime.id === id);
    };
  }

  static getRunTimesForDepartment(departmentId: string) {
    return createSelector([RunTimeState], (state: RunTimeStateModel) => {
      return state.departmenRunTimeMap[departmentId];
    });
  }

  @Selector()
  static getDepartmentRunTimeTotalPages(state: RunTimeStateModel): number {
    return state.departmentRunTimeTotalPages;
  }
}
