<ng-container [ngSwitch]="specificTemplateName">
  <ng-container *ngSwitchCase="tenantTableNames.Contacts">
    <app-contacts-template
      [columnName]="columnName"
      [element]="element"
      [hideButtons]="hideButtons"
      [buttonFunctions]="buttonFunctions"
    ></app-contacts-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container [ngSwitch]="columnName">
      <ng-container *ngSwitchCase="'status'">
        <app-ui-pill [name]="element[columnName] | lowercase"></app-ui-pill>
      </ng-container>
      <ng-container *ngSwitchCase="'moduleList'">
        <div *ngFor="let module of element[columnName]">{{ module | translate }}</div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
