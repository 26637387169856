/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import {
  IPlannedActivityRequest,
  IPlannedActivityByIdModel,
} from '../../models/planned-activity.model';

export class LoadPlannedActivities {
  static readonly type = '[PlannedActivities] Load Planned Activities';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadPlannedActivitiesUsers {
  static readonly type = '[PlannedActivities] Load Planned Activities Users';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadConflictingActivitiesForUser {
  static readonly type = '[PlannedActivities] Load Conflicting Activities For Users';
  constructor(
    public from: Date,
    public to: Date,
    public userId: string,
    public plannedActivityId: string
  ) {}
}

export class LoadPlannedActivityById {
  static readonly type = '[PlannedActivities] Load Planned Activity By Id';
  constructor(public plannedActivityId: string) {}
}

export class CreatePlannedActivity {
  static readonly type = '[PlannedActivities] Create Planned Activity';
  constructor(public plannedActivity: IPlannedActivityRequest) {}
}

export class UpdatePlannedActivity {
  static readonly type = '[PlannedActivities] Update Planned Activity';
  constructor(public plannedActivity: IPlannedActivityByIdModel) {}
}

export class DeletePlannedActivity {
  static readonly type = '[PlannedActivities] Delete Planned Activity';
  constructor(public plannedActivityId: string) {}
}
