import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { UiPillStatusEnum } from 'src/app/shared/enums/ui-pill-status.enum';
import { ProductStatus } from 'src/app/storage-user/enums/product-status.enum';

import { UseStockModalComponent } from 'src/app/shared/stock-module/use-stock-modal/use-stock-modal.component';
import { ModuleName } from 'src/app/shared/enums/enums';
import { UseProduct } from 'src/app/storage-user/state/product.actions';
import { mapStock } from 'src/app/storage-user/models/use-product.model';
import { IStock } from 'src/app/shared/models/stock.model';

@Component({
  selector: 'app-storage-template',
  templateUrl: './storage-template.component.html',
  styleUrls: ['./storage-template.component.scss'],
})
export class StorageTemplateComponent implements OnInit {
  @Input() columnName: string;
  @Input() element: any;
  statusColor: string;

  private statusMapper = {
    [ProductStatus.Green]: UiPillStatusEnum.Ok,
    [ProductStatus.Yellow]: UiPillStatusEnum.UnderThreshold,
    [ProductStatus.Red]: UiPillStatusEnum.UnderMinimum,
    [ProductStatus.Blue]: UiPillStatusEnum.ProductInOrder,
  };

  constructor(private dialog: MatDialog, private store: Store) {}

  ngOnInit(): void {
    this.statusColor = this.statusMapper[this.element.status as ProductStatus];
  }

  use(): void {
    this.dialog
      .open(UseStockModalComponent, {
        width: '30rem',
        data: {
          id: `${this.element.id}`,
          currentStock: `${this.element.quantity}`,
          module: ModuleName.STORAGE,
        },
      })
      .afterClosed()
      .subscribe((result: IStock) => {
        if (result) {
          const useProduct = mapStock(result);
          this.store.dispatch(new UseProduct(useProduct));
        }
      });
  }
}
