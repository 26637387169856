/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IDrillCreate, IDrillPerformRequest, IDrillPostpone } from '../models/drills.model';

export class LoadDrills {
  static readonly type = '[Drills] Load drills';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadDrillById {
  static readonly type = '[Drills] Load drill by id';
  constructor(public drillId: string) {}
}

export class LoadDrillLog {
  static readonly type = '[Drills] Load drill log by id';
  constructor(public drillId: string) {}
}

export class LoadDrillPerform {
  static readonly type = '[Drills] Load drill version by id';
  constructor(public drillId: string, public drillVersionId: string) {}
}

export class CreateDrill {
  static readonly type = '[Drills] Create drill';
  constructor(public drill: IDrillCreate) {}
}

export class UpdateDrill {
  static readonly type = '[Drills] Update drill';
  constructor(public drill: IDrillCreate) {}
}

export class DeleteDrill {
  static readonly type = '[Drills] Delete drill';
  constructor(public drillId: string) {}
}

export class PostponeDrill {
  static readonly type = '[Drills] Postpone drill';
  constructor(public drillPostpone: IDrillPostpone) {}
}

export class PerformDrill {
  static readonly type = '[Drills] Perform drill';
  constructor(public drill: IDrillPerformRequest) {}
}

export class LoadDrillDraftPerform {
  static readonly type = '[Drills] Load Drill Draft Perform';
  constructor(public drillId: string) {}
}
export class SaveDrillDraftPerform {
  static readonly type = '[Drills] Save Drill Draft Perform';
  constructor(public Drill: IDrillPerformRequest) {}
}
