<div
  class="main-menu-tree-container"
  [ngClass]="{ 'editable-container-styles': showFunctionalButtons }"
  *appLoader="
    (showFunctionalButtons && (loaded$ | async) === false) ||
    (actions | actionInProgress | async) === true
  "
>
  <div class="menu-tree-buttons-container">
    <app-menu-tree-buttons
      *ngIf="isRootAddEnabled"
      [moduleNumber]="moduleNumber"
      [onlyAdd]="true"
      [tenantId]="tenantId"
    >
    </app-menu-tree-buttons>
  </div>
  <app-menu-tree-single
    *ngFor="
      let item of structurePreview;
      trackBy: trackById;
      let i = index;
      let f = first;
      let l = last
    "
    [item]="item"
    [module]="module"
    [moduleNumber]="moduleNumber"
    [tenantId]="tenantId"
    [showFunctionalButtons]="showFunctionalButtons"
    [menuItemsAsRouting]="menuItemsAsRouting"
    [mainModules]="true"
    [isTopItem]="f"
    [isLast]="l"
    [nextItem]="structurePreview[i + 1]"
    [chosenElementId]="chosenElementId"
    (chosenElementClicked)="highlightMenuItem($event)"
  ></app-menu-tree-single>
</div>
