<ng-container *ngIf="useDateTimeFormat; else datePicker">
  <mat-form-field *ngIf="control" appearance="outline">
    <mat-label *ngIf="!placeholder || control.value">{{ label }}</mat-label>
    <input
      matInput
      [min]="min"
      [max]="max"
      [placeholder]="placeholder"
      [ngxMatDatetimePicker]="picker"
      [formControl]="control"
      [required]="required"
      [tabIndex]="tabIndex"
    />
    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      #picker
      [showSpinners]="false"
      [enableMeridian]="false"
      [defaultTime]="initialTime"
    >
    </ngx-mat-datetime-picker>
  </mat-form-field>
</ng-container>
<ng-template #datePicker>
  <mat-form-field *ngIf="control" appearance="outline">
    <mat-label *ngIf="!placeholder || control.value">{{ label }}</mat-label>
    <input
      matInput
      [min]="min"
      [max]="max"
      [placeholder]="placeholder"
      [matDatepicker]="picker"
      [formControl]="control"
      [required]="required"
      (dateChange)="changeHandler.emit($event)"
      [tabIndex]="tabIndex"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker type="datetime"></mat-datepicker>
  </mat-form-field>
</ng-template>
<div class="input-description">{{ description }}</div>
