// eslint-disable-next-line no-shadow
export enum NonConformanceStatus {
  Deleted = -1,
  Completed = 1,
  Closed = 2,
  Open = 3,
}

export const NON_CONFORMANCE_STATUS_LABELS: any = {
  [NonConformanceStatus.Completed]: 'completed',
  [NonConformanceStatus.Closed]: 'closed',
  [NonConformanceStatus.Open]: 'open',
  [NonConformanceStatus.Deleted]: 'deleted',
};
