import { Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModuleName } from '../../enums/enums';
import { StockForm } from '../../forms/stock.form';
import { markControlsAsTouched } from '../../functions/mark-controls-as-touched';

const titles = {
  [ModuleName.MEDICINE]: 'useMedicine',
  [ModuleName.STORAGE]: 'useStorage',
};
@Component({
  selector: 'app-use-stock-modal',
  templateUrl: './use-stock-modal.component.html',
  styleUrls: ['./use-stock-modal.component.scss'],
})
export class UseStockModalComponent {
  title: string;
  form: StockForm;

  constructor(
    public dialogRef: MatDialogRef<UseStockModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      currentStock: number;
      module: ModuleName.MEDICINE | ModuleName.STORAGE;
    }
  ) {
    this.form = new StockForm(this.data.id, this.data.currentStock);
    this.form.amount.addValidators(Validators.max(this.data.currentStock));
    if (this.data.module === ModuleName.MEDICINE) {
      this.form.amount.setValue(0);
    }
    this.title = titles[this.data.module];
  }

  save(): void {
    markControlsAsTouched(this.form);

    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }
}
