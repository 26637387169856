import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { RoleState } from './state/role.state';
import { LoadAccesses } from './state/role.action';

@Injectable()
export class AccessResolver implements Resolve<Observable<any>> {
  accessLoaded$: Observable<boolean> = this.store.select(RoleState.accessLoaded);

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.accessLoaded$.pipe(
      tap((accessLoaded) => {
        if (!accessLoaded) {
          this.store.dispatch(new LoadAccesses());
        }
      }),
      filter((accessLoaded) => accessLoaded),
      first()
    );
  }
}
