/* eslint-disable no-magic-numbers */
export enum DocumentStatus {
  Draft = 0,
  ForApproval = 2,
  Published = 3,
  Expired = 5,
  Expiring = 6,
  Rejected = 7,
  UnderRevision = 8,
}

export const DOCUMENT_STATUS_LABELS: any = {
  [DocumentStatus.Draft]: 'draft',
  [DocumentStatus.ForApproval]: 'forApproval',
  [DocumentStatus.Published]: 'published',
  [DocumentStatus.Expired]: 'expired',
  [DocumentStatus.Expiring]: 'expiring',
  [DocumentStatus.Rejected]: 'rejected',
  [DocumentStatus.UnderRevision]: 'underRevision',
};
