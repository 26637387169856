import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { TenantState } from '../../state/tenant.state';
import { LoadTenantLog } from '../../state/tenant.action';
import { Change, ChangeType, ChangeTypeEnum } from '../../models/tenant.model';

type LogMetadata = Record<
  string,
  {
    expanded: boolean;
    expandable: boolean;
    by: string;
    date: string;
    action: string;
    changes: Change[];
    toggleRow: () => void;
  }
>;

@Component({
  selector: 'app-tenant-log',
  templateUrl: './tenant-log.component.html',
  styleUrls: ['./tenant-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantLogComponent implements OnInit {
  tenantId = this.route.snapshot.params.id;
  tenantLogs$: Observable<LogMetadata>;

  constructor(private route: ActivatedRoute, private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.tenantLogs$ = this.store.dispatch(new LoadTenantLog(this.tenantId)).pipe(
      switchMap(() => this.store.select(TenantState.getCurrentTenantLog)),
      filter((log) => !!log),
      switchMap((log) => {
        const logMetaData: LogMetadata = {};
        log?.records.forEach((record) => {
          logMetaData[record.date] = {
            expanded: false,
            expandable: record?.changes[0]?.changeType !== ChangeTypeEnum.Created,
            by: record.by,
            date: record.date,
            action: ChangeType[record?.changes[0]?.changeType],
            changes: record.changes,
            toggleRow() {
              this.expanded = !this.expanded;
            },
          };
        });
        return of(logMetaData);
      })
    );
  }
  trackBy = (index: number) => index;
}
