import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ApiResponse } from 'src/app/core/models/api-response';
import { IdName } from '../../shared/models/id-name';
import { environment } from '../../../environments/environment';
import { IAlertRequest, IAlertResponse } from '../models/alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertApiService {
  headers = { 'content-type': 'application/json' };

  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/alerts`;
  }

  constructor(private http: HttpClient) {}

  getAllAlerts(): Observable<IdName[]> {
    const url = `${this.url}/List`;
    return this.http.get<IdName[]>(url);
  }

  getAlerts(currentFilter?: MainTableFilterModel): Observable<ApiResponse<IAlertResponse[]>> {
    let url = `${this.url}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IAlertResponse[]>>(url);
  }

  getAlertById(alertId: string): Observable<IAlertResponse> {
    const url = `${this.url}/${alertId}`;
    return this.http.get<IAlertResponse>(url);
  }

  createAlert(alert: IAlertRequest): Observable<IAlertResponse> {
    return this.http.post<IAlertResponse>(this.url, alert, { headers: this.headers });
  }

  updateAlert(update: IAlertRequest): Observable<IAlertResponse> {
    const url = `${this.url}/${update.id}`;
    return this.http.put<IAlertResponse>(url, update, { headers: this.headers });
  }

  deleteAlert(id: string) {
    const url = `${this.url}/${id}`;
    return this.http.delete<IAlertResponse>(url);
  }
}
