/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ITenant, ITenantUpdate } from '../models/tenant.model';
import { WebHookApiRequest } from '../models/webhook.model';

export class LoadTenants {
  static readonly type = '[Tenant] Load Tenants';
  constructor(public filter?: MainTableFilterModel) {}
}
export class LoadAllTenants {
  static readonly type = '[Tenant] Load All Tenants';
}

export class CreateTenant {
  static readonly type = '[Tenant] Create Tenant';
  constructor(public tenant: ITenant) {}
}

export class UpdateTenant {
  static readonly type = '[Tenant] Update Tenant';
  constructor(public tenant: ITenantUpdate) {}
}

export class DeleteTenant {
  static readonly type = '[Tenant] Delete Tenant';
  constructor(public tenantId: string) {}
}

export class DeactivateTenant {
  static readonly type = '[Tenant] Deactivate Tenant';
  constructor(public tenantId: string) {}
}

export class LoadTenantById {
  static readonly type = '[Tenant] Load Tenant By Id';
  constructor(public id: string) {}
}

export class LoadTenantLog {
  static readonly type = '[Tenant] Load Tenant log by tenant id';
  constructor(public tenantId: string) {}
}

export class LoadThirdPartyIntegrationTypes {
  static readonly type = '[Tenant] Load Third Party Integration Types';
  constructor(public tenantId: string) {}
}

export class LoadThirdPartyIntegrations {
  static readonly type = '[Tenant] Load Third Party Integrations';
  constructor(public tenantId: string) {}
}

export class UpdateThirdPartyIntegrations {
  static readonly type = '[Tenant] Update Third Party Integrations';
  constructor(public webhooks: WebHookApiRequest, public tenantId: string) {}
}
