<div class="dashboard-container" *ngIf="displayDashboard">
  <span class="info-block" routerLink="/due-list">
    <div class="vertical-line red-line"></div>
    <div class="info-block-details">
      <h1>{{ expiredWarnings }}</h1>
      <h4>{{ 'expiredWarnings' | translate }}</h4>
    </div>
  </span>
  <span class="info-block" routerLink="/due-list">
    <div class="vertical-line yellow-line"></div>
    <div class="info-block-details">
      <h1>{{ soonExpiringWarnings }}</h1>
      <h4>{{ 'soonExpiringdWarnings' | translate }}</h4>
    </div>
  </span>
  <span class="info-block" routerLink="/due-list">
    <div class="vertical-line red-line"></div>
    <div class="info-block-details">
      <h1>{{ expiredCompetences }}</h1>
      <h4>{{ 'expiredCompetences' | translate }}</h4>
    </div>
  </span>
  <span class="info-block" routerLink="/due-list">
    <div class="vertical-line yellow-line"></div>
    <div class="info-block-details">
      <h1>{{ soonExpiringCompetences }}</h1>
      <h4>{{ 'soonExpiringCompetences' | translate }}</h4>
    </div>
  </span>
  <span class="info-block" routerLink="/admin/alerts">
    <div class="vertical-line"></div>
    <div class="info-block-details">
      <h1>{{ alertSum }}</h1>
      <h4>{{ 'totalAlerts' | translate }}</h4>
    </div>
  </span>
  <span class="info-block" routerLink="/non-conformances">
    <div class="vertical-line blue-line"></div>
    <div class="info-block-details">
      <h1 *ngIf="canReadNC || isUserProductOwner; else ncNA">{{ ncOpen }}</h1>
      <ng-template #ncNA>N/A</ng-template>
      <h4>{{ 'nonConfromancesOpen' | translate }}</h4>
    </div>
  </span>
  <span class="info-block" routerLink="/non-conformances">
    <div class="vertical-line green-line"></div>
    <div class="info-block-details">
      <h1 *ngIf="canReadNC || isUserProductOwner; else ncNA">{{ ncCompleted }}</h1>
      <ng-template #ncNA>N/A</ng-template>
      <h4>{{ 'nonConformancesCompleted' | translate }}</h4>
    </div>
  </span>
  <span class="info-block" routerLink="/purchases/sentToSupplier">
    <div class="vertical-line"></div>
    <div class="info-block-details">
      <h1 *ngIf="canReadOrders || isUserProductOwner; else orderNA">{{ ordersSentToSupplier }}</h1>
      <ng-template #orderNA>N/A</ng-template>
      <h4>{{ 'ordersSentToSupplier' | translate }}</h4>
    </div>
  </span>
  <!-- charts below -->
  <span
    class="pie-chart-block"
    *ngIf="ncOpen > 0 || ncCompleted > 0"
    routerLink="/non-conformances"
  >
    <app-pie-chart
      [chartName]="'nc'"
      [data1]="ncOpen"
      [data2]="ncCompleted"
      [dataColor1]="'blue'"
      [dataColor2]="'green'"
      [label]="'nonConformances' | translate"
      [label1]="'ncOpen' | translate"
      [label2]="'ncCompleted' | translate"
    ></app-pie-chart>
    <h3>{{ 'nonConformanceChart' | translate }}</h3>
  </span>
  <span class="pie-chart-block" *ngIf="warningsLoaded" routerLink="/due-list">
    <app-pie-chart
      [chartName]="'warnings'"
      [data1]="expiredWarnings"
      [data2]="soonExpiringWarnings"
      [dataColor1]="'red'"
      [dataColor2]="'yellow'"
      [label]="'dueListWarnings' | translate"
      [label1]="'expiredWarnings' | translate"
      [label2]="'soonExpiringWarnings' | translate"
    ></app-pie-chart>
    <h3>{{ 'dueListWarningsChart' | translate }}</h3>
  </span>
  <span class="pie-chart-block" *ngIf="competencesLoaded" routerLink="/due-list">
    <app-pie-chart
      [chartName]="'competences'"
      [data1]="expiredCompetences"
      [data2]="soonExpiringCompetences"
      [dataColor1]="'red'"
      [dataColor2]="'yellow'"
      [label]="'dueListCompetences' | translate"
      [label1]="'expiredCompetences' | translate"
      [label2]="'soonExpiringCompetences' | translate"
    ></app-pie-chart>
    <h3>{{ 'dueListCompetencesChart' | translate }}</h3>
  </span>
</div>
