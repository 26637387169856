import { NgxsModule } from '@ngxs/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { HashtagAddComponent } from './components/hashtag-add/hashtag-add.component';
import { HashtagState } from './state/hashtag.state';
import { MapIdNameToString } from '../shared/pipes/map-id-name-to-string.pipe';
import { UIElementsModule } from '../shared/ui-elements/ui-elements.module';
import { AppLoaderDirective } from '../shared/directives/app-loader.directive';

@NgModule({
  declarations: [HashtagAddComponent],
  imports: [
    AppLoaderDirective,
    CommonModule,
    NgxsModule.forFeature([HashtagState]),
    MapIdNameToString,

    TranslateModule,
    UIElementsModule,
  ],
  exports: [HashtagAddComponent],
})
export class HashtagModule {}
