import { Component, Input } from '@angular/core';
import { MainTableLogicService } from 'src/app/core/services/main-table-logic.service';

@Component({
  selector: 'app-document-stages-template',
  templateUrl: './document-stages-template.component.html',
  styleUrls: ['./document-stages-template.component.scss'],
})
export class DocumentStagesTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  constructor(private mainTableLogicService: MainTableLogicService) {}

  isExpired(expiryDate: string): boolean {
    return this.mainTableLogicService.isExpired(expiryDate);
  }
}
