<section [matTooltip]="matTooltip | translate" class="checkbox-section" *ngIf="control">
  <!-- eslint-disable-next-line @angular-eslint/template/accessibility-label-for -->
  <mat-checkbox
    [ngClass]="{ disabled: !hasPermission }"
    [tabIndex]="!hasPermission ? -1 : 0"
    class="checkbox-margin"
    [formControl]="control"
  ></mat-checkbox>
  <label [ngClass]="control.disabled || !hasPermission ? 'dimmedLabel' : ''">{{
    label | translate
  }}</label>
</section>
