import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-owner-users-template',
  templateUrl: './product-owner-users-template.component.html',
  styleUrls: ['./product-owner-users-template.component.scss'],
})
export class ProductOwnerUsersTemplateComponent implements OnInit {
  @Input() columnName: string;
  @Input() element: any;
  showIcon: boolean;
  ngOnInit(): void {
    this.showIcon = ['isActive', 'deleted', 'isProductOwner'].includes(this.columnName);
  }
}
