<mat-form-field *ngIf="control" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [panelClass]="'app-ui-select' + ' ' + panelClass"
    [formControl]="control"
    [multiple]="isMultiple"
    [hideSingleSelectionIndicator]="true"
    (selectionChange)="onChange($event)"
    [value]="selected"
    [disabled]="disabled"
    [required]="required"
    [compareWith]="compareWith"
    (openedChange)="focusInput()"
  >
    <input
      #searchInput
      *ngIf="!isMultiple && enableSearch"
      (keyup)="$event.stopPropagation(); onSearch($event)"
      (keydown)="$event.stopPropagation()"
      (keydown.space)="$event.preventDefault(); $event.stopPropagation()"
      class="mat-dropdown-select-input"
    />
    <mat-option *ngIf="enableBlankOption && !isMultiple"></mat-option>

    <mat-checkbox
      *ngIf="selectAll"
      class="mat-option select-all-row"
      color="primary"
      [ngClass]="{ 'mat-selected': areSomeRowsSelected || areAllRowsSelected }"
      [indeterminate]="areSomeRowsSelected"
      [checked]="areAllRowsSelected"
      (click)="$event.stopPropagation()"
      (change)="toggleSelection($event)"
    >
      {{ 'selectAll' | translate }}
    </mat-checkbox>

    <mat-option
      *ngIf="selectAllSingleChoice"
      class="mat-option select-all-row-single-choice"
      [value]="{ id: SELECT_ALL_VALUE }"
      >{{ 'all' | translate }}</mat-option
    >

    <ng-container *ngIf="returnWholeObject; else returnDataTemplate">
      <ng-container *ngTemplateOutlet="wholeObject"></ng-container>
    </ng-container>

    <ng-template #returnDataTemplate>
      <ng-container *ngIf="returnData; else returnId">
        <ng-container *ngTemplateOutlet="valueData"></ng-container>
      </ng-container>
    </ng-template>

    <ng-template #returnId>
      <ng-container *ngIf="sortByName; else unsorted">
        <mat-option *ngFor="let option of sortedOptions; trackBy: trackBy" [value]="option.id">
          {{ option.name | translate }}
        </mat-option>
      </ng-container>
    </ng-template>

    <ng-template #unsorted>
      <mat-option *ngFor="let option of options; trackBy: trackBy" [value]="option.id">
        {{ option.name | translate }}
      </mat-option>
    </ng-template>

    <ng-template #valueData>
      <mat-option *ngFor="let option of sortedOptions; trackBy: trackBy" [value]="option.data">
        {{ option.name | translate }}
      </mat-option>
    </ng-template>

    <ng-template #wholeObject>
      <mat-option *ngFor="let option of sortedOptions; trackBy: trackBy" [value]="option">
        {{ option.name | translate }}
      </mat-option>
    </ng-template>

    <mat-option disabled *ngIf="options?.length === 0">{{
      'currentlyNoDataAvailable' | translate
    }}</mat-option>
  </mat-select>
  <ng-container *ngIf="!control.pristine">
    <mat-error *ngFor="let error of control.errors | errorKeys; trackBy: trackBy">
      {{ messages[error] | translate }}
    </mat-error>
  </ng-container>
</mat-form-field>
<div class="input-description">{{ description }}</div>
