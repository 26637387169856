import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {
  HandleAccessError,
  HandleMessageAndParameters,
  HandleGenericError,
  HandleCodeAndParameters,
  HandleCodeAndMessage,
} from '../state/global-error.actions';

interface Error {
  resource: string;
  missingPrivileges: string[];
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store, private oidcSecurityService: OidcSecurityService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === HttpStatusCode.Forbidden) {
          this.handleAccessError(response);
        } else if (response.status === HttpStatusCode.BadRequest) {
          this.handleBadRequest(response);
        } else if (response.status === HttpStatusCode.NotFound) {
          this.handleBadRequest(response);
        } else if (response.status === HttpStatusCode.Unauthorized) {
          this.handleUnauthorizedError();
        } else {
          this.store.dispatch(new HandleGenericError());
        }
        return throwError(response);
      })
    );
  }

  private handleBadRequest(response: HttpErrorResponse): void {
    const { error } = response;
    const message = error?.message;
    const messageDisplayDuration = 10000;
    const code = error?.code;
    if (code && message && error?.parameters?.length > 0) {
      this.store.dispatch(new HandleMessageAndParameters(error, messageDisplayDuration));
    } else if (code && message) {
      this.store.dispatch(new HandleCodeAndMessage(error, messageDisplayDuration));
    } else if (code) {
      this.store.dispatch(new HandleCodeAndParameters(error));
    } else if (error?.errors) {
      Object.values(error.errors as { [key: string]: string[] }).forEach((error: string[]) =>
        error.forEach((errorMessage) =>
          this.store.dispatch(new HandleGenericError(errorMessage, messageDisplayDuration))
        )
      );
    } else {
      this.store.dispatch(new HandleGenericError(message || error?.title || error));
    }
  }

  private handleAccessError(response: HttpErrorResponse): void {
    const { errors } = response.error;
    if (Array.isArray(errors)) {
      this.store.dispatch(
        new HandleAccessError({
          message: 'missingPrivileges',
          parameters: {
            resource: errors.map((error: Error) => error?.resource),
            privileges: errors.map((error: Error) => {
              const missingPrivileges = error?.missingPrivileges;
              if (missingPrivileges) {
                return missingPrivileges.join(', ');
              }
              return [];
            }),
          },
        })
      );
    }
  }

  private handleUnauthorizedError(): void {
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated }) => {
      if (!isAuthenticated) {
        window.location.reload();
      }
    });
  }
}
