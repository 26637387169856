<ng-container [ngSwitch]="specificTemplateName">
  <ng-container *ngSwitchCase="taskTemplateNames.Overview">
    <app-task-overview-template [element]="element" [columnName]="columnName">
    </app-task-overview-template>
  </ng-container>
  <ng-container *ngSwitchCase="taskTemplateNames.Log">
    <app-task-log-template [element]="element" [columnName]="columnName"></app-task-log-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
