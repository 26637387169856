import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertApiService } from './services/alert-api.service';
import { MaterialModule } from '../modules/material.module';
import { AlertComponent } from './alert.component';

import { AlertRoutingModule } from './alert-routing.module';
import { AlertViewComponent } from './components/alert-view/alert-view.component';
import { AlertState } from './state/alert.state';
import { AlertAddEditComponent } from './components/alert-add-edit/alert-add-edit.component';
import { ModuleState } from '../core/state/module.state';
import { MainTableModule } from '../shared/main-table-module/main-table.module';
import { UIElementsModule } from '../shared/ui-elements/ui-elements.module';
import { AppLoaderDirective } from '../shared/directives/app-loader.directive';
import { ActionInProgressPipe } from '../shared/directives/action-in-progress.pipe';
import { ConfirmDialogDirective } from '../shared/dialogs/confirm-dialog/confirm-dialog.directive';

@NgModule({
  declarations: [AlertAddEditComponent, AlertComponent, AlertViewComponent],
  imports: [
    ActionInProgressPipe,
    NgxsModule.forFeature([AlertState, ModuleState]),
    AlertRoutingModule,
    AppLoaderDirective,
    CommonModule,
    ConfirmDialogDirective,
    MainTableModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    UIElementsModule,
  ],
  providers: [AlertApiService],
})
export class AlertModule {}
