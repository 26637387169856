import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LoadUserGroups } from './user-groups.actions';
import { IUserGroups } from '../models/user-groups.model';
import { UserGroupsService } from '../service/user-groups-api.service';

export interface UserGroupsStateModel {
  userGroupsLoaded: boolean;
  userGroups: IUserGroups[];
}

const MENU_PLACEMENT_STATE_TOKEN = new StateToken<UserGroupsStateModel>('userGroups');

@State<UserGroupsStateModel>({
  name: MENU_PLACEMENT_STATE_TOKEN,
  defaults: {
    userGroupsLoaded: false,
    userGroups: [],
  },
})
@Injectable()
export class UserGroupState {
  constructor(private service: UserGroupsService) {}

  @Action(LoadUserGroups, { cancelUncompleted: true })
  loadUserGroups({ getState, setState }: StateContext<UserGroupsStateModel>) {
    return this.service.getAllUserGroups().pipe(
      tap((userGroups: IUserGroups[]) => {
        const state = getState();
        setState({
          ...state,
          userGroupsLoaded: true,
          userGroups,
        });
      })
    );
  }

  @Selector()
  static userGroupsLoaded(state: UserGroupsStateModel) {
    return state.userGroupsLoaded;
  }

  @Selector()
  static getAllUserGroups(state: UserGroupsStateModel) {
    return state.userGroups;
  }
}
