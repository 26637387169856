/* eslint-disable max-classes-per-file */
import {
  CopyMenuModel,
  MenuItemCreateModel,
  MenuItemModel,
  MenuItemUpdateModel,
  UpdateMenuStructureOrderRequest,
} from '../models/menu-id.model';

export class LoadMenuStructure {
  static readonly type = '[Menu Tree Structure] Load Menu Structure';
  constructor() {}
}

export class LoadSpecificMenuStructure {
  static readonly type = '[Menu Tree Structure] Load Specific Menu Structure';
  constructor(public module: number, public displayName?: string, public nodeId?: string) {}
}

export class LoadSpecificMenuStructureForList {
  static readonly type = '[Menu Tree Structure] Load Specific Menu Structure For List';
  constructor(public module: number) {}
}

export class LoadDocumentMenuRootStructures {
  static readonly type = '[Menu Tree Structure] Load Document Menu Root Structures';
  constructor() {}
}

export class LoadDocumentMenuStructure {
  static readonly type = '[Menu Tree Structure] Load Document Menu Structure';
}

export class LoadFlatMenuStructureByDepartmentAndModule {
  static readonly type = '[Menu Tree Structure] Load Menu Placement By Department And Module';
  constructor(public departmentId: string, public module: number) {}
}

export class SaveMenuStructureIdNameList {
  static readonly type = '[Menu Tree Structure] Save Menu Structure as Id Name List';
  constructor(public structure: MenuItemModel[]) {}
}

export class CreateMenuStructure {
  static readonly type = '[Menu Tree Structure] Create Menu Structure';
  constructor(public menuStructure: MenuItemCreateModel, public mainRoot?: boolean) {}
}

export class UpdateMenuStructure {
  static readonly type = '[Menu Tree Structure] Update Menu Structure';
  constructor(public menuStructure: MenuItemUpdateModel, public mainRoot?: boolean) {}
}

export class DeleteMenuStructure {
  static readonly type = '[Menu Tree Structure] Delete Menu Structure';
  constructor(public id: string, public mainRoot?: boolean) {}
}

export class GetMenuStructureElementById {
  static readonly type = '[Menu Tree Structure] Get Menu Structure Element By Id';
  constructor(public id: string) {}
}

export class SetActiveMenuElement {
  static readonly type = '[Menu Tree Structure] Set Active Menu Element';
  constructor(public id: string) {}
}

export class CopyMenuStructure {
  static readonly type = '[Menu Tree Structure] Copy Menu Structure';
  constructor(public model: CopyMenuModel) {}
}

export class UpdateMenuStructureOrder {
  static readonly type = '[Menu Tree Structure] Update Menu Structure Order';
  constructor(public order: UpdateMenuStructureOrderRequest, public mainRoot: boolean) {}
}
