import { Pipe, PipeTransform } from '@angular/core';
import { Actions, ofActionDispatched, ofActionErrored, ofActionSuccessful } from '@ngxs/store';
import { Observable, merge } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'actionInProgress',
  pure: true,
  standalone: true,
})
export class ActionInProgressPipe implements PipeTransform {
  constructor(private actions$: Actions) {}

  transform(actionNames: { type: string }[], initial = false): Observable<boolean> {
    const dispatchedObservables = this.actions$.pipe(
      ofActionDispatched(...actionNames),
      // tap((action) => console.log('action dispatched', action)),
      map(() => true),
      startWith(initial)
    );

    const successfulObservables = this.actions$.pipe(
      ofActionSuccessful(...actionNames),
      // tap((action) => console.log('action successfully completed', action)),
      map(() => false)
    );

    const errorObservables = this.actions$.pipe(
      ofActionErrored(...actionNames),
      // tap((action) => console.log('action successfully completed', action)),
      map(() => false)
    );

    return merge(dispatchedObservables, successfulObservables, errorObservables).pipe(
      // eslint-disable-next-line no-console
      // tap((inProgress) => console.log('pipe inProgress', inProgress)),
      delay(0)
    );
  }
}
