import { getDepartmentId, getTenantId } from 'src/app/services/store-snapshot.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IdName } from 'src/app/shared/models/id-name';
import { IJobPositionGroupModel } from '../model/job-position-group.model';

@Injectable({
  providedIn: 'root',
})
export class JobPositionGroupApiService {
  private get baseUrl(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/Positions/PositionGroup`;
  }

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) {}

  getJobPositionGroups(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IJobPositionGroupModel[]>> {
    let url = `${this.baseUrl}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IJobPositionGroupModel[]>>(url, this.httpOptions);
  }

  getAllJobPositionGroups(): Observable<IdName[]> {
    const url = `${this.baseUrl}/List`;
    return this.http.get<IdName[]>(url);
  }

  addJobPositionGroup(positionGroup: IJobPositionGroupModel): Observable<IJobPositionGroupModel> {
    return this.http.post<IJobPositionGroupModel>(this.baseUrl, positionGroup, this.httpOptions);
  }

  getJobPositionGroupById(id: string): Observable<IJobPositionGroupModel> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<IJobPositionGroupModel>(url, this.httpOptions);
  }

  getAllJobPositionGroupsForDepartment(): Observable<IdName[]> {
    const url = `${
      environment.apiUrl
    }/api/${getTenantId()}/${getDepartmentId()}/departmentScope/JobPositionGroups`;
    return this.http.get<IdName[]>(url, this.httpOptions);
  }

  updateJobPositionGroup(
    positionGroup: IJobPositionGroupModel
  ): Observable<IJobPositionGroupModel> {
    const url = `${this.baseUrl}/${positionGroup.id}`;
    return this.http.put<IJobPositionGroupModel>(url, positionGroup, this.httpOptions);
  }

  deleteJobPositionGroup(id: string): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url);
  }
}
