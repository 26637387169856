import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class GlobalSearchForm extends UntypedFormGroup {
  readonly searchTerm = this.get('searchTerm') as UntypedFormControl;

  constructor(readonly fb: UntypedFormBuilder = new UntypedFormBuilder()) {
    super(
      fb.group({
        searchTerm: [''],
      }).controls
    );
  }
}
