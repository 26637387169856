/* eslint-disable no-unused-expressions,no-magic-numbers */
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { IDepartment } from '../models/department.model';
import { DepartmentType } from '../../shared/enums/enums';
import { AdminDepartmentRoutes } from '../../core/enums/routes.enum';
import { ToastService } from '../../core/services/toast.service';
import { FormUtilService } from '../../services/form-util.service';
import { FormDataService } from '../../shared/attachments/service/form-data.service';

@Injectable()
export class DepartmentService {
  constructor(private zone: NgZone, private router: Router, private toastService: ToastService) {}

  static convertToFormData(department: IDepartment, tenantId: string): FormData {
    const { isAttachment } = FormUtilService;
    const formData = new FormData();
    formData.append('id', department.id);
    formData.append('tenantId', tenantId);
    formData.append('name', department.name);
    formData.append('departmentCode', department.departmentCode);
    formData.append('departmentType', `${department.departmentType}`);
    department.statusId && formData.append('status', department.statusId);

    department.pausedUntilDate &&
      formData.append(
        'pausedUntilDate',
        FormDataService.dateToFormDataString(department.pausedUntilDate)
      );
    department.pausedUntilComment &&
      formData.append('pausedUntilComment', department.pausedUntilComment);
    if (!isAttachment(department?.defaultThumbnail) && !department.isDefaultThumbnailRemoved) {
      formData.append('defaultThumbnail', department?.defaultThumbnail);
    }
    formData.append('isDefaultThumbnailRemoved', `${department.isDefaultThumbnailRemoved}`);
    department?.newThumbnails.forEach((thumbnail) => {
      formData.append(`newThumbnails`, thumbnail);
    });
    department?.thumbnails.forEach((thumbnail) => {
      formData.append(`thumbnails`, thumbnail.id);
    });
    department.modules.forEach((module) => formData.append('modules', `${module}`));

    this.appendVesselData(formData, department);
    this.appendContactPeople(department, formData);

    // land based props
    formData.append('imoNumber', department.imoNumber ?? '');
    this.appendContactInformation(formData, department);
    this.appendDeliveryInformation(department, formData);

    return formData;
  }

  private static appendVesselData(formData: FormData, department: IDepartment) {
    const { isNotNullOrUndefined, isIdName } = FormUtilService;
    department.vesselType &&
      formData.append('vesselType', !isIdName(department.vesselType) ? department.vesselType : '');
    formData.append('flagState', department.flagState ? `${department.flagState}` : '');
    formData.append('callSign', department.callSign ?? '');
    formData.append('operationArea', department.operationArea ?? '');
    formData.append(
      'classCompany',
      isNotNullOrUndefined(department.classCompany) ? `${department.classCompany}` : ''
    );
    formData.append('constructionYard', department.constructionYard ?? '');
    formData.append('departmentCode', department.departmentCode ?? '');

    formData.append(
      'dpClass',
      isNotNullOrUndefined(department.dpClass) ? `${department.dpClass}` : '1'
    );
    // dpClass is required even if user choose landbased dep
    formData.append(
      'iceClass',
      isNotNullOrUndefined(department.iceClass) ? `${department.iceClass}` : ''
    );
    formData.append(
      'enginePower',
      isNotNullOrUndefined(department.enginePower) ? `${department.enginePower}` : ''
    );
    formData.append(
      'engineType',
      isNotNullOrUndefined(department.engineType) ? `${department.engineType}` : ''
    );
    formData.append('homePort', department.homePort ?? '');
    formData.append('mmmsNumber', department.mmmsNumber ?? '');
    formData.append('size', isNotNullOrUndefined(department.size) ? `${department.size}` : '');
    formData.append(
      'sizeUnit',
      isNotNullOrUndefined(department.sizeUnit) ? `${department.sizeUnit}` : ''
    );
    this.appendDimension(department, formData, isNotNullOrUndefined);
    this.appendMainEngines(department, formData);
    this.appendAuxiliaryEngines(department, formData);

    department.cargoGears?.forEach((cargoGear, index) => {
      formData.append(`cargoGears[${index}].name`, cargoGear.name);
    });
    this.appendCargoHolds(department, formData);
  }

  private static appendDeliveryInformation(department: IDepartment, formData: FormData) {
    if (department.departmentType === DepartmentType.LandBased.id) {
      formData.append(
        'deliveryAddress.country',
        department.deliveryAddress?.country ? department.deliveryAddress?.country : ''
      );
      formData.append(
        'deliveryAddress.zip',
        department.deliveryAddress?.zip ? department.deliveryAddress?.zip : ''
      );
      formData.append(
        'deliveryAddress.city',
        department.deliveryAddress?.city ? department.deliveryAddress?.city : ''
      );
      formData.append(
        'deliveryAddress.address',
        department.deliveryAddress?.address ? department.deliveryAddress?.address : ''
      );
    }
  }

  private static appendContactInformation(formData: FormData, department: IDepartment) {
    formData.append(
      'contactInformation.country',
      department.contactInformation?.country ? department.contactInformation?.country : ''
    );
    formData.append(
      'contactInformation.zip',
      department.contactInformation?.zip ? department.contactInformation?.zip : ''
    );
    formData.append(
      'contactInformation.city',
      department.contactInformation?.city ? department.contactInformation?.city : ''
    );
    formData.append(
      'contactInformation.address',
      department.contactInformation?.address ? department.contactInformation?.address : ''
    );
  }

  private static appendDimension(
    department: IDepartment,
    formData: FormData,
    isNotNullOrUndefined: (field: any) => boolean
  ) {
    if (
      department?.dimension?.length ||
      department?.dimension?.breadth ||
      department?.dimension?.depth
    ) {
      formData.append(
        'Dimension.Length',
        department?.dimension?.length ? `${department.dimension.length}` : ''
      );
      formData.append(
        'Dimension.Breadth',
        department?.dimension?.breadth ? `${department.dimension.breadth}` : ''
      );
      formData.append(
        'Dimension.Depth',
        department?.dimension?.depth ? `${department.dimension.depth}` : ''
      );
      formData.append(
        'Dimension.DimensionUnit',
        isNotNullOrUndefined(department?.dimension?.dimensionsUnit)
          ? `${department.dimension.dimensionsUnit}`
          : ''
      );
    }
  }

  private static appendMainEngines(department: IDepartment, formData: FormData) {
    department.mainEngines?.forEach((mainEngine, index) => {
      formData.append(`mainEngines[${index}].name`, mainEngine.name ? mainEngine.name : '');
      formData.append(`mainEngines[${index}].power`, mainEngine.power ? `${mainEngine.power}` : '');
      formData.append(
        `mainEngines[${index}].powerType`,
        mainEngine.powerType ? `${mainEngine.powerType}` : ''
      );
    });
  }

  private static appendAuxiliaryEngines(department: IDepartment, formData: FormData) {
    department.auxiliaryEngines?.forEach((auxEngine, index) => {
      formData.append(`auxiliaryEngines[${index}].name`, auxEngine.name ? auxEngine.name : '');
      formData.append(
        `auxiliaryEngines[${index}].power`,
        auxEngine.power ? `${auxEngine.power}` : ''
      );
      formData.append(
        `auxiliaryEngines[${index}].powerType`,
        auxEngine.powerType ? `${auxEngine.powerType}` : ''
      );
    });
  }

  private static appendCargoHolds(department: IDepartment, formData: FormData) {
    department.cargoHolds?.forEach((cargoHold, index) => {
      formData.append(`cargoHolds[${index}].name`, cargoHold.name ? cargoHold.name : '');
      formData.append(`cargoHolds[${index}].volume`, cargoHold.volume ? `${cargoHold.volume}` : '');
      formData.append(
        `cargoHolds[${index}].volumeUnit`,
        cargoHold.volumeUnit ? `${cargoHold.volumeUnit}` : ''
      );
      formData.append(
        `cargoHolds[${index}].dimension.breadth`,
        cargoHold.dimension?.breadth ? `${cargoHold.dimension.breadth}` : ''
      );
      formData.append(
        `cargoHolds[${index}].dimension.depth`,
        cargoHold.dimension?.depth ? `${cargoHold.dimension.depth}` : ''
      );
      formData.append(
        `cargoHolds[${index}].dimension.height`,
        cargoHold.dimension?.depth ? `${cargoHold.dimension.depth}` : ''
      );
      formData.append(
        `cargoHolds[${index}].dimension.length`,
        cargoHold.dimension?.length ? `${cargoHold.dimension.length}` : ''
      );
      formData.append(
        `cargoHolds[${index}].dimension.dimensionUnit`,
        cargoHold.dimension?.dimensionsUnit ? `${cargoHold.dimension.dimensionsUnit}` : ''
      );
    });
  }

  private static appendContactPeople(department: IDepartment, formData: FormData) {
    department.contactPeople?.forEach((contactPerson, index) => {
      formData.append(
        `contactPeople[${index}].firstName`,
        contactPerson.firstName ? contactPerson.firstName : ''
      );
      formData.append(
        `contactPeople[${index}].lastName`,
        contactPerson.lastName ? contactPerson.lastName : ''
      );
      formData.append(
        `contactPeople[${index}].prefix`,
        contactPerson.prefix ? `${contactPerson.prefix}` : ''
      );
      formData.append(
        `contactPeople[${index}].phoneNumber`,
        contactPerson.phoneNumber ? `${contactPerson.phoneNumber}` : ''
      );
      formData.append(
        `contactPeople[${index}].email`,
        contactPerson.email ? contactPerson.email : ''
      );
      formData.append(
        `contactPeople[${index}].country`,
        contactPerson.country ? `${contactPerson.country}` : ''
      );
    });
  }
}
