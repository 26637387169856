import { getDepartmentId } from 'src/app/services/store-snapshot.service';
import { LoadQuantityTypes } from 'src/app/quantity-type/state/quantity-type.actions';
import { LoadDepartments } from 'src/app/department/state/department.actions';
import { LoadTenants } from 'src/app/tenant/state/tenant.action';
import { LoadDepartmentGroups } from 'src/app/department/state/department-group.actions';
import { LoadShifts } from 'src/app/crew-management/state/shifts/shift.actions';
import { LoadActivities } from 'src/app/crew-management/state/activities/activity.actions';
import { LoadJobPositions } from 'src/app/competence/state/job-position.actions';
import { LoadJobPositionGroups } from 'src/app/competence/state/job-position-group.actions';
import { LoadCompetenceGroups } from 'src/app/competence/state/competence-group.actions';
import { LoadCompetences } from 'src/app/competence/state/competence.actions';
import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { LoadDocuments } from 'src/app/document-user/state/document.actions';
import {
  MainTableFilterModel,
  MainTableFilterSearchByModel,
  MainTableSortingType,
} from 'src/app/shared/models/main-table-filter.model';
import { LoadUserCompetences } from 'src/app/shared/user-view/state/competence-user.actions';
import { LoadAllUsersAllTenants, LoadUsers } from 'src/app/shared/user-view/state/user.actions';
import {
  LoadPlannedActivities,
  LoadPlannedActivitiesUsers,
} from 'src/app/crew-management-user/state/planned-activities/planned-activities.actions';
import { LoadRunTimesForDepartment } from 'src/app/run-time/state/run-time.actions';
import { LoadSeverities } from 'src/app/non-conformance-admin/state/severities/severity.actions';
import {
  LoadCurrentPersonnel,
  LoadMissingConfirmations,
} from 'src/app/crew-management-user/state/current-personnel/current-personnel.actions';
import {
  LoadCurrentAndPlannedPaxOverviews,
  LoadCurrentPaxOverviews,
  LoadPastPaxOverviews,
  LoadPlannedPaxOverviews,
} from 'src/app/crew-management-user/state/pax-overview/pax-overview.actions';
import { LoadAlerts } from 'src/app/alert/state/alert.actions';
import { LoadCompetenceRequirements } from 'src/app/competence/state/competence-requirement.actions';
import { LoadPagedMedicines } from 'src/app/medicine-user/state/medicine.actions';
import { LoadProductCategories } from 'src/app/storage-admin/state/product-category.actions';
import { LoadProducts } from 'src/app/storage-user/state/product.actions';
import { LoadPaxes } from 'src/app/user/state/pax.actions';
import { LoadNonConformanceTypes } from 'src/app/non-conformance-admin/state/types/non-conformance-type.actions';
import { LoadSuppliers } from 'src/app/order-admin/state/supplier/supplier.actions';
import { LoadDrills } from 'src/app/drills-user/state/drills.actions';
import { LoadPurchasers } from 'src/app/order-admin/state/purchaser/purchaser.actions';
import { cloneDeep } from 'lodash-es';
import {
  LoadApprovedOrders,
  LoadOrdersSentToSuppliers,
  LoadPagedOrders,
} from 'src/app/order-user/state/order.actions';
import { LoadOrderTypes } from 'src/app/order-admin/state/order-types/order-type.actions';
import { LoadSeaStates } from 'src/app/non-conformance-admin/state/sea-state/non-conformance-sea-state.actions';
import { LoadLocationOnDepartments } from 'src/app/non-conformance-admin/state/location-on-department/non-conformance-location-on-department.actions';
import { LoadWeatherInformation } from 'src/app/non-conformance-admin/state/weather-information/non-conformance-weather-information.actions';
import { LoadRiskAssessments } from 'src/app/risk-assessment-user/state/risk-assessment.actions';
import { LoadRiskAssessmentValues } from 'src/app/risk-assessment-admin/state/risk-assessment-value.actions';
import { RiskAssessmentValueType } from 'src/app/risk-assessment-admin/enums/risk-assessment-value-type.enum';
import { LoadCauses } from 'src/app/non-conformance-admin/state/cause/non-conformance-cause.actions';
import { LoadCertificateTypes } from 'src/app/certificate-admin/state/certificate-types.action';
import { LoadPrivilegeTemplates } from 'src/app/competence/state/privilege-template.actions';
import { LoadTrips } from 'src/app/crew-management-user/state/trips/trip.actions';
import {
  LoadDueListDueCompetencies,
  LoadDueListWarnings,
  LoadMyDueListWarnings,
} from 'src/app/due-list/state/due-list.actions';
import {
  LoadComponentSpareParts,
  LoadJobSpareParts,
} from 'src/app/maintenance-user/state/spare-part.actions';
import { LoadProductTypes } from 'src/app/storage-admin/state/product-type.actions';
import {
  LoadComponentProductTypes,
  LoadJobProductTypes,
} from 'src/app/maintenance-user/state/product-type-user.actions';
import { LoadMyUserCompetences } from '../../shared/user-view/state/competence-user.actions';
import { LoadTasks } from '../../task-user/state/task.actions';
import { LoadDeliveryConditions } from '../../order-admin/state/delivery-condition.actions';
import { LoadAccountGroups } from '../../order-admin/state/account-group.actions';
import { LoadAccounts } from '../../order-admin/state/account.actions';
import { LoadDocumentsByMenu } from '../../document-user/state/document.actions';
import { LoadMedicineCategories } from '../../medicine-admin/state/medicine-category.actions';
import { LoadProductOwners } from '../../product-owner/state/product-owner.actions';
import { LoadJobTypes } from '../../maintenance-admin/state/job-type.actions';
import { LoadMaintenanceJobs } from '../../maintenance-user/state/maintenance-job.actions';
import { LoadNonConformances } from '../../non-conformance-user/state/non-conformance.actions';
import {
  LoadCertificateGroups,
  LoadCertificatesByMenu,
} from '../../certificate-user/state/certificate.actions';
import { MainTableFilterModulesEnum } from '../enums/main-table-filter-modules.enum';
import {
  ApplySearch,
  ApplyPaging,
  ApplyAdditional,
  ApplySorting,
  ClearFilterDetails,
  ReFetchWithLastFilters,
  ApplyColumnFilters,
  ReFetchAndClearSorting,
  ResetFilterPageIndex,
} from './main-table-filter.actions';
import { MainTableFilterIdentification } from '../models/main-table-filter-identification.model';

export interface MainTableFilterStateModel {
  filtersByModule: MainTableFilterModel[];
  currentFilter: MainTableFilterModel | undefined;
  previousModule: MainTableFilterModulesEnum | undefined;
  previousDepartment: string | undefined;
  modulePageSizes: { [key: string]: number };
  moduleSearchBy: { [key: string]: MainTableFilterSearchByModel[] };
  moduleSorting: { [key: string]: { orderBy: string; ascDesc: MainTableSortingType } };
  moduleSearchTerm: { [key: string]: string };
}

const MAIN_TABLE_FILTER_STATE_TOKEN = new StateToken<MainTableFilterStateModel>('mainTableFilter');

@State<MainTableFilterStateModel>({
  name: MAIN_TABLE_FILTER_STATE_TOKEN,
  defaults: {
    filtersByModule: [],
    currentFilter: undefined,
    previousModule: undefined,
    previousDepartment: undefined,
    modulePageSizes: {},
    moduleSearchBy: {},
    moduleSorting: {},
    moduleSearchTerm: {},
  },
})
@Injectable()
export class MainTableFilterState {
  @Action(ApplySearch, { cancelUncompleted: true })
  applySearch(
    ctx: StateContext<MainTableFilterStateModel>,
    { module, searchTerm, additional }: ApplySearch
  ) {
    const existingFilter = this.findFilter(ctx.getState().filtersByModule, module, additional);
    const newFilter = existingFilter || new MainTableFilterModel(module);
    newFilter.departmentId = existingFilter?.departmentId || getDepartmentId();
    newFilter.searchTerm = searchTerm;
    newFilter.pageIndex = 0;
    if (additional !== undefined) newFilter.additional = additional;
    if (existingFilter) {
      this.updateExistingFilter(ctx, newFilter, module, additional);
    } else {
      this.addNewFilter(ctx, newFilter);
    }
    this.setModuleSearchTerm(ctx, module, searchTerm);
    this.dispatchFilterToProperModule(newFilter, module, ctx);
  }

  @Action(ApplyAdditional, { cancelUncompleted: true })
  applyAdditional(
    ctx: StateContext<MainTableFilterStateModel>,
    { module, additional }: ApplyAdditional
  ) {
    const { filtersByModule } = ctx.getState();
    const existingFilter = this.findFilter(filtersByModule, module, additional);
    const newFilter = existingFilter || new MainTableFilterModel(module);
    newFilter.departmentId = existingFilter?.departmentId || getDepartmentId();
    newFilter.additional = additional;
    if (existingFilter) {
      newFilter.pageIndex = 0;
      this.updateExistingFilter(ctx, newFilter, module, additional);
    } else {
      this.addNewFilter(ctx, newFilter);
    }
    this.dispatchFilterToProperModule(newFilter, module, ctx);
  }

  @Action(ApplyPaging, { cancelUncompleted: true })
  applyPaging(
    ctx: StateContext<MainTableFilterStateModel>,
    { module, pageIndex, pageSize, additional }: ApplyPaging
  ) {
    const existingFilter = this.findFilter(ctx.getState().filtersByModule, module, additional);
    const existingModuleSearchBy = ctx.getState().moduleSearchBy[module];
    const existingModuleSearchTerm = ctx.getState().moduleSearchTerm[module];
    const existingModuleSorting = ctx.getState().moduleSorting[module];
    const newFilter = cloneDeep(existingFilter) || new MainTableFilterModel(module);
    newFilter.departmentId = existingFilter?.departmentId || getDepartmentId();
    newFilter.pageSize = pageSize;
    newFilter.searchBy = existingModuleSearchBy;
    newFilter.searchTerm = existingModuleSearchTerm;
    newFilter.orderBy = existingModuleSorting?.orderBy;
    newFilter.ascDesc = existingModuleSorting?.ascDesc;
    if (additional !== undefined) newFilter.additional = additional;
    if (existingFilter) {
      // if pageSize changes, then always go to pageIndex 0
      newFilter.pageIndex = pageSize !== existingFilter.pageSize ? 0 : pageIndex;
      this.updateExistingFilter(ctx, newFilter, module, additional);
    } else {
      this.addNewFilter(ctx, newFilter);
    }
    this.setModulePageSize(ctx, module, newFilter.pageSize);
    this.dispatchFilterToProperModule(newFilter, module, ctx);
  }

  @Action(ApplySorting, { cancelUncompleted: true })
  applySorting(
    ctx: StateContext<MainTableFilterStateModel>,
    { module, orderBy, ascDesc, additional }: ApplySorting
  ) {
    const existingFilter = this.findFilter(ctx.getState().filtersByModule, module, additional);
    const newFilter = existingFilter || new MainTableFilterModel(module);
    newFilter.departmentId = existingFilter?.departmentId || getDepartmentId();
    newFilter.orderBy = orderBy;
    newFilter.ascDesc = ascDesc;
    if (existingFilter) {
      this.updateExistingFilter(ctx, existingFilter, module, additional);
    } else {
      newFilter.additional = additional;
      this.addNewFilter(ctx, newFilter);
    }
    this.setModuleSorting(ctx, module, orderBy, ascDesc);
    this.dispatchFilterToProperModule(newFilter, module, ctx);
  }

  @Action(ApplyColumnFilters, { cancelUncompleted: true })
  applyColumnFilters(
    ctx: StateContext<MainTableFilterStateModel>,
    { module, filters, additional }: ApplyColumnFilters
  ) {
    const existingFilter = this.findFilter(ctx.getState().filtersByModule, module, additional);
    const newFilter = existingFilter || new MainTableFilterModel(module);
    newFilter.searchBy = filters;
    newFilter.pageIndex = 0;
    if (existingFilter) {
      this.updateExistingFilter(ctx, existingFilter, module, additional);
    } else {
      newFilter.additional = additional;
      this.addNewFilter(ctx, newFilter);
    }
    this.setModuleSearchBy(ctx, module, filters);
    this.dispatchFilterToProperModule(newFilter, module, ctx);
  }

  @Action(ReFetchWithLastFilters, { cancelUncompleted: true })
  reFetch(
    ctx: StateContext<MainTableFilterStateModel>,
    { module, additional }: ReFetchWithLastFilters
  ) {
    const existingFilter = this.findFilter(ctx.getState().filtersByModule, module, additional);
    if (existingFilter) {
      this.dispatchFilterToProperModule(existingFilter, module, ctx);
    } else {
      console.warn('ReFetch: No filter found for module: ', module);
    }
  }

  @Action(ReFetchAndClearSorting, { cancelUncompleted: true })
  reFetchAndClearSorting(
    ctx: StateContext<MainTableFilterStateModel>,
    { module, additional }: ReFetchAndClearSorting
  ) {
    const existingFilter = this.findFilter(ctx.getState().filtersByModule, module, additional);

    if (existingFilter) {
      existingFilter.orderBy = '';
      existingFilter.ascDesc = '';
      this.setModuleSorting(ctx, module, '', '');
      this.dispatchFilterToProperModule(existingFilter, module, ctx);
    } else {
      console.warn('ReFetch: No filter found for module: ', module);
    }
  }

  @Action(ClearFilterDetails, { cancelUncompleted: true })
  clearFilterDetails(ctx: StateContext<MainTableFilterStateModel>) {
    const allFilters = ctx.getState().filtersByModule;
    const clearedFilters = allFilters.map((filter: MainTableFilterModel) => {
      const clearedFilter = new MainTableFilterModel(filter.module);
      clearedFilter.pageSize = filter.pageSize;
      clearedFilter.additional = filter.additional;
      return clearedFilter;
    });
    ctx.setState(
      patch({
        filtersByModule: clearedFilters,
        moduleSearchBy: {},
        moduleSorting: {},
      })
    );
  }

  @Action(ResetFilterPageIndex, { cancelUncompleted: true })
  resetCurrentFilterPage(
    ctx: StateContext<MainTableFilterStateModel>,
    { filterIdentification }: ResetFilterPageIndex
  ) {
    const { moduleName, additional } = filterIdentification;
    const filter = this.findFilter(
      ctx.getState().filtersByModule,
      moduleName as MainTableFilterModulesEnum,
      additional
    );
    if (filter) {
      filter.pageIndex = 0;
      this.updateExistingFilter(ctx, filter, moduleName as MainTableFilterModulesEnum, additional);
    }
  }

  @Selector()
  static getAllPagingDetails(state: MainTableFilterStateModel) {
    return state.filtersByModule;
  }

  @Selector()
  static getCurrentFilterDetails(
    state: MainTableFilterStateModel
  ): MainTableFilterModel | undefined {
    return state.currentFilter;
  }

  static getModulePageSize(module: any): (state: MainTableFilterStateModel) => number | undefined {
    return createSelector([MainTableFilterState], (state: MainTableFilterStateModel) => {
      return state.modulePageSizes[module];
    });
  }

  static getPagingDetails(
    filterIdentification: MainTableFilterIdentification
  ): (state: MainTableFilterStateModel) => MainTableFilterModel | undefined {
    return createSelector([MainTableFilterState], (state: MainTableFilterStateModel) => {
      return state.filtersByModule.find(
        MainTableFilterState.findFilterFn(
          filterIdentification.moduleName as MainTableFilterModulesEnum,
          filterIdentification.additional
        )
      );
    });
  }

  private findFilter(
    filters: MainTableFilterModel[],
    module: MainTableFilterModulesEnum,
    additional: any
  ) {
    return cloneDeep(
      filters.find(MainTableFilterState.findFilterFn(module, additional)) as MainTableFilterModel
    );
  }

  private static findFilterFn(module: MainTableFilterModulesEnum, additional: any) {
    const filterFunctions: any = {
      [MainTableFilterModulesEnum.DocumentsByMenu]: (filter: MainTableFilterModel) =>
        // filter.additional.menuId === additional.menuId &&
        filter.additional.tabName === additional.tabName,
      [MainTableFilterModulesEnum.Users]: (filter: MainTableFilterModel) =>
        filter.additional.includeDeleted === additional.includeDeleted &&
        filter.additional.includePaused === additional.includePaused &&
        filter.additional.includeDeactivated === additional.includeDeactivated,
      [MainTableFilterModulesEnum.Tasks]: (filter: MainTableFilterModel) =>
        filter.additional.menuId === additional.menuId &&
        filter.additional.showDeleted === additional.showDeleted,
      [MainTableFilterModulesEnum.Certificates]: (filter: MainTableFilterModel) =>
        filter.additional.menuId === additional.menuId &&
        filter.additional.showDeleted === additional.showDeleted,
      [MainTableFilterModulesEnum.MaintenanceJobs]: (filter: MainTableFilterModel) =>
        filter.additional.componentId === additional.componentId &&
        filter.additional.showDeleted === additional.showDeleted,
      default: (filter: MainTableFilterModel) => filter.additional === additional,
    };
    return (filter: MainTableFilterModel) => {
      if (filter.module !== module) {
        return false;
      }
      const additionalFilterFunction = filterFunctions[module] || filterFunctions.default;
      return additionalFilterFunction(filter);
    };
  }

  private updateExistingFilter(
    ctx: StateContext<MainTableFilterStateModel>,
    filter: MainTableFilterModel,
    module: MainTableFilterModulesEnum,
    additional: any
  ): void {
    ctx.setState(
      patch({
        filtersByModule: updateItem<MainTableFilterModel>(
          MainTableFilterState.findFilterFn(module, additional),
          filter
        ),
        currentFilter: filter,
      })
    );
  }

  private addNewFilter(
    ctx: StateContext<MainTableFilterStateModel>,
    filter: MainTableFilterModel
  ): void {
    ctx.setState(
      patch({
        filtersByModule: append([filter]),
        currentFilter: filter,
      })
    );
  }

  private setModuleSorting(
    ctx: StateContext<MainTableFilterStateModel>,
    module: string,
    orderBy: string,
    ascDesc: MainTableSortingType
  ) {
    ctx.setState(
      patch({
        moduleSorting: {
          [module]: {
            orderBy,
            ascDesc,
          },
        },
      })
    );
  }

  private setModuleSearchBy(
    ctx: StateContext<MainTableFilterStateModel>,
    module: string,
    filters: MainTableFilterSearchByModel[]
  ) {
    ctx.setState(
      patch({
        moduleSearchBy: {
          [module]: filters,
        },
      })
    );
  }

  private setModulePageSize(
    ctx: StateContext<MainTableFilterStateModel>,
    module: string,
    pageSize: number
  ) {
    ctx.setState(
      patch({
        modulePageSizes: {
          [module]: pageSize,
        },
      })
    );
  }

  private setModuleSearchTerm(
    ctx: StateContext<MainTableFilterStateModel>,
    module: string,
    searchTerm: string
  ) {
    ctx.setState(
      patch({
        moduleSearchTerm: {
          [module]: searchTerm,
        },
      })
    );
  }

  private dispatchFilterToProperModule(
    filter: MainTableFilterModel,
    module: MainTableFilterModulesEnum,
    ctx: StateContext<MainTableFilterStateModel>
  ): void {
    switch (module) {
      case MainTableFilterModulesEnum.Certificates:
        ctx.dispatch(new LoadCertificatesByMenu(filter));
        break;
      case MainTableFilterModulesEnum.CertificateStructures:
        ctx.dispatch(new LoadCertificateGroups(filter));
        break;
      case MainTableFilterModulesEnum.Competences:
        ctx.dispatch(new LoadCompetences(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.CompetenceGroups:
        ctx.dispatch(new LoadCompetenceGroups(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.CompetenceUsers:
        ctx.dispatch(new LoadUserCompetences(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.MyCompetenceUsers:
        ctx.dispatch(new LoadMyUserCompetences(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.CompetenceRequirement:
        ctx.dispatch(new LoadCompetenceRequirements(filter));
        break;
      case MainTableFilterModulesEnum.PositionGroups:
        ctx.dispatch(new LoadJobPositionGroups(filter));
        break;
      case MainTableFilterModulesEnum.JobPositions:
        ctx.dispatch(new LoadJobPositions(filter));
        break;
      case MainTableFilterModulesEnum.Activities:
        ctx.dispatch(new LoadActivities(filter));
        break;
      case MainTableFilterModulesEnum.Shifts:
        ctx.dispatch(new LoadShifts(filter));
        break;
      case MainTableFilterModulesEnum.Trips:
        ctx.dispatch(new LoadTrips(filter));
        break;
      case MainTableFilterModulesEnum.Documents:
        ctx.dispatch(new LoadDocuments(filter, filter.additional));
        break;
      case MainTableFilterModulesEnum.DocumentsByMenu:
        ctx.dispatch(new LoadDocumentsByMenu(filter));
        break;
      case MainTableFilterModulesEnum.DepartmentGroups:
        ctx.dispatch(new LoadDepartmentGroups(filter));
        break;
      case MainTableFilterModulesEnum.JobTypes:
        ctx.dispatch(new LoadJobTypes(filter));
        break;
      case MainTableFilterModulesEnum.MaintenanceJobs:
        ctx.dispatch(new LoadMaintenanceJobs(filter));
        break;
      case MainTableFilterModulesEnum.Drills:
        ctx.dispatch(new LoadDrills(filter));
        break;
      case MainTableFilterModulesEnum.Tasks:
        ctx.dispatch(new LoadTasks(filter));
        break;
      case MainTableFilterModulesEnum.NonConformances:
        ctx.dispatch(new LoadNonConformances(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.NonConformanceTypes:
        ctx.dispatch(new LoadNonConformanceTypes(filter));
        break;
      case MainTableFilterModulesEnum.NonConformanceCauses:
        ctx.dispatch(new LoadCauses(filter));
        break;
      case MainTableFilterModulesEnum.NonConformanceSeaStates:
        ctx.dispatch(new LoadSeaStates(filter));
        break;
      case MainTableFilterModulesEnum.NonConformanceWeatherInformation:
        ctx.dispatch(new LoadWeatherInformation(filter));
        break;
      case MainTableFilterModulesEnum.NonConformanceLocationOnDepartment:
        ctx.dispatch(new LoadLocationOnDepartments(filter));
        break;
      case MainTableFilterModulesEnum.Users:
        ctx.dispatch(new LoadUsers(filter));
        break;
      case MainTableFilterModulesEnum.ProductOwnerUsers:
        ctx.dispatch(new LoadAllUsersAllTenants(filter));
        break;
      case MainTableFilterModulesEnum.PlannedActivities:
        ctx.dispatch(new LoadPlannedActivities(filter));
        break;
      case MainTableFilterModulesEnum.ProductOwner:
        ctx.dispatch(new LoadProductOwners(filter));
        break;
      case MainTableFilterModulesEnum.Tenant:
        ctx.dispatch(new LoadTenants(filter));
        break;
      case MainTableFilterModulesEnum.Departments:
        ctx.dispatch(new LoadDepartments(filter));
        break;
      case MainTableFilterModulesEnum.RunTime:
        ctx.dispatch(new LoadRunTimesForDepartment(filter));
        break;
      case MainTableFilterModulesEnum.MedicineCategories:
        ctx.dispatch(new LoadMedicineCategories(filter));
        break;
      case MainTableFilterModulesEnum.Medicines:
        ctx.dispatch(new LoadPagedMedicines(filter, filter.additional));
        break;
      case MainTableFilterModulesEnum.ProductCategories:
        ctx.dispatch(new LoadProductCategories(filter));
        break;
      case MainTableFilterModulesEnum.Products:
        ctx.dispatch(new LoadProducts(filter, filter.additional));
        break;
      case MainTableFilterModulesEnum.Severities:
        ctx.dispatch(new LoadSeverities(filter));
        break;
      case MainTableFilterModulesEnum.CurrentPersonnel:
        ctx.dispatch(new LoadCurrentPersonnel(filter));
        break;
      case MainTableFilterModulesEnum.MissingConfirmation:
        ctx.dispatch(new LoadMissingConfirmations(filter));
        break;
      case MainTableFilterModulesEnum.CurrentPaxOverview:
        ctx.dispatch(new LoadCurrentPaxOverviews(filter));
        break;
      case MainTableFilterModulesEnum.PlannedPaxOverview:
        ctx.dispatch(new LoadPlannedPaxOverviews(filter));
        break;
      case MainTableFilterModulesEnum.PastPaxOverview:
        ctx.dispatch(new LoadPastPaxOverviews(filter));
        break;
      case MainTableFilterModulesEnum.CurrentAndPlannedPaxOverview:
        ctx.dispatch(new LoadCurrentAndPlannedPaxOverviews(filter));
        break;
      case MainTableFilterModulesEnum.AdminPaxes:
        ctx.dispatch(new LoadPaxes(filter));
        break;
      case MainTableFilterModulesEnum.Alerts:
        ctx.dispatch(new LoadAlerts(filter));
        break;
      case MainTableFilterModulesEnum.Accounts:
        ctx.dispatch(new LoadAccounts(filter));
        break;
      case MainTableFilterModulesEnum.AccountGroups:
        ctx.dispatch(new LoadAccountGroups(filter));
        break;
      case MainTableFilterModulesEnum.DeliveryConditions:
        ctx.dispatch(new LoadDeliveryConditions(filter));
        break;
      case MainTableFilterModulesEnum.QuantityTypes:
        ctx.dispatch(new LoadQuantityTypes(filter));
        break;
      case MainTableFilterModulesEnum.Suppliers:
        ctx.dispatch(new LoadSuppliers(filter));
        break;
      case MainTableFilterModulesEnum.Purchasers:
        ctx.dispatch(new LoadPurchasers(filter));
        break;
      case MainTableFilterModulesEnum.PlannedActivityUsers:
        ctx.dispatch(new LoadPlannedActivitiesUsers(filter));
        break;
      case MainTableFilterModulesEnum.Orders:
        ctx.dispatch(new LoadPagedOrders(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.OrderTypes:
        ctx.dispatch(new LoadOrderTypes(filter));
        break;
      case MainTableFilterModulesEnum.ApprovedOrders:
        ctx.dispatch(new LoadApprovedOrders(filter));
        break;
      case MainTableFilterModulesEnum.OrdersSentToSupplier:
        ctx.dispatch(new LoadOrdersSentToSuppliers(filter));
        break;
      case MainTableFilterModulesEnum.RiskAssessments:
        ctx.dispatch(new LoadRiskAssessments(filter, filter.additional));
        break;
      case MainTableFilterModulesEnum.Hazards:
        ctx.dispatch(new LoadRiskAssessmentValues(RiskAssessmentValueType.Hazard, filter));
        break;
      case MainTableFilterModulesEnum.Consequences:
        ctx.dispatch(new LoadRiskAssessmentValues(RiskAssessmentValueType.Consequence, filter));
        break;
      case MainTableFilterModulesEnum.Locations:
        ctx.dispatch(new LoadRiskAssessmentValues(RiskAssessmentValueType.Location, filter));
        break;
      case MainTableFilterModulesEnum.OperationTypes:
        ctx.dispatch(new LoadRiskAssessmentValues(RiskAssessmentValueType.OperationType, filter));
        break;
      case MainTableFilterModulesEnum.MitigatingMeasures:
        ctx.dispatch(
          new LoadRiskAssessmentValues(RiskAssessmentValueType.MitigatingMeasure, filter)
        );
        break;
      case MainTableFilterModulesEnum.CertificateTypes:
        ctx.dispatch(new LoadCertificateTypes(filter));
        break;
      case MainTableFilterModulesEnum.PrivilegeTemplates:
        ctx.dispatch(new LoadPrivilegeTemplates(filter));
        break;
      case MainTableFilterModulesEnum.DueListWarnings:
        ctx.dispatch(new LoadDueListWarnings(filter));
        break;
      case MainTableFilterModulesEnum.DueListCompetences:
        ctx.dispatch(new LoadDueListDueCompetencies(filter));
        break;
      case MainTableFilterModulesEnum.DueListMyWarnings:
        ctx.dispatch(new LoadMyDueListWarnings(filter));
        break;
      case MainTableFilterModulesEnum.ComponentSpareParts:
        ctx.dispatch(new LoadComponentSpareParts(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.JobSpareParts:
        ctx.dispatch(new LoadJobSpareParts(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.ComponentProductTypes:
        ctx.dispatch(new LoadComponentProductTypes(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.JobProductTypes:
        ctx.dispatch(new LoadJobProductTypes(filter.additional, filter));
        break;
      case MainTableFilterModulesEnum.ProductTypes:
        ctx.dispatch(new LoadProductTypes(filter));
        break;
      default:
        break;
    }
  }
}
