/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadComponentProductTypes {
  static readonly type = '[Spare Part] Load Component Product Types via Service';
  constructor(public componentId: string, public filter?: MainTableFilterModel) {}
}

export class LoadJobProductTypes {
  static readonly type = '[Spare Part] Load Job Product Types via Service';
  constructor(public jobId: string, public filter?: MainTableFilterModel) {}
}

export class AttachProductTypeToComponent {
  static readonly type = '[Spare Part] Attach Product Type To Component';
  constructor(public componentId: string, public productTypeId: string) {}
}

export class DetachProductTypeFromComponent {
  static readonly type = '[Spare Part] Detach Product Type To Component';
  constructor(public componentId: string, public productTypeIds: string[]) {}
}

export class AttachProductTypeToJob {
  static readonly type = '[Spare Part] Attach Product Type To Job';
  constructor(public jobId: string, public productTypeId: string) {}
}

export class DetachProductTypeFromJob {
  static readonly type = '[Spare Part] Detach Product Type To Job';
  constructor(public jobId: string, public productTypeIds: string[]) {}
}
