import { Component, Input } from '@angular/core';
import { MaintenanceTableNames } from '../../../../../maintenance-user/enums/maintenance-table-names.enum';

@Component({
  selector: 'app-maintenance-templates',
  templateUrl: './maintenance-templates.component.html',
})
export class MaintenanceTemplatesComponent {
  @Input() columnName: string;
  @Input() element: any;
  @Input() specificTemplateName: string;
  @Input() buttonFunctions: { [key: string]: () => void };

  templates = MaintenanceTableNames;
}
