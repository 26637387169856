import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ActivityColor } from '../../enums/activity-color.enum';

@Component({
  selector: 'app-ui-activity-color',
  templateUrl: './ui-activity-color.component.html',
  styleUrls: ['./ui-activity-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiActivityColorComponent implements OnChanges {
  @Input() color: ActivityColor | null = null;
  @Input() label: string;
  activityColorCssClass = '';

  ngOnChanges() {
    if (this.label && this.color) {
      const value = this.color?.toString() ?? null;
      const activityColorValue = this.mapStrNumToActivityColor(value);
      this.activityColorCssClass = this.mapActivityColorToCssClass(activityColorValue);
    }
  }

  private mapStrNumToActivityColor(color: string | null): ActivityColor | null {
    const mapping: ReadonlyMap<string, ActivityColor> = new Map([
      ['1', ActivityColor.Red],
      ['2', ActivityColor.Yellow],
      ['3', ActivityColor.Green],
      ['4', ActivityColor.Blue],
      ['5', ActivityColor.Purple],
    ]);

    if (!color) {
      return null;
    }

    return mapping.get(color) ?? null;
  }

  private mapActivityColorToCssClass(color: ActivityColor | null): string {
    const defaultCssClass = 'activity-color--default';
    const mapping: ReadonlyMap<ActivityColor, string> = new Map([
      [ActivityColor.Blue, 'activity-color--blue'],
      [ActivityColor.Green, 'activity-color--green'],
      [ActivityColor.Purple, 'activity-color--purple'],
      [ActivityColor.Red, 'activity-color--red'],
      [ActivityColor.Yellow, 'activity-color--yellow'],
    ]);

    if (!color) {
      return defaultCssClass;
    }

    return mapping.get(color) ?? defaultCssClass;
  }
}
