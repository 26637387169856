/* eslint-disable max-classes-per-file */
import { ICompetenceRequirementModel } from 'src/app/competence/model/competence-requirement.model';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ICopyCompetenceRequirementModel } from '../model/competence-requirement-copy.model';

export class LoadCompetenceRequirements {
  static readonly type = '[COMPETENCE REQUIREMENTS] Fetch Competence Requirements';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllCompetenceRequirements {
  static readonly type = '[COMPETENCE REQUIREMENTS] Fetch All Competence Requirements';
  constructor() {}
}

export class AddCompetenceRequirement {
  static readonly type = '[COMPETENCE REQUIREMENTS] Add Competence Requirements';
  constructor(public competenceRequirement: ICompetenceRequirementModel) {}
}

export class LoadCompetenceRequirementsById {
  static readonly type = '[COMPETENCE REQUIREMENTS] Load Competence Requirements By Id';
  constructor(public id: string) {}
}

export class UpdateCompetenceRequirements {
  static readonly type = '[COMPETENCE REQUIREMENTS] Update Competence Requirements By Id';
  constructor(public competenceRequirement: ICompetenceRequirementModel) {}
}

export class DeleteCompetenceRequirement {
  static readonly type = '[COMPETENCE REQUIREMENTS] Delete Competence Requirement';
  constructor(public id: string) {}
}

export class CopyCompetenceRequirements {
  static readonly type = '[COMPETENCE REQUIREMENTS] Copy Competence Requirements';
  constructor(public copyCompetenceRequirement: ICopyCompetenceRequirementModel) {}
}
