/* eslint-disable no-magic-numbers */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapRiskAssessmentAction',
  pure: true,
  standalone: true,
})
export class MapRiskAssessmentActionPipe implements PipeTransform {
  private riskAssessmentActionMapper: { [key: number]: string } = {
    1: 'edited',
    2: 'performedAndEdited',
    3: 'performed',
  };

  transform(procurementArea: number): string {
    return this.riskAssessmentActionMapper[procurementArea];
  }
}
