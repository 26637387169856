/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { OrderStatus } from '../enums/order-status.enum';
import { IOrderRequest } from '../models/order.model';
import { IReceiveOrderLine } from '../models/receive-order-line.model';

export class LoadPagedOrders {
  static readonly type = '[Orders] Load Orders via Service';
  constructor(public orderStage: OrderStatus, public filter?: MainTableFilterModel) {}
}

export class LoadOrdersSentToSuppliers {
  static readonly type = '[Orders] Load Orders Sent To Suppliers via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadApprovedOrders {
  static readonly type = '[Orders] Load Approved Orders via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllOrders {
  static readonly type = '[Orders] Load All Orders via Service';
  constructor() {}
}

export class LoadOrderById {
  static readonly type = '[Orders] Load Order By Id via Service';
  constructor(public id: string) {}
}

export class LoadDeliveryToById {
  static readonly type = '[Orders] Load Delivery To By Id via Service';
  constructor(public id: string) {}
}

export class CreateOrder {
  static readonly type = '[Orders] Create Order';
  constructor(public order: IOrderRequest) {}
}

export class UpdateOrder {
  static readonly type = '[Orders] Update Order';
  constructor(public order: IOrderRequest) {}
}

export class DeleteOrder {
  static readonly type = '[Orders] Delete Order';
  constructor(public orderId: string) {}
}

export class PrintOrderPdf {
  static readonly type = '[Orders] Print Order as Pdf';
  constructor(public orderId: string) {}
}

export class PrintOrderExcel {
  static readonly type = '[Orders] Print Order as Excel';
  constructor(public orderId: string) {}
}

export class SendOrderToDraft {
  static readonly type = '[Orders] Send Order To Draft';
  constructor(public orderId: string) {}
}

export class SendOrderForApproval {
  static readonly type = '[Orders] Send Order For Approval';
  constructor(public orderId: string) {}
}

export class ApproveOrder {
  static readonly type = '[Orders] Approve Order';
  constructor(public orderId: string) {}
}

export class SendOrderToSupplier {
  static readonly type = '[Orders] Send Order To Supplier';
  constructor(public orderId: string, public sendEmailManually: boolean) {}
}

export class ReceiveOrder {
  static readonly type = '[Orders] Receive Order';
  constructor(public orderId: string, public receivedOrderLines: IReceiveOrderLine[]) {}
}

export class CancelOrder {
  static readonly type = '[Orders] Cancel Order';
  constructor(public orderId: string) {}
}

export class ReopenOrder {
  static readonly type = '[Orders] Reopen Order';
  constructor(public orderId: string) {}
}
