import { Store } from '@ngxs/store';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormsResponsesRoutes,
  RoutesEnum,
  UserProfileRoutes,
} from 'src/app/core/enums/routes.enum';
import { UserCompetenceRequirementType } from 'src/app/shared/enums/enums';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { CompetenceStatusEnum } from 'src/app/shared/user-view/models/competence-user.model';

@Component({
  selector: 'app-profile-competence-template',
  templateUrl: './profile-competence-template.component.html',
  styleUrls: ['./profile-competence-template.component.scss'],
})
export class ProfileCompetenceTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  @Input() urlForRouting: string;

  userId = this.store.selectSnapshot(TenantUserAccessState.userAccess).id;
  userProfileRoutes = UserProfileRoutes;
  userCompetenceRequirementType = UserCompetenceRequirementType;
  competenceStatusEnum = CompetenceStatusEnum;

  constructor(private router: Router, private store: Store) {}

  routeTo(): void {
    if (this.urlForRouting?.length > 0) {
      let id: string;
      if (this.element.id) {
        id = this.element.id;
      } else {
        id = this.element.competenceId;
      }

      this.router.navigate([this.urlForRouting, id]);
    }
  }

  routeToCustomForm(competenceId: string, formId: string): void {
    const link = FormsResponsesRoutes.List.replace(':id', competenceId);
    this.router.navigate([link], {
      queryParams: { moduleName: RoutesEnum.Profile, formId },
    });
  }
}
