<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'fullName'">
    {{ element | fullName }}
  </ng-container>
  <ng-container *ngSwitchCase="'userUpdatedDate'">
    {{ element.userUpdatedDate | date : 'yyyy-MM-dd HH:mm' }}
  </ng-container>
  <ng-container *ngSwitchCase="'tenantDepAndUpdated'">
    <div *ngFor="let employed of element.employedIn" style="display: flex">
      <div>
        {{
          employed.tenantName +
            ' / ' +
            (employed.departmentName ?? employed.departmentGroupName) +
            (employed.updated ? ' / ' : '') +
            (employed.updated | date : 'yyyy-MM-dd HH:mm') +
            (employed.deleted ? ' / ' + ('deleted' | translate) : '') +
            (employed.isPaused ? ' / ' + ('paused' | translate) : '')
        }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="showIcon; else basic">
      <ng-container *ngTemplateOutlet="showIconTemplate"></ng-container>
    </ng-container>
    <ng-template #basic> {{ element[columnName] }} </ng-template>
  </ng-container>
</ng-container>
<ng-template #showIconTemplate class="mat-icon-container">
  <mat-icon class="material-icons-round">{{ element[columnName] ? 'check' : 'close' }}</mat-icon>
</ng-template>
