<h1 class="page-title-h1">{{ 'thirdPartyIntegrations' | translate | titlecase }}</h1>
<app-main-table
  [tableData]="webhooks"
  [displayedColumns]="displayedColumns"
  [urlForRow]="urlForRow"
  (rowHandler)="showEditModal($event)"
  [hasRoutingForRow]="false"
>
  <app-ui-button
    color="primary"
    icon="add"
    text="{{ 'newIntegration' | translate }}"
    (click)="showEditModal()"
    class="add-new-item-to-table-btn"
    [disabled]="availableEvents.length === 0"
    [matTooltip]="availableEvents.length === 0 ? ('allAvailableEventsInUse' | translate) : ''"
  ></app-ui-button>
</app-main-table>
