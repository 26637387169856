/* eslint-disable max-classes-per-file */
import { IdName } from 'src/app/shared/models/id-name';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadSeaStates {
  static readonly type = '[Sea State] Load Sea States via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllSeaStates {
  static readonly type = '[Sea State] Load All Sea States via Service';
  constructor() {}
}

export class LoadSeaStateById {
  static readonly type = '[Sea State] Load a single Sea State';
  constructor(public id: string) {}
}

export class CreateSeaState {
  static readonly type = '[Sea State] Create Sea State';
  constructor(public seaState: IdName) {}
}

export class UpdateSeaState {
  static readonly type = '[Sea State] Update Sea State';
  constructor(public seaState: IdName) {}
}

export class DeleteSeaState {
  static readonly type = '[Sea State] Delete Sea State';
  constructor(public id: string) {}
}
