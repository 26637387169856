/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ICustomFormResponseCreateModel } from '../../custom-forms-responses/models/custom-form-response-create.model';
import { ICompetenceUserModel } from '../models/competence-user.model';

export class LoadUserCompetences {
  static readonly type = '[Competence User] Fetch User Competences';
  constructor(public userId?: string, public filter?: MainTableFilterModel) {}
}

export class LoadMyUserCompetences {
  static readonly type = '[Competence User] Fetch My User Competences';
  constructor(public userId?: string, public filter?: MainTableFilterModel) {}
}

export class GetUserCompetenceById {
  static readonly type = '[Competence User] Get By Id User Competences';
  constructor(public userId: string, public competenceId: string) {}
}

export class AddUserCompetence {
  static readonly type = '[Competence User] Add User Competences';
  constructor(
    public toAdd: ICompetenceUserModel,
    public formResponse?: ICustomFormResponseCreateModel
  ) {}
}

export class RenewUserCompetence {
  static readonly type = '[Competence User] Renew User Competences';
  constructor(
    public toRenew: ICompetenceUserModel,
    public formResponse?: ICustomFormResponseCreateModel
  ) {}
}

export class DeleteUserCompetence {
  static readonly type = '[Competence User] Delete User Competence';
  constructor(public userId: string, public competenceId: string) {}
}

export class UpdateUserCompetence {
  static readonly type = '[Competence User] Update User Competence';
  constructor(
    public toUpdate: Partial<ICompetenceUserModel>,
    public userId: string,
    public competenceId: string
  ) {}
}
