export enum MainModulesEnum {
  Dashboard = '',
  Forms = 'forms-library',
  Admin = 'admin',
  Tenant = 'tenants',
  Role = 'roles',
  Users = 'users',
  Alerts = 'alerts',
  ProductOwner = 'product-owner',
  Department = 'department',
  Document = 'documents',
  Certificate = 'certificate',
  Competence = 'competence',
  NonConformance = 'non-conformance',
  Medicine = 'medicine',
  Storage = 'storage',
  RiskAssessment = 'riskassessment',
  Maintenance = 'maintenance',
  Task = 'task',
  Purchase = 'purchase',
}

export enum DepartmentSubModulesEnum {
  Main = 'main',
  Groups = 'groups',
}

export enum CompetencesSubModulesEnum {
  Main = 'main',
  Groups = 'groups',
  JobPositions = 'job-positions',
  JobPositionsGroups = 'job-position-groups',
}

export enum NonConformanceSubModulesEnum {
  Main = 'main',
  TypesList = 'types/list',
  SeverityTypesList = 'severity-types/list',
}
