<mat-form-field class="search" appearance="outline" *ngIf="viewMode === 'full' && attachments">
  <mat-label>{{ 'search' | translate }}</mat-label>
  <input
    matInput
    placeholder="{{ 'exServiceReport' | translate }}"
    [formControl]="searchForm.searchedText"
  />
</mat-form-field>

<app-ui-button
  *ngIf="editMode"
  class="add-attachment-button"
  (click)="openAddDialog()"
  [disabled]="disabled"
  [icon]="addButtonIcon"
  text="{{ addButtonText | translate }}"
  [privilege]="addPrivilege"
></app-ui-button>
<app-ui-button
  *ngIf="!editMode && !passiveMode"
  class="add-attachment-button"
  (click)="enableEdit()"
  [disabled]="disabled"
  [icon]="'edit'"
  text="{{ 'edit' | translate }}"
  [privilege]="enableEditPrivilege"
></app-ui-button>
<app-ui-button
  *ngIf="editMode && !passiveMode"
  class="add-attachment-button"
  (click)="save()"
  [disabled]="disabled"
  [icon]="'save'"
  text="{{ 'save' | translate }}"
  [privilege]="savePrivilege"
></app-ui-button>
<div class="main">
  <div class="main-container">
    <div
      *ngIf="
        attachments.value?.length === 0;
        then noData;
        else filteredAttachments?.length === 0 && viewMode === 'full' ? noMatch : data
      "
    ></div>
    <ng-template #noData>
      <h3 class="statement">{{ 'noAttachments' | translate }}</h3>
    </ng-template>
    <ng-template #noMatch>
      <h3 class="statement">
        {{ 'noMatchingAttachments' | translate }} "{{ searchedText.value }}"
      </h3>
    </ng-template>
    <ng-template #data>
      <mat-card
        *ngFor="let attachment of filteredAttachments"
        [class.expanded]="expansionPanel.expanded"
        class="attachment-card"
      >
        <mat-progress-bar
          mode="determinate"
          *ngIf="downloadProgressMap[attachment.id]"
          [value]="downloadProgressMap[attachment.id].progress"
        ></mat-progress-bar>
        <div class="edit-and-delete-container">
          <a
            (click)="openInNewTab(attachment.link)"
            target="_blank"
            [download]="attachment.name"
            *ngIf="attachment.id !== 'new'"
          >
            <mat-icon class="download-icon">download</mat-icon>
          </a>
          <mat-icon class="settings-icon" [matMenuTriggerFor]="menu" *ngIf="editMode"
            >more_vert</mat-icon
          >
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openEditDialog(attachment)">
              <mat-icon>edit_note</mat-icon>
              <span>{{ 'edit' | translate }}</span>
            </button>
            <button
              mat-menu-item
              appConfirmDialog
              [confirmEntityName]="attachment.name"
              (confirmed)="delete(attachment)"
            >
              <mat-icon color="warn">delete</mat-icon>
              <span>{{ 'delete' | translate }}</span>
            </button>
          </mat-menu>
        </div>

        <img
          class="image"
          mat-card-image
          [src]="attachmentIcons[attachment.type] || attachmentIcons['other']"
          alt="{{ 'attachment' | translate }}"
        />

        <div class="attachment-info">
          <p class="attachment-name" [title]="attachment.name">{{ attachment.name }}</p>
        </div>

        <mat-accordion>
          <mat-expansion-panel #expansionPanel>
            <mat-expansion-panel-header>
              <mat-panel-title class="content">
                {{ 'attachmentInformation' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              {{ 'attachmentCategories' | translate }}
              <br />
              <span class="bold-text">{{
                attachment.categories | translateArray : 'attachmentCategory'
              }}</span>
            </p>
            <p>
              {{ 'attachmentType' | translate }} <br /><span class="bold-text">{{
                attachment.type
              }}</span>
            </p>
            <p>
              {{ 'fileSize' | translate }} <br /><span class="bold-text">{{
                attachment.fileSize
              }}</span
              >{{ 'mb' | translate }}
            </p>
            <p *ngIf="attachment.created">
              {{ 'created' | translate | lowercase }} <br /><span class="bold-text">{{
                attachment.created
              }}</span>
            </p>
            <p *ngIf="attachment.createdBy">
              {{ 'createdBy' | translate }} <br /><span class="bold-text">{{
                attachment.createdBy
              }}</span>
            </p>
            <p *ngIf="attachment.modified">
              {{ 'modified' | translate }}
              <br /><span class="bold-text">{{ attachment.modified }}</span>
            </p>
            <p *ngIf="attachment.modifiedBy">
              {{ 'modifiedBy' | translate }} <br /><span class="bold-text">{{
                attachment.modifiedBy
              }}</span>
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card>
    </ng-template>
  </div>
</div>

<app-upload-task></app-upload-task>
