<ng-container [ngSwitch]="specificTemplateName">
  <ng-container *ngSwitchCase="drillsTemplateNames.Overview">
    <app-drills-overview-template [element]="element" [columnName]="columnName">
    </app-drills-overview-template>
  </ng-container>
  <ng-container *ngSwitchCase="drillsTemplateNames.Log">
    <app-drills-log-template
      [element]="element"
      [columnName]="columnName"
    ></app-drills-log-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
