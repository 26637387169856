// eslint-disable-next-line max-classes-per-file
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { JobPositionModel } from '../model/job-position.model';

export class LoadJobPositions {
  static readonly type = '[Job Position] Fetch Job Positions';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllJobPositions {
  static readonly type = '[Job Position] Fetch All Job Positions';
  constructor() {}
}

export class LoadAllJobPositionsWithDepartments {
  static readonly type = '[Job Position] Fetch All Job Positions With Departments';
  constructor() {}
}

export class LoadAllJobPositionsForDepartment {
  static readonly type = '[Job Position] Fetch All Job Positions For Department';
  constructor(public departmentId?: string) {}
}
export class LoadAllJobPositionsForDepartmentAndGroups {
  static readonly type = '[Job Position] Fetch All Job Positions For Department And Groups';
  constructor(public departmentIds: string[], public departmentGroupIds: string[]) {}
}

export class LoadJobPositionsAndGroups {
  static readonly type = '[Job Position] Fetch Job Positions And Groups';
  constructor() {}
}

export class GetJobPositionById {
  static readonly type = '[Job Position] Get Job Position By Id';
  constructor(public id: string) {}
}

export class AddJobPosition {
  static readonly type = '[Job Position] Add Job Position';
  constructor(public jobPosition: JobPositionModel) {}
}

export class UpdateJobPosition {
  static readonly type = '[Job Position] Update Job Position';
  constructor(public jobPosition: JobPositionModel) {}
}

export class DeleteJobPosition {
  static readonly type = '[Job Position] Delete Job Position';
  constructor(public id: string) {}
}
