<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'department'">
    {{ element.departmentName }}
  </ng-container>
  <ng-container *ngSwitchCase="'departments'">
    <div *ngFor="let department of element.departments; trackBy: trackBy">
      {{ department.name }}
    </div>
    <div *ngFor="let departmentGroup of element.departmentGroups; trackBy: trackBy">
      {{ departmentGroup.name }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'color'">
    <app-ui-activity-color [color]="element.color" [label]="element.name"></app-ui-activity-color>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
