/* eslint-disable no-unused-expressions */
import { Injectable } from '@angular/core';
import { ITenant, ITenantUpdate } from '../models/tenant.model';
import { FormUtilService } from '../../services/form-util.service';

@Injectable()
export class TenantService {
  static convertUpdateToFormData(updateTenant: ITenantUpdate): FormData {
    const result = this.convertToFormData(updateTenant);
    result.append('isLogoChanged', `${updateTenant.isLogoChanged}`);
    return result;
  }

  static convertToFormData(tenant: ITenant): FormData {
    const { isNotNullOrUndefined, isAttachment } = FormUtilService;
    const formData = new FormData();

    formData.append('id', tenant.id);
    formData.append('Name', tenant.name);
    formData.append('OrganizationNumber', tenant.organizationNumber);
    if (!isAttachment(tenant?.logo)) {
      formData.append('logo', tenant?.logo);
    }
    if (!isAttachment(tenant?.predefinedValues)) {
      formData.append('predefinedValues', tenant.predefinedValues);
    }
    formData.append('soldBy', isNotNullOrUndefined(tenant.soldBy) ? `${tenant.soldBy}` : '');
    formData.append(
      'accountType',
      isNotNullOrUndefined(tenant.accountType) ? `${tenant.accountType}` : ''
    );
    formData.append('isDPAService', tenant.isDPAService ? `${tenant.isDPAService}` : 'false');
    tenant.selectDPA?.forEach((DPA) => formData.append('selectDPA', `${DPA}`));
    tenant.modules
      .filter((module) => module.selected)
      .forEach((module, index) => {
        formData.append(`modules[${index}].module`, `${module.id}`);
        formData.append(`modules[${index}].soldFor`, `${module.soldFor}`);
      });
    tenant.address.address && formData.append('address.address', tenant.address.address);
    tenant.address.zip && formData.append('address.zip', tenant.address.zip);
    tenant.address.city && formData.append('address.city', tenant.address.city);
    tenant.address.country && formData.append('address.country', tenant.address.country);

    tenant.contacts.forEach((contact, index) => {
      formData.append(
        `contacts[${index}].firstName`,
        contact.firstName ? `${contact.firstName}` : ''
      );
      formData.append(`contacts[${index}].lastName`, contact.lastName ? `${contact.lastName}` : '');
      formData.append(`contacts[${index}].prefix`, contact.prefix ? `${contact.prefix}` : '');
      formData.append(
        `contacts[${index}].phoneNumber`,
        contact.phoneNumber ? `${contact.phoneNumber}` : ''
      );
      formData.append(`contacts[${index}].position`, contact.position ? `${contact.position}` : '');
      formData.append(`contacts[${index}].email`, contact.email ? `${contact.email}` : '');
    });

    formData.append('isImplementing', tenant.isImplementing ? `${tenant.isImplementing}` : 'false');

    return formData;
  }
}
