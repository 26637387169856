<div class="container">
  <mat-label>{{ label | translate }}</mat-label>
  <mat-selection-list
    (selectionChange)="onChange($event)"
    [formControl]="control"
    [multiple]="isMultiple"
    [required]="required"
  >
    <mat-list-option *ngFor="let option of options" checkboxPosition="before" [value]="option.id">
      {{ option.name | translate }}
    </mat-list-option>
  </mat-selection-list>
  <ng-container *ngIf="!control.pristine">
    <mat-error *ngFor="let error of control.errors | errorKeys">
      {{ error | translate }}
    </mat-error>
  </ng-container>
</div>
