import { getTenantId } from 'src/app/services/store-snapshot.service';
import { Subscription } from 'rxjs';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITenant } from 'src/app/tenant/models/tenant.model';

export interface DialogData {
  tenants: ITenant[];
  title: string;
}

@Component({
  selector: 'app-tenant-id-list',
  templateUrl: './tenant-id-list.component.html',
  styleUrls: ['./tenant-id-list.component.scss'],
})
export class TenantIdListComponent implements OnDestroy {
  currentTenantId: string;

  private subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<TenantIdListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.currentTenantId = getTenantId();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  chooseTenantId(): void {
    this.dialogRef.close({ data: this.currentTenantId });
  }
}
