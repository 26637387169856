<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'statusCompetenceUser'">
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.Red" class="red">circle</mat-icon>
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.Yellow" class="yellow"
      >circle</mat-icon
    >
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.Orange" class="orange"
      >circle</mat-icon
    >
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.Green" class="green"
      >circle</mat-icon
    >
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.White" class="white"
      >circle</mat-icon
    >
  </ng-container>
  <ng-container *ngSwitchCase="'hasAttachments'">
    <mat-icon *ngIf="element.hasAttachments" [attr.aria-label]="'hasAttachments' | translate">
      attach_file
    </mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'actionCompetenceUser'">
    <div
      *ngIf="element.formId && element.isRegistrationCompleted; else restOfActions"
      (click)="routeToCustomForm(element.userCompetenceId, element.formId)"
    >
      <mat-icon><div>assignment</div></mat-icon>
    </div>
    <ng-template #restOfActions>
      <div
        *ngIf="element.isExpiring; else done"
        [routerLink]="[
          element.isRegistrationCompleted
            ? userProfileRoutes.CompetenceUserRenew
            : userProfileRoutes.CompetenceUserRegister,
          userId,
          element.competenceId
        ]"
      >
        <mat-icon>calendar_month</mat-icon>
      </div>
      <ng-template #done>
        <mat-icon>done</mat-icon>
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'competenceCode'">
    <div (click)="routeTo()">{{ element.competenceCode }} {{ element.code }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'competenceName'">
    <div (click)="routeTo()">
      {{ element.name }} {{ element.competenceName }}
      <app-equivalent-competences-tooltip
        [competence]="element"
      ></app-equivalent-competences-tooltip>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'requirementType'">
    <div (click)="routeTo()">
      <div *ngIf="element.requirementType === userCompetenceRequirementType.mandatory">
        {{ 'mandatory' | translate | uppercase }}
      </div>
      <div *ngIf="element.requirementType === userCompetenceRequirementType.optional">
        {{ 'optional' | translate | uppercase }}
      </div>
      <div *ngIf="element.requirementType === userCompetenceRequirementType.preferred">
        {{ 'preferred' | translate | uppercase }}
      </div>
      <div *ngIf="element.requirementType === userCompetenceRequirementType.notRequired">
        {{ 'notRequired' | translate | uppercase }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'expiry'">
    <div (click)="routeTo()">
      <div *ngIf="element.expiresAt; else noDate">
        {{ element.expiresAt | date : 'yyyy-MM-dd' }}
      </div>
      <ng-template #noDate><div>-</div></ng-template>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'daysUntilExpired'">
    <div (click)="routeTo()">
      <div *ngIf="element.daysUntilExpired; else noDays" class="center">
        {{ element.daysUntilExpired }}
      </div>
      <ng-template #noDays><div>-</div></ng-template>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'registrationComplete'">
    <div (click)="routeTo()">
      <div *ngIf="element.isRegistrationCompleted" class="center">
        {{ 'yes' | translate }}
      </div>
      <div *ngIf="!element.isRegistrationCompleted" class="center">
        {{ 'no' | translate }}
      </div>
    </div>
  </ng-container>
</ng-container>
