/* eslint-disable max-classes-per-file */

import {
  MainTableFilterSearchByModel,
  MainTableSortingType,
} from 'src/app/shared/models/main-table-filter.model';
import { MainTableFilterModulesEnum } from '../enums/main-table-filter-modules.enum';
import { MainTableFilterIdentification } from '../models/main-table-filter-identification.model';

export class ApplySearch {
  static readonly type = '[Main Table Filter] Apply Search to Specified Module';
  constructor(
    public module: MainTableFilterModulesEnum,
    public searchTerm: string,
    public additional?: any
  ) {}
}

export class ApplyPaging {
  static readonly type = '[Main Table Filter] Apply Paging to Specified Module';
  constructor(
    public module: MainTableFilterModulesEnum,
    public pageIndex: number,
    public pageSize: number,
    public additional?: any
  ) {}
}

export class ApplyAdditional {
  static readonly type = '[Main Table Filter] Apply Additional to Specified Module';
  constructor(public module: MainTableFilterModulesEnum, public additional: any) {}
}

export class ApplyColumnFilters {
  static readonly type = '[Main Table Filter] Apply Column Filters to Specified Module';
  constructor(
    public module: MainTableFilterModulesEnum,
    public filters: MainTableFilterSearchByModel[],
    public additional: any
  ) {}
}

export class ApplySorting {
  static readonly type = '[Main Table Filter] Apply Sorting to Specified Module';
  constructor(
    public module: MainTableFilterModulesEnum,
    public orderBy: string,
    public ascDesc: MainTableSortingType,
    public additional?: any
  ) {}
}

export class ReFetchWithLastFilters {
  static readonly type = '[Main Table Filter] Re Fetch Data With Last Filters';
  constructor(public module: MainTableFilterModulesEnum, public additional?: any) {}
}

export class ReFetchAndClearSorting {
  static readonly type = '[Main Table Filter] Re Fetch Data And Clear Sorting';
  constructor(public module: MainTableFilterModulesEnum, public additional?: any) {}
}

export class ClearFilterDetails {
  static readonly type = '[Main Table Filter] Clear Filtering Details';
  constructor() {}
}

export class ResetFilterPageIndex {
  static readonly type = '[Main Table Filter] Reset Current Filter Page';
  constructor(public filterIdentification: MainTableFilterIdentification) {}
}
