<table mat-table [dataSource]="_dataSource" class="mat-elevation-z8">
  <ng-container *ngFor="let column of _displayedColumns; trackBy: trackBy" [matColumnDef]="column">
    <ng-container [ngSwitch]="column">
      <ng-container *ngSwitchCase="selectColName">
        <th mat-header-cell *matHeaderCellDef>
          <!-- TODO: pipelize f calls            -->
          <mat-checkbox
            *ngIf="toggleAllEnabled"
            [disabled]="disabled"
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            [disabled]="disabled"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngSwitchCase="errorsColName"> </ng-container>
      <ng-container *ngSwitchDefault>
        <th mat-header-cell *matHeaderCellDef>
          {{ column | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row[column] }}
        </td></ng-container
      >
    </ng-container>
  </ng-container>

  <ng-container [matColumnDef]="errorsColName">
    <td mat-footer-cell *matFooterCellDef [colSpan]="_displayedColumns.length">
      <ng-container *ngIf="_control.statusChanges | async">
        <mat-error *ngFor="let error of _control.errors | errorKeys">
          {{ error | translate }}
        </mat-error>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="_displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: _displayedColumns" (click)="toggleSelection(row)"></tr>
  <tr mat-footer-row *matFooterRowDef="[errorsColName]"></tr>
</table>
