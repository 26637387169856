import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-default-cell',
  templateUrl: './default-cell.component.html',
})
export class DefaultCellComponent {
  @Input() columnName: string;
  @Input() element: any;

  trackBy = (index: number) => index;
}
