import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TenantUserPrivilegesService } from 'src/app/tenant-selector/services/tenant-user-privileges.service';
import { validationMessages } from '../../messages';

@Component({
  selector: 'app-shared-ui-checkbox',
  templateUrl: './ui-checkbox.component.html',
  styleUrls: ['./ui-checkbox.component.scss'],
})
export class UiCheckboxComponent {
  @Input() label = '';
  @Input() control: UntypedFormControl;
  @Input() placeholder?: string;
  @Input() messages = validationMessages;
  @Input() required = true;
  @Input() selected: boolean;
  @Input() set privilege(privilege: string | string[]) {
    this.hasPermission = this.checkPermission(privilege);
    this.matTooltip = this.hasPermission ? '' : 'noAccess';
  }
  @Input() privilegeStrategy: 'any' | 'all' = 'any';
  @Input() matTooltip: string;

  hasPermission = true;

  constructor(private tenantUserPrivileges: TenantUserPrivilegesService) {}

  checkPermission(privilege: string | string[]): boolean {
    if (!privilege || (privilege instanceof Array && privilege.length === 0)) {
      return true;
    }
    return this.privilegeStrategy === 'any'
      ? this.tenantUserPrivileges.userHasPrivilege(privilege)
      : this.tenantUserPrivileges.userHasAllPrivileges(
          Array.isArray(privilege) ? privilege : [privilege]
        );
  }
}
