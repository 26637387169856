/* eslint-disable no-underscore-dangle */

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Store } from '@ngxs/store';
import { ToastService } from '../core/services/toast.service';

@Injectable({ providedIn: 'root' })
export class StaticDependenciesService {
  private static _router: Router | undefined = undefined;
  private static _toast: ToastService | undefined = undefined;
  private static _zone: NgZone | undefined = undefined;
  private static _store: Store | undefined = undefined;
  private static _actions: Actions | undefined = undefined;
  public constructor(
    router: Router,
    toast: ToastService,
    zone: NgZone,
    store: Store,
    actions: Actions
  ) {
    StaticDependenciesService._router = router;
    StaticDependenciesService._toast = toast;
    StaticDependenciesService._zone = zone;
    StaticDependenciesService._store = store;
    StaticDependenciesService._actions = actions;
  }
  static get router(): Router {
    if (!StaticDependenciesService._router) {
      throw new Error('Router not initialized');
    }
    return StaticDependenciesService._router;
  }

  static get toast(): ToastService {
    if (!StaticDependenciesService._toast) {
      throw new Error('ToastService not initialized');
    }
    return StaticDependenciesService._toast;
  }

  static get zone(): NgZone {
    if (!StaticDependenciesService._zone) {
      throw new Error('NgZone not initialized');
    }
    return StaticDependenciesService._zone;
  }

  static get store(): Store {
    if (!StaticDependenciesService._store) {
      throw new Error('Store not initialized');
    }
    return StaticDependenciesService._store;
  }

  static get actions(): Actions {
    if (!StaticDependenciesService._actions) {
      throw new Error('Actions not initialized');
    }
    return StaticDependenciesService._actions;
  }
}
