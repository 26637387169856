import { getDepartmentId } from 'src/app/services/store-snapshot.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, switchMap } from 'rxjs';
import { LoadAllDepartmentsForSelectedTenant } from 'src/app/department/state/department.actions';
import { DepartmentState } from 'src/app/department/state/department.state';
import { TenantState } from 'src/app/tenant/state/tenant.state';
import { ITenant } from 'src/app/tenant/models/tenant.model';
import { CopyMenuStructure } from 'src/app/core/state/menu-tree-structure.actions';
import { LoadAllTenants } from 'src/app/tenant/state/tenant.action';
import { IdName } from '../../models/id-name';
import { CopyMenuTreeForm } from './copy-menu-tree.form';
import { markControlsAsTouched } from '../../functions/mark-controls-as-touched';

@Component({
  selector: 'app-copy-menu-tree-modal',
  templateUrl: './copy-menu-tree-modal.component.html',
})
export class CopyMenuTreeModalComponent implements OnInit {
  getAllTenants$: Observable<ITenant[]> = this.store.select(TenantState.allTenants);
  getAllDepartments$: Observable<IdName[]> = this.store.select(
    DepartmentState.getAllDepartmentsForSelectedTenant
  );

  form: CopyMenuTreeForm;
  allTenants: ITenant[];
  allDepartments: IdName[];

  constructor(
    public dialogRef: MatDialogRef<CopyMenuTreeModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      menuId: string;
      module: number;
    },
    private store: Store
  ) {
    this.form = new CopyMenuTreeForm(getDepartmentId(), this.data.module, this.data.menuId);
  }

  ngOnInit(): void {
    this.store
      .dispatch([new LoadAllTenants()])
      .pipe(switchMap(() => this.getAllTenants$))
      .subscribe((tenants) => {
        this.allTenants = tenants;
      });
  }

  onTargetTenantChange(): void {
    this.store
      .dispatch([new LoadAllDepartmentsForSelectedTenant(this.form.targetTenantId.value)])
      .pipe(switchMap(() => this.getAllDepartments$))
      .subscribe((departments) => {
        this.form.targetDepartmentIds.setValue([]);
        this.allDepartments = departments;
      });
  }

  save(): void {
    markControlsAsTouched(this.form);

    if (!this.form.valid) {
      return;
    }

    this.store.dispatch(new CopyMenuStructure(this.form.value));
  }
}
