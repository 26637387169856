<div *appLoader="!unallowedAttachmentFileTypes">
  <div
    class="drop-zone"
    appDropzone
    (hovered)="toggleHover($event)"
    (dropped)="processFiles($event)"
    [class.hovering]="isHovering"
  >
    <div class="center-container">
      <mat-icon class="upload-icon">cloud_upload</mat-icon>
    </div>

    <div class="center-container">
      <p class="drag-and-drop-text">
        {{ 'drag' | translate }} {{ 'and' | translate }} {{ 'drop' | translate }}
        {{ 'or ' | translate }}
      </p>
      <br />
      <p class="choose-files-text" (click)="fileUpload.click()">
        {{ 'choose' | translate }} {{ 'a' | translate }} {{ 'file' | translate }}
      </p>
    </div>

    <input
      type="file"
      multiple
      class="file-input cdk-visually-hidden"
      (change)="onFileSelected($event)"
      #fileUpload
    />
    <form [formGroup]="form" class="form-container">
      <div
        *ngFor="let formField of form.attachmentControls; let i = index"
        formArrayName="attachments"
        class="form-container-row"
      >
        <app-ui-input
          class="input"
          [control]="formField.name"
          color="primary"
          [label]="'name' | translate"
        ></app-ui-input>
        <app-ui-select
          [control]="formField.categories"
          [options]="attachmentCategories$ | async"
          color="primary"
          [label]="'attachmentCategories' | translate"
        ></app-ui-select>
        <mat-icon class="delete-icon" (click)="deleteFile(i)">delete</mat-icon>
      </div>
    </form>
  </div>
  <div class="button-container">
    <app-ui-button
      [text]="'cancel' | translate"
      buttonType="secondary"
      (click)="dialogRef.close()"
    ></app-ui-button>
    <app-ui-button
      class="save-button"
      text="{{ 'save' | translate }}"
      actionType="submit"
      (click)="upload()"
    ></app-ui-button>
  </div>
</div>
<!-- <h3>Uploads</h3> -->

<!-- <div *ngFor="let file of files">
  <app-upload-task [file]="file"></app-upload-task>
</div> -->
