<tx-document-viewer
  *ngIf="contentBase64"
  width="100%"
  height="800px"
  [basePath]="url"
  dock="Window"
  [toolbarDocked]="true"
  [documentData]="contentBase64"
  [isSelectionActivated]="true"
  [showThumbnailPane]="true"
>
</tx-document-viewer>
