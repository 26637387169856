<form [formGroup]="form" *appLoader="!form || (actions | actionInProgress | async) === true">
  <div class="form-page-header divider-bottom">
    <h2 *ngIf="!form.id.value; else editHeading">{{ 'addNewAlert' | translate }}</h2>
    <ng-template #editHeading>
      <h2>{{ 'editAlert' | translate }}</h2>
    </ng-template>
    <div class="buttons-container">
      <app-ui-button
        text="{{ 'cancel' | translate }}"
        buttonType="secondary"
        [routerLink]="adminAlertRoutes.Main"
      >
      </app-ui-button>
      <app-ui-button
        *ngIf="form.disabled; else editMode"
        text="{{ 'edit' | translate }}"
        color="primary"
        (click)="form.enable()"
        icon="edit"
        privilege="ALERT_UPDATE"
      >
      </app-ui-button>
      <ng-template #editMode>
        <app-ui-button
          *ngIf="form.id.value"
          appConfirmDialog
          [confirmEntityName]="form.name.value"
          (confirmed)="delete()"
          text="{{ 'delete' | translate }}"
          color="warn"
          buttonType="stroked"
          icon="delete"
          privilege="ALERT_DELETE"
        >
        </app-ui-button>
        <app-ui-button
          text="{{ 'save' | translate }}"
          color="primary"
          actionType="submit"
          (click)="save()"
          [privilege]="form.id.value ? 'ALERT_UPDATE' : 'ALERT_CREATE'"
        >
        </app-ui-button>
      </ng-template>
    </div>
  </div>
  <div class="form-cards">
    <mat-card>
      <app-ui-input
        label="{{ 'name' | translate }}"
        [control]="form.name"
        [required]="true"
      ></app-ui-input>
      <app-ui-select
        label="{{ 'module' | translate }}"
        [control]="form.modules"
        [options]="getModules$ | async"
        [selected]="form.modules.value"
      ></app-ui-select>
      <div class="toggles-container">
        <app-ui-slide-toggle
          label="{{ 'days' | translate }}"
          [control]="form.days"
          (changeHandler)="setDaysValidation()"
        ></app-ui-slide-toggle>
        <app-ui-slide-toggle
          label="{{ 'hours' | translate }}"
          [control]="form.hours"
          (changeHandler)="setHoursValidation()"
        ></app-ui-slide-toggle>
      </div>
      <div *ngIf="form.days.value">
        <app-ui-input
          label="{{ 'yellowAlertThresholdDays' | translate }}"
          [control]="form.yellowAlertDays"
          [required]="true"
        ></app-ui-input>
        <app-ui-input
          label="{{ 'redAlertThresholdDays' | translate }}"
          [control]="form.redAlertDays"
          [required]="true"
        >
        </app-ui-input>
      </div>
      <div *ngIf="form.hours.value">
        <app-ui-input
          label="{{ 'yellowAlertThresholdHours' | translate }}"
          [control]="form.yellowAlertHours"
          [required]="true"
        ></app-ui-input>
        <app-ui-input
          label="{{ 'redAlertThresholdHours' | translate }}"
          [control]="form.redAlertHours"
          [required]="true"
        >
        </app-ui-input>
      </div>
      <div class="toggles-container">
        <app-ui-slide-toggle
          label="{{ 'email' | translate }}"
          [control]="form.emailReminder"
        ></app-ui-slide-toggle>
        <app-ui-slide-toggle
          label="{{ 'sms' | translate }}"
          [control]="form.smsReminder"
        ></app-ui-slide-toggle>
      </div>
      <app-ui-input
        *ngIf="form.emailReminder.value || form.smsReminder.value"
        label="{{ 'reminderMessage' | translate }}"
        [control]="form.reminderMessage"
        [required]="false"
      ></app-ui-input>
    </mat-card>
  </div>
</form>
