import { UntypedFormControl } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-ui-list-select',
  templateUrl: './ui-list-select.component.html',
  styleUrls: ['./ui-list-select.component.scss'],
})
export class UiListSelectComponent {
  @Input() options: any | any[] = [];
  @Input() isMultiple = true;
  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() required: boolean;

  @Output() readonly changeHandler: EventEmitter<MatSelectionListChange> =
    new EventEmitter<MatSelectionListChange>();

  onChange(event: MatSelectionListChange): void {
    this.changeHandler.emit(event);
  }
}
