import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AddressForm } from '../../forms/address.form';
import { UIElementsModule } from '../../ui-elements/ui-elements.module';
import { AppLoaderDirective } from '../../directives/app-loader.directive';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, UIElementsModule, AppLoaderDirective],
})
export class AddressFormComponent {
  @Input() addressForm: AddressForm;
  @Input() title: string;
  @Input() countryDesc: string;
  @Input() addressDesc: string;
  @Input() zipDesc: string;
  @Input() cityDesc: string;
}
