import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MenuTreeModalInterface } from 'src/app/core/models/menu-tree-modal';
import { LoadDepartmentList } from 'src/app/department/state/department.actions';
import { SearchableIconsService } from 'src/app/core/services/searchable-icons.service';
import { MenuTreeStructureState } from 'src/app/core/state/menu-tree-structure.state';
import { MenuListLogicService } from 'src/app/core/services/menu-list-logic.service';

import { MenuTreeButtonAction } from '../../enums/menu-tree-button-actions.enum';
import { IdName } from '../../models/id-name';

@Component({
  selector: 'app-menu-tree-dialog',
  templateUrl: './menu-tree-dialog.component.html',
  styleUrls: ['./menu-tree-dialog.component.scss'],
})
export class MenuTreeDialogComponent implements OnInit, OnDestroy {
  menuStructureIdNameList$: Observable<IdName[]> = this.store.select(
    MenuTreeStructureState.getMenuStructureIdNameList
  );

  modalTitle: string;
  modalDescription: string;
  icons: IdName[];
  possibleLocationsList: IdName[] = [];
  MenuTreeButtonAction = MenuTreeButtonAction;

  titles = {
    [MenuTreeButtonAction.Create]: 'addNewMenuItem',
    [MenuTreeButtonAction.Update]: 'updateMenuItem',
    [MenuTreeButtonAction.Delete]: 'deleteItem',
  };
  descriptions = {
    [MenuTreeButtonAction.Create]: 'pleaseProvideDetailsToCreateANewNode',
    [MenuTreeButtonAction.Update]: 'pleaseChangeNodeDetails',
    [MenuTreeButtonAction.Delete]: 'removingThisNodeWillAlsoRemoveAllOfItsChildrenNodes',
  };
  saveButtonTexts = {
    [MenuTreeButtonAction.Create]: 'create',
    [MenuTreeButtonAction.Update]: 'update',
    [MenuTreeButtonAction.Delete]: 'delete',
  };
  saveValues = {
    [MenuTreeButtonAction.Create]: this.data,
    [MenuTreeButtonAction.Update]: this.data,
    [MenuTreeButtonAction.Delete]: 'deleted',
  };

  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<MenuTreeDialogComponent>,
    private store: Store,
    private menuListLogicService: MenuListLogicService,
    private searchableIconsService: SearchableIconsService,
    @Inject(MAT_DIALOG_DATA) public data: MenuTreeModalInterface
  ) {
    this.store.dispatch(new LoadDepartmentList());

    this.modalTitle = this.titles[this.data.modalType];
    this.modalDescription = this.descriptions[this.data.modalType];
    if (this.data.modalType === MenuTreeButtonAction.Delete) {
      this.data.form.disable();
    }
  }

  ngOnInit(): void {
    this.icons = this.searchableIconsService.getSearchableIcons();
    this.subscriptions.add(
      this.menuStructureIdNameList$.subscribe((possiblePlacementList: IdName[]) => {
        if (possiblePlacementList) {
          this.saveList(possiblePlacementList);
          if (this.data.modalType === MenuTreeButtonAction.Update) {
            this.removeOwnLocationFromList(this.data.form.value.id);
          } else if (this.data.modalType === MenuTreeButtonAction.Create) {
            const parentId = this.menuListLogicService.getParentId();
            if (parentId) this.data.form.controls.parentId.setValue(parentId);
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  save(): void {
    if (this.data.form.enabled && this.data.form.invalid) {
      return;
    }

    this.dialogRef.close(this.saveValues[this.data.modalType]);
  }

  private saveList(list: IdName[]): void {
    this.possibleLocationsList = list;
  }

  private removeOwnLocationFromList(id: string): void {
    this.possibleLocationsList = this.possibleLocationsList.filter((loc: IdName) => loc.id !== id);
  }
}
