import { Input, Component } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent {
  @Input() file: File;

  percentage$: Observable<number>;
  snapshot$: Observable<string>;
  downloadURL: string;

  constructor() {}

  isActive(snapshot: { state: string; bytesTransferred: number; totalBytes: number }): boolean {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
