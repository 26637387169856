<form [formGroup]="form" *appLoader="!form" class="edit-form">
  <app-dialog-container
    title="{{ title | translate }}"
    [isValid]="this.form.valid"
    (confirmButtonHandler)="save()"
    (editButtonHandler)="enableForm()"
    (deleteButtonHandler)="delete()"
    [form]="form"
  >
    <div class="row-container">
      <mat-form-field appearance="outline" class="display-flex">
        <mat-label>{{ 'topic' | translate }}</mat-label>
        <mat-select formControlName="topic" [multiple]="false">
          <mat-option *ngFor="let option of availableEvents" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row-container">
      <app-ui-input
        label="{{ 'callback' | translate }}"
        [control]="form.callback"
        [required]="true"
      ></app-ui-input>
    </div>
    <div class="row-container">
      <div formArrayName="requestHeaders">
        <div
          *ngFor="let header of requestHeaders.controls; let i = index"
          [formGroupName]="i"
          class="request-header-container"
        >
          <h5>{{ 'requestHeader' | translate }}</h5>
          <app-ui-input label="{{ 'name' | translate }}" [control]="header.name"></app-ui-input>
          <app-ui-input
            label="{{ 'value' | translate }}"
            [control]="header.headerValue"
          ></app-ui-input>
        </div>
      </div>
      <app-ui-button
        *ngIf="!form.disabled"
        text="{{ 'newRequestHeaders' | translate }}"
        color="primary"
        (click)="newReqHeaders()"
        icon="add"
      >
      </app-ui-button>
    </div>
  </app-dialog-container>
</form>
