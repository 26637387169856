import { Component, Input } from '@angular/core';
import { DocumentsTableNamesEnum } from '../../../../enums/documents-table-names.enum';

@Component({
  selector: 'app-document-templates',
  templateUrl: './document-templates.component.html',
})
export class DocumentTemplatesComponent {
  @Input() specificTemplateName: string;
  @Input() columnName: string;
  @Input() element: any;

  documentTableNames = DocumentsTableNamesEnum;
}
