/* eslint-disable max-classes-per-file */
import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { throttle } from 'lodash';

export class ResizedEvent {
  newRect: DOMRectReadOnly;
  oldRect?: DOMRectReadOnly;
  isFirst: boolean;

  constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
    this.newRect = newRect;
    this.oldRect = oldRect;
    this.isFirst = oldRect == null;
  }
}

const DEFAULT_THROTTLE_TIME = 150;

@Directive({
  selector: '[appElementResize]',
  standalone: true,
})
export class ResizeDirective implements OnInit, OnDestroy {
  @Input() throttleTime = DEFAULT_THROTTLE_TIME;
  @Output() readonly elementResized = new EventEmitter<ResizedEvent>();

  private observer: ResizeObserver;
  private oldRect?: DOMRectReadOnly;

  constructor(private readonly element: ElementRef, private readonly zone: NgZone) {
    this.observer = new ResizeObserver(
      throttle((entries) => this.zone.run(() => this.observe(entries)), this.throttleTime)
    );
  }

  ngOnInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    const domSize = entries[0];
    const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
    this.oldRect = domSize.contentRect;
    this.elementResized.emit(resizedEvent);
  }
}
