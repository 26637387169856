/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IAlertRequest } from '../models/alert.model';

export class LoadAlerts {
  static readonly type = '[Alerts] Load Alerts via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllAlerts {
  static readonly type = '[Alerts] Load All Alerts via Service';
  constructor() {}
}

export class CreateAlert {
  static readonly type = '[Alerts] Create Alert';
  constructor(public alert: IAlertRequest) {}
}

export class UpdateAlert {
  static readonly type = '[Alerts] Update Alert';
  constructor(public alert: IAlertRequest) {}
}

export class DeleteAlert {
  static readonly type = '[Alerts] Delete Alert';
  constructor(public alertId: string) {}
}
