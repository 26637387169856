import { trigger, state, style, transition, animate } from '@angular/animations';

export const Animations = {
  toggleContainer: trigger('toggleContainer', [
    state('true', style({ height: '*', padding: '*', overflow: 'hidden' })),
    state('false', style({ height: '0', padding: '0', visibility: 'hidden', overflow: 'hidden' })),
    transition('true <=> false', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
  rotatedState: trigger('rotatedState', [
    state('false', style({ transform: 'rotate(0deg)' })),
    state('true', style({ transform: 'rotate(90deg)' })),
    transition('true <=> false', animate('225ms ease-out')),
  ]),
  halfRotation: trigger('halfRotation', [
    state('false', style({ transform: 'rotate(0deg)' })),
    state('true', style({ transform: 'rotate(180deg)' })),
    transition('true <=> false', animate('225ms ease-out')),
  ]),
};
