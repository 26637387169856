/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadComponentSpareParts {
  static readonly type = '[Spare Part] Load Component Spare Parts via Service';
  constructor(public componentId: string, public filter?: MainTableFilterModel) {}
}

export class LoadAllComponentSpareParts {
  static readonly type = '[Spare Part] Load All Component Spare Parts via Service';
  constructor(public componentId: string) {}
}

export class GetNextSfiCode {
  static readonly type = '[Spare Part] Get Next Sfi Code via Service';
  constructor(public componentId: string) {}
}

export class LoadJobSpareParts {
  static readonly type = '[Spare Part] Load Job Spare Parts via Service';
  constructor(public jobId: string, public filter?: MainTableFilterModel) {}
}

export class LoadAllJobsSpareParts {
  static readonly type = '[Spare Part] Load ALl Component Spare Parts via Service';
  constructor(public jobId: string) {}
}

export class AttachSparePartToComponent {
  static readonly type = '[Spare Part] Attach Spare Part To Component';
  constructor(public componentId: string, public sparePartId: string) {}
}

export class DetachSparePartFromComponent {
  static readonly type = '[Spare Part] Detach Spare Part To Component';
  constructor(public componentId: string, public sparePartIds: string[]) {}
}

export class AttachSparePartToJob {
  static readonly type = '[Spare Part] Attach Spare Part To Job';
  constructor(public jobId: string, public sparePartId: string) {}
}

export class DetachSparePartFromJob {
  static readonly type = '[Spare Part] Detach Spare Part To Job';
  constructor(public jobId: string, public sparePartIds: string[]) {}
}
