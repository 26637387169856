import { Observable, tap } from 'rxjs';
import { StaticDependenciesService } from 'src/app/services/static-dependencies.service';
import { redirectAndBypassUnsavedDataCheck } from '../functions/redirect-and-bypass-unsaved-data-check';

export function OnSuccess(config: { url?: string; message?: string }) {
  const { url = '', message = '' } = config;
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const action = () => {
      if (message) {
        StaticDependenciesService.toast.success(message);
      }
      if (url) {
        redirectAndBypassUnsavedDataCheck(url);
      }
    };
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const result = originalMethod.apply(this, args);
      if (result && result instanceof Observable) {
        return result.pipe(
          tap(() => {
            StaticDependenciesService.zone.run(() => {
              action();
            });
          })
        );
      }
      return result;
    };
    return descriptor;
  };
}
