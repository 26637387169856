import { IMaintenanceComponent } from 'src/app/maintenance-admin/models/maintenance-component.model';

export const ROOT_MAINTENANCE_COMPONENT: IMaintenanceComponent = {
  id: '1',
  name: 'root',
  condition: '',
  department: { id: '', name: '' },
  sfiCode: '',
  riskLevel: 0,
  mains: '',
  maker: '',
  model: '',
  serialNumber: '',
  specification: '',
  located: '',
  classId: '',
  isCriticalComponent: false,
  isClassComponent: false,
  isSpareComponent: false,
  isE0Component: false,
  parentComponentId: { id: '', name: '' },
  picturesOfComponent: [],
  schematicImage: undefined,
};
