import { Router } from '@angular/router';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ICertificateGroup } from 'src/app/certificate-user/models/certificate.model';
import { CertificateState } from 'src/app/certificate-user/state/certificate.state';

@Component({
  selector: 'app-main-table-expandable-row',
  templateUrl: './main-table-expandable-row.component.html',
  styleUrls: ['./main-table-expandable-row.component.scss'],
})
export class MainTableExpandableRowComponent implements OnChanges, OnDestroy {
  @Input() displayedColumns: string[];
  @Input() moduleName: string;
  @Input() parentRow: any;
  @Input() urlForRow: string;
  @Input() specificTemplateName: string;

  allCertificateGrouped$: Observable<ICertificateGroup[]> = this.store.select(
    CertificateState.getAllCertificateGrouped
  );

  dataSource: MatTableDataSource<any>;
  disabled: any;

  private subscriptions = new Subscription();

  constructor(private router: Router, private store: Store) {}

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource([this.parentRow]);
    // TODO: Wojciech
    // switch (this.moduleName) {
    //   case 'certificate':
    //     this.subscriptions.add(
    //       this.allCertificateGrouped$.subscribe((certs: ICertificateGroup[]) => {
    //         const parentRowName = this.parentRow.displayName;
    //         certs.forEach((cert: any) => {
    //           if (cert?.menuName === parentRowName) {
    //             this.displayedColumns = ['documentType', 'name', 'expiryDate', 'nextRevisionDate'];
    //             this.dataSource = new MatTableDataSource(cert.certificateDetails);
    //           }
    //         });
    //       })
    //     );
    //     break;
    //   default:
    //     this.dataSource = new MatTableDataSource([this.parentRow]);
    //     break;
    // }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  routeTo(row: any): void {
    this.router.navigate([`${this.urlForRow}/${row.id}`]);
  }

  trackBy = (index: number) => index;
}
