import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, Observable, Subscription, switchMap, tap } from 'rxjs';
import { ModuleNumbers } from 'src/app/core/enums/module-numbers.enum';
import { MenuItemModel } from 'src/app/core/models/menu-id.model';
import { CreateUpdateDeleteRecursiveArrayService } from 'src/app/core/services/create-update-delete-recursive-array.service';
import { MenuListState } from 'src/app/core/state/menu-list.state';
import { MenuTreeStructureState } from 'src/app/core/state/menu-tree-structure.state';
import { ComponentsStructureState } from 'src/app/maintenance-admin/state/components-structure.state';
import { CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { UpdateComponentsStructureOrder } from 'src/app/maintenance-admin/state/components-structure.actions';
import { MenuDragDropService } from '../menu-drag-drop.service';
import {
  LoadSpecificMenuStructure,
  UpdateMenuStructureOrder,
} from '../../../core/state/menu-tree-structure.actions';

@Component({
  selector: 'app-menu-tree-structure',
  templateUrl: './menu-tree-structure.component.html',
  styleUrls: ['./menu-tree-structure.component.scss'],
})
export class MenuTreeStructureComponent implements OnInit, OnDestroy {
  getEntireMenuStructure$: Observable<MenuItemModel[]> = this.store
    .select(MenuTreeStructureState.getEntireMenuStructure)
    .pipe(
      tap((data) => {
        this.menuDNDService.setInitialMenuStructure(data);
      })
    );
  getModuleNumber$: Observable<number | undefined> = this.store.select(
    MenuListState.getModuleNumber
  );
  maintenanceMenuStructure$: Observable<any[]> = this.store
    .select(ComponentsStructureState.getComponentsStructure)
    .pipe(
      tap((data) => {
        this.menuDNDService.setInitialMenuStructure(data as any[]);
      })
    );

  structurePreview: MenuItemModel[] = [];
  activeElementId$: Observable<string> = this.store.select(
    MenuTreeStructureState.activeMenuElementId
  );
  loaded$: Observable<boolean> = this.store.select(MenuTreeStructureState.menuStructureLoaded);
  actions = [UpdateComponentsStructureOrder, UpdateMenuStructureOrder];

  @Input() showFunctionalButtons = true;
  @Input() isRootAddEnabled = false;
  @Input() menuItemsAsRouting = false;
  @Input() moduleNumber: ModuleNumbers;
  @Input() menuMode = false;
  @ViewChild(CdkDropListGroup) listGroup: CdkDropList<CdkDropListGroup<any>>;
  tenantId: string;
  module: number;
  moduleNumbers = ModuleNumbers;
  chosenElementId = '';
  isProductOwner = false;

  private subscriptions = new Subscription();

  constructor(
    private store: Store,
    private router: Router,
    public menuDNDService: MenuDragDropService
  ) {
    this.subscriptions.add(
      this.menuDNDService.structurePreview.subscribe((structure) => {
        this.structurePreview = structure;
      })
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          switchMap(() => this.getEntireMenuStructure$),
          filter((data) => !!data.length),
          filter((data) => {
            this.chosenElementId = this.isAnyElementChosen(data, this.router.url);
            return !this.chosenElementId;
          }),
          switchMap(() => this.activeElementId$),
          tap((activeElementId) => {
            this.chosenElementId = activeElementId;
          })
        )
        .subscribe()
    );
    if (this.moduleNumber && this.moduleNumber !== ModuleNumbers.Document) {
      this.store.dispatch(new LoadSpecificMenuStructure(this.moduleNumber));
    }
    this.subscriptions.add(
      this.getModuleNumber$.subscribe((moduleNumber) => {
        if (
          moduleNumber &&
          (moduleNumber !== ModuleNumbers.Document || !this.showFunctionalButtons)
        ) {
          this.module = moduleNumber;
          this.store.dispatch(new LoadSpecificMenuStructure(this.module));
        }
      })
    );
    this.subscriptions.add(
      (this.moduleNumber === ModuleNumbers.Maintenance
        ? this.maintenanceMenuStructure$
        : this.getEntireMenuStructure$
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  highlightMenuItem(itemId: string) {
    if (!this.showFunctionalButtons) {
      this.chosenElementId = itemId;
    }
  }

  trackById(index: number, item: MenuItemModel): string {
    return item.id;
  }

  private isAnyElementChosen(items: MenuItemModel[], url: string): string {
    const flatItems = CreateUpdateDeleteRecursiveArrayService.getFlatList(items);
    return flatItems.find((item) => url.includes(item.id))?.id || '';
  }
}
