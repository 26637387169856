/* eslint-disable max-classes-per-file */
import { ICompetenceGroupModel } from 'src/app/competence/model/competence-group.model';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadCompetenceGroups {
  static readonly type = '[Competence Group] Fetch Competence Groups';
  constructor(public includeDeleted?: boolean, public filter?: MainTableFilterModel) {}
}

export class LoadAllCompetenceGroups {
  static readonly type = '[Competence Group] Fetch All Competence Groups';
  constructor() {}
}

export class AddCompetenceGroup {
  static readonly type = '[Competence Group] Add Competence Groups';
  constructor(public competenceGroup: ICompetenceGroupModel) {}
}

export class GetCompetenceGroupById {
  static readonly type = '[Competence Group] Get Competence Group By Id';
  constructor(public id: string) {}
}

export class UpdateCompetenceGroup {
  static readonly type = '[Competence Group] Update Competence Groups';
  constructor(public competenceGroup: ICompetenceGroupModel) {}
}

export class DeleteCompetenceGroup {
  static readonly type = '[Competence Group] Delete Competence Group By Id';
  constructor(public id: string) {}
}

export class RestoreCompetenceGroup {
  static readonly type = '[Competence Group] Restore Competence Group By Id';
  constructor(public id: string) {}
}
