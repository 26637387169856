import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { filter, first, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserState } from './state/user.state';
import { LoadUserOptions } from './state/user.actions';

@Injectable()
export class UserOptionsResolver implements Resolve<Observable<any>> {
  userOptionsLoaded$: Observable<boolean> = this.store.select(UserState.userOptionsLoaded);

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.userOptionsLoaded$.pipe(
      tap((userOptionsLoaded) => {
        if (!userOptionsLoaded) {
          this.store.dispatch(new LoadUserOptions());
        }
      }),
      filter((userOptionsLoaded) => userOptionsLoaded),
      first()
    );
  }
}
