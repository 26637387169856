<mat-sidenav-container class="sidenav-container main-dark-background">
  <mat-sidenav
    #drawer
    class="sidenav main-sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false && desktopMode"
  >
    <div class="main-menu-container">
      <mat-toolbar class="main-sidenav logo-toolbar">
        <ng-container *ngIf="desktopMode; else mobileDepSelector">
          <img
            *ngIf="depId !== 'all_deps'; else withoutRouting"
            [src]="
              !!(isDarkMode$ | async)
                ? '../../../../assets/icons/ccom-logo-dark-mode.svg'
                : '../../../../assets/icons/ccom-logo-light-mode.svg'
            "
            class="logo-image"
            alt="ccom logo"
            routerLink="/"
          />
          <ng-template #withoutRouting>
            <img
              [src]="
                !!(isDarkMode$ | async)
                  ? '../../../../assets/icons/ccom-logo-dark-mode.svg'
                  : '../../../../assets/icons/ccom-logo-light-mode.svg'
              "
              class="logo-image"
              alt="ccom logo"
            />
          </ng-template> </ng-container
        ><ng-template #mobileDepSelector>
          <div
            class="mobile-dep-selector-container"
            *ngIf="{
              selectedDepartment: selectedDepartment$ | async,
              departmentList: departments$ | async
            } as departments"
            (click)="openDepartmentSelectorMobile()"
          >
            <img
              width="30"
              height="30"
              [src]="
                departments.selectedDepartment?.id === allDepsToken
                  ? allDepsIconPath
                  : (departments.selectedDepartment?.logo | appendDomain) ??
                    departmentPlaceHolderIconPath
              "
              class="mobile-dep-sel-picture"
              alt="vessel picture"
            />
            <span class="mobile-dep-text">{{ departments.selectedDepartment?.name }}</span>
            <mat-icon class="mobile-dep-sel-icon">keyboard_arrow_right</mat-icon>
          </div>
        </ng-template>
      </mat-toolbar>
      <app-menu-list></app-menu-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    ><mat-toolbar class="top-toolbar">
      <ng-container *ngIf="desktopMode; else mobileMode">
        <app-ui-button
          aria-label="Toggle sidenav"
          buttonType="icon-button"
          icon="{{
            (isHandset$ | async) ? 'menu' : drawer.opened ? 'switch_right' : 'switch_left'
          }}"
          (click)="toggleMainNav()"
          *ngIf="!!!(isHandset$ | async)"
          class="toggle-button"
        ></app-ui-button>
        <app-department-dropdown></app-department-dropdown>
        <div class="right-nav-container">
          <!-- <app-global-search></app-global-search> -->
          <mat-icon (click)="goToCCOMDocs()" [matTooltip]="'goToCCOMDocumentation' | translate"
            >help_outlined</mat-icon
          >
        </div>
        <div class="icons-container">
          <app-language-selector> </app-language-selector>
          <app-logged-in-as-popup></app-logged-in-as-popup>
        </div>
      </ng-container>
      <ng-template #mobileMode>
        <span class="mobile-header-container">
          <span class="left-side-mobile-container">
            <mat-icon (click)="toggleMainNav()" class="mobile-header-icon">menu</mat-icon>
          </span>
          <img
            [src]="
              !!(isDarkMode$ | async)
                ? '../../../../assets/icons/ccom-logo-dark-mode.svg'
                : '../../../../assets/icons/ccom-logo-light-mode.svg'
            "
            class="mobile-logo-image"
            alt="ccom logo"
            routerLink="/"
          />
          <span class="right-side-mobile-container">
            <mat-icon
              *ngIf="!(userAccess$ | async)?.profilePictureMiniature"
              class="mobile-header-icon"
              (click)="openUserProfileMobileDialog()"
              >account_circle</mat-icon
            >
            <img
              *ngIf="(userAccess$ | async)?.profilePictureMiniature"
              [src]="(userAccess$ | async)?.profilePictureMiniature?.link | appendDomain"
              alt="profile picture"
              class="mobile-header-icon"
              (click)="toggleMainNav()"
            />
          </span>
        </span>
      </ng-template>
    </mat-toolbar>
    <div class="breadcrumb-container" *ngIf="desktopMode">
      <xng-breadcrumb [separator]="iconTemplate">
        <ng-template #iconTemplate>
          <mat-icon class="material-icons-round">chevron_right</mat-icon>
        </ng-template>
        <ng-container *xngBreadcrumbItem="let breadcrumb">
          <ng-container>{{ breadcrumb | translate }}</ng-container>
        </ng-container>
      </xng-breadcrumb>
    </div>
    <mat-sidenav-container class="sidenav-inner" autosize>
      <mat-sidenav #inner [mode]="'side'" [opened]="innerMenuOpened" class="inner-sidenav">
        <app-menu-tree-structure
          *ngIf="!isInAdmin; else adminInnerNav"
          [showFunctionalButtons]="false"
          [menuItemsAsRouting]="enableRouting"
          [menuMode]="true"
        ></app-menu-tree-structure>
        <ng-template #adminInnerNav>
          <app-admin-inner-nav
            [adminInnerModuleName]="adminInnerModuleName$ | async"
          ></app-admin-inner-nav>
        </ng-template>
      </mat-sidenav>
      <mat-sidenav-content class="inner-sidenav-content" #innerNavContent>
        <ng-content> </ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
</mat-sidenav-container>
<div
  (click)="inner.toggle()"
  class="mobile-menu-button"
  *ngIf="!desktopMode && showSecondarySidenav"
>
  <mat-icon [@halfRotation]="!inner.opened">chevron_left</mat-icon>
</div>
<app-ui-button
  #innerNavToggleButton
  class="toggle-secondary-sidenav-button"
  [class.closed]="!inner.opened"
  [class.cdk-visually-hidden]="!showSecondarySidenav"
  aria-label="Toggle sidenav"
  buttonType="icon-button"
  icon="{{ (isHandset$ | async) ? 'menu' : inner.opened ? 'chevron_left' : 'chevron_right' }}"
  (click)="inner.toggle()"
  *ngIf="!!!(isHandset$ | async) && desktopMode"
>
  <!-- </app-ui-button>
  (click)="inner.toggle(); textControl.refreshLayout()"
  *ngIf="!!!(isHandset$ | async)"
></app-ui-button> -->
</app-ui-button>
<div class="text-control-reusable-container" *ngIf="useTextControl && !forceReload">
  <tx-document-editor
    *ngIf="textControl.isInitialized"
    appElementResize
    id="text-control-editor"
    class="cdk-visually-hidden"
    width="100%"
    [height]="textControl.isFullscreen ? '700px' : '100vh'"
    [saveDocumentQuestionDialogEnabled]="false"
    [webSocketURL]="url"
    [class.disabled]="textControl.isEditorDisabled"
    [class.ribbon-loading]="!textControl.ribbonBarLoaded"
    [class.document-loading]="textControl.isDocumentLoading"
    (elementResized)="textControl.refreshLayout()"
  >
  </tx-document-editor>
</div>
