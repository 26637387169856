import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { getTenantId, getDepartmentId } from 'src/app/services/store-snapshot.service';
import { IdName } from 'src/app/shared/models/id-name';
import { MainTableFilteringHelperService } from 'src/app/shared/main-table-module/services/main-table-filtering-helper.service';
import {
  ICertificateRenew,
  ICertificate,
  ICertificateGroup,
  IApiResponseGroupCertificates,
} from '../models/certificate.model';
import { environment } from '../../../environments/environment';
import { FormDataService } from '../../shared/attachments/service/form-data.service';

const COLUMN_TO_KEY_MAP: any = {
  type: 'CertificateType',
  expiryDate: 'ExpirationDate',
  access: 'PositionsWithAccess.Name',
};

@Injectable({
  providedIn: 'root',
})
export class CertificateApiService {
  private headers = { 'content-type': 'application/json' };
  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/certificates`;
  }

  private get certificatesForIOUrl(): string {
    return `${this.url}/Interconnected`;
  }

  constructor(private http: HttpClient) {}

  getCertificatesByMenuWithFiltering(
    currentFilter?: MainTableFilterModel
  ): Observable<IApiResponseGroupCertificates> {
    const url = `${this.url}/byMenu`;
    let params = new HttpParams();

    if (currentFilter?.additional?.menuId) {
      params = params.set('menuId', currentFilter.additional?.menuId);
    }

    if (currentFilter?.additional?.showDeleted) {
      params = params.set('OnlyDeleted', 'true');
    }

    if (currentFilter?.pageSize) {
      params = params.append('Page', currentFilter.pageIndex.toString());
      params = params.append('PageSize', currentFilter.pageSize.toString());
    }

    if (currentFilter?.searchTerm) {
      params = params.append('GlobalSearch', currentFilter.searchTerm);
    }

    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      params = params.append(`OrderBy[${currentFilter.orderBy}]`, currentFilter.ascDesc);
    }
    params = MainTableFilteringHelperService.appendFilteringParams(
      params,
      currentFilter,
      COLUMN_TO_KEY_MAP
    );

    return this.http.get<IApiResponseGroupCertificates>(url, { params });
  }

  getAllCertificatesGrouped(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<ICertificateGroup[]>> {
    let url = `${this.url}/groups`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<ICertificateGroup[]>>(url);
  }

  getSpecificCertificate(certificateId: string): Observable<ICertificate> {
    const url = `${this.url}/${certificateId}`;
    return this.http.get<ICertificate>(url);
  }

  loadCertificatesForIO(): Observable<IdName[]> {
    return this.http.get<IdName[]>(this.certificatesForIOUrl);
  }

  createCertificate(certificate: ICertificate): Observable<{ id: string }> {
    const formData = FormDataService.mapToFormData(certificate);
    return this.http.post<{ id: string }>(this.url, formData);
  }

  updateCertificate(certificate: ICertificate): Observable<void> {
    const formData = FormDataService.mapToFormData(certificate);
    const url = `${this.url}/${certificate.id}`;
    return this.http.put<void>(url, formData);
  }

  deleteCertificate(id: string): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }

  renewCertificate(certificate: ICertificateRenew): Observable<void> {
    const formData = FormDataService.mapToFormData(certificate);
    const url = `${this.url}/${certificate.id}/renew`;
    return this.http.put<void>(url, formData);
  }

  restoreCertificate(id: string): Observable<void> {
    return this.http.put<void>(`${this.url}/${id}/restore`, {});
  }
}
