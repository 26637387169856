import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserAccess } from 'src/app/shared/user-view/models/tenant-user-access.model';
import { IUserOptions } from 'src/app/shared/user-view/models/user-options.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserOptionsApiService {
  private readonly url = `${environment.apiUrl}/api/Options`;
  private readonly accessUrl = `${environment.apiUrl}/api/UserAccess/Me/v2`;

  constructor(private http: HttpClient) {}

  getOptions(): Observable<IUserOptions> {
    return this.http.get<IUserOptions>(this.url);
  }

  getUserAccess(tenantId: string): Observable<IUserAccess> {
    if (tenantId === '') {
      // initially we send request without specyfying tenantId
      // so API will return first tenantId
      // for people with only one tenantId it will be their default
      return this.http.get<IUserAccess>(this.accessUrl);
    }
    // if a user is requesting a specific tenantId
    // then we pass it inside queryParams
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tenantId', tenantId);
    return this.http.get<IUserAccess>(this.accessUrl, { params: queryParams });
  }
}
