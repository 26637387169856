<form class="form" [formGroup]="contactForm" *appLoader="!contactForm">
  <div class="row-container">
    <app-ui-input
      label="{{ 'firstName' | translate }}"
      [control]="contactForm.firstName"
      [required]="true"
    ></app-ui-input>
    <app-ui-input
      label="{{ 'lastName' | translate }}"
      [control]="contactForm.lastName"
      [required]="true"
    ></app-ui-input>
  </div>
  <div class="row-container">
    <app-ui-input
      label="{{ 'position' | translate }}"
      [control]="contactForm.position"
      type="text"
    ></app-ui-input>
  </div>
  <div class="row-container">
    <app-ui-input
      class="phonePrefix"
      label="{{ 'prefix' | translate }}"
      type="number"
      [control]="contactForm.prefix"
      [required]="true"
    ></app-ui-input>
    <app-ui-input
      label="{{ 'phoneNumber' | translate }}"
      type="number"
      [control]="contactForm.phoneNumber"
      [required]="true"
    ></app-ui-input>
  </div>
  <div class="row-container">
    <app-ui-input
      label="{{ 'email' | translate }}"
      [control]="contactForm.email"
      [required]="true"
    ></app-ui-input>
  </div>
</form>
