import { getTenantId } from 'src/app/services/store-snapshot.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { environment } from '../../../../environments/environment';
import { IUserTable, IUserModel, IUserRequest, IUserProductOwnerView } from '../models/user.model';
import { FormDataService } from '../../attachments/service/form-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private readonly baseUrl = `${environment.apiUrl}/api`;
  private readonly headers = { 'content-type': 'application/json' };
  private get url(): string {
    return `${this.baseUrl}/${getTenantId()}/users`;
  }

  private get productOwnerViewsUrl(): string {
    return `${this.baseUrl}/ProductOwnerViews`;
  }

  constructor(private http: HttpClient) {}

  getAllUsers(currentFilter?: MainTableFilterModel): Observable<ApiResponse<IUserTable[]>> {
    const params: { [key: string]: string } = {};
    if (currentFilter) {
      params.Page = `${currentFilter.pageIndex}`;
      params.PageSize = `${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      params.GlobalSearch = currentFilter.searchTerm;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      params[`OrderBy[${currentFilter.orderBy}]`] = currentFilter.ascDesc;
    }
    if (currentFilter?.additional?.includeDeleted) {
      params.IncludeDeleted = currentFilter.additional.includeDeleted;
    }
    if (currentFilter?.additional?.includePaused) {
      params.IncludePaused = currentFilter.additional.includePaused;
    }
    if (currentFilter?.additional?.includeDeactivated) {
      params.IncludeDeactivated = currentFilter.additional.includeDeactivated;
    }

    return this.http.get<ApiResponse<IUserTable[]>>(this.url, { params });
  }

  getAllUsersAllTenants(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IUserProductOwnerView[]>> {
    const params: { [key: string]: string } = {};
    if (currentFilter) {
      params.Page = `${currentFilter.pageIndex}`;
      params.PageSize = `${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      params.GlobalSearch = currentFilter.searchTerm;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      params[`OrderBy[${currentFilter.orderBy}]`] = currentFilter.ascDesc;
    }
    if (currentFilter?.additional) {
      params.IncludeDeleted = currentFilter.additional;
    }

    const url = `${this.productOwnerViewsUrl}/users`;
    return this.http.get<ApiResponse<IUserProductOwnerView[]>>(url, {
      params,
    });
  }

  getUserById(id: string): Observable<IUserModel> {
    const url = `${this.url}/${id}`;
    return this.http.get<IUserModel>(url);
  }

  getMyUser(): Observable<IUserModel> {
    const url = `${this.url}/me`;
    return this.http.get<IUserModel>(url);
  }

  createUser(user: IUserRequest): Observable<string> {
    const url = `${this.url}`;
    const formData = FormDataService.mapToFormData(user);
    return this.http.post<string>(url, formData);
  }

  updateUser(user: IUserRequest): Observable<void> {
    const url = `${this.url}/${user.id}`;
    const formData = FormDataService.mapToFormData(user);
    return this.http.put<void>(url, formData);
  }

  updateMyUser(user: IUserRequest): Observable<void> {
    const url = `${this.url}/me`;
    const formData = FormDataService.mapToFormData(user);
    return this.http.put<void>(url, formData);
  }

  restoreUser(id: string): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.patch<void>(url, id);
  }

  resumeUserEmployment(id: string): Observable<void> {
    const url = `${this.url}/${id}/Employments/Resume`;
    return this.http.patch<void>(url, id);
  }

  restoreUserEmployment(id: string): Observable<void> {
    const url = `${this.url}/${id}/Employments/Restore`;
    return this.http.patch<void>(url, id);
  }

  pauseUserEmployments(id: string): Observable<void> {
    const url = `${this.url}/${id}/Employments/Pause`;
    return this.http.patch<void>(url, {});
  }

  deleteUserEmployments(id: string): Observable<void> {
    const url = `${this.url}/${id}/Employments`;
    return this.http.delete<void>(url);
  }

  deactivateUser(id: string): Observable<void> {
    const url = `${this.url}/Deactivate/${id}`;
    return this.http.post<void>(url, { headers: this.headers });
  }

  reactivateUser(id: string): Observable<void> {
    const url = `${this.url}/Reactivate/${id}`;
    return this.http.post<void>(url, { id, tenantId: getTenantId() }, { headers: this.headers });
  }

  sendResetPasswordEmail(id: string): Observable<void> {
    const url = `${this.url}/ResendResetPassword/${id}`;
    return this.http.post<void>(url, { id, tenantId: getTenantId() }, { headers: this.headers });
  }

  resendConfirmationEmail(id: string): Observable<void> {
    const url = `${this.url}/ResendConfirmation/${id}`;
    return this.http.post<void>(url, { id, tenantId: getTenantId() }, { headers: this.headers });
  }
}
