import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TimeZoneInfoInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeZoneInfo = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zoneInfoRequest = request.clone({ setHeaders: { 'X-TimeZone-Info': timeZoneInfo } });
    return next.handle(zoneInfoRequest);
  }
}
