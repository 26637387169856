import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MainTableModuleNamesEnum } from '../../enums/main-table-module-names.enum';

@Component({
  selector: 'app-main-table-module-specific-template',
  templateUrl: './main-table-module-specific-template.component.html',
  styleUrls: ['./main-table-module-specific-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainTableModuleSpecificTemplateComponent {
  @Input() module: string;
  @Input() columnName: string;
  @Input() element: any;
  @Input() urlForRouting: string;
  @Input() specificTemplateName: string;
  @Input() toggleEditMode: () => void;
  @Input() buttonFunctions: { [key: string]: (...args: any[]) => void };
  @Input() hideButtons: boolean;

  modules = MainTableModuleNamesEnum;
}
