import { Animations } from 'src/app/core/angular-animations/angular-animations';
import { Component, Input } from '@angular/core';
import { UserCertificateRoutes } from 'src/app/core/enums/routes.enum';
import { Store } from '@ngxs/store';
import { RestoreCertificate } from 'src/app/certificate-user/state/certificate.actions';

@Component({
  selector: 'app-certificate-template',
  templateUrl: './certificate-template.component.html',
  styleUrls: ['./certificate-template.component.scss'],
  animations: [Animations.rotatedState],
})
export class CertificateTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  @Input() statusColor: string;
  @Input() tableColor: string;

  expanded = false;
  routerLink = `${UserCertificateRoutes.Renew}`;

  constructor(private store: Store) {}

  toggle(): void {
    this.expanded = !this.expanded;
  }

  restore(): void {
    this.store.dispatch(new RestoreCertificate(this.element.id));
  }

  // getCertStatusColor(status: string): string {
  //   switch (status) {
  //     case 'Expired':
  //       return 'red';
  //     case 'Expiring':
  //       return 'gold';
  //     default:
  //       return 'lightgreen';
  //   }
  // }
  // TODO remove if ui-pills works with new back-end STATUS property
}
