import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MenuIdModel } from 'src/app/core/models/menu-id.model';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuPlacementService {
  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/menu`;
  }
  constructor(private http: HttpClient) {}

  getAllMenuIds(): Observable<MenuIdModel[]> {
    const url = `${this.url}/1`;
    return this.http.get<MenuIdModel[]>(url);
  }
}
