import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, filter, fromEvent, Subscription, tap } from 'rxjs';
import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';
import { AdminDocumentsRoutes } from 'src/app/core/enums/routes.enum';
import { MainTableLogicService } from 'src/app/core/services/main-table-logic.service';
import { defaultInitialPageSize } from 'src/app/core/table-config/table-paging-defaults';
import { SelectionModel } from '@angular/cdk/collections';
import { MainTableCustomSortingService } from '../../../core/services/main-table-custom-sorting.service';
import {
  ApplyColumnFilters,
  ApplySorting,
  ClearFilterDetails,
  ReFetchAndClearSorting,
} from '../../../core/state/main-table-filter.actions';
import { ResizedEvent } from '../../directives/resize.directive';
import { MainTableModuleNamesEnum } from '../../enums/main-table-module-names.enum';
import { MainTableFilterState } from '../../../core/state/main-table-filter.state';
import {
  MainTableFilterSearchByModel,
  MainTableSortingType,
} from '../../models/main-table-filter.model';
import {
  MainTableColumnFilterComponent,
  MainTableFilterColumnData,
} from './main-table-column-filter/main-table-column-filter.component';

@Component({
  selector: 'app-main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MainTableComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  // This is a generic table component
  // You are obligated to specify only two properties
  // the "tableData" and "displayedColumns"
  // You can also specify optional parameters like
  // "pageSizeOptions", but it can be left as default
  @Input() tableData: any;
  @Input() displayedColumns: string[];
  @Input() detailsDisplayedColumns: string[];
  @Input() clickableColumns: string[] = [];
  @Input() disabledColumnHeaders: string[] = [];
  @Input() filterConfig: Record<string, MainTableFilterColumnData> | null = null;
  // eslint-disable-next-line no-magic-numbers
  @Input() totalPages: any;

  // additional options

  // Option 1:
  // urlForRow as string
  @Input() urlForRow = '';
  @Input() trClass = '';
  @Input() searchingEnabled = true;
  @Input() filteringEnabled = true;
  @Input() sortingEnabled = true;
  @Input() paginationEnabled = true;
  @Input() elevationEnabled = true;
  @Input() showHeaders = true;
  @Input() showPageSizeSelector = true;
  @Input() defaultInitialPageSize = defaultInitialPageSize;
  @Input() viewMode: 'full' | 'embedded' = 'full';
  @Input() moduleNameForPaging: MainTableFilterModulesEnum;
  @Input() idFieldNameForRowHandler: string;
  @Input() trClassMap: Record<string, string> = {};

  // Option 2:
  // hasRoutingForRow is boolean for not using a routing for a row
  @Input() hasRoutingForRow = true;
  @Input() rowClickDisabled = false;
  @Input() filterPredicate: (data: any, filter: string) => boolean;
  @Input() specificTemplateName: string;
  @Input() buttonFunctions: { [key: string]: (i: number) => (...args: any[]) => void } = {};
  @Input() hideButtons = true;
  @Input() additional: any; // TODO Illia: additional is handled in multiple places, should it be just here?
  @Input() @HostBinding('class.hide-main-table-show-search') loading = false;
  @Input() initialSelection: any[] = [];

  @Output() readonly rowHandler: EventEmitter<string | any> = new EventEmitter<string | any>();
  @Output() readonly columnHandler = new EventEmitter<{ column: string; row: any }>();
  @Output() readonly searchEmitter = new EventEmitter<string>();
  @Output() readonly selectionEmitter = new EventEmitter<any>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChildren(MainTableColumnFilterComponent)
  filterComponents: QueryList<MainTableColumnFilterComponent>;

  currentFilter$ = this.store.select(MainTableFilterState.getCurrentFilterDetails);

  dataSource: MatTableDataSource<any>;
  multipleSelection = new SelectionModel<any>(true, []);
  showSelector = false;

  expandedElement: any | null;

  filterValue: string;
  sortDirection: MainTableSortingType = '';
  orderByColumn = '';
  columnFilters: Record<string, MainTableFilterSearchByModel[]> = {};
  tableColor: string;
  // Remember to add a new enum for new template on shared main-table component
  moduleRouteEnums = MainTableModuleNamesEnum;
  moduleName: string;

  currentUrl: string;
  isExpandable = false;
  subheaderMaxWidth = 0; // TODO Illia: this is not used, should it be removed?
  allDisplayedColumns: string[];
  maintenanceSubheaderColumns = ['maintenanceJobsGroupName'];

  get hasActiveFilters(): boolean {
    return this.filterComponents?.some((f) => f.hasActiveFilters);
  }

  private subscription = new Subscription();

  constructor(
    private store: Store,
    private router: Router,
    private mainTableLogicService: MainTableLogicService,
    private mainTableCustomSortingService: MainTableCustomSortingService
  ) {
    const { url } = this.router;
    this.currentUrl = url;
    const urlParts = url.split(/[?\/]/g);
    urlParts.forEach((urlFragment: string) => {
      if ((<any>Object).values(this.moduleRouteEnums).includes(urlFragment)) {
        this.moduleName = urlFragment;
      }
    });
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.tableData ? this.tableData : []);
    this.setSortAndPagination();
    this.setCustomFilterPredicate();
    this.setInitiallySelectedRows();
  }

  ngOnInit(): void {
    this.filterExtraColumns();
    this.setInitiallySelectedRows();
  }

  ngAfterViewInit(): void {
    this.setSortAndPagination();
    this.applyFilter();
    this.isExpandable = this.displayedColumns?.includes('expand');
  }

  setInitiallySelectedRows(): void {
    this.multipleSelection.setSelection(
      ...(this.tableData?.filter((u: any) =>
        this.initialSelection.find(
          (s) => s[this.idFieldNameForRowHandler] === u[this.idFieldNameForRowHandler]
        )
      ) || [])
    );
  }

  filterExtraColumns(): void {
    this.allDisplayedColumns = [...this.displayedColumns];
    const containsMultiSelect = this.displayedColumns?.includes('multiselect');
    if (containsMultiSelect && !this.showSelector) {
      this.showSelector = true;
      this.displayedColumns = this.displayedColumns?.filter((col) => col !== 'multiselect');
      this.maintenanceSubheaderColumns = ['multiselect', 'maintenanceJobsGroupName'];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  applyFilter(): void {
    if (!this.input) {
      return;
    }
    this.subscription.add(
      this.currentFilter$.subscribe((currentFilter) => {
        this.orderByColumn = currentFilter?.orderBy || '';
        this.sortDirection = currentFilter?.ascDesc || '';
        if (Object.keys(this.columnFilters).length === 0) {
          const columnFilters: Record<string, MainTableFilterSearchByModel[]> = {};
          currentFilter?.searchBy?.forEach((searchBy) => {
            if (!columnFilters[searchBy.property]) {
              columnFilters[searchBy.property] = [];
            }
            columnFilters[searchBy.property].push(searchBy);
          });
          this.columnFilters = columnFilters;
        }

        if (!this.input.nativeElement.value) {
          if (currentFilter?.searchTerm && currentFilter.module === this.moduleNameForPaging) {
            this.input.nativeElement.value = currentFilter.searchTerm;
          }
        }
      })
    );
    const debounceTimeMs = 500;
    this.subscription.add(
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(debounceTimeMs),
          distinctUntilChanged(),
          tap(() => {
            this.searchEmitter.emit(this.input.nativeElement.value);
          })
        )
        .subscribe()
    );
  }

  applyColumnFilters(column: string, filters: MainTableFilterSearchByModel[]): void {
    this.columnFilters[column] = filters;
    this.store.dispatch(
      new ApplyColumnFilters(
        this.moduleNameForPaging,
        Object.values(this.columnFilters).flat(),
        this.additional
      )
    );
  }

  clearFilters(): void {
    this.sortDirection = '';
    this.orderByColumn = '';
    this.filterComponents?.forEach((f) => f.resetAllControls());
    this.columnFilters = {};
    this.store.dispatch(new ClearFilterDetails()).subscribe(() => {
      this.store.dispatch(new ReFetchAndClearSorting(this.moduleNameForPaging, this.additional));
    });
  }

  sortData(sort: Sort) {
    let sortDirection: MainTableSortingType = '';
    if (sort.direction === 'asc') {
      sortDirection = 'Ascending';
    } else if (sort.direction === 'desc') {
      sortDirection = 'Descending';
    } else if (sort.direction === '') {
      sortDirection = '';
    }
    this.sortDirection = sortDirection;

    const headerName = this.getProperHeaderNameForAPI(sort.active, this.moduleNameForPaging);
    this.store.dispatch(
      new ApplySorting(this.moduleNameForPaging, headerName, sortDirection, this.additional)
    );
  }

  handleRowClick(row: any) {
    if (this.rowClickDisabled) {
      return;
    }
    if (this.urlForRow === AdminDocumentsRoutes.Structure) {
      this.router.navigate([`${this.urlForRow}/${row.id}`]);
    } else if ((!row.count || row.count === 0) && this.hasRoutingForRow) {
      if (!row.count || row.count === 0) {
        let idForRouting = '';
        if (this.idFieldNameForRowHandler) {
          const rowElement = row[this.idFieldNameForRowHandler];
          idForRouting = rowElement instanceof AbstractControl ? rowElement.value : rowElement;
        } else if (row.id) {
          idForRouting = row.id;
        }
        if (this.rowHandler.observers.length > 0) {
          this.rowHandler.emit(idForRouting);
        } else if (this.urlForRow) {
          this.router.navigate([`${this.urlForRow}/${idForRouting}`]);
        }
      }
    } else if (!this.hasRoutingForRow) {
      // return whole row object if routing is disabled to handle click manually
      this.rowHandler.emit(row);
    }
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  handleColumnClick(event: MouseEvent, column: string, row: any) {
    if (this.clickableColumns.includes(column)) {
      event.stopPropagation();
      this.columnHandler.emit({ column, row });
    }
  }

  isExpired(expiryDate: string): boolean {
    if (this.mainTableLogicService.isExpired(expiryDate)) {
      this.tableColor = 'salmon';
      return true;
    }
    if (this.mainTableLogicService.isSoonExpired(expiryDate)) {
      this.tableColor = 'lightyellow';
      return true;
    }

    return this.mainTableLogicService.isExpired(expiryDate);
  }

  isColumnHeaderDisabled(columnHeaderName: string): boolean {
    return this.disabledColumnHeaders.some((header: string) => header === columnHeaderName);
  }

  onResize(event: ResizedEvent) {
    // set subheader max width to 80% of table width
    // can't use css because of ellipsis overflow quirks
    if (event.newRect.width > 0) {
      // eslint-disable-next-line no-magic-numbers
      this.subheaderMaxWidth = (event.newRect.width / 100) * 80;
    }
  }

  trackBy = (index: number) => index;

  private setSortAndPagination(): void {
    if (this.sortingEnabled && this.dataSource && this.dataSource.sort) {
      this.dataSource.sortingDataAccessor = (row, key) =>
        this.mainTableCustomSortingService.getCustomSorting(row, key);
      this.dataSource.sort = this.sort;
    }
  }

  private setCustomFilterPredicate() {
    if (this.filterPredicate) {
      this.dataSource.filterPredicate = this.filterPredicate;
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.multipleSelection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  isAllSelected() {
    const numSelected = this.multipleSelection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isAnyItemSelected(): boolean {
    return this.multipleSelection.selected.length > 0;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.multipleSelection.clear();
    } else {
      this.multipleSelection.select(...this.dataSource.data);
    }

    this.emitSelection();
  }

  toggleSingle(row: any) {
    const { isSubHeader } = row;
    if (isSubHeader) {
      const groupRows = this.dataSource.data.filter((r) => row.itemIds.includes(r.jobId));
      if (this.isAllRowsInGroupSelected(row)) {
        this.multipleSelection.deselect(row, ...groupRows);
      } else {
        this.multipleSelection.select(row, ...groupRows);
      }
      this.emitSelection();
      return;
    }
    this.multipleSelection.toggle(row);
    const rowGroupHeader = this.dataSource.data.find(
      (r) => r.isSubHeader && r.itemIds?.includes(row.jobId)
    );
    if (rowGroupHeader) {
      if (this.isAllRowsInGroupSelected(rowGroupHeader)) {
        this.multipleSelection.select(rowGroupHeader);
      } else {
        this.multipleSelection.deselect(rowGroupHeader);
      }
    }
    this.emitSelection();
  }

  clearSelection() {
    this.multipleSelection.clear();
    this.emitSelection();
  }

  isAllRowsInGroupSelected(row: any): boolean {
    const { isSubHeader } = row;
    if (isSubHeader) {
      const rowsToSelect = this.dataSource.data.filter((r) => row.itemIds.includes(r.jobId));
      return rowsToSelect.every((r) => this.multipleSelection.isSelected(r));
    }
    return false;
  }

  isSomeRowsInGroupSelected(row: any): boolean {
    const { isSubHeader } = row;
    if (isSubHeader) {
      const rowsToSelect = this.dataSource.data.filter((r) => row.itemIds.includes(r.jobId));
      return rowsToSelect.some((r) => this.multipleSelection.isSelected(r));
    }
    return false;
  }

  private emitSelection(): void {
    this.selectionEmitter.emit(this.multipleSelection.selected.filter((r) => !r.isSubHeader));
  }

  getProperHeaderNameForAPI(activeHeader: string, module: string): string {
    // TODO Illia: this one is confusing, refactor
    switch (activeHeader) {
      case 'arrivalDateTime':
        if (module === MainTableFilterModulesEnum.CurrentPersonnel) {
          return 'Arrival';
        }
        return 'From';
      case 'departureDateTime':
        return 'To';
      case 'expiryDate':
        return 'ExpirationDate';
      case 'currentQuantity':
        return 'Quantity';
      case 'critical':
        return 'IsCritical';
      case 'chemical':
        return 'IsChemical';
      case 'medicineCategory':
        return 'Category';
      case 'minQuantity':
        return 'MinimumQuantity';
      case 'refrigerated':
        return 'ShouldBeRefrigerated';
      case 'departmentType':
        return 'Type';
      case 'status':
        if (module === MainTableFilterModulesEnum.CurrentPersonnel) {
          return '';
        }
        return 'IsActive';
      case 'fullName':
        return 'FirstName';
      case 'phoneNumber':
        return 'Mobile';
      case 'productName':
        return 'Name';
      case 'name':
        return 'Name';
      case 'position':
        return 'Position';
      case 'plannedEndDate':
        return 'PlannedEnd';
      case 'totalSumVat':
        return 'TotalPrice';
      case 'employedInDepartment':
        return 'employedIn';
      default:
        return activeHeader;
    }
  }
}
