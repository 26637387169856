import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { IdName } from 'src/app/shared/models/id-name';
import {
  IDepartmentInfo,
  ITenantUserAccess,
  IUserAccess,
} from 'src/app/shared/user-view/models/tenant-user-access.model';
import {
  ALL_DEPS_TOKEN,
  TenantUserAccessState,
} from 'src/app/tenant-selector/state/tenant-user-access.state';
import { TenantSelectorComponent } from 'src/app/tenant-selector/tenant-selector.component';
import { AppUpdateService } from 'src/app/services/app-update.service';
import { TextControlService } from 'src/app/shared/text-control-module/text-control.service';
import { environment } from 'src/environments/environment';
import { LoadTenantUserAccess } from '../../../tenant-selector/state/tenant-user-access.actions';
import { MenuListState } from '../../state/menu-list.state';
import { UiState } from '../../state/ui.state';
import { getAdminRoutesWithChildren } from '../menu-list/menu-list-routes';
import { TenantIdListComponent } from '../tenant-id-list/tenant-id-list.component';
import { ToastService } from '../../services/toast.service';
import { Animations } from '../../angular-animations/angular-animations';
import { DepartmentSelectorMobileComponent } from '../department-selector-mobile/department-selector-mobile.component';
import { UserProfileDropdownMobileComponent } from '../user-profile-dropdown-mobile/user-profile-dropdown-mobile.component';
import { RoutesEnum } from '../../enums/routes.enum';

const USER_ROUTES_WITH_INNER_NAV = [
  RoutesEnum.Certificate,
  RoutesEnum.Documents,
  RoutesEnum.Maintenance,
  RoutesEnum.Storage,
  RoutesEnum.Medicines,
  RoutesEnum.Drills,
  RoutesEnum.Checklists,
  RoutesEnum.RiskAssessments,
];

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  animations: [Animations.halfRotation],
})
export class MainNavComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('innerNavToggleButton', { read: ElementRef }) innerNavToggleButton: ElementRef;
  @ViewChild('innerNavContent', { read: ElementRef }) innerNavContent: ElementRef;
  @ViewChild('drawer', { read: ElementRef }) mainSidenavEl: ElementRef;
  @ViewChild('drawer', { read: MatSidenav }) mainSidenav: MatSidenav;

  adminInnerModuleName$: Observable<string> = this.store.select(
    MenuListState.getAdminInnerNavModuleName
  );
  isDarkMode$: Observable<boolean> = this.store.select(UiState.isDarkMode);
  currentDepartmentId$ = this.store.select(TenantUserAccessState.currentDepartmentId);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  selectedDepartment$: Observable<IDepartmentInfo> = this.store.select(
    TenantUserAccessState.currentDepartment
  );
  departments$: Observable<IDepartmentInfo[]> = this.store
    .select(TenantUserAccessState.getAllAvailableDepartments)
    .pipe(map((departments) => [...departments].sort((a, b) => a.name.localeCompare(b.name))));
  userAccess$: Observable<IUserAccess> = this.store.select(TenantUserAccessState.userAccess);

  readonly allDepsToken = ALL_DEPS_TOKEN;
  allDepsIconPath = '/assets/icons/all-departments.svg';
  departmentPlaceHolderIconPath = '/assets/icons/department-placeholder.svg';
  innerMenuOpened = false;
  enableRouting = true;
  showSecondarySidenav = false;
  isInAdmin: boolean;
  depId: string;
  desktopMode: boolean;
  url = environment.textControlWS;
  forceReload = false;

  get useTextControl(): boolean {
    return TextControlService.isTextControlEnabled;
  }

  private initialMainNavWidth = 0;
  private firstRun = true;
  private subscription = new Subscription();

  constructor(
    public textControl: TextControlService,
    private appUpdateService: AppUpdateService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private store: Store,
    private translate: TranslateService,
    private renderer: Renderer2,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.listenToRouteChanges();

    this.subscription.add(
      this.breakpointObserver
        .observe(['(min-width: 650px)'])
        .subscribe((state: BreakpointState) => {
          this.desktopMode = state.matches;
          if (this.firstRun) {
            this.getSelectors();
            this.firstRun = false;
          }
        })
    );

    this.subscription.add(
      this.currentDepartmentId$.subscribe((depId: string) => {
        this.depId = depId;
      })
    );

    this.subscription.add(
      this.textControl.forceReload.subscribe(() => {
        this.forceReload = true;
        setTimeout(() => {
          this.forceReload = false;
        }, 100);
      })
    );
  }

  ngAfterViewInit(): void {
    this.bindInnerNavToggleButton();
    this.initialMainNavWidth = this.mainSidenavEl.nativeElement.getBoundingClientRect().width;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.textControl.dispose();
  }

  @HostListener('window:beforeunload')
  onBeforeUnload(): void {
    this.textControl.dispose();
  }

  bindInnerNavToggleButton(): void {
    const callback: MutationCallback = (mutationsList: MutationRecord[]) => {
      mutationsList.forEach(({ type }) => {
        if (type === 'attributes' && this.desktopMode) {
          this.setSecondarySidenavButtonOffset();
        }
      });
    };

    const observer = new MutationObserver(callback);
    observer.observe(this.innerNavContent.nativeElement, { attributes: true });
  }

  toggleMainNav(): void {
    this.mainSidenav.toggle();
    if (this.desktopMode) {
      this.setSecondarySidenavButtonOffset();
    }
  }

  setSecondarySidenavButtonOffset(): void {
    const baseOffset = this.mainSidenav.opened ? this.initialMainNavWidth : 0;
    const newContentOffset = this.innerNavContent.nativeElement.style.marginLeft;
    let newButtonOffset = baseOffset;
    if (newContentOffset) {
      newButtonOffset += +newContentOffset.replace('px', '');
    }
    this.renderer.setStyle(
      this.innerNavToggleButton.nativeElement,
      'margin-left',
      `${newButtonOffset}px`
    );
  }

  openInnerMenu(open: boolean): void {
    this.innerMenuOpened = open;
  }

  goToCCOMDocs(): void {
    window.open('https://support.ccom.no/portal/en/kb/ccom', '_blank');
  }

  openDepartmentSelectorMobile(): void {
    const dialogRef = this.dialog.open(DepartmentSelectorMobileComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const tenantId = result.data;
      if (tenantId !== getTenantId()) {
        this.store.dispatch(new LoadTenantUserAccess(tenantId));
      }
    });
  }

  openUserProfileMobileDialog(): void {
    this.dialog.open(UserProfileDropdownMobileComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      autoFocus: false,
    });
  }

  private listenToRouteChanges(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.processURL(event.url);
      });
  }

  private processURL(url: string): void {
    this.isInAdmin = url.split('/').some((fragment) => fragment === 'admin');
    if (this.isInAdmin) {
      this.showSecondarySidenav = getAdminRoutesWithChildren().some((r) => url.includes(r));
    } else {
      this.showSecondarySidenav = USER_ROUTES_WITH_INNER_NAV.some((moduleName) =>
        url.includes(moduleName.toLowerCase())
      );
    }
    this.openInnerMenu(this.showSecondarySidenav);
  }

  private getSelectors(): void {
    if (!this.appUpdateService.isPopupShown) {
      this.subscription.add(
        this.store
          .select(TenantUserAccessState.getAllAvailableTenants)
          .pipe(take(1))
          .subscribe((tenants: ITenantUserAccess[]) => {
            if (tenants && tenants.length > 1) {
              const selectedTenantId = TenantUserAccessState.getLastSelectedTenantId();
              if (!selectedTenantId) {
                this.dialog
                  .open(TenantSelectorComponent, {
                    width: this.desktopMode ? '50rem' : '100vw',
                    height: this.desktopMode ? '' : '100vh',
                    maxWidth: this.desktopMode ? '80vw' : '100vw',
                    autoFocus: false,
                  })
                  .afterClosed()
                  .subscribe();
              }
            } else if (tenants && tenants.length === 0) {
              this.toastService.error('userHasNoActiveTenants');
            }
          })
      );
    }
  }

  private openDialog(tenants: IdName[]): void {
    const title = this.translate.instant('chooseATenant');

    const dialogRef = this.dialog.open(TenantIdListComponent, {
      width: '250px',
      data: { title, tenants },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      const tenantId = result.data;
      if (tenantId !== getTenantId()) {
        this.store.dispatch(new LoadTenantUserAccess(tenantId));
      }
    });
  }
}
