<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'hasAttachments'">
    <mat-icon *ngIf="element.hasAttachments" [attr.aria-label]="'hasAttachments' | translate">
      attach_file
    </mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'competenceCode'">
    <div (click)="routeTo()">{{ element.competenceCode }} {{ element.code }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'competenceName'">
    <div (click)="routeTo()">{{ element.name }} {{ element.competenceName }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'isInUse'">
    <mat-icon class="material-icons-round">{{ element.isInUse ? 'check' : 'close' }}</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'isExpiring'">
    <mat-icon class="material-icons-round">{{ element.isExpiring ? 'check' : 'close' }}</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'expiry'">
    <div (click)="routeTo()">
      <div *ngIf="element.expiresAt; else noDate">
        {{ element.expiresAt | date : 'yyyy-MM-dd' }}
      </div>
      <ng-template #noDate><div>-</div></ng-template>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'requirementType'">
    <div (click)="routeTo()">
      <div *ngIf="element.requirementType === 0">
        {{ 'mandatory' | translate | uppercase }}
      </div>
      <div *ngIf="element.requirementType === 1">
        {{ 'optional' | translate | uppercase }}
      </div>
      <div *ngIf="element.requirementType === 2">
        {{ 'preferred' | translate | uppercase }}
      </div>
      <div *ngIf="element.requirementType === 3">
        {{ 'notRequired' | translate | uppercase }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'departments'">
    <div *ngFor="let department of element.requiredForDepartments">
      {{ department }}
    </div>
    <div *ngIf="element.requiredForPositionGroups !== null">
      <br />
      <div *ngFor="let depGroup of element.requiredForDepartmentGroups">
        {{ depGroup }}
      </div>
    </div>
    <div *ngFor="let department of element.departments">
      {{ department }}
    </div>
    <div *ngIf="element.requiredForPositionGroups !== null">
      <br />
      <div *ngFor="let depGroup of element.departmentGroups">
        {{ depGroup }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'jobPositions'">
    <div *ngFor="let position of element.requiredForPositions">
      {{ position }}
    </div>
    <div *ngFor="let posGroup of element.requiredForPositionGroups">
      {{ posGroup }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'statusCompetenceUser'">
    <mat-icon *ngIf="element.statusType === 0" class="green">circle</mat-icon>
    <mat-icon *ngIf="element.statusType === 1" class="yellow">circle</mat-icon>
    <mat-icon *ngIf="element.statusType === 2" class="red">circle</mat-icon>
    <mat-icon *ngIf="element.statusType === 3" class="orange">circle</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'positions'">
    <div *ngFor="let position of element.positions">
      {{ position.name }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'daysUntilExpired'">
    <div (click)="routeTo()">
      <div *ngIf="element.daysUntilExpired; else noDays" class="center">
        {{ element.daysUntilExpired }}
      </div>
      <ng-template #noDays><div>-</div></ng-template>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'registrationComplete'">
    <div (click)="routeTo()">
      <div *ngIf="element.isRegistrationCompleted === true" class="center">
        {{ 'yes' | translate }}
      </div>
      <div *ngIf="element.isRegistrationCompleted === false" class="center">
        {{ 'no' | translate }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'departments'">
    <div *ngFor="let positionGroup of element.requiredForPositionGroups">
      {{ positionGroup }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'jobPositionGroup'">
    <div *ngFor="let positionGroup of element.requiredForPositionGroups">
      {{ positionGroup }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'jobPositionGroup'">
    <div *ngFor="let positionGroup of element.positionGroups">
      {{ positionGroup }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'depAndDepGroups'">
    <div *ngFor="let department of element.departments">
      {{ department }}
    </div>
    <div *ngIf="element.departmentGroups">
      <br />
      <div *ngFor="let depGroup of element.departmentGroups">
        {{ depGroup }}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
