/* eslint-disable max-classes-per-file */
import { UpdateFormsLibraryModel } from '../models/update-forms-library.model';
import { AddCustomFormModel } from '../models/add-custom-form.model';
import { AddFormsLibraryModel } from '../models/add-forms-library.model';
import { UpdateCustomFormModel } from '../models/update-custom-form.model';
import { UpsertCustomFormDefinitionModel } from '../models/upsert-custom-form-definition.model';

export class LoadFormsLibraries {
  static readonly type = '[Custom Forms] Load Forms Libraries';
  constructor() {}
}

export class GetFormsLibraryById {
  static readonly type = '[Custom Forms] Get Forms Library By Id';
  constructor(public libraryId: string = '') {}
}

export class AddFormsLibrary {
  static readonly type = '[Custom Forms] Add Forms Library';
  constructor(public formLibrary: AddFormsLibraryModel) {}
}

export class DeleteFormsLibrary {
  static readonly type = '[Custom Forms] Delete Forms Library';
  constructor(public id: string) {}
}

export class UpdateFormsLibrary {
  static readonly type = '[Custom Forms] Update Forms Library';
  constructor(public formLibrary: UpdateFormsLibraryModel) {}
}

export class AddCustomForm {
  static readonly type = '[Custom Forms] Add Custom Form';
  constructor(public customForm: AddCustomFormModel) {}
}

export class UpdateCustomForm {
  static readonly type = '[Custom Forms] Update Custom Form';
  constructor(public customForm: UpdateCustomFormModel) {}
}

export class UpsertCustomFormDefinition {
  static readonly type = '[Custom Forms] Upsert Custom Form Definition';
  constructor(public model: UpsertCustomFormDefinitionModel) {}
}
export class DeleteCustomForm {
  static readonly type = '[Custom Forms] Delete Custom Form';
  constructor(public id: string) {}
}

export class GetCustomFormById {
  static readonly type = '[Custom Forms] Get Custom Form By Id';
  constructor(public formId: string) {}
}

export class GetCustomFormsByModuleId {
  static readonly type = '[Custom Forms] Get Custom Forms By Module Id';
  constructor(public moduleId: number) {}
}

export class ClearCurrentCustomForm {
  static readonly type = '[Custom Forms] Clear Current Custom Form';
}

export class LoadCustomFormStatuses {
  static readonly type = '[Custom Forms] Load Custom Form Statuses';
}
