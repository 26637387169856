<mat-form-field
  *ngIf="searchTerm"
  class="display-flex"
  appearance="outline"
  [floatLabel]="floatLabel"
  [class.no-label]="!label"
>
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    placeholder="{{ placeholder | translate }} {{ label }}"
    matInput
    [formControl]="searchTerm"
    [matAutocomplete]="auto"
    [required]="required"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="nameof"
    (optionSelected)="onChange($event)"
  >
    <ng-container *ngIf="showList"
      ><mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
        [ngClass]="option.optionClass"
        (onSelectionChange)="onSelect($event, option)"
        (click)="search()"
      >
        <mat-icon *ngIf="renderIcons">{{ option.name }}</mat-icon> {{ option.name }}
        <ng-container *ngIf="option.optionSuffix"
          >({{ option.optionSuffix | translate }})</ng-container
        >
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
<div class="input-description">{{ description }}</div>
