import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { TranslateModule } from '@ngx-translate/core';
import { UiActivityColorComponent } from './ui-activity-color/ui-activity-color.component';
import { UiAutocompleteComponent } from './ui-autocomplete/ui-autocomplete.component';
import { UiButtonComponent } from './ui-button/ui-button.component';
import { UiCheckboxComponent } from './ui-checkbox/ui-checkbox.component';
import { UiChipComponent } from './ui-chip/ui-chip.component';
import { UiDatepickerComponent } from './ui-datepicker/ui-datepicker.component';
import { UiListComponent } from './ui-list/ui-list.component';
import { UiListSelectComponent } from './ui-list-select/ui-list-select.component';
import { UiPillComponent } from './ui-pill/ui-pill.component';
import { UiRadioGroupComponent } from './ui-radio-group/ui-radio-group.component';
import { UiSelectGroupComponent } from './ui-select-group/ui-select-group.component';
import { UiSlideToggleComponent } from './ui-slide-toggle/ui-slide-toggle.component';
import { UiTableSelectionComponent } from './ui-table-selection/ui-table-selection.component';
import { UiTextAreaComponent } from './ui-text-area/ui-text-area.component';
import { UiTextInputComponent } from './ui-text-input/ui-text-input.component';
import { ErrorMessagesPipe } from '../pipes/error-messages.pipe';
import { MaterialModule } from '../../modules/material.module';
import { SharedUiSelectComponent } from './ui-select/ui-select.component';
import { UiChipsAutocompleteComponent } from './ui-chips-autocomplete/ui-chips-autocomplete.component';

@NgModule({
  declarations: [
    SharedUiSelectComponent,
    UiActivityColorComponent,
    UiAutocompleteComponent,
    UiButtonComponent,
    UiCheckboxComponent,
    UiChipComponent,
    UiChipsAutocompleteComponent,
    UiDatepickerComponent,
    UiListComponent,
    UiListSelectComponent,
    UiPillComponent,
    UiRadioGroupComponent,
    UiSelectGroupComponent,
    UiSlideToggleComponent,
    UiTableSelectionComponent,
    UiTextAreaComponent,
    UiTextInputComponent,
  ],
  imports: [
    CommonModule,
    ErrorMessagesPipe,
    MaterialModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    SharedUiSelectComponent,
    UiActivityColorComponent,
    UiAutocompleteComponent,
    UiButtonComponent,
    UiCheckboxComponent,
    UiChipComponent,
    UiChipsAutocompleteComponent,
    UiDatepickerComponent,
    UiListComponent,
    UiListSelectComponent,
    UiPillComponent,
    UiRadioGroupComponent,
    UiSelectGroupComponent,
    UiSlideToggleComponent,
    UiTableSelectionComponent,
    UiTextAreaComponent,
    UiTextInputComponent,
  ],
})
export class UIElementsModule {}
