/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import {
  IPerformInfoRequest,
  IRiskAssessmentPerformRequest,
  IRiskAssessmentRequest,
} from '../models/risk-assessment.model';

export class LoadRiskAssessments {
  static readonly type = '[RiskAssessments] Load Risk Assessments via Service';
  constructor(public filter?: MainTableFilterModel, public menuId?: string) {}
}

export class LoadRiskAssessmentById {
  static readonly type = '[RiskAssessments] Load Risk Assessment By Id via Service';
  constructor(public id: string) {}
}

export class LoadRiskAssessmentsForIO {
  static readonly type = 'RiskAssessments] Load RiskAssessments for IO via Service';
  constructor() {}
}

export class CreateRiskAssessment {
  static readonly type = '[RiskAssessments] Create Risk Assessment';
  constructor(public riskAssessment: IRiskAssessmentRequest) {}
}

export class UpdateRiskAssessment {
  static readonly type = '[RiskAssessments] Update Risk Assessment';
  constructor(public riskAssessment: IRiskAssessmentRequest) {}
}

export class DeleteRiskAssessment {
  static readonly type = '[RiskAssessments] Delete Risk Assessment';
  constructor(public riskAssessmentId: string) {}
}

export class PerformRiskAssessment {
  static readonly type = '[RiskAssessments] Perform Risk Assessment';
  constructor(public performRequest: IPerformInfoRequest | IRiskAssessmentPerformRequest) {}
}

export class LoadRiskAssessmentVersions {
  static readonly type = '[RiskAssessments] Load Risk Assessment Versions';
  constructor(public id: string) {}
}

export class LoadRiskAssessmentVersionById {
  static readonly type = '[RiskAssessments] Load Risk Assessment Version By Id';
  constructor(public id: string, public versionId: string) {}
}
