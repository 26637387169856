import { Store } from '@ngxs/store';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuItemModel } from 'src/app/core/models/menu-id.model';
import { ToastService } from 'src/app/core/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminMaintenanceRoutes } from 'src/app/core/enums/routes.enum';
import { MaintenanceItemTypeEnum } from 'src/app/maintenance-admin/models/maintenance-item-type.enum';
import {
  CreateMenuStructure,
  DeleteMenuStructure,
  UpdateMenuStructure,
} from 'src/app/core/state/menu-tree-structure.actions';
import { ModuleNumbers } from 'src/app/core/enums/module-numbers.enum';
import { TenantUserPrivilegesService } from 'src/app/tenant-selector/services/tenant-user-privileges.service';
import { Observable } from 'rxjs';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { MenuListLogicService } from '../../../core/services/menu-list-logic.service';
import { CompOrCompGroupDialogComponent } from '../../../maintenance-admin/components/components/comp-or-comp-group-dialog/comp-or-comp-group-dialog.component';
import { MenuTreeDialogComponent } from '../menu-tree-dialog/menu-tree-dialog.component';
import { NameIconForm } from '../../forms/name-icon.form';
import { MenuTreeButtonAction } from '../../enums/menu-tree-button-actions.enum';
import { IUserAccess } from '../../user-view/models/tenant-user-access.model';
import { CopyMenuTreeModalComponent } from '../copy-menu-tree-modal/copy-menu-tree-modal.component';
import { CopyComponentsStructureModalComponent } from '../../components/copy-components-structure-modal/copy-components-structure-modal.component';
import { MenuDragDropService } from '../menu-drag-drop.service';

@Component({
  selector: 'app-menu-tree-buttons',
  templateUrl: './menu-tree-buttons.component.html',
  styleUrls: ['./menu-tree-buttons.component.scss'],
})
export class MenuTreeButtonsComponent implements OnInit {
  userAccess$: Observable<IUserAccess> = this.store.select(TenantUserAccessState.userAccess);

  @Input() item: MenuItemModel;
  @Input() onlyAdd: boolean;
  @Input() tenantId: string;
  @Input() moduleNumber: ModuleNumbers;

  @Output() readonly showChildren: EventEmitter<boolean> = new EventEmitter<boolean>();

  menuTreeButtonActions = MenuTreeButtonAction;
  isProductOwner = false;

  constructor(
    public dialog: MatDialog,
    public menuDNDService: MenuDragDropService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router,
    private toastService: ToastService,
    private menuListLogicService: MenuListLogicService,
    private tenantUserPrivileges: TenantUserPrivilegesService
  ) {}

  ngOnInit(): void {
    this.userAccess$.subscribe((userAccess) => {
      if (userAccess) {
        this.isProductOwner = userAccess.isProductOwner;
      }
    });
  }

  checkPermission(privilege: string | string[]): boolean {
    return this.tenantUserPrivileges.userHasPrivilege(privilege);
  }

  openModal(action: MenuTreeButtonAction): void {
    switch (action) {
      case MenuTreeButtonAction.Create:
        this.openCreateModal(action);
        break;
      case MenuTreeButtonAction.Update:
        this.openUpdateModal(action);
        break;
      case MenuTreeButtonAction.Delete:
        this.openDeleteModal(action);
        break;
      default:
        this.toastService.error('somethingWentWrong');
    }
  }

  openCopyMenuModal(): void {
    let menuId = null;

    if (this.item?.id) {
      menuId = this.item?.id;
    } else if (this.moduleNumber === ModuleNumbers.Document) {
      menuId = this.activatedRoute.snapshot.params.id;
    }

    this.dialog.open(CopyMenuTreeModalComponent, {
      data: {
        module: this.moduleNumber,
        menuId,
      },
      width: '35.5rem',
    });
  }

  openCopyComponentsStructureModal(): void {
    this.dialog.open(CopyComponentsStructureModalComponent, {
      data: { moduleNumber: this.moduleNumber, id: this.item?.id },
      width: '35.5rem',
    });
  }

  openCreateModal(action: MenuTreeButtonAction): void {
    if (this.moduleNumber === ModuleNumbers.Maintenance) {
      this.dialog.open(CompOrCompGroupDialogComponent, { data: this.item?.id || undefined });
    } else {
      this.menuListLogicService.saveTempParentId(this.item?.id);
      this.dialog
        .open(MenuTreeDialogComponent, {
          data: {
            modalType: action,
            module: this.moduleNumber,
            form: new NameIconForm(),
          },
          width: '30rem',
        })
        .afterClosed()
        .subscribe((result) => {
          if (result && result.form.value.name) {
            const menuStructureToCreate: any = {
              module: this.moduleNumber,
              name: result.form.value.name,
              icon: result.form.value.icon?.id || '',
              parentId: result.form.value.parentId || null,
              departments: [],
            };
            this.store
              .dispatch(new CreateMenuStructure(menuStructureToCreate))
              .subscribe(() => this.showChildren.emit(true));
          }
        });
    }
  }

  openUpdateModal(action: MenuTreeButtonAction): void {
    if (this.moduleNumber === ModuleNumbers.Maintenance) {
      if (this.item.type === MaintenanceItemTypeEnum.Component) {
        this.router.navigate([`${AdminMaintenanceRoutes.ComponentDetail}/${this.item.id}`]);
      } else {
        this.router.navigate([`${AdminMaintenanceRoutes.ComponentGroupDetail}/${this.item.id}`]);
      }
    } else {
      this.dialog
        .open(MenuTreeDialogComponent, {
          data: {
            modalType: action,
            module: this.moduleNumber,
            form: new NameIconForm(this.item),
          },
          width: '30rem',
        })
        .afterClosed()
        .subscribe((result) => {
          if (result && result.form.value.name) {
            const menuStructureToUpdate: any = {
              id: result.form.value.id,
              module: this.moduleNumber,
              name: result.form.value.name,
              icon: result.form.value.icon?.id || '',
              parentId: result.form.value.parentId || null,
              departments: [],
            };
            this.store.dispatch(new UpdateMenuStructure(menuStructureToUpdate));
          }
        });
    }
  }

  openDeleteModal(action: MenuTreeButtonAction): void {
    if (this.moduleNumber === ModuleNumbers.Maintenance) {
      this.toastService.error('deleteCanOnlyBePerformedInEditDetailedView');
    } else {
      this.dialog
        .open(MenuTreeDialogComponent, {
          data: {
            modalType: action,
            module: this.moduleNumber,
            form: new NameIconForm(this.item),
            items: this.item.children,
            parentId: this.item.parentId || null,
          },
          width: '35rem',
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === 'deleted') {
            this.store.dispatch(new DeleteMenuStructure(this.item.id));
          }
        });
    }
  }
}
