import {
  Component,
  Input,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() chartName: string;
  @Input() data1: number;
  @Input() data2: number;
  @Input() dataColor1 = 'red';
  @Input() dataColor2 = 'green';
  @Input() label = 'Label 1';
  @Input() label1 = 'Label 1';
  @Input() label2 = 'Label 2';

  public chart: any;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.data1?.currentValue !== undefined &&
        changes.data1?.previousValue !== undefined &&
        changes.data1.currentValue !== changes.data1.previousValue) ||
      (changes.data2?.currentValue !== undefined &&
        changes.data2?.previousValue !== undefined &&
        changes.data2.currentValue !== changes.data2.previousValue)
    ) {
      setTimeout(() => {
        if (this.chart) {
          this.chart.destroy();
          this.createChart();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.createChart();
    });
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  createChart() {
    this.chart = new Chart(this.chartName, {
      type: 'pie',
      data: {
        // values on X-Axis
        labels: [this.label1, this.label2],
        datasets: [
          {
            label: this.label,
            data: [this.data1, this.data2],
            backgroundColor: [this.dataColor1, this.dataColor2],
            hoverOffset: 4,
            borderColor: 'grey',
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        aspectRatio: 2.5,
      },
    });

    this.cdRef.detectChanges();
  }
}
