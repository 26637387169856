import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss'],
})
export class LogoutButtonComponent {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  logout() {
    TenantUserAccessState.clearSelectedTenant();
    this.oidcSecurityService.logoff();
  }
}
