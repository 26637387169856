import { Component, Input } from '@angular/core';
import { differenceInDays } from 'date-fns';
import { PaxOverviewType } from 'src/app/crew-management-user/enums/pax-overview-type.enum';
import { PersonOnBoardType } from '../../../../../crew-management-user/models/personnel-with-missing-confirmation.model';

@Component({
  selector: 'app-crew-management-user-template',
  templateUrl: './crew-management-user-template.component.html',
  styleUrls: ['./crew-management-user-template.component.scss'],
})
export class CrewManagementUserTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  paxOverviewType = PaxOverviewType;
  dateFormat = 'yyyy-MM-dd';
  dateTimeFormat = 'yyyy-MM-dd HH:mm';
  personOnBoardType = PersonOnBoardType;
  trackBy = (index: number) => index;

  diffDates(from: Date, to: Date): number {
    return differenceInDays(new Date(to), new Date(from)) + 1;
  }
}
