import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/modules/material.module';
import { UiAttachmentsComponent } from './ui-attachments.component';
import { DropzoneDirective } from './dropzone.directive';
import { EditAttachmentDialogComponent } from './edit-attachment-dialog/edit-attachment-dialog.component';
import { UploaderDialogComponent } from './uploader-dialog/uploader-dialog.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { AttachmentState } from './state/attachments.state';
import { TranslateArrayPipe } from '../pipes/translate-array.pipe';
import { UIElementsModule } from '../ui-elements/ui-elements.module';
import { AppLoaderDirective } from '../directives/app-loader.directive';
import { ConfirmDialogDirective } from '../dialogs/confirm-dialog/confirm-dialog.directive';

@NgModule({
  declarations: [
    DropzoneDirective,
    EditAttachmentDialogComponent,
    UiAttachmentsComponent,
    UploaderDialogComponent,
    UploadTaskComponent,
  ],
  imports: [
    AppLoaderDirective,
    CommonModule,
    ConfirmDialogDirective,
    NgxsModule.forFeature([AttachmentState]),
    MatCardModule,
    MaterialModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    TranslateArrayPipe,
    TranslateModule,
    UIElementsModule,
  ],
  exports: [
    DropzoneDirective,
    EditAttachmentDialogComponent,
    UiAttachmentsComponent,
    UploaderDialogComponent,
    UploadTaskComponent,
  ],
})
export class AttachmentsModule {}
