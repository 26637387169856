/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ICertificate, ICertificateRenew } from '../models/certificate.model';

// TODO Illia: unused, remove soon
// export class LoadCertificates {
//   static readonly type = '[Certificates] Load Certificates via Service';
//   constructor(public filter?: MainTableFilterModel) {}
// }

export class LoadCertificatesByMenu {
  static readonly type = '[Certificates] Load Certificates via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadCertificateGroups {
  static readonly type = '[Certificates] Load Certificate Groups via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadSpecificCertificate {
  static readonly type = '[Certificates] Load Specific Certificate via Service';
  constructor(public certificateId: string) {}
}

export class LoadCertificatesForIO {
  static readonly type = '[Certificates] Load Certificates for IO via Service';
  constructor() {}
}

export class CreateCertificate {
  static readonly type = '[Certificate] Create Certificate';
  constructor(public certificate: ICertificate) {}
}

export class UpdateCertificate {
  static readonly type = '[Certificate] Update Certificate';
  constructor(public certificate: ICertificate) {}
}

export class DeleteCertificate {
  static readonly type = '[Certificate] Delete Certificate';
  constructor(public certificateId: string) {}
}

export class RenewCertificate {
  static readonly type = '[Certificate] Renew Certificate';
  constructor(public certificate: ICertificateRenew) {}
}

export class RestoreCertificate {
  static readonly type = '[Certificate] Restore Certificate';
  constructor(public id: string) {}
}
