/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IAccountRequest } from '../models/account.model';

export class LoadAccounts {
  static readonly type = '[Account] Load Accounts via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllAccounts {
  static readonly type = '[Account] Load All Accounts via Service';
  constructor() {}
}

export class LoadAccountById {
  static readonly type = '[Account] Load a single account';
  constructor(public id: string) {}
}

export class CreateAccount {
  static readonly type = '[Account] Create account';
  constructor(public account: IAccountRequest) {}
}

export class UpdateAccount {
  static readonly type = '[Account] Update account';
  constructor(public account: IAccountRequest) {}
}

export class DeleteAccount {
  static readonly type = '[Account] Delete account';
  constructor(public id: string) {}
}
