import { IdName } from 'src/app/shared/models/id-name';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { AttachmentForm, UploaderForm } from '../forms/uploader.form';
import { AttachmentState } from '../state/attachments.state';
import {
  LoadAttachmentCategories,
  LoadAttachmentUnallowedFileTypes,
} from '../state/attachments.actions';

@Component({
  selector: 'app-uploader-dialog',
  templateUrl: './uploader-dialog.component.html',
  styleUrls: ['./uploader-dialog.component.scss'],
})
export class UploaderDialogComponent implements OnInit, OnDestroy {
  attachmentCategories$: Observable<IdName[] | undefined> = this.store.select(
    AttachmentState.getAttachmentCategories
  );
  unallowedAttachmentFileTypes$: Observable<string[] | undefined> = this.store.select(
    AttachmentState.getAttachmentUnallowedFileTypes
  );

  isHovering: boolean;
  form: UploaderForm = new UploaderForm();
  unallowedAttachmentFileTypes: string[];
  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<UploaderDialogComponent>,
    private store: Store,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.unallowedAttachmentFileTypes$.subscribe(
        (unallowedAttachmentFileTypes: string[] | undefined) => {
          if (unallowedAttachmentFileTypes) {
            this.unallowedAttachmentFileTypes = unallowedAttachmentFileTypes;
          }
        }
      )
    );
    this.store.dispatch([new LoadAttachmentUnallowedFileTypes(), new LoadAttachmentCategories()]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleHover(event: boolean): void {
    this.isHovering = event;
  }

  onFileSelected(event: Event): void {
    this.processFiles((event.target as HTMLInputElement).files as FileList);
  }

  processFiles(fileList: FileList): void {
    let files = Array.from(fileList);

    const emptyFiles = files.filter((file: File) => file.size === 0);
    if (emptyFiles.length > 0) {
      const fileNames = emptyFiles.map((file: File) => file.name).join(', ');
      this.toastService.error('cannotAddEmptyAttachments', { fileNames });
    }

    const unallowedFiles = files.filter((file: File) => this.isUnsupportedFileType(file));

    if (unallowedFiles.length > 0) {
      const fileNames = unallowedFiles.map((file: File) => file.name).join(', ');
      this.toastService.error('cannotAddUnallowedAttachments', { fileNames });
    }

    files = files.filter(
      (file: File) =>
        emptyFiles.every((emptyFile) => file !== emptyFile) &&
        unallowedFiles.every((unallowedFile) => file !== unallowedFile)
    );

    files.forEach((file) =>
      this.form.attachments.push(new AttachmentForm({ name: file.name, categories: [1], file }))
    );
  }

  deleteFile(index: number): void {
    this.form.attachments.removeAt(index);
  }

  upload(): void {
    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.attachmentControls.map((field) => field.value));
  }

  private isUnsupportedFileType(file: File): boolean {
    return this.unallowedAttachmentFileTypes.some((fileType: string) =>
      file.name.endsWith(fileType)
    );
  }
}
