import { Pipe, PipeTransform } from '@angular/core';
import { FormUtilService } from '../../services/form-util.service';
import { Attachment } from '../models/attachment.model';

@Pipe({
  name: 'isAttachment',
  pure: true,
  standalone: true,
})
export class IsAttachmentPipe implements PipeTransform {
  transform(element: any): element is Attachment {
    return FormUtilService.isAttachment(element);
  }
}
