import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getDepartmentId, getTenantId } from 'src/app/services/store-snapshot.service';
import { environment } from 'src/environments/environment';
import { IMaintenancePrintRequest } from 'src/app/maintenance-user/models/print-jobs-request.model';
import { IdName } from 'src/app/shared/models/id-name';
import { FormDataService } from 'src/app/shared/attachments/service/form-data.service';
import { UpdateMenuStructureOrderRequest } from 'src/app/core/models/menu-id.model';
import {
  CopyComponentsModel,
  IComponentsStructureMenu,
} from '../models/components-structure-menu.model';
import {
  IMaintenanceComponentGroup,
  IMaintenanceComponentGroupCreateUpdate,
} from '../models/maintenance-component-group.model';
import {
  IMaintenanceComponent,
  IMaintenanceComponentCreateUpdate,
} from '../models/maintenance-component.model';

@Injectable({
  providedIn: 'root',
})
export class ComponentStructureApiService {
  private readonly headers = { 'content-type': 'application/json' };
  private readonly multipartFormDataHeaders = { 'content-type': 'multipart/form-data' };
  private get urlForComponentsMenu(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/Components/Menu`;
  }
  private get urlComponents(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/Components`;
  }
  private get urlComponentGroups(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/ComponentGroups`;
  }

  constructor(private http: HttpClient) {}

  loadComponentsMenuStructure(): Observable<IComponentsStructureMenu[]> {
    return this.http.get<IComponentsStructureMenu[]>(this.urlForComponentsMenu);
  }

  // MAINTENANCE COMPONENTS
  getComponentById(id: string): Observable<any> {
    const url = `${this.urlComponents}/${id}`;
    return this.http.get<any>(url);
  }

  createComponent(component: IMaintenanceComponentCreateUpdate): Observable<any> {
    if (component.parentComponentId === '1') {
      component.parentComponentId = null;
    }

    const formData = FormDataService.mapToFormData(component);
    return this.http.post<any>(this.urlComponents, formData);
  }

  updateComponent(component: IMaintenanceComponentCreateUpdate): Observable<any> {
    if (component.parentComponentId === '1') {
      component.parentComponentId = null;
    }

    const formData = FormDataService.mapToFormData(component);
    return this.http.put<any>(this.urlComponents, formData);
  }

  deleteComponent(id: string) {
    const url = `${this.urlComponents}/${id}/Scrap`;
    return this.http.delete<any>(url);
  }

  getComponentList(): Observable<IMaintenanceComponent[]> {
    const url = `${this.urlComponents}`;
    return this.http.get<IMaintenanceComponent[]>(url);
  }

  getAllComponentList(): Observable<IdName[]> {
    const url = `${this.urlComponents}/List`;
    return this.http.get<IdName[]>(url);
  }

  transformToComponentsGroup(id: string) {
    const url = `${this.urlComponents}/${id}/ToComponentsGroup`;
    return this.http.patch<any>(url, {});
  }

  // MAINTENANCE COMPONENT GROUPS
  getComponentGroupById(id: string): Observable<any> {
    const url = `${this.urlComponentGroups}/${id}`;
    return this.http.get<any>(url);
  }

  createComponentGroup(componentGroup: IMaintenanceComponentGroupCreateUpdate): Observable<any> {
    if (componentGroup.parentId === '1') {
      componentGroup.parentId = null;
    }

    const formData = FormDataService.mapToFormData(componentGroup);
    return this.http.post<any>(this.urlComponentGroups, formData);
  }

  updateComponentGroup(componentGroup: IMaintenanceComponentGroupCreateUpdate): Observable<any> {
    if (componentGroup.parentId === '1') {
      componentGroup.parentId = null;
    }

    const formData = FormDataService.mapToFormData(componentGroup);
    const url = `${this.urlComponentGroups}`;
    return this.http.put<any>(url, formData);
  }

  deleteComponentGroup(id: string) {
    const url = `${this.urlComponentGroups}/${id}`;
    return this.http.delete<any>(url);
  }

  getComponentGroupList(): Observable<IMaintenanceComponentGroup[]> {
    const url = `${this.urlComponentGroups}`;
    return this.http.get<IMaintenanceComponentGroup[]>(url);
  }

  printComponentsList(request: IMaintenancePrintRequest): Observable<Blob> {
    // excel isn't supported yet
    // const printType = request.isExcel ? 'Excel' : 'Pdf';
    const url = `${this.urlComponents}/ComponentsList/Pdf`;
    const params: any = {
      Critical: request.isCritical,
      Class: request.isClass,
      IncludeAllComponents: request.includeAllComponents,
    };

    if (request.componentId) {
      params.ComponentsIdToPrint = request.componentId;
    }

    return this.http.get(url, { params, responseType: 'blob' });
  }

  copyComponentStructure(model: CopyComponentsModel): Observable<void> {
    const url = `${this.urlComponents}/copy`;
    return this.http.post<void>(url, model);
  }

  transformToComponent(id: string) {
    const url = `${this.urlComponentGroups}/${id}/ToComponent`;
    return this.http.patch<any>(url, {});
  }

  updateComponentStructureOrder(order: UpdateMenuStructureOrderRequest): Observable<void> {
    const url = `${this.urlComponents}/structure`;
    return this.http.put<void>(url, order);
  }
}
