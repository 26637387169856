import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { filter, first, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { FormsLibraryState } from './state/forms-library.state';
import { LoadCustomFormStatuses } from './state/forms-library.actions';

@Injectable()
export class CustomFormStatusesResolver implements Resolve<Observable<any>> {
  customFormStatusesLoaded$: Observable<boolean> = this.store.select(
    FormsLibraryState.customFormStatusesLoaded
  );

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.customFormStatusesLoaded$.pipe(
      tap((customFormStatusesLoaded) => {
        if (!customFormStatusesLoaded) {
          this.store.dispatch(new LoadCustomFormStatuses());
        }
      }),
      filter((customFormStatusesLoaded) => customFormStatusesLoaded),
      first()
    );
  }
}
