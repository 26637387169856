/* eslint-disable max-classes-per-file */
import { IdName } from 'src/app/shared/models/id-name';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadWeatherInformation {
  static readonly type = '[Weather Information] Load Weather Information via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllWeatherInformation {
  static readonly type = '[Weather Information] Load All Weather Information via Service';
  constructor() {}
}

export class LoadWeatherInformationById {
  static readonly type = '[Weather Information] Load a single Weather Information';
  constructor(public id: string) {}
}

export class CreateWeatherInformation {
  static readonly type = '[Weather Information] Create Weather Information';
  constructor(public weatherInformation: IdName) {}
}

export class UpdateWeatherInformation {
  static readonly type = '[Weather Information] Update Weather Information';
  constructor(public weatherInformation: IdName) {}
}

export class DeleteWeatherInformation {
  static readonly type = '[Weather Information] Delete Weather Information';
  constructor(public id: string) {}
}
