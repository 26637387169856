import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserTasksRoutes } from 'src/app/core/enums/routes.enum';
import { RestoreTask } from 'src/app/task-user/state/task.actions';

@Component({
  selector: 'app-task-overview-template',
  templateUrl: './task-overview-template.component.html',
})
export class TaskOverviewTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  performRoute = UserTasksRoutes.Perform;

  constructor(private store: Store) {}

  restore(): void {
    this.store.dispatch(new RestoreTask(this.element.id));
  }
}
