import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { CompetenceRequirementState } from 'src/app/competence/state/competence-requirement.state';
import { DepartmentState } from 'src/app/department/state/department.state';
import { GlobalErrorModel } from 'src/app/core/models/global-error.model';
import { ToastService } from '../services/toast.service';
import {
  HandleAccessError,
  HandleCodeAndMessage,
  HandleCodeAndParameters,
  HandleGenericError,
  HandleMessageAndParameters,
} from './global-error.actions';

export interface GlobalErrorStateModel {
  errors: any[];
}

const GLOBAL_ERROR_STATE_TOKEN = new StateToken<GlobalErrorStateModel>('globalError');
const PARAM_FORMATTING_NEEDED: Record<string, string> = {
  err_CompetenceRequirementOverlappingRequirementScope:
    'err_CompetenceRequirementOverlappingRequirementScope',
};

@State({
  name: GLOBAL_ERROR_STATE_TOKEN,
  defaults: {
    errors: [],
  },
})
@Injectable()
export class GlobalErrorState {
  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private store: Store
  ) {}

  @Action(HandleCodeAndParameters, { cancelUncompleted: true })
  handleCodeAndParameters(
    ctx: StateContext<GlobalErrorStateModel>,
    { error }: HandleCodeAndParameters
  ) {
    ctx.setState(
      patch({
        errors: insertItem<GlobalErrorModel>(error),
      })
    );
    this.error(this.translateService.instant(error.code, error.parameters));
  }

  @Action(HandleGenericError, { cancelUncompleted: true })
  showGenericError(
    ctx: StateContext<GlobalErrorStateModel>,
    { error, duration }: HandleGenericError
  ) {
    if (error) {
      ctx.setState(
        patch({
          errors: insertItem<string>(error),
        })
      );
      this.error(error, duration);
    } else {
      this.error(this.translateService.instant('err_Generic'));
    }
  }

  private error(message: string, duration?: number): void {
    if (duration !== undefined) {
      this.toastService.errorWithDuration(message, duration);
    } else {
      this.toastService.error(message);
    }
  }

  @Action(HandleMessageAndParameters, { cancelUncompleted: true })
  handleMessageAndReason(
    ctx: StateContext<GlobalErrorStateModel>,
    { error, duration }: HandleMessageAndParameters
  ) {
    ctx.setState(
      patch({
        errors: insertItem<GlobalErrorModel>(error),
      })
    );
    let message = '';
    if (this.isParamFormattingNeeded(error.code)) {
      message = this.translateService.instant(error.code, this.formatErrorParams(error));
    } else {
      const paramsTable = {
        ...(Object.values(error.parameters) as object[])
          .flat()
          .map((item) => Object.values(item))
          .flat(),
      };
      const params = paramsTable.length ? paramsTable : error.parameters;
      message = `${this.translateService.instant(error.code, params)}`;
    }
    this.error(message, duration);
  }

  @Action(HandleCodeAndMessage, { cancelUncompleted: true })
  handleCodeAndMessage(ctx: StateContext<GlobalErrorStateModel>, { error }: HandleCodeAndMessage) {
    ctx.setState(
      patch({
        errors: insertItem<GlobalErrorModel>(error),
      })
    );
    this.error(`${this.translateService.instant(error.code)} ${error.message}`);
  }

  @Action(HandleAccessError, { cancelUncompleted: true })
  handleAccessError(ctx: StateContext<GlobalErrorStateModel>, { error }: HandleAccessError) {
    ctx.setState(
      patch({
        errors: insertItem<Partial<GlobalErrorModel>>(error),
      })
    );
    this.error(
      `${this.translateService.instant('missingPrivileges', {
        resource: error.parameters.resource,
        privileges: error.parameters.privileges,
      })}`
    );
  }

  isParamFormattingNeeded(code: string): boolean {
    return PARAM_FORMATTING_NEEDED[code] !== undefined;
  }

  formatErrorParams(error: GlobalErrorModel): any {
    const formatted: any = {};
    const findById = (id: string) => (item: { id: string }) => item.id === id;
    switch (PARAM_FORMATTING_NEEDED[error.code]) {
      case PARAM_FORMATTING_NEEDED.err_CompetenceRequirementOverlappingRequirementScope: {
        const { departmentIds, positionIds, competenceRequirementIds } = error.parameters;
        if (departmentIds) {
          const departments = this.store.selectSnapshot(DepartmentState.getDepartmentsForUser);
          formatted.departments = departmentIds.map(
            (id: string) => departments?.find(findById(id))?.name || id
          );
        }
        if (positionIds) {
          const departments = this.store.selectSnapshot(DepartmentState.getDepartmentsForUser);
          const positions = departmentIds
            .map((id: string) => departments?.find(findById(id))?.positions)
            .flat();
          formatted.positions = positionIds.map(
            (id: string) => positions.find(findById(id))?.name || id
          );
        }
        if (competenceRequirementIds) {
          const requirements = this.store.selectSnapshot(
            CompetenceRequirementState.getCompetenceRequirements
          );
          formatted.requirements = competenceRequirementIds.map(
            (id: string) => requirements?.find(findById(id))?.competenceName || id
          );
        }

        break;
      }
      default:
    }
    return formatted;
  }
}
