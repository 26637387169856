/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IPax } from '../models/pax.model';

export class LoadPaxes {
  static readonly type = '[Paxes] Load Paxs via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllPaxes {
  static readonly type = '[Paxes] Load All Paxs via Service';
  constructor() {}
}

export class LoadPaxById {
  static readonly type = '[Paxes] Load Pax By Id via Service';
  constructor(public id: string) {}
}

export class CreatePax {
  static readonly type = '[Paxes] Create Pax';
  constructor(public pax: IPax) {}
}

export class UpdatePax {
  static readonly type = '[Paxes] Update Pax';
  constructor(public pax: IPax) {}
}

export class DeletePax {
  static readonly type = '[Paxes] Delete Pax';
  constructor(public paxId: string) {}
}
