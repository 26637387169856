import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngxs/store';
import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { Observable, Subscription, of } from 'rxjs';
import { datepickerValueToDate } from 'src/app/shared/functions/datepicker-value-to-date';
import {
  MainTableFilterSearchByModel,
  MainTableSortingType,
} from 'src/app/shared/models/main-table-filter.model';
import { IOption } from 'src/app/shared/models/option.model';

export enum MainTableFilterOperand {
  Equal = 'Equal',
  In = 'In',
  Contains = 'Contains',
  LessThan = 'LessThan',
  MoreThan = 'MoreThan',
  LessThanOrEqual = 'LessThanOrEqual',
  MoreThanOrEqual = 'MoreThanOrEqual',
  EqualDate = 'EqualDate',
}
export type MainTableFilterListOption = { label: string; value: string | boolean }[] | string[];
export interface MainTableFilterColumnData {
  operand: string;
  options: Observable<MainTableFilterListOption>;
  type: 'text' | 'number' | 'date' | 'boolean' | 'list';
}

export type MainTableSearchByValueType = string | string[] | number;

@Component({
  selector: 'app-main-table-column-filter',
  templateUrl: './main-table-column-filter.component.html',
  styleUrls: ['./main-table-column-filter.component.scss'],
  exportAs: 'appMainTableColumnFilter',
})
export class MainTableColumnFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() sortDirection: MainTableSortingType = '';
  @Input() column: string;
  @Input() sorting = false;
  @Input() initialFilters: MainTableFilterSearchByModel[] = [];
  @Input() filterConfig: Record<string, MainTableFilterColumnData> = {};
  @Output() filter = new EventEmitter<MainTableFilterSearchByModel[]>();
  @Output() close = new EventEmitter<void>();
  @Output() sort = new EventEmitter<Sort>();
  optionControlMap: { value: string; label: string; control: FormControl }[] = [];
  searchControl = new FormControl('');
  fromControl: FormControl<any> = new FormControl(null);
  toControl: FormControl<any> = new FormControl(null);
  dateFromControl: FormControl<any> = new FormControl(null);
  dateToControl: FormControl<any> = new FormControl(null);
  booleanControl: FormControl<any> = new FormControl(null);
  showOptions = false;
  showSearch = false;
  hasActiveFilters = false;
  columnData: MainTableFilterColumnData;
  booleanControlOptions: IOption[] = [];
  ascIcons: Record<string, string> = {
    number: 'arrow_upward',
    date: '',
    text: 'sort_by_alpha',
  };
  descIcons: Record<string, string> = {
    number: 'arrow_downward',
    date: '',
    text: 'sort_by_alpha',
  };
  ascLabels: Record<string, string> = {
    number: 'sortAscending',
    date: 'oldestFirst',
    text: 'sortAToZ',
    boolean: 'checkedFirst',
  };
  descLabels: Record<string, string> = {
    number: 'sortDecsending',
    date: 'latestFirst',
    text: 'sortZToA',
    boolean: 'notCheckedFirst',
  };

  get controlsValid(): boolean {
    return (
      this.fromControl.valid &&
      this.toControl.valid &&
      this.dateFromControl.valid &&
      this.dateToControl.valid &&
      this.searchControl.valid &&
      this.booleanControl.valid
    );
  }

  get columnType(): string {
    if (!this.columnData) {
      console.warn('No column data for column:', this.column);
      return 'text';
    }
    return this.columnData?.type;
  }

  get isDateColumn(): boolean {
    return this.columnType === 'date';
  }

  get isNumberColumn(): boolean {
    return this.columnType === 'number';
  }

  get isTextColumn(): boolean {
    return this.columnType === 'text';
  }

  get isBooleanColumn(): boolean {
    return this.columnType === 'boolean';
  }

  private rangeControlMapping: any = {
    number: { from: this.fromControl, to: this.toControl },
    date: { from: this.dateFromControl, to: this.dateToControl },
  };
  private subscriptions = new Subscription();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.hasActiveFilters = !!this.initialFilters.length;
    this.columnData = this.filterConfig[this.column];
    this.initControlsFromInitialFilters();
    this.showSearch = this.columnData?.operand !== MainTableFilterOperand.In;
    if (this.isNumberColumn) {
      this.subscriptions.add(this.fromControl.valueChanges.subscribe(() => this.validateRange()));
      this.subscriptions.add(this.toControl.valueChanges.subscribe(() => this.validateRange()));
    }
    if (this.isDateColumn) {
      this.subscriptions.add(
        this.dateFromControl.valueChanges.subscribe(() => this.validateDateRange())
      );
      this.subscriptions.add(
        this.dateToControl.valueChanges.subscribe(() => this.validateDateRange())
      );
    }
    if (!this.columnData) {
      console.warn('No column data for column:', this.column);
      this.columnData = { operand: MainTableFilterOperand.Equal, options: of([]), type: 'text' };
    }
    this.fetchFilterOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialFilters) {
      this.hasActiveFilters = !!this.initialFilters.length;
      this.initControlsFromInitialFilters();
    }
  }

  initControlsFromInitialFilters(): void {
    this.hasActiveFilters = !!this.initialFilters.length;
    if (this.hasActiveFilters) {
      if (this.columnType in this.rangeControlMapping) {
        this.initialFilters.forEach((f) => {
          if (f.operand === MainTableFilterOperand.MoreThanOrEqual) {
            this.rangeControlMapping[this.columnData.type].from.setValue(f.value);
          }
          if (f.operand === MainTableFilterOperand.LessThanOrEqual) {
            this.rangeControlMapping[this.columnData.type].to.setValue(f.value);
          }
        });
      }
      if (this.isTextColumn && this.columnData?.operand === MainTableFilterOperand.Contains) {
        this.searchControl.setValue(this.initialFilters[0].value as string);
      }
      this.booleanControl.setValue(this.initialFilters[0].value as unknown as boolean);
    } else {
      this.resetAllControls();
    }
    this.optionControlMap.forEach((c) => {
      c.control.setValue(
        this.initialFilters.some(
          (f) => f.value === c.value || (Array.isArray(f.value) && f.value.includes(c.value))
        )
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchFilterOptions(): void {
    const options = this.columnData.options ?? of([]);
    if (!options) {
      return;
    }
    this.subscriptions.add(
      options.subscribe((opts) => {
        if (this.isBooleanColumn) {
          this.booleanControlOptions = opts.map((option: any) => {
            const isString = typeof option === 'string';
            const value = isString ? option : option.value;
            const label = isString ? option : option.label;
            return { id: value, name: label };
          });
          return;
        }
        this.optionControlMap = opts.map((option: any) => {
          const isString = typeof option === 'string';
          const value = isString ? option : option.value;
          const label = isString ? option : option.label;

          const control = new FormControl(
            this.initialFilters.some(
              (f) => f.value === value || (Array.isArray(f.value) && f.value.includes(value))
            )
          );

          return { value, label, control };
        });
      })
    );
  }

  sortChange(direction: 'asc' | 'desc'): void {
    // check if the current sort direction is the same as the new one
    // if so, remove the sort
    // if not, set the new sort direction
    let currentDirection = '';
    if (!this.sortDirection) {
      currentDirection = '';
    } else {
      currentDirection =
        this.sortDirection === 'asc' || this.sortDirection === 'Ascending' ? 'asc' : 'desc';
    }
    const newDirection = currentDirection !== direction ? direction : '';
    this.sort.emit({ active: this.column, direction: newDirection });
    this.close.emit();
  }

  filterChange(): void {
    const filters: MainTableFilterSearchByModel[] = [];
    const filterKey = this.column;
    const selectedOptions = this.optionControlMap.filter((c) => c.control.value);
    if (this.isBooleanColumn && this.booleanControl.value !== null) {
      filters.push(
        new MainTableFilterSearchByModel(
          filterKey,
          this.booleanControl.value,
          MainTableFilterOperand.Equal
        )
      );
    }
    if (selectedOptions.length) {
      filters.push(
        new MainTableFilterSearchByModel(
          filterKey,
          selectedOptions.map((c) => c.value),
          MainTableFilterOperand.In
        )
      );
    }
    if (this.showSearch && this.searchControl.value) {
      if (this.columnData?.operand === MainTableFilterOperand.In) {
        (filters[0].value as string[]).push(this.searchControl.value);
      } else {
        filters.push(
          new MainTableFilterSearchByModel(
            filterKey,
            this.searchValueToFilterValue(this.searchControl.value),
            this.isTextColumn ? MainTableFilterOperand.Contains : MainTableFilterOperand.Equal
          )
        );
      }
    }
    if (this.columnType in this.rangeControlMapping) {
      const fromControl = this.rangeControlMapping[this.columnType].from;
      const toControl = this.rangeControlMapping[this.columnType].to;

      if (fromControl.value) {
        filters.push(
          new MainTableFilterSearchByModel(
            filterKey,
            this.isDateColumn
              ? formatISO(startOfDay(datepickerValueToDate(fromControl.value)))
              : fromControl.value,
            MainTableFilterOperand.MoreThanOrEqual
          )
        );
      }
      if (toControl.value) {
        filters.push(
          new MainTableFilterSearchByModel(
            filterKey,
            this.isDateColumn
              ? formatISO(endOfDay(datepickerValueToDate(toControl.value)))
              : toControl.value,
            MainTableFilterOperand.LessThanOrEqual
          )
        );
      }
    }
    this.filter.emit(filters);
    this.hasActiveFilters = !!filters.length;
  }

  apply(): void {
    this.filterChange();
    this.cancel();
  }

  resetAllControls(): void {
    this.optionControlMap.forEach((c) => c.control.reset());
    this.searchControl.reset();
    this.dateFromControl.reset();
    this.dateToControl.reset();
    this.fromControl.reset();
    this.toControl.reset();
    this.booleanControl.reset();
    this.hasActiveFilters = false;
  }

  clear(): void {
    this.resetAllControls();
    if (this.sortDirection) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.sortChange(this.sortDirection);
    }
    this.filterChange();
    this.cancel();
  }

  cancel(): void {
    this.resetAllControls();
    this.close.emit();
  }

  private validateRange(): void {
    if (this.fromControl.value == null || this.toControl.value == null) {
      this.resetNumericControlsErrors();
      return;
    }
    const from = +this.fromControl.value;
    const to = +this.toControl.value;

    if (from > to || to < from) {
      this.fromControl.setErrors({ rangeInvalid: true });
      this.toControl.setErrors({ rangeInvalid: true });
    } else {
      this.resetNumericControlsErrors();
    }
  }

  private validateDateRange(): void {
    if (this.dateFromControl.value == null || this.dateToControl.value == null) {
      this.resetDateControlsErrors();
      return;
    }
    const fromDate = datepickerValueToDate(this.dateFromControl.value);
    const toDate = datepickerValueToDate(this.dateToControl.value);

    if (new Date(fromDate) > new Date(toDate) || new Date(toDate) < new Date(fromDate)) {
      this.dateFromControl.setErrors({ rangeInvalid: true });
      this.dateToControl.setErrors({ rangeInvalid: true });
    } else {
      this.resetDateControlsErrors();
    }
  }

  private resetNumericControlsErrors(): void {
    this.fromControl.setErrors(null);
    this.toControl.setErrors(null);
  }

  private resetDateControlsErrors(): void {
    this.dateFromControl.setErrors(null);
    this.dateToControl.setErrors(null);
  }

  private searchValueToFilterValue(value: string): MainTableSearchByValueType {
    if (this.columnData.type === 'number') {
      return +value;
    }
    return this.columnData?.operand === MainTableFilterOperand.In ? [value] : value;
  }
}
