<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'length'">
    {{ form.dimension.length.value + ' ' }}
    {{ dimensionUnit[form.dimension.dimensionsUnit.value] | translate }}
  </ng-container>

  <ng-container *ngSwitchCase="'breadth'">
    {{ form.dimension.breadth.value + ' ' }}
    {{ dimensionUnit[form.dimension.dimensionsUnit.value] | translate }}
  </ng-container>

  <ng-container *ngSwitchCase="'depth'">
    {{ form.dimension.depth.value + ' ' }}
    {{ dimensionUnit[form.dimension.dimensionsUnit.value] | translate }}
  </ng-container>

  <ng-container *ngSwitchCase="'volume'">
    {{ form.volume.value + ' ' }}
    {{ 'tonnes' | translate }}
  </ng-container>

  <ng-container *ngSwitchCase="'edit'">
    <button *ngIf="!hideButtons" mat-button (click)="buttonFunctions.edit()">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'delete'">
    <button *ngIf="!hideButtons" mat-button (click)="buttonFunctions.delete()">
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ form.get(columnName)?.value }}
  </ng-container>
</ng-container>
