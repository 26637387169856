import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapIdNameToString',
  pure: true,
  standalone: true,
})
export class MapIdNameToString implements PipeTransform {
  transform(items: { id: string; name: string; [key: string]: any }[]): string[] {
    return items.map((idName) => idName.name);
  }
}
