import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { ModuleNumbers } from 'src/app/core/enums/module-numbers.enum';
import { MenuItemModel } from 'src/app/core/models/menu-id.model';
import { CreateUpdateDeleteRecursiveArrayService } from 'src/app/core/services/create-update-delete-recursive-array.service';
import { MenuListLogicService } from 'src/app/core/services/menu-list-logic.service';
import { MenuListState } from 'src/app/core/state/menu-list.state';
import { UpdateMenuStructureOrder } from 'src/app/core/state/menu-tree-structure.actions';
import { UpdateComponentsStructureOrder } from 'src/app/maintenance-admin/state/components-structure.actions';
import { getDepartmentId } from 'src/app/services/store-snapshot.service';

const DEFAULT_ROOT_ID = '00000000-0000-0000-0000-000000000000';

@Injectable({
  providedIn: 'root',
})
export class MenuDragDropService {
  menuDisabledStatuses: Record<string, boolean> = {};
  isStructureChanged = false;
  dragStopped = new EventEmitter();
  structurePreview = new EventEmitter<MenuItemModel[]>();
  private updatedStructure: MenuItemModel[] = [];
  private draggedItem: MenuItemModel | null = null;
  private currentMenuStructure: MenuItemModel[] = [];
  private initialMenuStructure: MenuItemModel[] = [];
  private rootId = DEFAULT_ROOT_ID;

  constructor(
    private menuLogicService: MenuListLogicService,
    private menuStructureService: CreateUpdateDeleteRecursiveArrayService,
    private store: Store
  ) {}

  set activeItem(item: MenuItemModel | null) {
    if (this.draggedItem !== item) {
      this.draggedItem = item;
      if (!item) {
        this.setDefaultStatuses();
        this.dragStopped.emit();
        return;
      }
      if (item.parentId === this.rootId) {
        item.children.forEach((child: MenuItemModel) => {
          this.menuDisabledStatuses[child.id] = true;
        });
      }
    }
  }

  get activeItem(): MenuItemModel | null {
    return this.draggedItem;
  }

  setInitialMenuStructure(structure: MenuItemModel[]): void {
    this.initialMenuStructure = JSON.parse(JSON.stringify(structure));
    this.currentMenuStructure = structure;
    this.structurePreview.emit(this.initialMenuStructure);
    this.isStructureChanged = false;
    this.setDefaultStatuses();
    if (structure.length && structure.every((item) => item.parentId === structure[0].parentId)) {
      this.rootId = structure[0].parentId;
    }
  }

  saveChanges(): void {
    const module = this.store.selectSnapshot(MenuListState.getModuleNumber) as number;
    const updateReq = {
      module,
      departmentId: getDepartmentId(),
      root: {
        id: this.rootId,
        nodes: this.buildStructureForUpdate(this.updatedStructure),
      },
    };
    const action =
      module === ModuleNumbers.Maintenance
        ? new UpdateComponentsStructureOrder(updateReq)
        : new UpdateMenuStructureOrder(updateReq, this.rootId === DEFAULT_ROOT_ID);
    this.store
      .dispatch(action)
      .pipe(
        take(1),
        tap(() => {
          this.isStructureChanged = false;
        })
      )
      .subscribe();
  }

  cancelChanges(): void {
    this.currentMenuStructure = JSON.parse(JSON.stringify(this.initialMenuStructure));
    this.structurePreview.emit(this.currentMenuStructure);
    this.setDefaultStatuses();
    this.isStructureChanged = false;
  }

  setDefaultStatuses(): void {
    this.menuLogicService
      .getIdNameFlatListOfPossibleLocations(this.currentMenuStructure)
      .forEach((item) => {
        this.menuDisabledStatuses[item.id] = false;
      });
  }

  onDrop(target: MenuItemModel, placement: 'inside' | 'bottom' | 'top'): void {
    if (!this.activeItem) return;
    let rootStructure: MenuItemModel[] = JSON.parse(JSON.stringify(this.currentMenuStructure));
    if (placement === 'inside') {
      const updatedItem = { ...this.activeItem, parentId: target.id };
      rootStructure = this.removeActiveItemFromList(rootStructure);
      rootStructure = this.menuStructureService.addNewItemToParentChildrenElements(
        rootStructure,
        target.id,
        updatedItem
      );
    }

    if (placement === 'bottom') {
      const updatedItem = { ...this.activeItem, parentId: target.parentId };
      let targetParent = this.findParentItem(rootStructure, target.parentId);
      let structure = targetParent?.children || rootStructure;
      const targetIndex = structure.findIndex((item) => item.id === target.id);
      const oldIndex = structure.findIndex((item) => item.id === this.activeItem?.id);
      const newIndex = targetIndex + (oldIndex > targetIndex ? 1 : 0);
      rootStructure = this.removeActiveItemFromList(rootStructure);
      targetParent = this.findParentItem(rootStructure, target.parentId);
      structure = targetParent?.children || rootStructure;
      const index = this.isElementDroppedOnSameParent(target) ? newIndex : newIndex + 1;
      structure.splice(index, 0, updatedItem);
    }

    if (placement === 'top') {
      const updatedItem = { ...this.activeItem, parentId: target.parentId };
      rootStructure = this.removeActiveItemFromList(rootStructure);
      const targetParent = this.findParentItem(rootStructure, target.parentId);
      const structure = targetParent?.children || rootStructure;
      structure.unshift(updatedItem);
    }
    this.updateStructure(rootStructure);
    this.activeItem = null;
  }

  private isElementDroppedOnSameParent(target: MenuItemModel): boolean {
    if (!this.activeItem) return false;
    return this.activeItem.parentId === target.parentId;
  }

  private findParentItem(structure: MenuItemModel[], parentId: string): MenuItemModel {
    return CreateUpdateDeleteRecursiveArrayService.getItemThatMatchParentId(structure, parentId);
  }

  private removeActiveItemFromList(structure: MenuItemModel[]): MenuItemModel[] {
    // eslint-disable-next-line no-param-reassign
    return this.menuStructureService.removeItemFromNestedItems(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      structure,
      this.activeItem?.id as string
    );
  }

  private updateStructure(structure: MenuItemModel[]): void {
    this.activeItem = null;
    this.isStructureChanged = true;
    this.currentMenuStructure = structure;
    this.setDefaultStatuses();
    this.structurePreview.emit(this.currentMenuStructure);
    this.updatedStructure = this.currentMenuStructure;
  }

  private buildStructureForUpdate(structure: MenuItemModel[]): any[] {
    return structure.map((item) => {
      return {
        id: item.id,
        nodes: item.children?.length ? this.buildStructureForUpdate(item.children) : [],
      };
    });
  }
}
