import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription, switchMap, tap } from 'rxjs';
import { AdminTenantRoutes } from 'src/app/core/enums/routes.enum';
import { MatDialog } from '@angular/material/dialog';
import { TenantState } from '../../state/tenant.state';
import {
  LoadThirdPartyIntegrationTypes,
  LoadThirdPartyIntegrations,
} from '../../state/tenant.action';
import { WebHookModel } from '../../models/webhook.model';
import { ThirdPartyIntegrationEditComponent } from '../third-party-integration-edit/third-party-integration-edit.component';

@Component({
  selector: 'app-third-party-integration-list',
  templateUrl: './third-party-integration-list.component.html',
  styleUrls: ['./third-party-integration-list.component.scss'],
})
export class ThirdPartyIntegrationListComponent implements OnInit, OnDestroy {
  @Input() chosenTenantIdFromTableRow: string;

  thirdPartyIntegrations$: Observable<WebHookModel[]> = this.store.select(
    TenantState.allThirdPartyIntegrationsPerTenant
  );
  allAvailableEventTypes$: Observable<string[]> = this.store.select(
    TenantState.allAvailableEventTypes
  );

  displayedColumns = ['topic', 'callback'];
  urlForRow = AdminTenantRoutes.Edit;
  addTenantRoute = AdminTenantRoutes.Add;
  availableEvents: string[] = [];
  alreadyExistingEvents: string[] = [];
  webhooks: WebHookModel[] = [];

  private subscriptions = new Subscription();

  constructor(private store: Store, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.store.dispatch([
      new LoadThirdPartyIntegrations(this.chosenTenantIdFromTableRow),
      new LoadThirdPartyIntegrationTypes(this.chosenTenantIdFromTableRow),
    ]);

    this.thirdPartyIntegrations$
      .pipe(
        tap((webhooks: WebHookModel[]) => {
          this.webhooks = webhooks;
          this.alreadyExistingEvents = [];
          webhooks.forEach((webhook: WebHookModel) => {
            if (webhook?.topic) {
              this.alreadyExistingEvents.push(webhook.topic);
            }
          });
        }),
        switchMap(() => this.allAvailableEventTypes$),
        tap((events: string[]) => {
          this.availableEvents = [];
          events.forEach((event: string) => {
            if (!this.isEventAlreadyInUse(event)) {
              this.availableEvents.push(event);
            }
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  showEditModal(row?: WebHookModel): void {
    this.dialog
      .open(ThirdPartyIntegrationEditComponent, {
        panelClass: 'no-padding',
        width: '40rem',
        data: {
          webhooks: this.webhooks,
          webhookTopic: row?.topic,
          availableEvents: this.availableEvents.filter((el, i, a) => i === a.indexOf(el)),
          tenantId: this.chosenTenantIdFromTableRow,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.store.dispatch(new LoadThirdPartyIntegrations(this.chosenTenantIdFromTableRow));
      });
  }

  private isEventAlreadyInUse(event: string): boolean {
    return this.alreadyExistingEvents.some((existingEvent: string) => existingEvent === event);
  }
}
