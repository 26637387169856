import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { AdminUsersRoutes, RoutesEnum } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { UserOptionsApiService } from 'src/app/core/services/user-options-api.service';
import { PatchProfilePicture } from 'src/app/tenant-selector/state/tenant-user-access.actions';
import { UserPreferencesLogicService } from 'src/app/profile/services/user-preferences-logic.service';
import { IUserProductOwnerView, IUserModel, IUserTable } from '../models/user.model';
import { OnSuccess } from '../../decorators/on-success.decorator';
import { IUserOptions } from '../models/user-options.model';

import { UserApiService } from '../services/user-api.service';
import {
  CreateUser,
  DeactivateUser,
  PauseUserEmployments,
  DeleteUserEmployments,
  GetMyUser,
  GetUserById,
  LoadAllUsersAllTenants,
  LoadUserOptions,
  LoadUsers,
  ReactivateUser,
  ResendConfirmationEmail,
  RestoreUser,
  ResumeUserEmployment,
  SendResetPasswordEmail,
  UpdateMyUser,
  UpdateUser,
  RestoreUserEmployment,
} from './user.actions';
import { redirectAndBypassUnsavedDataCheck } from '../../functions/redirect-and-bypass-unsaved-data-check';

export interface UserStateModel {
  currentUser: IUserModel;
  users: IUserTable[];
  usersTotalPages: number;
  allUsersAllTenants: IUserProductOwnerView[];
  allUsersAllTenantsTotalPages: number;
  userOptions?: IUserOptions;
  userOptionsLoaded: boolean;
  showDeletedUsers: boolean;
  showPausedUsers: boolean;
  showDeactivatedUsers: boolean;
}

const USER_STATE_TOKEN = new StateToken<UserStateModel>('user');

@State<UserStateModel>({
  name: USER_STATE_TOKEN,
  defaults: {
    currentUser: <IUserModel>{},
    users: [],
    allUsersAllTenants: [],
    allUsersAllTenantsTotalPages: 0,
    usersTotalPages: 0,
    userOptions: undefined,
    userOptionsLoaded: false,
    showDeletedUsers: false,
    showPausedUsers: false,
    showDeactivatedUsers: false,
  },
})
@Injectable()
export class UserState {
  constructor(
    private service: UserApiService,
    private optionsService: UserOptionsApiService,
    private router: Router,
    private userPreferencesLogicService: UserPreferencesLogicService
  ) {}

  @Action(LoadUsers, { cancelUncompleted: true })
  loadUsers({ getState, setState }: StateContext<UserStateModel>, { filter }: LoadUsers) {
    return this.service.getAllUsers(filter).pipe(
      tap((response: ApiResponse<IUserTable[]>) => {
        const state = getState();
        let newState = {
          ...state,
          users: response.data,
          usersTotalPages: response.totalPages,
        };
        if (filter?.additional !== null && filter?.additional !== undefined) {
          newState = {
            ...newState,
            showDeletedUsers: filter.additional.includeDeleted,
            showPausedUsers: filter.additional.includePaused,
            showDeactivatedUsers: filter.additional.includeDeactivated,
          };
        }
        setState(newState);
      })
    );
  }

  @Action(LoadAllUsersAllTenants, { cancelUncompleted: true })
  loadAllUsersAllTenants(
    { setState }: StateContext<UserStateModel>,
    { filter }: LoadAllUsersAllTenants
  ) {
    return this.service.getAllUsersAllTenants(filter).pipe(
      tap((response: ApiResponse<IUserProductOwnerView[]>) => {
        setState(
          patch({
            allUsersAllTenantsTotalPages: response.totalPages,
            allUsersAllTenants: response.data,
            showDeletedUsers: filter?.additional.includeDeleted,
            showPausedUsers: filter?.additional.includePaused,
            showDeactivatedUsers: filter?.additional.includeDeactivated,
          })
        );
      })
    );
  }

  @Action(LoadUserOptions, { cancelUncompleted: true })
  loadUserOptions({ getState, setState }: StateContext<UserStateModel>) {
    return this.optionsService.getOptions().pipe(
      tap((userOptions: IUserOptions) => {
        const state = getState();
        setState({
          ...state,
          userOptionsLoaded: true,
          userOptions,
        });
      })
    );
  }

  @Action(CreateUser, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'userCreated' })
  createUser(ctx: StateContext<UserStateModel>, { user }: CreateUser) {
    return this.service.createUser(user);
  }

  @Action(UpdateUser, { cancelUncompleted: true })
  @OnSuccess({ message: 'userUpdated' })
  updateUser(ctx: StateContext<UserStateModel>, { user }: UpdateUser) {
    return this.service.updateUser(user).pipe(
      tap(() => {
        if (this.userPreferencesLogicService.getUserId() === user.id) {
          // eslint-disable-next-line no-use-before-define
          dispatchProfilePicture(ctx, user);
        }
        redirectAndBypassUnsavedDataCheck(AdminUsersRoutes.Users);
      })
    );
  }

  @Action(UpdateMyUser, { cancelUncompleted: true })
  @OnSuccess({ message: 'userUpdated' })
  updateMyUser(ctx: StateContext<UserStateModel>, { user }: UpdateMyUser) {
    return this.service.updateMyUser(user).pipe(
      tap(() => {
        // eslint-disable-next-line no-use-before-define
        dispatchProfilePicture(ctx, user);
        redirectAndBypassUnsavedDataCheck(`${RoutesEnum.Profile}/view/(details:edit-user)`);
      })
    );
  }

  @Action(GetUserById, { cancelUncompleted: true })
  getUserById({ getState, setState }: StateContext<UserStateModel>, { id }: GetUserById) {
    return this.service.getUserById(id).pipe(
      tap((user: IUserModel) => {
        const state = getState();
        setState({
          ...state,
          currentUser: user,
        });
      })
    );
  }

  @Action(GetMyUser, { cancelUncompleted: true })
  getMyUser({ getState, setState }: StateContext<UserStateModel>) {
    return this.service.getMyUser().pipe(
      tap((user: IUserModel) => {
        const state = getState();
        setState({
          ...state,
          currentUser: user,
        });
      })
    );
  }

  @Action(PauseUserEmployments, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'userEmploymentPaused' })
  pauseUserEmployments(ctx: StateContext<UserStateModel>, { id }: PauseUserEmployments) {
    return this.service.pauseUserEmployments(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            users: removeItem<IUserTable>((user: IUserTable | undefined) => user?.id === id),
          })
        );
      })
    );
  }

  @Action(DeleteUserEmployments, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'userEmploymentDeleted' })
  deleteUserEmployments(ctx: StateContext<UserStateModel>, { id }: DeleteUserEmployments) {
    return this.service.deleteUserEmployments(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            users: removeItem<IUserTable>((user: IUserTable | undefined) => user?.id === id),
          })
        );
      })
    );
  }

  @Action(RestoreUser, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'userRestored' })
  restoreUser(ctx: StateContext<UserStateModel>, { id }: RestoreUser) {
    return this.service.restoreUser(id);
  }

  @Action(ResumeUserEmployment, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'userEmploymentResumed' })
  resumeUserEmployment(ctx: StateContext<UserStateModel>, { id }: ResumeUserEmployment) {
    return this.service.resumeUserEmployment(id);
  }

  @Action(RestoreUserEmployment, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'userEmploymentRestored' })
  restoreUserEmployment(ctx: StateContext<UserStateModel>, { id }: RestoreUserEmployment) {
    return this.service.restoreUserEmployment(id);
  }

  @Action(DeactivateUser, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'userDeactivated' })
  deactivateUser(ctx: StateContext<UserStateModel>, { id }: DeactivateUser) {
    return this.service.deactivateUser(id);
  }

  @Action(ReactivateUser, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'userReactivated' })
  reactivateUser(ctx: StateContext<UserStateModel>, { id }: ReactivateUser) {
    return this.service.reactivateUser(id);
  }

  @Action(SendResetPasswordEmail, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'resetPasswordEmailSent' })
  sendResetPasswordEmail(ctx: StateContext<UserStateModel>, { id }: ReactivateUser) {
    return this.service.sendResetPasswordEmail(id);
  }

  @Action(ResendConfirmationEmail, { cancelUncompleted: true })
  @OnSuccess({ url: AdminUsersRoutes.Users, message: 'confirmationEmailSent' })
  resendConfirmationEmail(ctx: StateContext<UserStateModel>, { id }: ReactivateUser) {
    return this.service.resendConfirmationEmail(id);
  }

  @Selector()
  static getUsersTotalPages(state: UserStateModel): number {
    return state.usersTotalPages;
  }

  @Selector()
  static getAllUsersAllTenantsTotalPages(state: UserStateModel): number {
    return state.allUsersAllTenantsTotalPages;
  }

  @Selector()
  static getUserOptions(state: UserStateModel): IUserOptions | undefined {
    return state.userOptions;
  }

  @Selector()
  static getAllUsers(state: UserStateModel): IUserTable[] {
    return state.users;
  }

  @Selector()
  static getAllUsersFromAllTenants(state: UserStateModel): IUserProductOwnerView[] {
    return state.allUsersAllTenants;
  }

  @Selector()
  static userOptionsLoaded(state: UserStateModel): boolean {
    return state.userOptionsLoaded;
  }

  @Selector()
  static getUserById(state: UserStateModel) {
    return state.currentUser;
  }

  @Selector()
  static showDeletedUsers(state: UserStateModel) {
    return state.showDeletedUsers;
  }

  @Selector()
  static showPausedUsers(state: UserStateModel) {
    return state.showPausedUsers;
  }

  @Selector()
  static showDeactivatedUsers(state: UserStateModel) {
    return state.showDeactivatedUsers;
  }
}

function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

async function dispatchProfilePicture(ctx: any, user: any) {
  if (user.profilePicture) {
    try {
      const base64String = await getBase64(user.profilePicture);
      ctx.dispatch(new PatchProfilePicture(base64String));
    } catch (error) {
      console.error('Error converting file to base64:', error);
    }
  } else {
    ctx.dispatch(new PatchProfilePicture(''));
  }
}
