/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IDueListPrintRequest } from '../models/due-list-print-request.model';

export class LoadDueListItems {
  static readonly type = '[Due List] Load Due List Items via Service';
  constructor() {}
}

export class LoadMyDueListItems {
  static readonly type = '[Due List] Load My Due List Items via Service';
  constructor() {}
}

export class LoadDueListWarnings {
  static readonly type = '[Due List] Load Due List Warnings via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllDueListWarnings {
  static readonly type = '[Due List] Load All Due List Warnings via Service';
  constructor() {}
}

export class LoadDueListDueCompetencies {
  static readonly type = '[Due List] Load Due List Due Competencies via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllDueListDueCompetencies {
  static readonly type = '[Due List] Load All Due List Due Competencies via Service';
  constructor() {}
}

export class LoadMyDueListWarnings {
  static readonly type = '[Due List] Load My Due List Warnings via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllMyDueListWarnings {
  static readonly type = '[Due List] Load All My Due List Warnings via Service';
  constructor() {}
}

export class PrintDueListWarnings {
  static readonly type = '[Due List] Print Due List Warnings';
  constructor(public request?: IDueListPrintRequest) {}
}
