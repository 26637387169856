import { Store } from '@ngxs/store';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { map, Observable } from 'rxjs';
import { ChangeCurrentSelectedDepartment } from 'src/app/tenant-selector/state/tenant-user-access.actions';
import {
  ALL_DEPS_TOKEN,
  TenantUserAccessState,
} from 'src/app/tenant-selector/state/tenant-user-access.state';
import { IDepartmentInfo } from 'src/app/shared/user-view/models/tenant-user-access.model';

@Component({
  selector: 'app-department-dropdown',
  templateUrl: './department-dropdown.component.html',
  styleUrls: ['./department-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentDropdownComponent {
  departments$: Observable<IDepartmentInfo[]> = this.store
    .select(TenantUserAccessState.getAllAvailableDepartments)
    .pipe(map((departments) => [...departments].sort((a, b) => a.name.localeCompare(b.name))));
  selectedDepartment$: Observable<IDepartmentInfo> = this.store.select(
    TenantUserAccessState.currentDepartment
  );

  allDepsIconPath = '/assets/icons/all-departments.svg';
  departmentPlaceHolderIconPath = '/assets/icons/department-placeholder.svg';

  constructor(private store: Store) {}
  readonly allDepsToken = ALL_DEPS_TOKEN;

  selectDepartment(change: MatSelectChange): void {
    this.store.dispatch(new ChangeCurrentSelectedDepartment(change.value, true));
  }

  trackBy = (index: number) => index;
}
