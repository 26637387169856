/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IPrivilegeTemplateModel } from '../model/privilege-template.model';

export class LoadPrivilegeTemplates {
  static readonly type = '[Privilege Template] Load Privilege Templates via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllPrivilegeTemplates {
  static readonly type = '[Privilege Template] Load All Privilege Templates via Service';
  constructor() {}
}

export class LoadPrivilegeTemplateById {
  static readonly type = '[Privilege Template] Load a single Privilege Template';
  constructor(public id: string) {}
}

export class CreatePrivilegeTemplate {
  static readonly type = '[Privilege Template] Create Privilege Template';
  constructor(public privilegeTemplate: IPrivilegeTemplateModel) {}
}

export class UpdatePrivilegeTemplate {
  static readonly type = '[Privilege Template] Update Privilege Template';
  constructor(public privilegeTemplate: IPrivilegeTemplateModel) {}
}

export class DeletePrivilegeTemplate {
  static readonly type = '[Privilege Template] Delete Privilege Template';
  constructor(public id: string) {}
}
