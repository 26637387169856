import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  isPopupShown = false;

  constructor(swUpdate: SwUpdate, dialog: MatDialog) {
    if (navigator.serviceWorker && navigator.serviceWorker.getRegistrations) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          if (registrations) {
            registrations.forEach((registration) => {
              registration.unregister();
            });
          } else {
            console.error('No service worker registrations found.');
          }
        })
        .catch((error) => {
          console.error('Error while getting service worker registrations:', error);
        });
    } else {
      console.error('Service worker or getRegistrations not supported in this browser.');
    }
    // swUpdate.versionUpdates
    //   .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
    //   .subscribe(() => {
    //     const mainNav = document.querySelector('app-main-nav');
    //     if (mainNav) {
    //       dialog.open(AppUpdateDialogComponent, {
    //         width: '30rem',
    //         disableClose: true,
    //       });
    //       this.isPopupShown = true;
    //     } else {
    //       // eslint-disable-next-line no-console
    //       console.warn(
    //         'Received update event from service worker but the app has not been initialized yet. The app will be updated now.'
    //       );
    //       document.location.reload();
    //     }
    //   });
  }
}
