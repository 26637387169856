import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TenantViewComponent } from './components/tenant-view/tenant-view.component';
import { TenantApiService } from './service/tenant-api.service';
import { MaterialModule } from '../modules/material.module';
import { TenantComponent } from './tenant.component';
import { TenantDetailComponent } from './components/tenant-detail/tenant-detail.component';

import { TenantRoutingModule } from './tenant-routing.module';
import { TenantResolver } from './tenant.resolver';
import { TenantService } from './service/tenant.service';
import { ContactsTableComponent } from './components/contacts/contacts-table.component';
import { ContactsModalComponent } from './components/contacts/contacts-modal.component';
import { ContactComponent } from './components/contacts/contact.component';
import { ProductOwnerState } from '../product-owner/state/product-owner.state';
import { ModuleState } from '../core/state/module.state';
import { MainTableModule } from '../shared/main-table-module/main-table.module';
import { IsAttachmentPipe } from '../shared/pipes/is-thumbnail.pipe';
import { UIElementsModule } from '../shared/ui-elements/ui-elements.module';
import { AppLoaderDirective } from '../shared/directives/app-loader.directive';
import { DialogContainerComponent } from '../shared/dialogs/dialog-container/dialog-container.component';
import { AddressFormComponent } from '../shared/components/address/address-form.component';
import { ActionInProgressPipe } from '../shared/directives/action-in-progress.pipe';
import { TenantLogComponent } from './components/tenant-log/tenant-log.component';
import { AttachmentsModule } from '../shared/attachments/attachments.module';
import { ThirdPartyIntegrationEditComponent } from './components/third-party-integration-edit/third-party-integration-edit.component';
import { ThirdPartyIntegrationListComponent } from './components/third-party-integration-list/third-party-integration-list.component';
import { ConfirmDialogDirective } from '../shared/dialogs/confirm-dialog/confirm-dialog.directive';

@NgModule({
  declarations: [
    ContactComponent,
    ContactsModalComponent,
    ContactsTableComponent,
    TenantComponent,
    TenantDetailComponent,
    TenantLogComponent,
    TenantViewComponent,
    ThirdPartyIntegrationListComponent,
    ThirdPartyIntegrationEditComponent,
  ],
  imports: [
    ActionInProgressPipe,
    AddressFormComponent,
    AttachmentsModule,
    AppLoaderDirective,
    NgxsModule.forFeature([ModuleState, ProductOwnerState]),
    CommonModule,
    ConfirmDialogDirective,
    DialogContainerComponent,
    IsAttachmentPipe,
    MainTableModule,
    MaterialModule,
    ReactiveFormsModule,
    TenantRoutingModule,
    TranslateModule,
    UIElementsModule,
  ],
  providers: [TenantApiService, TenantResolver, TenantService],
  exports: [ContactComponent],
})
export class TenantModule {}
