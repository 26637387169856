import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { Validators } from '@angular/forms';
import { IAttachment } from '../models/attachments.model';
import { AttachmentForm } from '../forms/uploader.form';
import { IdName } from '../../models/id-name';
import { AttachmentState } from '../state/attachments.state';
import { LoadAttachmentCategories } from '../state/attachments.actions';

@Component({
  selector: 'app-edit-attachment-dialog',
  templateUrl: './edit-attachment-dialog.component.html',
  styleUrls: ['./edit-attachment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAttachmentDialogComponent implements OnInit {
  attachmentCategories$: Observable<IdName[] | undefined> = this.store.select(
    AttachmentState.getAttachmentCategories
  );
  form: AttachmentForm;
  categories: IdName[];

  constructor(
    public dialogRef: MatDialogRef<EditAttachmentDialogComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: { attachment: IAttachment }
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadAttachmentCategories());
    this.form = new AttachmentForm({
      name: this.data.attachment.name,
      categories: this.data.attachment.categories,
      file: new File([], this.data.attachment.name),
    });
    this.form.categories.removeValidators([Validators.required]);
    this.form.categories.updateValueAndValidity();
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }
}
