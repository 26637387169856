/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IPostponeJobRequest } from '../models/postpone-job-request.model';
import { IMaintenanceJobRequest } from '../models/maintenance-job.model';
import { IPerformJobRequest } from '../models/perform-job-request.model';
import { IMaintenancePrintRequest } from '../models/print-jobs-request.model';

export class LoadMaintenanceJobs {
  static readonly type = '[Maintenance Job] Load Maintenance Jobs via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadSparePartsForPerform {
  static readonly type = '[Maintenance Job] Load Spare Parts For Perform via Service';
  constructor(public id: string) {}
}

export class LoadMaintenanceJobById {
  static readonly type = '[Maintenance Job] Load a single maintenance job';
  constructor(public id: string) {}
}

export class LoadMaintenanceJobsForIO {
  static readonly type = '[Maintenance Job] Load Maintenance Jobs for IO';
  constructor() {}
}

export class CreateMaintenanceJob {
  static readonly type = '[Maintenance Job] Create maintenance job';
  constructor(public maintenanceJob: IMaintenanceJobRequest) {}
}

export class UpdateMaintenanceJob {
  static readonly type = '[Maintenance Job] Update maintenance job';
  constructor(public maintenanceJob: IMaintenanceJobRequest) {}
}

export class PostponeMaintenanceJob {
  static readonly type = '[Maintenance Job] Postpone maintenance job';
  constructor(public request: IPostponeJobRequest) {}
}

export class PerformMaintenanceJob {
  static readonly type = '[Maintenance Job] Perform maintenance job';
  constructor(public request: IPerformJobRequest, public isDialog: boolean) {}
}

export class GetPerformMaintenanceJobDetails {
  static readonly type = '[Maintenance Job] Get perform maintenance job details';
  constructor(public performId: string) {}
}

export class LoadMaintenanceJobLog {
  static readonly type = '[Maintenance Job] Load a maintenance job log';
  constructor(public id: string) {}
}

export class DeleteMaintenanceJob {
  static readonly type = '[Maintenance Job] Delete maintenance job';
  constructor(public id: string) {}
}

export class RestoreMaintenanceJob {
  static readonly type = '[Maintenance Job] Restore maintenance job';
  constructor(public id: string) {}
}

export class PrintMaintenanceJobs {
  static readonly type = '[Maintenance Job] Print maintenance jobs';
  constructor(public request: IMaintenancePrintRequest, public filter: MainTableFilterModel) {}
}

export class LoadJobDraftPerform {
  static readonly type = '[Maintenance Job] Load Job Draft Perform';
  constructor(public id: string) {}
}
export class SaveJobDraftPerform {
  static readonly type = '[Maintenance Job] Save Job Draft Perform';
  constructor(public job: IPerformJobRequest, public isDialog: boolean) {}
}
