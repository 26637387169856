import { Store } from '@ngxs/store';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Animations } from '../../angular-animations/angular-animations';
import { UserAccessService } from '../../services/user-access.service';
import { MenuStateModel } from '../../state/menu-list.state';
import { ClearFilterDetails } from '../../state/main-table-filter.actions';

@Component({
  selector: 'app-menu-list-recursive',
  templateUrl: './menu-list-recursive.component.html',
  styleUrls: ['./menu-list-recursive.component.scss'],
  animations: [Animations.toggleContainer, Animations.rotatedState],
})
export class MenuListRecursiveComponent implements OnChanges {
  @Input() navItems: any[];
  @Input() currentTenantId: string;
  @Input() currentMenuState: MenuStateModel;
  @Input() isInAdmin: boolean;
  @Input() dueListWarningsExpiredSum: number;
  @Input() dueListWarningsExpiringSum: number;

  expandedItems: string[] = [];
  activeRoute: string;
  activeNavItem: any;

  constructor(
    private userAccessService: UserAccessService,
    private router: Router,
    private store: Store
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        [this.activeRoute] = event.urlAfterRedirects.split('?');
        this.checkActiveNavItem();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.navItems) {
      this.checkActiveNavItem();
    }
  }

  checkActiveNavItem(): void {
    this.activeNavItem = this.navItems.find((item) => this.hasActiveChildren(item.children));
  }

  hasActiveChildren(children: any[]): boolean {
    return children?.some((child) => this.activeRoute === child.route);
  }

  isVisible(role: string): boolean {
    return this.userAccessService.hasAccess(role);
  }

  toggleItem(name: string): void {
    if (this.isInAdmin) {
      this.store.dispatch(new ClearFilterDetails());
    } else if (this.isExpanded(name)) {
      this.expandedItems = this.expandedItems.filter((itemName: string) => itemName !== name);
    } else {
      this.expandedItems.push(name);
    }
    this.store.dispatch(new ClearFilterDetails());
  }

  trackBy = (index: number) => index;

  private isExpanded(name: string): boolean {
    return this.expandedItems.some((itemName: string) => itemName === name);
  }
}
