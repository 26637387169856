import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';
import { IAttachment } from '../models/attachments.model';

@Injectable({ providedIn: 'root' })
export class FormDataService {
  static mapToFormData(object: any): FormData {
    const formData = new FormData();
    if (object) {
      const formValues = Object.entries(object);
      formValues.forEach(([key, value]) => {
        if (FormDataService.isDate(value)) {
          this.appendDate(formData, key, value);
        } else if (value instanceof File) {
          formData.append(key, value, value.name);
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          this.appendObject(value as object, formData, key);
        } else if (FormDataService.isIAttachments(value)) {
          FormDataService.appendAttachments(value, formData, key);
        } else if (Array.isArray(value)) {
          this.appendArray(value, formData, key);
        } else if (value !== null && value !== undefined) {
          formData.append(key, `${value}`);
        }
      });
    }

    return formData;
  }

  private static appendDate(formData: FormData, key: string, value: Date | moment.Moment) {
    if (value) {
      formData.append(key, FormDataService.dateToFormDataString(value));
    }
  }

  private static appendObject(value: object, formData: FormData, upperLevelKey: string) {
    if (value) {
      const objEntries = Object.entries(value);
      objEntries.forEach(([objKey, objValue]) => {
        const currentLevelKey = `${upperLevelKey}.${objKey}`;
        if (FormDataService.isDate(objValue)) {
          this.appendDate(formData, currentLevelKey, objValue);
        } else if (objValue instanceof File) {
          formData.append(currentLevelKey, objValue, objValue.name);
        } else if (typeof objValue === 'object' && !Array.isArray(objValue)) {
          this.appendObject(objValue, formData, currentLevelKey);
        } else if (FormDataService.isIAttachments(objValue)) {
          FormDataService.appendAttachments(objValue, formData, currentLevelKey);
        } else if (Array.isArray(objValue)) {
          this.appendArray(objValue, formData, currentLevelKey);
        } else if (objValue) {
          formData.append(currentLevelKey, `${objValue}`);
        }
      });
    }
  }

  static dateToFormDataString(date: string | Date | Moment) {
    // eslint-disable-next-line no-magic-numbers
    return JSON.stringify(date).slice(1, -1);
  }

  private static isDate(value: any): value is Date | Moment {
    return value instanceof Date || moment.isMoment(value);
  }

  private static appendArray(value: any[], formData: FormData, upperLevelKey: string) {
    if (value) {
      value.forEach((arrayValue, index) => {
        const currentLevelKey = `${upperLevelKey}[${index}]`;
        if (FormDataService.isDate(arrayValue)) {
          this.appendDate(formData, currentLevelKey, arrayValue);
        } else if (arrayValue instanceof File) {
          formData.append(upperLevelKey, arrayValue, arrayValue.name);
        } else if (typeof arrayValue === 'object') {
          this.appendObject(arrayValue, formData, currentLevelKey);
        } else if (Array.isArray(arrayValue)) {
          this.appendArray(arrayValue, formData, currentLevelKey);
        } else if (arrayValue) {
          formData.append(currentLevelKey, `${arrayValue}`);
        }
      });
    }
  }

  private static isIAttachments(value: any): value is IAttachment[] {
    if (value) {
      if (Array.isArray(value) && value?.length) {
        if (value[0].isNew) {
          return value[0]?.file !== undefined;
        }
        return typeof value[0] !== 'string' && value[0]?.link !== undefined;
      }
    }
    return false;
  }

  static appendAttachments(attachments: IAttachment[], formData: FormData, upperLevelKey: string) {
    FormDataService.appendArray(attachments, formData, upperLevelKey);
    const upperLevelKeyWithoutLastSegment = upperLevelKey.replace(/\.[^.]*$/, '');
    attachments
      .filter((item: IAttachment) => item.isNew)
      .forEach((item: IAttachment) => {
        formData.append(
          `${
            upperLevelKeyWithoutLastSegment.includes('.')
              ? `${upperLevelKeyWithoutLastSegment}.`
              : ''
          }newAttachments`,
          item.file,
          item.name
        );
      });
  }
}
