<mat-card *ngIf="tenantLogs$ | async as logs">
  <h2>{{ 'log' | translate }}</h2>
  <div class="log">
    <div class="log-entry header">
      <div class="toggle"></div>
      <div class="date">
        {{ 'dateTime' | translate }}
      </div>
      <div class="action">
        {{ 'action' | translate }}
      </div>
      <div class="done-by vertical-center">
        {{ 'actionDoneBy' | translate | titlecase }}
      </div>
    </div>
    <ng-container *ngFor="let entry of logs | keyvalue; trackBy: trackBy">
      <div class="log-entry" [class.expanded]="entry.value.expanded">
        <div class="toggle">
          <mat-icon *ngIf="entry.value.expandable" (click)="entry.value.toggleRow()"
            >chevron_right</mat-icon
          >
        </div>
        <div class="date">
          {{ entry.value.date | date : 'yyyy-MM-dd HH:mm' }}
        </div>
        <div class="action">
          {{ entry.value.action | translate }}
        </div>
        <div class="done-by vertical-center">
          <mat-icon class="material-icons-outlined">person</mat-icon> {{ entry.value.by }}
        </div>
      </div>
      <div class="log-entry-info" *ngIf="entry.value.expanded">
        <div class="entry-detail" *ngFor="let info of entry.value.changes; trackBy: trackBy">
          {{ 'changed' | translate | titlecase }} {{ info.field }}
          <div *ngIf="info.oldValue" class="form-row">
            <div class="form-row-item">{{ 'from' | translate | titlecase }}:</div>
            <div class="form-row-item">{{ info.oldValue }}</div>
          </div>
          <div *ngIf="info.newValue" class="form-row">
            <div class="form-row-item">{{ 'to' | translate | titlecase }}:</div>
            <div class="form-row-item">{{ info.newValue }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</mat-card>
