export enum MainTableModuleNamesEnum {
  Alerts = 'alerts',
  Certificate = 'certificate',
  Certificates = 'certificates',
  CrewManagement = 'crew-management',
  CrewManagementUser = 'shift-and-manning',
  Competence = 'competence',
  Departments = 'department',
  Document = 'document',
  Documents = 'documents',
  Drills = 'drills',
  FormsLibrary = 'forms-library',
  NonConformance = 'non-conformance',
  NonConformances = 'non-conformances',
  Maintenance = 'maintenance',
  Profile = 'profile',
  RunTime = 'run-time',
  Tenant = 'tenant',
  UsersAndPax = 'users-and-pax',
  OrderAdmin = 'purchase',
  OrderUser = 'purchases',
  DueList = 'due-list',
  Checklist = 'tasks',
  Medicines = 'medicines',
  Medicine = 'medicine',
  Storage = 'storage',
  ProductOwnerUsers = 'po-users',
  RiskAssessment = 'risk-assessment',
  RiskAssessments = 'risk-assessments',
  PrivilegeTemplates = 'privilege-templates',
  QuantityTypes = 'quantity-types',
}
