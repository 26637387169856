import { Pipe, PipeTransform } from '@angular/core';
import { PlannedActivityUserTripStatus } from 'src/app/crew-management-user/enums/planned-activity-user-trip-status.enum';

@Pipe({
  name: 'tripUserStatus',
  pure: true,
  standalone: true,
})
export class TripUserStatusPipe implements PipeTransform {
  private plannedActivityUserTripStatusMapper = {
    [PlannedActivityUserTripStatus.NotConfirmed]: 'notConfirmed',
    [PlannedActivityUserTripStatus.ConfirmedOnBoard]: 'confirmedOnBoard',
    [PlannedActivityUserTripStatus.SignedOff]: 'signedOff',
  };

  transform(plannedActivityUserTripStatus: PlannedActivityUserTripStatus): string {
    return this.plannedActivityUserTripStatusMapper[plannedActivityUserTripStatus];
  }
}
