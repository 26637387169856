import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { IMaintenanceJobForTable } from 'src/app/maintenance-user/models/maintenance-job.model';
import { RestoreMaintenanceJob } from 'src/app/maintenance-user/state/maintenance-job.actions';

// TODO Illia: might be useful to use this type in other templates with element: any
type WithIndexSignature = {
  [key: string]: any;
};

@Component({
  selector: 'app-maintenance-template',
  templateUrl: './maintenance-template.component.html',
  styleUrls: ['./maintenance-template.component.scss'],
})
export class MaintenanceTemplateComponent implements OnInit {
  @Input() columnName: string;
  @Input() element: IMaintenanceJobForTable & WithIndexSignature;
  @Input() buttonFunctions: { [key: string]: () => void };
  expiryDate: Date;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (this.element.expiryType === 'Both' && this.element.dueDateOrRuntime) {
      this.expiryDate = new Date(
        Math.min(
          +new Date(this.element.dueDateOrRuntime.intervalExpiry),
          +new Date(this.element.dueDateOrRuntime.runtimeExpiry.dueDate)
        )
      );
    }
  }

  restore(): void {
    this.store.dispatch(new RestoreMaintenanceJob(this.element.jobId));
  }
}
