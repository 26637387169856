import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { IdName } from 'src/app/shared/models/id-name';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ApiResponse } from 'src/app/core/models/api-response';
import { environment } from '../../../environments/environment';

const headers = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root',
})
export class CertificateTypeService {
  constructor(private http: HttpClient) {}

  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/certificateTypes`;
  }

  loadCertificateTypes(currentFilter?: MainTableFilterModel): Observable<ApiResponse<IdName[]>> {
    let params = new HttpParams();

    if (currentFilter) {
      params = params.append('Page', currentFilter.pageIndex.toString());
      params = params.append('PageSize', currentFilter.pageSize.toString());
    }

    if (currentFilter?.searchTerm) {
      params = params.append('GlobalSearch', currentFilter.searchTerm);
    }

    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      params = params.append(`OrderBy[${currentFilter.orderBy}]`, currentFilter.ascDesc);
    }

    return this.http.get<ApiResponse<IdName[]>>(this.url, { params });
  }

  loadAllCertificateTypes(): Observable<IdName[]> {
    const url = `${this.url}/All`;
    return this.http.get<IdName[]>(url);
  }

  loadCertificateTypeById(id: string): Observable<IdName> {
    const url = `${this.url}/${id}`;
    return this.http.get<IdName>(url);
  }

  createCertificateType(certificateType: IdName): Observable<IdName> {
    return this.http.post<IdName>(this.url, certificateType, { headers });
  }

  updateCertificateType(certificateType: IdName): Observable<IdName> {
    const url = `${this.url}/${certificateType.id}`;
    return this.http.put<IdName>(url, certificateType, { headers });
  }

  deleteCertificateType(id: string): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }
}
