import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CargoHoldForm } from '../../../../../department/forms/vessel-cargo-hold.form';
import { DimensionUnit } from '../../../../enums/enums';
import { AbstractDepartmentTemplateComponent } from './abstract-department-template-component.directive';

@Component({
  selector: 'app-cargo-hold-template',
  templateUrl: './cargo-hold-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoHoldTemplateComponent extends AbstractDepartmentTemplateComponent<CargoHoldForm> {
  dimensionUnit = DimensionUnit;
}
