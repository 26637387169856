import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAccessService } from '../../core/services/user-access.service';

@Injectable()
export class HasAccessGuard implements CanActivate {
  constructor(private useraccessService: UserAccessService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const role = route.data.role as string;
    return this.useraccessService.hasAccess(role);
  }
}
