import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { IOption } from '../../models/option.model';

@Component({
  selector: 'app-shared-ui-radio',
  templateUrl: './ui-radio-group.component.html',
  styleUrls: ['./ui-radio-group.component.scss'],
})
export class UiRadioGroupComponent {
  @Input() label = '';
  @Input() control: UntypedFormControl;
  @Input() options: IOption[] = [];
  @Input() selected: any = '0';
  @Input() placeholder?: string;
  @Input() optionLabelTemplate?: TemplateRef<any>;
  @Input() layout: 'vertical' | 'horizontal' = 'horizontal';

  @Output() readonly changeHandler: EventEmitter<MatRadioChange> =
    new EventEmitter<MatRadioChange>();

  onChange(event: MatRadioChange): void {
    this.changeHandler.emit(event);
  }
}
