import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { JobPositionState } from 'src/app/competence/state/job-position.state';
import { PlannedActivityUserForm } from 'src/app/crew-management-user/forms/planned-activity.form';
import { IdName } from 'src/app/shared/models/id-name';

@Component({
  selector: 'app-planned-activity-user-template',
  templateUrl: './planned-activity-user-template.component.html',
  styleUrls: ['./planned-activity-user-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannedActivityUserTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  form: PlannedActivityUserForm;

  getJobPositionsForDepartment$: Observable<IdName[] | undefined> = this.store.select(
    JobPositionState.getJobPositionsForDepartment
  );

  constructor(private store: Store) {}
}
