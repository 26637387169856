<app-dialog-container
  [title]="data.title"
  [confirmButtonText]="data.confirmButtonText"
  [confirmButton]="data.confirmButton"
>
  <mat-dialog-content class="content-container">
    <p>{{ data.message | translate : { entityName: data.entityName } }}</p>
    <p>{{ 'areYouSure' | translate }}?</p>
  </mat-dialog-content>
</app-dialog-container>
