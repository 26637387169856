<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchDefault>
    {{ element[columnName] ?? '---' }}
  </ng-container>
  <ng-container *ngSwitchCase="'updateRunTimeCounterIcon__hidden'">
    <span class="vertical-center"><mat-icon>schedule</mat-icon></span>
  </ng-container>
  <ng-container *ngSwitchCase="'averageDailyRunTime'">
    {{ element.averageDailyRunTime ?? '---' }}
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill [name]="element[columnName]"></app-ui-pill>
  </ng-container>
  <ng-container *ngSwitchCase="'access'">
    <app-positions-cell [element]="element"></app-positions-cell>
  </ng-container>
</ng-container>
