import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pax-on-board-template',
  templateUrl: './pax-on-board-template.component.html',
})
export class PaxOnBoardTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  dateFormat = 'yyyy-MM-dd';
  dateTimeFormat = 'yyyy-MM-dd HH:mm';
}
