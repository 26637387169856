import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, last, map, startWith, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { TenantUserPrivilegesService } from 'src/app/tenant-selector/services/tenant-user-privileges.service';
import { environment } from 'src/environments/environment';
import { AttachmentApiService } from './service/attachments-api.service';
import { IAttachmentUploader } from './models/attachment-uploader.model';
import { UploaderDialogComponent } from './uploader-dialog/uploader-dialog.component';
import { IAttachment } from './models/attachments.model';
import { EditAttachmentDialogComponent } from './edit-attachment-dialog/edit-attachment-dialog.component';
import { AttachmentService } from './service/attachments.service';
import { AttachmentSearchForm } from './forms/uploader.form';

@Component({
  selector: 'app-ui-attachments',
  templateUrl: './ui-attachments.component.html',
  styleUrls: ['./ui-attachments.component.scss'],
})
export class UiAttachmentsComponent {
  @Input() disabled = false;
  @Input() viewMode: 'full' | 'embedded' = 'full';
  @Input() addButtonText = 'add';
  @Input() addButtonIcon: string;
  @Input() editMode = false;
  @Input() passiveMode = false;
  @Input() attachments: FormControl<IAttachment[]>;
  @Input() addPrivilege: string | string[];
  @Input() enableEditPrivilege: string | string[];
  @Input() savePrivilege: string | string[];

  @Output() readonly attachmentsChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly attachmentsAdded: EventEmitter<void> = new EventEmitter<void>();

  attachmentIcons: { [key: string]: string } = {
    html: '/assets/icons/attachments/html.svg',
    word: '/assets/icons/attachments/word.svg',
    pdf: '/assets/icons/attachments/pdf.svg',
    png: '/assets/icons/attachments/png.svg',
    jpeg: '/assets/icons/attachments/jpeg.svg',
    excel: '/assets/icons/attachments/xlsx.svg',
    xml: '/assets/icons/attachments/xlsx.svg',
    xlsx: '/assets/icons/attachments/xlsx.svg',
    xls: '/assets/icons/attachments/xlsx.svg',
    csv: '/assets/icons/attachments/xlsx.svg',
    other: '/assets/icons/attachments/other.svg',
  };

  downloadProgressMap: { [key: string]: { progress: number } } = {};
  filteredAttachments: IAttachment[] = [];
  searchForm = new AttachmentSearchForm();
  searchedText = this.searchForm.searchedText;
  environment = environment.apiUrl.slice(0, -3);

  constructor(
    public dialog: MatDialog,
    private api: AttachmentApiService,
    private cdr: ChangeDetectorRef,
    private tenantUserPrivileges: TenantUserPrivilegesService
  ) {
    this.searchedText.valueChanges
      .pipe(
        startWith<string>(''),
        debounceTime(250),
        map((term: string) => this.filter(term))
      )
      .subscribe((attachments) => {
        this.filteredAttachments = attachments;
      });
  }

  checkPermission(privilege: string | string[]): boolean {
    return this.tenantUserPrivileges.userHasPrivilege(privilege);
  }

  openInNewTab(attachmentUrl: string | undefined): void {
    const fullUrl = `${this.environment}${attachmentUrl}`;
    window.open(fullUrl, '_blank');
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(UploaderDialogComponent);
    dialogRef.afterClosed().subscribe((attachments: IAttachmentUploader[]) => {
      if (!attachments) {
        return;
      }

      const newAttachments: IAttachment[] = attachments.map((attachment) => {
        return {
          id: uuidv4(),
          isNew: true,
          name: attachment.name,
          file: attachment.file,
          categories: attachment.categories,
          type: AttachmentService.getAttachmentType(attachment.file.type),
          fileSize: AttachmentService.bytesToMegabytes(attachment.file.size),
        };
      });

      this.addAttachments(newAttachments);
    });
  }

  openEditDialog(attachment: IAttachment): void {
    const dialogRef = this.dialog.open(EditAttachmentDialogComponent, { data: { attachment } });
    dialogRef.afterClosed().subscribe((formValue: IAttachmentUploader) => {
      if (!formValue) {
        return;
      }
      const { attachments, index } = this.copyArrayAndFindElementById(
        this.attachments.value,
        attachment.id
      );
      attachments[index].categories = formValue.categories;
      attachments[index].name = formValue.name;
      this.attachments.setValue(attachments);
      this.attachments.markAsDirty();
      this.attachmentsChanged.emit();
      this.clearSearch();
    });
  }

  delete(attachment: IAttachment): void {
    const { attachments, index } = this.copyArrayAndFindElementById(
      this.attachments.value,
      attachment.id
    );
    attachments.splice(index, 1);

    this.attachments.setValue(attachments);
    this.attachments.markAsDirty();
    this.attachmentsChanged.emit();
    this.clearSearch();
  }

  enableEdit(): void {
    this.editMode = true;
  }

  getAttachments(): IAttachment[] {
    return this.attachments.value;
  }

  addAttachments(attachments: IAttachment[]): void {
    this.attachments.setValue([...this.attachments.value, ...attachments]);
    this.attachments.markAsDirty();
    this.filteredAttachments = this.filter(this.searchedText.value);
    this.attachmentsChanged.emit();
    if (!this.passiveMode) this.attachmentsAdded.emit();
    else this.clearSearch();
  }

  refreshAttachments(attachments: IAttachment[]): void {
    this.attachments.setValue([]);
    this.attachments.setValue([...attachments]);
    this.filteredAttachments = this.filter(this.searchedText.value);
    this.cdr.detectChanges();
    this.attachments.markAsDirty();
  }

  save(): void {
    this.editMode = false;
    this.attachmentsAdded.emit();
  }

  download(attachment: IAttachment): void {
    this.downloadProgressMap[attachment.id] = { progress: 0 };
    const fileSizeInBytes = AttachmentService.megabytesToBytes(attachment.fileSize);
    const progressObj = this.downloadProgressMap[attachment.id];
    this.api
      .downloadFileAsBlob(attachment.link as string)
      .pipe(
        tap((event: HttpProgressEvent) => {
          if (!(event instanceof HttpResponse)) {
            progressObj.progress = (event.loaded / fileSizeInBytes) * 100;
          }
        }),
        last(),
        map((res: any) => res.body)
      )
      .subscribe((file) => {
        saveAs(file, attachment.name);
        delete this.downloadProgressMap[attachment.id];
      });
  }

  private filter(searchedText: string): IAttachment[] {
    const iAttachments = this.attachments.value;
    if (!iAttachments) {
      return [];
    }
    if (!searchedText) {
      return iAttachments;
    }
    return iAttachments.filter(
      (attachment: IAttachment) =>
        searchedText === null || attachment.name.toLocaleLowerCase().includes(searchedText)
    );
  }

  private clearSearch() {
    this.searchedText.setValue(null);
  }

  private copyArrayAndFindElementById(
    originalAttachments: IAttachment[],
    idToFind: string
  ): { attachments: IAttachment[]; index: number } {
    const attachments: IAttachment[] = originalAttachments.map((attachment: IAttachment) => {
      return {
        id: attachment.id,
        name: attachment.name,
        link: attachment.link,
        file: attachment.file,
        categories: attachment.categories,
        type: attachment.type,
        isNew: attachment.isNew,
        fileSize: attachment.fileSize,
        created: attachment.created,
        createdBy: attachment.createdBy,
        modified: attachment.modified,
        modifiedBy: attachment.modifiedBy,
      };
    });
    const index = attachments.findIndex(({ id }) => id === idToFind);
    return { attachments, index };
  }
}
