import { Store } from '@ngxs/store';
/* eslint-disable no-magic-numbers */
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';
import { ApplyPaging, ResetFilterPageIndex } from 'src/app/core/state/main-table-filter.actions';
import { first, Subscription, tap } from 'rxjs';
import { MainTableFilterState } from 'src/app/core/state/main-table-filter.state';
import { MainTableFilterIdentification } from 'src/app/core/models/main-table-filter-identification.model';
import {
  defaultInitialPageSize,
  defaultPageIndex,
  defaultPageSizes,
} from '../../../core/table-config/table-paging-defaults';
import { IPageModel } from '../../models/page.model';
import { MainTableFilterModel } from '../../models/main-table-filter.model';

@Component({
  selector: 'app-main-table-paginator',
  templateUrl: './main-table-paginator.component.html',
  styleUrls: ['./main-table-paginator.component.scss'],
})
export class MainTablePaginatorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() totalPages: any;
  @Input() additional: any;
  @Input() showPageSizeSelector = true;
  @Input() initialPageSize = defaultInitialPageSize;
  @Input() moduleName: MainTableFilterModulesEnum;

  pageSizeOptions = defaultPageSizes;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  currentPageDetails: IPageModel;

  private subscription = new Subscription();
  private filterIdentification: MainTableFilterIdentification;

  constructor(private store: Store) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (
      this.filterIdentification &&
      _changes.additional?.currentValue !== _changes.additional?.previousValue
    ) {
      this.filterIdentification.additional = _changes.additional.currentValue;
      this.listenToPagingDetailsChanges();
    }
    if (this.currentPageDetails) {
      this.checkIfPreviousAndNextPageExist();
      this.checkAmountOfTotalPages();
    }
  }

  ngOnInit(): void {
    this.listenToPagingDetailsChanges();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetFilterPageIndex(this.filterIdentification));
    this.subscription.unsubscribe();
  }

  previousPage(): void {
    this.currentPageDetails.pageIndex--;
    this.checkIfPreviousAndNextPageExist();
    this.store.dispatch(
      new ApplyPaging(
        this.moduleName,
        this.currentPageDetails.pageIndex,
        this.currentPageDetails.pageSize,
        this.additional
      )
    );
  }

  nextPage(): void {
    this.currentPageDetails.pageIndex++;
    this.checkIfPreviousAndNextPageExist();
    this.store.dispatch(
      new ApplyPaging(
        this.moduleName,
        this.currentPageDetails.pageIndex,
        this.currentPageDetails.pageSize,
        this.additional
      )
    );
  }

  pageSizeChangeEvent(event: MatSelectChange): void {
    this.pageSizeChange(event.value);
  }

  private pageSizeChange(pageSize: number): void {
    this.currentPageDetails.pageSize = pageSize;
    this.currentPageDetails.pageIndex = 0;
    this.checkIfPreviousAndNextPageExist();
    this.checkAmountOfTotalPages();
    this.store.dispatch(
      new ApplyPaging(
        this.moduleName,
        this.currentPageDetails.pageIndex,
        this.currentPageDetails.pageSize,
        this.additional
      )
    );
  }

  private listenToPagingDetailsChanges(): void {
    this.filterIdentification = new MainTableFilterIdentification(this.moduleName, this.additional);
    this.subscription.add(
      this.store
        .select(MainTableFilterState.getPagingDetails(this.filterIdentification))
        .pipe(
          tap((pagingDetails: MainTableFilterModel | undefined) => {
            // sending the initial request with the default page index and page size for the module
            this.currentPageDetails = new IPageModel(
              defaultPageIndex,
              this.store.selectSnapshot(MainTableFilterState.getModulePageSize(this.moduleName)) ||
                pagingDetails?.pageSize ||
                this.initialPageSize
            );
            this.checkIfPreviousAndNextPageExist();
            this.checkAmountOfTotalPages();
          }),
          first(),
          tap(() => {
            this.store.dispatch(
              new ApplyPaging(
                this.moduleName,
                this.currentPageDetails.pageIndex || defaultPageIndex,
                this.currentPageDetails.pageSize,
                this.additional
              )
            );
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.store
        .select(MainTableFilterState.getModulePageSize(this.moduleName))
        .pipe(
          tap((pageSize) => {
            if (pageSize) {
              this.currentPageDetails.pageSize = pageSize;
            }
          })
        )
        .subscribe()
    );
  }

  private checkIfPreviousAndNextPageExist(): void {
    this.previousPageExist();
    this.nextPageExist();
  }

  private checkAmountOfTotalPages(): void {
    if (this.totalPages === 1 && this.currentPageDetails.pageIndex !== 0) {
      this.currentPageDetails.pageIndex = 0;
      this.checkIfPreviousAndNextPageExist();
    }
    if (this.currentPageDetails.pageIndex + 1 > this.totalPages) {
      this.currentPageDetails.pageIndex = 0;
      this.checkIfPreviousAndNextPageExist();
    }
  }

  private previousPageExist(): void {
    this.hasPreviousPage = this.currentPageDetails.pageIndex > 0;
  }

  private nextPageExist(): void {
    this.hasNextPage = this.totalPages - 1 > this.currentPageDetails.pageIndex;
  }
}
