import { ProductAction } from 'src/app/storage-user/enums/product-action.enum';

export function mapProductActionToString(productAction: ProductAction): string {
  const mapper = {
    [ProductAction.Create]: 'initialStock',
    [ProductAction.Add]: 'add',
    [ProductAction.Adjust]: 'adjust',
    [ProductAction.Use]: 'use',
    [ProductAction.Edit]: 'edit',
    [ProductAction.Delete]: 'delete',
  };
  return mapper[productAction];
}
