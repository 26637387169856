import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitForLastChildren',
  pure: true,
  standalone: true,
})
export class SplitPipe implements PipeTransform {
  transform(input: string, sep: string): string {
    const lengthOfInputElements = input?.split(sep).length;
    return input?.split(sep)[lengthOfInputElements - 1];
  }
}
