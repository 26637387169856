import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DepartmentTableNamesEnum } from '../../../../enums/department-table-names.enum';

@Component({
  selector: 'app-departments-templates',
  templateUrl: './departments-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentsTemplatesComponent {
  @Input() specificTemplateName: string;
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: () => void };
  @Input() hideButtons: boolean;

  departmentTableNamesEnum = DepartmentTableNamesEnum;
}
