import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILanguage } from '../../models/language.model';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  languageValue: string;

  languages: ILanguage[] = [
    { label: 'English', value: 'en', iconSource: 'assets/images/languageIcons/united-states.png' },
    { label: 'Norwegian', value: 'no', iconSource: 'assets/images/languageIcons/norway.png' },
    { label: 'Svenska', value: 'se', iconSource: 'assets/images/languageIcons/sweden.png' },
    { label: 'Español', value: 'es', iconSource: 'assets/images/languageIcons/spain.png' },
    { label: 'Ísland', value: 'is', iconSource: 'assets/images/languageIcons/iceland.png' },
  ];

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    const localStorageLanguage = localStorage.getItem('language');
    if (localStorageLanguage) {
      this.languageValue = localStorageLanguage;
    } else {
      this.languageValue = 'en';
    }
  }

  setLanguage = (language: string) => {
    this.translate.use(language);
    this.languageValue = language;
    if (localStorage.getItem('language')) {
      // Remove the existing key
      localStorage.removeItem('language');
    }

    // Set the new value for the key
    localStorage.setItem('language', language);
    // TODO Call a method in the store to Store chosen language as the standard language used
  };

  trackByValue(index: number, item: ILanguage) {
    return item.value;
  }
}
