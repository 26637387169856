import { UntypedFormControl } from '@angular/forms';

export function noWhitespaceValidator(control: UntypedFormControl) {
  if (!control.value) {
    return null;
  }

  const isValid = control.value.trim().length === control.value.length;
  return isValid ? null : { whitespace: true };
}
