import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProfileTableNamesEnum } from '../../../../enums/profile-table-names.enum';

@Component({
  selector: 'app-profile-templates',
  templateUrl: './profile-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileTemplatesComponent {
  @Input() specificTemplateName: string;
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: () => void };
  @Input() hideButtons: boolean;
  @Input() urlForRouting: string;

  tableNamesEnum = ProfileTableNamesEnum;
}
