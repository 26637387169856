import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { getDepartmentId, getTenantId } from 'src/app/services/store-snapshot.service';
import { environment } from 'src/environments/environment';
import { IdName } from 'src/app/shared/models/id-name';
import { IShiftRequest, IShiftResponse, IShiftWithDepartmentResponse } from '../models/shift.model';

@Injectable({
  providedIn: 'root',
})
export class ShiftApiService {
  headers = { 'content-type': 'application/json' };

  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/shifts`;
  }

  constructor(private http: HttpClient) {}

  getShifts(currentFilter?: MainTableFilterModel): Observable<ApiResponse<IShiftResponse[]>> {
    let url = `${this.url}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IShiftResponse[]>>(url);
  }

  getAllShifts(): Observable<IdName[]> {
    const url = `${this.url}/List`;
    return this.http.get<IdName[]>(url);
  }

  getAllShiftsForDepartment(departmentId?: string): Observable<IdName[]> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/${
      departmentId || getDepartmentId()
    }/departmentScope/shifts`;
    return this.http.get<IdName[]>(url);
  }

  getAllShiftsWithDepartment(
    onlyAvailableShifts: boolean
  ): Observable<IShiftWithDepartmentResponse[]> {
    const url = `${this.url}/List/withDepartment?onlyAvailableShifts=${onlyAvailableShifts}`;
    return this.http.get<IShiftWithDepartmentResponse[]>(url);
  }

  getShiftById(shiftId: string): Observable<IShiftResponse> {
    const url = `${this.url}/${shiftId}`;
    return this.http.get<IShiftResponse>(url);
  }

  createShift(shift: IShiftRequest): Observable<IShiftResponse> {
    return this.http.post<IShiftResponse>(this.url, shift, { headers: this.headers });
  }

  updateShift(shift: IShiftRequest): Observable<IShiftResponse> {
    const url = `${this.url}/${shift.id}`;
    return this.http.put<IShiftResponse>(url, shift, { headers: this.headers });
  }

  deleteShift(id: string) {
    const url = `${this.url}/${id}`;
    return this.http.delete<IShiftResponse>(url);
  }
}
