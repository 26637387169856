import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ITenant, ITenantLog, ITenantUpdate } from '../models/tenant.model';
import { environment } from '../../../environments/environment';
import { TenantService } from './tenant.service';
import { WebHookApiRequest } from '../models/webhook.model';

const baseUrl = `${environment.apiUrl}/api/Tenants`;
const eventUrl = `${environment.apiUrl}/api`;
@Injectable({
  providedIn: 'root',
})
export class TenantApiService {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) {}

  getAllTenants(): Observable<ITenant[]> {
    return this.http.get<ITenant[]>(baseUrl);
  }

  getPagedTenants(currentFilter?: MainTableFilterModel): Observable<ApiResponse<ITenant[]>> {
    let url = `${baseUrl}/Paged`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<ITenant[]>>(url);
  }

  getTenantById(tenantId: string): Observable<ITenant> {
    const url = `${baseUrl}/${tenantId}`;
    return this.http.get<ITenant>(url, this.httpOptions);
  }

  getTenantLog(tenantId: string) {
    const url = `${baseUrl}/${tenantId}/log`;
    return this.http.get<ITenantLog>(url);
  }

  createTenant(tenant: ITenant): Observable<ITenant> {
    const formData = TenantService.convertToFormData(tenant);
    return this.http.post<ITenant>(baseUrl, formData);
  }

  updateTenant(tenant: ITenantUpdate): Observable<ITenant> {
    const formData = TenantService.convertUpdateToFormData(tenant);
    const url = `${baseUrl}/${tenant.id}`;
    return this.http.put<ITenantUpdate>(url, formData);
  }

  deleteTenant(id: string): Observable<ITenant> {
    const url = `${baseUrl}/${id}`;
    return this.http.delete<ITenant>(url);
  }

  deactivateTenant(id: string): Observable<string> {
    const url = `${baseUrl}/${id}/deactivate`;
    return this.http.put<string>(url, { id });
  }

  getAvailableEvents(tenantId: string): Observable<string[]> {
    const url = `${eventUrl}/${tenantId}/webhooks/getAvailableEvents`;
    return this.http.get<string[]>(url);
  }

  getAllWebhooksPerTenant(tenantId: string): Observable<WebHookApiRequest> {
    const url = `${eventUrl}/${tenantId}/webhooks/subscribe`;
    return this.http.get<WebHookApiRequest>(url);
  }

  updateAllWebhooksPerTenant(
    updatedWebhooks: WebHookApiRequest,
    tenantId: string
  ): Observable<any> {
    const url = `${eventUrl}/${tenantId}/webhooks/subscribe`;
    return this.http.post<any>(url, updatedWebhooks);
  }
}
