import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateArray',
  pure: true,
  standalone: true,
})
export class TranslateArrayPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(items: any[], namePrefix: string, separator = ', '): any {
    return items
      .map((item: string) => this.translateService.instant(`${namePrefix}_${item}`))
      .join(separator);
  }
}
