/* eslint-disable @angular-eslint/use-component-view-encapsulation */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProcurementArea } from 'src/app/order-user/enums/procurement-area.enum';

@Component({
  selector: 'app-order-user-template',
  templateUrl: './order-user-template.component.html',
  styleUrls: ['./order-user-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderUserTemplateComponent implements OnInit {
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: () => void };

  ProcurementArea = ProcurementArea;
  totalReceivedQuantity: number;

  constructor() {}

  ngOnInit(): void {
    if (this.columnName === 'amountReceived') {
      this.totalReceivedQuantity =
        (this.element.receivedQuantity ?? 0) + (this.element.addedReceivedQuantity ?? 0);
    }
  }
}
