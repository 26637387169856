import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { UserCertificateRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { redirectAndBypassUnsavedDataCheck } from 'src/app/shared/functions/redirect-and-bypass-unsaved-data-check';
import { IdName } from 'src/app/shared/models/id-name';
import {
  IApiResponseGroupCertificates,
  ICertificate,
  ICertificateGroup,
  ICertificateGroupData,
} from '../models/certificate.model';
import { CertificateApiService } from '../service/certificate-api.service';
import {
  CreateCertificate,
  DeleteCertificate,
  LoadCertificateGroups,
  LoadCertificatesByMenu,
  LoadCertificatesForIO,
  LoadSpecificCertificate,
  RenewCertificate,
  RestoreCertificate,
  UpdateCertificate,
} from './certificate.actions';

export interface CertificateStateModel {
  certificatesStructuresLoaded: boolean;
  certificates: ICertificate[];
  certificatesTotalPages: number;
  groupedCertificates: ICertificateGroupData[];
  certificatesGrouped: ICertificateGroup[];
  certificatesGroupedTotalPages: number;
  chosenCertificate: ICertificate | undefined;
  cetificatesForIO: IdName[];
}

const CERTIFICATE_STATE_TOKEN = new StateToken<CertificateStateModel>('certificate');

@State<CertificateStateModel>({
  name: CERTIFICATE_STATE_TOKEN,
  defaults: {
    certificatesStructuresLoaded: false,
    certificates: [],
    certificatesTotalPages: 0,
    groupedCertificates: [],
    certificatesGrouped: [],
    certificatesGroupedTotalPages: 0,
    chosenCertificate: undefined,
    cetificatesForIO: [],
  },
})
@Injectable()
export class CertificateState {
  constructor(private service: CertificateApiService) {}

  // TODO Illia: unused, remove soon
  // @Action(LoadCertificates, { cancelUncompleted: true })
  // loadCertificates(ctx: StateContext<CertificateStateModel>, { filter }: LoadCertificates) {
  //   return this.service.getAllCertificates(filter).pipe(
  //     tap((response: ApiResponse<ICertificate[]>) => {
  //       ctx.setState(
  //         patch({
  //           certificates: response.data,
  //         })
  //       );
  //       ctx.dispatch(new LoadCertificateGroups());
  //     })
  //   );
  // }

  @Action(LoadCertificatesByMenu, { cancelUncompleted: true })
  loadCertificatesByMenu(
    ctx: StateContext<CertificateStateModel>,
    { filter }: LoadCertificatesByMenu
  ) {
    return this.service.getCertificatesByMenuWithFiltering(filter).pipe(
      tap((response: IApiResponseGroupCertificates) => {
        if (response.groupData) {
          ctx.setState(
            patch({
              certificates: response.data,
              certificatesTotalPages: response.totalPages,
              groupedCertificates: response.groupData,
            })
          );
        }
      })
    );
  }

  @Action(LoadCertificateGroups, { cancelUncompleted: true })
  loadCertificateGroups(
    { getState, setState }: StateContext<CertificateStateModel>,
    { filter }: LoadCertificateGroups
  ) {
    return this.service.getAllCertificatesGrouped(filter).pipe(
      tap((response: ApiResponse<ICertificateGroup[]>) => {
        const state = getState();
        setState({
          ...state,
          certificatesGrouped: response.data,
          certificatesGroupedTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadSpecificCertificate, { cancelUncompleted: true })
  loadSpecificCertificate(
    { getState, setState }: StateContext<CertificateStateModel>,
    { certificateId }: LoadSpecificCertificate
  ) {
    return this.service.getSpecificCertificate(certificateId).pipe(
      tap((response: ICertificate) => {
        const state = getState();
        setState({
          ...state,
          chosenCertificate: response,
        });
      })
    );
  }

  @Action(LoadCertificatesForIO, { cancelUncompleted: true })
  loadCertificatesForIO({ getState, setState }: StateContext<CertificateStateModel>) {
    return this.service.loadCertificatesForIO().pipe(
      tap((response: IdName[]) => {
        const state = getState();
        setState({
          ...state,
          cetificatesForIO: response,
        });
      })
    );
  }

  @Action(CreateCertificate, { cancelUncompleted: true })
  @OnSuccess({ message: 'certificateCreatedSuccessfully' })
  createCertificate(ctx: StateContext<CertificateStateModel>, { certificate }: CreateCertificate) {
    return this.service.createCertificate(certificate).pipe(
      tap((response: { id: string }) => {
        // const newCertificate: ICertificate = certificate;
        // newCertificate.id = response.id;
        // // eslint-disable-next-line prefer-destructuring
        // newCertificate.nextRevisionDate = certificate.revisionDates[0];
        // ctx.setState(
        //   patch({
        //     certificates: append([newCertificate]),
        //   })
        // );
        // ctx.dispatch([new LoadCertificates(), new LoadCertificateGroups()]); // TODO if we want to put it manually we need to have menuId inside groupedCerts parent item, not only menuName like now
        redirectAndBypassUnsavedDataCheck(
          `${UserCertificateRoutes.Overview}/${certificate.menuId}`
        );
      })
    );
  }

  @Action(UpdateCertificate, { cancelUncompleted: true })
  @OnSuccess({ message: 'certificateUpdatedSuccessfully' })
  updateCertificate(ctx: StateContext<CertificateStateModel>, { certificate }: UpdateCertificate) {
    return this.service.updateCertificate(certificate).pipe(
      tap(() => {
        // eslint-disable-next-line prefer-destructuring
        // certificate.nextRevisionDate = certificate.revisionDates[0];
        // ctx.setState(
        //   patch({
        //     certificates: updateItem<ICertificate>(
        //       (cert: ICertificate | undefined) => cert?.id === certificate.id,
        //       certificate
        //     ),
        //   })
        // );
        // ctx.dispatch([new LoadCertificates(), new LoadCertificateGroups()]); // TODO if we want to put it manually we need to have menuId inside groupedCerts parent item, not only menuName like now
        redirectAndBypassUnsavedDataCheck(
          `${UserCertificateRoutes.Overview}/${certificate.menuId}`
        );
      })
    );
  }

  @Action(DeleteCertificate, { cancelUncompleted: true })
  @OnSuccess({ url: UserCertificateRoutes.Main, message: 'certificateDeletedSuccessfully' })
  deleteCertificate(
    ctx: StateContext<CertificateStateModel>,
    { certificateId }: DeleteCertificate
  ) {
    return this.service.deleteCertificate(certificateId).pipe(
      tap(() => {
        // ctx.dispatch([new LoadCertificates(), new LoadCertificateGroups()]); // TODO if we want to put it manually we need to have menuId inside groupedCerts parent item, not only menuName like now
        // ctx.setState(
        //   patch({
        //     certificates: removeItem<ICertificate>(
        //       (cert: ICertificate | undefined) => cert?.id === certificateId
        //     ),
        //   })
        // );
        // ctx.dispatch([new LoadCertificateGroups()]); // TODO if we want to put it manually we need to have menuId inside groupedCerts parent item, not only menuName like now
      })
    );
  }

  @Action(RenewCertificate, { cancelUncompleted: true })
  renewCertificate(ctx: StateContext<CertificateStateModel>, { certificate }: RenewCertificate) {
    return this.service.renewCertificate(certificate).pipe(
      tap(() => {
        // ctx.dispatch([new LoadCertificates(), new LoadCertificateGroups()]); // TODO if we want to put it manually we need to have menuId inside groupedCerts parent item, not only menuName like now
        redirectAndBypassUnsavedDataCheck(
          `${UserCertificateRoutes.Overview}/${certificate.menuId}`
        );
        // const state = ctx.getState();
        // const foundCert: ICertificate | undefined = JSON.parse(
        //   JSON.stringify(
        //     state.certificates.find((cert: ICertificate) => cert.id === certificate.id)
        //   )
        // );
        // if (foundCert) {
        //   foundCert.expirationDate = formatISO(new Date(certificate.expirationDate), {
        //     representation: 'date',
        //   });

        //   ctx.setState(
        //     patch({
        //       certificates: updateItem<ICertificate>(
        //         (cert: ICertificate | undefined) => cert?.id === foundCert.id,
        //         foundCert
        //       ),
        //     })
        //   );
        // }
      })
    );
  }

  @Action(RestoreCertificate, { cancelUncompleted: true })
  @OnSuccess({ message: 'certificateRestored', url: UserCertificateRoutes.MainActive })
  restoreCertificate(ctx: StateContext<CertificateStateModel>, { id }: RestoreCertificate) {
    return this.service.restoreCertificate(id);
  }

  @Selector()
  static getCertificatesByMenu(state: CertificateStateModel) {
    return state.groupedCertificates;
  }

  @Selector()
  static getTotalPagesForCertificates(state: CertificateStateModel) {
    return state.certificatesTotalPages;
  }

  @Selector()
  static getAllCertificateGroupedPages(state: CertificateStateModel) {
    return state.certificatesGroupedTotalPages;
  }

  @Selector()
  static getAllCertificates(state: CertificateStateModel) {
    return state.certificates;
  }

  @Selector()
  static getChosenCertificate(state: CertificateStateModel) {
    return state.chosenCertificate;
  }

  @Selector()
  static getAllCertificateGrouped(state: CertificateStateModel) {
    return state.certificatesGrouped;
  }

  @Selector()
  static getCertificateById(state: CertificateStateModel) {
    return (index: any) => {
      return state.certificates[index];
    };
  }

  @Selector()
  static getCertificatesForIO(state: CertificateStateModel) {
    return state.cetificatesForIO;
  }
}
