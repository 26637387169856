<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'type'">
    {{ (element['category'] === 'Work' ? 'Task' : element['category']) | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'expires'">
    {{ element[columnName] | date: 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'daysUntilExpired'">
    {{ element['expires'] | daysBeforeExpired }}
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <div
      [ngStyle]="{
        'background-color': element?.status === 'Expired' ? 'red' : 'gold'
      }"
      class="status"
    ></div>
  </ng-container>
  <ng-container *ngSwitchCase="'actions'">
    <ng-container *ngIf="element['category'] === 'Work' || element['category'] === 'Drill'"
      ><button
        appStopClickPropagation
        mat-button
        [title]="'perform' | translate"
        (click)="dueListService.navigateToItem(performRouterLink, element.departmentId)"
      >
        <mat-icon>done</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="element['category'] === 'MaintenanceJob'"
      ><button
        mat-button
        appStopClickPropagation
        [title]="'perform' | translate"
        (click)="
          dueListService.navigateToItem(performMaintenanceJobRouterLink, element.departmentId)
        "
      >
        <mat-icon>done</mat-icon>
      </button>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'placement'">
    <ng-container *ngIf="element['location'] | isSingleLevelPlacement; else multipleLevelPlacement">
      {{ element['location'] }}
    </ng-container>
    <ng-template #multipleLevelPlacement
      ><span [matTooltip]="element['location']"
        >{{ element['location'] | splitForLastChildren: '/' }}
      </span>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="element.departmentId">
    <ng-container *ngSwitchCase="'department'">
      {{ element.departmentName }}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
