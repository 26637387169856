<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'status'">
    <!-- <div
      [ngStyle]="{
        'background-color': getCertStatusColor(element[columnName])
      }"
      class="status"
    ></div> -->
    <!-- TODO remove if ui-pills works with new back-end STATUS property -->
    <app-ui-pill name="{{ element.status ? (element.status | lowercase) : 'ok' }}"></app-ui-pill>
  </ng-container>
  <ng-container *ngSwitchCase="'action'">
    <mat-icon>recycling</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'type'">
    {{ element.certificateType | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'expand'">
    <span [@rotatedState]="!expanded" (click)="toggle()" *ngIf="element.count > 0"
      ><mat-icon>expand_more</mat-icon>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'expiryDate'">
    <ng-container *ngIf="element.expirationDate !== null; else noExpirationDate">
      {{ element.expirationDate | date : 'yyyy-MM-dd' }}
    </ng-container>
    <ng-template #noExpirationDate> {{ 'notProvided' | translate }} </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'nextRevisionDate'">
    <ng-container
      *ngIf="
        element.nextRevisionDate !== null && element.nextRevisionDate !== undefined;
        else noExpiryDate
      "
    >
      {{ element.nextRevisionDate | date : 'yyyy-MM-dd' }}
    </ng-container>
    <ng-template #noExpiryDate>
      {{ 'notProvided' | translate }}
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'hasAttachments'">
    <mat-icon *ngIf="element.hasAttachments" [attr.aria-label]="'hasAttachments' | translate">
      attach_file
    </mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'access'">
    <app-positions-cell [element]="element"></app-positions-cell>
  </ng-container>
  <ng-container *ngSwitchCase="'renew'">
    <ng-container *ngIf="element.expirationDate || element.nextRevisionDate">
      <app-ui-button
        buttonType="icon-button"
        class="renew-button"
        icon="recycling"
        [routerLink]="routerLink + element.id"
      ></app-ui-button>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'restore'">
    <app-ui-button
      appConfirmDialog
      appStopClickPropagation
      confirmTitle="restore"
      confirmMessage="youAreRestoring"
      [confirmEntityName]="element.name"
      (confirmed)="restore()"
      buttonType="secondary"
      text="{{ 'restore' | translate }}"
      privilege="CERTIFICATE_RESTORE"
    >
    </app-ui-button>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
