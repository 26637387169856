/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IUserRequest } from '../models/user.model';

export class LoadUsers {
  static readonly type = '[User] Load Users';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllUsersAllTenants {
  static readonly type = '[User] Load All Users';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadUserOptions {
  static readonly type = '[User] Load User Options';
}

export class CreateUser {
  static readonly type = '[User] Create User';
  constructor(public user: IUserRequest) {}
}

export class UpdateUser {
  static readonly type = '[User] Update User';
  constructor(public user: IUserRequest) {}
}

export class UpdateMyUser {
  static readonly type = '[User] Update My User';
  constructor(public user: IUserRequest) {}
}

export class GetMyUser {
  static readonly type = '[User] Get My User';
  constructor() {}
}

export class GetUserById {
  static readonly type = '[User] Get User By Id';
  constructor(public id: string) {}
}

export class PauseUserEmployments {
  static readonly type = '[User] Pause User Employments';
  constructor(public id: string) {}
}

export class DeleteUserEmployments {
  static readonly type = '[User] Delete User Employments';
  constructor(public id: string) {}
}

export class RestoreUser {
  static readonly type = '[User] Restore User';
  constructor(public id: string) {}
}

export class ResumeUserEmployment {
  static readonly type = '[User] Resume User Employment';
  constructor(public id: string) {}
}

export class RestoreUserEmployment {
  static readonly type = '[User] Restore User Employment';
  constructor(public id: string) {}
}

export class DeactivateUser {
  static readonly type = '[User] Deactivate User';
  constructor(public id: string) {}
}

export class ReactivateUser {
  static readonly type = '[User] Reactivate User';
  constructor(public id: string) {}
}

export class ResendConfirmationEmail {
  static readonly type = '[User] Resend confirmation email to User';
  constructor(public id: string) {}
}

export class SendResetPasswordEmail {
  static readonly type = '[User] Send reset password email to User';
  constructor(public id: string) {}
}
