import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { IdName } from 'src/app/shared/models/id-name';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { environment } from 'src/environments/environment';
import { IProductOwner } from '../models/product-owner.model';

const headers = { 'content-type': 'application/json' };
const baseUrl = `${environment.apiUrl}/api/ProductOwner`;

@Injectable({
  providedIn: 'root',
})
export class ProductOwnerService {
  constructor(private http: HttpClient) {}

  getProductOwners(currentFilter?: MainTableFilterModel): Observable<ApiResponse<IProductOwner[]>> {
    let url = `${baseUrl}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IProductOwner[]>>(url);
  }

  getAllProductOwners(): Observable<IdName[]> {
    const url = `${baseUrl}/List`;
    return this.http.get<IdName[]>(url);
  }

  createProductOwner(productOwner: IProductOwner): Observable<IProductOwner> {
    return this.http.post<IProductOwner>(baseUrl, productOwner, { headers });
  }

  updateProductOwner(productOwner: IProductOwner): Observable<IProductOwner> {
    const url = `${baseUrl}/${productOwner.id}`;
    return this.http.put<IProductOwner>(url, productOwner, { headers });
  }

  deleteProductOwner(id: string) {
    const url = `${baseUrl}/${id}`;
    return this.http.delete<IProductOwner>(url);
  }
}
