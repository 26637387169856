/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IConfirmLeave } from '../../models/confirm-leave.model';
import { IConfirmOnBoard } from '../../models/confirm-on-board.model';
import { IEditMultipleCurrentPersonnel as IEditMultipleOnBoardPersonnel } from '../../models/edit-multiple-current-personnel.model';
import { IConfirmOnBoardMultiplePersonnel } from '../../models/confirm-on-board-multiple-personnel.model';
import { IConfirmLeaveMultipleOnBoardPersonnel } from '../../models/confirm-leave-multiple-on-board-personnel.model';

export class LoadCurrentPersonnel {
  static readonly type = '[CurrentPersonnel] Load Current Personnel via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadMissingConfirmations {
  static readonly type =
    '[CurrentPersonnel] Load Current with Missing Confirmation By Id via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class ConfirmOnBoard {
  static readonly type = '[CurrentPersonnel] Confirm Personnel On Board';
  constructor(public request: IConfirmOnBoard) {}
}

export class ConfirmOnBoardMultipleUsers {
  static readonly type = '[CurrentPersonnel] Confirm Multiple Personnel On Board';
  constructor(public request: IConfirmOnBoardMultiplePersonnel) {}
}

export class ConfirmLeave {
  static readonly type = '[CurrentPersonnel] Confirm Personnel Leave';
  constructor(public request: IConfirmLeave) {}
}

export class ConfirmLeaveMultipleUsers {
  static readonly type = '[CurrentPersonnel] Confirm Multiple Personnel Leave';
  constructor(public request: IConfirmLeaveMultipleOnBoardPersonnel) {}
}

export class EditMultipleOnBoardPersonnel {
  static readonly type = '[CurrentPersonnel] Edit Multiple On Board Personnel';
  constructor(public request: IEditMultipleOnBoardPersonnel) {}
}
