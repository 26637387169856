import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Directive({
  selector: '[appConfirmDialog]',
  standalone: true,
  exportAs: 'appConfirmDialog',
})
export class ConfirmDialogDirective {
  @Input() confirmTitle: string;
  @Input() confirmEntityName: string;
  @Input() confirmMessage: string;
  @Input() confirmButtonText: string;
  @Input() confirmButton: 'primary' | 'warn-secondary';
  @Input() manualTrigger = false;
  @Output() confirmed = new EventEmitter<void>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click')
  handleClick(): void {
    if (this.manualTrigger) {
      return;
    }
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.confirmTitle,
        entityName: this.confirmEntityName,
        message: this.confirmMessage,
        confirmButtonText: this.confirmButtonText,
        confirmButton: this.confirmButton,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.confirmed.emit();
      }
    });
  }
}
