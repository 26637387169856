import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-admin-template',
  templateUrl: './order-admin-template.component.html',
  styleUrls: ['./order-admin-template.component.scss'],
})
export class OrderAdminTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: () => void };

  trackBy = (index: number) => index;
}
