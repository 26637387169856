/* eslint-disable no-shadow */
/* eslint-disable no-magic-numbers */
import { IOption } from '../models/option.model';

export enum DepartmentStatus {
  NotCompleted = 1,
  Paused = 2,
  Active = 3,
  Archived = 4,
}

export const DepartmentType: { [key: string]: IOption } = {
  Vessel: { id: 1, name: 'Vessel' },
  LandBased: { id: 2, name: 'LandBased' },
};

export enum DepartmentVesselType {
  Cargo = 0,
  Tankers = 1,
  PassengerVessels = 2,
  TugsAndSpecial = 3,
  Fishing = 4,
  FishingFarming = 5,
  Unspecified = 6,
}

export enum DimensionUnit {
  Meters = 1,
  Feet = 2,
}

export enum PowerUnit {
  kW = 1,
  kVA = 2,
  HP = 3,
  bKW = 4,
}
export enum CertificateStatus {
  notCompleted = 1,
  paused = 2,
  active = 3,
}

export enum ModuleName {
  ADMIN = 'ADMIN',
  ALERT = 'ALERT',
  AUDIT = 'AUDIT',
  CERTIFICATE = 'CERTIFICATE',
  COMPETENCE = 'COMPETENCE',
  CREWMANAGEMENT = 'CREWMANAGEMENT',
  DOCUMENT = 'DOCUMENT',
  DEPARTMENT = 'DEPARTMENT',
  DRILL = 'DRILL',
  DUE_LIST = 'DUE_LIST',
  FORMS = 'FORMS',
  JOBPOSITION = 'JOBPOSITION',
  MAINTENANCE = 'MAINTENANCE',
  MEDICINE = 'MEDICINE',
  MESSAGES = 'MESSAGES',
  STORAGE = 'STORAGE',
  NONCONFORMANCE = 'NONCONFORMANCE',
  PRODUCTOWNER = 'PRODUCTOWNER',
  PRODUCTOWNERUSER = 'PRODUCTOWNERUSER',
  PROFILE = 'PROFILE',
  ROLE = 'ROLE',
  TENANT = 'TENANT',
  USER = 'USER',
  ORDER = 'ORDER',
  TASK = 'WORK', // yes yes
  QUANTITYTYPES = 'QUANTITYTYPES',
  WORKANDREST = 'WORKANDREST',
  RISKASSESSMENT = 'RISKASSESSMENT',
}

export enum CustomFormStatus {
  draft = 1,
  inProgress = 2,
  complete = 3,
}

export enum UserCompetenceRequirementType {
  mandatory = 0,
  optional = 1,
  preferred = 2,
  notRequired = 3,
}
