import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-departments-template',
  templateUrl: './departments-template.component.html',
  styleUrls: ['./departments-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentsTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  trackBy = (index: number) => index;
}
