<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'dateTime'">
    {{ element['date'] | date : 'yyyy-MM-dd H:mm:ss' }}
  </ng-container>
  <ng-container *ngSwitchCase="'action'">
    {{ element['drillVersionReason'] | lowercase | translate }}
  </ng-container>

  <ng-container *ngSwitchCase="'actionDoneBy'">
    <div class="row">
      <mat-icon>perm_identity</mat-icon>
      <p class="text">{{ element['by'] }}</p>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'comment'">
    <ng-container *ngIf="element.drillVersionReason === drillActionTypes.Perform">
      {{ 'performedWithXParticipants' | translate : { participantsCount: element['details'] } }}
    </ng-container>
    <ng-container *ngIf="element.drillVersionReason === drillActionTypes.Postpone">
      <div class="column">
        <p>
          {{ 'postponed' | translate
          }}<ng-container *ngIf="element?.details?.oldValue"
            >&nbsp;{{ 'from' | translate | lowercase }}</ng-container
          >{{ element?.details?.oldValue | date : 'yyyy-MM-dd'
          }}<ng-container *ngIf="element?.details?.newValue"
            >&nbsp;{{ 'to' | translate | lowercase }} </ng-container
          >{{ element?.details?.newValue | date : 'yyyy-MM-dd' }}
        </p>
        <p>{{ element?.details?.comment }}</p>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'view'">
    <ng-container *ngIf="element.drillVersionReason === drillActionTypes.Perform">
      <app-ui-button
        buttonType="secondary"
        text="{{ 'view' | translate }}"
        [routerLink]="performViewRoute + '/' + element['drillId'] + '/' + element['drillVersionId']"
      >
      </app-ui-button>
    </ng-container>
    <ng-container *ngIf="element.attachment?.link">
      <a
        *ngIf="element.attachment.id !== 'new'"
        [href]="element.attachment.link"
        target="_blank"
        [download]="element.attachment.name"
      >
        <mat-icon class="download-icon">download</mat-icon>
      </a>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
