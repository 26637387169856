/* eslint-disable no-magic-numbers */
export enum ActivityColor {
  Red = 1,
  Yellow = 2,
  Green = 3,
  Blue = 4,
  Purple = 5,
}

export const activityColorOptions = [
  { id: ActivityColor.Green, name: ActivityColor.Green.toString() },
  { id: ActivityColor.Yellow, name: ActivityColor.Yellow.toString() },
  { id: ActivityColor.Red, name: ActivityColor.Red.toString() },
  { id: ActivityColor.Blue, name: ActivityColor.Blue.toString() },
  { id: ActivityColor.Purple, name: ActivityColor.Purple.toString() },
];
