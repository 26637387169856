<mat-form-field
  appearance="outline"
  [class.has-items]="control.value.length"
  [class.mat-form-field-invalid]="control.invalid && (control.dirty || control.touched)"
  [class.disabled]="disabled"
  (focusout)="onFocusOut()"
>
  <mat-label
    >{{ label }}
    <span
      *ngIf="required"
      class="mat-mdc-form-field-required-marker mdc-floating-label--required"
    ></span>
  </mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let item of control.value" [value]="item.id" (removed)="remove(item)">
      <span>{{ returnWholeObject ? item.name : optionNamesMap[item] }}</span>
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      placeholder="{{
        (addNewValueOnBlur || addNewValueOnEnter
          ? 'newItemPlaceholder'
          : 'pleaseStartTypingToFilter'
        ) | translate
      }}"
      #inputField
      [formControl]="inputControl"
      [required]="required"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="onInputTokenEnd($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelect($event)"
    (opened)="control.markAsTouched()"
  >
    <mat-option
      *ngFor="let option of filteredOptions"
      [value]="returnWholeObject ? option : option.id"
    >
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<div class="input-description">{{ description }}</div>
