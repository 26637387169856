import { Moment } from 'moment';

export function datepickerValueToDate(date: Date | string | Moment): Date {
  // TODO: take a look at material date-fns adapter, moment.js should be removed
  if (date instanceof Date) {
    return date;
  }
  if (typeof date === 'string') {
    return new Date(date);
  }
  return (date as Moment).toDate();
}
