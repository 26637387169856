<div class="main-search-container">
  <app-ui-autocomplete
    [placeholder]="'search'"
    [options]="hashtags"
    [searchTerm]="form.searchTerm"
    [floatLabel]="'always'"
    (keyup)="searchWhenKeyUp($event)"
    (emitSearch)="searchFor()"
  >
  </app-ui-autocomplete>
</div>
