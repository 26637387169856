import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subscription, switchMap, tap } from 'rxjs';
import { LoadAlerts } from 'src/app/alert/state/alert.actions';
import { DueListState } from 'src/app/due-list/state/due-list.state';
import { IUserAccess } from 'src/app/shared/user-view/models/tenant-user-access.model';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { TenantUserPrivilegesService } from 'src/app/tenant-selector/services/tenant-user-privileges.service';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IDueListItem } from 'src/app/due-list/models/due-list-item.model';
import { MainMenuListModel } from '../../models/menu-list.model';
import { ClearFilterDetails } from '../../state/main-table-filter.actions';
import { SaveNewMenuState } from '../../state/menu-list.actions';
import { MenuListState, MenuStateModel } from '../../state/menu-list.state';
import { ADMIN_MENU_LIST_ROUTES, USER_MENU_LIST_ROUTES } from './menu-list-routes';
import { MainTableFilterModulesEnum } from '../../enums/main-table-filter-modules.enum';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent implements OnInit, OnDestroy {
  getMenuState$: Observable<MenuStateModel | undefined> = this.store.select(
    MenuListState.getMenuState
  );
  dueListWarnings$: Observable<IDueListItem[]> = this.store.select(DueListState.getAllWarnings);
  dueListCompetencies$: Observable<IDueListItem[]> = this.store.select(
    DueListState.getAllDueCompetencies
  );
  userAccess$: Observable<IUserAccess> = this.store.select(TenantUserAccessState.userAccess);
  currentDepartmentId$ = this.store.select(TenantUserAccessState.currentDepartmentId);
  currentTenantId$: Observable<string> = this.store.select(TenantUserAccessState.currentTenantId);

  mainMenuItems: MainMenuListModel[];
  currentTenantId: string;
  currentMenuState: MenuStateModel;
  isInAdmin: boolean;
  buttonText = 'backToHome';
  expiredWarnings = 0;
  soonExpiringWarnings = 0;

  private subscriptions = new Subscription();
  private isProductOwner: boolean;

  constructor(
    private store: Store,
    private router: Router,
    private tenantUserPrivileges: TenantUserPrivilegesService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.userAccess$.subscribe((userAccess) => {
        if (userAccess) {
          this.isProductOwner = userAccess.isProductOwner;
        }
      })
    );
    this.initSubscriptions();
    this.listenToRouteChanges();
    this.getWarnings();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  exitAdmin(): void {
    this.store.dispatch(new ClearFilterDetails());
  }

  trackBy = (index: number) => index;

  private initSubscriptions(): void {
    const alertsFilterAll = new MainTableFilterModel(MainTableFilterModulesEnum.Alerts);
    alertsFilterAll.pageIndex = 0;
    // eslint-disable-next-line no-magic-numbers
    alertsFilterAll.pageSize = 10000;
    this.subscriptions.add(
      this.currentTenantId$.subscribe((tenantId) => {
        if (tenantId) {
          this.currentTenantId = tenantId;
          this.store.dispatch(new LoadAlerts(alertsFilterAll));
        }
      })
    );

    this.subscriptions.add(
      this.getMenuState$.subscribe((getMenuState) => {
        if (getMenuState) {
          this.currentMenuState = getMenuState;
          this.mainMenuItems = this.loadAppropriateMenuItems(getMenuState.isInAdmin);
        }
      })
    );
  }

  private listenToRouteChanges(): void {
    if (this.router.url === '/') {
      this.store.dispatch(new SaveNewMenuState('/'));
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.alreadyHasAppropriateItems(event.url))
          this.mainMenuItems = this.loadAppropriateMenuItems(this.isUrlInAdmin(event.url));
        this.store.dispatch(new SaveNewMenuState(event.url));
      }
    });
  }

  private alreadyHasAppropriateItems(url: string): boolean {
    if (!this.mainMenuItems) return false;
    if (this.isUrlInAdmin(url) && this.isInAdmin) return true;
    return !this.isUrlInAdmin(url) && !this.isInAdmin;
  }

  private loadAppropriateMenuItems(isInAdmin: boolean): MainMenuListModel[] {
    this.isInAdmin = isInAdmin;

    if (!this.currentTenantId) {
      return [];
    }

    return this.isInAdmin ? this.getAdminRoutes() : this.getUserRoutes();
  }

  private isUrlInAdmin(url: string): boolean {
    const urlFragments = url.split('/');
    return urlFragments.some((urlFragment: string) => urlFragment === 'admin');
  }

  private getUserRoutes(): MainMenuListModel[] {
    return this.tenantUserPrivileges.recursiveFilterMenuItemsPerPrivileges(USER_MENU_LIST_ROUTES);
  }

  private getAdminRoutes(): MainMenuListModel[] {
    let adminRoutes =
      this.tenantUserPrivileges.recursiveFilterMenuItemsPerPrivileges(ADMIN_MENU_LIST_ROUTES);

    if (!this.isProductOwner) {
      adminRoutes = adminRoutes.filter((route: MainMenuListModel) => route.name !== 'productOwner');
    }
    return adminRoutes;
  }

  private getWarnings(): void {
    this.subscriptions.add(
      this.dueListWarnings$
        .pipe(
          tap((dueListWarnings: IDueListItem[]) => {
            this.expiredWarnings = 0;
            this.soonExpiringWarnings = 0;
            dueListWarnings.forEach((dueListWarning: IDueListItem) => {
              if (dueListWarning.status === 'Expired') {
                this.expiredWarnings++;
              } else if (dueListWarning.status === 'Expiring') {
                this.soonExpiringWarnings++;
              }
            });
          }),
          switchMap(() => this.dueListCompetencies$),
          tap((dueListCompetences: IDueListItem[]) => {
            dueListCompetences.forEach((dueListCompetence: IDueListItem) => {
              if (dueListCompetence.status === 'Expired') {
                this.expiredWarnings++;
              } else if (dueListCompetence.status === 'Expiring') {
                this.soonExpiringWarnings++;
              }
            });
          })
        )
        .subscribe()
    );
  }
}
