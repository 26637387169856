import { AbstractControl } from '@angular/forms';

// TODO Illia: move all validators to a separate folder

export function onlyIntegersValidator(control: AbstractControl) {
  const isValid = /^[0-9]*$/.test(control.value);
  return isValid ? null : { onlyIntegers: true };
}

export function onlyIntegersIncludingNegativeValidator(control: AbstractControl) {
  const isValid = /^-?[0-9]*$/.test(control.value);
  return isValid ? null : { onlyIntegers: true };
}
