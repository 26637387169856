import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-crew-management-user-templates',
  templateUrl: './crew-management-user-templates.component.html',
})
export class CrewManagementUserTemplatesComponent {
  @Input() specificTemplateName: string;
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: (...args: any[]) => void };
}
