<mat-nav-list appAccordion>
  <div class="back-to-home-container" *ngIf="isInAdmin">
    <app-ui-button
      buttonType="mat"
      [text]="buttonText | translate"
      icon="arrow_circle_left"
      routerLink="/"
      (click)="exitAdmin()"
    ></app-ui-button>
  </div>
  <div class="main-modules-section" [ngClass]="isInAdmin ? 'admin-main-modules-section' : ''">
    <div
      *ngFor="let mainSection of mainMenuItems; trackBy: trackBy"
      class="main-section"
      [ngClass]="
        mainSection.name === 'productOwner' || mainSection.name === 'other'
          ? 'bottom-main-section'
          : ''
      "
    >
      <div *ngIf="mainSection.name | showMainMenuSection : (currentDepartmentId$ | async)">
        <h5>{{ mainSection.name | translate | uppercase }}</h5>
        <app-menu-list-recursive
          [navItems]="mainSection.children"
          [currentTenantId]="currentTenantId"
          [currentMenuState]="currentMenuState"
          [isInAdmin]="isInAdmin"
          [dueListWarningsExpiredSum]="expiredWarnings"
          [dueListWarningsExpiringSum]="soonExpiringWarnings"
        ></app-menu-list-recursive>
      </div>
    </div>
  </div>
</mat-nav-list>
