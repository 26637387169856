export enum MainTableFilterModulesEnum {
  Certificates = 'certificates',
  CertificateTypes = 'certificate-type',
  CertificateStructures = 'certificate-structures',
  Competences = 'competences',
  CompetenceGroups = 'competence-groups',
  CompetenceUsers = 'competence-users',
  MyCompetenceUsers = 'my-competence-users',
  CompetenceRequirement = 'competence-requirement',
  PositionGroups = 'position-groups',
  JobPositions = 'job-positions',
  Activities = 'activities',
  Shifts = 'shifts',
  PlannedActivities = 'planned-activities',
  Documents = 'documents',
  DocumentsByMenu = 'documents-by-menu',
  Departments = 'departments',
  DepartmentGroups = 'department-groups',
  JobTypes = 'job-types',
  MaintenanceJobs = 'maintenance-jobs',
  Components = 'components',
  Drills = 'drills',
  Tasks = 'tasks',
  NonConformances = 'non-conformances',
  NonConformanceCauses = 'non-conformance-causes',
  NonConformanceTypes = 'non-conformance-types',
  NonConformanceSeaStates = 'non-conformance-sea-states',
  NonConformanceWeatherInformation = 'non-conformance-weather-information',
  NonConformanceLocationOnDepartment = 'non-conformance-location-on-department',
  Severities = 'severities',
  Users = 'users',
  ProductOwnerUsers = 'po-users',
  PlannedActivityUsers = 'planned-activities-users',
  ProductOwner = 'product-owner',
  Tenant = 'tenant',
  RunTime = 'run-time',
  Medicines = 'medicines',
  MedicineCategories = 'medicine-categories',
  OrderTypes = 'order-types',
  Suppliers = 'suppliers',
  Purchasers = 'purchasers',
  QuantityTypes = 'quantity-types',
  OvertimeTypes = 'overtime-types',
  CurrentPersonnel = 'current-personnel',
  MissingConfirmation = 'missing-confirmation',
  CurrentPaxOverview = 'current-pax-overview',
  PlannedPaxOverview = 'planned-pax-overview',
  PastPaxOverview = 'past-pax-overview',
  CurrentAndPlannedPaxOverview = 'current-and-planned-pax-overview',
  AdminPaxes = 'admin-paxes',
  Alerts = 'alerts',
  Storage = 'storage',
  Products = 'products',
  ProductCategories = 'product-categories',
  ProductTypes = 'product-types',
  Accounts = 'accounts',
  AccountGroups = 'account-groups',
  DeliveryConditions = 'delivery-conditions',
  Orders = 'orders',
  OrdersSentToSupplier = 'ordersSentToSupplier',
  ApprovedOrders = 'approvedOrders',
  RiskAssessments = 'risk-assessments',
  Hazards = 'hazards',
  Consequences = 'consequences',
  Locations = 'locations',
  OperationTypes = 'operationTypes',
  MitigatingMeasures = 'mitigatingMeasures',
  PrivilegeTemplates = 'privilege-templates',
  Trips = 'trips',
  DueListWarnings = 'due-list-warnings',
  DueListCompetences = 'due-list-competences',
  DueListMyWarnings = 'due-list-my-warnings',
  ComponentSpareParts = 'component-spare-parts',
  JobSpareParts = 'job-spare-parts',
  ComponentProductTypes = 'component-product-types',
  JobProductTypes = 'job-product-types',
}
