/* eslint-disable new-cap */
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, switchMap, map, Observable, shareReplay, tap, Subscription } from 'rxjs';
import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';
import { LoadQuantityTypes } from 'src/app/quantity-type/state/quantity-type.actions';
import { QuantityTypeState } from 'src/app/quantity-type/state/quantity-type.state';
import { LoadMedicineCategories } from 'src/app/medicine-admin/state/medicine-category.actions';
import { MedicineCategoryState } from 'src/app/medicine-admin/state/medicine-category.state';
import { LoadJobTypes } from 'src/app/maintenance-admin/state/job-type.actions';
import { JobTypeState } from 'src/app/maintenance-admin/state/job-type.state';
import { LoadCertificateTypes } from 'src/app/certificate-admin/state/certificate-types.action';
import { CertificateTypeState } from 'src/app/certificate-admin/state/certificate-types.state';
import { StaticDependenciesService } from 'src/app/services/static-dependencies.service';
import { ComponentsStructureState } from 'src/app/maintenance-admin/state/components-structure.state';
import { GetMaintenanceComponents } from 'src/app/maintenance-admin/state/components-structure.actions';
import { ofActionSuccessful } from '@ngxs/store';
import { LoadAllJobPositionsForDepartment } from 'src/app/competence/state/job-position.actions';
import { JobPositionState } from 'src/app/competence/state/job-position.state';
import { LoadJobPositionGroups } from 'src/app/competence/state/job-position-group.actions';
import { JobPositionGroupState } from 'src/app/competence/state/job-position-group.state';
import { IMaintenanceComponent } from 'src/app/maintenance-admin/models/maintenance-component.model';
import {
  MainTableFilterModel,
  MainTableFilterSearchByModel,
} from '../../models/main-table-filter.model';
import {
  MainTableFilterListOption,
  MainTableSearchByValueType,
} from '../main-table/main-table-column-filter/main-table-column-filter.component';

const MODULE_MAP: any = {
  [MainTableFilterModulesEnum.QuantityTypes]: [LoadQuantityTypes, QuantityTypeState.quantityTypes],
  [MainTableFilterModulesEnum.MedicineCategories]: [
    LoadMedicineCategories,
    MedicineCategoryState.medicineCategoriesForCurrentDepartment,
  ],
  [MainTableFilterModulesEnum.JobTypes]: [LoadJobTypes, JobTypeState.jobTypes],
  [MainTableFilterModulesEnum.CertificateTypes]: [
    LoadCertificateTypes,
    CertificateTypeState.certificateTypes,
  ],
  [MainTableFilterModulesEnum.Components]: [
    GetMaintenanceComponents,
    ComponentsStructureState.getComponentList,
    (components: IMaintenanceComponent[]) =>
      components
        .map((c) => ({ label: `${c.sfiCode} ${c.name}`, value: c.name }))
        .sort((a, b) => a.label.localeCompare(b.label)),
  ],
  [MainTableFilterModulesEnum.JobPositions]: [
    LoadAllJobPositionsForDepartment,
    JobPositionState.getJobPositionsForDepartment,
  ],
  [MainTableFilterModulesEnum.PositionGroups]: [
    LoadJobPositionGroups,
    JobPositionGroupState.getJobPositionGroups,
  ],
};
const MODULES_WITHOUT_FILTERS = [
  MainTableFilterModulesEnum.JobPositions,
  MainTableFilterModulesEnum.PositionGroups,
];

@Injectable({ providedIn: 'root' })
export class MainTableFilteringHelperService {
  // TODO Illia: have I overdone it with statics? try a regular service after release
  static cache: { [key: string]: Observable<MainTableFilterListOption> } = {};
  static subs: { [key: string]: Subscription } = {};
  static appendFilteringParams(
    params: HttpParams,
    currentFilter?: MainTableFilterModel,
    columnToKeyMap: any = {}
  ): any {
    if (!currentFilter) {
      return params;
    }
    const consolidated = consolidateProperties(currentFilter.searchBy || []);
    consolidated.forEach((searchBy) => {
      // TODO Illia: kind of a hack, need to refactor and pass sort of a filtering value trasnformers/mappers to filtering config
      if (searchBy.property === 'expiryType') {
        const arr = (searchBy.value as any).In as string[];
        if (arr.includes('intervalExpiry') && arr.includes('runtimeExpiry')) {
          // eslint-disable-next-line no-param-reassign
          searchBy = { property: 'expiryType', value: { In: ['both'] } };
        }
      }
      // eslint-disable-next-line no-param-reassign
      params = params.append(
        `SearchBy[${toTitleCase(columnToKeyMap[searchBy.property] || searchBy.property)}]`,
        JSON.stringify(searchBy.value)
      );
    });
    return params;
  }

  static getOptionsForList(module: MainTableFilterModulesEnum): Observable<string[]> {
    const [action, selector, mapper] = MODULE_MAP[module];
    if (!MainTableFilteringHelperService.cache[module]) {
      MainTableFilteringHelperService.cache[module] = of(null).pipe(
        switchMap(() => {
          const filter = new MainTableFilterModel(module);
          filter.pageSize = 9999;
          const actionClass = MODULES_WITHOUT_FILTERS.includes(module)
            ? new action()
            : new action(filter);
          return StaticDependenciesService.store.dispatch(actionClass);
        }),
        switchMap(() => StaticDependenciesService.store.select(selector) as Observable<any>),
        tap((options) => {
          MainTableFilteringHelperService.cache[module] = of(options);
        }),
        shareReplay(1)
      );
    }
    if (!MainTableFilteringHelperService.subs[module]) {
      MainTableFilteringHelperService.subs[module] = StaticDependenciesService.actions
        .pipe(
          ofActionSuccessful(action),
          tap(() => {
            delete MainTableFilteringHelperService.cache[module];
          })
        )
        .subscribe();
    }
    return this.cache[module].pipe(
      map((items) =>
        mapper
          ? mapper(items)
          : items.map((entity: any) => entity.name).sort((a, b) => a.localeCompare(b))
      )
    );
  }

  static clear(): void {
    Object.keys(MainTableFilteringHelperService.cache).forEach((key) => {
      delete MainTableFilteringHelperService.cache[key];
    });
    Object.keys(MainTableFilteringHelperService.subs).forEach((key) => {
      MainTableFilteringHelperService.subs[key].unsubscribe();
      delete MainTableFilteringHelperService.subs[key];
    });
  }
}

interface SearchByRequestBody {
  property: string;
  value: Record<string, MainTableSearchByValueType>;
}

function toTitleCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function consolidateProperties(arr: MainTableFilterSearchByModel[]): SearchByRequestBody[] {
  const temp: Record<string, Record<string, MainTableSearchByValueType>> = {};
  arr.forEach(({ property, operand, value }) => {
    if (!temp[property]) {
      temp[property] = {};
    }
    temp[property][operand] = value;
  });
  return Object.entries(temp).map(([property, value]) => ({ property, value }));
}
