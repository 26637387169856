/* eslint-disable no-magic-numbers */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapNonConformanceAction',
  pure: true,
  standalone: true,
})
export class MapNonConformanceActionPipe implements PipeTransform {
  // temporary solution, until enums come as numbers from backend
  private nonConformanceActionMapper: { [key: number]: string } = {
    0: 'created',
    1: 'created',
    2: 'edited',
    3: 'delegated',
    4: 'closed',
    5: 'completed',
    6: 'reopened',
    7: 'reportUpdated',
    8: 'reportCreated',
    99: 'legacy',
  };

  transform(nonConformanceAction: string | number): string {
    if (typeof nonConformanceAction === 'string') {
      return this.firstLetterToLowercase(nonConformanceAction);
    }
    return this.nonConformanceActionMapper[nonConformanceAction];
  }

  private firstLetterToLowercase(value: string) {
    if (value) {
      return value.charAt(0).toLowerCase() + value.slice(1);
    }
    return '';
  }
}
