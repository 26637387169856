<mat-form-field *ngIf="control" appearance="outline">
  <mat-label> {{ label }}</mat-label>
  <div
    class="text-input-plus-sign"
    *ngIf="prependPlusSingForPositives && control.value > 0"
    [ngClass]="control.errors ? 'error-offset' : ''"
  >
    +
  </div>
  <input
    #input
    [type]="type"
    matInput
    [formControl]="control"
    [required]="required"
    (keydown)="keyDown($event)"
    (input)="formatInput()"
    [tabindex]="tabIndex"
    [placeholder]="placeholder"
  />
  <ng-container *ngIf="!control.pristine">
    <mat-error *ngFor="let error of control.errors | errorKeys">
      {{ messages[error] || error | translate }}
    </mat-error>
  </ng-container>
</mat-form-field>
<div class="input-description">{{ description }}</div>
