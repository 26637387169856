<ng-container *appLoader="!currentTenantId">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Tenant Id</mat-label>
      <mat-select [(ngModel)]="currentTenantId" [hideSingleSelectionIndicator]="true">
        <mat-option *ngFor="let tenantId of data.tenants" [value]="tenantId.id">{{
          tenantId.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="chooseTenantId()">Choose</button>
  </div>
</ng-container>
