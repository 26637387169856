import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { WorkInProgressComponent } from './shared/components/work-in-progress/work-in-progress.component';
import { GlobalSearchResultComponent } from './core/components/global-search-result/global-search-result.component';
import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { FailedComponent } from './core/components/failed/failed.component';
import { AdminRoutesEnum, RoutesEnum, SupportRoutes } from './core/enums/routes.enum';

const appRoutes: Routes = [
  {
    path: AdminRoutesEnum.Admin,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: SupportRoutes.LoginFailed,
    component: FailedComponent,
  },
  {
    path: `${SupportRoutes.GlobalSearchResult}`,
    component: GlobalSearchResultComponent,
  },
  {
    path: RoutesEnum.Documents,
    loadChildren: () =>
      import('./document-user/document-user.module').then((m) => m.DocumentUserModule),
  },
  {
    path: RoutesEnum.Certificate,
    loadChildren: () =>
      import('./certificate-user/certificate-user.module').then((m) => m.CertificateUserModule),
  },
  {
    path: RoutesEnum.NonConformance,
    loadChildren: () =>
      import('./non-conformance-user/non-conformance-user.module').then(
        (m) => m.NonConformanceUserModule
      ),
  },
  {
    path: RoutesEnum.RiskAssessments,
    loadChildren: () =>
      import('./risk-assessment-user/risk-assessment-user.module').then(
        (m) => m.RiskAssessmentUserModule
      ),
  },
  {
    path: RoutesEnum.Purchase,
    loadChildren: () => import('./order-user/order-user.module').then((m) => m.OrderUserModule),
  },
  {
    path: RoutesEnum.Audits,
    loadChildren: () => import('./audit-user/audit-user.module').then((m) => m.AuditUserModule),
  },
  {
    path: RoutesEnum.Storage,
    loadChildren: () =>
      import('./storage-user/storage-user.module').then((m) => m.StorageUserModule),
  },
  {
    path: RoutesEnum.Medicines,
    loadChildren: () =>
      import('./medicine-user/medicine-user.module').then((m) => m.MedicineUserModule),
  },
  {
    path: RoutesEnum.Drills,
    loadChildren: () => import('./drills-user/drills-user.module').then((m) => m.DrillsUserModule),
  },
  {
    path: RoutesEnum.Checklists,
    loadChildren: () => import('./task-user/task-user.module').then((m) => m.TaskUserModule),
  },
  {
    path: RoutesEnum.Responses,
    loadChildren: () =>
      import('./shared/custom-forms-responses/custom-forms-responses.module').then(
        (m) => m.CustomFormsResponsesModule
      ),
  },
  {
    path: RoutesEnum.Maintenance,
    loadChildren: () =>
      import('./maintenance-user/maintenance-user.module').then((m) => m.MaintenanceUserModule),
  },
  {
    path: RoutesEnum.ShiftAndManning,
    loadChildren: () =>
      import('./crew-management-user/crew-management-user.module').then(
        (m) => m.CrewManagementUserModule
      ),
  },
  {
    path: RoutesEnum.Profile,
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: RoutesEnum.RunTime,
    loadChildren: () => import('./run-time/run-time.module').then((m) => m.RunTimeModule),
  },
  {
    path: 'due-list',
    loadChildren: () => import('./due-list/due-list.module').then((m) => m.DueListModule),
  },
  {
    path: 'work-and-rest-admin',
    loadChildren: () =>
      import('./work-and-rest-admin/work-and-rest-admin.module').then(
        (m) => m.WorkAndRestAdminModule
      ),
  },
  {
    path: '**',
    component: WorkInProgressComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // Don't perform initial navigation in iframes or popups
      // should be 'enabledBlocking' for Angular Universal but we're not using it rn and it's causing issues with breadcrumbs on reload
      // https://github.com/udayvunnam/xng-breadcrumb/issues/108
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Remove this line to use Angular Universal
      preloadingStrategy: NoPreloading,
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
