import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { validationMessages } from '../../messages';
import { UiSelectOptions } from '../../models/ui-select-option.model';
import { SELECT_ALL_VALUE } from '../../constants/select-all-value';

@Component({
  selector: 'app-ui-select',
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.scss'],
})
export class SharedUiSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label = '';
  @Input() description = '';
  @Input() control: UntypedFormControl;
  @Input() options: UiSelectOptions = [];
  @Input() messages = validationMessages;
  @Input() placeholder?: string;
  @Input() selected: string[] | string | any = [];
  @Input() isMultiple = true;
  @Input() disabled = false;
  @Input() required = false;
  @Input() sortByName = false;
  @Input() enableBlankOption = false;
  @Input() returnData = false;
  @Input() returnWholeObject = false;
  @Input() enableSearch = false;
  @Input() selectAll = false;
  @Input() selectAllSingleChoice = false;
  @Input() panelClass = '';

  sortedOptions: UiSelectOptions = [];
  areSomeRowsSelected = false;
  areAllRowsSelected = false;
  SELECT_ALL_VALUE = SELECT_ALL_VALUE;

  @Output() readonly changeHandler: EventEmitter<MatSelectChange> =
    new EventEmitter<MatSelectChange>();

  @ViewChild('searchInput') searchInput: ElementRef;

  private initialOptions: UiSelectOptions;
  private subscription = new Subscription();

  ngOnInit(): void {
    if (this.selectAll) {
      this.subscription.add(
        this.control.valueChanges.subscribe(() => this.setSelectAllCheckboxProperties())
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setSelectAllCheckboxProperties(): void {
    if (this.options && Array.isArray(this.control.value)) {
      this.areSomeRowsSelected =
        this.control.value.length > 0 && this.control.value.length < this.options?.length;
      this.areAllRowsSelected =
        this.options.length > 0 && this.control.value.length === this.options.length;
    } else {
      this.areAllRowsSelected = false;
      this.areSomeRowsSelected = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options?.currentValue !== changes.options?.previousValue) {
      this.initialOptions = this.options;
      if (this.searchInput) {
        this.searchInput.nativeElement.value = '';
      }
    }
    if (changes.isRequired?.currentValue === true) {
      this.control.setValidators(Validators.required);
      this.control.updateValueAndValidity();
    } else if (changes.isRequired?.currentValue === false) {
      this.control.clearValidators();
      this.control.updateValueAndValidity();
    }
    if (changes?.options?.currentValue?.length > 0) {
      this.sortedOptions = [...changes.options.currentValue].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    }

    if (this.selectAll) {
      this.setSelectAllCheckboxProperties();
    }
  }

  focusInput(): void {
    if (this.searchInput?.nativeElement) {
      const listEl = document.querySelector('.mat-mdc-select-panel.app-ui-select');
      if (listEl) {
        listEl.scrollTop = 0;
      }
      this.searchInput.nativeElement.value = '';
      this.searchInput.nativeElement.focus();
    }
    this.options = this.initialOptions;
  }

  // Receive user input and send to search method**
  onSearch(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    if (
      event.code === 'Space' ||
      event.key === ' ' ||
      event.key === 'Spacebar' ||
      event.key === 'Space'
    ) {
      // prevent closing the dropdown on space press
      event.preventDefault();
      event.stopPropagation();
      target.value += ' ';
    }
    this.options = target.value?.length > 0 ? this.search(target.value) : this.initialOptions;
  }

  // Filter the states list and send back to populate the selectedStates**
  search(value: string) {
    const filter = value.toLowerCase();
    return this.options?.filter((option: any) => option.name.toLowerCase().includes(filter));
  }

  onChange(event: MatSelectChange): void {
    this.changeHandler.emit(event);
    if (this.searchInput?.nativeElement) {
      this.searchInput.nativeElement.value = '';
    }
  }

  trackBy = (index: number) => index;

  compareWith(o1: any, o2: any): boolean {
    return o1?.id && o2?.id ? o1.id === o2.id : Object.is(o1, o2);
  }

  toggleSelection(change: any): void {
    if (change.checked) {
      const allOptions = this.options?.map((option) => option.id);
      this.control.setValue(allOptions as any[]);
    } else {
      this.control.setValue([]);
    }

    this.setSelectAllCheckboxProperties();
  }
}
