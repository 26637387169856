import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge, Observable, tap } from 'rxjs';
import { ModuleForm } from './module-form';
import { AddressForm } from '../../shared/forms/address.form';
import { ContactForm } from './contact-form';
import { ITenant } from '../models/tenant.model';
import { IdName } from '../../shared/models/id-name';

export class TenantForm extends FormGroup {
  readonly id = this.get('id') as FormControl<string>;
  readonly name = this.get('name') as FormControl<string>;
  readonly logo = this.get('logo') as FormControl<File>;
  readonly soldBy = this.get('soldBy') as FormControl<number>;
  readonly accountType = this.get('accountType') as FormControl<number>;
  readonly isDPAService = this.get('isDPAService') as FormControl<boolean>;
  readonly isImplementing = this.get('isImplementing') as FormControl<boolean>;
  readonly isLogoChanged = this.get('isLogoChanged') as FormControl<boolean>;
  readonly selectDPA = this.get('selectDPA') as FormControl<string[]>;
  readonly modules = this.get('modules') as FormArray<ModuleForm>;
  readonly address = this.get('address') as AddressForm;
  readonly contacts = this.get('contacts') as FormArray<ContactForm>;
  readonly predefinedValues = this.get('predefinedValues') as FormControl<File>;
  readonly organizationNumber = this.get('organizationNumber') as FormControl<number>;

  isDPARequired = false;

  constructor(modules: IdName[], tenant?: ITenant, readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        id: [tenant?.id ? tenant?.id : ''],
        name: [tenant?.name ? tenant?.name : '', [Validators.required, Validators.maxLength(200)]],
        logo: [tenant?.logo ? tenant?.logo : ''],
        isLogoChanged: false,
        soldBy: [tenant?.soldBy ? tenant?.soldBy : '', [Validators.required, Validators.min(0)]],
        accountType: [tenant?.accountType ? tenant?.accountType : 1, Validators.required],
        isDPAService: [tenant?.isDPAService ? tenant?.isDPAService : ''],
        isImplementing: [tenant?.isImplementing ? tenant?.isImplementing : ''],
        selectDPA: [tenant?.selectDPA],
        address: new AddressForm(tenant?.address),
        contacts: tenant?.contacts
          ? fb.array(tenant?.contacts?.map((contact) => new ContactForm(contact)))
          : new FormArray<ContactForm>([]),
        modules: fb.array(
          modules.map((moduleOption) => {
            const module = tenant?.modules.find((module) => module.module === +moduleOption.id);
            let moduleForm;
            if (module) {
              moduleForm = new ModuleForm(moduleOption, module);
            } else {
              moduleForm = new ModuleForm(moduleOption);
            }
            return moduleForm;
          })
        ),
        predefinedValues: [''],
        organizationNumber: [tenant?.organizationNumber ?? ''],
      }).controls
    );
  }
  onModuleValueChanges(): Observable<boolean> {
    return merge(
      ...this.modules.controls.map((moduleForm) => {
        return moduleForm.onModuleSelected();
      })
    );
  }

  enableForm(): void {
    this.enable();
    this.modules.controls.forEach((module) => {
      if (!module.selected.value) {
        module.soldFor.disable();
      }
    });
  }

  onIsDPAChange(): Observable<boolean> {
    return this.isDPAService.valueChanges.pipe(
      tap((isDPAService) => {
        if (isDPAService) {
          this.selectDPA.setValidators([Validators.required]);
          this.isDPARequired = true;
        } else {
          this.selectDPA.clearValidators();
          this.selectDPA.setErrors(null);
          this.isDPARequired = false;
        }
      })
    );
  }
}
