import { FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { maxLengthValidator } from 'src/app/shared/functions/max-length-validator';
import { noWhitespaceValidator } from 'src/app/shared/functions/no-whitespace-validator';
import { onlyIntegersIncludingNegativeValidator } from 'src/app/shared/functions/only-integers-validator';
import { IAlertResponse } from '../models/alert.model';

export class AlertForm extends FormGroup {
  readonly id = this.get('id') as UntypedFormControl;
  readonly name = this.get('name') as UntypedFormControl;
  readonly modules = this.get('modules') as UntypedFormControl;
  readonly yellowAlertDays = this.get('yellowAlertDays') as UntypedFormControl;
  readonly redAlertDays = this.get('redAlertDays') as UntypedFormControl;
  readonly yellowAlertHours = this.get('yellowAlertHours') as UntypedFormControl;
  readonly redAlertHours = this.get('redAlertHours') as UntypedFormControl;
  readonly smsReminder = this.get('smsReminder') as UntypedFormControl;
  readonly emailReminder = this.get('emailReminder') as UntypedFormControl;
  readonly reminderMessage = this.get('reminderMessage') as UntypedFormControl;
  readonly days = this.get('days') as UntypedFormControl;
  readonly hours = this.get('hours') as UntypedFormControl;

  constructor(alert?: IAlertResponse, readonly fb: UntypedFormBuilder = new UntypedFormBuilder()) {
    super(
      fb.group({
        id: [alert?.id ?? ''],
        name: [alert?.name ?? '', [Validators.required, noWhitespaceValidator, maxLengthValidator]],
        modules: [alert?.moduleNames ?? '', Validators.required],
        yellowAlertDays: [
          alert?.yellowAlertDays ?? null,
          [Validators.required, onlyIntegersIncludingNegativeValidator, Validators.maxLength(9)],
        ],
        redAlertDays: [
          alert?.redAlertDays ?? null,
          [Validators.required, onlyIntegersIncludingNegativeValidator, Validators.maxLength(9)],
        ],
        yellowAlertHours: [
          alert?.yellowAlertHours ?? null,
          [Validators.required, onlyIntegersIncludingNegativeValidator, Validators.maxLength(9)],
        ],
        redAlertHours: [
          alert?.redAlertHours ?? null,
          [Validators.required, onlyIntegersIncludingNegativeValidator, Validators.maxLength(9)],
        ],
        smsReminder: [alert?.smsReminder ?? false],
        emailReminder: [alert?.emailReminder ?? false],
        reminderMessage: [
          alert?.reminderMessage ?? '',
          [noWhitespaceValidator, maxLengthValidator],
        ],
        days: [(alert?.yellowAlertDays || alert?.redAlertDays) ?? true],
        hours: [(alert?.yellowAlertHours || alert?.redAlertHours) ?? false],
      }).controls
    );
  }
}
