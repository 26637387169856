/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IPurchaserRequest } from '../../models/purchasers/purchaser.model';

export class LoadPurchasers {
  static readonly type = '[Purchasers] Load Purchasers via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllPurchasers {
  static readonly type = '[Purchasers] Load All Purchasers via Service';
  constructor(public departmentId?: string) {}
}

export class LoadPurchaserById {
  static readonly type = '[Purchasers] Load Purchaser By Id via Service';
  constructor(public id: string) {}
}

export class CreatePurchaser {
  static readonly type = '[Purchasers] Create Purchaser';
  constructor(public purchaser: IPurchaserRequest) {}
}

export class UpdatePurchaser {
  static readonly type = '[Purchasers] Update Purchaser';
  constructor(public purchaser: IPurchaserRequest) {}
}

export class DeletePurchaser {
  static readonly type = '[Purchasers] Delete Purchaser';
  constructor(public purchaserId: string) {}
}
