<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'name'">
    {{ element['name'] }}
    <ng-container *ngIf="element.isGloballyAvailableForProductOwners">
      <mat-icon
        [matTooltip]="'thisIsGloballyAvailablePrivilegeTemplateForProductOwners' | translate"
        >public</mat-icon
      >
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
