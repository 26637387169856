import { IAttachment } from 'src/app/shared/attachments/models/attachments.model';
import { IStock } from 'src/app/shared/models/stock.model';

export interface IUseProduct {
  storageProductId: string;
  amountUsed: number;
  reasonForUse: string;
  adjustmentAttachments: IAttachment[];
}

export function mapStock(stock: IStock): IUseProduct {
  return <IUseProduct>{
    storageProductId: stock.id,
    amountUsed: stock.amount,
    reasonForUse: stock.reason,
    adjustmentAttachments: stock.adjustmentAttachments,
  };
}
