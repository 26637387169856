import { ComponentsStructureState } from 'src/app/maintenance-admin/state/components-structure.state';
import { JobTypeState } from 'src/app/maintenance-admin/state/job-type.state';
import { NgxsModule } from '@ngxs/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { TranslateModule } from '@ngx-translate/core';
import { RunTimeState } from '../run-time/state/run-time.state';
import { GlobalErrorState } from './state/global-error.state';
import { MenuTreeStructureState } from './state/menu-tree-structure.state';
import { PriviligeState } from './state/privileges.state';
import { TenantModule } from '../tenant/tenant.module';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FailedComponent } from './components/failed/failed.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LoggedInAsPopupComponent } from './components/logged-in-as-popup/logged-in-as-popup.component';
import { LogoutButtonComponent } from './components/login-status-logout/logout-button.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { MaterialModule } from '../modules/material.module';
import { HashtagModule } from '../hashtag/hashtag.module';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { GlobalSearchResultComponent } from './components/global-search-result/global-search-result.component';
import { GlobalSearchResultState } from './state/global-search.state';
import { TenantIdListComponent } from './components/tenant-id-list/tenant-id-list.component';
import { UiState } from './state/ui.state';
import { MenuListRecursiveComponent } from './components/menu-list-recursive/menu-list-recursive.component';
import { AlertModule } from '../alert/alert.module';
import { JobPositionState } from '../competence/state/job-position.state';
import { DepartmentGroupState } from '../department/state/department-group.state';
import { DepartmentState } from '../department/state/department.state';
import { DepartmentDropdownComponent } from './components/department-dropdown/department-dropdown.component';
import { AdminInnerNavComponent } from './components/admin-inner-nav/admin-inner-nav.component';
import { MenuPlacementIdState } from '../certificate-user/state/menu-placement-id.state';
import { UserGroupState } from '../certificate-user/state/user-groups.state';
import { JobPositionGroupState } from '../competence/state/job-position-group.state';
import { MainTableFilterState } from './state/main-table-filter.state';
import { UserState } from '../shared/user-view/state/user.state';
import { DueListState } from '../due-list/state/due-list.state';
import { ShiftState } from '../crew-management/state/shifts/shift.state';
import { QuantityTypeState } from '../quantity-type/state/quantity-type.state';
import { UIElementsModule } from '../shared/ui-elements/ui-elements.module';
import { AppLoaderDirective } from '../shared/directives/app-loader.directive';
import { MenuTreeModule } from '../shared/menu-tree-module/menu-tree.module';
import { TenantUserAccessState } from '../tenant-selector/state/tenant-user-access.state';
import { ShowMainMenuSectionPipe } from './pipes/show-main-menu-section.pipe';
import { ContainsPipe } from './pipes/contains.pipe';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { CompetenceMatrixState } from '../shared/competence-matrix/competence-matrix.state';
import { DepartmentSelectorMobileComponent } from './components/department-selector-mobile/department-selector-mobile.component';
import { UserProfileDropdownMobileComponent } from './components/user-profile-dropdown-mobile/user-profile-dropdown-mobile.component';
import { TextControlModule } from '../shared/text-control-module/text-control.module';
import { ResizeDirective } from '../shared/directives/resize.directive';
import { AppendDomainPipe } from '../shared/pipes/append-domain-name.pipe';

@NgModule({
  declarations: [
    AdminInnerNavComponent,
    DashboardComponent,
    DepartmentDropdownComponent,
    DepartmentSelectorMobileComponent,
    FailedComponent,
    GlobalSearchComponent,
    GlobalSearchResultComponent,
    LanguageSelectorComponent,
    LoggedInAsPopupComponent,
    LogoutButtonComponent,
    MainNavComponent,
    MenuListComponent,
    MenuListRecursiveComponent,
    PieChartComponent,
    TenantIdListComponent,
    UserProfileDropdownMobileComponent,
  ],
  imports: [
    AlertModule,
    AppendDomainPipe,
    AppLoaderDirective,
    BreadcrumbModule,
    CommonModule,
    ContainsPipe,
    HashtagModule,
    MaterialModule,
    MenuTreeModule,

    ResizeDirective,
    RouterModule,
    ShowMainMenuSectionPipe,
    TenantModule,
    NgxsModule.forFeature([
      CompetenceMatrixState,
      ComponentsStructureState,
      DepartmentGroupState,
      DepartmentState,
      DueListState,
      GlobalErrorState,
      GlobalSearchResultState,
      JobPositionGroupState,
      JobPositionState,
      JobTypeState,
      MainTableFilterState,
      MenuPlacementIdState,
      MenuTreeStructureState,
      PriviligeState,
      QuantityTypeState,
      RunTimeState,
      ShiftState,
      TenantUserAccessState,
      UiState,
      UserGroupState,
      UserState,
    ]),
    TextControlModule,
    TranslateModule,
    UIElementsModule,
  ],
  exports: [
    DashboardComponent,
    FailedComponent,
    LanguageSelectorComponent,
    LoggedInAsPopupComponent,
    LogoutButtonComponent,
    MainNavComponent,
    MenuListComponent,
    TextControlModule,
  ],
})
export class CoreModule {}
