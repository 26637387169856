<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'expires'">
    {{ element[columnName] | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'medicineCategory'">
    {{ element.category?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'quantityType'">
    {{ element.quantityType?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'minQuantity'">
    {{ element.minimumQuantity }}
  </ng-container>
  <ng-container *ngSwitchCase="'refrigerated'">
    <mat-icon *ngIf="element.shouldBeRefrigerated">ac_unit</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill [name]="statusColor"></app-ui-pill>
  </ng-container>
  <ng-container *ngSwitchCase="'description'">
    {{ element.reason }}
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    {{ element.date | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'changedBy'">
    {{ element.changedBy | fullName }}
  </ng-container>
  <ng-container *ngSwitchCase="'event'">
    {{ element.medicineEvent | mapMedicineEvent | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'departments'">
    <div *ngFor="let department of element.departments; trackBy: trackBy">
      {{ department.name }}
    </div>
    <div *ngFor="let departmentGroup of element.departmentGroups; trackBy: trackBy">
      {{ departmentGroup.name }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'actionButton'">
    <app-ui-button
      class="use-button"
      buttonType="secondary"
      text="{{ 'use' | translate }}"
      (click)="use()"
      privilege="MEDICINE_UPDATE"
    >
    </app-ui-button>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
