/* eslint-disable no-magic-numbers */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapProcurementArea',
})
export class MapProcurementAreaPipe implements PipeTransform {
  private procurementAreaMapper: { [key: number]: string } = {
    0: 'other',
    1: 'storage',
    2: 'medicine',
    3: 'provision',
    4: 'supplier',
  };

  transform(procurementArea: number): string {
    return this.procurementAreaMapper[procurementArea];
  }
}
