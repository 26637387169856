import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadAttachmentCategories, LoadAttachmentUnallowedFileTypes } from './attachments.actions';
import { AttachmentApiService } from '../service/attachments-api.service';
import { IdName } from '../../models/id-name';

export interface AttachmentStateModel {
  attachmentCategoriesLoaded: boolean;
  attachmentCategories: IdName[] | undefined;
  attachmentUnallowedFileTypesLoaded: boolean;
  attachmentUnallowedFileTypes: string[] | undefined;
}

const ATTACHMENTS_STATE_TOKEN = new StateToken<AttachmentStateModel>('attachments');

@State<AttachmentStateModel>({
  name: ATTACHMENTS_STATE_TOKEN,
  defaults: {
    attachmentCategoriesLoaded: false,
    attachmentCategories: undefined,
    attachmentUnallowedFileTypesLoaded: false,
    attachmentUnallowedFileTypes: undefined,
  },
})
@Injectable()
export class AttachmentState {
  constructor(private service: AttachmentApiService) {}

  @Action(LoadAttachmentCategories, { cancelUncompleted: true })
  loadAttachmentCategories({ getState, setState }: StateContext<AttachmentStateModel>) {
    if (getState().attachmentCategoriesLoaded) {
      return of(null);
    }
    return this.service.getAttachmentCategories().pipe(
      tap((response: IdName[]) => {
        setState({
          ...getState(),
          attachmentCategoriesLoaded: true,
          attachmentCategories: response,
        });
      })
    );
  }

  @Action(LoadAttachmentUnallowedFileTypes, { cancelUncompleted: true })
  loadAttachmentUnallowedFileTypes({ getState, setState }: StateContext<AttachmentStateModel>) {
    if (getState().attachmentUnallowedFileTypesLoaded) {
      return of(null);
    }
    return this.service.getAttachmentUnallowedFileTypes().pipe(
      tap((response: string[]) => {
        setState({
          ...getState(),
          attachmentUnallowedFileTypesLoaded: true,
          attachmentUnallowedFileTypes: response,
        });
      })
    );
  }

  @Selector()
  static getAttachmentCategories(state: AttachmentStateModel): IdName[] | undefined {
    return state.attachmentCategories;
  }

  @Selector()
  static attachmentCategoriesLoaded(state: AttachmentStateModel) {
    return state.attachmentCategoriesLoaded;
  }

  @Selector()
  static getAttachmentUnallowedFileTypes(state: AttachmentStateModel): string[] | undefined {
    return state.attachmentUnallowedFileTypes;
  }

  @Selector()
  static attachmentUnallowedFileTypesLoaded(state: AttachmentStateModel) {
    return state.attachmentUnallowedFileTypesLoaded;
  }
}
