import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { patch } from '@ngxs/store/operators';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';
import { DueListApiService } from '../services/due-list-api.service';
import { IDueListItem } from '../models/due-list-item.model';
import {
  LoadAllDueListDueCompetencies,
  LoadAllDueListWarnings,
  LoadAllMyDueListWarnings,
  LoadDueListDueCompetencies,
  LoadDueListItems,
  LoadDueListWarnings,
  LoadMyDueListItems,
  LoadMyDueListWarnings,
  PrintDueListWarnings,
} from './due-list.actions';

export interface DueListStateModel {
  warnings: IDueListItem[];
  allWarnings: IDueListItem[];
  dueCompetencies: IDueListItem[];
  allDueCompetencies: IDueListItem[];
  summaryOfWarnings: number;
  currentPrintedDueListWarnings: Blob | undefined;
  warningsTotalPages: number;
  myWarningsTotalPages: number;
  competencesTotalPages: number;
}

const DUE_LIST_STATE_TOKEN = new StateToken<DueListStateModel>('dueList');

@State<DueListStateModel>({
  name: DUE_LIST_STATE_TOKEN,
  defaults: {
    warnings: [],
    allWarnings: [],
    dueCompetencies: [],
    allDueCompetencies: [],
    summaryOfWarnings: 0,
    currentPrintedDueListWarnings: undefined,
    warningsTotalPages: 0,
    myWarningsTotalPages: 0,
    competencesTotalPages: 0,
  },
})
@Injectable()
export class DueListState {
  constructor(private service: DueListApiService) {}

  @Action(LoadDueListItems, { cancelUncompleted: true })
  loadDueListItems(ctx: StateContext<DueListStateModel>) {
    ctx.dispatch([
      new LoadDueListWarnings(),
      new LoadDueListDueCompetencies(),
      new LoadAllDueListWarnings(),
      new LoadAllDueListDueCompetencies(),
    ]);
  }

  @Action(LoadMyDueListItems, { cancelUncompleted: true })
  loadMyDueListItems(ctx: StateContext<DueListStateModel>) {
    ctx.dispatch([
      new LoadMyDueListWarnings(),
      new LoadDueListDueCompetencies(),
      new LoadAllMyDueListWarnings(),
      new LoadAllDueListDueCompetencies(),
    ]);
  }

  @Action(LoadDueListWarnings, { cancelUncompleted: true })
  loadDueListWarnings(ctx: StateContext<DueListStateModel>, { filter }: LoadDueListWarnings) {
    return this.service.getDueListWarnings(filter).pipe(
      tap((response: ApiResponse<IDueListItem[]>) => {
        if (response) {
          ctx.setState(patch({ warnings: response.data, warningsTotalPages: response.totalPages }));
        }
      })
    );
  }

  @Action(LoadAllDueListWarnings, { cancelUncompleted: true })
  loadAllDueListWarnings(ctx: StateContext<DueListStateModel>) {
    const tempFilterObj = new MainTableFilterModel(MainTableFilterModulesEnum.DueListWarnings);
    tempFilterObj.pageIndex = 0;
    // eslint-disable-next-line no-magic-numbers
    tempFilterObj.pageSize = 10000;
    return this.service.getDueListWarnings(tempFilterObj).pipe(
      tap((response: ApiResponse<IDueListItem[]>) => {
        if (response) {
          ctx.setState(patch({ allWarnings: response.data }));
        }
      })
    );
  }

  @Action(LoadDueListDueCompetencies, { cancelUncompleted: true })
  loadDueListDueCompetencies(
    ctx: StateContext<DueListStateModel>,
    { filter }: LoadDueListDueCompetencies
  ) {
    return this.service.getDueListDueCompetencies(filter).pipe(
      tap((response: ApiResponse<IDueListItem[]>) => {
        if (response) {
          ctx.setState(
            patch({ dueCompetencies: response.data, competencesTotalPages: response.totalPages })
          );
        }
      })
    );
  }

  @Action(LoadAllDueListDueCompetencies, { cancelUncompleted: true })
  loadAllDueListDueCompetencies(ctx: StateContext<DueListStateModel>) {
    const tempFilterObj = new MainTableFilterModel(MainTableFilterModulesEnum.DueListCompetences);
    tempFilterObj.pageIndex = 0;
    // eslint-disable-next-line no-magic-numbers
    tempFilterObj.pageSize = 10000;
    return this.service.getDueListDueCompetencies(tempFilterObj).pipe(
      tap((response: ApiResponse<IDueListItem[]>) => {
        if (response) {
          ctx.setState(patch({ allDueCompetencies: response.data }));
        }
      })
    );
  }

  @Action(LoadMyDueListWarnings, { cancelUncompleted: true })
  loadMyDueListWarnings(ctx: StateContext<DueListStateModel>, { filter }: LoadMyDueListWarnings) {
    return this.service.getMyDueListWarnings(filter).pipe(
      tap((response: ApiResponse<IDueListItem[]>) => {
        if (response) {
          ctx.setState(
            patch({ warnings: response.data, myWarningsTotalPages: response.totalPages })
          );
        }
      })
    );
  }

  @Action(LoadAllMyDueListWarnings, { cancelUncompleted: true })
  loadAllMyDueListWarnings(ctx: StateContext<DueListStateModel>) {
    const tempFilterObj = new MainTableFilterModel(MainTableFilterModulesEnum.DueListMyWarnings);
    tempFilterObj.pageIndex = 0;
    // eslint-disable-next-line no-magic-numbers
    tempFilterObj.pageSize = 10000;
    return this.service.getMyDueListWarnings(tempFilterObj).pipe(
      tap((response: ApiResponse<IDueListItem[]>) => {
        if (response) {
          ctx.setState(patch({ allWarnings: response.data }));
        }
      })
    );
  }

  @Action(PrintDueListWarnings, { cancelUncompleted: true })
  printDueListWarnings(
    { setState }: StateContext<DueListStateModel>,
    { request }: PrintDueListWarnings
  ) {
    return this.service.print(request).pipe(
      tap((file) => {
        setState(patch({ currentPrintedDueListWarnings: file }));
      })
    );
  }

  @Selector()
  static getDueListSummaryNumber(state: DueListStateModel): number {
    const warningsAmount = state.warnings?.length;
    const dueCompetenciesAmount = state.dueCompetencies?.length;
    const summary = warningsAmount + dueCompetenciesAmount;
    if (summary > 0) {
      return summary;
    }
    return state.summaryOfWarnings;
  }

  @Selector()
  static getWarnings(state: DueListStateModel): IDueListItem[] {
    return state.warnings;
  }

  @Selector()
  static getAllWarnings(state: DueListStateModel): IDueListItem[] {
    return state.allWarnings;
  }

  @Selector()
  static getTotalPagesForWarnings(state: DueListStateModel): number {
    return state.warningsTotalPages;
  }

  @Selector()
  static getTotalPagesForCompetences(state: DueListStateModel): number {
    return state.competencesTotalPages;
  }

  @Selector()
  static getTotalPagesForMyWarnings(state: DueListStateModel): number {
    return state.myWarningsTotalPages;
  }

  @Selector()
  static getDueCompetencies(state: DueListStateModel): IDueListItem[] {
    return state.dueCompetencies;
  }

  @Selector()
  static getAllDueCompetencies(state: DueListStateModel): IDueListItem[] {
    return state.allDueCompetencies;
  }

  @Selector()
  static getCurrentPrintedDueListWarnings(state: DueListStateModel) {
    return state.currentPrintedDueListWarnings;
  }
}
