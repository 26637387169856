import { Component, Input } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { FormsResponsesRoutes, AdminUsersRoutes, RoutesEnum } from 'src/app/core/enums/routes.enum';
import { UserCompetenceRequirementType } from 'src/app/shared/enums/enums';
import { CompetenceStatusEnum } from 'src/app/shared/user-view/models/competence-user.model';

@Component({
  selector: 'app-users-template',
  templateUrl: './users-template.component.html',
  styleUrls: ['./users-template.component.scss'],
})
export class UsersTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: () => void };
  @Input() urlForRouting: string;

  adminUsersRoutes = AdminUsersRoutes;
  userCompetenceRequirementType = UserCompetenceRequirementType;
  competenceStatusEnum = CompetenceStatusEnum;
  userId = '';

  constructor(private router: Router) {}

  routeToCustomForm(competenceId: string, formId: string): void {
    const link = FormsResponsesRoutes.List.replace(':id', competenceId);
    this.router.navigate([link], {
      queryParams: { moduleName: RoutesEnum.Users, formId },
    });
  }

  toRenewCompetence() {
    this.getUserId(this.router.routerState.snapshot.root);
    this.router.navigateByUrl(
      `${
        this.element.isRegistrationCompleted
          ? this.adminUsersRoutes.CompetenceUserRenew
          : this.adminUsersRoutes.CompetenceUserRegister
      }${this.userId}/${this.element.competenceId}`
    );
  }
  private getUserId(activatedRouteSnap: ActivatedRouteSnapshot) {
    const userId = activatedRouteSnap.params.id;
    if (userId) {
      this.userId = userId;
      return;
    }
    activatedRouteSnap.children.forEach((childRoute) => {
      if (childRoute.params.id) {
        this.userId = childRoute.params.id;
        return;
      }
      if (!this.userId) {
        this.getUserId(childRoute);
      }
    });
  }
}
