import { getTenantId } from 'src/app/services/store-snapshot.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDepartment } from 'src/app/department/models/department.model';
import { environment } from 'src/environments/environment';
import { IDepartmentOptions } from 'src/app/shared/user-view/models/user-options.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentOptionsService {
  // TODO: fix "depaTment" typo in BE & FE
  url = `${environment.apiUrl}/api/Options/DepatmentOptionTypes`;

  constructor(private http: HttpClient) {}

  getDepartmentOptions(): Observable<IDepartmentOptions> {
    return this.http.get<IDepartmentOptions>(this.url);
  }

  getDepartmentList(): Observable<IDepartment[]> {
    const departmentListUrl = `${environment.apiUrl}/api/${getTenantId()}/departments/list`;
    return this.http.get<IDepartment[]>(departmentListUrl);
  }
}
