import { IdName } from 'src/app/shared/models/id-name';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchableIconsService {
  private allMaterialIcons = [
    { id: 'search', name: 'search' },
    { id: 'home', name: 'home' },
    { id: 'account_circle', name: 'account_circle' },
    { id: 'settings', name: 'settings' },
    { id: 'done', name: 'done' },
    { id: 'info', name: 'info' },
    { id: 'check_circle', name: 'check_circle' },
    { id: 'delete', name: 'delete' },
    { id: 'shopping_cart', name: 'shopping_cart' },
    { id: 'visibility', name: 'visibility' },
    { id: 'favorite', name: 'favorite' },
    { id: 'logout', name: 'logout' },
    { id: 'description', name: 'description' },
    { id: 'favorite_border', name: 'favorite_border' },
    { id: 'lock', name: 'lock' },
    { id: 'schedule', name: 'schedule' },
    { id: 'language', name: 'language' },
    { id: 'face', name: 'face' },
    { id: 'help_outline', name: 'help_outline' },
    { id: 'manage_accounts', name: 'manage_accounts' },
    { id: 'filter_alt', name: 'filter_alt' },
    { id: 'fingerprint', name: 'fingerprint' },
    { id: 'event', name: 'event' },
    { id: 'verified', name: 'verified' },
    { id: 'thumb_up', name: 'thumb_up' },
    { id: 'dashboard', name: 'dashboard' },
    { id: 'login', name: 'login' },
    { id: 'calendar_today', name: 'calendar_today' },
    { id: 'visibility_off', name: 'visibility_off' },
    { id: 'list', name: 'list' },
    { id: 'check_circle_outline', name: 'check_circle_outline' },
    { id: 'highlight_off', name: 'highlight_off' },
    { id: 'date_range', name: 'date_range' },
    { id: 'help', name: 'help' },
    { id: 'question_answer', name: 'question_answer' },
    { id: 'task_alt', name: 'task_alt' },
    { id: 'paid', name: 'paid' },
    { id: 'article', name: 'article' },
    { id: 'shopping_bag', name: 'shopping_bag' },
    { id: 'lightbulb', name: 'lightbulb' },
    { id: 'open_in_new', name: 'open_in_new' },
    { id: 'perm_identity', name: 'perm_identity' },
    { id: 'trending_up', name: 'trending_up' },
    { id: 'credit_card', name: 'credit_card' },
    { id: 'history', name: 'history' },
    { id: 'account_balance', name: 'account_balance' },
    { id: 'delete_outline', name: 'delete_outline' },
    { id: 'report_problem', name: 'report_problem' },
    { id: 'fact_check', name: 'fact_check' },
    { id: 'assignment', name: 'assignment' },
    { id: 'verified_user', name: 'verified_user' },
    { id: 'arrow_right_alt', name: 'arrow_right_alt' },
    { id: 'star_rate', name: 'star_rate' },
    { id: 'account_balance_wallet', name: 'account_balance_wallet' },
    { id: 'analytics', name: 'analytics' },
    { id: 'autorenew', name: 'autorenew' },
    { id: 'work', name: 'work' },
    { id: 'build', name: 'build' },
    { id: 'view_list', name: 'view_list' },
    { id: 'print', name: 'print' },
    { id: 'store', name: 'store' },
    { id: 'today', name: 'today' },
    { id: 'delete_forever', name: 'delete_forever' },
    { id: 'admin_panel_settings', name: 'admin_panel_settings' },
    { id: 'lock_open', name: 'lock_open' },
    { id: 'savings', name: 'savings' },
    { id: 'room', name: 'room' },
    { id: 'code', name: 'code' },
    { id: 'grade', name: 'grade' },
    { id: 'watch_later', name: 'watch_later' },
    { id: 'receipt', name: 'receipt' },
    { id: 'update', name: 'update' },
    { id: 'add_shopping_cart', name: 'add_shopping_cart' },
    { id: 'contact_support', name: 'contact_support' },
    { id: 'power_settings_new', name: 'power_settings_new' },
    { id: 'pets', name: 'pets' },
    { id: 'done_all', name: 'done_all' },
    { id: 'explore', name: 'explore' },
    { id: 'bookmark', name: 'bookmark' },
    { id: 'bookmark_border', name: 'bookmark_border' },
    { id: 'note_add', name: 'note_add' },
    { id: 'reorder', name: 'reorder' },
    { id: 'account_box', name: 'account_box' },
    { id: 'shopping_basket', name: 'shopping_basket' },
    { id: 'pending_actions', name: 'pending_actions' },
    { id: 'drag_indicator', name: 'drag_indicator' },
    { id: 'payment', name: 'payment' },
    { id: 'launch', name: 'launch' },
    { id: 'supervisor_account', name: 'supervisor_account' },
    { id: 'pending', name: 'pending' },
    { id: 'zoom_in', name: 'zoom_in' },
    { id: 'touch_app', name: 'touch_app' },
    { id: 'assessment', name: 'assessment' },
    { id: 'calendar_month', name: 'calendar_month' },
    { id: 'thumb_up_off_alt', name: 'thumb_up_off_alt' },
    { id: 'open_in_full', name: 'open_in_full' },
    { id: 'leaderboard', name: 'leaderboard' },
    { id: 'done_outline', name: 'done_outline' },
    { id: 'exit_to_app', name: 'exit_to_app' },
    { id: 'preview', name: 'preview' },
    { id: 'assignment_ind', name: 'assignment_ind' },
    { id: 'card_giftcard', name: 'card_giftcard' },
    { id: 'view_in_ar', name: 'view_in_ar' },
    { id: 'work_outline', name: 'work_outline' },
    { id: 'feedback', name: 'feedback' },
    { id: 'published_with_changes', name: 'published_with_changes' },
    { id: 'timeline', name: 'timeline' },
    { id: 'dns', name: 'dns' },
    { id: 'swap_horiz', name: 'swap_horiz' },
    { id: 'assignment_turned_in', name: 'assignment_turned_in' },
    { id: 'sync_alt', name: 'sync_alt' },
    { id: 'flight_takeoff', name: 'flight_takeoff' },
    { id: 'book', name: 'book' },
    { id: 'stars', name: 'stars' },
    { id: 'label', name: 'label' },
    { id: 'pan_tool', name: 'pan_tool' },
    { id: 'bug_report', name: 'bug_report' },
    { id: 'contact_page', name: 'contact_page' },
    { id: 'gavel', name: 'gavel' },
    { id: 'cached', name: 'cached' },
    { id: 'translate', name: 'translate' },
    { id: 'alarm', name: 'alarm' },
    { id: 'space_dashboard', name: 'space_dashboard' },
    { id: 'android', name: 'android' },
    { id: 'supervised_user_circle', name: 'supervised_user_circle' },
    { id: 'accessibility', name: 'accessibility' },
    { id: 'edit_calendar', name: 'edit_calendar' },
    { id: 'minimize', name: 'minimize' },
    { id: 'extension', name: 'extension' },
    { id: 'get_app', name: 'get_app' },
    { id: 'tips_and_updates', name: 'tips_and_updates' },
    { id: 'record_voice_over', name: 'record_voice_over' },
    { id: 'add_task', name: 'add_task' },
    { id: 'trending_flat', name: 'trending_flat' },
    { id: 'hourglass_empty', name: 'hourglass_empty' },
    { id: 'help_center', name: 'help_center' },
    { id: 'thumb_down', name: 'thumb_down' },
    { id: 'accessibility_new', name: 'accessibility_new' },
    { id: 'sticky_note_2', name: 'sticky_note_2' },
    { id: 'flutter_dash', name: 'flutter_dash' },
    { id: 'rule', name: 'rule' },
    { id: 'dashboard_customize', name: 'dashboard_customize' },
    { id: 'support', name: 'support' },
    { id: 'source', name: 'source' },
    { id: 'find_in_page', name: 'find_in_page' },
    { id: 'close_fullscreen', name: 'close_fullscreen' },
    { id: 'settings_applications', name: 'settings_applications' },
    { id: 'view_headline', name: 'view_headline' },
    { id: 'redeem', name: 'redeem' },
    { id: 'announcement', name: 'announcement' },
    { id: 'loyalty', name: 'loyalty' },
    { id: 'group_work', name: 'group_work' },
    { id: 'ads_click', name: 'ads_click' },
    { id: 'swap_vert', name: 'swap_vert' },
    { id: 'restore', name: 'restore' },
    { id: 'euro_symbol', name: 'euro_symbol' },
    { id: 'sensors', name: 'sensors' },
    { id: 'dangerous', name: 'dangerous' },
    { id: 'compare_arrows', name: 'compare_arrows' },
    { id: 'nightlight_round', name: 'nightlight_round' },
    { id: 'privacy_tip', name: 'privacy_tip' },
    { id: 'subject', name: 'subject' },
    { id: 'rocket_launch', name: 'rocket_launch' },
    { id: 'arrow_circle_right', name: 'arrow_circle_right' },
    { id: 'question_mark', name: 'question_mark' },
    { id: 'disabled_by_default', name: 'disabled_by_default' },
    { id: 'track_changes', name: 'track_changes' },
    { id: 'grading', name: 'grading' },
    { id: 'https', name: 'https' },
    { id: 'table_view', name: 'table_view' },
    { id: 'copyright', name: 'copyright' },
    { id: 'toc', name: 'toc' },
    { id: 'arrow_circle_up', name: 'arrow_circle_up' },
    { id: 'bookmarks', name: 'bookmarks' },
    { id: 'api', name: 'api' },
    { id: 'query_builder', name: 'query_builder' },
    { id: 'perm_media', name: 'perm_media' },
    { id: 'build_circle', name: 'build_circle' },
    { id: 'input', name: 'input' },
    { id: 'book_online', name: 'book_online' },
    { id: 'zoom_out', name: 'zoom_out' },
    { id: 'backup', name: 'backup' },
    { id: 'perm_contact_calendar', name: 'perm_contact_calendar' },
    { id: 'open_with', name: 'open_with' },
    { id: 'view_module', name: 'view_module' },
    { id: 'settings_phone', name: 'settings_phone' },
    { id: 'circle_notifications', name: 'circle_notifications' },
    { id: '3d_rotation', name: '3d_rotation' },
    { id: 'speaker_notes', name: 'speaker_notes' },
    { id: 'arrow_circle_down', name: 'arrow_circle_down' },
    { id: 'label_important', name: 'label_important' },
    { id: 'card_membership', name: 'card_membership' },
    { id: 'perm_phone_msg', name: 'perm_phone_msg' },
    { id: 'file_present', name: 'file_present' },
    { id: 'wysiwyg', name: 'wysiwyg' },
    { id: 'swipe', name: 'swipe' },
    { id: 'pageview', name: 'pageview' },
    { id: 'integration_instructions', name: 'integration_instructions' },
    { id: 'upgrade', name: 'upgrade' },
    { id: 'trending_down', name: 'trending_down' },
    { id: 'change_history', name: 'change_history' },
    { id: 'class', name: 'class' },
    { id: 'accessible', name: 'accessible' },
    { id: 'g_translate', name: 'g_translate' },
    { id: 'settings_accessibility', name: 'settings_accessibility' },
    { id: 'production_quantity_limits', name: 'production_quantity_limits' },
    { id: 'offline_bolt', name: 'offline_bolt' },
    { id: 'expand', name: 'expand' },
    { id: 'model_training', name: 'model_training' },
    { id: 'aspect_ratio', name: 'aspect_ratio' },
    { id: 'donut_large', name: 'donut_large' },
    { id: 'calendar_view_month', name: 'calendar_view_month' },
    { id: 'settings_backup_restore', name: 'settings_backup_restore' },
    { id: 'segment', name: 'segment' },
    { id: 'maximize', name: 'maximize' },
    { id: 'schedule_send', name: 'schedule_send' },
    { id: 'view_column', name: 'view_column' },
    { id: 'bookmark_add', name: 'bookmark_add' },
    { id: 'arrow_circle_left', name: 'arrow_circle_left' },
    { id: 'alarm_on', name: 'alarm_on' },
    { id: 'thumbs_up_down', name: 'thumbs_up_down' },
    { id: 'thumb_down_off_alt', name: 'thumb_down_off_alt' },
    { id: 'percent', name: 'percent' },
    { id: 'view_agenda', name: 'view_agenda' },
    { id: 'settings_ethernet', name: 'settings_ethernet' },
    { id: 'theaters', name: 'theaters' },
    { id: 'important_devices', name: 'important_devices' },
    { id: 'invert_colors', name: 'invert_colors' },
    { id: 'unpublished', name: 'unpublished' },
    { id: 'open_in_browser', name: 'open_in_browser' },
    { id: 'currency_exchange', name: 'currency_exchange' },
    { id: 'commute', name: 'commute' },
    { id: 'opacity', name: 'opacity' },
    { id: 'addchart', name: 'addchart' },
    { id: 'no_accounts', name: 'no_accounts' },
    { id: 'youtube_searched_for', name: 'youtube_searched_for' },
    { id: 'history_toggle_off', name: 'history_toggle_off' },
    { id: 'view_week', name: 'view_week' },
    { id: 'tour', name: 'tour' },
    { id: 'system_update_alt', name: 'system_update_alt' },
    { id: 'settings_input_antenna', name: 'settings_input_antenna' },
    { id: 'shop', name: 'shop' },
    { id: 'turned_in', name: 'turned_in' },
    { id: 'mark_as_unread', name: 'mark_as_unread' },
    { id: 'bookmark_added', name: 'bookmark_added' },
    { id: 'hide_source', name: 'hide_source' },
    { id: 'plagiarism', name: 'plagiarism' },
    { id: 'flight_land', name: 'flight_land' },
    { id: 'search_off', name: 'search_off' },
    { id: 'not_started', name: 'not_started' },
    { id: 'saved_search', name: 'saved_search' },
    { id: 'contactless', name: 'contactless' },
    { id: 'assignment_late', name: 'assignment_late' },
    { id: 'donut_small', name: 'donut_small' },
    { id: 'highlight_alt', name: 'highlight_alt' },
    { id: 'view_carousel', name: 'view_carousel' },
    { id: 'anchor', name: 'anchor' },
    { id: 'settings_input_component', name: 'settings_input_component' },
    { id: 'turned_in_not', name: 'turned_in_not' },
    { id: 'mediation', name: 'mediation' },
    { id: 'fit_screen', name: 'fit_screen' },
    { id: 'toll', name: 'toll' },
    { id: 'edit_off', name: 'edit_off' },
    { id: 'flaky', name: 'flaky' },
    { id: 'settings_remote', name: 'settings_remote' },
    { id: 'assignment_return', name: 'assignment_return' },
    { id: 'view_quilt', name: 'view_quilt' },
    { id: 'remove_shopping_cart', name: 'remove_shopping_cart' },
    { id: 'all_inbox', name: 'all_inbox' },
    { id: 'hourglass_full', name: 'hourglass_full' },
    { id: 'lock_clock', name: 'lock_clock' },
    { id: 'settings_voice', name: 'settings_voice' },
    { id: 'online_prediction', name: 'online_prediction' },
    { id: 'pregnant_woman', name: 'pregnant_woman' },
    { id: 'swap_horizontal_circle', name: 'swap_horizontal_circle' },
    { id: 'hotel_class', name: 'hotel_class' },
    { id: 'event_seat', name: 'event_seat' },
    { id: 'next_plan', name: 'next_plan' },
    { id: 'view_sidebar', name: 'view_sidebar' },
    { id: 'camera_enhance', name: 'camera_enhance' },
    { id: 'markunread_mailbox', name: 'markunread_mailbox' },
    { id: 'restore_from_trash', name: 'restore_from_trash' },
    { id: 'vertical_split', name: 'vertical_split' },
    { id: 'dynamic_form', name: 'dynamic_form' },
    { id: 'calendar_view_week', name: 'calendar_view_week' },
    { id: 'accessible_forward', name: 'accessible_forward' },
    { id: 'tab', name: 'tab' },
    { id: 'balance', name: 'balance' },
    { id: 'filter_alt_off', name: 'filter_alt_off' },
    { id: 'add_to_drive', name: 'add_to_drive' },
    { id: 'data_exploration', name: 'data_exploration' },
    { id: 'shopping_cart_checkout', name: 'shopping_cart_checkout' },
    { id: 'settings_power', name: 'settings_power' },
    { id: 'view_stream', name: 'view_stream' },
    { id: 'remove_done', name: 'remove_done' },
    { id: 'smart_button', name: 'smart_button' },
    { id: 'request_page', name: 'request_page' },
    { id: 'card_travel', name: 'card_travel' },
    { id: 'try', name: 'try' },
    { id: 'outbox', name: 'outbox' },
    { id: 'offline_pin', name: 'offline_pin' },
    { id: 'rowing', name: 'rowing' },
    { id: 'calendar_view_day', name: 'calendar_view_day' },
    { id: 'outlet', name: 'outlet' },
    { id: 'find_replace', name: 'find_replace' },
    { id: 'compress', name: 'compress' },
    { id: 'rocket', name: 'rocket' },
    { id: 'chrome_reader_mode', name: 'chrome_reader_mode' },
    { id: 'token', name: 'token' },
    { id: 'settings_brightness', name: 'settings_brightness' },
    { id: 'http', name: 'http' },
    { id: 'alarm_add', name: 'alarm_add' },
    { id: 'assignment_returned', name: 'assignment_returned' },
    { id: 'spellcheck', name: 'spellcheck' },
    { id: 'play_for_work', name: 'play_for_work' },
    { id: 'gif', name: 'gif' },
    { id: 'backup_table', name: 'backup_table' },
    { id: 'restore_page', name: 'restore_page' },
    { id: 'credit_card_off', name: 'credit_card_off' },
    { id: 'wifi_protected_setup', name: 'wifi_protected_setup' },
    { id: 'settings_overscan', name: 'settings_overscan' },
    { id: 'new_label', name: 'new_label' },
    { id: 'lock_reset', name: 'lock_reset' },
    { id: 'fax', name: 'fax' },
    { id: 'settings_input_composite', name: 'settings_input_composite' },
    { id: 'generating_tokens', name: 'generating_tokens' },
    { id: 'quickreply', name: 'quickreply' },
    { id: 'comment_bank', name: 'comment_bank' },
    { id: 'view_day', name: 'view_day' },
    { id: 'terminal', name: 'terminal' },
    { id: 'swap_vertical_circle', name: 'swap_vertical_circle' },
    { id: 'polymer', name: 'polymer' },
    { id: 'outbound', name: 'outbound' },
    { id: 'line_weight', name: 'line_weight' },
    { id: 'cancel_schedule_send', name: 'cancel_schedule_send' },
    { id: 'horizontal_split', name: 'horizontal_split' },
    { id: 'batch_prediction', name: 'batch_prediction' },
    { id: 'send_and_archive', name: 'send_and_archive' },
    { id: 'picture_in_picture', name: 'picture_in_picture' },
    { id: 'free_cancellation', name: 'free_cancellation' },
    { id: 'density_medium', name: 'density_medium' },
    { id: 'code_off', name: 'code_off' },
    { id: 'settings_bluetooth', name: 'settings_bluetooth' },
    { id: 'bookmark_remove', name: 'bookmark_remove' },
    { id: 'disabled_visible', name: 'disabled_visible' },
    { id: 'switch_access_shortcut', name: 'switch_access_shortcut' },
    { id: 'flip_to_front', name: 'flip_to_front' },
    { id: 'shop_two', name: 'shop_two' },
    { id: 'gif_box', name: 'gif_box' },
    { id: 'add_card', name: 'add_card' },
    { id: 'eject', name: 'eject' },
    { id: 'speaker_notes_off', name: 'speaker_notes_off' },
    { id: 'satellite_alt', name: 'satellite_alt' },
    { id: 'perm_data_setting', name: 'perm_data_setting' },
    { id: 'view_timeline', name: 'view_timeline' },
    { id: 'settings_input_hdmi', name: 'settings_input_hdmi' },
    { id: 'perm_scan_wifi', name: 'perm_scan_wifi' },
    { id: 'alarm_off', name: 'alarm_off' },
    { id: 'settings_cell', name: 'settings_cell' },
    { id: 'hourglass_disabled', name: 'hourglass_disabled' },
    { id: 'app_blocking', name: 'app_blocking' },
    { id: 'sensors_off', name: 'sensors_off' },
    { id: 'work_off', name: 'work_off' },
    { id: 'line_style', name: 'line_style' },
    { id: 'webhook', name: 'webhook' },
    { id: 'perm_device_information', name: 'perm_device_information' },
    { id: 'picture_in_picture_alt', name: 'picture_in_picture_alt' },
    { id: 'subtitles_off', name: 'subtitles_off' },
    { id: 'assured_workload', name: 'assured_workload' },
    { id: 'html', name: 'html' },
    { id: '123', name: '123' },
    { id: 'switch_access_shortcut_add', name: 'switch_access_shortcut_add' },
    { id: 'display_settings', name: 'display_settings' },
    { id: 'update_disabled', name: 'update_disabled' },
    { id: 'event_repeat', name: 'event_repeat' },
    { id: 'settings_input_svideo', name: 'settings_input_svideo' },
    { id: 'voice_over_off', name: 'voice_over_off' },
    { id: 'output', name: 'output' },
    { id: 'view_array', name: 'view_array' },
    { id: 'flip_to_back', name: 'flip_to_back' },
    { id: 'open_in_new_off', name: 'open_in_new_off' },
    { id: 'swipe_left', name: 'swipe_left' },
    { id: 'noise_control_off', name: 'noise_control_off' },
    { id: 'all_out', name: 'all_out' },
    { id: 'javascript', name: 'javascript' },
    { id: 'private_connectivity', name: 'private_connectivity' },
    { id: 'work_history', name: 'work_history' },
    { id: 'app_shortcut', name: 'app_shortcut' },
    { id: 'label_off', name: 'label_off' },
    { id: 'rounded_corner', name: 'rounded_corner' },
    { id: 'join_full', name: 'join_full' },
    { id: 'tab_unselected', name: 'tab_unselected' },
    { id: 'shop_2', name: 'shop_2' },
    { id: 'text_rotate_vertical', name: 'text_rotate_vertical' },
    { id: 'swipe_right', name: 'swipe_right' },
    { id: 'explore_off', name: 'explore_off' },
    { id: 'pan_tool_alt', name: 'pan_tool_alt' },
    { id: 'install_desktop', name: 'install_desktop' },
    { id: 'abc', name: 'abc' },
    { id: 'text_rotation_none', name: 'text_rotation_none' },
    { id: 'swipe_up', name: 'swipe_up' },
    { id: 'not_accessible', name: 'not_accessible' },
    { id: 'pin_invoke', name: 'pin_invoke' },
    { id: 'join_inner', name: 'join_inner' },
    { id: 'perm_camera_mic', name: 'perm_camera_mic' },
    { id: 'manage_history', name: 'manage_history' },
    { id: 'extension_off', name: 'extension_off' },
    { id: 'density_small', name: 'density_small' },
    { id: 'data_thresholding', name: 'data_thresholding' },
    { id: 'lightbulb_circle', name: 'lightbulb_circle' },
    { id: 'pin_end', name: 'pin_end' },
    { id: 'install_mobile', name: 'install_mobile' },
    { id: 'commit', name: 'commit' },
    { id: 'swipe_down', name: 'swipe_down' },
    { id: 'text_rotate_up', name: 'text_rotate_up' },
    { id: 'css', name: 'css' },
    { id: 'text_rotation_down', name: 'text_rotation_down' },
    { id: 'join_left', name: 'join_left' },
    { id: 'text_rotation_angledown', name: 'text_rotation_angledown' },
    { id: 'view_kanban', name: 'view_kanban' },
    { id: 'text_rotation_angleup', name: 'text_rotation_angleup' },
    { id: 'pinch', name: 'pinch' },
    { id: 'swipe_vertical', name: 'swipe_vertical' },
    { id: 'view_cozy', name: 'view_cozy' },
    { id: 'join_right', name: 'join_right' },
    { id: 'swipe_right_alt', name: 'swipe_right_alt' },
    { id: 'view_comfy_alt', name: 'view_comfy_alt' },
    { id: 'spatial_audio_off', name: 'spatial_audio_off' },
    { id: 'noise_aware', name: 'noise_aware' },
    { id: 'php', name: 'php' },
    { id: 'view_compact_alt', name: 'view_compact_alt' },
    { id: 'swipe_down_alt', name: 'swipe_down_alt' },
    { id: 'browse_gallery', name: 'browse_gallery' },
    { id: 'swipe_left_alt', name: 'swipe_left_alt' },
    { id: 'network_ping', name: 'network_ping' },
    { id: 'swipe_up_alt', name: 'swipe_up_alt' },
    { id: 'density_large', name: 'density_large' },
    { id: 'lock_person', name: 'lock_person' },
    { id: 'on_device_training', name: 'on_device_training' },
    { id: 'spatial_tracking', name: 'spatial_tracking' },
    { id: 'spatial_audio', name: 'spatial_audio' },
    { id: 'hls', name: 'hls' },
    { id: 'troubleshoot', name: 'troubleshoot' },
    { id: 'hls_off', name: 'hls_off' },
    { id: 'width_normal', name: 'width_normal' },
    { id: 'width_full', name: 'width_full' },
    { id: 'width_wide', name: 'width_wide' },
    { id: 'face_unlock', name: 'face_unlock' },
    { id: 'arrow_outward', name: 'arrow_outward' },
    { id: 'add_home', name: 'add_home' },
    { id: 'unfold_more_double', name: 'unfold_more_double' },
    { id: 'repartition', name: 'repartition' },
    { id: 'unfold_less_double', name: 'unfold_less_double' },
    { id: 'transcribe', name: 'transcribe' },
    { id: 'warning', name: 'warning' },
    { id: 'error', name: 'error' },
    { id: 'error_outline', name: 'error_outline' },
    { id: 'warning_amber', name: 'warning_amber' },
    { id: 'notification_important', name: 'notification_important' },
    { id: 'add_alert', name: 'add_alert' },
    { id: 'auto_delete', name: 'auto_delete' },
    { id: 'play_arrow', name: 'play_arrow' },
    { id: 'play_circle_filled', name: 'play_circle_filled' },
    { id: 'videocam', name: 'videocam' },
    { id: 'play_circle', name: 'play_circle' },
    { id: 'mic', name: 'mic' },
    { id: 'volume_up', name: 'volume_up' },
    { id: 'pause', name: 'pause' },
    { id: 'play_circle_outline', name: 'play_circle_outline' },
    { id: 'volume_off', name: 'volume_off' },
    { id: 'replay', name: 'replay' },
    { id: 'skip_next', name: 'skip_next' },
    { id: 'library_books', name: 'library_books' },
    { id: 'speed', name: 'speed' },
    { id: 'stop', name: 'stop' },
    { id: 'fiber_manual_record', name: 'fiber_manual_record' },
    { id: 'movie', name: 'movie' },
    { id: 'skip_previous', name: 'skip_previous' },
    { id: 'playlist_add', name: 'playlist_add' },
    { id: 'new_releases', name: 'new_releases' },
    { id: 'equalizer', name: 'equalizer' },
    { id: 'loop', name: 'loop' },
    { id: 'fast_forward', name: 'fast_forward' },
    { id: 'web', name: 'web' },
    { id: 'video_library', name: 'video_library' },
    { id: 'playlist_add_check', name: 'playlist_add_check' },
    { id: 'mic_off', name: 'mic_off' },
    { id: 'library_add', name: 'library_add' },
    { id: 'video_call', name: 'video_call' },
    { id: 'pause_circle', name: 'pause_circle' },
    { id: 'subscriptions', name: 'subscriptions' },
    { id: 'repeat', name: 'repeat' },
    { id: 'stop_circle', name: 'stop_circle' },
    { id: 'volume_mute', name: 'volume_mute' },
    { id: 'shuffle', name: 'shuffle' },
    { id: 'not_interested', name: 'not_interested' },
    { id: 'sort_by_alpha', name: 'sort_by_alpha' },
    { id: 'mic_none', name: 'mic_none' },
    { id: 'library_music', name: 'library_music' },
    { id: 'fast_rewind', name: 'fast_rewind' },
    { id: 'videocam_off', name: 'videocam_off' },
    { id: 'volume_down', name: 'volume_down' },
    { id: 'queue_music', name: 'queue_music' },
    { id: 'recent_actors', name: 'recent_actors' },
    { id: 'web_asset', name: 'web_asset' },
    { id: 'pause_circle_filled', name: 'pause_circle_filled' },
    { id: 'hearing', name: 'hearing' },
    { id: 'library_add_check', name: 'library_add_check' },
    { id: 'subtitles', name: 'subtitles' },
    { id: 'album', name: 'album' },
    { id: 'pause_circle_outline', name: 'pause_circle_outline' },
    { id: 'fiber_new', name: 'fiber_new' },
    { id: 'note', name: 'note' },
    { id: 'av_timer', name: 'av_timer' },
    { id: 'radio', name: 'radio' },
    { id: 'games', name: 'games' },
    { id: 'playlist_play', name: 'playlist_play' },
    { id: 'branding_watermark', name: 'branding_watermark' },
    { id: 'replay_circle_filled', name: 'replay_circle_filled' },
    { id: 'queue', name: 'queue' },
    { id: 'forward_10', name: 'forward_10' },
    { id: 'replay_10', name: 'replay_10' },
    { id: 'closed_caption', name: 'closed_caption' },
    { id: 'video_settings', name: 'video_settings' },
    { id: 'featured_play_list', name: 'featured_play_list' },
    { id: 'control_camera', name: 'control_camera' },
    { id: 'playlist_add_check_circle', name: 'playlist_add_check_circle' },
    { id: 'add_to_queue', name: 'add_to_queue' },
    { id: 'slow_motion_video', name: 'slow_motion_video' },
    { id: 'airplay', name: 'airplay' },
    { id: 'repeat_one', name: 'repeat_one' },
    { id: 'call_to_action', name: 'call_to_action' },
    { id: 'snooze', name: 'snooze' },
    { id: 'hd', name: 'hd' },
    { id: 'high_quality', name: 'high_quality' },
    { id: 'repeat_on', name: 'repeat_on' },
    { id: 'closed_caption_off', name: 'closed_caption_off' },
    { id: 'replay_30', name: 'replay_30' },
    { id: 'featured_video', name: 'featured_video' },
    { id: 'playlist_remove', name: 'playlist_remove' },
    { id: '5g', name: '5g' },
    { id: 'shuffle_on', name: 'shuffle_on' },
    { id: 'forward_30', name: 'forward_30' },
    { id: 'music_video', name: 'music_video' },
    { id: 'replay_5', name: 'replay_5' },
    { id: 'queue_play_next', name: 'queue_play_next' },
    { id: 'art_track', name: 'art_track' },
    { id: 'playlist_add_circle', name: 'playlist_add_circle' },
    { id: 'hearing_disabled', name: 'hearing_disabled' },
    { id: 'forward_5', name: 'forward_5' },
    { id: 'explicit', name: 'explicit' },
    { id: '4k', name: '4k' },
    { id: 'fiber_smart_record', name: 'fiber_smart_record' },
    { id: 'video_label', name: 'video_label' },
    { id: 'audio_file', name: 'audio_file' },
    { id: 'interpreter_mode', name: 'interpreter_mode' },
    { id: 'video_file', name: 'video_file' },
    { id: 'repeat_one_on', name: 'repeat_one_on' },
    { id: 'closed_caption_disabled', name: 'closed_caption_disabled' },
    { id: 'surround_sound', name: 'surround_sound' },
    { id: 'remove_from_queue', name: 'remove_from_queue' },
    { id: 'play_disabled', name: 'play_disabled' },
    { id: 'web_asset_off', name: 'web_asset_off' },
    { id: 'sd', name: 'sd' },
    { id: 'missed_video_call', name: 'missed_video_call' },
    { id: '10k', name: '10k' },
    { id: 'fiber_pin', name: 'fiber_pin' },
    { id: 'fiber_dvr', name: 'fiber_dvr' },
    { id: '4k_plus', name: '4k_plus' },
    { id: '1k', name: '1k' },
    { id: '2k', name: '2k' },
    { id: '1k_plus', name: '1k_plus' },
    { id: '8k', name: '8k' },
    { id: 'lyrics', name: 'lyrics' },
    { id: '9k_plus', name: '9k_plus' },
    { id: '8k_plus', name: '8k_plus' },
    { id: '5k', name: '5k' },
    { id: '3k', name: '3k' },
    { id: '5k_plus', name: '5k_plus' },
    { id: '7k', name: '7k' },
    { id: '2k_plus', name: '2k_plus' },
    { id: '3k_plus', name: '3k_plus' },
    { id: '9k', name: '9k' },
    { id: '6k', name: '6k' },
    { id: '6k_plus', name: '6k_plus' },
    { id: '7k_plus', name: '7k_plus' },
    { id: 'email', name: 'email' },
    { id: 'location_on', name: 'location_on' },
    { id: 'call', name: 'call' },
    { id: 'phone', name: 'phone' },
    { id: 'business', name: 'business' },
    { id: 'chat', name: 'chat' },
    { id: 'mail_outline', name: 'mail_outline' },
    { id: 'list_alt', name: 'list_alt' },
    { id: 'vpn_key', name: 'vpn_key' },
    { id: 'qr_code_scanner', name: 'qr_code_scanner' },
    { id: 'chat_bubble_outline', name: 'chat_bubble_outline' },
    { id: 'alternate_email', name: 'alternate_email' },
    { id: 'forum', name: 'forum' },
    { id: 'chat_bubble', name: 'chat_bubble' },
    { id: 'textsms', name: 'textsms' },
    { id: 'contact_mail', name: 'contact_mail' },
    { id: 'person_search', name: 'person_search' },
    { id: 'qr_code_2', name: 'qr_code_2' },
    { id: 'qr_code', name: 'qr_code' },
    { id: 'sentiment_satisfied_alt', name: 'sentiment_satisfied_alt' },
    { id: 'message', name: 'message' },
    { id: 'comment', name: 'comment' },
    { id: 'contacts', name: 'contacts' },
    { id: 'app_registration', name: 'app_registration' },
    { id: 'import_contacts', name: 'import_contacts' },
    { id: 'contact_phone', name: 'contact_phone' },
    { id: 'live_help', name: 'live_help' },
    { id: 'import_export', name: 'import_export' },
    { id: 'forward_to_inbox', name: 'forward_to_inbox' },
    { id: 'key', name: 'key' },
    { id: 'rss_feed', name: 'rss_feed' },
    { id: 'hourglass_bottom', name: 'hourglass_bottom' },
    { id: 'hourglass_top', name: 'hourglass_top' },
    { id: 'mark_email_read', name: 'mark_email_read' },
    { id: 'read_more', name: 'read_more' },
    { id: 'mark_email_unread', name: 'mark_email_unread' },
    { id: 'call_end', name: 'call_end' },
    { id: 'more_time', name: 'more_time' },
    { id: 'document_scanner', name: 'document_scanner' },
    { id: 'clear_all', name: 'clear_all' },
    { id: 'hub', name: 'hub' },
    { id: 'dialpad', name: 'dialpad' },
    { id: 'phone_enabled', name: 'phone_enabled' },
    { id: 'mark_chat_unread', name: 'mark_chat_unread' },
    { id: '3p', name: '3p' },
    { id: 'cancel_presentation', name: 'cancel_presentation' },
    { id: 'screen_share', name: 'screen_share' },
    { id: 'call_made', name: 'call_made' },
    { id: 'call_split', name: 'call_split' },
    { id: 'unsubscribe', name: 'unsubscribe' },
    { id: 'present_to_all', name: 'present_to_all' },
    { id: 'add_ic_call', name: 'add_ic_call' },
    { id: 'domain_verification', name: 'domain_verification' },
    { id: 'mark_chat_read', name: 'mark_chat_read' },
    { id: 'phonelink_ring', name: 'phonelink_ring' },
    { id: 'stay_current_portrait', name: 'stay_current_portrait' },
    { id: 'call_received', name: 'call_received' },
    { id: 'phonelink_lock', name: 'phonelink_lock' },
    { id: 'phone_disabled', name: 'phone_disabled' },
    { id: 'duo', name: 'duo' },
    { id: 'ring_volume', name: 'ring_volume' },
    { id: 'phonelink_setup', name: 'phonelink_setup' },
    { id: 'location_off', name: 'location_off' },
    { id: 'stay_primary_portrait', name: 'stay_primary_portrait' },
    { id: 'swap_calls', name: 'swap_calls' },
    { id: 'co_present', name: 'co_present' },
    { id: 'voicemail', name: 'voicemail' },
    { id: 'call_merge', name: 'call_merge' },
    { id: 'mobile_screen_share', name: 'mobile_screen_share' },
    { id: 'phonelink_erase', name: 'phonelink_erase' },
    { id: 'person_add_disabled', name: 'person_add_disabled' },
    { id: 'cell_tower', name: 'cell_tower' },
    { id: 'domain_disabled', name: 'domain_disabled' },
    { id: 'call_missed_outgoing', name: 'call_missed_outgoing' },
    { id: 'cell_wifi', name: 'cell_wifi' },
    { id: 'speaker_phone', name: 'speaker_phone' },
    { id: 'desktop_access_disabled', name: 'desktop_access_disabled' },
    { id: 'stop_screen_share', name: 'stop_screen_share' },
    { id: 'call_missed', name: 'call_missed' },
    { id: 'spoke', name: 'spoke' },
    { id: 'print_disabled', name: 'print_disabled' },
    { id: 'pause_presentation', name: 'pause_presentation' },
    { id: 'dialer_sip', name: 'dialer_sip' },
    { id: 'invert_colors_off', name: 'invert_colors_off' },
    { id: 'rtt', name: 'rtt' },
    { id: 'wifi_calling', name: 'wifi_calling' },
    { id: 'portable_wifi_off', name: 'portable_wifi_off' },
    { id: 'comments_disabled', name: 'comments_disabled' },
    { id: 'stay_current_landscape', name: 'stay_current_landscape' },
    { id: 'sip', name: 'sip' },
    { id: 'nat', name: 'nat' },
    { id: 'stay_primary_landscape', name: 'stay_primary_landscape' },
    { id: 'no_sim', name: 'no_sim' },
    { id: 'mark_unread_chat_alt', name: 'mark_unread_chat_alt' },
    { id: 'send_time_extension', name: 'send_time_extension' },
    { id: 'key_off', name: 'key_off' },
    { id: 'vpn_key_off', name: 'vpn_key_off' },
    { id: 'mail_lock', name: 'mail_lock' },
    { id: 'contact_emergency', name: 'contact_emergency' },
    { id: 'add', name: 'add' },
    { id: 'add_circle_outline', name: 'add_circle_outline' },
    { id: 'content_copy', name: 'content_copy' },
    { id: 'add_circle', name: 'add_circle' },
    { id: 'send', name: 'send' },
    { id: 'clear', name: 'clear' },
    { id: 'mail', name: 'mail' },
    { id: 'save', name: 'save' },
    { id: 'link', name: 'link' },
    { id: 'remove', name: 'remove' },
    { id: 'filter_list', name: 'filter_list' },
    { id: 'inventory_2', name: 'inventory_2' },
    { id: 'insights', name: 'insights' },
    { id: 'inventory', name: 'inventory' },
    { id: 'sort', name: 'sort' },
    { id: 'remove_circle_outline', name: 'remove_circle_outline' },
    { id: 'bolt', name: 'bolt' },
    { id: 'flag', name: 'flag' },
    { id: 'reply', name: 'reply' },
    { id: 'add_box', name: 'add_box' },
    { id: 'remove_circle', name: 'remove_circle' },
    { id: 'create', name: 'create' },
    { id: 'push_pin', name: 'push_pin' },
    { id: 'block', name: 'block' },
    { id: 'calculate', name: 'calculate' },
    { id: 'undo', name: 'undo' },
    { id: 'how_to_reg', name: 'how_to_reg' },
    { id: 'content_paste', name: 'content_paste' },
    { id: 'report', name: 'report' },
    { id: 'file_copy', name: 'file_copy' },
    { id: 'shield', name: 'shield' },
    { id: 'backspace', name: 'backspace' },
    { id: 'archive', name: 'archive' },
    { id: 'save_alt', name: 'save_alt' },
    { id: 'policy', name: 'policy' },
    { id: 'tag', name: 'tag' },
    { id: 'change_circle', name: 'change_circle' },
    { id: 'redo', name: 'redo' },
    { id: 'content_cut', name: 'content_cut' },
    { id: 'forward', name: 'forward' },
    { id: 'inbox', name: 'inbox' },
    { id: 'outlined_flag', name: 'outlined_flag' },
    { id: 'drafts', name: 'drafts' },
    { id: 'link_off', name: 'link_off' },
    { id: 'biotech', name: 'biotech' },
    { id: 'ballot', name: 'ballot' },
    { id: 'report_gmailerrorred', name: 'report_gmailerrorred' },
    { id: 'markunread', name: 'markunread' },
    { id: 'stacked_bar_chart', name: 'stacked_bar_chart' },
    { id: 'delete_sweep', name: 'delete_sweep' },
    { id: 'square_foot', name: 'square_foot' },
    { id: 'stream', name: 'stream' },
    { id: 'add_link', name: 'add_link' },
    { id: 'where_to_vote', name: 'where_to_vote' },
    { id: 'copy_all', name: 'copy_all' },
    { id: 'move_to_inbox', name: 'move_to_inbox' },
    { id: 'dynamic_feed', name: 'dynamic_feed' },
    { id: 'waves', name: 'waves' },
    { id: 'unarchive', name: 'unarchive' },
    { id: 'reply_all', name: 'reply_all' },
    { id: 'select_all', name: 'select_all' },
    { id: 'low_priority', name: 'low_priority' },
    { id: 'text_format', name: 'text_format' },
    { id: 'content_paste_search', name: 'content_paste_search' },
    { id: 'weekend', name: 'weekend' },
    { id: 'font_download', name: 'font_download' },
    { id: 'how_to_vote', name: 'how_to_vote' },
    { id: 'upcoming', name: 'upcoming' },
    { id: 'gesture', name: 'gesture' },
    { id: 'attribution', name: 'attribution' },
    { id: 'save_as', name: 'save_as' },
    { id: 'next_week', name: 'next_week' },
    { id: 'content_paste_go', name: 'content_paste_go' },
    { id: 'flag_circle', name: 'flag_circle' },
    { id: 'content_paste_off', name: 'content_paste_off' },
    { id: 'filter_list_off', name: 'filter_list_off' },
    { id: 'report_off', name: 'report_off' },
    { id: 'font_download_off', name: 'font_download_off' },
    { id: 'deselect', name: 'deselect' },
    { id: 'web_stories', name: 'web_stories' },
    { id: 'light_mode', name: 'light_mode' },
    { id: 'restart_alt', name: 'restart_alt' },
    { id: 'dark_mode', name: 'dark_mode' },
    { id: 'task', name: 'task' },
    { id: 'summarize', name: 'summarize' },
    { id: 'password', name: 'password' },
    { id: 'sell', name: 'sell' },
    { id: 'signal_cellular_alt', name: 'signal_cellular_alt' },
    { id: 'devices', name: 'devices' },
    { id: 'settings_suggest', name: 'settings_suggest' },
    { id: 'quiz', name: 'quiz' },
    { id: 'widgets', name: 'widgets' },
    { id: 'storage', name: 'storage' },
    { id: 'thermostat', name: 'thermostat' },
    { id: 'battery_full', name: 'battery_full' },
    { id: 'credit_score', name: 'credit_score' },
    { id: 'gps_fixed', name: 'gps_fixed' },
    { id: 'medication', name: 'medication' },
    { id: 'price_check', name: 'price_check' },
    { id: 'pin', name: 'pin' },
    { id: 'gpp_good', name: 'gpp_good' },
    { id: 'price_change', name: 'price_change' },
    { id: 'tungsten', name: 'tungsten' },
    { id: 'battery_charging_full', name: 'battery_charging_full' },
    { id: 'fmd_good', name: 'fmd_good' },
    { id: 'reviews', name: 'reviews' },
    { id: 'note_alt', name: 'note_alt' },
    { id: 'air', name: 'air' },
    { id: 'graphic_eq', name: 'graphic_eq' },
    { id: 'bluetooth', name: 'bluetooth' },
    { id: 'dvr', name: 'dvr' },
    { id: 'nightlight', name: 'nightlight' },
    { id: 'sports_score', name: 'sports_score' },
    { id: 'access_time', name: 'access_time' },
    { id: 'water', name: 'water' },
    { id: 'share_location', name: 'share_location' },
    { id: 'monitor_heart', name: 'monitor_heart' },
    { id: 'gpp_maybe', name: 'gpp_maybe' },
    { id: 'cable', name: 'cable' },
    { id: 'location_searching', name: 'location_searching' },
    { id: 'cameraswitch', name: 'cameraswitch' },
    { id: 'shortcut', name: 'shortcut' },
    { id: 'device_thermostat', name: 'device_thermostat' },
    { id: 'airplane_ticket', name: 'airplane_ticket' },
    { id: 'wallpaper', name: 'wallpaper' },
    { id: 'monitor_weight', name: 'monitor_weight' },
    { id: 'radar', name: 'radar' },
    { id: 'data_usage', name: 'data_usage' },
    { id: 'signal_wifi_4_bar', name: 'signal_wifi_4_bar' },
    { id: 'gpp_bad', name: 'gpp_bad' },
    { id: 'developer_mode', name: 'developer_mode' },
    { id: 'battery_std', name: 'battery_std' },
    { id: 'mode_night', name: 'mode_night' },
    { id: 'bloodtype', name: 'bloodtype' },
    { id: 'wifi_tethering', name: 'wifi_tethering' },
    { id: 'signal_cellular_4_bar', name: 'signal_cellular_4_bar' },
    { id: 'flashlight_on', name: 'flashlight_on' },
    { id: 'network_wifi', name: 'network_wifi' },
    { id: 'fmd_bad', name: 'fmd_bad' },
    { id: 'splitscreen', name: 'splitscreen' },
    { id: 'access_time_filled', name: 'access_time_filled' },
    { id: 'airplanemode_active', name: 'airplanemode_active' },
    { id: 'mobile_friendly', name: 'mobile_friendly' },
    { id: 'sim_card_download', name: 'sim_card_download' },
    { id: 'battery_alert', name: 'battery_alert' },
    { id: 'send_to_mobile', name: 'send_to_mobile' },
    { id: 'screen_search_desktop', name: 'screen_search_desktop' },
    { id: 'usb', name: 'usb' },
    { id: 'lens_blur', name: 'lens_blur' },
    { id: 'signal_wifi_statusbar_4_bar', name: 'signal_wifi_statusbar_4_bar' },
    { id: 'discount', name: 'discount' },
    { id: 'screen_rotation', name: 'screen_rotation' },
    {
      id: 'signal_wifi_statusbar_connected_no_internet_4',
      name: 'signal_wifi_statusbar_connected_no_internet_4',
    },
    { id: 'system_security_update_good', name: 'system_security_update_good' },
    { id: 'gps_not_fixed', name: 'gps_not_fixed' },
    { id: 'bluetooth_connected', name: 'bluetooth_connected' },
    { id: 'remember_me', name: 'remember_me' },
    { id: 'pattern', name: 'pattern' },
    { id: 'nfc', name: 'nfc' },
    { id: 'brightness_high', name: 'brightness_high' },
    { id: 'mode_standby', name: 'mode_standby' },
    { id: 'battery_saver', name: 'battery_saver' },
    { id: 'play_lesson', name: 'play_lesson' },
    { id: 'lan', name: 'lan' },
    { id: 'storm', name: 'storm' },
    { id: 'network_cell', name: 'network_cell' },
    { id: 'data_saver_off', name: 'data_saver_off' },
    { id: 'screenshot', name: 'screenshot' },
    { id: 'brightness_medium', name: 'brightness_medium' },
    { id: 'signal_wifi_0_bar', name: 'signal_wifi_0_bar' },
    { id: 'brightness_low', name: 'brightness_low' },
    { id: 'data_saver_on', name: 'data_saver_on' },
    { id: 'grid_4x4', name: 'grid_4x4' },
    { id: 'bluetooth_searching', name: 'bluetooth_searching' },
    { id: 'mobiledata_off', name: 'mobiledata_off' },
    { id: 'ad_units', name: 'ad_units' },
    { id: 'bluetooth_disabled', name: 'bluetooth_disabled' },
    { id: 'security_update_good', name: 'security_update_good' },
    { id: 'hdr_auto', name: 'hdr_auto' },
    { id: 'battery_unknown', name: 'battery_unknown' },
    { id: 'signal_wifi_off', name: 'signal_wifi_off' },
    { id: 'signal_wifi_bad', name: 'signal_wifi_bad' },
    { id: 'settings_system_daydream', name: 'settings_system_daydream' },
    { id: 'wifi_calling_3', name: 'wifi_calling_3' },
    { id: 'access_alarms', name: 'access_alarms' },
    { id: 'battery_5_bar', name: 'battery_5_bar' },
    { id: 'signal_wifi_connected_no_internet_4', name: 'signal_wifi_connected_no_internet_4' },
    { id: 'aod', name: 'aod' },
    { id: 'add_to_home_screen', name: 'add_to_home_screen' },
    { id: 'security_update', name: 'security_update' },
    { id: 'ssid_chart', name: 'ssid_chart' },
    { id: 'signal_cellular_0_bar', name: 'signal_cellular_0_bar' },
    { id: 'flashlight_off', name: 'flashlight_off' },
    { id: 'flourescent', name: 'flourescent' },
    { id: 'access_alarm', name: 'access_alarm' },
    { id: 'gps_off', name: 'gps_off' },
    { id: '4g_mobiledata', name: '4g_mobiledata' },
    { id: 'grid_3x3', name: 'grid_3x3' },
    { id: 'signal_wifi_statusbar_null', name: 'signal_wifi_statusbar_null' },
    { id: 'battery_4_bar', name: 'battery_4_bar' },
    { id: 'sd_storage', name: 'sd_storage' },
    { id: 'reset_tv', name: 'reset_tv' },
    { id: 'wifi_lock', name: 'wifi_lock' },
    { id: 'battery_0_bar', name: 'battery_0_bar' },
    { id: 'battery_6_bar', name: 'battery_6_bar' },
    { id: 'brightness_auto', name: 'brightness_auto' },
    { id: 'do_not_disturb_on_total_silence', name: 'do_not_disturb_on_total_silence' },
    {
      id: 'signal_cellular_connected_no_internet_4_bar',
      name: 'signal_cellular_connected_no_internet_4_bar',
    },
    { id: 'airplanemode_inactive', name: 'airplanemode_inactive' },
    { id: '1x_mobiledata', name: '1x_mobiledata' },
    { id: 'nearby_error', name: 'nearby_error' },
    { id: 'security_update_warning', name: 'security_update_warning' },
    {
      id: 'signal_cellular_connected_no_internet_0_bar',
      name: 'signal_cellular_connected_no_internet_0_bar',
    },
    { id: 'screen_lock_portrait', name: 'screen_lock_portrait' },
    { id: 'system_security_update', name: 'system_security_update' },
    { id: 'battery_3_bar', name: 'battery_3_bar' },
    { id: 'system_security_update_warning', name: 'system_security_update_warning' },
    { id: 'wifi_tethering_off', name: 'wifi_tethering_off' },
    { id: 'edgesensor_high', name: 'edgesensor_high' },
    { id: 'punch_clock', name: 'punch_clock' },
    { id: '4g_plus_mobiledata', name: '4g_plus_mobiledata' },
    { id: 'battery_1_bar', name: 'battery_1_bar' },
    { id: 'signal_wifi_4_bar_lock', name: 'signal_wifi_4_bar_lock' },
    { id: 'lte_mobiledata', name: 'lte_mobiledata' },
    { id: 'add_alarm', name: 'add_alarm' },
    { id: 'signal_cellular_off', name: 'signal_cellular_off' },
    { id: 'media_bluetooth_on', name: 'media_bluetooth_on' },
    { id: 'bluetooth_drive', name: 'bluetooth_drive' },
    { id: 'signal_cellular_nodata', name: 'signal_cellular_nodata' },
    { id: 'wifi_password', name: 'wifi_password' },
    { id: 'location_disabled', name: 'location_disabled' },
    { id: 'signal_cellular_null', name: 'signal_cellular_null' },
    { id: 'mobile_off', name: 'mobile_off' },
    { id: 'medication_liquid', name: 'medication_liquid' },
    { id: '30fps', name: '30fps' },
    { id: 'battery_2_bar', name: 'battery_2_bar' },
    { id: 'grid_goldenratio', name: 'grid_goldenratio' },
    { id: 'screen_lock_rotation', name: 'screen_lock_rotation' },
    { id: 'rsvp', name: 'rsvp' },
    { id: 'edgesensor_low', name: 'edgesensor_low' },
    { id: '3g_mobiledata', name: '3g_mobiledata' },
    { id: '60fps', name: '60fps' },
    { id: 'screen_lock_landscape', name: 'screen_lock_landscape' },
    { id: 'lte_plus_mobiledata', name: 'lte_plus_mobiledata' },
    { id: 'usb_off', name: 'usb_off' },
    { id: 'phishing', name: 'phishing' },
    { id: 'timer_10_select', name: 'timer_10_select' },
    { id: 'signal_cellular_no_sim', name: 'signal_cellular_no_sim' },
    { id: 'screenshot_monitor', name: 'screenshot_monitor' },
    { id: 'g_mobiledata', name: 'g_mobiledata' },
    { id: 'timer_3_select', name: 'timer_3_select' },
    { id: 'wifi_find', name: 'wifi_find' },
    { id: 'h_mobiledata', name: 'h_mobiledata' },
    { id: 'network_wifi_3_bar', name: 'network_wifi_3_bar' },
    { id: 'network_wifi_1_bar', name: 'network_wifi_1_bar' },
    { id: 'media_bluetooth_off', name: 'media_bluetooth_off' },
    { id: 'e_mobiledata', name: 'e_mobiledata' },
    { id: 'r_mobiledata', name: 'r_mobiledata' },
    { id: 'h_plus_mobiledata', name: 'h_plus_mobiledata' },
    { id: 'wifi_tethering_error', name: 'wifi_tethering_error' },
    { id: 'hdr_on_select', name: 'hdr_on_select' },
    { id: 'hdr_auto_select', name: 'hdr_auto_select' },
    { id: 'wifi_channel', name: 'wifi_channel' },
    { id: 'network_wifi_2_bar', name: 'network_wifi_2_bar' },
    { id: 'nearby_off', name: 'nearby_off' },
    { id: 'wifi_2_bar', name: 'wifi_2_bar' },
    { id: 'hdr_off_select', name: 'hdr_off_select' },
    { id: 'signal_cellular_alt_2_bar', name: 'signal_cellular_alt_2_bar' },
    { id: 'signal_cellular_alt_1_bar', name: 'signal_cellular_alt_1_bar' },
    { id: 'wifi_1_bar', name: 'wifi_1_bar' },
    { id: 'devices_fold', name: 'devices_fold' },
    { id: 'dataset', name: 'dataset' },
    { id: 'dataset_linked', name: 'dataset_linked' },
    { id: 'macro_off', name: 'macro_off' },
    { id: 'attach_money', name: 'attach_money' },
    { id: 'format_list_bulleted', name: 'format_list_bulleted' },
    { id: 'mode_edit', name: 'mode_edit' },
    { id: 'edit_note', name: 'edit_note' },
    { id: 'monetization_on', name: 'monetization_on' },
    { id: 'attach_file', name: 'attach_file' },
    { id: 'post_add', name: 'post_add' },
    { id: 'bar_chart', name: 'bar_chart' },
    { id: 'checklist', name: 'checklist' },
    { id: 'drag_handle', name: 'drag_handle' },
    { id: 'insert_drive_file', name: 'insert_drive_file' },
    { id: 'show_chart', name: 'show_chart' },
    { id: 'format_quote', name: 'format_quote' },
    { id: 'format_list_numbered', name: 'format_list_numbered' },
    { id: 'border_color', name: 'border_color' },
    { id: 'table_chart', name: 'table_chart' },
    { id: 'pie_chart', name: 'pie_chart' },
    { id: 'notes', name: 'notes' },
    { id: 'query_stats', name: 'query_stats' },
    { id: 'format_bold', name: 'format_bold' },
    { id: 'insert_photo', name: 'insert_photo' },
    { id: 'text_fields', name: 'text_fields' },
    { id: 'insert_emoticon', name: 'insert_emoticon' },
    { id: 'title', name: 'title' },
    { id: 'table_rows', name: 'table_rows' },
    { id: 'auto_graph', name: 'auto_graph' },
    { id: 'publish', name: 'publish' },
    { id: 'mode', name: 'mode' },
    { id: 'insert_chart_outlined', name: 'insert_chart_outlined' },
    { id: 'checklist_rtl', name: 'checklist_rtl' },
    { id: 'mode_comment', name: 'mode_comment' },
    { id: 'insert_invitation', name: 'insert_invitation' },
    { id: 'draw', name: 'draw' },
    { id: 'insert_link', name: 'insert_link' },
    { id: 'functions', name: 'functions' },
    { id: 'mode_edit_outline', name: 'mode_edit_outline' },
    { id: 'format_italic', name: 'format_italic' },
    { id: 'format_align_left', name: 'format_align_left' },
    { id: 'horizontal_rule', name: 'horizontal_rule' },
    { id: 'format_color_fill', name: 'format_color_fill' },
    { id: 'add_comment', name: 'add_comment' },
    { id: 'insert_chart', name: 'insert_chart' },
    { id: 'linear_scale', name: 'linear_scale' },
    { id: 'format_size', name: 'format_size' },
    { id: 'stacked_line_chart', name: 'stacked_line_chart' },
    { id: 'format_underlined', name: 'format_underlined' },
    { id: 'bubble_chart', name: 'bubble_chart' },
    { id: 'insert_comment', name: 'insert_comment' },
    { id: 'money_off', name: 'money_off' },
    { id: 'vertical_align_bottom', name: 'vertical_align_bottom' },
    { id: 'height', name: 'height' },
    { id: 'vertical_align_top', name: 'vertical_align_top' },
    { id: 'format_align_center', name: 'format_align_center' },
    { id: 'format_paint', name: 'format_paint' },
    { id: 'schema', name: 'schema' },
    { id: 'format_color_text', name: 'format_color_text' },
    { id: 'area_chart', name: 'area_chart' },
    { id: 'format_align_right', name: 'format_align_right' },
    { id: 'scatter_plot', name: 'scatter_plot' },
    { id: 'format_list_numbered_rtl', name: 'format_list_numbered_rtl' },
    { id: 'highlight', name: 'highlight' },
    { id: 'merge_type', name: 'merge_type' },
    { id: 'format_align_justify', name: 'format_align_justify' },
    { id: 'square', name: 'square' },
    { id: 'add_chart', name: 'add_chart' },
    { id: 'format_shapes', name: 'format_shapes' },
    { id: 'money_off_csred', name: 'money_off_csred' },
    { id: 'pie_chart_outline', name: 'pie_chart_outline' },
    { id: 'numbers', name: 'numbers' },
    { id: 'short_text', name: 'short_text' },
    { id: 'format_indent_increase', name: 'format_indent_increase' },
    { id: 'align_horizontal_left', name: 'align_horizontal_left' },
    { id: 'strikethrough_s', name: 'strikethrough_s' },
    { id: 'vertical_align_center', name: 'vertical_align_center' },
    { id: 'format_color_reset', name: 'format_color_reset' },
    { id: 'data_object', name: 'data_object' },
    { id: 'multiline_chart', name: 'multiline_chart' },
    { id: 'score', name: 'score' },
    { id: 'border_all', name: 'border_all' },
    { id: 'hexagon', name: 'hexagon' },
    { id: 'space_bar', name: 'space_bar' },
    { id: 'format_indent_decrease', name: 'format_indent_decrease' },
    { id: 'format_clear', name: 'format_clear' },
    { id: 'rectangle', name: 'rectangle' },
    { id: 'format_line_spacing', name: 'format_line_spacing' },
    { id: 'move_up', name: 'move_up' },
    { id: 'align_vertical_bottom', name: 'align_vertical_bottom' },
    { id: 'horizontal_distribute', name: 'horizontal_distribute' },
    { id: 'align_horizontal_center', name: 'align_horizontal_center' },
    { id: 'format_strikethrough', name: 'format_strikethrough' },
    { id: 'move_down', name: 'move_down' },
    { id: 'align_horizontal_right', name: 'align_horizontal_right' },
    { id: 'vertical_distribute', name: 'vertical_distribute' },
    { id: 'superscript', name: 'superscript' },
    { id: 'candlestick_chart', name: 'candlestick_chart' },
    { id: 'margin', name: 'margin' },
    { id: 'text_increase', name: 'text_increase' },
    { id: 'wrap_text', name: 'wrap_text' },
    { id: 'border_clear', name: 'border_clear' },
    { id: 'align_vertical_top', name: 'align_vertical_top' },
    { id: 'subscript', name: 'subscript' },
    { id: 'align_vertical_center', name: 'align_vertical_center' },
    { id: 'polyline', name: 'polyline' },
    { id: 'border_style', name: 'border_style' },
    { id: 'data_array', name: 'data_array' },
    { id: 'padding', name: 'padding' },
    { id: 'border_outer', name: 'border_outer' },
    { id: 'pentagon', name: 'pentagon' },
    { id: 'text_decrease', name: 'text_decrease' },
    { id: 'line_axis', name: 'line_axis' },
    { id: 'insert_page_break', name: 'insert_page_break' },
    { id: 'border_inner', name: 'border_inner' },
    { id: 'border_left', name: 'border_left' },
    { id: 'border_bottom', name: 'border_bottom' },
    { id: 'format_textdirection_l_to_r', name: 'format_textdirection_l_to_r' },
    { id: 'border_vertical', name: 'border_vertical' },
    { id: 'border_horizontal', name: 'border_horizontal' },
    { id: 'format_textdirection_r_to_l', name: 'format_textdirection_r_to_l' },
    { id: 'border_top', name: 'border_top' },
    { id: 'border_right', name: 'border_right' },
    { id: 'shape_line', name: 'shape_line' },
    { id: 'type_specimen', name: 'type_specimen' },
    { id: 'file_download', name: 'file_download' },
    { id: 'file_upload', name: 'file_upload' },
    { id: 'download', name: 'download' },
    { id: 'folder', name: 'folder' },
    { id: 'grid_view', name: 'grid_view' },
    { id: 'upload_file', name: 'upload_file' },
    { id: 'cloud_upload', name: 'cloud_upload' },
    { id: 'folder_open', name: 'folder_open' },
    { id: 'cloud', name: 'cloud' },
    { id: 'text_snippet', name: 'text_snippet' },
    { id: 'request_quote', name: 'request_quote' },
    { id: 'cloud_download', name: 'cloud_download' },
    { id: 'drive_file_rename_outline', name: 'drive_file_rename_outline' },
    { id: 'upload', name: 'upload' },
    { id: 'attachment', name: 'attachment' },
    { id: 'download_for_offline', name: 'download_for_offline' },
    { id: 'create_new_folder', name: 'create_new_folder' },
    { id: 'downloading', name: 'downloading' },
    { id: 'newspaper', name: 'newspaper' },
    { id: 'folder_shared', name: 'folder_shared' },
    { id: 'cloud_done', name: 'cloud_done' },
    { id: 'topic', name: 'topic' },
    { id: 'download_done', name: 'download_done' },
    { id: 'cloud_queue', name: 'cloud_queue' },
    { id: 'workspaces', name: 'workspaces' },
    { id: 'approval', name: 'approval' },
    { id: 'cloud_off', name: 'cloud_off' },
    { id: 'drive_file_move', name: 'drive_file_move' },
    { id: 'drive_folder_upload', name: 'drive_folder_upload' },
    { id: 'file_download_done', name: 'file_download_done' },
    { id: 'file_open', name: 'file_open' },
    { id: 'attach_email', name: 'attach_email' },
    { id: 'rule_folder', name: 'rule_folder' },
    { id: 'cloud_sync', name: 'cloud_sync' },
    { id: 'cloud_circle', name: 'cloud_circle' },
    { id: 'snippet_folder', name: 'snippet_folder' },
    { id: 'folder_zip', name: 'folder_zip' },
    { id: 'file_download_off', name: 'file_download_off' },
    { id: 'folder_copy', name: 'folder_copy' },
    { id: 'difference', name: 'difference' },
    { id: 'drive_file_move_rtl', name: 'drive_file_move_rtl' },
    { id: 'folder_delete', name: 'folder_delete' },
    { id: 'folder_off', name: 'folder_off' },
    { id: 'format_overline', name: 'format_overline' },
  ];

  constructor() {}

  getSearchableIcons(): IdName[] {
    return this.allMaterialIcons;
  }
}
