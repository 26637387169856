import { IdName } from 'src/app/shared/models/id-name';

interface IShift {
  id: string;
  name: string;
}

export interface IShiftRequest extends IShift {
  departmentId: string;
}

export interface IShiftResponse extends IShift {
  departmentName: string;
  department: IdName;
}

export interface IShiftWithDepartmentResponse extends IShift {
  departmentId: string;
  shiftName: string;
  departmentName: string;
}

export interface IShiftWithDepartment extends IShift {
  departmentId: string;
  departmentName: string;
}

export function map(shiftRequest: IShiftRequest): IShiftResponse {
  return <IShiftResponse>{
    id: shiftRequest.id,
    name: shiftRequest.name,
  };
}
