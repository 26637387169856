import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'passArgumentsToObjFunctions', pure: true, standalone: true })
export class PassArgumentsToObjectsFunctionsPipe implements PipeTransform {
  transform(
    obj: { [key: string]: (...args: any[]) => () => void },
    ...args: any[]
  ): { [key: string]: () => void } {
    return Object.fromEntries(Object.entries(obj).map(([key, func]) => [key, func(args)]));
  }
}
