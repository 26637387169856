import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TenantUserAccessState } from '../state/tenant-user-access.state';

@Injectable({
  providedIn: 'root',
})
export class TenantUserPrivilegesService {
  privilegesFlat$: Observable<string[]> = this.store.select(
    TenantUserAccessState.getPrivilegesFlat
  );
  isProductOwner$: Observable<boolean | undefined> = this.store.select(
    TenantUserAccessState.isProductOwner
  );

  private privilegesFlat: string[] = [];
  private isProductOwner = false;

  constructor(private store: Store) {
    this.privilegesFlat$.subscribe((flatPrivileges: string[]) => {
      this.privilegesFlat = flatPrivileges;
    });
    this.isProductOwner$.subscribe((isProductOwner: boolean | undefined) => {
      this.isProductOwner = isProductOwner ?? false;
    });
  }

  userHasPrivilege(privilege: string | string[]): boolean {
    if (this.isProductOwner) {
      return true;
    }
    if (privilege instanceof Array) {
      return privilege.some((singlePriv: string) => this.privilegesFlat.includes(singlePriv));
    }
    return this.privilegesFlat.some((flatPrivilege: string) => flatPrivilege === privilege);
  }

  userHasAllPrivileges(privileges: string[]): boolean {
    if (this.isProductOwner) {
      return true;
    }

    return privileges.every((singlePriv: string) => this.privilegesFlat.includes(singlePriv));
  }

  recursiveFilterMenuItemsPerPrivileges(menuItems: any) {
    return menuItems.reduce((filtered: any, item: any) => {
      let hasMatchingPrivilege = false;
      if (this.isProductOwner) {
        hasMatchingPrivilege = true;
      } else if (item.privileges?.length === 0) {
        hasMatchingPrivilege = true;
      } else if (item.checkAllPrivileges) {
        hasMatchingPrivilege = item.privileges?.every((privilege: any) =>
          this.privilegesFlat.includes(privilege)
        );
      } else {
        hasMatchingPrivilege = item.privileges?.some((privilege: any) =>
          this.privilegesFlat.includes(privilege)
        );
      }
      const filteredChildren = this.recursiveFilterMenuItemsPerPrivileges(item.children);

      if (hasMatchingPrivilege || filteredChildren.length > 0) {
        filtered.push({ ...item, children: filteredChildren });
      }

      return filtered;
    }, []);
  }
}
