<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'mainContact'">
    <ng-container *ngIf="element.isMainContact">
      <mat-icon class="tick">done</mat-icon>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'fullName'">
    {{ element | fullName }}
  </ng-container>
  <ng-container *ngSwitchCase="'employedInDepartment'">
    {{ element.employedIn }}
  </ng-container>
  <ng-container *ngSwitchCase="'phoneNumber'">
    +{{ element.mobilePrefix }} {{ element.mobile }}
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <ng-container *ngIf="element.deleted; else hasDeletedEmployment">
      <mat-icon class="red" [matTooltip]="'deletedUserStatus' | translate">cancel</mat-icon>
    </ng-container>
    <ng-template #hasDeletedEmployment>
      <ng-container *ngIf="element.hasDeletedEmployment; else isPaused">
        <mat-icon class="red" [matTooltip]="'deletedEmploymentStatus' | translate">block</mat-icon>
      </ng-container>
      <ng-template #isPaused>
        <ng-container *ngIf="element.hasPausedEmployment; else isActive">
          <mat-icon class="cross" [matTooltip]="'pausedEmploymentStatus' | translate"
            >pause</mat-icon
          >
        </ng-container>
        <ng-template #isActive>
          <ng-container *ngIf="element.isActive; else cross">
            <mat-icon class="tick" [matTooltip]="'activeNotDeletedUserStatus' | translate"
              >done</mat-icon
            >
          </ng-container>
          <ng-template #cross>
            <mat-icon class="cross" [matTooltip]="'inactiveUserStatus' | translate">close</mat-icon>
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'edit'">
    <button mat-button (click)="buttonFunctions.edit()">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'delete'">
    <button
      mat-button
      appConfirmDialog
      [confirmEntityName]="element | fullName"
      (confirmed)="buttonFunctions.delete()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
  <!-- User Competences -->
  <ng-container *ngSwitchCase="'statusCompetenceUser'">
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.Red" class="red">circle</mat-icon>
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.Yellow" class="yellow"
      >circle</mat-icon
    >
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.Orange" class="orange"
      >circle</mat-icon
    >
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.Green" class="green"
      >circle</mat-icon
    >
    <mat-icon *ngIf="element.statusType === competenceStatusEnum.White" class="white"
      >circle</mat-icon
    >
  </ng-container>
  <ng-container *ngSwitchCase="'hasAttachments'">
    <mat-icon *ngIf="element.hasAttachments" [attr.aria-label]="'hasAttachments' | translate">
      attach_file
    </mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'actionCompetenceUser'">
    <div
      *ngIf="element.formId && element.isRegistrationCompleted; else restOfActions"
      (click)="routeToCustomForm(element.userCompetenceId, element.formId)"
    >
      <mat-icon><div>assignment</div></mat-icon>
    </div>
    <ng-template #restOfActions>
      <div *ngIf="element.isExpiring; else done" (click)="toRenewCompetence()">
        <mat-icon>calendar_month</mat-icon>
      </div>
      <ng-template #done>
        <mat-icon>done</mat-icon>
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'competenceCode'">
    {{ element.competenceCode }} {{ element.code }}
  </ng-container>
  <ng-container *ngSwitchCase="'competenceName'">
    {{ element.name }} {{ element.competenceName }}
    <app-equivalent-competences-tooltip [competence]="element"></app-equivalent-competences-tooltip>
  </ng-container>
  <ng-container *ngSwitchCase="'requirementType'">
    <div *ngIf="element.requirementType === userCompetenceRequirementType.mandatory">
      {{ 'mandatory' | translate | uppercase }}
    </div>
    <div *ngIf="element.requirementType === userCompetenceRequirementType.optional">
      {{ 'optional' | translate | uppercase }}
    </div>
    <div *ngIf="element.requirementType === userCompetenceRequirementType.preferred">
      {{ 'preferred' | translate | uppercase }}
    </div>
    <div *ngIf="element.requirementType === userCompetenceRequirementType.notRequired">
      {{ 'notRequired' | translate | uppercase }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'expiry'">
    <div *ngIf="element.expiresAt; else noDate">
      {{ element.expiresAt | date : 'yyyy-MM-dd' }}
    </div>
    <ng-template #noDate><div>-</div></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'daysUntilExpired'">
    <div *ngIf="element.daysUntilExpired; else noDays" class="center">
      {{ element.daysUntilExpired }}
    </div>
    <ng-template #noDays><div>-</div></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'registrationComplete'">
    <div *ngIf="element.isRegistrationCompleted === true" class="center">
      {{ 'yes' | translate }}
    </div>
    <div *ngIf="element.isRegistrationCompleted === false" class="center">
      {{ 'no' | translate }}
    </div>
  </ng-container>
</ng-container>
