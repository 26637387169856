<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'id'">
    {{ element['number'] }}
  </ng-container>

  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill
      *ngIf="!element.deleted && element.enabled; else disabledOrDeleted"
      [name]="element['statusDisplayName'] | lowercase"
    ></app-ui-pill>
    <ng-template #disabledOrDeleted>
      <app-ui-pill
        *ngIf="!element.enabled; else deleted"
        [name]="'disabled' | lowercase"
      ></app-ui-pill>
      <ng-template #deleted>
        <app-ui-pill *ngIf="element.deleted" [name]="'deleted' | lowercase"></app-ui-pill>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'nextRevisionDate'">
    {{ element[columnName] | date : 'yyyy-MM-dd' }}
  </ng-container>

  <ng-container *ngSwitchCase="'hasAttachments'">
    <mat-icon *ngIf="element.hasAttachments" [attr.aria-label]="'hasAttachments' | translate">
      attach_file
    </mat-icon>
  </ng-container>

  <ng-container *ngSwitchCase="'access'">
    <app-positions-cell [element]="element"></app-positions-cell>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
