import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-forms-library-template',
  templateUrl: './forms-library-template.component.html',
  styleUrls: ['./forms-library-template.component.scss'],
})
export class FormsLibraryTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  constructor() {}
}
