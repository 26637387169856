<app-ui-button
  [disabled]="_formArray.disabled"
  text=" + {{ 'addContact' | translate }}"
  (click)="add()"
></app-ui-button>
<app-main-table
  *ngIf="_rows?.length"
  [tableData]="_rows"
  [displayedColumns]="columnsToDisplay"
  [filteringEnabled]="false"
  [specificTemplateName]="tableNamesEnum.Contacts"
  [buttonFunctions]="buttonFunctions"
  [hideButtons]="_formArray.disabled"
></app-main-table>
