import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { SearchResultModel } from 'src/app/core/models/search-result.model';
import { HashtagApiService } from 'src/app/hashtag/services/hashtag-api.service';
import { LoadSearchResult } from './global-search.actions';

export interface GlobalSearchResultStateModel {
  isLoaded: boolean;
  list: SearchResultModel[];
}

const GLOBAL_SEARCH_RESULT_STATE_TOKEN = new StateToken<GlobalSearchResultStateModel>(
  'globalSearchResult'
);

@State<GlobalSearchResultStateModel>({
  name: GLOBAL_SEARCH_RESULT_STATE_TOKEN,
  defaults: {
    isLoaded: false,
    list: [],
  },
})
@Injectable()
export class GlobalSearchResultState {
  constructor(private service: HashtagApiService) {}

  @Action(LoadSearchResult, { cancelUncompleted: true })
  loadSearchResult(
    { getState, setState }: StateContext<GlobalSearchResultStateModel>,
    { searchTerm }: LoadSearchResult
  ) {
    return this.service.getHashtagResultByString(searchTerm).pipe(
      tap((list: SearchResultModel[]) => {
        const state = getState();
        setState({
          ...state,
          isLoaded: true,
          list,
        });
      })
    );
  }

  @Selector()
  static globalSearchResults(state: GlobalSearchResultStateModel) {
    return state.list;
  }
}
