import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../user-view/models/user.model';
import { fullName } from '../functions/full-name';

@Pipe({
  name: 'fullName',
  pure: true,
  standalone: true,
})
export class FullNamePipe implements PipeTransform {
  transform(user: IUser): string {
    return fullName(user);
  }
}
