import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';

export enum RiskAssessmentValueType {
  Hazard = 'Hazards',
  Consequence = 'Consequences',
  MitigatingMeasure = 'MitigatingMeasures',
  OperationType = 'OperationTypes',
  Location = 'Locations',
}

export const RISK_ASSESSMENT_VALUE_TITLES: any = {
  [RiskAssessmentValueType.Hazard]: 'hazards',
  [RiskAssessmentValueType.Consequence]: 'consequences',
  [RiskAssessmentValueType.MitigatingMeasure]: 'mitigatingMeasures',
  [RiskAssessmentValueType.OperationType]: 'operationTypes',
  [RiskAssessmentValueType.Location]: 'locations',
};

export const RISK_ASSESSMENT_VALUE_CREATE_TITLE: any = {
  [RiskAssessmentValueType.Hazard]: 'newHazard',
  [RiskAssessmentValueType.Consequence]: 'newConsequence',
  [RiskAssessmentValueType.MitigatingMeasure]: 'newMitigatingMeasure',
  [RiskAssessmentValueType.OperationType]: 'newOperationType',
  [RiskAssessmentValueType.Location]: 'newLocation',
};

export const RISK_ASSESSMENT_VALUE_UPDATE_TITLE: any = {
  [RiskAssessmentValueType.Hazard]: 'updateHazard',
  [RiskAssessmentValueType.Consequence]: 'updateConsequence',
  [RiskAssessmentValueType.MitigatingMeasure]: 'updateMitigatingMeasure',
  [RiskAssessmentValueType.OperationType]: 'updateOperationType',
  [RiskAssessmentValueType.Location]: 'updateLocation',
};

export const RISK_ASSESSMENT_VALUE_CREATED_MESSAGE: any = {
  [RiskAssessmentValueType.Hazard]: 'hazardCreated',
  [RiskAssessmentValueType.Consequence]: 'consequenceCreated',
  [RiskAssessmentValueType.MitigatingMeasure]: 'mitigatingMeasureCreated',
  [RiskAssessmentValueType.OperationType]: 'operationTypeCreated',
  [RiskAssessmentValueType.Location]: 'locationCreated',
};

export const RISK_ASSESSMENT_VALUE_UPDATED_MESSAGE: any = {
  [RiskAssessmentValueType.Hazard]: 'hazardUpdated',
  [RiskAssessmentValueType.Consequence]: 'consequenceUpdated',
  [RiskAssessmentValueType.MitigatingMeasure]: 'mitigatingMeasureUpdated',
  [RiskAssessmentValueType.OperationType]: 'operationTypeUpdated',
  [RiskAssessmentValueType.Location]: 'locationUpdated',
};

export const RISK_ASSESSMENT_VALUE_DELETED_MESSAGE: any = {
  [RiskAssessmentValueType.Hazard]: 'hazardDeleted',
  [RiskAssessmentValueType.Consequence]: 'consequenceDeleted',
  [RiskAssessmentValueType.MitigatingMeasure]: 'mitigatingMeasureDeleted',
  [RiskAssessmentValueType.OperationType]: 'operationTypeDeleted',
  [RiskAssessmentValueType.Location]: 'locationDeleted',
};

export const RISK_ASSESSMENT_VALUE_TABLE_FILTERS: any = {
  [RiskAssessmentValueType.Hazard]: MainTableFilterModulesEnum.Hazards,
  [RiskAssessmentValueType.Consequence]: MainTableFilterModulesEnum.Consequences,
  [RiskAssessmentValueType.MitigatingMeasure]: MainTableFilterModulesEnum.MitigatingMeasures,
  [RiskAssessmentValueType.OperationType]: MainTableFilterModulesEnum.OperationTypes,
  [RiskAssessmentValueType.Location]: MainTableFilterModulesEnum.Locations,
};

export const RISK_ASSESSMENT_VALUE_CREATE_PRIVILEGE: any = {
  [RiskAssessmentValueType.Hazard]: 'RISK-ASSESSMENT-HAZARD_CREATE',
  [RiskAssessmentValueType.Consequence]: 'RISK-ASSESSMENT-CONSEQUENCE_CREATE',
  [RiskAssessmentValueType.MitigatingMeasure]: 'RISK-ASSESSMENT-MITIGATING-MEASURES_CREATE',
  [RiskAssessmentValueType.OperationType]: 'RISK-ASSESSMENT-OPERATION-TYPE_CREATE',
  [RiskAssessmentValueType.Location]: 'RISK-ASSESSMENT-LOCATION_CREATE',
};

export const RISK_ASSESSMENT_VALUE_UPDATE_PRIVILEGE: any = {
  [RiskAssessmentValueType.Hazard]: 'RISK-ASSESSMENT-HAZARD_UPDATE',
  [RiskAssessmentValueType.Consequence]: 'RISK-ASSESSMENT-CONSEQUENCE_UPDATE',
  [RiskAssessmentValueType.MitigatingMeasure]: 'RISK-ASSESSMENT-MITIGATING-MEASURES_UPDATE',
  [RiskAssessmentValueType.OperationType]: 'RISK-ASSESSMENT-OPERATION-TYPE_UPDATE',
  [RiskAssessmentValueType.Location]: 'RISK-ASSESSMENT-LOCATION_UPDATE',
};

export const RISK_ASSESSMENT_VALUE_DELETE_PRIVILEGE: any = {
  [RiskAssessmentValueType.Hazard]: 'RISK-ASSESSMENT-HAZARD_DELETE',
  [RiskAssessmentValueType.Consequence]: 'RISK-ASSESSMENT-CONSEQUENCE_DELETE',
  [RiskAssessmentValueType.MitigatingMeasure]: 'RISK-ASSESSMENT-MITIGATING-MEASURES_DELETE',
  [RiskAssessmentValueType.OperationType]: 'RISK-ASSESSMENT-OPERATION-TYPE_DELETE',
  [RiskAssessmentValueType.Location]: 'RISK-ASSESSMENT-LOCATION_DELETE',
};
