/* eslint-disable no-magic-numbers */
export enum ModuleNumbers {
  Core = 0,
  Certificate = 1,
  Maintenance = 2,
  Document = 3,
  NonConformance = 4,
  Audit = 5,
  Storage = 6,
  Competence = 7,
  CrewManagement = 8,
  Order = 9,
  Medicine = 10,
  Drill = 11,
  Task = 12,
  RiskAssessment = 13,
}
