import { getDepartmentId, getTenantId } from 'src/app/services/store-snapshot.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CopyMenuModel,
  MenuItemCreateModel,
  MenuItemModel,
  MenuItemUpdateModel,
  UpdateMenuStructureOrderRequest,
} from '../models/menu-id.model';
import { ModuleNumbers } from '../enums/module-numbers.enum';
import { IMenuPlacement } from '../models/menu-placement.model';

@Injectable({
  providedIn: 'root',
})
export class MenuTreeStructureApiService {
  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/menu`;
  }
  private get tenantUrl(): string {
    return `${environment.apiUrl}/api/${getTenantId()}`;
  }
  private get urlComponentList(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/Components/Menu`;
  }
  private get menuByDepartment(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/menu`;
  }
  constructor(private http: HttpClient) {}

  getEntireMenuTreeStructure(): Observable<MenuItemModel[]> {
    return this.http.get<MenuItemModel[]>(this.url);
  }

  getDocumentMenuRootStructure(): Observable<MenuItemModel[]> {
    return this.http.get<MenuItemModel[]>(`${this.url}/${ModuleNumbers.Document}`);
  }

  getSpecificMenuTreeStructure(module: number): Observable<MenuItemModel[]> {
    const url = `${this.menuByDepartment}/${module}`;
    if (module === ModuleNumbers.Maintenance) {
      return this.http.get<MenuItemModel[]>(this.urlComponentList);
    }
    return this.http.get<MenuItemModel[]>(url);
  }

  getMenuByDepartment(module: number): Observable<MenuItemModel[]> {
    return this.http.get<MenuItemModel[]>(`${this.menuByDepartment}/${module}`);
  }

  getMenuStructureElementById(id: string): Observable<MenuItemModel[]> {
    return this.http.get<MenuItemModel[]>(`${this.url}/${id}`);
  }

  getFlatMenuStructureByDepartmentAndModule(
    departmentId: string,
    module: number
  ): Observable<IMenuPlacement[]> {
    const url = `${this.tenantUrl}/${departmentId}/menu/${module}/flat`;
    return this.http.get<IMenuPlacement[]>(url);
  }

  createMenuStructure(menuStructure: MenuItemCreateModel): Observable<string> {
    if (menuStructure.departments === undefined || menuStructure.departments?.length === 0) {
      menuStructure.departments.push(getDepartmentId());
    }
    if (menuStructure.parentId === '1') {
      menuStructure.parentId = null;
    }
    return this.http.post<string>(this.url, menuStructure);
  }

  updateMenuStructure(menuStructure: MenuItemUpdateModel): Observable<string> {
    if (menuStructure.departments === undefined || menuStructure.departments?.length === 0) {
      menuStructure.departments.push(getDepartmentId());
    }
    if (menuStructure.parentId === '1') {
      menuStructure.parentId = null;
    }
    const url = `${this.url}/${menuStructure.id}`;
    return this.http.put<string>(url, menuStructure);
  }

  deleteMenuStructure(id: string): Observable<string> {
    const url = `${this.url}/${id}`;
    return this.http.delete<string>(url);
  }

  copyMenuStructure(model: CopyMenuModel): Observable<void> {
    const url = `${this.url}/copy`;
    return this.http.post<void>(url, model);
  }

  updateMenuStructureOrder(order: UpdateMenuStructureOrderRequest): Observable<void> {
    const url = `${this.url}/structure`;
    return this.http.put<void>(url, order);
  }
}
