import { Pipe, PipeTransform } from '@angular/core';
import { DocumentStatus } from 'src/app/document-user/models/document-status.enum';

@Pipe({
  name: 'documentStatusMap',
  pure: true,
  standalone: true,
})
export class DocumentStatusMapPipe implements PipeTransform {
  private documentStatusMapper = {
    [DocumentStatus.Draft]: 'draft',
    [DocumentStatus.Expired]: 'expired',
    [DocumentStatus.Expiring]: 'expiring',
    [DocumentStatus.Published]: 'published',
    [DocumentStatus.Rejected]: 'rejected',
    [DocumentStatus.UnderRevision]: 'underRevision',
    [DocumentStatus.ForApproval]: 'forApproval',
  };

  transform(documentStatus: DocumentStatus): string {
    return this.documentStatusMapper[documentStatus];
  }
}
