import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MainTableComponent } from './main-table/main-table.component';
import { MainTableExpandableRowComponent } from './main-table/main-table-expandable-row/main-table-expandable-row.component';
import { MainTableModuleSpecificTemplateComponent } from './main-table-module-specific-template/main-table-module-specific-template.component';
import { MainTablePaginatorComponent } from './main-table-paginator/main-table-paginator.component';
import { MainTableSearchComponent } from './main-table/main-table-search/main-table-search.component';
import { MaintenanceTemplateComponent } from './main-table-module-specific-template/table-module-templates/maintenance-templates/maintenance-template/maintenance-template.component';
import { MaintenanceTemplatesComponent } from './main-table-module-specific-template/table-module-templates/maintenance-templates/maintenance-templates.component';
import { AlertTemplateComponent } from './main-table-module-specific-template/table-module-templates/alert-template/alert-template.component';
import { CargoGearTemplateComponent } from './main-table-module-specific-template/table-module-templates/departments-template/cargo-gear-template.component';
import { CargoHoldTemplateComponent } from './main-table-module-specific-template/table-module-templates/departments-template/cargo-hold-template.component';
import { CertificateTemplateComponent } from './main-table-module-specific-template/table-module-templates/certificate-template/certificate-template.component';
import { CompetenceTemplateComponent } from './main-table-module-specific-template/table-module-templates/competence-template/competence-template.component';
import { ComponentHistoryTemplateComponent } from './main-table-module-specific-template/table-module-templates/maintenance-templates/component-history-template/component-history-template.component';
import { ContactPersonTemplateComponent } from './main-table-module-specific-template/table-module-templates/departments-template/contact-person-template.component';
import { ContactsTemplateComponent } from './main-table-module-specific-template/table-module-templates/tenant-template/contacts-template.component';
import { CrewManagementTemplateComponent } from './main-table-module-specific-template/table-module-templates/crew-management-template/crew-management-template.component';
import { CrewManagementUserTemplateComponent } from './main-table-module-specific-template/table-module-templates/crew-management-user-template/crew-management-user-template.component';
import { CrewManagementUserTemplatesComponent } from './main-table-module-specific-template/table-module-templates/crew-management-user-template/crew-management-user-templates.component';
import { DepartmentsTemplateComponent } from './main-table-module-specific-template/table-module-templates/departments-template/departments-template.component';
import { DepartmentsTemplatesComponent } from './main-table-module-specific-template/table-module-templates/departments-template/departments-templates.component';
import { DocumentOverviewTemplateComponent } from './main-table-module-specific-template/table-module-templates/document-template/document-overview-template.component';
import { DocumentStagesTemplateComponent } from './main-table-module-specific-template/table-module-templates/document-template/document-stages-template.component';
import { DocumentTemplatesComponent } from './main-table-module-specific-template/table-module-templates/document-template/document-templates.component';
import { DrillsLogTemplateComponent } from './main-table-module-specific-template/table-module-templates/drills-template/drills-log-template.component';
import { DrillsOverviewTemplateComponent } from './main-table-module-specific-template/table-module-templates/drills-template/drills-overview-template.component';
import { DrillsTemplatesComponent } from './main-table-module-specific-template/table-module-templates/drills-template/drills-templates.component';
import { DueListTemplateComponent } from './main-table-module-specific-template/table-module-templates/due-list-template/due-list-template.component';
import { EngineTemplateComponent } from './main-table-module-specific-template/table-module-templates/departments-template/engine-template.component';
import { FormsLibraryTemplateComponent } from './main-table-module-specific-template/table-module-templates/forms-library-template/forms-library-template.component';
import { MedicineTemplateComponent } from './main-table-module-specific-template/table-module-templates/medicine-template/medicine-template.component';
import { NonConformanceTemplateComponent } from './main-table-module-specific-template/table-module-templates/non-conformance-template/non-conformance-template.component';
import { OrderAdminTemplateComponent } from './main-table-module-specific-template/table-module-templates/order-admin-template/order-admin-template.component';
import { OrderUserTemplateComponent } from './main-table-module-specific-template/table-module-templates/order-user-template/order-user-template.component';
import { PaxOnBoardTemplateComponent } from './main-table-module-specific-template/table-module-templates/crew-management-user-template/pax-on-board-template.component';
import { PaxTemplateComponent } from './main-table-module-specific-template/table-module-templates/crew-management-user-template/pax-template.component';
import { PlannedActivityUserTemplateComponent } from './main-table-module-specific-template/table-module-templates/crew-management-user-template/planned-activity-user-template.component';
import { ProfileCompetenceTemplateComponent } from './main-table-module-specific-template/table-module-templates/profile-template/profile-competence-template.component';
import { RuntimeTemplateComponent } from './main-table-module-specific-template/table-module-templates/runtime-template/runtime-template.component';
import { StorageTemplateComponent } from './main-table-module-specific-template/table-module-templates/storage-template/storage-template.component';
import { TaskLogTemplateComponent } from './main-table-module-specific-template/table-module-templates/task-template/task-log-template.component';
import { TaskOverviewTemplateComponent } from './main-table-module-specific-template/table-module-templates/task-template/task-overview-template.component';
import { TaskTemplatesComponent } from './main-table-module-specific-template/table-module-templates/task-template/task-template.component';
import { TenantTemplatesComponent } from './main-table-module-specific-template/table-module-templates/tenant-template/tenant-templates.component';
import { UsersTemplateComponent } from './main-table-module-specific-template/table-module-templates/users-template/users-template.component';
import { DefaultCellComponent } from './main-table-module-specific-template/table-module-templates/default-cell.component';
import { MaterialModule } from '../../modules/material.module';
import { IsArrayPipe } from '../pipes/is-array.pipe';
import { ShouldHeaderBeDisplayedPipe } from '../pipes/should-header-be-displayed.pipe';
import { MapProductActionPipe } from '../pipes/map-product-action.pipe';
import { MapNonConformanceActionPipe } from '../pipes/map-non-conformance-action.pipe';
import { MapMedicineEventPipe } from '../pipes/map-medicine-event.pipe';
import { IsStringArrayPipe } from '../pipes/is-string-array.pipe';
import { OnBoardStatusMapPipe } from '../pipes/on-board-status-map.pipe';
import { DateDiffPipe } from '../pipes/date-diff.pipe';
import { SplitPipe } from '../pipes/split-text.pipe';
import { DaysBeforeExpiredPipe } from '../pipes/days-before-expired.pipe';
import { PassArgumentsToObjectsFunctionsPipe } from '../pipes/pass-arguments-to-objects-functions.pipe';
import { UIElementsModule } from '../ui-elements/ui-elements.module';
import { AppLoaderDirective } from '../directives/app-loader.directive';
import { AppStopClickPropagationDirective } from '../directives/stop-click-propagation.directive';
import { ResizeDirective } from '../directives/resize.directive';
import { IsSingleLevelPlacementPipe } from '../pipes/is-single-level-placement.pipe';
import { ProfileTemplatesComponent } from './main-table-module-specific-template/table-module-templates/profile-template/profile-templates.component';
import { ContainsPipe } from '../../core/pipes/contains.pipe';
import { MapProcurementAreaPipe } from '../pipes/map-procurement-area.pipe';
import { DelimitedCurrencyPipe } from '../pipes/delimited-currency.pipe';
import { EquivalentCompetencesTooltipComponent } from '../components/equivalent-competences-tooltip/equivalent-competences-tooltip.component';
import { DocumentRevisionsTemplateComponent } from './main-table-module-specific-template/table-module-templates/document-template/document-revisions-template.component';
import { ProductOwnerUsersTemplateComponent } from './main-table-module-specific-template/table-module-templates/product-owner-users-template/product-owner-users-template.component';
import { RiskAssessmentsTemplateComponent } from './main-table-module-specific-template/table-module-templates/risk-assessments-template/risk-assessments-template.component';
import { MapRiskAssessmentActionPipe } from '../pipes/map-risk-assessment-action.pipe';
import { DocumentStatusMapPipe } from '../pipes/document-status-map.pipe';
import { PrivilegeTemplatesTemplateComponent } from './main-table-module-specific-template/table-module-templates/privilege-template/privilege-templates-template.component';
import { MainTableColumnFilterComponent } from './main-table/main-table-column-filter/main-table-column-filter.component';
import { TripsTemplateComponent } from './main-table-module-specific-template/table-module-templates/crew-management-user-template/trips-template.component';
import { TripUserStatusPipe } from '../pipes/trip-user-status.pipe';
import { TextOverflowLabelComponent } from './main-table-module-specific-template/table-module-templates/cells/text-overflow-label/text-overflow-label.component';
import { PositionsCellComponent } from './main-table-module-specific-template/table-module-templates/cells/positions-cell/positions-cell.component';
import { QuantityTypesTemplateComponent } from './main-table-module-specific-template/table-module-templates/quantity-types-template/quantity-types-template.component';
import { ConfirmDialogDirective } from '../dialogs/confirm-dialog/confirm-dialog.directive';
import { PerformJobSparePartsTemplateComponent } from './main-table-module-specific-template/table-module-templates/maintenance-templates/perform-job-spare-parts-template/perform-job-spare-parts-template.component';
import { FullNamePipe } from '../pipes/full-name.pipe';

@NgModule({
  declarations: [
    AlertTemplateComponent,
    CargoGearTemplateComponent,
    CargoHoldTemplateComponent,
    CertificateTemplateComponent,
    CompetenceTemplateComponent,
    ComponentHistoryTemplateComponent,
    ContactPersonTemplateComponent,
    ContactsTemplateComponent,
    CrewManagementTemplateComponent,
    CrewManagementUserTemplateComponent,
    CrewManagementUserTemplatesComponent,
    DefaultCellComponent,
    DepartmentsTemplateComponent,
    DepartmentsTemplatesComponent,
    DocumentOverviewTemplateComponent,
    DocumentRevisionsTemplateComponent,
    DocumentStagesTemplateComponent,
    DocumentTemplatesComponent,
    DrillsLogTemplateComponent,
    DrillsOverviewTemplateComponent,
    DrillsTemplatesComponent,
    DueListTemplateComponent,
    EngineTemplateComponent,
    FormsLibraryTemplateComponent,
    MainTableComponent,
    MainTableExpandableRowComponent,
    MainTableColumnFilterComponent,
    MainTableModuleSpecificTemplateComponent,
    MainTablePaginatorComponent,
    MainTableSearchComponent,
    MaintenanceTemplateComponent,
    MaintenanceTemplatesComponent,
    MapProcurementAreaPipe,
    MedicineTemplateComponent,
    NonConformanceTemplateComponent,
    OrderAdminTemplateComponent,
    OrderUserTemplateComponent,
    PaxOnBoardTemplateComponent,
    PaxTemplateComponent,
    PlannedActivityUserTemplateComponent,
    ProductOwnerUsersTemplateComponent,
    ProfileCompetenceTemplateComponent,
    ProfileTemplatesComponent,
    PrivilegeTemplatesTemplateComponent,
    PositionsCellComponent,
    RiskAssessmentsTemplateComponent,
    RuntimeTemplateComponent,
    StorageTemplateComponent,
    TaskLogTemplateComponent,
    TaskOverviewTemplateComponent,
    TaskTemplatesComponent,
    TextOverflowLabelComponent,
    TenantTemplatesComponent,
    UsersTemplateComponent,
    TripsTemplateComponent,
    QuantityTypesTemplateComponent,
    PerformJobSparePartsTemplateComponent,
  ],
  exports: [MainTableComponent, MainTablePaginatorComponent, MainTableColumnFilterComponent],
  imports: [
    AppLoaderDirective,
    AppStopClickPropagationDirective,
    CommonModule,
    ConfirmDialogDirective,
    ContainsPipe,
    DateDiffPipe,
    DaysBeforeExpiredPipe,
    DelimitedCurrencyPipe,
    DocumentStatusMapPipe,
    EquivalentCompetencesTooltipComponent,
    IsArrayPipe,
    IsSingleLevelPlacementPipe,
    IsStringArrayPipe,
    FullNamePipe,
    MapMedicineEventPipe,
    MapNonConformanceActionPipe,
    MapProductActionPipe,
    MapRiskAssessmentActionPipe,
    MaterialModule,
    OnBoardStatusMapPipe,
    PassArgumentsToObjectsFunctionsPipe,
    ResizeDirective,
    RouterModule,
    ShouldHeaderBeDisplayedPipe,
    SplitPipe,
    TranslateModule,
    TripUserStatusPipe,
    UIElementsModule,
  ],
  providers: [CurrencyPipe],
})
export class MainTableModule {}
