import { Store } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { filter, catchError, switchMap } from 'rxjs/operators';
import { take, of, Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { TenantUserAccessState } from '../tenant-selector/state/tenant-user-access.state';
import { LoadTenantUserAccess } from '../tenant-selector/state/tenant-user-access.actions';

// eslint-disable-next-line no-use-before-define
let serviceInstance: StoreSnapshotService;

@Injectable({
  providedIn: 'root',
})
export class StoreSnapshotService {
  // this really should be called 'StaticDependencyService' but I'll leave it as is for now
  constructor(private store: Store, private zone: NgZone) {
    serviceInstance = this;
    this.getTenantId();
  }

  getTenantId(): string {
    return this.store.selectSnapshot(TenantUserAccessState.currentTenantId);
  }

  getDepartmentId(): string {
    return this.store.selectSnapshot(TenantUserAccessState.currentDepartmentId);
  }

  runOutsideAngular<T>(fn: () => T): T {
    return this.zone.runOutsideAngular(fn);
  }
}

export function getTenantId(): string {
  if (!serviceInstance || !serviceInstance.getTenantId()) {
    console.error('Tenant ID not found');
    return '';
  }

  return serviceInstance.getTenantId();
}

export function getDepartmentId(): string {
  if (!serviceInstance || !serviceInstance.getDepartmentId()) {
    console.error('Department ID not found');
    return '';
  }

  return serviceInstance.getDepartmentId();
}

export function runOutsideAngular<T>(fn: () => T): T {
  if (!serviceInstance) {
    console.error('StoreSnapshotService not found');
    return fn();
  }

  return serviceInstance.runOutsideAngular(fn);
}

export const REDIRECT_PATH_KEY = 'redirectPath';

function authenticate(oidcSecurityService: OidcSecurityService, store: Store) {
  return oidcSecurityService.checkAuth().pipe(
    filter(({ isAuthenticated }) => {
      const item = sessionStorage.getItem(REDIRECT_PATH_KEY);
      if (!item || item === '/') {
        sessionStorage.setItem(REDIRECT_PATH_KEY, window.location.pathname);
      }
      if (!isAuthenticated) {
        oidcSecurityService.authorize();
        return false;
      }
      return true;
    }),
    switchMap(() =>
      store.dispatch(new LoadTenantUserAccess()).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.Unauthorized) {
            sessionStorage.clear();
            oidcSecurityService.authorize();
          }
          return of(null);
        })
      )
    ),
    take(1)
  );
}

export function initApp(
  oidcSecurityService: OidcSecurityService,
  store: Store
): () => Observable<any> {
  return () => authenticate(oidcSecurityService, store);
}
