import { IAddress } from '../../shared/models/address.model';

export interface IContact {
  firstName: string;
  lastName: string;
  position: string;
  prefix: number;
  phoneNumber: number;
  email: string;
}

export interface IModule {
  module: number;
  soldFor?: number;
  selected?: boolean;
  id: number;
}

export interface ITenant {
  name: string;
  soldBy: number | string;
  accountType: number;
  isDPAService: boolean;
  selectDPA: string[];
  modules: IModule[];
  moduleList: string[];
  address: IAddress;
  contacts: IContact[];
  isImplementing: boolean;
  logo: string | File;
  id: string;
  amountOfDepartments: number;
  status: string;
  predefinedValues: File;
  organizationNumber: string;
}
export interface ITenantUpdate extends ITenant {
  isLogoChanged: boolean;
}

export enum ChangeTypeEnum {
  Created = 1,
  Edit = 2,
  Status = 3,
  Deleted = 4,
}

export const ChangeType = {
  [ChangeTypeEnum.Created]: 'created',
  [ChangeTypeEnum.Status]: 'status',
  [ChangeTypeEnum.Edit]: 'edit',
  [ChangeTypeEnum.Deleted]: 'deleted',
};

export interface Change {
  changeType: ChangeTypeEnum;
  field: string;
  newValue: string;
  oldValue: string;
}

export interface Record {
  date: string;
  changes: Change[];
  by: string;
}

export interface ITenantLog {
  records: Record[];
}
