<h4 *ngIf="label">{{ label }}</h4>
<mat-radio-group
  aria-label="label"
  [formControl]="control"
  *ngIf="control"
  class="example-radio-group"
  [ngClass]="layout"
  (change)="onChange($event)"
>
  <mat-radio-button
    *ngFor="let option of options"
    [value]="option.id"
    [checked]="option.id === selected"
  >
    <ng-container *ngIf="optionLabelTemplate; else defaultLabel">
      <ng-container
        *ngTemplateOutlet="optionLabelTemplate; context: { label: option.name }"
      ></ng-container>
    </ng-container>
    <ng-template #defaultLabel>{{ option.name | translate }}</ng-template>
  </mat-radio-button>
</mat-radio-group>
