import { ModuleName } from 'src/app/shared/enums/enums';
import {
  DueListRoutes,
  RoutesEnum,
  UserCertificateRoutes,
  AdminRoutesEnum,
  AdminDepartmentRoutes,
  AdminAlertRoutes,
  AdminUsersRoutes,
  AdminCrewManagementRoutes,
  AdminCompetenceRoutes,
  AdminCertificateRoutes,
  AdminDocumentsRoutes,
  AdminNonConformanceRoutes,
  AdminMaintenanceRoutes,
  AdminStorageRoutes,
  AdminMedicineRoutes,
  UserCrewManagementRoutes,
  AdminOrderRoutes,
  UserDocumentsRoutes,
  UserNonConformancesRoutes,
  AdminRiskAssessmentRoutes,
  AdminWorkAndRestRoutes,
} from '../../enums/routes.enum';

// user routes

export const USER_ALERTS_MENU_LIST_ROUTES = {
  name: 'alerts',
  footerModule: false,
  privileges: [],
  children: [
    {
      name: 'dueList',
      route: DueListRoutes.Main,
      icon: 'format_list_bulleted',
      module: ModuleName.DUE_LIST,
      privileges: [],
      children: [],
    },
    // {
    //   name: 'messages',
    //   route: RoutesEnum.Messages,
    //   icon: 'mark_chat_unread',
    //   module: ModuleName.ALERT,
    //   children: [],
    // },
  ],
};
export const USER_CREW_MENU_LIST_ROUTES = {
  name: 'crew',
  footerModule: false,
  privileges: ['PLANNED-ACTIVITY_READ'],
  children: [
    {
      name: 'shiftAndManning',
      route: UserCrewManagementRoutes.Main,
      icon: 'group_add',
      module: ModuleName.CREWMANAGEMENT,
      privileges: ['PLANNED-ACTIVITY_READ'],
      children: [],
    },
  ],
};
export const USER_DOCUMENTS_MENU_LIST_ROUTES = {
  name: 'documentation',
  footerModule: false,
  privileges: ['CERTIFICATE_READ', 'DOCUMENT_READ', 'NON-CONFORMANCE_READ', 'RISK-ASSESSMENT_READ'],
  children: [
    {
      name: 'certificates',
      route: UserCertificateRoutes.Main,
      icon: 'description',
      module: ModuleName.COMPETENCE,
      privileges: ['CERTIFICATE_READ'],
      children: [],
    },
    {
      name: 'documents',
      route: UserDocumentsRoutes.Main,
      icon: 'folder_open',
      module: ModuleName.DOCUMENT,
      privileges: ['DOCUMENT_READ'],
      children: [],
    },
    {
      name: 'riskAssessment',
      route: RoutesEnum.RiskAssessments,
      icon: 'balance',
      module: ModuleName.RISKASSESSMENT,
      privileges: ['RISK-ASSESSMENT_READ'],
      children: [],
    },
    // {
    //   name: 'audits',
    //   route: RoutesEnum.Audits,
    //   icon: 'plagiarism',
    //   module: ModuleName.COMPETENCE,
    //   children: [],
    // },
    {
      name: 'nonConformances',
      route: UserNonConformancesRoutes.Open,
      icon: 'remove_moderator',
      module: ModuleName.NONCONFORMANCE,
      privileges: ['NON-CONFORMANCE_READ'],
      children: [],
    },
  ],
};
export const USER_SHIP_MENU_LIST_ROUTES = {
  name: 'ship',
  footerModule: false,
  privileges: [
    'RUNTIME_READ',
    'MAINTENANCE-JOB_READ',
    'STORAGE-PRODUCT_READ',
    'MEDICINE_READ',
    'DRILL_READ',
    'TASK_READ',
    'ORDER_READ',
  ],
  children: [
    {
      name: 'runTime',
      route: RoutesEnum.RunTime,
      icon: 'update',
      module: ModuleName.COMPETENCE,
      privileges: ['RUNTIME_READ'],
      children: [],
    },
    {
      name: 'maintenance',
      route: RoutesEnum.Maintenance,
      icon: 'build',
      module: ModuleName.MAINTENANCE,
      privileges: ['MAINTENANCE-JOB_READ', 'RUNTIME_READ'],
      checkAllPrivileges: true,
      children: [],
    },
    // {
    //   name: 'emissions',
    //   route: RoutesEnum.Emissions,
    //   icon: 'air',
    //   module: ModuleName.COMPETENCE,
    //   children: [],
    // },
    {
      name: 'storage',
      route: RoutesEnum.Storage,
      icon: 'view_in_ar',
      module: ModuleName.STORAGE,
      privileges: ['STORAGE-PRODUCT_READ'],
      children: [],
    },
    {
      name: 'medicines',
      route: RoutesEnum.Medicines,
      icon: 'local_hospital',
      module: ModuleName.MEDICINE,
      privileges: ['MEDICINE_READ'],
      children: [],
    },
    {
      name: 'drills',
      route: RoutesEnum.Drills,
      icon: 'campaign',
      module: ModuleName.DRILL,
      privileges: ['DRILL_READ'],
      children: [],
    },
    {
      name: 'tasks',
      route: RoutesEnum.Checklists,
      icon: 'checklist_rtl',
      module: ModuleName.COMPETENCE,
      privileges: ['TASK_READ'],
      children: [],
    },
    {
      name: 'purchase',
      route: RoutesEnum.Purchase,
      icon: 'shopping_cart',
      module: ModuleName.ORDER,
      privileges: ['ORDER_READ'],
      children: [],
    },
  ],
};
export const USER_OTHER_MENU_LIST_ROUTES = {
  name: 'other',
  footerModule: true,
  privileges: [
    'DEPARTMENT_READ',
    'DEPARTMENT-GROUP_READ',
    'ALERT_READ',
    'QUANTITY-TYPE_READ',
    'USER_READ',
    'PAX_READ',
    'ACTIVITY_READ',
    'SHIFT_READ',
    'COMPETENCE_READ',
    'COMPETENCE-GROUP_READ',
    'POSITION_READ',
    'POSITION-GROUP_READ',
    'USER-COMPETENCE_MATRIXADMIN',
    'MENU_UPDATE',
    'NON-CONFORMANCE-TYPE_READ',
    'SEVERITY_READ',
    'ORDER-TYPE_READ',
    'SUPPLIER_READ',
    'PURCHASER_READ',
    'ACCOUNT-GROUP_READ',
    'ACCOUNT_READ',
    'ORDERS-SETTINGS_READ',
    'DELIVERY-CONDITION_READ',
    'MAINTENANCE-JOB-TYPE_READ',
    'COMPONENT_READ',
    'STORAGE-PRODUCT-CATEGORY_READ',
    'MEDICINE-CATEGORY_READ',
    'FORM-LIBRARY_READ',
  ],
  children: [
    // {
    //   name: 'help',
    //   route: SupportRoutes.Help,
    //   icon: 'help',
    //   module: ModuleName.COMPETENCE,
    //   children: [],
    // },
    {
      name: 'adminSettings',
      route: AdminRoutesEnum.Admin,
      icon: 'settings',
      module: ModuleName.COMPETENCE,
      privileges: [
        'DEPARTMENT_READ',
        'DEPARTMENT-GROUP_READ',
        'ALERT_READ',
        'QUANTITY-TYPE_READ',
        'USER_READ',
        'PAX_READ',
        'ACTIVITY_READ',
        'SHIFT_READ',
        'COMPETENCE_READ',
        'COMPETENCE-GROUP_READ',
        'POSITION_READ',
        'POSITION-GROUP_READ',
        'USER-COMPETENCE_MATRIXADMIN',
        'MENU_UPDATE',
        'NON-CONFORMANCE-TYPE_READ',
        'SEVERITY_READ',
        'ORDER-TYPE_READ',
        'SUPPLIER_READ',
        'PURCHASER_READ',
        'ACCOUNT-GROUP_READ',
        'ACCOUNT_READ',
        'ORDERS-SETTINGS_READ',
        'DELIVERY-CONDITION_READ',
        'MAINTENANCE-JOB-TYPE_READ',
        'COMPONENT_READ',
        'COMPONENT-GROUP_READ',
        'STORAGE-PRODUCT-CATEGORY_READ',
        'MEDICINE-CATEGORY_READ',
        'FORM-LIBRARY_READ',
        'PRIVILEGE-TEMPLATE_READ',
      ],
      children: [],
    },
  ],
};
export const USER_MENU_LIST_ROUTES = [
  USER_ALERTS_MENU_LIST_ROUTES,
  USER_CREW_MENU_LIST_ROUTES,
  USER_DOCUMENTS_MENU_LIST_ROUTES,
  USER_SHIP_MENU_LIST_ROUTES,
  USER_OTHER_MENU_LIST_ROUTES,
];

// admin routes
export const ADMIN_DEPARTMENT_SUBMODULES = [
  {
    name: 'department',
    route: AdminDepartmentRoutes.Main,
    module: ModuleName.DEPARTMENT,
    privileges: ['DEPARTMENT_READ'],
    children: [],
  },
  {
    name: 'groups',
    route: AdminDepartmentRoutes.Groups,
    module: ModuleName.DEPARTMENT,
    privileges: ['DEPARTMENT-GROUP_READ'],
    children: [],
  },
];
export const ADMIN_USERS_SUBMODULES = [
  {
    name: 'users',
    route: AdminUsersRoutes.Users,
    module: ModuleName.USER,
    privileges: ['USER_READ'],
    children: [],
  },
  {
    name: 'pax',
    route: AdminUsersRoutes.Paxes,
    module: ModuleName.USER,
    privileges: ['PAX_READ'],
    children: [],
  },
];
export const ADMIN_COMPETENCE_SUBMODULES = [
  {
    name: 'competence',
    route: AdminCompetenceRoutes.Main,
    module: ModuleName.COMPETENCE,
    privileges: ['COMPETENCE_READ'],
    children: [],
  },
  {
    name: 'competenceGroups',
    route: AdminCompetenceRoutes.Groups,
    module: ModuleName.COMPETENCE,
    privileges: ['COMPETENCE-GROUP_READ'],
    children: [],
  },
  {
    name: 'jobPositions',
    route: AdminCompetenceRoutes.JobPositions,
    module: ModuleName.COMPETENCE,
    privileges: ['POSITION_READ'],
    children: [],
  },
  {
    name: 'jobPositionGroups',
    route: AdminCompetenceRoutes.JobPositionGroups,
    module: ModuleName.COMPETENCE,
    privileges: ['POSITION-GROUP_READ'],
    children: [],
  },
  {
    name: 'privilegeTemplates',
    route: AdminCompetenceRoutes.PrivilegeTemplates,
    module: ModuleName.COMPETENCE,
    privileges: ['PRIVILEGE-TEMPLATE_READ'],
    children: [],
  },
  {
    name: 'competenceMatrix',
    route: AdminCompetenceRoutes.Matrix,
    module: ModuleName.COMPETENCE,
    privileges: ['USER-COMPETENCE_MATRIXADMIN'],
    children: [],
  },
];
export const ADMIN_MAINTENANCE_SUBMODULES = [
  {
    name: 'jobTypes',
    route: AdminMaintenanceRoutes.JobTypes,
    module: ModuleName.MAINTENANCE,
    privileges: ['MAINTENANCE-JOB-TYPE_READ'],
    children: [],
  },
  {
    name: 'components',
    route: AdminMaintenanceRoutes.Components,
    module: ModuleName.MAINTENANCE,
    privileges: ['COMPONENT_READ', 'COMPONENT-GROUP_READ'],
    children: [],
  },
];
export const ADMIN_NONCONFORMANCE_SUBMODULES = [
  {
    name: 'nonConformanceTypes',
    route: AdminNonConformanceRoutes.Types,
    module: ModuleName.NONCONFORMANCE,
    privileges: ['NON-CONFORMANCE-TYPE_READ'],
    children: [],
  },
  {
    name: 'severities',
    route: AdminNonConformanceRoutes.SeverityTypes,
    module: ModuleName.NONCONFORMANCE,
    privileges: ['SEVERITY_READ'],
    children: [],
  },
  {
    name: 'seaState',
    route: AdminNonConformanceRoutes.SeaState,
    module: ModuleName.NONCONFORMANCE,
    privileges: ['NON-CONFORMANCE-SEA-STATE_READ'],
    children: [],
  },
  {
    name: 'weatherInformation',
    route: AdminNonConformanceRoutes.WeatherInformation,
    module: ModuleName.NONCONFORMANCE,
    privileges: ['NON-CONFORMANCE-WEATHER-INFORMATION_READ'],
    children: [],
  },
  {
    name: 'locationOnDepartment',
    route: AdminNonConformanceRoutes.LocationOnDepartment,
    module: ModuleName.NONCONFORMANCE,
    privileges: ['NON-CONFORMANCE-LOCATION_READ'],
    children: [],
  },
  {
    name: 'cause',
    route: AdminNonConformanceRoutes.Cause,
    module: ModuleName.NONCONFORMANCE,
    privileges: ['NON-CONFORMANCE-CAUSE_READ'],
    children: [],
  },
];
export const ADMIN_CREW_MANAGEMENT_SUBMODULES = [
  {
    name: 'activities',
    route: AdminCrewManagementRoutes.Activities,
    module: ModuleName.CREWMANAGEMENT,
    privileges: ['ACTIVITY_READ'],
    children: [],
  },
  {
    name: 'shifts',
    route: AdminCrewManagementRoutes.Shifts,
    module: ModuleName.CREWMANAGEMENT,
    privileges: ['SHIFT_READ'],
    children: [],
  },
];
export const ADMIN_DOCUMENTS_SUBMODULES = [
  {
    name: 'structures',
    route: AdminDocumentsRoutes.Structure,
    module: ModuleName.DOCUMENT,
    privileges: ['MENU_READ'],
    children: [],
  },
  // {
  //   name: 'settings',
  //   route: AdminDocumentsRoutes.Settings,
  //   module: ModuleName.DOCUMENT,
  //   children: [],
  // },
];
export const ADMIN_MEDICINE_SUBMODULES = [
  {
    name: 'structure',
    route: AdminMedicineRoutes.Structure,
    module: ModuleName.MEDICINE,
    privileges: ['MENU_READ'],
    children: [],
  },
  {
    name: 'medicineCategories',
    route: AdminMedicineRoutes.Categories,
    module: ModuleName.MEDICINE,
    privileges: ['MEDICINE-CATEGORY_READ'],
    children: [],
  },
];
export const ADMIN_STORAGE_SUBMODULES = [
  {
    name: 'structure',
    route: AdminStorageRoutes.Structure,
    module: ModuleName.STORAGE,
    privileges: ['MENU_READ'],
    children: [],
  },
  {
    name: 'storageCategories',
    route: AdminStorageRoutes.Categories,
    module: ModuleName.STORAGE,
    privileges: ['STORAGE-PRODUCT-CATEGORY_READ'],
    children: [],
  },
  {
    name: 'productTypes',
    route: AdminStorageRoutes.ProductTypes,
    module: ModuleName.STORAGE,
    privileges: ['PRODUCT-TYPE_READ'],
    children: [],
  },
];
export const ADMIN_ORDER_SUBMODULES = [
  {
    name: 'orderTypes',
    route: AdminOrderRoutes.OrderTypes,
    module: ModuleName.ORDER,
    privileges: ['ORDER-TYPE_READ'],
    children: [],
  },
  {
    name: 'suppliers',
    route: AdminOrderRoutes.Suppliers,
    module: ModuleName.ORDER,
    privileges: ['SUPPLIER_READ'],
    children: [],
  },
  {
    name: 'purchasers',
    route: AdminOrderRoutes.Purchasers,
    module: ModuleName.ORDER,
    privileges: ['PURCHASER_READ'],
    children: [],
  },
  {
    name: 'accountGroups',
    route: AdminOrderRoutes.AccountGroups,
    module: ModuleName.ORDER,
    privileges: ['ACCOUNT-GROUP_READ'],
    children: [],
  },
  {
    name: 'accounts',
    route: AdminOrderRoutes.Accounts,
    module: ModuleName.ORDER,
    privileges: ['ACCOUNT_READ'],
    children: [],
  },
  {
    name: 'deliveryConditions',
    route: AdminOrderRoutes.DeliveryConditions,
    module: ModuleName.ORDER,
    privileges: ['DELIVERY-CONDITION_READ'],
    children: [],
  },
  {
    name: 'settings',
    route: AdminOrderRoutes.Settings,
    module: ModuleName.ORDER,
    privileges: ['ORDERS-SETTINGS_READ'],
    children: [],
  },
];
export const ADMIN_WORK_AND_REST_SUBMODULES = [
  {
    name: 'workSchedule',
    route: `${AdminWorkAndRestRoutes.WorkSchedule}`,
    module: ModuleName.WORKANDREST,
    privileges: [],
    children: [],
  },
  {
    name: 'workPlan',
    route: `${AdminWorkAndRestRoutes.WorkPlan}`,
    module: ModuleName.WORKANDREST,
    privileges: [],
    children: [],
  },
  {
    name: 'overtimeTypes',
    route: `${AdminWorkAndRestRoutes.OvertimeTypes}`,
    module: ModuleName.WORKANDREST,
    privileges: [],
    children: [],
  },
];
export const ADMIN_RISK_ASSESSMENT_MODULES = [
  {
    name: 'structure',
    route: AdminRiskAssessmentRoutes.Structure,
    module: ModuleName.RISKASSESSMENT,
    privileges: ['MENU_READ'],
    children: [],
  },
  {
    name: 'hazards',
    route: AdminRiskAssessmentRoutes.Hazards,
    module: ModuleName.RISKASSESSMENT,
    privileges: ['RISK-ASSESSMENT-HAZARD_READ'],
    children: [],
  },
  {
    name: 'consequences',
    route: AdminRiskAssessmentRoutes.Consequences,
    module: ModuleName.RISKASSESSMENT,
    privileges: ['RISK-ASSESSMENT-CONSEQUENCE_READ'],
    children: [],
  },
  {
    name: 'locations',
    route: AdminRiskAssessmentRoutes.Locations,
    module: ModuleName.RISKASSESSMENT,
    privileges: ['RISK-ASSESSMENT-LOCATION_READ'],
    children: [],
  },
  {
    name: 'mitigatingMeasures',
    route: AdminRiskAssessmentRoutes.MitigatingMeasures,
    module: ModuleName.RISKASSESSMENT,
    privileges: ['RISK-ASSESSMENT-MITIGATING-MEASURES_READ'],
    children: [],
  },
  {
    name: 'operationTypes',
    route: AdminRiskAssessmentRoutes.OperationTypes,
    module: ModuleName.RISKASSESSMENT,
    privileges: ['RISK-ASSESSMENT-OPERATION-TYPE_READ'],
    children: [],
  },
  // {
  //   name: 'settings',
  //   route: AdminRiskAssessmentRoutes.Settings,
  //   module: ModuleName.RISKASSESSMENT,
  //   children: [],
  // },
];
export const ADMIN_CERTIFICATE_SUBMODULES = [
  {
    name: 'structure',
    route: AdminCertificateRoutes.Structure,
    module: ModuleName.CERTIFICATE,
    privileges: ['MENU_READ'],
    children: [],
  },
  {
    name: 'certificateTypes',
    route: AdminCertificateRoutes.CertificateTypes,
    module: ModuleName.CERTIFICATE,
    privileges: ['CERTIFICATE-TYPE_READ'],
    children: [],
  },
];

export const ADMIN_TENANT_SETTINGS_MENU_LIST_ROUTES = {
  name: 'tenantSettings',
  footerModule: false,
  privileges: ['DEPARTMENT_READ', 'DEPARTMENT-GROUP_READ', 'ALERT_READ', 'QUANTITY-TYPE_READ'],
  children: [
    {
      name: 'department',
      route: `${AdminDepartmentRoutes.Main}`,
      module: ModuleName.DEPARTMENT,
      privileges: ['DEPARTMENT_READ', 'DEPARTMENT-GROUP_READ'],
      children: ADMIN_DEPARTMENT_SUBMODULES,
    },
    {
      name: 'alerts',
      route: `${AdminAlertRoutes.Main}`,
      module: ModuleName.ALERT,
      privileges: ['ALERT_READ'],
      children: [],
    },
    {
      name: 'quantityTypes',
      route: `${AdminRoutesEnum.Admin}/${AdminRoutesEnum.QuantityTypes}`,
      module: ModuleName.QUANTITYTYPES,
      privileges: ['QUANTITY-TYPE_READ'],
      children: [],
    },
  ],
};
export const ADMIN_CREW_MANAGEMENT_AND_PAX_MENU_LIST_ROUTES = {
  name: 'crewManagementAndPax',
  footerModule: false,
  privileges: [
    'USER_READ',
    'PAX_READ',
    'ACTIVITY_READ',
    'SHIFT_READ',
    'COMPETENCE_READ',
    'COMPETENCE-GROUP_READ',
    'POSITION_READ',
    'POSITION-GROUP_READ',
    'USER-COMPETENCE_MATRIXADMIN',
  ],
  children: [
    {
      name: 'usersAndPax',
      route: AdminUsersRoutes.Users,
      module: ModuleName.USER,
      privileges: ['USER_READ', 'PAX_READ'],
      children: ADMIN_USERS_SUBMODULES,
    },
    {
      name: 'crewManagement',
      route: `${AdminCrewManagementRoutes.Activities}`,
      module: ModuleName.CREWMANAGEMENT,
      privileges: ['ACTIVITY_READ', 'SHIFT_READ'],
      children: ADMIN_CREW_MANAGEMENT_SUBMODULES,
    },
    {
      name: 'competence',
      route: `${AdminCompetenceRoutes.Main}`,
      module: ModuleName.COMPETENCE,
      privileges: [
        'COMPETENCE_READ',
        'COMPETENCE-GROUP_READ',
        'POSITION_READ',
        'POSITION-GROUP_READ',
        'USER-COMPETENCE_MATRIXADMIN',
      ],
      children: ADMIN_COMPETENCE_SUBMODULES,
    },
    // {
    //   name: 'workAndRest',
    //   route: `${AdminWorkAndRestRoutes.Main}`,
    //   module: ModuleName.WORKANDREST,
    //   children: [
    //     {
    //       name: 'workSchedule',
    //       route: `${AdminWorkAndRestRoutes.WorkSchedule}`,
    //       module: ModuleName.WORKANDREST,
    //       children: [],
    //     },
    //     {
    //       name: 'workPlan',
    //       route: `${AdminWorkAndRestRoutes.WorkPlan}`,
    //       module: ModuleName.WORKANDREST,
    //       children: [],
    //     },
    //     {
    //       name: 'overtimeTypes',
    //       route: `${AdminWorkAndRestRoutes.OvertimeTypes}`,
    //       module: ModuleName.WORKANDREST,
    //       children: [],
    //     },
    //   ],
    // },
  ],
};
export const ADMIN_DOCUMENTATION_AND_REPORTS_MENU_LIST_ROUTES = {
  name: 'documentationAndReports',
  footerModule: false,
  privileges: [
    'MENU_UPDATE',
    'NON-CONFORMANCE-TYPE_READ',
    'SEVERITY_READ',
    'ORDER-TYPE_READ',
    'SUPPLIER_READ',
    'PURCHASER_READ',
    'ACCOUNT-GROUP_READ',
    'ACCOUNT_READ',
    'ORDERS-SETTINGS_READ',
    'DELIVERY-CONDITION_READ',
    'CERTIFICATE-TYPE_READ',
  ],
  children: [
    // {
    //   name: 'audit',
    //   route: 'admin/audit',
    //   module: ModuleName.NONCONFORMANCE,
    //   children: [],
    // },
    {
      name: 'certificate',
      route: AdminCertificateRoutes.Structure,
      module: ModuleName.CERTIFICATE,
      privileges: ['MENU_UPDATE', 'CERTIFICATE-TYPE_READ'],
      children: ADMIN_CERTIFICATE_SUBMODULES,
    },
    {
      name: 'document',
      route: AdminDocumentsRoutes.Structure,
      module: ModuleName.DOCUMENT,
      privileges: ['MENU_UPDATE'],
      children: [
        {
          name: 'structures',
          route: AdminDocumentsRoutes.Structure,
          module: ModuleName.DOCUMENT,
          privileges: ['MENU_UPDATE'],
          children: [],
        },
        // {
        //   name: 'settings',
        //   route: AdminDocumentsRoutes.Settings,
        //   module: ModuleName.DOCUMENT,
        //   children: [],
        // },
      ],
    },
    {
      name: 'purchase',
      route: AdminOrderRoutes.OrderTypes,
      module: ModuleName.ORDER,
      privileges: [
        'ORDER-TYPE_READ',
        'SUPPLIER_READ',
        'PURCHASER_READ',
        'ACCOUNT-GROUP_READ',
        'ACCOUNT_READ',
        'ORDERS-SETTINGS_READ',
        'DELIVERY-CONDITION_READ',
      ],
      children: ADMIN_ORDER_SUBMODULES,
    },
    {
      name: 'nonConformance',
      route: AdminNonConformanceRoutes.Types,
      module: ModuleName.NONCONFORMANCE,
      privileges: ['NON-CONFORMANCE-TYPE_READ', 'SEVERITY_READ'],
      children: ADMIN_NONCONFORMANCE_SUBMODULES,
    },
    {
      name: 'riskAssessment',
      route: AdminRiskAssessmentRoutes.Structure,
      module: ModuleName.RISKASSESSMENT,
      privileges: [
        'RISK-ASSESSMENT-HAZARD_READ',
        'RISK-ASSESSMENT-LOCATION_READ',
        'RISK-ASSESSMENT-CONSEQUENCE_READ',
        'RISK-ASSESSMENT-MITIGATING-MEASURES_READ',
        'RISK-ASSESSMENT-OPERATION-TYPE_READ',
      ],
      children: ADMIN_RISK_ASSESSMENT_MODULES,
    },
  ],
};
export const ADMIN_MAINTENANCE_AND_STORAGE_MENU_LIST_ROUTES = {
  name: 'maintenanceAndStorage',
  footerModule: false,
  privileges: [
    'MAINTENANCE-JOB-TYPE_READ',
    'COMPONENT_READ',
    'COMPONENT-GROUP_READ',
    'MENU_UPDATE',
    'STORAGE-PRODUCT-CATEGORY_READ',
    'MEDICINE-CATEGORY_READ',
  ],
  children: [
    {
      name: 'maintenance',
      route: `${AdminRoutesEnum.Admin}/maintenance`,
      module: ModuleName.MAINTENANCE,
      privileges: ['MAINTENANCE-JOB-TYPE_READ', 'COMPONENT_READ', 'COMPONENT-GROUP_READ'],
      children: ADMIN_MAINTENANCE_SUBMODULES,
    },
    {
      name: 'storage',
      route: `${AdminRoutesEnum.Admin}/storage`,
      module: ModuleName.STORAGE,
      privileges: ['MENU_UPDATE', 'STORAGE-PRODUCT-CATEGORY_READ'],
      children: ADMIN_STORAGE_SUBMODULES,
    },
    {
      name: 'medicine',
      route: `${AdminRoutesEnum.Admin}/medicine`,
      module: ModuleName.MEDICINE,
      privileges: ['MENU_UPDATE', 'MEDICINE-CATEGORY_READ'],
      children: ADMIN_MEDICINE_SUBMODULES,
    },
  ],
};
export const ADMIN_FORMS_AND_DRILLS_MENU_LIST_ROUTES = {
  name: 'formAndDrills',
  footerModule: false,
  privileges: ['FORM-LIBRARY_READ', 'MENU_UPDATE'],
  children: [
    {
      name: 'forms',
      route: `${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Forms}`,
      module: ModuleName.COMPETENCE,
      privileges: ['FORM-LIBRARY_READ'],
      children: [],
    },
    {
      name: 'drills',
      route: `${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Drills}`,
      module: ModuleName.NONCONFORMANCE,
      privileges: ['MENU_UPDATE'],
      children: [],
    },
    {
      name: 'tasks',
      route: `${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Tasks}`,
      module: ModuleName.NONCONFORMANCE,
      privileges: ['MENU_UPDATE'],
      children: [],
    },
  ],
};
export const ADMIN_PRODUCT_OWNER_MENU_LIST_ROUTES = {
  name: 'productOwner',
  footerModule: false,
  privileges: [],
  children: [
    {
      name: 'tenant',
      route: `${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Tenant}`,
      module: ModuleName.TENANT,
      privileges: [],
      children: [],
    },
    {
      name: 'productOwner',
      route: `${AdminRoutesEnum.Admin}/${AdminRoutesEnum.ProductOwner}`,
      module: ModuleName.PRODUCTOWNER,
      privileges: [],
      children: [],
    },
    {
      name: 'role',
      route: `${AdminRoutesEnum.Admin}/${AdminRoutesEnum.Role}`,
      module: ModuleName.COMPETENCE,
      privileges: [],
      children: [],
    },
    {
      name: 'users',
      route: `${AdminRoutesEnum.Admin}/${AdminRoutesEnum.ProductOwnerUsers}`,
      module: ModuleName.USER,
      privileges: [],
      children: [],
    },
  ],
};
export const ADMIN_MENU_LIST_ROUTES = [
  ADMIN_TENANT_SETTINGS_MENU_LIST_ROUTES,
  ADMIN_CREW_MANAGEMENT_AND_PAX_MENU_LIST_ROUTES,
  ADMIN_DOCUMENTATION_AND_REPORTS_MENU_LIST_ROUTES,
  ADMIN_MAINTENANCE_AND_STORAGE_MENU_LIST_ROUTES,
  ADMIN_FORMS_AND_DRILLS_MENU_LIST_ROUTES,
  ADMIN_PRODUCT_OWNER_MENU_LIST_ROUTES,
];

export function getAdminRoutesWithChildren(): string[] {
  const routes: string[] = [];
  ADMIN_MENU_LIST_ROUTES.map((r) => r.children)
    .flat()
    .forEach((route) => {
      if (route.children.length) {
        routes.push(route.route, ...route.children.map((r) => r.route));
      }
    });
  return routes;
}
