import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { AdminCompetenceRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from 'src/app/shared/models/id-name';
import { JobPositionModel } from '../model/job-position.model';
import { JobPositionApiService } from '../services/job-position-api.service';
import {
  AddJobPosition,
  DeleteJobPosition,
  GetJobPositionById,
  LoadAllJobPositions,
  LoadAllJobPositionsForDepartment,
  LoadAllJobPositionsWithDepartments,
  LoadAllJobPositionsForDepartmentAndGroups,
  LoadJobPositions,
  UpdateJobPosition,
} from './job-position.actions';

export interface JobPositionStateModel {
  jobPositions: JobPositionModel[] | undefined;
  currentJobPosition: JobPositionModel | undefined;
  allJobPositions: IdName[];
  jobPositionTotalPages: number;
  jobPositionsForDepartment: IdName[] | undefined;
  jobPositionsForDepartmentAndGroups: IdName[] | undefined;
}

const JOB_POSITION_STATE_TOKEN = new StateToken<JobPositionStateModel>('jobPosition');

@State<JobPositionStateModel>({
  name: JOB_POSITION_STATE_TOKEN,
  defaults: {
    jobPositions: undefined,
    currentJobPosition: undefined,
    allJobPositions: [],
    jobPositionTotalPages: 0,
    jobPositionsForDepartment: undefined,
    jobPositionsForDepartmentAndGroups: undefined,
  },
})
@Injectable()
export class JobPositionState {
  constructor(private service: JobPositionApiService) {}

  @Action(LoadJobPositions, { cancelUncompleted: true })
  loadJobPositions(
    { getState, setState }: StateContext<JobPositionStateModel>,
    { filter }: LoadJobPositions
  ) {
    return this.service.getJobPositionList(filter).pipe(
      tap((response: ApiResponse<JobPositionModel[]>) => {
        const state = getState();
        setState({
          ...state,
          jobPositions: response.data,
          jobPositionTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllJobPositions, { cancelUncompleted: true })
  loadAllJobPositions(ctx: StateContext<JobPositionStateModel>) {
    return this.service.getAllJobPositions().pipe(
      tap((allJobPositions: IdName[]) => {
        ctx.setState(patch({ allJobPositions }));
      })
    );
  }

  @Action(LoadAllJobPositionsWithDepartments, { cancelUncompleted: true })
  loadAllJobPositionsWithDepartments(ctx: StateContext<JobPositionStateModel>) {
    return this.service.getAllJobPositionsWithDepartments().pipe(
      tap((jobPositions: JobPositionModel[]) => {
        ctx.setState(patch({ jobPositions }));
      })
    );
  }

  @Action(LoadAllJobPositionsForDepartment, { cancelUncompleted: true })
  loadAllJobPositionsForDepartment(
    { getState, setState }: StateContext<JobPositionStateModel>,
    { departmentId }: LoadAllJobPositionsForDepartment
  ) {
    return this.service.getAllJobPositionsForDepartment(departmentId).pipe(
      tap((response: IdName[]) => {
        const state = getState();
        setState({
          ...state,
          jobPositionsForDepartment: response,
        });
      })
    );
  }

  @Action(LoadAllJobPositionsForDepartmentAndGroups, { cancelUncompleted: true })
  getAllJobPositionsForDepartmentAndGroups(
    { getState, setState }: StateContext<JobPositionStateModel>,
    { departmentIds, departmentGroupIds }: LoadAllJobPositionsForDepartmentAndGroups
  ) {
    return this.service
      .getAllJobPositionsForDepartmentAndGroups(departmentIds, departmentGroupIds)
      .pipe(
        tap(({ positions }) => {
          const state = getState();
          setState({
            ...state,
            jobPositionsForDepartmentAndGroups: positions,
          });
        })
      );
  }

  @Action(AddJobPosition, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCompetenceRoutes.JobPositions, message: 'jobPositionCreated' })
  addJobPosition(ctx: StateContext<JobPositionStateModel>, { jobPosition }: AddJobPosition) {
    return this.service.addJobPosition(jobPosition).pipe(
      tap((response: JobPositionModel) => {
        ctx.setState(
          patch({
            jobPositions: append([response]),
          })
        );
      })
    );
  }

  @Action(GetJobPositionById, { cancelUncompleted: true })
  getJobPositionById(ctx: StateContext<JobPositionStateModel>, { id }: GetJobPositionById) {
    return this.service.getJobPositionById(id).pipe(
      tap((response: JobPositionModel) => {
        ctx.setState(
          patch({
            currentJobPosition: response,
          })
        );
      })
    );
  }

  @Action(UpdateJobPosition, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCompetenceRoutes.JobPositions, message: 'jobPositionUpdated' })
  updateJobPosition(ctx: StateContext<JobPositionStateModel>, { jobPosition }: UpdateJobPosition) {
    return this.service.updateJobPosition(jobPosition);
  }

  @Action(DeleteJobPosition, { cancelUncompleted: true })
  @OnSuccess({ url: AdminCompetenceRoutes.JobPositions, message: 'jobPositionDeleted' })
  deleteJobPosition(ctx: StateContext<JobPositionStateModel>, { id }: DeleteJobPosition) {
    return this.service.deleteJobPosition(id);
  }

  @Selector()
  static getJobPositionTotalPages(state: JobPositionStateModel) {
    return state.jobPositionTotalPages;
  }

  @Selector()
  static getJobPositions(state: JobPositionStateModel) {
    return state.jobPositions;
  }

  @Selector()
  static getAllJobPositions(state: JobPositionStateModel): IdName[] {
    return state.allJobPositions;
  }

  @Selector()
  static getCurrentJobPosition(state: JobPositionStateModel) {
    return state.currentJobPosition;
  }

  @Selector()
  static getJobPositionsForDepartment(state: JobPositionStateModel) {
    return state.jobPositionsForDepartment;
  }

  @Selector()
  static getJobPositionsForDepartmentAndGroups(state: JobPositionStateModel) {
    return state.jobPositionsForDepartmentAndGroups;
  }
}
