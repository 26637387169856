import { Component, Input, OnChanges } from '@angular/core';
import { ModuleName } from 'src/app/shared/enums/enums';
import { TenantUserPrivilegesService } from 'src/app/tenant-selector/services/tenant-user-privileges.service';
import { MenuListModel } from '../../models/menu-list.model';
import {
  ADMIN_DEPARTMENT_SUBMODULES,
  ADMIN_USERS_SUBMODULES,
  ADMIN_COMPETENCE_SUBMODULES,
  ADMIN_MAINTENANCE_SUBMODULES,
  ADMIN_NONCONFORMANCE_SUBMODULES,
  ADMIN_CREW_MANAGEMENT_SUBMODULES,
  ADMIN_DOCUMENTS_SUBMODULES,
  ADMIN_MEDICINE_SUBMODULES,
  ADMIN_STORAGE_SUBMODULES,
  ADMIN_ORDER_SUBMODULES,
  ADMIN_WORK_AND_REST_SUBMODULES,
  ADMIN_RISK_ASSESSMENT_MODULES,
  ADMIN_CERTIFICATE_SUBMODULES,
} from '../menu-list/menu-list-routes';

@Component({
  selector: 'app-admin-inner-nav',
  templateUrl: './admin-inner-nav.component.html',
  styleUrls: ['./admin-inner-nav.component.scss'],
})
export class AdminInnerNavComponent implements OnChanges {
  @Input() adminInnerModuleName: string | null = '-';

  adminInnerItems: MenuListModel[];

  constructor(private tenantUserPrivileges: TenantUserPrivilegesService) {}

  ngOnChanges(): void {
    this.adminInnerItems = this.tenantUserPrivileges.recursiveFilterMenuItemsPerPrivileges(
      this.getProperInnerNavItems()
    );
  }

  private getProperInnerNavItems(): MenuListModel[] {
    switch (this.adminInnerModuleName) {
      case ModuleName.DEPARTMENT:
        return ADMIN_DEPARTMENT_SUBMODULES;
      case ModuleName.USER:
        return ADMIN_USERS_SUBMODULES;
      case ModuleName.COMPETENCE:
        return ADMIN_COMPETENCE_SUBMODULES;
      case ModuleName.NONCONFORMANCE:
        return ADMIN_NONCONFORMANCE_SUBMODULES;
      case ModuleName.MAINTENANCE:
        return ADMIN_MAINTENANCE_SUBMODULES;
      case ModuleName.CREWMANAGEMENT:
        return ADMIN_CREW_MANAGEMENT_SUBMODULES;
      case ModuleName.DOCUMENT:
        return ADMIN_DOCUMENTS_SUBMODULES;
      case ModuleName.MEDICINE:
        return ADMIN_MEDICINE_SUBMODULES;
      case ModuleName.STORAGE:
        return ADMIN_STORAGE_SUBMODULES;
      case ModuleName.ORDER:
        return ADMIN_ORDER_SUBMODULES;
      case ModuleName.WORKANDREST:
        return ADMIN_WORK_AND_REST_SUBMODULES;
      case ModuleName.RISKASSESSMENT:
        return ADMIN_RISK_ASSESSMENT_MODULES;
      case ModuleName.CERTIFICATE:
        return ADMIN_CERTIFICATE_SUBMODULES;
      default:
        console.warn(
          'No proper inner nav items for specified module name',
          this.adminInnerModuleName
        );
        return [];
    }
  }
}
