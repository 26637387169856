import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { AdminCompetenceRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { redirectAndBypassUnsavedDataCheck } from 'src/app/shared/functions/redirect-and-bypass-unsaved-data-check';
import { IdName } from 'src/app/shared/models/id-name';
import { ICompetenceRequirementModel } from '../model/competence-requirement.model';
import { CompetenceRequirementsApiService } from '../services/competence-requirement-api.service';
import {
  AddCompetenceRequirement,
  CopyCompetenceRequirements,
  DeleteCompetenceRequirement,
  LoadAllCompetenceRequirements,
  LoadCompetenceRequirements,
  LoadCompetenceRequirementsById,
  UpdateCompetenceRequirements,
} from './competence-requirement.actions';

export interface CompetenceRequirementStateModel {
  competenceRequirements: ICompetenceRequirementModel[] | undefined;
  allCompetenceRequirements: IdName[];
  currentCompetenceRequirement: ICompetenceRequirementModel | undefined;
  competenceRequirementsTotalPages: number;
  loaded: boolean;
}

const COMPETENCE_REQUIREMENT_STATE_TOKEN = new StateToken<CompetenceRequirementStateModel>(
  'competenceRequirements'
);

@State<CompetenceRequirementStateModel>({
  name: COMPETENCE_REQUIREMENT_STATE_TOKEN,
  defaults: {
    loaded: false,
    competenceRequirements: undefined,
    allCompetenceRequirements: [],
    currentCompetenceRequirement: undefined,
    competenceRequirementsTotalPages: 0,
  },
})
@Injectable()
export class CompetenceRequirementState {
  constructor(private service: CompetenceRequirementsApiService) {}

  @Action(LoadCompetenceRequirements, { cancelUncompleted: true })
  loadCompetenceRequirements(
    { getState, setState }: StateContext<CompetenceRequirementStateModel>,
    { filter }: LoadCompetenceRequirements
  ) {
    return this.service.getCompetenceRequirements(filter).pipe(
      tap((response: ApiResponse<ICompetenceRequirementModel[]>) => {
        const state = getState();
        setState({
          ...state,
          loaded: true,
          competenceRequirements: response.data,
          competenceRequirementsTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllCompetenceRequirements, { cancelUncompleted: true })
  loadAllCompetenceRequirements(ctx: StateContext<CompetenceRequirementStateModel>) {
    return this.service.getAllCompetenceRequirements().pipe(
      tap((allCompetenceRequirements: IdName[]) => {
        ctx.setState(patch({ allCompetenceRequirements }));
      })
    );
  }

  @Action(AddCompetenceRequirement, { cancelUncompleted: true })
  addCompetenceRequirement(
    ctx: StateContext<CompetenceRequirementStateModel>,
    { competenceRequirement }: AddCompetenceRequirement
  ) {
    return this.service.addCompetenceRequirement(competenceRequirement).pipe(
      tap((response: ICompetenceRequirementModel) => {
        ctx.setState(
          patch({
            competenceRequirements: append([response]),
          })
        );
        redirectAndBypassUnsavedDataCheck(AdminCompetenceRoutes.Main, {
          queryParams: { index: '1' },
        });
      })
    );
  }

  @Action(LoadCompetenceRequirementsById, { cancelUncompleted: true })
  loadCompetenceRequiermentsById(
    ctx: StateContext<CompetenceRequirementStateModel>,
    { id }: LoadCompetenceRequirementsById
  ) {
    // getting every existing competence group from state
    const competenceRequirementFromState = ctx.getState().competenceRequirements;
    // if a competence group exist then just get updated (latest) from api
    if (competenceRequirementFromState?.find((cg: ICompetenceRequirementModel) => cg.id === id)) {
      return this.service.getCompetenceRequirementsById(id).pipe(
        tap((response: ICompetenceRequirementModel) => {
          ctx.setState(
            patch({
              competenceRequirements: updateItem<ICompetenceRequirementModel>(
                (toGet: ICompetenceRequirementModel | undefined) => toGet?.id === response.id,
                response
              ),
              currentCompetenceRequirement: response,
            })
          );
        })
      );
    }
    // if it does not exist then fetch it from the api
    return this.service.getCompetenceRequirementsById(id).pipe(
      tap((response: ICompetenceRequirementModel) => {
        ctx.setState(
          patch({
            competenceRequirements: insertItem<ICompetenceRequirementModel>(response),
            currentCompetenceRequirement: response,
          })
        );
      })
    );
  }

  @Action(UpdateCompetenceRequirements, { cancelUncompleted: true })
  updateCompetenceRequirements(
    ctx: StateContext<CompetenceRequirementStateModel>,
    { competenceRequirement }: UpdateCompetenceRequirements
  ) {
    return this.service.updateCompetenceRequirement(competenceRequirement).pipe(
      tap(() => {
        redirectAndBypassUnsavedDataCheck(AdminCompetenceRoutes.Main, {
          queryParams: { index: '1' },
        });
      })
    );
  }

  @Action(DeleteCompetenceRequirement, { cancelUncompleted: true })
  deleteCompetenceRequirement(
    ctx: StateContext<CompetenceRequirementStateModel>,
    { id }: DeleteCompetenceRequirement
  ) {
    return this.service.deleteCompetenceRequirement(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            competenceRequirements: removeItem<ICompetenceRequirementModel>(
              (requirement) => requirement?.id === id
            ),
          })
        );
        redirectAndBypassUnsavedDataCheck(AdminCompetenceRoutes.Main, {
          queryParams: { index: '1' },
        });
      })
    );
  }

  @Action(CopyCompetenceRequirements, { cancelUncompleted: true })
  copyCompetenceRequirements(
    ctx: StateContext<CompetenceRequirementStateModel>,
    { copyCompetenceRequirement }: CopyCompetenceRequirements
  ) {
    return this.service.copyCompetenceRequirement(copyCompetenceRequirement).pipe(
      tap(() => {
        redirectAndBypassUnsavedDataCheck(AdminCompetenceRoutes.Main, {
          queryParams: { index: '1' },
        });
      })
    );
  }

  @Selector()
  static competenceRequirementsLoaded(state: CompetenceRequirementStateModel) {
    return state.loaded;
  }

  @Selector()
  static competenceRequirementsTotalPages(state: CompetenceRequirementStateModel) {
    return state.competenceRequirementsTotalPages;
  }

  @Selector()
  static getCompetenceRequirements(state: CompetenceRequirementStateModel) {
    return state.competenceRequirements;
  }

  @Selector()
  static getCurrentCompetenceRequirement(state: CompetenceRequirementStateModel) {
    return state.currentCompetenceRequirement;
  }

  @Selector()
  static getAllCompetenceRequirements(state: CompetenceRequirementStateModel): IdName[] {
    return state.allCompetenceRequirements;
  }

  @Selector()
  static getCompetenceRequirementById(state: CompetenceRequirementStateModel) {
    return (id: string) => {
      return state.competenceRequirements?.find(
        (competenceRequirement: ICompetenceRequirementModel) => competenceRequirement.id === id
      );
    };
  }
}
