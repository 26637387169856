import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserNonConformancesRoutes } from 'src/app/core/enums/routes.enum';
import { NonConformanceAction } from 'src/app/non-conformance-user/enums/non-conformance-action.enum';
import { RestoreNonConformance } from 'src/app/non-conformance-user/state/non-conformance.actions';
import { AlertStatus } from 'src/app/risk-assessment-user/enums/alert-status.enum';
import { NonConformanceStatus } from 'src/app/non-conformance-user/enums/non-conformance-status.enum';

@Component({
  selector: 'app-non-conformance-template',
  templateUrl: './non-conformance-template.component.html',
  styleUrls: ['./non-conformance-template.component.scss'],
})
export class NonConformanceTemplateComponent implements OnInit {
  @Input() columnName: string;
  @Input() element: any;
  showAlertWarnings = false;
  alertStatus = AlertStatus;

  nonConformanceAction = NonConformanceAction;

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.showAlertWarnings =
      this.element.alertStatus !== AlertStatus.Ok &&
      (this.element.status === NonConformanceStatus.Open ||
        this.element.status === NonConformanceStatus.Completed);
  }

  viewPreviousReport(nonConformanceId: string, responseId: string): void {
    const link = UserNonConformancesRoutes.ViewReport.replace(':id', nonConformanceId).replace(
      ':rid',
      responseId
    );
    this.router.navigate([link]);
  }

  restore(): void {
    this.store.dispatch(new RestoreNonConformance(this.element.id));
  }
}
