/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ISupplierRequest } from '../../models/suppliers/supplier.model';

export class LoadSuppliers {
  static readonly type = '[Suppliers] Load Suppliers via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllSuppliers {
  static readonly type = '[Suppliers] Load All Suppliers via Service';
  constructor(public departmentId?: string) {}
}

export class LoadSupplierById {
  static readonly type = '[Suppliers] Load Supplier By Id via Service';
  constructor(public id: string, public departmentId?: string, public toCurrencyCode?: string) {}
}

export class CreateSupplier {
  static readonly type = '[Suppliers] Create Supplier';
  constructor(public supplier: ISupplierRequest) {}
}

export class UpdateSupplier {
  static readonly type = '[Suppliers] Update Supplier';
  constructor(public supplier: ISupplierRequest) {}
}

export class DeleteSupplier {
  static readonly type = '[Suppliers] Delete Supplier';
  constructor(public supplierId: string) {}
}

export class ImportProducts {
  static readonly type = '[Suppliers] Import Products';
  constructor(public supplierId: string, public file: File) {}
}

export class ExportProducts {
  static readonly type = '[Suppliers] Export Products';
  constructor(public supplierId: string) {}
}
