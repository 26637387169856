import { MatDialog } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { AbstractTable } from '../../../shared/directives/abstract-table.directive';
import { ContactForm } from '../../forms/contact-form';
import { ContactsModalComponent } from './contacts-modal.component';
import { TenantTableNames } from '../../enums/tenant-table-names';

@Component({ selector: 'app-contacts-table', templateUrl: './contacts-table.component.html' })
export class ContactsTableComponent extends AbstractTable<ContactForm, ContactsModalComponent> {
  columnsToDisplay = ['name', 'position', 'phoneNumber', 'email', 'edit', 'delete'];
  tableNamesEnum = TenantTableNames;

  constructor(dialog: MatDialog) {
    super(dialog, ContactForm, ContactsModalComponent);
  }
}
