import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, merge, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AdminTenantRoutes } from 'src/app/core/enums/routes.enum';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { cloneDeep } from 'lodash-es';
import { FormControl } from '@angular/forms';
import { IAttachment } from 'src/app/shared/attachments/models/attachments.model';
import { ITenant } from '../../models/tenant.model';
import { TenantForm } from '../../forms/tenant-form';
import { TenantState } from '../../state/tenant.state';
import {
  CreateTenant,
  DeactivateTenant,
  DeleteTenant,
  LoadTenantById,
  LoadTenants,
  UpdateTenant,
} from '../../state/tenant.action';
import { FormUtilService } from '../../../services/form-util.service';
import { ProductOwnerState } from '../../../product-owner/state/product-owner.state';
import { LoadAllProductOwners } from '../../../product-owner/state/product-owner.actions';
import { IdName } from '../../../shared/models/id-name';
import { SoldBy } from '../../enums/sold-by.enum';
import { AccountType } from '../../enums/account-type.enum';
import { ModuleState } from '../../../core/state/module.state';
import { LoadModules } from '../../../core/state/module.actions';
import { Attachment } from '../../../shared/models/attachment.model';
import { FormArrayWithName } from '../../../shared/directives/abstract-table.directive';

@Component({
  selector: 'app-tenant-detail',
  templateUrl: './tenant-detail.component.html',
  styleUrls: ['./tenant-detail.component.scss'],
})
export class TenantDetailComponent implements OnInit, OnDestroy {
  @ViewChild('addLogo') addLogoButton: ElementRef;
  productOwners$: Observable<IdName[]>;
  form: TenantForm;
  adminTenantRoutes = AdminTenantRoutes;
  logo: SafeResourceUrl | Attachment;
  tenantId = this.route.snapshot.params.id;
  subscription = new Subscription();
  soldByOptions = Object.values(SoldBy).map(FormUtilService.mapObjectLiteralToIdName);
  accountTypes = Object.values(AccountType).map(FormUtilService.mapObjectLiteralToIdName);
  actions = [CreateTenant, DeleteTenant, UpdateTenant];
  selectedTab = 0;

  tempPredefinedFile = new FormControl<IAttachment[]>([]) as FormControl<IAttachment[]>;
  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.initTenantForm()
        .pipe(
          tap((form) => {
            this.form = form;
          }),
          switchMap(() => merge(this.form.onIsDPAChange(), this.form.onModuleValueChanges()))
        )
        .subscribe()
    );
    this.productOwners$ = this.store
      .dispatch(LoadAllProductOwners)
      .pipe(switchMap(() => this.store.select(ProductOwnerState.getAllProductOwners)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }
    if (this.tempPredefinedFile.value[0]?.file)
      this.form.predefinedValues.setValue(this.tempPredefinedFile.value[0].file);

    const tenant = this.form.value;

    if (tenant.id) {
      this.store.dispatch(new UpdateTenant(tenant));
    } else {
      this.store.dispatch(new CreateTenant(tenant));
    }
  }

  delete(): void {
    this.store.dispatch(new DeleteTenant(this.form.id.value));
  }

  deactivate(): void {
    this.store.dispatch(new DeactivateTenant(this.form.id.value));
  }

  onLogoChange(event: any): void {
    const file = event.target.files[0];
    this.form.isLogoChanged.setValue(true);
    if (file) {
      if (FormUtilService.validateImageFileType(file, this.addLogoButton)) {
        return;
      }
      this.form.logo.setValue(file);
      const blob = new Blob([this.form.logo.value], { type: 'image/*' });
      const image = URL.createObjectURL(blob);
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(`${image}`);
    }
  }

  removeLogo(): void {
    this.form.logo.reset();
    this.logo = '';
    this.form.isLogoChanged.setValue(true);
  }

  reloadTenants(): void {
    this.store.dispatch(new LoadTenants());
  }

  reloadForm(fa: FormArrayWithName) {
    const clonedForm = cloneDeep(this.form);
    clonedForm.setControl(fa.name, fa.formArray);
    this.form = clonedForm;
  }

  trackBy = (index: number) => index;

  private initTenantForm(): Observable<TenantForm> {
    const id = this.tenantId;
    return this.store.dispatch(LoadModules).pipe(
      switchMap(() => this.store.select(ModuleState.getModules)),
      switchMap((modules) => {
        if (!id || id === 'new') {
          return of(new TenantForm(modules));
        }
        return this.store.dispatch(new LoadTenantById(id)).pipe(
          map(() => modules),
          switchMap((modules: IdName[]) => {
            return this.store.select(TenantState.getTenantById).pipe(
              map((filterFn) => filterFn(id)),
              map((tenant: ITenant) => {
                if (tenant) {
                  const form = new TenantForm(modules, tenant);
                  this.logo = form.logo.value;
                  form.disable();
                  return form;
                }
                return new TenantForm(modules);
              }),
              first()
            );
          })
        );
      })
    );
  }
}
