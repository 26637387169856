import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HashtagModel } from 'src/app/hashtag/models/hashtag.model';
import { SearchResultModel } from 'src/app/core/models/search-result.model';
import { Store } from '@ngxs/store';
import { LoadHashtags } from 'src/app/hashtag/state/hashtag.actions';
import { HashtagState } from 'src/app/hashtag/state/hashtag.state';
import { LoadSearchResult } from '../../state/global-search.actions';
import { GlobalSearchResultState } from '../../state/global-search.state';

@Component({
  selector: 'app-global-search-result',
  templateUrl: './global-search-result.component.html',
  styleUrls: ['./global-search-result.component.scss'],
})
export class GlobalSearchResultComponent implements OnInit, OnDestroy {
  globalSearchResults$: Observable<SearchResultModel[]> = this.store.select(
    GlobalSearchResultState.globalSearchResults
  );
  allHashtags$: Observable<HashtagModel[]> = this.store.select(HashtagState.allHashtags);

  searchTerm: string;
  searchResult: SearchResultModel[];
  hashtag: HashtagModel;
  hashtags: HashtagModel[];

  private subscriptions = new Subscription();

  constructor(private store: Store, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getSearchTerm();
    this.getHashtags();
    this.checkSearchName();
    this.displayList();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private displayList() {
    this.store.dispatch(new LoadSearchResult(this.searchTerm));

    this.subscriptions.add(
      this.globalSearchResults$.subscribe((result: SearchResultModel[]) => {
        if (result) {
          this.searchResult = result;
        }
      })
    );
  }

  private getSearchTerm() {
    this.activatedRoute.paramMap.subscribe((params) => {
      const searchTermParam = params.get('searchTerm');
      if (typeof searchTermParam === 'string' && searchTermParam !== null) {
        this.searchTerm = searchTermParam;
      }
    });
  }

  private getHashtags(): void {
    // first we need to call an action to fetch all available hashtags
    this.store.dispatch(new LoadHashtags());
    // then we subscribe to hashtags from the state
    this.subscriptions.add(
      this.allHashtags$.subscribe((hashtags: HashtagModel[]) => {
        if (hashtags) {
          this.hashtags = hashtags;
        }
      })
    );
  }

  private searchValueIsValidHashtag(searchTerm: string): HashtagModel | undefined {
    return this.hashtags.find((hashtag: HashtagModel) => hashtag.name === searchTerm);
  }

  private checkSearchName() {
    const isValidHashtag = this.searchValueIsValidHashtag(this.searchTerm);
    if (isValidHashtag) {
      this.hashtag = isValidHashtag;
      // ToDo call API for objects with specefic hashtag
    } else {
      // ToDo Call API with string for list of SearchResultModel with searchTerm
    }
  }
}
