import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { environment } from '../../../environments/environment';
import { IUserGroups } from '../models/user-groups.model';

@Injectable({
  providedIn: 'root',
})
export class UserGroupsService {
  constructor(private http: HttpClient) {}

  getAllUserGroups(): Observable<IUserGroups[]> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/userGroups`;

    return this.http.get<IUserGroups[]>(url);
  }
}
