/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IListSeverityModel } from '../../models/severities/list-severity.model';
import { INewSeverityModel } from '../../models/severities/new-severity.model';

export class LoadSeverities {
  static readonly type = '[Severity] Load Severities';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllSeverities {
  static readonly type = '[Severity] Load All Severities';
  constructor() {}
}

export class AddSeverity {
  static readonly type = '[Severity] Add Severity';
  constructor(public severity: INewSeverityModel) {}
}

export class UpdateSeverity {
  static readonly type = '[Severity] Update Severity';
  constructor(public severity: IListSeverityModel) {}
}

export class DeleteSeverity {
  static readonly type = '[Severity] Delete Severity';
  constructor(public id: string) {}
}

export class LoadSingleSeverity {
  static readonly type = '[Severity] Load Single Severity';
  constructor(public id: string) {}
}
