import { Pipe, PipeTransform } from '@angular/core';
import { ProductAction } from 'src/app/storage-user/enums/product-action.enum';
import { mapProductActionToString } from '../functions/map-product-action-to-string';

@Pipe({
  name: 'mapProductAction',
  pure: true,
  standalone: true,
})
export class MapProductActionPipe implements PipeTransform {
  transform(medicineEvent: ProductAction): string {
    return mapProductActionToString(medicineEvent);
  }
}
