<h1 class="page-title-h1">{{ 'alerts' | translate | titlecase }}</h1>
<app-main-table
  [loading]="(loadingAction | actionInProgress | async) === true"
  [tableData]="alerts$ | async"
  [displayedColumns]="displayedColumns"
  [disabledColumnHeaders]="disabledColumnHeaders"
  [urlForRow]="routerLink"
  [filterPredicate]="filterPredicate"
  [totalPages]="alertsTotalPages$ | async"
  (searchEmitter)="searchChanged($event)"
  [moduleNameForPaging]="moduleNameForPaging"
>
  <app-ui-button
    routerLink="new"
    color="primary"
    text="{{ 'newAlert' | translate }}"
    icon="add"
    class="add-new-item-to-table-btn"
    privilege="ALERT_CREATE"
  ></app-ui-button
></app-main-table>
