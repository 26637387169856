<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill [name]="element[columnName]"></app-ui-pill>
  </ng-container>

  <ng-container *ngSwitchCase="'lastPerformed'">
    {{ element['lastDrillDate'] | date : 'yyyy-MM-dd' }}
  </ng-container>

  <ng-container *ngSwitchCase="'expiry'">
    {{ element['expiryDate'] | date : 'yyyy-MM-dd' }}
  </ng-container>

  <ng-container *ngSwitchCase="'actions'">
    <app-ui-button
      buttonType="secondary"
      text="{{ 'perform' | translate }}"
      [routerLink]="performRoute + '/' + element['id']"
      privilege="DRILL_PERFORM"
    >
    </app-ui-button>
  </ng-container>

  <ng-container *ngSwitchCase="'access'">
    <app-positions-cell [element]="element"></app-positions-cell>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
