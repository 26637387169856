/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
/* eslint-disable no-self-compare */
/* eslint-disable no-cond-assign */

import { runOutsideAngular } from 'src/app/services/store-snapshot.service';

/* eslint-disable no-magic-numbers */
declare const TXTextControl: any;

export const SUPPORTED_EXTENSIONS = ['doc', 'docx', 'rtf', 'htm', 'html', 'tx', 'pdf'];

export function retry(condition: () => boolean, fn: () => any, interval: number) {
  runOutsideAngular(() => {
    const executeFn = () => {
      if (condition()) {
        fn();
        return;
      }
      setTimeout(executeFn, interval);
    };
    executeFn();
  });
}

// direct copypaste from text control viewer lib, need this fn to load the document into the viewer
function o(n: string): string {
  const t = String.fromCharCode;
  let i = n.charCodeAt(0);
  let r;
  if (i >= 55296 && i <= 56319) {
    if (((r = n.charCodeAt(1)), r !== r)) return t(239, 191, 189);
    if (r >= 56320 && r <= 57343) {
      if (((i = (i - 55296) * 1024 + r - -9216), i > 65535))
        return t(240 | (i >>> 18), 128 | ((i >>> 12) & 63), 128 | ((i >>> 6) & 63), 128 | (i & 63));
    } else return t(239, 191, 189);
  }
  const result =
    i <= 127
      ? i
      : i <= 2047
      ? t(192 | (i >>> 6), 128 | (i & 63))
      : t(224 | (i >>> 12), 128 | ((i >>> 6) & 63), 128 | (i & 63));
  return `${result}`;
}

export function btoaUTF8(inputString: string, addBOM: boolean) {
  return btoa(
    (addBOM ? 'ï»¿' : '') +
      inputString.replace(/[\x80-\uD7ff\uDC00-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]?/g, o)
  );
}

export function containsHtmlTag(input: string): boolean {
  return input.indexOf('</') > -1;
}

export function TCSaveDocumentAsPDF(cb: () => void): void {
  TXTextControl.saveDocument(TXTextControl.StreamType.AdobePDF, function (e: any) {
    const element = document.createElement('a');
    element.setAttribute('href', `data:application/octet-stream;base64,${e.data}`);
    element.setAttribute('download', 'document.pdf');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    cb();
  });
}

export function isFileExtensionSupported(input: any): boolean {
  const fileExtension = input.target.files[0].name.split('.').pop().toLowerCase();
  return SUPPORTED_EXTENSIONS.includes(fileExtension);
}

export function getMaxWidth(): Promise<number> {
  let pageWidth = 0;
  let marginLeft = 0;
  let marginRight = 0;

  return new Promise((resolve) => {
    TXTextControl.setPageUnit(TXTextControl.MeasuringUnit.Twips, function () {
      TXTextControl.sections.getItem(({ format }: any) => {
        format.pageSize.getWidth((width: any) => {
          pageWidth = width;
          format.pageMargins.getLeft((left: any) => {
            marginLeft = left;
            format.pageMargins.getRight((right: any) => {
              marginRight = right;
              resolve(pageWidth - marginLeft - marginRight);
            });
          });
        });
      });
    });
  });
}

export function getTableInfo(table: any): Promise<any> {
  return new Promise((resolve, reject) => {
    if (table === null) {
      reject(new Error('Table is null'));
      return;
    }

    table.rows.getCount((rowCount: number) => {
      table.columns.getCount((colCount: number) => {
        const tableInfo = {
          rows: rowCount,
          cols: colCount,
          table,
        };

        resolve(tableInfo);
      });
    });
  });
}

export function getRowWidth(row: number, colCount: number, table: any) {
  return new Promise((resolve) => {
    let currentWidth = 0;
    for (let col = 1; col <= colCount; col++) {
      table.cells.getItem(
        // eslint-disable-next-line no-loop-func
        (cell: any) => {
          cell.getWidth((cellWidth: number) => {
            currentWidth += cellWidth;
            if (col === colCount) resolve(currentWidth);
          });
        },
        null,
        row,
        col
      );
    }
  });
}

export function setRowWidth(
  row: any,
  colCount: any,
  table: any,
  destinationWidth: any,
  currentWidth: any
) {
  return new Promise((resolve) => {
    for (let col = 1; col <= colCount; col++) {
      // calculate the ratio percentage for each cell
      table.cells.getItem(
        function (cell: any) {
          cell.getWidth(function (cellWidth: any) {
            const percentage = cellWidth / currentWidth;

            cell.setWidth(
              parseInt(`${cellWidth - destinationWidth * percentage}`, 10),
              function () {
                if (col === colCount) resolve(true);
              }
            );
          });
        },
        null,
        row,
        col
      );
    }
  });
}
