import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComponentStructureSupportLogicService {
  tempParentId = '';

  constructor() {}

  saveTempParentId(id: string): void {
    this.tempParentId = id;
  }

  getParentId(): string {
    return this.tempParentId;
  }
}
