/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IOrderTypeRequest } from '../../models/order-type.model';

export class LoadOrderTypes {
  static readonly type = '[OrderTypes] Load Order Types via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllOrderTypes {
  static readonly type = '[OrderTypes] Load All Order Types via Service';
  constructor(public departmentId?: string) {}
}

export class LoadOrderTypeById {
  static readonly type = '[OrderTypes] Load Order Type By Id via Service';
  constructor(public id: string) {}
}

export class CreateOrderType {
  static readonly type = '[OrderTypes] Create Order Type';
  constructor(public orderType: IOrderTypeRequest) {}
}

export class UpdateOrderType {
  static readonly type = '[OrderTypes] Update Order Type';
  constructor(public orderType: IOrderTypeRequest) {}
}

export class DeleteOrderType {
  static readonly type = '[OrderTypes] Delete Order Type';
  constructor(public orderTypeId: string) {}
}
