import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { HashtagModel } from '../../models/hashtag.model';
import { LoadHashtags } from '../../state/hashtag.actions';
import { HashtagState } from '../../state/hashtag.state';

@Component({
  selector: 'app-hashtag-add',
  templateUrl: './hashtag-add.component.html',
  styleUrls: ['./hashtag-add.component.scss'],
})
export class HashtagAddComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() disabled: boolean;

  allHashtags$: Observable<HashtagModel[]> = this.store.select(HashtagState.allHashtags);
  hashtagsLoaded$: Observable<boolean> = this.store.select(HashtagState.hashtagsLoaded);

  hashtags: HashtagModel[];
  chosenHashtags: HashtagModel[] = [];

  private subscriptions = new Subscription();

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control) {
      this.chosenHashtags = [...this.control.value];
    }
  }

  ngOnInit(): void {
    this.getHashtags();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addHashtagToList(hashtag: string): void {
    if (this.hashtagIsValid(hashtag)) {
      this.assignNewHashtag(hashtag);
    }
  }

  removeHashtagFromList(hashtagName: string): void {
    this.chosenHashtags = this.chosenHashtags.filter(
      (hashtag: HashtagModel) => hashtag.name !== hashtagName
    );
    this.setHashtagsToControl();
  }

  private getHashtags(): void {
    this.store.dispatch(new LoadHashtags());
    this.subscriptions.add(
      this.allHashtags$.subscribe((hashtags: HashtagModel[]) => {
        if (hashtags) {
          this.hashtags = hashtags;
        }
      })
    );
  }

  private hashtagIsValid(hashtag: string): boolean {
    return hashtag.length > 1 && hashtag[0] === '#';
  }

  private assignNewHashtag(hashtag: string): void {
    const existingHashtag = this.hashtags.find((hash: HashtagModel) => hash.name === hashtag);
    // we then check if it already exist on back-end
    if (existingHashtag) {
      // in this scenario we have this hashtag on back-end, so we replace it
      this.chosenHashtags.push(existingHashtag);
      this.setHashtagsToControl();
    } else {
      // in this scenario we simply push a new name,
      // as this is a new hashtag and we don't have it on back-end
      const newHashtag = { name: hashtag } as HashtagModel;
      this.chosenHashtags.push(newHashtag);
      this.setHashtagsToControl();
    }
  }

  private setHashtagsToControl(): void {
    this.control.setValue(this.chosenHashtags);
  }
}
