import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-ui-chip',
  templateUrl: './ui-chip.component.html',
  styleUrls: ['./ui-chip.component.scss'],
})
export class UiChipComponent implements OnInit {
  @Input() label = '';
  @Input() items: any[] = [];
  @Input() disabled: boolean;
  @Input() selectedItems: any[] = [];
  @Output() readonly itemAdded = new EventEmitter<string>();
  @Output() readonly itemRemoved = new EventEmitter<string>();

  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;

  inputControl = new UntypedFormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredItems: Observable<any[]>;

  ngOnInit(): void {
    this.filteredItems = this.inputControl.valueChanges.pipe(
      startWith(null),
      map((item: any) => (item ? this.filter(item) : this.items.slice()))
    );
  }

  addKeywordFromInput(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && value.length > 1 && value[0] === '#') {
      this.selectedItems.push(value);
      this.itemAdded.emit(value);
    }

    event.chipInput?.clear();
  }

  remove(item: string): void {
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.itemRemoved.emit(item);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.viewValue);
    this.inputField.nativeElement.value = '';
    this.itemAdded.emit(event.option.viewValue);
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.items.filter((item) => item.name.toLowerCase().includes(filterValue));
  }
}
