<input #fileInput (change)="onChangeLoad($event)" type="file" id="fileinput" />
<mat-icon
  *ngIf="!textControl.isLoading"
  class="fullscreen-icon"
  matTooltip="{{ 'fullscreen' | translate }}"
  matTooltipPosition="above"
  (click)="enterFullscreen()"
  >fullscreen</mat-icon
>
<div class="text-control-container" #container [class.loading]="textControl.isLoading">
  <mat-icon
    *ngIf="!textControl.isLoading && textControl.isFullscreen"
    class="exit-fullscreen-icon"
    attr.title="{{ 'exitFullscreen' | translate }}"
    matTooltipPosition="above"
    (click)="exitFullscreen()"
    >fullscreen_exit</mat-icon
  >
</div>
<ng-container *ngIf="textControl.isLoading">
  <div class="loader" *appLoader="true"></div>
</ng-container>
