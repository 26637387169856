import { getDepartmentId, getTenantId } from 'src/app/services/store-snapshot.service';
import { IdName } from 'src/app/shared/models/id-name';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { JobPositionModel } from '../model/job-position.model';

@Injectable({
  providedIn: 'root',
})
export class JobPositionApiService {
  private get baseUrl(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/Positions`;
  }

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) {}

  getJobPositionList(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<JobPositionModel[]>> {
    let url = `${this.baseUrl}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<JobPositionModel[]>>(url, this.httpOptions);
  }

  getAllJobPositions(): Observable<IdName[]> {
    const url = `${this.baseUrl}/List`;
    return this.http.get<IdName[]>(url);
  }

  getAllJobPositionsWithDepartments(): Observable<JobPositionModel[]> {
    const url = `${this.baseUrl}/WithDepartments`;
    return this.http.get<JobPositionModel[]>(url);
  }

  getJobPositionById(id: string): Observable<JobPositionModel> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<JobPositionModel>(url, this.httpOptions);
  }

  addJobPosition(jobPosition: JobPositionModel): Observable<JobPositionModel> {
    return this.http.post<JobPositionModel>(this.baseUrl, jobPosition, this.httpOptions);
  }

  updateJobPosition(jobPosition: JobPositionModel): Observable<JobPositionModel> {
    return this.http.put<JobPositionModel>(this.baseUrl, jobPosition, this.httpOptions);
  }

  deleteJobPosition(id: string): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url, this.httpOptions);
  }

  getAllJobPositionsForDepartment(departmentId?: string): Observable<IdName[]> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/${
      departmentId || getDepartmentId()
    }/departmentScope/jobPositions`;
    return this.http.get<IdName[]>(url, this.httpOptions);
  }

  getAllJobPositionsForDepartmentAndGroups(
    departmentIds: string[],
    departmentGroupIds: string[]
  ): Observable<{ positions: IdName[] }> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/positions/departments`;
    return this.http.get<{ positions: IdName[] }>(url, {
      params: { departmentIds, departmentGroupIds },
    });
  }
}
