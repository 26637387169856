import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DrillTableNamesEnum } from '../../../../enums/drill-table-names.enum';

@Component({
  selector: 'app-drills-templates',
  templateUrl: './drills-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrillsTemplatesComponent {
  @Input() specificTemplateName: string;
  @Input() columnName: string;
  @Input() element: any;

  drillsTemplateNames = DrillTableNamesEnum;
}
