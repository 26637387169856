import { Pipe, PipeTransform } from '@angular/core';
import { MedicineEvent } from 'src/app/medicine-user/enums/medicine-event.enum';
import { mapMedicineEventToString } from '../functions/map-medicine-event-to-string';

@Pipe({
  name: 'mapMedicineEvent',
  pure: true,
  standalone: true,
})
export class MapMedicineEventPipe implements PipeTransform {
  transform(medicineEvent: MedicineEvent): string {
    return mapMedicineEventToString(medicineEvent);
  }
}
