import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContactPersonForm } from '../../../../../department/forms/vessel-contact-person.form';
import { AbstractDepartmentTemplateComponent } from './abstract-department-template-component.directive';

@Component({
  selector: 'app-contact-person-template',
  templateUrl: './contact-person-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPersonTemplateComponent extends AbstractDepartmentTemplateComponent<ContactPersonForm> {}
