import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { TenantSelectorComponent } from 'src/app/tenant-selector/tenant-selector.component';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { IUserAccess } from 'src/app/shared/user-view/models/tenant-user-access.model';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { UiState } from '../../state/ui.state';
import { ToggleDarkMode } from '../../state/ui.actions';
import { RoutesEnum } from '../../enums/routes.enum';
import { ClearFilterDetails } from '../../state/main-table-filter.actions';

@Component({
  selector: 'app-logged-in-as-popup',
  templateUrl: './logged-in-as-popup.component.html',
  styleUrls: ['./logged-in-as-popup.component.scss'],
})
export class LoggedInAsPopupComponent implements OnInit, OnDestroy {
  userAccess$: Observable<IUserAccess> = this.store.select(TenantUserAccessState.userAccess);
  userCurrentTenantId$: Observable<string> = this.store.select(
    TenantUserAccessState.currentTenantId
  );
  isDarkMode: boolean;
  firstRun = true;
  profileSettingsAvailable = false;
  tenantUserEmail: string;
  tenantUserName: string;
  tenantPosition: string;
  userAccess: IUserAccess;
  private isDarkMode$: Observable<boolean> = this.store.select(UiState.isDarkMode);
  private subscription = new Subscription();

  constructor(public dialog: MatDialog, private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.subscription.add(
      this.isDarkMode$.subscribe((mode) => {
        if (this.firstRun) {
          this.firstRun = false;
        }
        this.isDarkMode = mode;
      })
    );

    this.subscription.add(
      this.userAccess$.subscribe((userAccess: IUserAccess) => {
        this.userAccess = userAccess;
        this.updatePopupInfo(getTenantId());
      })
    );

    this.subscription.add(
      this.userCurrentTenantId$.subscribe((tenantId: string) => {
        this.updatePopupInfo(tenantId);
      })
    );
  }

  private updatePopupInfo(tenantId: string): void {
    if (this.userAccess) {
      this.profileSettingsAvailable = this.userAccess.tenantInfos.length > 0;
      this.tenantUserName = this.userAccess.name;
      this.tenantUserEmail = this.userAccess?.email
        .replace(/\./g, '\u200B.')
        .replace(/@/, '\u200B@');
      this.tenantPosition = this.userAccess.isProductOwner
        ? 'productOwner'
        : this.userAccess.tenantInfos.find((tenantInfo) => tenantInfo.id === tenantId)?.position
            ?.name || 'user';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onToggleDarkMode(event: UIEvent): void {
    this.isDarkMode = !this.isDarkMode;
    this.store.dispatch(new ToggleDarkMode(this.isDarkMode));
    event.stopPropagation();
  }

  onProfileSettings(): void {
    this.store.dispatch(new ClearFilterDetails());
    this.router.navigate([`${RoutesEnum.Profile}/view`]);
  }

  changeTenant(): void {
    this.dialog
      .open(TenantSelectorComponent, {
        width: '50rem',
      })
      .afterClosed()
      .subscribe();
  }
}
