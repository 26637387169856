<ng-container *ngIf="name" [ngSwitch]="name | lowercase">
  <ng-container *ngSwitchCase="status.Ok">
    <div class="pill ok">{{ 'ok' | translate | uppercase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Active">
    <div class="pill ok">
      {{ (module === mainModulesEnum.Tenant ? 'operational' : 'ok') | translate | uppercase }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="status.NotCompleted">
    <div class="pill paused">{{ 'notCompleted' | translate | uppercase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Paused">
    <div class="pill paused">{{ 'paused' | translate | uppercase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.ConfirmationNeeded">
    <div class="pill paused">{{ 'paused' | translate | uppercase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Inactive">
    <div class="pill archive">{{ 'inactive' | translate | uppercase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Archived">
    <div class="pill archive">{{ 'archive' | translate | uppercase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Implementing">
    <div class="pill implementing">{{ 'implementing' | translate | uppercase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.InProgress">
    <div class="pill implementing">{{ 'inProgress' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Expiring">
    <div class="pill expiring">{{ 'expiring' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Expired">
    <div class="pill expired">{{ 'expired' | translate | titlecase }}</div>
  </ng-container>
  <!-- Storage status start -->
  <ng-container *ngSwitchCase="status.UnderThreshold">
    <div class="pill expiring">{{ 'underThreshold' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.UnderMinimum">
    <div class="pill expired">{{ 'underMinimum' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.ProductInOrder">
    <div class="pill implementing">{{ 'productInOrder' | translate | titlecase }}</div>
  </ng-container>
  <!-- Storage status end -->
  <!-- Document status  -->
  <ng-container *ngSwitchCase="status.Published">
    <div class="pill ok">{{ 'ok' | translate | uppercase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Draft">
    <div class="pill implementing">{{ 'draft' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.UnderRevision">
    <div class="pill paused">{{ 'underRevision' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.ForApproval">
    <div class="pill ok">{{ 'forApproval' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.InactiveOrHistory">
    <div class="pill archive">{{ 'inactiveOrHistory' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Rejected">
    <div class="pill rejected">{{ 'rejected' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Disabled">
    <div class="pill disabled">{{ 'disabled' | translate | titlecase }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="status.Deleted">
    <div class="pill deleted">{{ 'deleted' | translate | titlecase }}</div>
  </ng-container>
</ng-container>

<ng-container *ngIf="number">
  <div
    class="pill due-list-summary"
    [ngClass]="expiredDueListWarnings ? 'due-list-expired' : 'due-list-expiring'"
  >
    {{ number }}
  </div>
</ng-container>
