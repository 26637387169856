import { ApplicationRef, EventEmitter, Injectable, RendererFactory2 } from '@angular/core';
import { FormControlStatus } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { getTenantId, runOutsideAngular } from 'src/app/services/store-snapshot.service';
import { environment } from 'src/environments/environment';
import {
  containsHtmlTag,
  getMaxWidth,
  getRowWidth,
  getTableInfo,
  retry,
  setRowWidth,
} from './text-control.helpers';

declare const TXTextControl: any;
const EDITOR_HIDDEN_CLASS = 'cdk-visually-hidden';
const EDITOR_TEMP_CONTAINER = '.text-control-reusable-container';
const EDITOR_ID = 'text-control-editor';
const WEBSOCKET_RECONNECT_TIMEOUT = 15000;
const ENABLE_FOR_TENANTS = environment.textControlTenants.map((t) => t.toLocaleLowerCase());

@Injectable({
  providedIn: 'root',
})
export class TextControlService {
  get tx(): any {
    if (typeof TXTextControl !== 'undefined') {
      return TXTextControl;
    }
    return null;
  }

  get editorElement(): any {
    return document.getElementById(EDITOR_ID);
  }

  get isEditorReady(): boolean {
    return !!this.tx && this.isEditorLoaded;
  }

  get ribbonBarLoaded(): boolean {
    return this.isRibbonLoaded;
  }

  get isEditorDisabled(): boolean {
    return this.isDisabled;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get isDocumentLoading(): boolean {
    return this.documentLoading;
  }

  get isFullscreen(): boolean {
    return document.fullscreenElement !== null;
  }

  get isInitialized(): boolean {
    return this.primaryInitCalled;
  }

  static get isTextControlEnabled(): boolean {
    return ENABLE_FOR_TENANTS.includes(getTenantId().toLocaleLowerCase());
  }

  forceReload = new EventEmitter();
  editorLoaded = new BehaviorSubject(false);

  private primaryInitCalled = false;
  private isShown = false;
  private isDisabled = false;
  private isEditorLoaded = false;
  private isTablePasted = false;
  private isRibbonLoaded = false;
  private loading = false;
  private importingFromFile = false;
  private documentLoading = false;
  private connectionCheckTimeout: any | null = null;
  private renderer = this.rendererFactory.createRenderer(null, null);

  constructor(
    private rendererFactory: RendererFactory2,
    private toast: ToastService,
    private appRef: ApplicationRef
  ) {}

  init(container: HTMLElement): void {
    this.setLoading(true);
    retry(
      () => !!this.tx,
      () => {
        this.tx.addEventListener('textControlLoaded', () => {
          this.tx.clipboardMode = 1;
          this.isEditorLoaded = true;
          this.editorLoaded.next(true);
          this.show(container);
          this.setLoading(false);
          this.tx.addEventListener('hypertextLinkClicked', (e: any) => {
            window.open(e.hypertextLink.target, '_blank');
          });
          this.tx.addEventListener('fileDropped', () => {
            this.importingFromFile = true;
            this.setLoading(true);
          });
          this.tx.addEventListener('imageCreated', () => {
            this.setLoading(false);
          });
          this.tx.addEventListener('ribbonTabsLoaded', () => {
            this.isRibbonLoaded = true;
          });
          this.tx.addEventListener('documentLoaded', () => {
            this.importingFromFile = false;
            this.setLoading(false);
          });
          this.tx.addEventListener('tableCreated', () => {
            if (this.importingFromFile || !this.isTablePasted) {
              return;
            }
            this.tx.tables.getCount((count: number) => {
              this.tx.tables.elementAt(count - 1, async (table: any) => {
                this.setLoading(true);
                if (this.isTablePasted) {
                  await this.adaptTable(table);
                  this.isTablePasted = false;
                }
                table.cells.forEach((cell: any) => {
                  cell.getCellFormat((format: any) => {
                    format.topBorder.setWidth(1);
                    format.bottomBorder.setWidth(1);
                    format.leftBorder.setWidth(1);
                    format.rightBorder.setWidth(1);
                  });
                });
                this.setLoading(false);
              });
            });
          });
          this.tx.addEventListener('webSocketClosed', () => {
            if (!this.connectionCheckTimeout) {
              this.connectionCheckTimeout = setTimeout(() => {
                this.connectionCheckTimeout = null;
                if (document.querySelector('#txErrMsgDiv')) {
                  console.warn('could not establish textcontrol server connection, need reload');
                  this.reload();
                }
              }, WEBSOCKET_RECONNECT_TIMEOUT);
            }
          });
          this.tx.addEventListener('textPasted', (e: { text: string; textType: string }) => {
            if (e.textType === 'text/html' && e.text.indexOf('<table') > -1) {
              this.isTablePasted = true;
            }
          });
        });
      },
      100
    );
  }

  loadDocument(document: string): void {
    this.setLoading(true);
    this.documentLoading = true;
    const isHTML = containsHtmlTag(document);
    const format = isHTML
      ? TXTextControl.StreamType.HTMLFormat
      : TXTextControl.StreamType.WordprocessingML;
    const htmlContent = TXTextControl.btoaUTF8(document);
    TXTextControl.loadDocument(format, isHTML ? htmlContent : document, async () => {
      if (isHTML) {
        await this.adaptAllTables();
      }
      this.setLoading(false);
      this.documentLoading = false;
    });
  }

  readAndLoadDocument(input: any): void {
    this.setLoading(true);
    if (input.target.files && input.target.files[0]) {
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        let streamType = TXTextControl.streamType.PlainText;

        // set the StreamType based on the lower case extension
        switch (input.target.files[0].name.split('.').pop().toLowerCase()) {
          case 'doc':
            streamType = TXTextControl.streamType.MSWord;
            break;
          case 'docx':
            streamType = TXTextControl.streamType.WordprocessingML;
            break;
          case 'rtf':
            streamType = TXTextControl.streamType.RichTextFormat;
            break;
          case 'htm':
          case 'html':
            streamType = TXTextControl.streamType.HTMLFormat;
            break;
          case 'tx':
            streamType = TXTextControl.streamType.InternalUnicodeFormat;
            break;
          case 'pdf':
            streamType = TXTextControl.streamType.AdobePDF;
            break;
          default:
            streamType = TXTextControl.streamType.PlainText;
            break;
        }

        // load the document beginning at the Base64 data (split at comma)
        if (e?.target?.result) {
          const base64string = e.target.result as string;
          TXTextControl.loadDocument(
            streamType,
            base64string.split(',')[1],
            () => this.setLoading(false),
            {},
            ({ msg }: any) => {
              this.toast.error('errorWhileImportingDocumentRecoveringEditor');
              setTimeout(() => {
                this.setLoading(false);
                this.reload();
              }, 1000);
            }
          );
        }
      };

      fileReader.readAsDataURL(input.target.files[0]);
    }
  }

  setEditModeStatus(status: FormControlStatus) {
    this.isDisabled = status === 'DISABLED';
    if (this.tx) {
      this.tx.editMode = this.isDisabled ? 3 : 1;
    }
  }

  refreshLayout(): void {
    if (this.tx && this.tx.refreshLayout) {
      this.tx.refreshLayout();
    } else {
      console.warn('Text Control refreshLayout not available yet');
    }
  }

  show(container: HTMLElement): void {
    this.primaryInitCalled = true;
    if (this.isShown) {
      return;
    }
    if (this.isEditorReady) {
      this.editorElement.classList.remove(EDITOR_HIDDEN_CLASS);
      container.appendChild(this.editorElement);
      this.isShown = true;
      this.refreshLayout();
    } else {
      this.renderer.listen('document', 'txDocumentEditorLoaded', () => {
        try {
          this.init(container);
        } catch (e) {
          console.warn(e);
        }
      });
    }
  }

  hide(): void {
    if (this.editorElement && this.tx) {
      this.setLoading(true);
      this.documentLoading = true;
      this.tx.resetContents(() => {
        this.setLoading(false);
        this.documentLoading = false;
      });
      this.setEditModeStatus('VALID');
      this.editorElement.classList.add(EDITOR_HIDDEN_CLASS);
      document.querySelector(EDITOR_TEMP_CONTAINER)?.appendChild(this.editorElement);
      this.isShown = false;
    } else {
      console.warn('Text Control hide not available yet');
    }
  }

  dispose(): void {
    if (this.tx && this.tx.removeFromDom) {
      this.tx.removeFromDom();
    } else {
      console.warn('Text Control removeFromDom not available yet');
    }
  }

  reload(): void {
    if (this.tx) {
      this.isDisabled = false;
      this.isEditorLoaded = false;
      this.isRibbonLoaded = false;
      this.loading = false;
      this.hide();
      this.dispose();
      this.forceReload.emit();
      this.editorLoaded.next(false);
    }
  }

  setLoading(value: boolean): void {
    runOutsideAngular(() => {
      setTimeout(() => {
        this.loading = value;
        this.appRef.tick();
      }, 50);
    });
  }

  private async adaptTable(table: any) {
    const maxWidth = await getMaxWidth();
    const tableInfo = await getTableInfo(table);
    const rowWidths = await Promise.all(
      Array.from({ length: tableInfo.rows }, (_, i) =>
        getRowWidth(i + 1, tableInfo.cols, tableInfo.table)
      )
    );
    const destinationWidths = rowWidths.map((rowWidth: any) => rowWidth - maxWidth);

    return Promise.all(
      Array.from({ length: tableInfo.rows }, (_, i) =>
        setRowWidth(i + 1, tableInfo.cols, tableInfo.table, destinationWidths[i], rowWidths[i])
      )
    );
  }

  private async adaptAllTables(): Promise<void> {
    const promises: any[] = [];
    TXTextControl.tables.forEach((table: any) => {
      promises.push(this.adaptTable(table));
    });
    await Promise.all(promises);
  }
}
