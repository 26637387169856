import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { ToggleDarkMode } from './ui.actions';
import { LocalStorageService } from '../services/local-storage.service';

export interface UiStateModel {
  isDarkMode: boolean;
}

const UI_STATE_TOKEN = new StateToken<UiStateModel>('ui');

@State<UiStateModel>({
  name: UI_STATE_TOKEN,
  defaults: {
    isDarkMode: false,
  },
})
@Injectable()
export class UiState {
  constructor(private service: LocalStorageService) {}

  @Action(ToggleDarkMode)
  ToggleDarkMode(
    { getState, setState }: StateContext<UiStateModel>,
    { isDarkMode }: ToggleDarkMode
  ) {
    const state = getState();
    setState({
      ...state,
      isDarkMode,
    });
    return this.service.setSavedState(isDarkMode ? 'darkMode' : '', 'theme');
  }

  @Selector()
  static isDarkMode(state: UiStateModel) {
    return state.isDarkMode;
  }
}
