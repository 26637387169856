/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IShiftRequest } from '../../models/shift.model';

export class LoadShifts {
  static readonly type = '[Shifts] Load Shifts via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllShifts {
  static readonly type = '[Shifts] Load All Shifts via Service';
  constructor() {}
}

export class LoadAllShiftsForDepartment {
  static readonly type = '[Shifts] Load All Shifts For Department via Service';
  constructor(public departmentId?: string) {}
}

export class LoadAllShiftsWithDepartment {
  static readonly type = '[Shifts] Load All Shifts With Department via Service';
  constructor(public onlyAvailableShifts: boolean = true) {}
}

export class LoadShiftById {
  static readonly type = '[Shifts] Load Shift By Id via Service';
  constructor(public id: string) {}
}

export class CreateShift {
  static readonly type = '[Shifts] Create Shift';
  constructor(public shift: IShiftRequest) {}
}

export class UpdateShift {
  static readonly type = '[Shifts] Update Shift';
  constructor(public shift: IShiftRequest) {}
}

export class DeleteShift {
  static readonly type = '[Shifts] Delete Shift';
  constructor(public shiftId: string) {}
}
