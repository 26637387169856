import { Pipe, PipeTransform } from '@angular/core';
import { OnBoardStatus } from 'src/app/crew-management-user/enums/on-board-status.enum';

@Pipe({
  name: 'onBoardStatusMap',
  pure: true,
  standalone: true,
})
export class OnBoardStatusMapPipe implements PipeTransform {
  private onBoardStatusMapper = {
    [OnBoardStatus.OnBoard]: 'onBoard',
    [OnBoardStatus.TemporaryAshore]: 'temporaryAshore',
  };

  transform(onBoardStatus: OnBoardStatus): string {
    return this.onBoardStatusMapper[onBoardStatus];
  }
}
