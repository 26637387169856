import { IOption } from '../../shared/models/option.model';

export const SoldBy: { [key: string]: IOption } = {
  CCOMNorway: { id: 1, name: 'CCOM Norway' },
  CCOMIceland: { id: 2, name: 'CCOM Iceland' },
};

export enum SoldByEnum {
  CCOMNorway = 1,
  CCOMIceland = 2,
}
