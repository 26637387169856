<ng-container *ngIf="moduleNumber !== 2; else maintenanceStructure">
  <ng-container *ngIf="onlyAdd; else editButtons">
    <app-ui-button
      text="{{ 'newRootElement' | translate }}"
      icon="add"
      (click)="openModal(menuTreeButtonActions.Create)"
      class="only-add-button"
      privilege="MENU_CREATE"
      [disabled]="menuDNDService.isStructureChanged"
      [matTooltip]="menuDNDService.isStructureChanged ? 'saveChangesBeforeAddingItem' : ''"
    ></app-ui-button>
    <app-ui-button
      *ngIf="isProductOwner"
      text="{{ 'copyMenu' | translate }}"
      icon="content_copy"
      (click)="openCopyMenuModal()"
      class="only-add-button"
    ></app-ui-button>
    <ng-container *ngTemplateOutlet="structureActions"></ng-container>
  </ng-container>
  <ng-template #editButtons>
    <div
      class="edit-buttons"
      [class.disabled-edit]="menuDNDService.isStructureChanged"
      [matTooltip]="
        menuDNDService.isStructureChanged ? ('saveChangesBeforeEditingItem' | translate) : ''
      "
    >
      <app-ui-button
        color="primary"
        text="{{ 'newMenuItem' | translate }}"
        buttonType="icon-button"
        icon="add"
        (click)="openModal(menuTreeButtonActions.Create)"
        class="square-menu-button"
        privilege="MENU_CREATE"
      ></app-ui-button>
      <app-ui-button
        text=""
        buttonType="icon-button"
        icon="remove"
        (click)="openModal(menuTreeButtonActions.Delete)"
        class="square-menu-button"
        privilege="MENU_DELETE"
      ></app-ui-button>
      <app-ui-button
        text=""
        buttonType="icon-button"
        icon="edit"
        (click)="openModal(menuTreeButtonActions.Update)"
        class="square-menu-button"
        privilege="MENU_UPDATE"
      ></app-ui-button>
      <app-ui-button
        *ngIf="isProductOwner"
        text=""
        buttonType="icon-button"
        icon="content_copy"
        (click)="openCopyMenuModal()"
        class="square-menu-button"
      ></app-ui-button>
    </div>
  </ng-template>
</ng-container>
<ng-template #maintenanceStructure>
  <ng-container *ngIf="onlyAdd; else allButtons">
    <app-ui-button
      [text]="'newRootElement' | translate"
      icon="add"
      (click)="openModal(menuTreeButtonActions.Create)"
      class="square-menu-button"
      [privilege]="['COMPONENT_CREATE', 'COMPONENT-GROUP_CREATE']"
      [disabled]="menuDNDService.isStructureChanged"
      [matTooltip]="menuDNDService.isStructureChanged ? 'saveChangesBeforeAddingItem' : ''"
    ></app-ui-button>
    <app-ui-button
      *ngIf="isProductOwner"
      text="{{ 'copyComponents' | translate }}"
      icon="content_copy"
      (click)="openCopyComponentsStructureModal()"
      class="only-add-button"
    ></app-ui-button>
    <ng-container *ngTemplateOutlet="structureActions"></ng-container>
  </ng-container>
  <ng-template #allButtons>
    <div
      class="edit-buttons"
      [class.disabled-edit]="menuDNDService.isStructureChanged"
      [matTooltip]="
        menuDNDService.isStructureChanged ? ('saveChangesBeforeEditingItem' | translate) : ''
      "
    >
      <app-ui-button
        text=""
        buttonType="icon-button"
        icon="edit"
        (click)="openModal(menuTreeButtonActions.Update)"
        class="square-menu-button"
        [privilege]="['COMPONENT_UPDATE', 'COMPONENT-GROUP_UPDATE']"
      ></app-ui-button>
      <app-ui-button
        text=""
        buttonType="icon-button"
        icon="add"
        (click)="openModal(menuTreeButtonActions.Create)"
        class="square-menu-button"
        [privilege]="['COMPONENT_CREATE', 'COMPONENT-GROUP_CREATE']"
      ></app-ui-button>
      <app-ui-button
        text=""
        buttonType="icon-button"
        icon="remove"
        (click)="openModal(menuTreeButtonActions.Delete)"
        class="square-menu-button"
        [privilege]="['COMPONENT_DELETE', 'COMPONENT-GROUP_DELETE']"
      ></app-ui-button>
      <app-ui-button
        *ngIf="isProductOwner"
        text=""
        buttonType="icon-button"
        icon="content_copy"
        (click)="openCopyComponentsStructureModal()"
        class="square-menu-button"
      ></app-ui-button>
    </div>
  </ng-template>
</ng-template>
<ng-template #structureActions>
  <ng-container *ngIf="menuDNDService.isStructureChanged">
    <app-ui-button
      color="primary"
      text="{{ 'saveMenuOrder' | translate }}"
      (click)="menuDNDService.saveChanges()"
    ></app-ui-button>
    <app-ui-button
      buttonType="secondary"
      text="{{ 'resetChanges' | translate }}"
      (click)="menuDNDService.cancelChanges()"
    ></app-ui-button>
  </ng-container>
</ng-template>
