import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserDrillsRoutes } from '../../../../../core/enums/routes.enum';
import { DrillsActionType } from '../../../../../drills-user/components/enum/drills-action-type';

@Component({
  selector: 'app-drills-log-template',
  templateUrl: './drills-log-template.component.html',
  styleUrls: ['./drills-log-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrillsLogTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  drillActionTypes = DrillsActionType;
  performViewRoute = UserDrillsRoutes.PerformView;
}
