/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ITripResponse } from '../../models/trip.model';

export class LoadTrips {
  static readonly type = '[Trips] Load Trips via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAvailableTrips {
  static readonly type = '[Trips] Load All Trips via Service';
  constructor() {}
}

export class LoadTripById {
  static readonly type = '[Trips] Load Trip By Id via Service';
  constructor(public id: string) {}
}

export class UpdateTrip {
  static readonly type = '[Trips] Update Trip';
  constructor(public trip: ITripResponse) {}
}

export class EndTrip {
  static readonly type = '[Trips] End Trip';
  constructor(public tripId: string, public endDate: Date) {}
}

export class PrintTripPdf {
  static readonly type = '[Trips] Print Trip';
  constructor(public tripId: string) {}
}

export class DeleteTrip {
  static readonly type = '[Trips] Delete Trip';
  constructor(public tripId: string) {}
}
