<ng-container [ngSwitch]="specificTemplateName">
  <ng-container *ngSwitchCase="'plannedActivityUsers'">
    <app-planned-activity-user-template
      [columnName]="columnName"
      [element]="element"
    ></app-planned-activity-user-template>
  </ng-container>
  <ng-container *ngSwitchCase="'paxOverview'">
    <app-pax-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
    ></app-pax-template>
  </ng-container>
  <ng-container *ngSwitchCase="'paxOnBoard'">
    <app-pax-on-board-template
      [columnName]="columnName"
      [element]="element"
    ></app-pax-on-board-template>
  </ng-container>
  <ng-container *ngSwitchCase="'trips'">
    <app-trips-template [columnName]="columnName" [element]="element"></app-trips-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-crew-management-user-template
      [columnName]="columnName"
      [element]="element"
    ></app-crew-management-user-template>
  </ng-container>
</ng-container>
