import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-missing-access',
  templateUrl: './missing-access.component.html',
  styleUrls: ['./missing-access.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class MissingAccessComponent {}
