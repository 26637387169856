import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { FormArray } from '@angular/forms';
import { markControlsAsTouched } from 'src/app/shared/functions/mark-controls-as-touched';
import { WebHookForm, WebHookHeaderForm } from '../../forms/webhook.form';
import { WebHookApiRequest, WebHookModel, WebHookReqHeader } from '../../models/webhook.model';
import { UpdateThirdPartyIntegrations } from '../../state/tenant.action';

@Component({
  selector: 'app-third-party-integration-edit',
  templateUrl: './third-party-integration-edit.component.html',
  styleUrls: ['./third-party-integration-edit.component.scss'],
})
export class ThirdPartyIntegrationEditComponent {
  form: WebHookForm;
  title: string;
  availableEvents: string[] = [];
  alreadyExistingEvents: string[] = [];

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<ThirdPartyIntegrationEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      webhooks: WebHookModel[];
      webhookTopic: string;
      availableEvents: string[];
      tenantId: string;
    }
  ) {
    if (data.availableEvents?.length > 0) {
      this.availableEvents = data.availableEvents;
    }
    if (data.webhooks?.length > 0 && data.webhookTopic) {
      this.availableEvents.push(data.webhookTopic);
      const webhookToEdit = this.data.webhooks.find(
        (webhook: WebHookModel) => webhook.topic === data.webhookTopic
      );
      this.form = new WebHookForm(webhookToEdit);
      webhookToEdit?.requestHeaders.forEach((requestHeader: WebHookReqHeader) => {
        if (requestHeader.name && requestHeader.value) {
          const newReqHeader = new WebHookHeaderForm(requestHeader);
          newReqHeader.disable();
          this.requestHeaders.push(newReqHeader);
        }
      });
      this.title = 'editWebhook';
      this.form.disable();
    } else {
      this.title = 'createWebhook';
      this.form = new WebHookForm();
    }
  }

  newReqHeaders(): void {
    if (!this.form.disabled) this.form.requestHeaders.push(new WebHookHeaderForm());
  }

  enableForm(): void {
    this.form.enable();
  }

  save(): void {
    markControlsAsTouched(this.form);
    if (!this.form.valid) {
      return;
    }
    const updatedWebhooks = [...this.data.webhooks];
    const index = updatedWebhooks.findIndex(
      (webhook: WebHookModel) => webhook.topic === this.form.topic.value
    );
    if (index !== -1) {
      const requestHeadersUpdated: WebHookReqHeader[] = [];
      this.form.requestHeaders.value.forEach((reqHed: { name: string; headerValue: string }) => {
        requestHeadersUpdated.push({ name: reqHed.name, value: reqHed.headerValue });
      });
      updatedWebhooks[index] = {
        callback: this.form.callback.value,
        topic: this.form.topic.value,
        requestHeaders: requestHeadersUpdated,
      };
    } else {
      const requestHeadersUpdated: WebHookReqHeader[] = [];
      this.form.requestHeaders.value.forEach((reqHed: { name: string; headerValue: string }) => {
        requestHeadersUpdated.push({ name: reqHed.name, value: reqHed.headerValue });
      });
      updatedWebhooks.push({
        callback: this.form.callback.value,
        topic: this.form.topic.value,
        requestHeaders: requestHeadersUpdated,
      });
    }
    const webhooksApiReq: WebHookApiRequest = {
      subscriptionDetails: updatedWebhooks,
    };
    this.store.dispatch(new UpdateThirdPartyIntegrations(webhooksApiReq, this.data.tenantId));
    this.dialogRef.close();
  }

  delete(): void {
    let updatedWebhooks = [...this.data.webhooks];
    const index = updatedWebhooks.findIndex(
      (webhook: WebHookModel) => webhook.topic === this.form.topic.value
    );
    if (index !== -1) {
      updatedWebhooks = updatedWebhooks.filter(
        (webhook: WebHookModel) => webhook.topic !== this.form.topic.value
      );
      const webhooksApiReq: WebHookApiRequest = {
        subscriptionDetails: updatedWebhooks,
      };
      this.store.dispatch(new UpdateThirdPartyIntegrations(webhooksApiReq, this.data.tenantId));
      this.dialogRef.close();
    }
  }

  get requestHeaders() {
    return this.form.get('requestHeaders') as FormArray<WebHookHeaderForm>;
  }
}
