<mat-form-field *ngIf="control" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    panelClass="app-ui-select"
    [hideSingleSelectionIndicator]="true"
    [formControl]="control"
    [multiple]="isMultiple"
    (selectionChange)="onChange($event)"
    [value]="selected"
    [disabled]="disabled"
    [required]="required"
    [compareWith]="compareWith"
  >
    <mat-optgroup *ngFor="let group of optionGroups" [label]="group.type | translate">
      <ng-container *ngIf="returnWholeObject; else returnId">
        <ng-container *ngTemplateOutlet="wholeObject"></ng-container>
      </ng-container>

      <ng-template #returnId>
        <mat-option *ngFor="let option of group.options" [value]="option.id">
          {{ option.name }}
        </mat-option>
      </ng-template>

      <ng-template #wholeObject>
        <mat-option
          *ngFor="let option of group.options"
          [value]="{ value: option, type: group.type }"
        >
          {{ option.name }}
        </mat-option>
      </ng-template>
    </mat-optgroup>
  </mat-select>
  <ng-container *ngIf="!control.pristine">
    <mat-error *ngFor="let error of control.errors | errorKeys">
      {{ messages[error] | translate }}
    </mat-error>
  </ng-container>
</mat-form-field>
<div class="input-description">{{ description }}</div>
