<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'id'">
    <mat-icon
      *ngIf="showAlertWarnings"
      class="status-icon"
      [class.expired]="element.alertStatus === alertStatus.Expired"
      [class.expiring]="element.alertStatus === alertStatus.Expiring"
      >circle</mat-icon
    >
    {{ element.readableId }}
  </ng-container>
  <ng-container *ngSwitchCase="'nonConformanceId'"> {{ element.readableId }} </ng-container>
  <ng-container *ngSwitchCase="'severity'">
    {{ element.severity?.name || '-' }}
  </ng-container>
  <ng-container *ngSwitchCase="'type'">
    {{ element.type.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'nonConformanceDate'">
    {{ element.nonConformanceDate | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'expires'">
    {{ element.expires | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'version'"> V{{ element.formVersion }} </ng-container>
  <ng-container *ngSwitchCase="'dateTime'">
    <ng-container
      *ngIf="element.action === 'Legacy' && element.created; else standardDateTimeEntry"
    >
      {{ element.created | date : 'yyyy-MM-dd HH:mm' }}
    </ng-container>
    <ng-template #standardDateTimeEntry
      >{{ element.updated ? (element.updated | date : 'yyyy-MM-dd HH:mm') : '-' }}
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'editedBy'">
    {{ element.updatedBy | fullName }}
  </ng-container>
  <ng-container *ngSwitchCase="'action'">
    {{ element.action | mapNonConformanceAction | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'restore'">
    <app-ui-button
      appConfirmDialog
      confirmTitle="restore"
      confirmMessage="youAreRestoring"
      [confirmEntityName]="element.name"
      (confirmed)="restore()"
      buttonType="secondary"
      text="{{ 'restore' | translate }}"
      privilege="NON-CONFORMANCE_RESTORE"
    >
    </app-ui-button>
  </ng-container>
  <ng-container *ngSwitchCase="'comment'">
    {{ 'comment' | translate }}: {{ element.comment }}
  </ng-container>
  <ng-container *ngSwitchCase="'actionButton'">
    <ng-container
      *ngIf="
        element.action === nonConformanceAction.ReportUpdated ||
        element.action === nonConformanceAction.ReportCreated
      "
    >
      <button
        *ngIf="element.responseId"
        mat-button
        (click)="viewPreviousReport(element.id, element.responseId)"
      >
        <mat-icon>remove_red_eye</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="element.action === nonConformanceAction.Legacy">
      <a
        *ngIf="element.id !== 'new' && element.link?.length > 1"
        [href]="element.link"
        target="_blank"
        [download]="element.name"
      >
        <mat-icon class="download-icon">download</mat-icon>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'severities'">
    <div *ngFor="let severity of element.severities">
      {{ severity?.name ?? '-' }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'nonConformanceTypes'">
    <div *ngFor="let type of element.nonConformanceTypes">
      {{ type?.name ?? '-' }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'responsibleForClosing'">
    <div *ngFor="let position of element.positionsResponsibleForClosing">
      {{ position.name }}
    </div>
    <div *ngFor="let positionGroup of element.positionGroupsResponsibleForClosing">
      {{ positionGroup.name }}
    </div>
    <div *ngFor="let user of element.usersResponsibleForClosing">
      {{ user | fullName }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'responsibleForFollowUp'">
    <div *ngFor="let positionForFollowUp of element.positionsResponsibleForFollowUp">
      {{ positionForFollowUp.name }}
    </div>
    <div *ngFor="let positionGroupForFollowUp of element.positionGroupsResponsibleForFollowUp">
      {{ positionGroupForFollowUp.name }}
    </div>
    <div *ngFor="let userForFollowUp of element.usersResponsibleForFollowUp">
      {{ userForFollowUp | fullName }}
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
