<div [matMenuTriggerFor]="language" class="expand-button-section">
  <mat-icon *ngIf="!userAccess?.profilePictureMiniature" class="navbar-user-icon"
    >account_circle</mat-icon
  >
  <img
    *ngIf="userAccess?.profilePictureMiniature"
    [src]="userAccess?.profilePictureMiniature?.link | appendDomain"
    alt="profile picture"
    class="navbar-user-icon"
  />
  <span class="navbar-name-label">{{ tenantUserName }}</span>
  <mat-icon class="expand-icon">expand_more</mat-icon>
</div>

<mat-menu #language="matMenu">
  <div class="main-container">
    <div class="name-section">
      <mat-icon
        class="dropdown-user-icon"
        color="primary"
        *ngIf="!userAccess?.profilePictureMiniature"
        >account_circle</mat-icon
      >
      <img
        class="dropdown-user-icon"
        *ngIf="userAccess?.profilePictureMiniature"
        [src]="userAccess?.profilePictureMiniature?.link | appendDomain"
        alt="profile picture"
      />
      <div class="name-position-labels">
        <span class="dropdown-name-label">{{ tenantUserName }}</span>
        <span class="position-label">
          {{ 'position' | translate }}: {{ tenantPosition | translate }}</span
        >
      </div>
    </div>
    <div class="mail-section">
      {{ tenantUserEmail }}
    </div>

    <div class="separator"></div>

    <div class="section-with-title">
      <span class="section-title-label">{{ 'tenant' | translate | uppercase }}</span>
    </div>

    <button (click)="changeTenant()" mat-menu-item>
      <div class="icon-text-container">
        <mat-icon>notifications_off</mat-icon>
        {{ 'customer' | translate }}
      </div>
    </button>

    <div class="separator"></div>

    <div class="section-with-title">
      <span class="section-title-label">{{ 'settings' | translate | uppercase }}</span>
    </div>

    <button (click)="onToggleDarkMode($event)" mat-menu-item>
      <div class="dark-mode-toggle-container">
        <div class="icon-text-container">
          <mat-icon [class.sun]="!isDarkMode">{{
            isDarkMode ? 'bedtime' : 'brightness_7'
          }}</mat-icon>
          <span>{{ 'darkMode' | translate }}</span>
        </div>
        <mat-slide-toggle [checked]="isDarkMode" appStopClickPropagation></mat-slide-toggle>
      </div>
    </button>

    <button mat-menu-item (click)="onProfileSettings()" [disabled]="!profileSettingsAvailable">
      <div class="icon-text-container">
        <mat-icon>person</mat-icon>
        <span>
          {{ 'profileSettings' | translate }}
        </span>
      </div>
    </button>

    <div class="separator"></div>

    <app-logout-button></app-logout-button>
  </div>
</mat-menu>
