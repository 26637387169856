import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export class CopyComponentsStructureForm extends FormGroup {
  readonly targetDepartmentIds = this.get('targetDepartmentIds') as FormControl<string[]>;
  readonly targetTenantId = this.get('targetTenantId') as FormControl<string>;
  readonly componentId = this.get('componentId') as FormControl<string>;
  readonly copyContents = this.get('copyContents') as FormControl<boolean>;

  constructor(id?: string, readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        componentId: id,
        targetTenantId: ['', Validators.required],
        targetDepartmentIds: [[], Validators.required],
        copyContents: [false],
      }).controls
    );
  }
}
