/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IProductOwner } from '../models/product-owner.model';

export class LoadProductOwners {
  static readonly type = '[Product Owner] Load Product Owners';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllProductOwners {
  static readonly type = '[Product Owner] Load All Product Owners';
  constructor() {}
}

export class CreateProductOwner {
  static readonly type = '[Product Owner] Create Product Owner';
  constructor(public productOwner: IProductOwner) {}
}

export class UpdateProductOwner {
  static readonly type = '[Product Owner] Update Product Owner';
  constructor(public productOwner: IProductOwner) {}
}

export class DeleteProductOwner {
  static readonly type = '[Product Owner] Delete Product Owner';
  constructor(public id: string) {}
}
