import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { HashtagApiService } from 'src/app/hashtag/services/hashtag-api.service';
import { HashtagModel } from '../models/hashtag.model';
import { LoadHashtags } from './hashtag.actions';

export interface HashtagStateModel {
  hashtagsLoaded: boolean;
  hashtags: HashtagModel[];
}

const HASHTAG_STATE_TOKEN = new StateToken<HashtagStateModel>('hashtag');

@State<HashtagStateModel>({
  name: HASHTAG_STATE_TOKEN,
  defaults: {
    hashtagsLoaded: false,
    hashtags: [],
  },
})
@Injectable()
export class HashtagState {
  constructor(private service: HashtagApiService) {}

  @Action(LoadHashtags, { cancelUncompleted: true })
  loadHashtags({ getState, setState }: StateContext<HashtagStateModel>) {
    return this.service.fetchHashtags().pipe(
      tap((hashtags: HashtagModel[]) => {
        const state = getState();
        setState({
          ...state,
          hashtagsLoaded: true,
          hashtags,
        });
      })
    );
  }

  @Selector()
  static allHashtags(state: HashtagStateModel) {
    return state.hashtags;
  }

  @Selector()
  static hashtagsLoaded(state: HashtagStateModel) {
    return state.hashtagsLoaded;
  }
}
