import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { getDepartmentId, getTenantId } from 'src/app/services/store-snapshot.service';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { environment } from 'src/environments/environment';
import { IdName } from '../../shared/models/id-name';
import { IDepartment, IDepartmentUser, IDepartmentWithGroups } from '../models/department.model';
import { DepartmentService } from './department.service';

@Injectable({
  providedIn: 'root',
})
export class DepartmentApiService {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };

  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/departments`;
  }

  private urlForSelectedTenant(tenantId: string): string {
    return `${environment.apiUrl}/api/${tenantId}/departments`;
  }

  private get selectedDepartment(): string {
    return getDepartmentId();
  }

  constructor(private http: HttpClient) {}

  getDepartments(currentFilter?: MainTableFilterModel): Observable<ApiResponse<IDepartment[]>> {
    let url = `${this.url}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IDepartment[]>>(url, this.httpOptions);
  }

  getAllDepartments(): Observable<IdName[]> {
    const url = `${this.url}/List`;
    return this.http.get<IdName[]>(url, this.httpOptions);
  }

  getAccessedByEmployment(): Observable<IdName[]> {
    const url = `${this.url}/accessedByEmployment`;
    return this.http.get<IdName[]>(url, this.httpOptions);
  }

  getAllDepartmentsForSelectedTenant(tenantId: string): Observable<IdName[]> {
    const url = `${this.urlForSelectedTenant(tenantId)}/List`;
    return this.http.get<IdName[]>(url, this.httpOptions);
  }

  getAllDepartmentsForMenuId(menuId: string): Observable<IdName[]> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/menu/${menuId}/departments`;
    return this.http.get<IdName[]>(url, this.httpOptions);
  }

  getDepartmentsForUser(): Observable<IDepartmentWithGroups[]> {
    const url = `${this.url}/forUser`;
    return this.http.get<IDepartmentWithGroups[]>(url, this.httpOptions);
  }

  getSelectedDepartmentUsers(includeDeleted: boolean): Observable<IDepartmentUser[]> {
    const url = `${this.url}/${this.selectedDepartment}/users?IncludeDeleted=${includeDeleted}`;
    return this.http.get<IDepartmentUser[]>(url, this.httpOptions);
  }

  getDepartmentById(departmentId: string) {
    const url = `${this.url}/${departmentId}`;
    return this.http.get<IDepartment>(url);
  }

  createDepartment(department: IDepartment): Observable<Response> {
    const formData = DepartmentService.convertToFormData(department, getTenantId());
    return this.http.post<Response>(this.url, formData);
  }

  updateDepartment(department: IDepartment): Observable<IDepartment> {
    const formData = DepartmentService.convertToFormData(department, getTenantId());
    return this.http.put<IDepartment>(this.url, formData);
  }

  deleteDepartment(departmentId: string): Observable<IDepartment> {
    const url = `${this.url}/${departmentId}`;
    return this.http.delete<IDepartment>(url, this.httpOptions);
  }
}
