/* eslint-disable no-magic-numbers */
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  UserDrillsRoutes,
  UserMaintenanceRoutes,
  UserTasksRoutes,
} from '../../../../../core/enums/routes.enum';
import { DueListRoutingLogicService } from '../../../../../due-list/services/due-list-routing-logic.service';

const moduleUrlMapper = {
  core: { route: '' },
  Certificate: { route: '' },
  MaintenanceJob: { route: UserMaintenanceRoutes.JobPerform },
  Document: { route: '' },
  NonConformance: { route: '' },
  audit: { route: '' },
  storage: { route: '' },
  UserCompetence: { route: '' },
  crewManagement: { route: '' },
  order: { route: '' },
  Medicine: { route: '' },
  Drill: { route: UserDrillsRoutes.Perform },
  Work: { route: UserTasksRoutes.Perform },
  Runtime: { route: '' },
  RiskAssessment: { route: '' },
};
type ModuleUrlMapper = typeof moduleUrlMapper;

@Component({
  selector: 'app-due-list-template',
  templateUrl: './due-list-template.component.html',
  styleUrls: ['./due-list-template.component.scss'],
})
export class DueListTemplateComponent implements OnChanges {
  @Input() columnName: string;
  @Input() element: { category: keyof ModuleUrlMapper; [key: string]: any };

  constructor(public dueListService: DueListRoutingLogicService) {}

  performRouterLink: string;
  performMaintenanceJobRouterLink: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.element.currentValue) {
      this.performRouterLink = `${moduleUrlMapper[this.element.category].route}/${this.element.id}`;
      this.performMaintenanceJobRouterLink = moduleUrlMapper[this.element.category].route
        .replace(':id', this.element.id)
        .replace(':componentId', this.element.componentId);
    }
  }
}
