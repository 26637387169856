export enum UiPillStatusEnum {
  Ok = 'ok',
  NotCompleted = 'notcompleted',
  Active = 'active',
  Paused = 'paused',
  Archived = 'archived',
  Implementing = 'implementing',
  InProgress = 'inprogress',
  Expiring = 'expiring',
  Expired = 'expired',
  Published = 'published',
  ConfirmationNeeded = 'confirmationneeded',
  Inactive = 'inactive',
  Draft = 'draft',
  UnderRevision = 'underrevision',
  ForApproval = 'forapproval',
  InactiveOrHistory = 'inactiveorhistory',
  UnderThreshold = 'underthreshold',
  UnderMinimum = 'underminimum',
  ProductInOrder = 'productinorder',
  Rejected = 'rejected',
  Disabled = 'disabled',
  Deleted = 'deleted',
}
