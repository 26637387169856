import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DndModule } from 'ngx-drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { MenuTreeButtonsComponent } from './menu-tree-buttons/menu-tree-buttons.component';
import { MenuTreeDialogComponent } from './menu-tree-dialog/menu-tree-dialog.component';
import { MenuTreeSingleComponent } from './menu-tree-single/menu-tree-single.component';
import { MenuTreeStructureComponent } from './menu-tree-structure/menu-tree-structure.component';
import { MaterialModule } from '../../modules/material.module';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { UIElementsModule } from '../ui-elements/ui-elements.module';
import { AppLoaderDirective } from '../directives/app-loader.directive';
import { CopyMenuTreeModalComponent } from './copy-menu-tree-modal/copy-menu-tree-modal.component';
import { DialogContainerComponent } from '../dialogs/dialog-container/dialog-container.component';
import { ActionInProgressPipe } from '../directives/action-in-progress.pipe';

@NgModule({
  declarations: [
    CopyMenuTreeModalComponent,
    MenuTreeButtonsComponent,
    MenuTreeDialogComponent,
    MenuTreeSingleComponent,
    MenuTreeStructureComponent,
  ],
  imports: [
    ActionInProgressPipe,
    AppLoaderDirective,
    CommonModule,
    DialogContainerComponent,
    DndModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    TruncatePipe,
    UIElementsModule,
  ],
  exports: [MenuTreeStructureComponent],
})
export class MenuTreeModule {}
