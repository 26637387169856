<app-dialog-container
  title="{{ 'copyMenu' | translate }}"
  [isValid]="form.valid"
  (confirmButtonHandler)="save()"
>
  <form [formGroup]="form">
    <div>
      <app-ui-select
        label="{{ 'tenant' | translate }}"
        [control]="form.targetTenantId"
        [options]="allTenants"
        [isMultiple]="false"
        (changeHandler)="onTargetTenantChange()"
      >
      </app-ui-select>
      <app-ui-select
        label="{{ 'departments' | translate }}"
        [control]="form.targetDepartmentIds"
        [options]="allDepartments"
        [isMultiple]="true"
      >
      </app-ui-select>
      <app-ui-slide-toggle
        label="{{ 'copyEntities' | translate }}"
        [control]="form.copyContent"
      ></app-ui-slide-toggle>
    </div>
  </form>
</app-dialog-container>
