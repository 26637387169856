import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { AdminMaintenanceRoutes } from 'src/app/core/enums/routes.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentStructureSupportLogicService } from 'src/app/maintenance-admin/services/component-structure-support-logic.service';

@Component({
  selector: 'app-comp-or-comp-group-dialog',
  templateUrl: './comp-or-comp-group-dialog.component.html',
  styleUrls: ['./comp-or-comp-group-dialog.component.scss'],
})
export class CompOrCompGroupDialogComponent implements OnInit {
  constructor(
    private router: Router,
    private componentStructureSupportLogicService: ComponentStructureSupportLogicService,
    private dialogRef: MatDialogRef<CompOrCompGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.componentStructureSupportLogicService.saveTempParentId(this.data);
    }
  }

  createComponent(): void {
    this.router.navigate([`${AdminMaintenanceRoutes.ComponentDetail}/new`]);
    this.dialogRef.close();
  }

  createComponentGroup(): void {
    this.router.navigate([`${AdminMaintenanceRoutes.ComponentGroupDetail}/new`]);
    this.dialogRef.close();
  }
}
