/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IMedicineCategory } from '../models/medicine-category.model';

export class LoadMedicineCategories {
  static readonly type = '[Medicine Category] Load MedicineCategories via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadDepartmentMedicineCategories {
  static readonly type = '[Medicine Category] Load DepartmentMedicineCategories via Service';
}

export class LoadMedicineCategoryById {
  static readonly type = '[Medicine Category] Load a single medicine category';
  constructor(public id: string) {}
}

export class CreateMedicineCategory {
  static readonly type = '[Medicine Category] Create medicine category';
  constructor(public medicineCategory: IMedicineCategory) {}
}

export class UpdateMedicineCategory {
  static readonly type = '[Medicine Category] Update medicine category';
  constructor(public medicineCategory: IMedicineCategory) {}
}

export class DeleteMedicineCategory {
  static readonly type = '[Medicine Category] Delete medicine category';
  constructor(public id: string) {}
}
