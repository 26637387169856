declare global {
  interface Window {
    apiUrl: string | null;
    authUrl: string | null;
    textControlUrl: string | null;
    textControlWS: string | null;
    textControlTenants: string | null;
  }
}

export const environment = {
  production: false,
  serviceWorker: false,
  environmentName: 'dev',
  instrumentationKey: 'af985bef-007b-4ccd-9f18-19e3a60f4854',
  apiUrl: window.apiUrl || 'http://localhost:5000',
  authUrl: window.authUrl || 'http://gateway.docker.internal:5002',
  froalaKey: '8JF3bB2B3A2E4B2A2D1zPAENHMf1JPQRFZBTBf1WWEPYDbB3H3E2A10A19B7A5C6A2E1==', // TODO: move to .env for better security
  surveyJSKey:
    'NTg0NDcyYTQtMGEzYS00MzRlLWJiYzItMzc4OTk3OGYxNTFiOzE9MjAyNS0wMS0xOCwyPTIwMjUtMDEtMTg=',
  textControlUrl: window.textControlUrl || 'https://textcontrol.ccomx.app',
  textControlWS: `${window.textControlWS || 'wss://textcontrol.ccomx.app'}/api/TXWebSocket`,
  textControlTenants:
    window.textControlTenants !== undefined &&
    window.textControlTenants !== null &&
    window.textControlTenants.length > 0
      ? window.textControlTenants.split(',')
      : [
          '9986126d-1343-44c2-a89b-08dae3a21bc6',
          '5CAB3E24-F6C2-45EE-96D5-08DB5C4584DE',
          '8b2d6c5c-76b2-4b73-8ce9-08db7e1a8d1d',
          '29e4cf21-1262-401d-b9df-6139cafcef57',
          'f857752b-ba4e-4109-ad14-08dbf63b0b32',
        ],
};
