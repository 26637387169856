import { Store } from '@ngxs/store';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, debounceTime, tap } from 'rxjs';
import { MainTableFilterModulesEnum } from 'src/app/core/enums/main-table-filter-modules.enum';
import { MainTableFilterIdentification } from 'src/app/core/models/main-table-filter-identification.model';
import { ApplySearch } from 'src/app/core/state/main-table-filter.actions';
import { IPageModel } from 'src/app/shared/models/page.model';
import { MainTableFilterState } from 'src/app/core/state/main-table-filter.state';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

@Component({
  selector: 'app-main-table-search',
  templateUrl: './main-table-search.component.html',
  styleUrls: ['./main-table-search.component.scss'],
})
export class MainTableSearchComponent implements OnChanges, OnInit, OnDestroy {
  @Input() totalPages: any;
  @Input() additional: any;
  @Input() moduleName: MainTableFilterModulesEnum;

  currentPageDetails: IPageModel;
  searchTerm = new FormControl<string>('');

  private subscription = new Subscription();
  private filterIdentification: MainTableFilterIdentification;
  private currentSearchTerm: string;

  constructor(private store: Store) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (
      this.filterIdentification &&
      _changes.additional?.currentValue !== _changes.additional?.previousValue
    ) {
      this.filterIdentification.additional = _changes.additional.currentValue;
      this.checkIfSearchIsPresent();
    }
  }

  ngOnInit(): void {
    this.listenToSearchChanges();
    this.checkIfSearchIsPresent();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchChanged(searchTerm: any): void {
    this.store.dispatch(new ApplySearch(this.moduleName, searchTerm, this.additional));
  }

  private listenToSearchChanges(): void {
    const debounceTimeCounter = 500;
    this.searchTerm.valueChanges
      .pipe(
        debounceTime(debounceTimeCounter),
        tap((searchTerm: any) => {
          if (searchTerm !== this.currentSearchTerm) {
            this.searchChanged(searchTerm);
          }
        })
      )
      .subscribe();
  }

  private checkIfSearchIsPresent(): void {
    this.filterIdentification = new MainTableFilterIdentification(this.moduleName, this.additional);
    this.subscription.add(
      this.store
        .select(MainTableFilterState.getPagingDetails(this.filterIdentification))
        .pipe(
          tap((pagingDetails: MainTableFilterModel | undefined) => {
            if (pagingDetails?.searchTerm && pagingDetails?.searchTerm !== this.currentSearchTerm) {
              this.currentSearchTerm = pagingDetails?.searchTerm;
              this.searchTerm.setValue(pagingDetails?.searchTerm);
              setTimeout(() => {
                this.searchChanged(pagingDetails?.searchTerm);
              }, 0);
            }
          })
        )
        .subscribe()
    );
  }
}
