import { getTenantId } from 'src/app/services/store-snapshot.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IdName } from '../../shared/models/id-name';
import { IDepartmentGroupModel } from '../models/department-group.model';
import { IDepartmentGroupModelWithJobPositions } from '../models/department-group-with-job-positions.model';
import { IDepartmentGroupsWithDepartments } from '../models/department-group-with-departments.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentGroupApiService {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };

  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/DepartmentGroup`;
  }

  constructor(private http: HttpClient, private store: Store) {}

  getDepartmentGroups(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IDepartmentGroupModel[]>> {
    let url = `${this.url}`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IDepartmentGroupModel[]>>(url, this.httpOptions);
  }

  getAllDepartmentGroups(): Observable<IDepartmentGroupModel[]> {
    const url = `${this.url}/List`;
    return this.http.get<IDepartmentGroupModel[]>(url, this.httpOptions);
  }

  getDepartmentGroupsForUser(): Observable<IDepartmentGroupModelWithJobPositions[]> {
    const url = `${this.url}/forUser`;
    return this.http.get<IDepartmentGroupModelWithJobPositions[]>(url, this.httpOptions);
  }

  getDepartmentGroupsWithDepartments(
    departmentIds?: string[],
    departmentGroupIds?: string[]
  ): Observable<IDepartmentGroupsWithDepartments> {
    if (departmentIds?.length === 0 && departmentGroupIds?.length === 0) {
      throw new Error('Either department ids or department groups ids must be provided.');
    }

    const params = this.mapDepartmentsAndGroupsToParams(departmentIds, departmentGroupIds);

    const url = `${this.url}/Departments`;
    return this.http.get<IDepartmentGroupsWithDepartments>(url, { params });
  }

  getDepartmentGroupsById(id: string): Observable<IDepartmentGroupModel> {
    const url = `${this.url}/${id}`;
    return this.http.get<IDepartmentGroupModel>(url, this.httpOptions);
  }

  addDepartmentGroups(toAdd: IDepartmentGroupModel): Observable<IDepartmentGroupModel> {
    return this.http.post<IDepartmentGroupModel>(this.url, toAdd, this.httpOptions);
  }

  updateDepartmentGroups(toUpdate: IDepartmentGroupModel): Observable<IDepartmentGroupModel> {
    const url = `${this.url}/${toUpdate.id}`;
    return this.http.put<IDepartmentGroupModel>(url, toUpdate, this.httpOptions);
  }

  deleteDepartmentGroup(departmentGroupId: string): Observable<IDepartmentGroupModel> {
    const url = `${this.url}/${departmentGroupId}`;
    return this.http.delete<IDepartmentGroupModel>(url, this.httpOptions);
  }

  private mapDepartmentsAndGroupsToParams(
    departmentIds?: string[],
    departmentGroupIds?: string[]
  ): HttpParams {
    let params = new HttpParams();

    departmentIds?.forEach((department: string) => {
      params = params.append('DepartmentIds', department);
    });

    departmentGroupIds?.forEach((departmentGroup: string) => {
      params = params.append('DepartmentGroupIds', departmentGroup);
    });

    return params;
  }
}
