import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TenantTableNames } from '../../../../../tenant/enums/tenant-table-names';

@Component({
  selector: 'app-tenant-templates',
  templateUrl: './tenant-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantTemplatesComponent {
  @Input() specificTemplateName: string;
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: () => void };
  @Input() hideButtons: boolean;

  tenantTableNames = TenantTableNames;
}
