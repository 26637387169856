<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'action'">
    {{ element.type | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    {{ element.date | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'performDate'">
    {{ element.performDate ? (element.performDate | date : 'yyyy-MM-dd') : '---' }}
  </ng-container>
  <ng-container *ngSwitchCase="'runtimeCounter'">
    {{ element.runtimeName ?? '---' }}
  </ng-container>
  <ng-container *ngSwitchCase="'doneBy'">
    <span class="vertical-center done-by">
      <mat-icon class="material-icons-outlined">person</mat-icon> {{ element?.user?.userName }}
      <small>{{ element?.user?.jobPosition }}</small>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'openHistoryEntry__hidden'">
    <span class="vertical-center" *ngIf="element.isClickable"
      ><mat-icon>chevron_right</mat-icon></span
    >
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] ?? '---' }}
  </ng-container>
</ng-container>
