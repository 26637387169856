import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of, Subject, switchMap } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { ToggleDarkMode } from './core/state/ui.actions';
import { LocalStorageService } from './core/services/local-storage.service';
import { UiState } from './core/state/ui.state';
import { REDIRECT_PATH_KEY } from './services/store-snapshot.service';
import {
  ChangeCurrentSelectedDepartment,
  ChangeCurrentSelectedTenant,
} from './tenant-selector/state/tenant-user-access.actions';
import { TenantUserAccessState } from './tenant-selector/state/tenant-user-access.state';

type RedirectData = {
  externalLink: boolean;
  tenantId: string;
  departmentId: string;
  path: string | undefined | null;
};

const DARKMODE = 'darkMode';
const LIGHTMODE = 'lightMode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isDarkMode$: Observable<boolean> = this.store.select(UiState.isDarkMode);
  @HostBinding('class') className: string;

  title = 'ccomx-client';
  isAuthenticated = false;
  private readonly destroying$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {
    this.translate.addLangs(['en', 'no', 'se', 'is', 'es']);
    const localStorageLanguage = localStorage.getItem('language');
    if (localStorageLanguage) {
      this.translate.use(localStorageLanguage);
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit(): void {
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        switchMap(({ isAuthenticated }) => {
          this.isAuthenticated = isAuthenticated;
          if (!isAuthenticated) {
            this.oidcSecurityService.authorize();
            return EMPTY;
          }
          return of(this.prepareRedirectData());
        }),
        switchMap(this.redirect())
      )
      .subscribe();

    this.subscribeToTheme();
    this.checkState();
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }

  private redirect() {
    return ({ externalLink, tenantId, departmentId, path }: RedirectData) => {
      if (externalLink && path) {
        const isTenantChanged = TenantUserAccessState.getLastSelectedTenantId() !== tenantId;
        const isDepartmentChanged =
          TenantUserAccessState.getLastSelectedDepartmentId() !== departmentId;

        if (!isTenantChanged && !isDepartmentChanged) {
          this.navigateAndClearPathKey(path);
          return EMPTY;
        }

        return this.store.dispatch(new ChangeCurrentSelectedTenant(tenantId)).pipe(
          switchMap(() =>
            this.store.dispatch(new ChangeCurrentSelectedDepartment(departmentId, false))
          ),
          tap(() => this.navigateAndClearPathKey(path))
        );
      }

      this.navigateAndClearPathKey(path);
      return EMPTY;
    };
  }

  private navigateAndClearPathKey(path: string | null | undefined) {
    this.router.navigateByUrl(path ?? '/').then(() => sessionStorage.removeItem(REDIRECT_PATH_KEY));
  }

  private prepareRedirectData(): RedirectData {
    const item = sessionStorage.getItem(REDIRECT_PATH_KEY);
    const path = item;
    const externalLink = !!item?.includes('/ext/');
    const data = { externalLink, tenantId: '', departmentId: '', path };
    if (externalLink && item) {
      const tenantIdDepartmentIdPath = item.slice(item.indexOf('/ext/') + 5);
      data.tenantId = tenantIdDepartmentIdPath.slice(0, 36);
      data.departmentId = tenantIdDepartmentIdPath.slice(37, 73);
      data.path = tenantIdDepartmentIdPath.slice(73);
    }
    return data;
  }

  private checkState() {
    let mode = false;
    const localtheme = this.localStorageService.getSavedState('theme');
    if (localtheme === DARKMODE) {
      this.className = DARKMODE;
      mode = true;
    } else {
      this.className = LIGHTMODE;
    }
    this.store.dispatch(new ToggleDarkMode(mode));
  }

  private subscribeToTheme() {
    this.isDarkMode$
      .pipe(
        takeUntil(this.destroying$),
        tap((isDarkMode) => {
          const htmlBodyClasses = document.body.classList;
          if (isDarkMode) {
            this.className = DARKMODE;
            if (!htmlBodyClasses.contains(DARKMODE)) {
              htmlBodyClasses.add(DARKMODE);
            }
          } else {
            this.className = LIGHTMODE;
            if (htmlBodyClasses.contains(DARKMODE)) {
              htmlBodyClasses.remove(DARKMODE);
            }
          }
        })
      )
      .subscribe();
  }
}
