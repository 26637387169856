/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IPaxOverviewRequest } from '../../models/pax-overview.model';
import { IConfirmOnBoard } from '../../models/confirm-on-board.model';
import { IConfirmLeave } from '../../models/confirm-leave.model';
import { IEditMultipleCurrentPaxes } from '../../models/edit-multiple-current-paxes.model';
import { IConfirmLeaveMultiplePaxes } from '../../models/confirm-leave-multiple-paxes.model';

export class LoadCurrentPaxOverviews {
  static readonly type = '[PaxOverviews] Load Current Pax Overviews via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadPlannedPaxOverviews {
  static readonly type = '[PaxOverviews] Load Planned Pax Overviews via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadCurrentAndPlannedPaxOverviews {
  static readonly type = '[PaxOverviews] Load Current And Planned Pax Overviews via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadPastPaxOverviews {
  static readonly type = '[PaxOverviews] Load Past Pax Overviews via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadPaxOverviewById {
  static readonly type = '[PaxOverviews] Load Pax Overview By Id via Service';
  constructor(public id: string) {}
}

export class CreatePaxOverview {
  static readonly type = '[PaxOverviews] Create Pax Overview';
  constructor(public paxOverview: IPaxOverviewRequest) {}
}

export class UpdatePaxOverview {
  static readonly type = '[PaxOverviews] Update Pax Overview';
  constructor(public paxOverview: IPaxOverviewRequest) {}
}

export class DeletePaxOverview {
  static readonly type = '[PaxOverviews] Delete Pax Overview';
  constructor(public paxOverviewId: string) {}
}

export class ConfirmPAXOnBoard {
  static readonly type = '[CurrentPersonnel] Confirm Passenger On Board';
  constructor(public request: IConfirmOnBoard) {}
}

export class ConfirmPAXLeave {
  static readonly type = '[CurrentPersonnel] Confirm Passenger Leave';
  constructor(public request: IConfirmLeave) {}
}

export class ConfirmMultiplePAXLeave {
  static readonly type = '[CurrentPersonnel] Confirm Multiple Passengers Leave';
  constructor(public request: IConfirmLeaveMultiplePaxes) {}
}

export class EditMultipleCurrentPaxes {
  static readonly type = '[CurrentPersonnel] Edit Multiple Passengers';
  constructor(public request: IEditMultipleCurrentPaxes) {}
}
