import { initApp, StoreSnapshotService } from 'src/app/services/store-snapshot.service';
/* eslint-disable no-magic-numbers */
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthModule, LogLevel, OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DepartmentOptionsResolver } from './department/department-options.resolver';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AccessResolver } from './role/access.resolver';
import { UserOptionsResolver } from './shared/user-view/useroptions.resolver';
import { TenantSelectorComponent } from './tenant-selector/tenant-selector.component';
import { AccessContextsResolver } from './role/accessContexts.resolver';
import { RoleResolver } from './role/role.resolver';
import { TenantResolver } from './tenant/tenant.resolver';
import { ErrorInterceptor } from './core/services/error.interceptor';
import { CoreModule } from './core/core.module';
import { TenantUserAccessState } from './tenant-selector/state/tenant-user-access.state';
import { DepartmentState } from './department/state/department.state';
import { CustomFormStatusesResolver } from './custom-forms/custom-form-statuses.resolver';
import { AuthInterceptor } from './core/services/auth.interceptor';
import { MenuListState } from './core/state/menu-list.state';
import { TenantState } from './tenant/state/tenant.state';
import { USE_DATE_TIME_FORMAT } from './core/ccomx-date-formats';
import { UIElementsModule } from './shared/ui-elements/ui-elements.module';
import { DialogContainerComponent } from './shared/dialogs/dialog-container/dialog-container.component';

import { StaticDependenciesService } from './services/static-dependencies.service';
import { AppUpdateService } from './services/app-update.service';
import { HasAccessGuard } from './shared/guards/has-access.guard';
import { TimeZoneInfoInterceptor } from './core/services/timezone-info.interceptor';
import { MaterialModule } from './modules/material.module';
import { AppendDomainPipe } from './shared/pipes/append-domain-name.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, TenantSelectorComponent],
  imports: [
    AppRoutingModule,
    AppendDomainPipe,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    DialogContainerComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HammerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxsModule.forRoot([DepartmentState, MenuListState, TenantState, TenantUserAccessState], {
      developmentMode: !environment.production,
    }),
    AuthModule.forRoot({
      config: {
        authority: environment.authUrl,
        redirectUrl: window.location.origin,
        postLoginRoute: '/',
        postLogoutRedirectUri: window.location.origin,
        clientId: 'frontend',
        scope: 'openid profile email backendScope offline_access',
        responseType: 'code',
        silentRenew: true,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        useRefreshToken: true,
        logLevel: LogLevel.None,
        maxIdTokenIatOffsetAllowedInSeconds: 60 * 60 + 15 * 60, // One hour and fifteen minutes.
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    HttpClientModule,
    MaterialModule,
    UIElementsModule,
  ],
  exports: [TranslatePipe],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeZoneInfoInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { strict: true, useUtc: false },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [OidcSecurityService, Store, StoreSnapshotService],
      multi: true,
    },
    { provide: USE_DATE_TIME_FORMAT, useValue: false },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    AccessContextsResolver,
    AccessResolver,
    AppUpdateService,
    CustomFormStatusesResolver,
    DepartmentOptionsResolver,
    HasAccessGuard,
    RoleResolver,
    StoreSnapshotService,
    TenantResolver,
    UserOptionsResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private appUpdateService: AppUpdateService,
    private staticDependenciesService: StaticDependenciesService
  ) {}
}
