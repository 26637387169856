import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MainTableCustomSortingService {
  constructor() {}

  getCustomSorting(row: any, key: any) {
    switch (key) {
      case 'fullName':
        return row.firstName + row.lastName;
      case 'status':
        return row.expiryDate ?? row.isActive ?? row.status;
      case 'competenceCode':
        return row.competenceCode;
      case 'competenceName':
        return row.competenceName;
      case 'inUse':
        return row.isInUse;
      case 'expiry':
        return row.expiry;
      case 'requirementType':
        return row.requirementType;
      case 'statusCompetenceUser':
        return row.status;
      case 'actionCompetenceUser':
        return row.daysUntilExpired;
      case 'registrationComplete':
        return row.isRegistrationCompleted;
      case 'statusIcon':
        return row.statusId;
      case 'formStatus':
        return row.formStatus;
      case 'nonConformanceId':
        return row.id;
      case 'severity':
        return row.severity.name;
      case 'type':
        return row.type.name ?? row.type;
      case 'nonConformanceDate':
        return row.nonConformanceDate;
      case 'expires':
        return row.expires;
      case 'version':
        return row.name;
      case 'dateTime':
        return row.nonConformanceDate;
      case 'mainContact':
        return row.isMainContact;
      case 'phoneNumber':
        return row.mobile;
      default:
        return row[key];
    }
  }
}
