<span [matTooltip]="matTooltip | translate" (click)="onClick($event)">
  <ng-container [ngSwitch]="buttonType">
    <ng-container *ngSwitchCase="'raised'">
      <button
        mat-raised-button
        [color]="color"
        [disabled]="isDisabled"
        [type]="actionType"
        (click)="onClick($event)"
      >
        <div class="icon-text-container">
          <mat-icon *ngIf="icon" [color]="iconColor">
            {{ icon }}
          </mat-icon>
          {{ text }}
        </div>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'stroked'">
      <button
        mat-stroked-button
        [color]="color"
        [disabled]="isDisabled"
        [type]="actionType"
        (click)="onClick($event)"
      >
        <div class="icon-text-container">
          <mat-icon *ngIf="icon" [color]="iconColor"> {{ icon }} </mat-icon>
          {{ text }}
        </div>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'secondary'">
      <button
        mat-stroked-button
        class="mat-secondary"
        [disabled]="isDisabled"
        [type]="actionType"
        (click)="onClick($event)"
      >
        <div class="icon-text-container">
          <mat-icon *ngIf="icon" [color]="iconColor"> {{ icon }} </mat-icon>
          {{ text }}
        </div>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'mat'">
      <button
        mat-button
        [color]="color"
        [disabled]="isDisabled"
        [type]="actionType"
        (click)="onClick($event)"
      >
        <mat-icon *ngIf="icon" [color]="iconColor"> {{ icon }} </mat-icon>
        {{ text }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'fab'">
      <button
        mat-fab
        [color]="color"
        [disabled]="isDisabled"
        [type]="actionType"
        (click)="onClick($event)"
      >
        <mat-icon *ngIf="icon" [color]="iconColor"> {{ icon }} </mat-icon>
        {{ text }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'icon-button'">
      <button
        [color]="color"
        mat-icon-button
        matSuffix
        [disabled]="isDisabled"
        [type]="actionType"
        (click)="onClick($event)"
      >
        <mat-icon *ngIf="icon" [color]="iconColor"> {{ icon }} </mat-icon>
      </button>
    </ng-container>
  </ng-container>
</span>
