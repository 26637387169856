import { Component, Input } from '@angular/core';
import { SparePartForm } from 'src/app/maintenance-user/forms/spare-part.form';

@Component({
  selector: 'app-perform-job-spare-parts-template',
  templateUrl: './perform-job-spare-parts-template.component.html',
  styleUrls: ['./perform-job-spare-parts-template.component.scss'],
})
export class PerformJobSparePartsTemplateComponent {
  @Input() columnName: string;
  @Input() set element(value: SparePartForm) {
    this.form = value;
  }
  @Input() buttonFunctions: { [key: string]: () => void };

  form: SparePartForm;
}
