/* eslint-disable no-underscore-dangle */
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Provider,
  SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { IdName } from '../../models/id-name';

export const WRAPPER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line no-use-before-define
  useExisting: forwardRef(() => UiAutocompleteComponent),
  multi: true,
};

export interface UiAutocompleteOption extends IdName {
  optionClass?: string;
  optionSuffix?: string;
}

@Component({
  selector: 'app-ui-autocomplete',
  templateUrl: './ui-autocomplete.component.html',
  styleUrls: ['./ui-autocomplete.component.scss'],
  providers: [WRAPPER_CONTROL_VALUE_ACCESSOR],
})
export class UiAutocompleteComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() description = '';
  @Input() options: UiAutocompleteOption[] | null;
  @Input() initialValue: string | undefined;
  @Input() searchTerm: UntypedFormControl;
  @Input() placeholder: string;
  @Input() floatLabel: 'always' | 'auto' = 'auto';
  @Input() showList = true;
  @Input() required = false;
  @Input() renderIcons = false;
  @Output() readonly emitSearch = new EventEmitter<boolean>();

  @Output() readonly changeHandler: EventEmitter<any> = new EventEmitter<any>();

  filteredOptions: Observable<UiAutocompleteOption[] | undefined>;

  private subscriptions = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.filteredOptions = this.searchTerm.valueChanges.pipe(
      startWith<string | UiAutocompleteOption>(''),
      debounceTime(100),
      map((value: string | UiAutocompleteOption) =>
        typeof value === 'string' ? value : value.name
      ),
      map((name: string) => (name ? this.filter(name) : this.options?.slice()))
    );

    if (this.initialValue) {
      const option = this.options?.find((o) => o.id === this.initialValue);
      if (option) {
        this.setValue(option);
      } else {
        throw Error(`no option found for ${this.initialValue}`);
      }
    }

    this.subscriptions.add(
      this.searchTerm.valueChanges.subscribe((value) => {
        this.changeHandler.emit(value);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.searchTerm.setValue(this.searchTerm.value);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  search(): void {
    this.emitSearch.emit(true);
  }

  onSelect(event: MatOptionSelectionChange, option: UiAutocompleteOption) {
    // onSelectionChange fires both for the new selected, and the one being unselected, in that order
    if (event.source.selected) {
      this.setValue(option);
    }
  }

  onChange(event: any): void {
    this.changeHandler.emit(event);
  }

  setValue(option: UiAutocompleteOption) {
    this.searchTerm.setValue(option);
  }

  nameof(option: UiAutocompleteOption): string {
    return option.name || `${option}` || '';
  }

  private filter(value: string): UiAutocompleteOption[] | undefined {
    const filterValue = value.toLocaleLowerCase();
    return this.options?.filter((option) => option.name.toLocaleLowerCase().includes(filterValue));
  }
}
