/* eslint-disable no-unused-expressions */
import { Injectable } from '@angular/core';
import { IAttachment } from '../models/attachments.model';

const FILE_TYPES_MAP: { [key: string]: string } = {
  'text/html': 'html',
  'application/msword': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/xml': 'xml',
  'text/csv': 'csv',
  'image/png': 'png',
  'image/jpeg': 'jpeg',
};

const DENOMINATOR = 1024;

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  static bytesToMegabytes(bytes: number): number {
    return Number((bytes / DENOMINATOR / DENOMINATOR).toFixed(2));
  }

  static megabytesToBytes(bytes: number): number {
    return Number((bytes * DENOMINATOR * DENOMINATOR).toFixed(2));
  }

  static getAttachmentType(type: string): string {
    return FILE_TYPES_MAP[type] || 'other';
  }

  static mapAttachments(attachments: IAttachment[]): IAttachment[] {
    return attachments.map((attachment) => {
      return {
        id: attachment.id,
        name: attachment.name,
        link: attachment.link,
        isNew: false,
        categories: attachment.categories,
        fileSize: AttachmentService.bytesToMegabytes(attachment.fileSize),
        type: attachment.name.split('.').pop() as string,
      };
    });
  }
}
