<ng-container [ngSwitch]="specificTemplateName">
  <app-component-history-template
    *ngSwitchCase="templates.ComponentHistory"
    [columnName]="columnName"
    [element]="element"
  ></app-component-history-template>
  <app-maintenance-template
    *ngSwitchCase="templates.MaintenanceJobs"
    [columnName]="columnName"
    [element]="element"
    [buttonFunctions]="buttonFunctions"
  ></app-maintenance-template
  ><app-perform-job-spare-parts-template
    *ngSwitchCase="templates.PerformJobSpareParts"
    [columnName]="columnName"
    [element]="element"
    [buttonFunctions]="buttonFunctions"
  ></app-perform-job-spare-parts-template>
</ng-container>
