<mat-form-field
  *ngIf="{
    selectedDepartment: selectedDepartment$ | async,
    departmentList: departments$ | async
  } as departments"
  appearance="fill"
>
  <mat-select
    [ngModel]="departments.selectedDepartment?.id"
    [hideSingleSelectionIndicator]="true"
    panelClass="department-dropdown-select"
    (selectionChange)="selectDepartment($event)"
  >
    <mat-select-trigger>
      <img
        width="50"
        height="50"
        [src]="
          departments.selectedDepartment?.id === allDepsToken
            ? allDepsIconPath
            : (departments.selectedDepartment?.logo | appendDomain) || departmentPlaceHolderIconPath
        "
        style="border-radius: 5px"
        alt="vessel picture"
      />
      <span class="vessel-name"
        >{{ departments.selectedDepartment?.name || 'allDepartments' | translate }}
      </span>
    </mat-select-trigger>
    <mat-option
      *ngFor="let option of departments.departmentList; trackBy: trackBy"
      [value]="option.id"
    >
      <img
        width="50"
        height="50"
        class="rounded-border"
        [src]="(option.logo | appendDomain) || departmentPlaceHolderIconPath"
        alt="vessel picture"
      />
      <div class="department-option-container">
        <span class="left-details-col">
          <h3>{{ option.name }}</h3>
          <span class="vessel-details">
            <div class="vessel-headings-row">
              <span>LOA</span>
              <span>DW </span>
              <span>GRP</span>
            </div>
            <!-- TODO awaiting back-end response, as we're missing these props -->
            <!-- <div class="vessel-info-row">
              <span>{{ option.loa | uppercase }}</span>
              <span>{{ option.dw | uppercase }}</span>
              <span>{{ option.grp | uppercase }}</span>
            </div> -->
            <!-- TODO awaiting back-end response, as we're missing these props -->
          </span>
        </span>
        <span class="right-details-col">
          <span *ngIf="option.id === departments.selectedDepartment?.id" class="selected-marker">{{
            'selected' | translate | uppercase
          }}</span>
        </span>
      </div>
    </mat-option>
    <mat-option
      *ngIf="departments.departmentList && departments.departmentList.length > 1"
      [value]="allDepsToken"
      class="all-departments-opt"
    >
      <img
        width="50"
        height="50"
        class="rounded-border"
        [src]="allDepsIconPath"
        alt="vessel picture"
      />
      <div class="department-option-container">
        <span class="left-details-col">
          <h3>{{ 'allDepartments' | translate }}</h3>
        </span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
