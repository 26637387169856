import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentEditorModule } from '@txtextcontrol/tx-ng-document-editor';
import { DocumentViewerModule } from '@txtextcontrol/tx-ng-document-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppLoaderDirective } from '../directives/app-loader.directive';
import { TextControlEditorComponent } from './text-control-editor/text-control-editor.component';
import { TextControlViewerComponent } from './text-control-viewer/text-control-viewer.component';
import { ResizeDirective } from '../directives/resize.directive';

@NgModule({
  declarations: [TextControlEditorComponent, TextControlViewerComponent],
  imports: [
    AppLoaderDirective,
    CommonModule,
    DocumentEditorModule,
    DocumentViewerModule,
    MatIconModule,
    MatTooltipModule,
    ResizeDirective,
    TranslateModule,
  ],
  exports: [
    DocumentEditorModule,
    DocumentViewerModule,
    TextControlEditorComponent,
    TextControlViewerComponent,
  ],
})
export class TextControlModule {}
