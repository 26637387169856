<ng-container [ngSwitch]="specificTemplateName">
  <ng-container *ngSwitchCase="tableNamesEnum.NextOfKin">
    <app-users-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
    ></app-users-template>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-profile-competence-template
      [columnName]="columnName"
      [element]="element"
      [urlForRouting]="urlForRouting"
    ></app-profile-competence-template>
  </ng-container>
</ng-container>
