import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getTenantId } from 'src/app/services/store-snapshot.service';
import { IPrivilegeModel } from '../models/privilege.model';
import { IPrivilegeGroupResultModel } from '../models/privilege-group.model';
import { ModuleNumbers } from '../enums/module-numbers.enum';

@Injectable({
  providedIn: 'root',
})
export class PriviligeApiService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) {}

  // getAllPrivileges(): Observable<{ name: string }[]> {
  //   const url = `${environment.apiUrl}/api/privileges/flat`;
  //   return this.http.get<{ name: string }[]>(url, this.httpOptions);
  // }

  getPrivileges(
    departmentIds: Array<string>,
    departmentGroupIds: Array<string>
  ): Observable<IPrivilegeModel[]> {
    this.addDepartmentsAndGroupsToRequest(departmentIds, departmentGroupIds);

    const url = `${this.url}/Flat`;
    return this.http.get<IPrivilegeModel[]>(url, this.httpOptions);
  }

  getAllGroupedPrivileges(): Observable<IPrivilegeGroupResultModel> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/privileges/groupedbymodule`;
    return this.http.get<IPrivilegeGroupResultModel>(url, this.httpOptions);
  }

  getPrivilegesForModule(
    module: ModuleNumbers,
    departmentIds: Array<string>,
    departmentGroupIds: Array<string>
  ): Observable<string[]> {
    this.addDepartmentsAndGroupsToRequest(departmentIds, departmentGroupIds);

    const url = `${this.url}/flat/${module}`;
    return this.http.get<string[]>(url, this.httpOptions);
  }

  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/privileges`;
  }

  private addDepartmentsAndGroupsToRequest(
    departmentIds: Array<string>,
    departmentGroupIds: Array<string>
  ) {
    this.httpOptions.params = this.httpOptions.params
      .delete('departments')
      .delete('departmentGroups');

    departmentIds.forEach((department: string) => {
      this.httpOptions.params = this.httpOptions.params.append('departments', department);
    });

    departmentGroupIds.forEach((departmentGroup: string) => {
      this.httpOptions.params = this.httpOptions.params.append('departmentGroups', departmentGroup);
    });
  }
}
