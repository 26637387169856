import { IUserAccess } from 'src/app/shared/user-view/models/tenant-user-access.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { TenantUserAccessState } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { Observable } from 'rxjs';
import { ITenantUserAccess } from '../../shared/user-view/models/tenant-user-access.model';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  currentTenant$: Observable<ITenantUserAccess> = this.store.select(
    TenantUserAccessState.currentTenant
  );
  userAccess$: Observable<IUserAccess> = this.store.select(TenantUserAccessState.userAccess);

  access: string[];
  currentSelectedTenantId: string;
  isProductOwner: boolean;

  constructor(private store: Store) {
    this.currentTenant$.subscribe((tenant) => {
      if (tenant) {
        this.access = tenant.privileges;
        this.currentSelectedTenantId = tenant.id;
      }
    });
    this.userAccess$.subscribe((userAccess) => {
      if (userAccess) {
        this.isProductOwner = userAccess.isProductOwner;
      }
    });
  }

  getCurrentSelectedTenantId(): string {
    return this.currentSelectedTenantId;
  }

  hasAccess(role: string): boolean {
    // divide string in 3, role is 2 , rights is 3
    // get userAccess from store. // to do write access logic.
    if (this.isProductOwner) {
      // all access!!
      return true;
    }

    // if (this.access) {
    //   const found = this.access.filter((x) => {
    //     return x.includes(role);
    //   });
    //   return found.length > 0;
    // }
    return true;
  }
}
