<div class="main-paginator-container" *ngIf="currentPageDetails">
  <span
    class="paginator-item-count"
    [ngClass]="{
      hidden: !showPageSizeSelector
    }"
  >
    <mat-form-field appearance="fill">
      <mat-label>{{ 'pageSize' | translate }}</mat-label>
      <mat-select
        panelClass="app-ui-select"
        [hideSingleSelectionIndicator]="true"
        [(value)]="currentPageDetails.pageSize"
        (selectionChange)="pageSizeChangeEvent($event)"
      >
        <mat-option [value]="option" *ngFor="let option of pageSizeOptions">{{
          option
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </span>
  <div
    class="paginator-right-details"
    [ngClass]="{
      'page-navigator': !showPageSizeSelector
    }"
  >
    <span class="page-index-display"
      >{{ 'page' | translate }} {{ currentPageDetails.pageIndex + 1 }} {{ 'of' | translate }}
      {{ totalPages }}</span
    >
    <app-ui-button
      buttonType="icon-button"
      icon="navigate_before"
      [disabled]="!hasPreviousPage"
      (click)="previousPage()"
    ></app-ui-button>
    <app-ui-button
      buttonType="icon-button"
      icon="navigate_next"
      [disabled]="!hasNextPage"
      (click)="nextPage()"
    ></app-ui-button>
  </div>
</div>
