import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-trips-template',
  templateUrl: './trips-template.component.html',
  styleUrls: ['./trips-template.component.scss'],
})
export class TripsTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  dateFormat = 'yyyy-MM-dd';
  dateTimeFormat = 'yyyy-MM-dd HH:mm';
}
