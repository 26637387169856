/* eslint-disable max-classes-per-file */
import { HashtagModel } from '../models/hashtag.model';

export class LoadHashtags {
  static readonly type = '[Hashtag] Load Hashtags';
}

export class AddNewHashTag {
  static readonly type = '[Hashtag] Create a New Hashtag';
  constructor(public hashtag: HashtagModel) {}
}
