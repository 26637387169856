import { InjectionToken, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertViewComponent } from './components/alert-view/alert-view.component';
import { AlertComponent } from './alert.component';
import { DepartmentOptionsResolver } from '../department/department-options.resolver';
import { AlertAddEditComponent } from './components/alert-add-edit/alert-add-edit.component';
import { UnsavedDataGuard } from '../shared/guards/unsaved-data.guard';

const MODULE_ROLE = new InjectionToken<string>('module_alert');

const alertsRoutes: Routes = [
  {
    path: '',
    component: AlertComponent,
    children: [
      {
        path: '',
        component: AlertViewComponent,
      },
      {
        path: ':id',
        component: AlertAddEditComponent,
        canDeactivate: [UnsavedDataGuard],
        resolve: {
          departmentOptions: DepartmentOptionsResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(alertsRoutes)],
  exports: [RouterModule],
  providers: [{ provide: MODULE_ROLE, useValue: 'ALERT' }],
})
export class AlertRoutingModule {}
