import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { getTenantId, getDepartmentId } from 'src/app/services/store-snapshot.service';
import { FormDataService } from 'src/app/shared/attachments/service/form-data.service';
import { IdName } from 'src/app/shared/models/id-name';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { environment } from 'src/environments/environment';
import { IPurchaserResponse } from 'src/app/order-admin/models/purchasers/purchaser.model';
import { OrderStatus } from '../enums/order-status.enum';
import { IOrderResponse, IOrderRequest } from '../models/order.model';
import { IReceiveOrderLine } from '../models/receive-order-line.model';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  headers = { 'content-type': 'application/json' };

  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/orders`;
  }

  constructor(private http: HttpClient) {}

  getOrders(
    orderStage: OrderStatus,
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IOrderResponse[]>> {
    let url = `${this.url}?OrderStage=${orderStage}`;
    if (currentFilter) {
      url = `${url}&Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    if (currentFilter?.orderBy && currentFilter.ascDesc) {
      url = `${url}&OrderBy[${currentFilter.orderBy}]=${currentFilter.ascDesc}`;
    }
    return this.http.get<ApiResponse<IOrderResponse[]>>(url);
  }

  getAllOrders(): Observable<IdName[]> {
    const url = `${this.url}`;
    return this.http.get<IdName[]>(url);
  }

  getAllOrdersForDepartment(): Observable<IdName[]> {
    const url = `${
      environment.apiUrl
    }/api/${getTenantId()}/${getDepartmentId()}/departmentScope/orders`;
    return this.http.get<IdName[]>(url);
  }

  getOrderById(orderId: string): Observable<IOrderResponse> {
    const url = `${this.url}/${orderId}`;
    return this.http.get<IOrderResponse>(url);
  }

  // Hack alert - for some reason couldn't get two purchasers at once from PurchaserState
  // decided to get it from here since it's used for order purposes
  getDeliveryToById(id: string): Observable<IPurchaserResponse> {
    const url = `${environment.apiUrl}/api/${getTenantId()}/purchasers/${id}`;
    return this.http.get<IPurchaserResponse>(url);
  }

  createOrder(order: IOrderRequest): Observable<IOrderResponse> {
    const formData = FormDataService.mapToFormData(order);
    return this.http.post<IOrderResponse>(this.url, formData);
  }

  updateOrder(order: IOrderRequest): Observable<IOrderResponse> {
    const formData = FormDataService.mapToFormData(order);
    const url = `${this.url}/${order.id}`;
    return this.http.put<IOrderResponse>(url, formData);
  }

  deleteOrder(id: string) {
    const url = `${this.url}/${id}`;
    return this.http.delete<IOrderResponse>(url);
  }

  printOrderPdf(orderId: string): Observable<Blob> {
    const url = `${this.url}/${orderId}/pdf`;
    return this.http.get(url, { responseType: 'blob' });
  }

  printOrderExcel(orderId: string): Observable<Blob> {
    const url = `${this.url}/${orderId}/excel`;
    return this.http.get(url, { responseType: 'blob' });
  }

  // STAGES endpoints

  sendOrderToDraft(orderId: string): Observable<any> {
    const url = `${this.url}/${orderId}/stages/draft`;
    return this.http.patch<any>(url, { headers: this.headers });
  }

  sendOrderForApproval(orderId: string): Observable<any> {
    const url = `${this.url}/${orderId}/stages/forApprove`;
    return this.http.patch<any>(url, { headers: this.headers });
  }

  approveOrder(orderId: string): Observable<any> {
    const url = `${this.url}/${orderId}/stages/approved`;
    return this.http.patch<any>(url, { headers: this.headers });
  }

  sendOrderToSupplier(orderId: string, sendEmailManually: boolean): Observable<any> {
    const url = `${this.url}/${orderId}/stages/sentToSupplier`;
    return this.http.patch<any>(url, { sendEmailManually }, { headers: this.headers });
  }

  receiveOrder(orderId: string, receivedOrderLines: IReceiveOrderLine[]): Observable<any> {
    const url = `${this.url}/${orderId}/stages/receive`;
    return this.http.patch<any>(url, { receivedOrderLines }, { headers: this.headers });
  }

  cancelOrder(orderId: string): Observable<any> {
    const url = `${this.url}/${orderId}/stages/cancelled`;
    return this.http.patch<any>(url, { headers: this.headers });
  }

  reopenOrder(orderId: string): Observable<any> {
    const url = `${this.url}/${orderId}/stages/reopen`;
    return this.http.patch<any>(url, { headers: this.headers });
  }
}
