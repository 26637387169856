<form [formGroup]="form" *appLoader="!form">
  <app-dialog-container
    title="{{ title | translate }}"
    [isValid]="this.form.valid"
    (confirmButtonHandler)="save()"
    [form]="form"
  >
    <div class="title">{{ 'useMedicineDescription' | translate }}</div>
    <div class="row-container">
      <app-ui-input
        label="{{ 'currentStock' | translate }}"
        [control]="form.currentStock"
        class="disabled"
        [tabIndex]="-1"
      ></app-ui-input>
      <app-ui-input
        label="{{ 'amount' | translate }}"
        [control]="form.amount"
        [required]="true"
        type="number"
        min="0"
      ></app-ui-input>
    </div>
    <app-ui-text-area
      [label]="'reasonForUse' | translate"
      [control]="form.reason"
      [required]="true"
    ></app-ui-text-area>
  </app-dialog-container>
</form>
