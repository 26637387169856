/* eslint-disable no-magic-numbers */
export enum OrderStatus {
  Draft = 0,
  ForApproval = 1,
  Approved = 2,
  SentToSupplier = 3,
  ReceivedPartially = 4,
  Received = 5,
  Cancelled = 6,
  Closed = 7,
}

export const ORDER_STATUS_LABELS: any = {
  [OrderStatus.Draft]: 'draft',
  [OrderStatus.ForApproval]: 'forApproval',
  [OrderStatus.Approved]: 'approved',
  [OrderStatus.SentToSupplier]: 'sentToSupplier',
  [OrderStatus.ReceivedPartially]: 'receivedPartially',
  [OrderStatus.Received]: 'received',
  [OrderStatus.Cancelled]: 'cancelled',
  [OrderStatus.Closed]: 'closed',
};
