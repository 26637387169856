import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-positions-cell',
  templateUrl: './positions-cell.component.html',
  styleUrls: ['./positions-cell.component.css'],
})
export class PositionsCellComponent {
  @Input() element: any;
}
