import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isStringArray',
  pure: true,
  standalone: true,
})
export class IsStringArrayPipe implements PipeTransform {
  transform(element: any): boolean {
    if (Array.isArray(element)) {
      if (!element.some((item) => typeof item !== 'string')) {
        return true;
      }
    }
    return false;
  }
}
