import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { insertItem, patch, updateItem } from '@ngxs/store/operators';
import { map, switchMap, tap } from 'rxjs/operators';
import { AdminTenantRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { ITenant, ITenantLog } from '../models/tenant.model';
import { TenantApiService } from '../service/tenant-api.service';
import { IdName } from '../../shared/models/id-name';
import {
  CreateTenant,
  DeactivateTenant,
  DeleteTenant,
  LoadAllTenants,
  LoadTenantById,
  LoadTenantLog,
  LoadTenants,
  LoadThirdPartyIntegrationTypes,
  LoadThirdPartyIntegrations,
  UpdateTenant,
  UpdateThirdPartyIntegrations,
} from './tenant.action';
import { WebHookApiRequest, WebHookModel } from '../models/webhook.model';

export interface TenantStateModel {
  tenantLog: ITenantLog | undefined;
  loaded: boolean;
  tenants: ITenant[];
  tenantTotalPages: number;
  allTenants: ITenant[];
  thirdPartyIntegrations: WebHookModel[];
  availableEventTypes: string[];
}

const TENANT_STATE_TOKEN = new StateToken<TenantStateModel>('tenant');

@State<TenantStateModel>({
  name: TENANT_STATE_TOKEN,
  defaults: {
    loaded: false,
    tenants: [],
    tenantTotalPages: 0,
    allTenants: [],
    tenantLog: undefined,
    thirdPartyIntegrations: [],
    availableEventTypes: [],
  },
})
@Injectable()
export class TenantState {
  constructor(private service: TenantApiService, private store: Store) {}

  @Action(LoadTenants, { cancelUncompleted: true })
  loadTenants({ getState, setState }: StateContext<TenantStateModel>, { filter }: LoadTenants) {
    return this.service.getPagedTenants(filter).pipe(
      map((response: ApiResponse<ITenant[]>) => {
        return [
          response.data?.map((tenant) => {
            tenant.moduleList = tenant.modules as unknown as string[];
            return tenant;
          }),
          response.totalPages,
        ] as [ITenant[], number];
      }),
      tap(([tenants, totalPages]) => {
        const state = getState();
        setState({
          ...state,
          loaded: true,
          tenants,
          tenantTotalPages: totalPages,
        });
      })
    );
  }

  @Action(LoadAllTenants, { cancelUncompleted: true })
  loadAllTenants({ getState, setState }: StateContext<TenantStateModel>) {
    return this.service.getAllTenants().pipe(
      map((tenants: ITenant[]) => {
        return [
          tenants?.map((tenant) => {
            tenant.moduleList = tenant.modules as unknown as string[];
            return tenant;
          }),
        ] as [ITenant[]];
      }),
      tap(([allTenants]) => {
        const state = getState();
        setState({
          ...state,
          loaded: true,
          allTenants,
        });
      })
    );
  }

  @Action(LoadTenantById, { cancelUncompleted: true })
  loadTenantById({ getState, setState }: StateContext<TenantStateModel>, { id }: LoadTenantById) {
    const tenantsFromState = getState().tenants;
    if (tenantsFromState.find((tenant) => tenant.id === id)) {
      return this.service.getTenantById(id).pipe(
        tap((tenant) => {
          setState(
            patch<TenantStateModel>({
              tenants: updateItem<ITenant>(
                (updatedTenant: ITenant | undefined) => updatedTenant?.id === tenant.id,
                tenant
              ),
            })
          );
        })
      );
    }

    return this.service.getTenantById(id).pipe(
      tap((tenant: ITenant) => {
        setState(
          patch({
            tenants: insertItem<ITenant>(tenant),
          })
        );
      })
    );
  }

  @Action(CreateTenant, { cancelUncompleted: true })
  @OnSuccess({ url: AdminTenantRoutes.Main, message: 'tenantCreated' })
  createTenant(ctx: StateContext<TenantStateModel>, { tenant }: CreateTenant) {
    return this.service
      .createTenant(tenant)
      .pipe(switchMap(() => this.store.dispatch(new LoadTenants())));
  }

  @Action(UpdateTenant, { cancelUncompleted: true })
  @OnSuccess({ url: AdminTenantRoutes.Main, message: 'tenantUpdated' })
  updateTenant(ctx: StateContext<TenantStateModel>, { tenant }: UpdateTenant) {
    return this.service
      .updateTenant(tenant)
      .pipe(switchMap(() => this.store.dispatch(new LoadTenants())));
  }

  @Action(DeleteTenant, { cancelUncompleted: true })
  @OnSuccess({ url: AdminTenantRoutes.Main, message: 'tenantDeleted' })
  deleteTenant(ctx: StateContext<TenantStateModel>, { tenantId }: DeleteTenant) {
    return this.service
      .deleteTenant(tenantId)
      .pipe(switchMap(() => this.store.dispatch(new LoadTenants())));
  }

  @Action(DeactivateTenant, { cancelUncompleted: true })
  @OnSuccess({ url: AdminTenantRoutes.Main, message: 'tenantDeactivated' })
  deactivateTenant(ctx: StateContext<TenantStateModel>, { tenantId }: DeactivateTenant) {
    return this.service
      .deactivateTenant(tenantId)
      .pipe(switchMap(() => this.store.dispatch(new LoadTenants())));
  }

  @Action(LoadTenantLog, { cancelUncompleted: true })
  loadTenantLog({ setState }: StateContext<TenantStateModel>, { tenantId }: LoadTenantLog) {
    return this.service.getTenantLog(tenantId).pipe(
      tap((tenantLog) => {
        if (tenantLog) {
          setState(
            patch({
              tenantLog,
            })
          );
        }
      })
    );
  }

  @Action(LoadThirdPartyIntegrationTypes, { cancelUncompleted: true })
  loadThirdPartyIntegrationTypes(
    { setState }: StateContext<TenantStateModel>,
    { tenantId }: LoadThirdPartyIntegrationTypes
  ) {
    return this.service.getAvailableEvents(tenantId).pipe(
      tap((eventTypes: string[]) => {
        if (eventTypes) {
          setState(
            patch({
              availableEventTypes: eventTypes,
            })
          );
        }
      })
    );
  }

  @Action(LoadThirdPartyIntegrations, { cancelUncompleted: true })
  loadThirdPartyIntegrations(
    { setState }: StateContext<TenantStateModel>,
    { tenantId }: LoadThirdPartyIntegrations
  ) {
    return this.service.getAllWebhooksPerTenant(tenantId).pipe(
      tap((webHookRequest: WebHookApiRequest) => {
        if (webHookRequest.subscriptionDetails) {
          setState(
            patch({
              thirdPartyIntegrations: webHookRequest.subscriptionDetails,
            })
          );
        }
      })
    );
  }

  @Action(UpdateThirdPartyIntegrations, { cancelUncompleted: true })
  updateThirdPartyIntegrations(
    { setState }: StateContext<TenantStateModel>,
    { webhooks, tenantId }: UpdateThirdPartyIntegrations
  ) {
    return this.service.updateAllWebhooksPerTenant(webhooks, tenantId).pipe(
      tap(() => {
        setState(
          patch({
            thirdPartyIntegrations: webhooks.subscriptionDetails,
          })
        );
      })
    );
  }

  @Selector()
  static tenantsLoaded(state: TenantStateModel) {
    return state.loaded;
  }

  @Selector()
  static getTenantTotalPages(state: TenantStateModel) {
    return state.tenantTotalPages;
  }

  @Selector()
  static tenants(state: TenantStateModel) {
    return state.tenants;
  }

  @Selector()
  static tenantsList(state: TenantStateModel) {
    return state.tenants.map((tenant) => ({ id: tenant.id, name: tenant.name } as IdName));
  }

  @Selector()
  static allTenants(state: TenantStateModel) {
    return state.allTenants;
  }

  @Selector()
  static getTenantById(state: any) {
    return (id: string) => {
      return state.tenants.find((tenant: ITenant) => tenant.id === id);
    };
  }

  @Selector()
  static getCurrentTenantLog(state: TenantStateModel): ITenantLog | undefined {
    return state.tenantLog;
  }

  @Selector()
  static allThirdPartyIntegrationsPerTenant(state: TenantStateModel): WebHookModel[] {
    return state.thirdPartyIntegrations;
  }

  @Selector()
  static allAvailableEventTypes(state: TenantStateModel): string[] {
    return state.availableEventTypes;
  }
}
