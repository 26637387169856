import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { IAttachment } from '../attachments/models/attachments.model';

export class StockForm extends FormGroup {
  readonly id = this.get('id') as FormControl<string>;
  readonly name = this.get('name') as FormControl<string>;
  readonly amount = this.get('amount') as FormControl<number>;
  readonly reason = this.get('reason') as FormControl<string>;
  readonly adjustmentAttachments = this.get('adjustmentAttachments') as FormControl<IAttachment[]>;
  readonly currentStock = this.get('currentStock') as FormControl<number>;
  readonly newStock = this.get('newStock') as FormControl<number>;

  constructor(
    id: string,
    currentStock: number,
    name?: string,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group({
        id: [id ?? ''],
        name: [name ?? ''],
        amount: ['', [Validators.required, Validators.min(0)]],
        reason: ['', Validators.required],
        adjustmentAttachments: [[]],
        currentStock: [currentStock ?? ''],
        newStock: [currentStock ?? ''],
      }).controls
    );
  }
}
