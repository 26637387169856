/* eslint-disable consistent-return */
import { Injectable } from '@angular/core';

/*
This service provide a functionality to Add/Update/Delete an item
from a deeply nested (infinitely nested) array of items.

There are 2 requiremenets that needs to be met for it to work properly:
1. Every parent needs to have a `children` property as an array of same type (even if it's empty)
2. Every item needs to have fields `id` and `parentId`

It simply takes necessary props to operate, and returns updated items
so you can replace the items inside the state.
*/
@Injectable({
  providedIn: 'root',
})
export class CreateUpdateDeleteRecursiveArrayService {
  constructor() {}

  addNewItemToParentChildrenElements(items: any[], parentId: string, newItem: any): any[] {
    // first of all, get the parent item
    const parent = CreateUpdateDeleteRecursiveArrayService.getItemThatMatchParentId(
      items,
      parentId
    );
    // copy the parent item without a reference
    const parentExtensible = JSON.parse(JSON.stringify(parent));
    // push a new children item to parent children array
    parentExtensible.children.push(newItem);
    // update parent as a whole inside array of all items
    const updatedItems = this.updateAllItemsWithNewParent(items, parentExtensible);
    return updatedItems;
  }

  updateItemInParentChildrenElements(items: any[], parentId: string, updatedItem: any): any[] {
    // first of all, get the parent item
    const parent = CreateUpdateDeleteRecursiveArrayService.getItemThatMatchParentId(
      items,
      parentId
    );
    // copy the parent item without a reference
    const parentExtensible = JSON.parse(JSON.stringify(parent));
    // push updated children item to parent children array
    const itemToUpdate = parentExtensible.children.find((item: any) => item.id === updatedItem.id);
    const indexOfItemToUpdate = parentExtensible.children.indexOf(itemToUpdate);
    parentExtensible.children[indexOfItemToUpdate] = updatedItem;
    // update parent as a whole inside array of all items
    const updatedItems = this.updateAllItemsWithNewParent(items, parentExtensible);
    return updatedItems;
  }

  removeItemFromNestedItems(items: any[], id: string): any[] {
    return this.removeItemRecursiveById(items, id);
  }

  getNodeWithChildrenOneLevelDeep(items: any[], nodeId: string): any[] {
    return CreateUpdateDeleteRecursiveArrayService.getItemThatMatchParentId(items, nodeId);
  }

  getChildInParent(items: any[], parentId: string, childId: string): any {
    const parent = CreateUpdateDeleteRecursiveArrayService.getItemThatMatchParentId(
      items,
      parentId
    );
    const child = parent.children.find((item: any) => item.id === childId);
    return JSON.parse(JSON.stringify(child));
  }

  static getItemThatMatchParentId(items: any[], parentId: string): any {
    let itemFound: any;
    items.forEach((item: any) => {
      if (item.id === parentId) {
        itemFound = item;
      } else if (item.children?.length > 0) {
        const result = CreateUpdateDeleteRecursiveArrayService.getItemThatMatchParentId(
          item.children,
          parentId
        );
        if (result) itemFound = result;
      }
    });
    return itemFound;
  }

  static getFlatList(items: any[]): any[] {
    let list: any[] = [];
    if (items) {
      items.forEach((item: any) => {
        list.push(item);
        if (item.children && item.children.length > 0) {
          list = list.concat(CreateUpdateDeleteRecursiveArrayService.getFlatList(item.children));
        }
      });
    }
    return list;
  }

  private updateAllItemsWithNewParent(items: any[], updatedParent: any): any[] {
    return items.map((item: any) => {
      if (item.id === updatedParent.id) {
        return updatedParent;
      }
      if (item.children?.length > 0) {
        // JSON parse and stringify used to copy without a reference
        const parentItem = JSON.parse(JSON.stringify(item));
        parentItem.children = this.updateAllItemsWithNewParent(item.children, updatedParent);
        return parentItem;
      }
      return item;
    });
  }

  private removeItemRecursiveById(items: any[], id: string): any[] {
    if (items.find((item: any) => item.id === id)) {
      return items.filter((item: any) => item.id !== id);
    }
    return items.map((item: any) => {
      if (item.children?.length > 0) {
        // JSON parse and stringify used to copy without a reference
        const parentItem = JSON.parse(JSON.stringify(item));
        parentItem.children = this.removeItemRecursiveById(item.children, id);
        return parentItem;
      }
      return item;
    });
  }
}
