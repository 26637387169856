import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/models/api-response';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from 'src/app/shared/models/id-name';
import { AdminProductOwnerRoutes } from '../../core/enums/routes.enum';
import { IProductOwner } from '../models/product-owner.model';
import { ProductOwnerService } from '../services/product-owner-api.service';
import {
  CreateProductOwner,
  DeleteProductOwner,
  LoadAllProductOwners,
  LoadProductOwners,
  UpdateProductOwner,
} from './product-owner.actions';

export interface ProductOwnerStateModel {
  loaded: boolean;
  productOwners: IProductOwner[] | undefined;
  allProductOwners: IdName[];
  productOwnerTotalPages: number;
}

const PRODUCT_OWNER_STATE_TOKEN = new StateToken<ProductOwnerStateModel>('productOwner');

@State<ProductOwnerStateModel>({
  name: PRODUCT_OWNER_STATE_TOKEN,
  defaults: {
    loaded: false,
    productOwners: undefined,
    allProductOwners: [],
    productOwnerTotalPages: 0,
  },
})
@Injectable()
export class ProductOwnerState {
  constructor(private service: ProductOwnerService) {}

  @Action(LoadProductOwners, { cancelUncompleted: true })
  LoadProductOwners(
    { getState, setState }: StateContext<ProductOwnerStateModel>,
    { filter }: LoadProductOwners
  ) {
    return this.service.getProductOwners(filter).pipe(
      tap((response: ApiResponse<IProductOwner[]>) => {
        const state = getState();
        setState({
          ...state,
          loaded: true,
          productOwners: response.data,
          productOwnerTotalPages: response.totalPages,
        });
      })
    );
  }

  @Action(LoadAllProductOwners, { cancelUncompleted: true })
  loadAllProductOwners(ctx: StateContext<ProductOwnerStateModel>) {
    return this.service.getAllProductOwners().pipe(
      tap((allProductOwners: IdName[]) => {
        ctx.setState(patch({ allProductOwners }));
      })
    );
  }

  @Action(CreateProductOwner, { cancelUncompleted: true })
  @OnSuccess({ url: AdminProductOwnerRoutes.Main })
  createProductowner(
    ctx: StateContext<ProductOwnerStateModel>,
    { productOwner }: CreateProductOwner
  ) {
    return this.service.createProductOwner(productOwner).pipe(
      tap((response: IProductOwner) => {
        ctx.setState(
          patch({
            productOwners: append<IProductOwner>([response]),
          })
        );
      })
    );
  }

  @Action(UpdateProductOwner, { cancelUncompleted: true })
  @OnSuccess({ url: AdminProductOwnerRoutes.Main })
  updateProductowner(
    ctx: StateContext<ProductOwnerStateModel>,
    { productOwner }: UpdateProductOwner
  ) {
    return this.service.updateProductOwner(productOwner).pipe(
      tap((response: IProductOwner) => {
        ctx.setState(
          patch({
            productOwners: updateItem<IProductOwner>(
              (productOwnerToUpdate: IProductOwner | undefined) =>
                productOwnerToUpdate?.id === response.id,
              response
            ),
          })
        );
      })
    );
  }

  @Action(DeleteProductOwner, { cancelUncompleted: true })
  @OnSuccess({ url: AdminProductOwnerRoutes.Main })
  deleteProductowner(ctx: StateContext<ProductOwnerStateModel>, { id }: DeleteProductOwner) {
    return this.service.deleteProductOwner(id).pipe(
      tap((response: IProductOwner) => {
        ctx.setState(
          patch({
            productOwners: removeItem<IProductOwner>(
              (productOwner: IProductOwner | undefined) => productOwner?.id === response.id
            ),
          })
        );
      })
    );
  }

  @Selector()
  static productOwnersLoaded(state: ProductOwnerStateModel) {
    return state.loaded;
  }

  @Selector()
  static getProductOwnersTotalPages(state: ProductOwnerStateModel): number {
    return state.productOwnerTotalPages;
  }

  @Selector()
  static getProductOwners(state: ProductOwnerStateModel) {
    return state.productOwners;
  }

  @Selector()
  static getAllProductOwners(state: ProductOwnerStateModel): IdName[] {
    return state.allProductOwners;
  }

  @Selector()
  static getProductOwnerById(state: any) {
    return (id: string) => {
      return state.productOwners?.find((productOwner: IProductOwner) => productOwner.id === id);
    };
  }
}
