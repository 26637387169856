/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IRunTimeCounterUpdateRequest, IRunTimeRequest } from '../models/run-time.model';

export class LoadRunTimesForDepartment {
  static readonly type = '[Run Time] Load Run Times via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllRunTimesForDepartment {
  static readonly type = '[Run Time] Load All Run Times via Service';
  constructor() {}
}

export class LoadRunTimeById {
  static readonly type = '[Run Time] Load a single run time';
  constructor(public id: string) {}
}

export class CreateRunTime {
  static readonly type = '[Run Time] Create run time';
  constructor(public runTime: IRunTimeRequest) {}
}

export class UpdateRunTime {
  static readonly type = '[Run Time] Update run time';
  constructor(public runTime: IRunTimeRequest) {}
}

export class UpdateRunTimeCounter {
  static readonly type = '[Run Time] Update run time counter';
  constructor(public runTime: IRunTimeCounterUpdateRequest) {}
}

export class UpdateOffset {
  static readonly type = '[Run Time] Update offset';
  constructor(public runTime: IRunTimeCounterUpdateRequest) {}
}

export class DeleteRunTime {
  static readonly type = '[Run Time] Delete run time';
  constructor(public id: string) {}
}

export class LoadRunTimeLog {
  static readonly type = '[Run Time] Load Runtime Log';
  constructor(public id: string) {}
}
