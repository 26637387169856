/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IProductRequest } from '../models/product.model';
import { IUseProduct } from '../models/use-product.model';

export class LoadProducts {
  static readonly type = '[Products] Load Products via Service';
  constructor(public filter?: MainTableFilterModel, public menuId?: string) {}
}

export class LoadAllProducts {
  static readonly type = '[Products] Load All Products via Service';
  constructor(
    public excludeAttachedToMaintenanceJobId?: string,
    public excludeAttachedToComponentId?: string
  ) {}
}

export class LoadProductById {
  static readonly type = '[Products] Load Product By Id via Service';
  constructor(public id: string) {}
}

export class CreateProduct {
  static readonly type = '[Products] Create Product';
  constructor(public product: IProductRequest) {}
}

export class UpdateProduct {
  static readonly type = '[Products] Update Product';
  constructor(public product: IProductRequest) {}
}

export class DeleteProduct {
  static readonly type = '[Products] Delete Product';
  constructor(public productId: string, public menuId: string) {}
}

export class UseProduct {
  static readonly type = '[Products] Use Product';
  constructor(public product: IUseProduct) {}
}

export class AddToStock {
  static readonly type = '[Products] Add to Stock';
  constructor(public product: IUseProduct) {}
}

export class AdjustStock {
  static readonly type = '[Products] Adjust Stock';
  constructor(public product: IUseProduct) {}
}

export class LoadProductStock {
  static readonly type = '[Products] Get Product Stock';
  constructor(public productId: string) {}
}
