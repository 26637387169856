// eslint-disable-next-line max-classes-per-file
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  UntypedFormControl,
} from '@angular/forms';
import { IAttachmentUploader } from '../models/attachment-uploader.model';

export class AttachmentForm extends FormGroup {
  readonly name = this.get('name') as FormControl;
  readonly categories = this.get('categories') as FormControl;
  readonly file = this.get('file') as FormControl;

  constructor(uploader?: IAttachmentUploader, readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        name: [uploader?.name || '', Validators.required],
        categories: [uploader?.categories || null, Validators.required],
        file: [uploader?.file || ''],
      }).controls
    );
  }
}
export class UploaderForm extends FormGroup {
  readonly attachments = this.get('attachments') as FormArray;
  readonly attachmentControls = this.attachments.controls as AttachmentForm[];

  constructor(readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        attachments: fb.array([]),
      }).controls
    );
  }
}
export class AttachmentSearchForm extends FormGroup {
  readonly searchedText = this.get('searchedText') as UntypedFormControl;

  constructor(readonly fb: FormBuilder = new FormBuilder()) {
    super(
      fb.group({
        searchedText: null,
      }).controls
    );
  }
}
