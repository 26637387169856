/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IProductType } from '../models/product-type.model';

export class LoadProductTypes {
  static readonly type = '[Product Type] Load Product Types via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllProductTypes {
  static readonly type = '[Product Type] Load All Product Types via Service';
  constructor() {}
}

export class LoadProductTypeById {
  static readonly type = '[Product Type] Load a Product Type By Id';
  constructor(public id: string) {}
}

export class CreateProductType {
  static readonly type = '[Product Type] Create Product Type';
  constructor(public productType: IProductType) {}
}

export class UpdateProductType {
  static readonly type = '[Product Type] Update Product Type';
  constructor(public productType: IProductType) {}
}

export class DeleteProductType {
  static readonly type = '[Product Type] Delete Product Type';
  constructor(public id: string) {}
}
