<div
  class="tenant-selector-main-container-mobile"
  *ngIf="{
    selectedDepartment: selectedDepartment$ | async
  } as departmentInfo"
>
  <div class="mobile-dialog-heading">
    <span class="mobile-heading-title">
      {{ 'Department' | translate }}
    </span>
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>
  <div class="dialog-search-container-mobile mat-dialog-mobile-border-bottom">
    <app-ui-autocomplete
      [placeholder]="'search' | translate"
      [options]="searchableDepartments"
      [searchTerm]="form.searchTerm"
      [floatLabel]="'always'"
      [showList]="false"
    ></app-ui-autocomplete>
  </div>
  <div class="tenant-list-container">
    <div
      class="department-single-container mat-dialog-mobile-border-top mat-dialog-mobile-border-bottom"
      *ngFor="let department of filteredDepartments"
      (click)="selectDepartment(department)"
      [ngClass]="department.id === currentDepartmentId ? 'selected-dialog-list-item' : ''"
    >
      <div class="mobile-dep-image-container">
        <img
          width="50"
          height="50"
          class="rounded-border"
          [src]="(department.logo | appendDomain) ?? departmentPlaceHolderIconPath"
          alt="vessel picture"
        />
      </div>
      <div class="department-option-container">
        <span class="left-details-col">
          <h3>{{ department.name }}</h3>
          <span class="vessel-details">
            <div class="vessel-headings-row">
              <span>LOA</span>
              <span>DW </span>
              <span>GRP</span>
            </div>
            <!-- TODO awaiting back-end response, as we're missing these props -->
            <div class="vessel-info-row">
              <!-- <span>{{ option.loa | uppercase }}</span>
              <span>{{ option.dw | uppercase }}</span>
              <span>{{ option.grp | uppercase }}</span> -->
              <span> - </span>
              <span> - </span>
              <span> - </span>
            </div>
            <!-- TODO awaiting back-end response, as we're missing these props -->
          </span>
        </span>
        <span class="right-details-col">
          <span
            *ngIf="department.id === departmentInfo.selectedDepartment?.id"
            class="selected-marker"
          >
            <mat-icon>done</mat-icon>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
