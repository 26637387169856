import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { AdminFormsRoutes } from 'src/app/core/enums/routes.enum';
import { ApiResponse } from 'src/app/core/models/api-response';
import { CreateUpdateDeleteRecursiveArrayService } from 'src/app/core/services/create-update-delete-recursive-array.service';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { redirectAndBypassUnsavedDataCheck } from 'src/app/shared/functions/redirect-and-bypass-unsaved-data-check';
import { IdName } from 'src/app/shared/models/id-name';
import {
  ICustomFormDefinitionModel,
  map,
} from '../../shared/custom-forms-responses/models/custom-form-definition.model';
import { ICustomFormBasicsModel } from '../models/custom-form-basics.model';
import { CustomFormModelForList } from '../models/custom-form-for-list.model';
import { ICustomFormVersionModel } from '../models/custom-form-version.model';
import { ICustomFormModel } from '../models/custom-form.model';
import { IFormsLibraryModel } from '../models/forms-library.model';
import { FormsLibraryApiService } from '../services/forms-library-api.service';
import {
  AddCustomForm,
  AddFormsLibrary,
  ClearCurrentCustomForm,
  DeleteCustomForm,
  DeleteFormsLibrary,
  GetCustomFormById,
  GetCustomFormsByModuleId,
  GetFormsLibraryById,
  LoadCustomFormStatuses,
  LoadFormsLibraries,
  UpdateCustomForm,
  UpdateFormsLibrary,
  UpsertCustomFormDefinition,
} from './forms-library.actions';

export interface FormsLibraryStateModel {
  formLibraries: IFormsLibraryModel[];
  currentLibrary: IFormsLibraryModel | undefined;
  currentFormBasics: ICustomFormBasicsModel;
  currentFormVersions: ICustomFormVersionModel[];
  currentFormDefinition: ICustomFormDefinitionModel | undefined;
  customFormStatusesLoaded: boolean;
  customFormStatuses: IdName[];
  customFormsForModule: IdName[];
}

const FORMS_LIBRARY_STATE_TOKEN = new StateToken<FormsLibraryStateModel>('formsLibrary');

@State<FormsLibraryStateModel>({
  name: FORMS_LIBRARY_STATE_TOKEN,
  defaults: {
    formLibraries: [],
    currentLibrary: undefined,
    currentFormBasics: <ICustomFormBasicsModel>{},
    currentFormVersions: [],
    currentFormDefinition: undefined,
    customFormStatusesLoaded: false,
    customFormStatuses: <IdName[]>{},
    customFormsForModule: [],
  },
})
@Injectable()
export class FormsLibraryState {
  constructor(
    private service: FormsLibraryApiService,
    private createUpdateDeleteRecursiveArrayService: CreateUpdateDeleteRecursiveArrayService
  ) {}

  @Action(LoadFormsLibraries, { cancelUncompleted: true })
  loadFormsLibraries({ getState, setState }: StateContext<FormsLibraryStateModel>) {
    return this.service.getAllFormsLibraries().pipe(
      tap((response: ApiResponse<IFormsLibraryModel[]>) => {
        const state = getState();
        setState({
          ...state,
          formLibraries: response.data,
        });
      })
    );
  }

  @Action(LoadCustomFormStatuses, { cancelUncompleted: true })
  loadCustomFormStatuses({ getState, setState }: StateContext<FormsLibraryStateModel>) {
    return this.service.getCustomFormStatuses().pipe(
      tap((response: IdName[]) => {
        const state = getState();
        setState({
          ...state,
          customFormStatusesLoaded: true,
          customFormStatuses: response,
        });
      })
    );
  }

  @Action(GetFormsLibraryById, { cancelUncompleted: true })
  getFormsLibraryById(
    { getState, setState }: StateContext<FormsLibraryStateModel>,
    { libraryId }: GetFormsLibraryById
  ) {
    return this.service.getFormsLibraryById(libraryId).pipe(
      tap((library: IFormsLibraryModel) => {
        const state = getState();
        setState({
          ...state,
          currentLibrary: library,
        });
      })
    );
  }

  @Action(AddFormsLibrary, { cancelUncompleted: true })
  @OnSuccess({ url: AdminFormsRoutes.Main })
  addFormsLibrary(ctx: StateContext<FormsLibraryStateModel>, { formLibrary }: AddFormsLibrary) {
    return this.service.createFormsLibrary(formLibrary).pipe(
      tap((response: IFormsLibraryModel) => {
        response.children = [];
        ctx.setState(
          patch({
            formLibraries: insertItem<IFormsLibraryModel>(response),
          })
        );
      })
    );
  }

  @Action(UpdateFormsLibrary, { cancelUncompleted: true })
  @OnSuccess({ url: AdminFormsRoutes.Main })
  updateFormsLibrary(
    ctx: StateContext<FormsLibraryStateModel>,
    { formLibrary }: UpdateFormsLibrary
  ) {
    return this.service.updateFormsLibrary(formLibrary).pipe(
      tap((response: IFormsLibraryModel) => {
        const state = ctx.getState();
        const library = state.formLibraries.find((fl: IFormsLibraryModel) => fl.id === response.id);
        if (library) response.children = library.children;

        ctx.setState(
          patch({
            formLibraries: updateItem<IFormsLibraryModel>(
              (libraryToUpdate: IFormsLibraryModel | undefined) =>
                libraryToUpdate?.id === response.id,
              response
            ),
          })
        );
      })
    );
  }

  @Action(DeleteFormsLibrary, { cancelUncompleted: true })
  @OnSuccess({ message: 'formLibraryDeletedSuccessfully' })
  deleteFormsLibrary(ctx: StateContext<FormsLibraryStateModel>, { id }: DeleteFormsLibrary) {
    return this.service.deleteFormsLibrary(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            formLibraries: removeItem<IFormsLibraryModel>(
              (library: IFormsLibraryModel | undefined) => library?.id === id
            ),
          })
        );
      })
    );
  }

  @Action(GetCustomFormsByModuleId, { cancelUncompleted: true })
  getCustomFormsByModuleId(
    { getState, setState }: StateContext<FormsLibraryStateModel>,
    { moduleId }: GetCustomFormsByModuleId
  ) {
    return this.service.getCustomFormsByModuleId(moduleId).pipe(
      tap((customFormsForModule: IdName[]) => {
        const state = getState();
        setState({
          ...state,
          customFormsForModule,
        });
      })
    );
  }

  @Action(AddCustomForm, { cancelUncompleted: true })
  addCustomForm(ctx: StateContext<FormsLibraryStateModel>, { customForm }: AddCustomForm) {
    return this.service.createCustomForm(customForm).pipe(
      tap((response: CustomFormModelForList) => {
        const state = ctx.getState();
        const updatedItems =
          this.createUpdateDeleteRecursiveArrayService.addNewItemToParentChildrenElements(
            state.formLibraries,
            response.parentId,
            response
          );

        ctx.setState(
          patch({
            formLibraries: updatedItems,
          })
        );

        redirectAndBypassUnsavedDataCheck(AdminFormsRoutes.DetailsId.replace(':id', response.id));
      })
    );
  }

  @Action(UpdateCustomForm, { cancelUncompleted: true })
  updateCustomForm(
    { getState, setState }: StateContext<FormsLibraryStateModel>,
    { customForm }: UpdateCustomForm
  ) {
    return this.service.updateCustomForm(customForm).pipe(
      tap((response: ICustomFormBasicsModel) => {
        const state = getState();

        const customFormForList: CustomFormModelForList =
          this.createUpdateDeleteRecursiveArrayService.getChildInParent(
            state.formLibraries,
            response.parentId,
            response.id
          );
        customFormForList.formStatus = response.formStatus;
        customFormForList.name = response.name;

        const updatedItems =
          this.createUpdateDeleteRecursiveArrayService.updateItemInParentChildrenElements(
            state.formLibraries,
            customFormForList.parentId,
            customFormForList
          );

        setState({
          ...state,
          currentFormBasics: response,
          formLibraries: updatedItems,
        });
      })
    );
  }

  @Action(UpsertCustomFormDefinition, { cancelUncompleted: true })
  upsertCustomFormDefinition(
    { getState, setState }: StateContext<FormsLibraryStateModel>,
    { model }: UpsertCustomFormDefinition
  ) {
    return this.service.upsertCustomFormDefinition(model).pipe(
      tap(() => {
        const state = getState();

        setState({
          ...state,
          currentFormDefinition: map(model),
        });
      })
    );
  }

  @Action(DeleteCustomForm, { cancelUncompleted: true })
  @OnSuccess({ url: AdminFormsRoutes.Main, message: 'customFormDeletedSuccessfully' })
  deleteCustomForm(ctx: StateContext<FormsLibraryStateModel>, { id }: DeleteCustomForm) {
    return this.service.deleteCustomForm(id).pipe(
      tap(() => {
        const state = ctx.getState();
        const updatedItems = this.createUpdateDeleteRecursiveArrayService.removeItemFromNestedItems(
          state.formLibraries,
          id
        );

        ctx.setState(
          patch({
            formLibraries: updatedItems,
          })
        );
      })
    );
  }

  @Action(GetCustomFormById, { cancelUncompleted: true })
  getCustomFormById(
    { getState, setState }: StateContext<FormsLibraryStateModel>,
    { formId }: GetCustomFormById
  ) {
    return this.service.getCustomFormById(formId).pipe(
      tap((form: ICustomFormModel) => {
        const state = getState();
        setState({
          ...state,
          currentFormBasics: form.details,
          currentFormVersions: form.versions,
          currentFormDefinition: form.definition,
        });
      })
    );
  }

  @Action(ClearCurrentCustomForm, { cancelUncompleted: true })
  clearCurrentCustomForm({ getState, setState }: StateContext<FormsLibraryStateModel>) {
    const state = getState();
    setState({
      ...state,
      currentFormBasics: <ICustomFormBasicsModel>{},
      currentFormVersions: [],
      currentFormDefinition: undefined,
    });
  }

  @Selector()
  static getFormsLibraries(state: FormsLibraryStateModel) {
    return state.formLibraries;
  }

  @Selector()
  static getFormsLibraryById(state: FormsLibraryStateModel) {
    return state.currentLibrary;
  }

  @Selector()
  static getCustomFormBasics(state: FormsLibraryStateModel) {
    return state.currentFormBasics;
  }

  @Selector()
  static getCustomFormVersions(state: FormsLibraryStateModel) {
    return state.currentFormVersions;
  }

  @Selector()
  static getCustomFormDefinition(state: FormsLibraryStateModel) {
    return state.currentFormDefinition as ICustomFormDefinitionModel;
  }

  @Selector()
  static getCustomFormsForModulesById(state: FormsLibraryStateModel) {
    return state.customFormsForModule;
  }

  @Selector()
  static customFormStatusesLoaded(state: FormsLibraryStateModel) {
    return state.customFormStatusesLoaded;
  }

  @Selector()
  static customFormStatuses(state: FormsLibraryStateModel) {
    return state.customFormStatuses;
  }
}
