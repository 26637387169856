import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { REDIRECT_PATH_KEY } from '../../../services/store-snapshot.service';

@Component({
  selector: 'app-work-in-progress',
  templateUrl: './work-in-progress.component.html',
  styleUrls: ['./work-in-progress.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class WorkInProgressComponent {
  constructor() {
    sessionStorage.removeItem(REDIRECT_PATH_KEY);
  }
}
