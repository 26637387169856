import { ENTER, MAC_ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { HashtagModel } from 'src/app/hashtag/models/hashtag.model';
import { Store } from '@ngxs/store';
import { LoadHashtags } from 'src/app/hashtag/state/hashtag.actions';
import { HashtagState } from 'src/app/hashtag/state/hashtag.state';
import { SupportRoutes } from '../../enums/routes.enum';
import { ToastService } from '../../services/toast.service';
import { GlobalSearchForm } from '../../forms/global-search-form';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  allHashtags$: Observable<HashtagModel[]> = this.store.select(HashtagState.allHashtags);

  form = new GlobalSearchForm();
  hashtags: HashtagModel[];

  private subscriptions = new Subscription();

  constructor(private store: Store, private router: Router, private toastService: ToastService) {}

  ngOnInit(): void {
    this.getHashtags();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  searchWhenKeyUp(key: any): void {
    if (key.keyCode === ENTER || key.keyCode === MAC_ENTER) {
      this.checkSearchName();
    }
  }

  searchFor(): void {
    this.checkSearchName();
  }

  private getHashtags(): void {
    // first we need to call an action to fetch all available hashtags
    this.store.dispatch(new LoadHashtags());
    // then we subscribe to hashtags from the state
    this.subscriptions.add(
      this.allHashtags$.subscribe((hashtags: HashtagModel[]) => {
        if (hashtags) {
          this.hashtags = hashtags;
        }
      })
    );
  }

  private checkSearchName(): void {
    const hashtagExist = this.searchValueIsValidHashtag(this.form.searchTerm.value);

    if (hashtagExist) {
      this.routeTo(hashtagExist.name);
    } else if (this.form.searchTerm.value[0] !== '#') {
      this.routeTo(this.form.searchTerm.value);
    } else {
      // has a "hash" inside, but is not a valid hashtag
      this.noHashtag();
    }
  }

  private searchValueIsValidHashtag(searchTerm: HashtagModel | string): HashtagModel | undefined {
    if (typeof searchTerm === 'string') {
      return this.hashtags.find((hashtag: HashtagModel) => hashtag.name === searchTerm);
    }
    return this.hashtags.find((hashtag: HashtagModel) => hashtag.name === searchTerm.name);
  }

  private noHashtag(): void {
    this.form.searchTerm.setValue('');
    this.toastService.error('enteredHashNotExist');
  }

  private routeTo(searchTerm: string): void {
    this.router.navigate([`${SupportRoutes.GlobalSearchResult}`, searchTerm]);
  }
}
