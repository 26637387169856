import { DecimalPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { validationMessages } from '../../messages';

@Component({
  selector: 'app-ui-input',
  templateUrl: './ui-text-input.component.html',
  styleUrls: ['./ui-text-input.component.scss'],
  providers: [DecimalPipe],
})
export class UiTextInputComponent implements AfterViewInit {
  @ViewChild('input') input: ElementRef;
  @Input() label = '';
  @Input() description = '';
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() messages = validationMessages;
  @Input() required = false;
  @Input() type: 'text' | 'number' | 'file' = 'text';
  @Input() formatNumber = false;
  @Input() tabIndex: number;
  @Input() prependPlusSingForPositives = false;

  @Output() readonly changeHandler: EventEmitter<string> = new EventEmitter<string>();

  private invalidChars = ['E', 'e', ','];

  constructor(private decimalPipe: DecimalPipe) {}

  ngAfterViewInit(): void {
    if (this.control) {
      this.formatInput();
    }
  }

  keyDown(event: KeyboardEvent) {
    if (this.type === 'number')
      if (this.invalidChars.includes(event.key)) {
        event.preventDefault();
      }
  }

  formatInput(): void {
    this.changeHandler.emit(this.input.nativeElement.value);
    if (!this.formatNumber) {
      return;
    }

    const value = this.input.nativeElement.value.replace(/\D/g, ''); // remove non-numeric characters
    const formattedValue = this.decimalPipe.transform(value, '1.0-0'); // format value with 0 decimal places
    this.input.nativeElement.value = formattedValue || ''; // set input value to formatted value or empty string if undefined/null
    this.control.setValue(value, { emitModelToViewChange: false }); // update FormControl's value with unformatted value
  }
}
