/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IMedicineRequest } from '../models/medicine.model';
import { IUseMedicine } from '../models/use-medicine.model';

export class LoadPagedMedicines {
  static readonly type = '[Medicines] Load Paged Medicines via Service';
  constructor(public filter?: MainTableFilterModel, public menuId?: string) {}
}

export class LoadAllMedicines {
  static readonly type = '[Medicines] Load All Medicines via Service';
  constructor(public filter?: MainTableFilterModel, public menuId?: string) {}
}

export class LoadMedicineById {
  static readonly type = '[Medicines] Load Medicine By Id via Service';
  constructor(public id: string) {}
}

export class CreateMedicine {
  static readonly type = '[Medicines] Create Medicine';
  constructor(public medicine: IMedicineRequest) {}
}

export class UpdateMedicine {
  static readonly type = '[Medicines] Update Medicine';
  constructor(public medicine: IMedicineRequest) {}
}

export class DeleteMedicine {
  static readonly type = '[Medicines] Delete Medicine';
  constructor(public medicineId: string) {}
}

export class UseMedicine {
  static readonly type = '[Medicines] Use Medicine';
  constructor(public medicine: IUseMedicine) {}
}

export class AddToStock {
  static readonly type = '[Medicines] Add to Stock';
  constructor(public medicine: IUseMedicine) {}
}

export class AdjustStock {
  static readonly type = '[Medicines] Adjust Stock';
  constructor(public medicine: IUseMedicine) {}
}

export class LoadMedicineStock {
  static readonly type = '[Medicines] Get Medicine Stock';
  constructor(public medicineId: string) {}
}
