<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'confirm'">
    <button mat-button *ngIf="element.type === paxOverviewType.Current; else planned">
      <mat-icon>transfer_within_a_station</mat-icon>
    </button>
  </ng-container>
  <ng-template #planned>
    <button mat-button>
      <mat-icon>done</mat-icon>
    </button>
  </ng-template>
  <ng-container *ngSwitchCase="'trip'">
    {{ element.tripName }}
  </ng-container>
  <ng-container *ngSwitchCase="'arrivalDateTime'">
    {{ element.from | date : dateTimeFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'departureDateTime'">
    {{ element.to | date : dateTimeFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'from'">
    {{ element.from | date : dateFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'to'">
    {{ element.to | date : dateFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'plannedArrivalDate'">
    {{ element.plannedStartDate | date : dateFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'plannedDepartureDate'">
    {{ element.plannedEndDate | date : dateFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'arrivalDateTime'">
    {{ element.arrival | date : dateTimeFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'plannedEndDate'">
    {{ element.plannedEnd | date : dateFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    {{ element.status | onBoardStatusMap | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'currentActivityEndDate'">
    {{ element.currentActivityEndDate | date : dateFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'period'">
    {{ element | dateDiff }} {{ 'days' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'type'">
    <ng-container *ngIf="element.type === personOnBoardType.Personnel; else PAX">
      {{ 'employee' | translate }}
    </ng-container>
  </ng-container>
  <ng-template #PAX>{{ 'pax' | translate }}</ng-template>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
