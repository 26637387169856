import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Observer, Subscription, startWith } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { isFileExtensionSupported, retry } from '../text-control.helpers';
import { TextControlService } from '../text-control.service';

@Component({
  selector: 'app-text-control-editor',
  templateUrl: './text-control-editor.component.html',
  styleUrls: ['./text-control-editor.component.scss'],
})
export class TextControlEditorComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() control: UntypedFormControl;
  @ViewChild('container') container: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @HostBinding('class.fullscreen') get fullscreenClass(): boolean {
    return this.textControl.isFullscreen;
  }
  @Output() readonly TXContent = new EventEmitter<any>();

  private subscriptions = new Subscription();
  private parentElement: any;

  constructor(
    private elRef: ElementRef,
    private toast: ToastService,
    public textControl: TextControlService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.control.statusChanges.pipe(startWith(this.control.status)).subscribe((status) => {
        this.textControl.setEditModeStatus(status);
      })
    );
  }

  ngOnDestroy(): void {
    this.textControl.hide();
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.parentElement = this.elRef.nativeElement.parentElement;
    this.subscriptions.add(
      this.textControl.editorLoaded.subscribe((loaded) => {
        if (loaded) {
          this.initContent();
        }
      })
    );
  }

  initContent(): void {
    retry(
      () => this.textControl.isEditorReady,
      () => {
        if (this.textControl.editorElement && this.container.nativeElement) {
          this.loadDocumentIntoEditor();
          this.textControl.refreshLayout();
        }
      },
      100
    );
  }

  loadDocumentIntoEditor(): void {
    if (this.control.value) {
      this.textControl.loadDocument(this.control.value);
    }
  }

  show(): void {
    this.textControl.show(this.container.nativeElement);
  }

  import(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeLoad(event: any): void {
    if (!event.target.files.length) {
      return;
    }
    if (!isFileExtensionSupported(event)) {
      this.toast.error('fileExtensionNotSupported');
      return;
    }
    this.textControl.readAndLoadDocument(event);
  }

  refreshLayout(): void {
    this.textControl.refreshLayout();
  }

  updateControl(): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      if (this.textControl.isEditorReady) {
        this.toast.loading('startedSavingTextControlDocument');
        this.textControl.setLoading(true);
        this.textControl.tx.saveDocument(
          this.textControl.tx.StreamType.WordprocessingML,
          (e: any) => {
            this.control.setValue(e.data);
            this.control.updateValueAndValidity();
            this.textControl.setLoading(false);
            observer.next(e.data);
            observer.complete();
          }
        );
      }
    });
  }

  @HostListener('document:fullscreenchange')
  onFullscreenChange(): void {
    if (document.fullscreenElement) {
      document.body.appendChild(this.elRef.nativeElement);
    } else {
      this.parentElement.appendChild(this.elRef.nativeElement);
    }
    this.refreshLayout();
  }

  enterFullscreen(): void {
    document.body.requestFullscreen();
  }

  exitFullscreen(): void {
    document.exitFullscreen();
  }
}
