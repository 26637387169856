import { Component, Input } from '@angular/core';
import { MainModulesEnum } from 'src/app/core/enums/modules.enum';
import { UiPillStatusEnum } from '../../enums/ui-pill-status.enum';

@Component({
  selector: 'app-ui-pill',
  templateUrl: './ui-pill.component.html',
  styleUrls: ['./ui-pill.component.scss'],
})
export class UiPillComponent {
  @Input() name: string;
  @Input() number: number;
  @Input() module: string;
  @Input() expiredDueListWarnings: boolean;

  mainModulesEnum = MainModulesEnum;
  status = UiPillStatusEnum;
}
