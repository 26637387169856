<div
  *ngIf="isTopItem"
  #tdzEl
  dndDropzone
  class="menu-drop-zone top"
  dndDragoverClass="active"
  [class.cdk-visually-hidden]="!menuDNDService.activeItem"
  [dndDisableIf]="
    !showFunctionalButtons ||
    menuDNDService.menuDisabledStatuses[item.id] ||
    menuDNDService.activeItem === item
  "
  (dndDrop)="onDrop('top')"
  (dragenter)="resetOtherDropzones(tdz)"
  (dragleave)="resetDropzone(tdz)"
></div>

<div
  class="main-menu-tree-single-container"
  [ngClass]="{
    'padding-left': !showFunctionalButtons,
    'padding-right': !showFunctionalButtons,
    'edit-mode': showFunctionalButtons,
    'view-mode': !showFunctionalButtons,
  }"
>
  <div>
    <div
      class="single-menu-tree-row"
      [ngClass]="{
        'initial-node-margin': mainModules && !showFunctionalButtons,
        'single-menu-tree-row-edit': showFunctionalButtons,
        'list-opened': showChildren
      }"
      #dzEl
      dndDropzone
      dndDragoverClass="drop-zone-active"
      [dndDropzoneDisabledClass]="showFunctionalButtons ? 'drop-zone-disabled' : 'dummy'"
      [dndDisableIf]="
        !showFunctionalButtons ||
        menuDNDService.menuDisabledStatuses[item.id] ||
        menuDNDService.activeItem === item
      "
      (dndDrop)="onDrop('inside')"
    >
      <span
        (click)="routeTo()"
        class="node-name"
        [matTooltip]="item && item.name && item.name.length > truncateThreshold ? item.name : ''"
        [ngClass]="{
          'editable-element': showFunctionalButtons,
          'item-highlighted': isChosen
        }"
        [dndDraggable]="item"
        [dndDragImageOffsetFunction]="dragItemOffsetRight"
        [dndDisableIf]="!showFunctionalButtons"
        [dndEffectAllowed]="'all'"
        (dndStart)="onDragStart($event, item)"
        (dndCanceled)="onDragEnd()"
        (dndEnd)="onDragEnd()"
        (dragenter)="resetOtherDropzones(dz)"
        (dragleave)="resetDropzone(dz)"
      >
        <mat-icon
          *ngIf="hasChildren"
          aria-hidden="false"
          aria-label="Arrow down icon"
          [@rotatedState]="showChildren"
          (click)="toggleDropdown()"
          class="arrow-icon"
        >
          chevron_right</mat-icon
        >
        <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
        {{ item.name | truncate : truncateThreshold }}
        <mat-icon *ngIf="showFunctionalButtons" class="drag-handle">drag_indicator</mat-icon>
      </span>

      <ng-container *ngIf="showFunctionalButtons">
        <app-menu-tree-buttons
          [item]="item"
          [moduleNumber]="moduleNumber"
          [onlyAdd]="onlyAdd"
          [tenantId]="tenantId"
          (showChildren)="reactOnShowChildrenChange($event)"
        ></app-menu-tree-buttons>
      </ng-container>
    </div>
    <div *ngIf="showChildren" class="container-with-child-elements">
      <app-menu-tree-single
        *ngFor="let child of item.children; let i = index; trackBy: trackById"
        [item]="child"
        [module]="module"
        [moduleNumber]="moduleNumber"
        [tenantId]="tenantId"
        [showFunctionalButtons]="showFunctionalButtons"
        [menuItemsAsRouting]="menuItemsAsRouting"
        [isLast]="item.children.length === i + 1"
        [isTopItem]="i === 0"
        [nextItem]="item.children[i + 1]"
        [chosenElementId]="chosenElementId"
        (chosenElementClicked)="emitElementClicked($event)"
      >
      </app-menu-tree-single>
    </div>
  </div>
</div>

<div
  #bdzEl
  dndDropzone
  class="menu-drop-zone bottom"
  [class.increased]="isLast"
  [class.cdk-visually-hidden]="!menuDNDService.activeItem"
  dndDragoverClass="active"
  [dndDisableIf]="
    !showFunctionalButtons ||
    menuDNDService.menuDisabledStatuses[item.id] ||
    menuDNDService.activeItem === item ||
    menuDNDService.activeItem === nextItem
  "
  (dndDrop)="onDrop('bottom')"
  (dragenter)="resetOtherDropzones(bdz)"
  (dragleave)="resetDropzone(bdz)"
></div>
