/* eslint-disable max-classes-per-file */
import { IdName } from 'src/app/shared/models/id-name';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadCauses {
  static readonly type = '[Cause] Load Causes via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllCauses {
  static readonly type = '[Cause] Load All Causes via Service';
  constructor() {}
}

export class LoadCauseById {
  static readonly type = '[Cause] Load a single Cause';
  constructor(public id: string) {}
}

export class CreateCause {
  static readonly type = '[Cause] Create Cause';
  constructor(public cause: IdName) {}
}

export class UpdateCause {
  static readonly type = '[Cause] Update Cause';
  constructor(public cause: IdName) {}
}

export class DeleteCause {
  static readonly type = '[Cause] Delete Cause';
  constructor(public id: string) {}
}
