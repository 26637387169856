import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { validationMessages } from '../../messages';
import { IOptionGroup } from '../../models/option-group.model';

@Component({
  selector: 'app-ui-select-group',
  templateUrl: './ui-select-group.component.html',
  styleUrls: ['./ui-select-group.component.scss'],
})
export class UiSelectGroupComponent implements OnChanges {
  @Input() label = '';
  @Input() control: UntypedFormControl;
  @Input() optionGroups: IOptionGroup[] | null;
  @Input() messages = validationMessages;
  @Input() selected: string[] = [];
  @Input() isMultiple = true;
  @Input() disabled = false;
  @Input() description = '';
  @Input() required = false;
  @Input() returnWholeObject = false;
  @Input() touched = false;

  @Output() readonly changeHandler: EventEmitter<MatSelectChange> =
    new EventEmitter<MatSelectChange>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.touched?.currentValue === true) {
      this.control.markAllAsTouched();
    }
  }

  onChange(event: MatSelectChange): void {
    this.changeHandler.emit(event);
  }

  compareWith(o1: any, o2: any): boolean {
    return o1.value?.id && o2.value?.id ? o1.value?.id === o2.value?.id : Object.is(o1, o2);
  }
}
