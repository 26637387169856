import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private unAuthUrl = 'blob.core.windows.net';

  constructor(private oidcSecurityService: OidcSecurityService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(this.oidcSecurityService.getAccessToken()).pipe(
      switchMap((token: string) => {
        if (token && !request.url.includes(this.unAuthUrl)) {
          const authRequest = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
          return next.handle(authRequest);
        }
        return next.handle(request);
      })
    );
  }
}
