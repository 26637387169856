<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'smsReminder'">
    <mat-icon>{{ element[columnName] ? 'done' : 'close' }}</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'emailReminder'">
    <mat-icon>{{ element[columnName] ? 'done' : 'close' }}</mat-icon>
  </ng-container>
  <ng-container *ngSwitchCase="'modules'">
    <ng-container *ngFor="let element of element['moduleNames']"
      >{{ element | translate }}<br
    /></ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
