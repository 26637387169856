<div class="form">
  <h3>{{ title | translate }}</h3>
  <form [formGroup]="addressForm" *appLoader="!addressForm">
    <div class="row-container">
      <app-ui-input
        label="{{ 'country' | translate }}"
        [control]="addressForm.country"
        [description]="countryDesc"
      ></app-ui-input>
      <app-ui-input
        label="{{ 'zip' | translate }}"
        [control]="addressForm.zip"
        [description]="zipDesc"
      ></app-ui-input>
    </div>
    <div class="row-container">
      <app-ui-input
        label="{{ 'city' | translate }}"
        [control]="addressForm.city"
        [description]="cityDesc"
      ></app-ui-input>
      <app-ui-input
        label="{{ 'address' | translate }}"
        [control]="addressForm.address"
        [description]="addressDesc"
      ></app-ui-input>
    </div>
  </form>
</div>
