/* eslint-disable max-classes-per-file */
import {
  defaultInitialPageSize,
  defaultPageIndex,
  moduleDefaultPageSizes,
} from '../../core/table-config/table-paging-defaults';
import { MainTableFilterModulesEnum } from '../../core/enums/main-table-filter-modules.enum';

export class MainTableFilterSearchByModel {
  property: string;
  value: number | string | string[];
  operand: string;
  constructor(property: string, value: number | string | string[], operand: string) {
    this.property = property;
    this.value = value;
    this.operand = operand;
  }
}

export type MainTableSortingType = 'Ascending' | 'Descending' | 'asc' | 'desc' | ''; // TODO Illia: this makes my eye go brrr. transform short -> long once in API service helper
export class MainTableFilterModel {
  module: MainTableFilterModulesEnum;
  pageIndex = defaultPageIndex;
  pageSize = defaultInitialPageSize;
  searchTerm?: string;
  orderBy?: string;
  ascDesc?: MainTableSortingType;
  additional?: any;
  departmentId?: string;
  searchBy?: MainTableFilterSearchByModel[];

  constructor(module: MainTableFilterModulesEnum) {
    this.module = module;
    this.pageSize = moduleDefaultPageSizes[module] ?? defaultInitialPageSize;
  }
}
