import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { patch } from '@ngxs/store/operators';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { ICompetenceMatrix } from './competence-matrix.model';
import { CompetenceMatrixApiService } from './competence-matrix-api.service';
import {
  LoadCompetenceMatrix,
  PrintCompetenceMatrix,
  SendCompetenceMatrixNotifications,
} from './competence-matrix.actions';

export interface CompetenceMatrixStateModel {
  matrix: ICompetenceMatrix | undefined;
  matrixPdf: Blob | undefined;
}

const COMPETENCE_MATRIX_STATE_TOKEN = new StateToken<CompetenceMatrixStateModel>(
  'competenceMatrixState'
);

@State<CompetenceMatrixStateModel>({
  name: COMPETENCE_MATRIX_STATE_TOKEN,
  defaults: {
    matrix: undefined,
    matrixPdf: undefined,
  },
})
@Injectable()
export class CompetenceMatrixState {
  constructor(private service: CompetenceMatrixApiService) {}

  @Action(LoadCompetenceMatrix, { cancelUncompleted: true })
  loadMatrix(
    { setState }: StateContext<CompetenceMatrixStateModel>,
    { params, adminPanel }: LoadCompetenceMatrix
  ) {
    return this.service.getMatrix(params, adminPanel).pipe(
      tap((matrix: ICompetenceMatrix) => {
        setState(patch({ matrix }));
      })
    );
  }

  @Action(PrintCompetenceMatrix, { cancelUncompleted: true })
  printMatrix(
    { setState }: StateContext<CompetenceMatrixStateModel>,
    { config }: PrintCompetenceMatrix
  ) {
    return this.service.printMatrixAsPdf(config).pipe(
      tap((matrixPdf: Blob) => {
        setState(patch({ matrixPdf }));
      })
    );
  }

  @Action(SendCompetenceMatrixNotifications, { cancelUncompleted: true })
  @OnSuccess({ message: 'competenceMatrixNotificationsSentSuccessfully' })
  sendNotifications(
    { setState }: StateContext<CompetenceMatrixStateModel>,
    { config }: SendCompetenceMatrixNotifications
  ) {
    return this.service.sendMatrixNotifications(config);
  }

  @Selector()
  static matrix(state: CompetenceMatrixStateModel) {
    return state.matrix;
  }

  @Selector()
  static matrixPdf(state: CompetenceMatrixStateModel) {
    return state.matrixPdf;
  }
}
