import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { filter, first, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { DepartmentState } from './state/department.state';
import { LoadDepartmentOptions } from './state/department.actions';

@Injectable()
export class DepartmentOptionsResolver implements Resolve<Observable<any>> {
  departmentOptionsLoaded$: Observable<boolean> = this.store.select(
    DepartmentState.departmentOptionsLoaded
  );

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.departmentOptionsLoaded$.pipe(
      tap((departmentOptionsLoaded) => {
        if (!departmentOptionsLoaded) {
          this.store.dispatch(new LoadDepartmentOptions());
        }
      }),
      filter((departmentOptionsLoaded) => departmentOptionsLoaded),
      first()
    );
  }
}
