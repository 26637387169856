/* eslint-disable no-use-before-define,@angular-eslint/use-component-selector */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractTableModal } from '../../../shared/directives/abstract-table-edit-modal.directive';
import { ContactForm } from '../../forms/contact-form';

@Component({
  templateUrl: './contacts-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsModalComponent extends AbstractTableModal<
  ContactForm,
  ContactsModalComponent
> {}
