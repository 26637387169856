import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { ROOT_MAINTENANCE_COMPONENT } from 'src/app/core/constants';
import { ModuleNumbers } from 'src/app/core/enums/module-numbers.enum';
import { AdminMaintenanceRoutes, AdminRoutesEnum } from 'src/app/core/enums/routes.enum';
import { CreateUpdateDeleteRecursiveArrayService } from 'src/app/core/services/create-update-delete-recursive-array.service';
import { LoadSpecificMenuStructure } from 'src/app/core/state/menu-tree-structure.actions';
import { OnSuccess } from 'src/app/shared/decorators/on-success.decorator';
import { IdName } from 'src/app/shared/models/id-name';
import {
  CopyComponentsModel,
  IComponentsStructureMenu,
} from '../models/components-structure-menu.model';
import { IMaintenanceComponentGroup } from '../models/maintenance-component-group.model';
import { IMaintenanceComponent } from '../models/maintenance-component.model';
import { ComponentStructureApiService } from '../services/component-structure.api.service';
import {
  CopyComponentStructure,
  CreateMaintenanceComponent,
  CreateMaintenanceComponentGroup,
  DeleteMaintenanceComponent,
  DeleteMaintenanceComponentGroup,
  GetAllMaintenanceComponents,
  GetMaintenanceComponentById,
  GetMaintenanceComponentGroupById,
  GetMaintenanceComponentGroups,
  GetMaintenanceComponents,
  GetMaintenanceMenuStructure,
  PrintComponentList,
  TransformToComponent,
  TransformToComponentsGroup,
  UpdateComponentsStructureOrder,
  UpdateMaintenanceComponent,
  UpdateMaintenanceComponentGroup,
} from './components-structure.actions';

export interface ComponentsStructureStateModel {
  componentsMenu: IComponentsStructureMenu[];
  componentList: IMaintenanceComponent[];
  componentListWithSFIInName: IMaintenanceComponent[];
  allComponents: IdName[];
  componentGroupList: IdName[];
  componentDetailed?: IMaintenanceComponent;
  componentGroupDetailed?: IMaintenanceComponentGroup;
  currentPrintedList?: Blob;
}

const COMPONENTS_STRUCTURE = new StateToken<ComponentsStructureStateModel>('componentsStructure');

@State<ComponentsStructureStateModel>({
  name: COMPONENTS_STRUCTURE,
  defaults: {
    allComponents: [],
    componentsMenu: [],
    componentList: [ROOT_MAINTENANCE_COMPONENT],
    componentListWithSFIInName: [ROOT_MAINTENANCE_COMPONENT],
    componentGroupList: [ROOT_MAINTENANCE_COMPONENT],
    componentDetailed: undefined,
    componentGroupDetailed: undefined,
    currentPrintedList: undefined,
  },
})
@Injectable()
export class ComponentsStructureState {
  constructor(private service: ComponentStructureApiService, private router: Router) {}

  private get isInAdmin(): boolean {
    return this.router.url.includes(AdminRoutesEnum.Admin);
  }

  // MAINTENANCE COMPONENTS MENU
  // _______________________________________________________________________
  @Action(GetMaintenanceMenuStructure, { cancelUncompleted: true })
  getMaintenanceMenuStructure({ setState }: StateContext<ComponentsStructureStateModel>) {
    return this.service.loadComponentsMenuStructure().pipe(
      tap((componentsMenu: IComponentsStructureMenu[]) => {
        setState(
          patch({
            componentsMenu,
          })
        );
      })
    );
  }

  // MAINTENANCE COMPONENTS
  // _______________________________________________________________________
  @Action(CreateMaintenanceComponent, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.Components, message: 'componentCreated' })
  createMaintenanceComponent(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { component }: CreateMaintenanceComponent
  ) {
    return this.service.createComponent(component).pipe(
      tap(() => {
        dispatch(new GetMaintenanceMenuStructure());
      })
    );
  }

  @Action(UpdateMaintenanceComponent, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.Components, message: 'componentUpdated' })
  updateMaintenanceComponent(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { component }: UpdateMaintenanceComponent
  ) {
    return this.service.updateComponent(component).pipe(
      tap(() => {
        dispatch(new GetMaintenanceMenuStructure());
        if (!this.isInAdmin) {
          dispatch(new LoadSpecificMenuStructure(ModuleNumbers.Maintenance));
        }
      })
    );
  }

  @Action(DeleteMaintenanceComponent, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.Components, message: 'componentDeleted' })
  deleteMaintenanceComponent(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { id }: DeleteMaintenanceComponent
  ) {
    return this.service.deleteComponent(id).pipe(
      tap(() => {
        dispatch(new GetMaintenanceMenuStructure());
      })
    );
  }

  @Action(GetMaintenanceComponentById, { cancelUncompleted: true })
  getMaintenanceComponentById(
    { setState }: StateContext<ComponentsStructureStateModel>,
    { id }: GetMaintenanceComponentById
  ) {
    return this.service.getComponentById(id).pipe(
      tap((componentDetailed: IMaintenanceComponent) => {
        setState(
          patch({
            componentDetailed,
          })
        );
      })
    );
  }

  @Action(GetMaintenanceComponents, { cancelUncompleted: true })
  getMaintenanceComponents({ setState }: StateContext<ComponentsStructureStateModel>) {
    return this.service.getComponentList().pipe(
      tap((componentList: IMaintenanceComponent[]) => {
        setState(
          patch({
            componentList: [ROOT_MAINTENANCE_COMPONENT, ...componentList],
            componentListWithSFIInName: [
              ROOT_MAINTENANCE_COMPONENT,
              ...componentList
                .map((c) => ({
                  ...c,
                  name: `${c.sfiCode} ${c.name}`,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
            ],
          })
        );
      })
    );
  }

  @Action(GetAllMaintenanceComponents, { cancelUncompleted: true })
  getAllMaintenanceComponents({ setState }: StateContext<ComponentsStructureStateModel>) {
    return this.service.getAllComponentList().pipe(
      tap((allComponents: IdName[]) => {
        setState(
          patch({
            allComponents: [ROOT_MAINTENANCE_COMPONENT, ...allComponents],
          })
        );
      })
    );
  }

  @Action(TransformToComponentsGroup, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.Components, message: 'componentTransformed' })
  transformToComponentsGroup(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { id }: TransformToComponentsGroup
  ) {
    return this.service.transformToComponentsGroup(id).pipe(
      tap(() => {
        dispatch(new GetMaintenanceMenuStructure());
      })
    );
  }

  // MAINTENANCE COMPONENT GROUPS
  // _______________________________________________________________________
  @Action(CreateMaintenanceComponentGroup, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.Components, message: 'componentGroupCreated' })
  createMaintenanceComponentGroup(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { componentGroup }: CreateMaintenanceComponentGroup
  ) {
    return this.service.createComponentGroup(componentGroup).pipe(
      tap(() => {
        dispatch(new GetMaintenanceMenuStructure());
      })
    );
  }

  @Action(UpdateMaintenanceComponentGroup, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.Components, message: 'componentGroupUpdated' })
  updateMaintenanceComponentGroup(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { componentGroup }: UpdateMaintenanceComponentGroup
  ) {
    return this.service.updateComponentGroup(componentGroup).pipe(
      tap(() => {
        dispatch(new GetMaintenanceMenuStructure());
        if (!this.isInAdmin) {
          dispatch(new LoadSpecificMenuStructure(ModuleNumbers.Maintenance));
        }
      })
    );
  }

  @Action(DeleteMaintenanceComponentGroup, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.Components, message: 'componentGroupDeleted' })
  deleteMaintenanceComponentGroup(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { id }: DeleteMaintenanceComponentGroup
  ) {
    return this.service.deleteComponentGroup(id).pipe(
      tap(() => {
        dispatch(new GetMaintenanceMenuStructure());
      })
    );
  }

  @Action(TransformToComponent, { cancelUncompleted: true })
  @OnSuccess({ url: AdminMaintenanceRoutes.Components, message: 'componentsGroupTransformed' })
  transformToComponent(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { id }: TransformToComponent
  ) {
    return this.service.transformToComponent(id).pipe(
      tap(() => {
        dispatch(new GetMaintenanceMenuStructure());
      })
    );
  }

  @Action(GetMaintenanceComponentGroupById, { cancelUncompleted: true })
  getMaintenanceComponentGroupById(
    { setState }: StateContext<ComponentsStructureStateModel>,
    { id }: GetMaintenanceComponentGroupById
  ) {
    return this.service.getComponentGroupById(id).pipe(
      tap((componentGroupDetailed: IMaintenanceComponentGroup) => {
        setState(
          patch({
            componentGroupDetailed,
          })
        );
      })
    );
  }

  @Action(GetMaintenanceComponentGroups, { cancelUncompleted: true })
  getMaintenanceComponentGroups({ setState }: StateContext<ComponentsStructureStateModel>) {
    return this.service.getComponentGroupList().pipe(
      tap((componentGroupList: IMaintenanceComponentGroup[]) => {
        const compGroupIdNameList: IdName[] = [];
        compGroupIdNameList.push(ROOT_MAINTENANCE_COMPONENT);
        if (componentGroupList?.length > 0) {
          componentGroupList.forEach((c: IMaintenanceComponentGroup) => {
            compGroupIdNameList.push({ id: c.id, name: c.name });
          });
          setState(
            patch({
              componentGroupList: compGroupIdNameList,
            })
          );
        }
      })
    );
  }

  @Action(PrintComponentList, { cancelUncompleted: true })
  printMaintenanceJob(
    { setState }: StateContext<ComponentsStructureStateModel>,
    { request }: PrintComponentList
  ) {
    return this.service.printComponentsList(request).pipe(
      tap((file) => {
        setState(patch({ currentPrintedList: file }));
      })
    );
  }

  @Action(CopyComponentStructure, { cancelUncompleted: true })
  @OnSuccess({ message: 'componentsStructureCopiedSuccessfully' })
  copyComponentStructure(
    ctx: StateContext<CopyComponentsModel>,
    { model }: CopyComponentStructure
  ) {
    return this.service.copyComponentStructure(model);
  }

  @Action(UpdateComponentsStructureOrder, { cancelUncompleted: true })
  @OnSuccess({ message: 'componentsStructureOrderUpdated' })
  updateComponentsStructureOrder(
    { dispatch }: StateContext<ComponentsStructureStateModel>,
    { order }: UpdateComponentsStructureOrder
  ) {
    return this.service
      .updateComponentStructureOrder(order)
      .pipe
      // tap(() => {
      // TODO Illia: works flakey because the update operation is async on the server
      // dispatch(new GetMaintenanceMenuStructure());
      // })
      ();
  }

  @Selector()
  static getComponentsStructure(state: ComponentsStructureStateModel) {
    return state.componentsMenu;
  }

  @Selector()
  static getFlatComponentsStructure(
    state: ComponentsStructureStateModel
  ): IComponentsStructureMenu[] {
    return CreateUpdateDeleteRecursiveArrayService.getFlatList(state.componentsMenu);
  }

  @Selector()
  static getComponentGroupList(state: ComponentsStructureStateModel) {
    return state.componentGroupList;
  }

  @Selector()
  static getComponentList(state: ComponentsStructureStateModel) {
    return state.componentList;
  }

  @Selector()
  static getComponentListWithSFIInName(state: ComponentsStructureStateModel) {
    return state.componentListWithSFIInName;
  }

  @Selector()
  static getAllComponentList(state: ComponentsStructureStateModel) {
    return state.allComponents;
  }

  @Selector()
  static getComponentDetails(state: ComponentsStructureStateModel) {
    return state.componentDetailed;
  }

  @Selector()
  static getComponentGroupDetails(state: ComponentsStructureStateModel) {
    return state.componentGroupDetailed;
  }

  @Selector()
  static getPrintedList(state: ComponentsStructureStateModel) {
    return state.currentPrintedList;
  }
}
