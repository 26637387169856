<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'quantity'">
    {{ element.quantity }} {{ element.quantityType?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'orderNumber'">
    {{ element.orderNumber }}
  </ng-container>
  <ng-container *ngSwitchCase="'checkbox'">
    <input
      type="checkbox"
      [(ngModel)]="element.isReceived"
      [ngModelOptions]="{ standalone: true }"
      (click)="buttonFunctions.markOrderLineAsReceived()"
    />
  </ng-container>
  <ng-container *ngSwitchCase="'supplier'">
    {{ element.supplier?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'orderType'">
    {{ element.orderType?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'totalSumVat'">
    {{ element.totalPrice }} {{ element.currencyCode }}
  </ng-container>
  <ng-container *ngSwitchCase="'sum'">
    {{ element.sum | delimitedCurrency }} {{ element.currencyName }}
  </ng-container>
  <ng-container *ngSwitchCase="'priceIncludingVat'">
    {{ element.priceIncludingVat | delimitedCurrency }} {{ element.currencyName }}
  </ng-container>
  <ng-container *ngSwitchCase="'account'"> {{ element.account?.name }} </ng-container>
  <ng-container *ngSwitchCase="'product'">
    <mat-icon
      *ngIf="element.supplierProductInvalid; else productName"
      matTooltip="{{ 'supplierChangedErrorDescription' | translate }}"
      matTooltipClass="tooltip"
      class="warn-icon"
      >warning</mat-icon
    >
    <ng-template #productName>
      {{ element.product }} {{ element.storageProduct?.name }} {{ element.medicine?.name }}
      {{ element.supplierProduct?.name }}
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="'procurementArea'">
    {{ element.procurementArea | mapProcurementArea | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'amountReceived'">
    <div class="received-container">
      <div
        *ngIf="
          element.procurementArea === ProcurementArea.Medicine ||
          element.procurementArea === ProcurementArea.Storage
        "
      >
        {{ totalReceivedQuantity }}/{{ element.quantity }}
      </div>
      <mat-icon *ngIf="totalReceivedQuantity === element.quantity">check_circle_outline</mat-icon>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'markAsReceived'">
    <button
      *ngIf="element.receivedQuantity !== element.quantity"
      mat-button
      (click)="buttonFunctions.markReceivedAmount()"
      matTooltip="{{ 'markAsReceivedButtonDescription' | translate }}"
      matTooltipClass="tooltip tall"
      matTooltipPosition="right"
    >
      <mat-icon>library_add_check</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'edit'">
    <button mat-button (click)="buttonFunctions.edit()">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'delete'">
    <button
      mat-button
      appConfirmDialog
      [confirmEntityName]="
        element?.medicine?.name ||
        element?.storageProduct?.name ||
        element?.supplierProduct?.name ||
        element?.product
      "
      (confirmed)="buttonFunctions.delete()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'createdBy'">
    <div>{{ element[columnName] | fullName }}</div>
    <div>{{ element.created | date : 'yyyy-MM-dd' }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'updatedBy'">
    <div>{{ element[columnName] | fullName }}</div>
    <div>{{ element.updated | date : 'yyyy-MM-dd' }}</div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
