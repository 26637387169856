import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { IdName } from '../shared/models/id-name';
import { ChangeCurrentSelectedTenant } from './state/tenant-user-access.actions';
import { TenantUserAccessState } from './state/tenant-user-access.state';
import { GlobalSearchForm } from '../core/forms/global-search-form';
import { MainModulesEnum } from '../core/enums/modules.enum';
import { getTenantId } from '../services/store-snapshot.service';
import {
  IDepartmentInfo,
  ITenantUserAccess,
} from '../shared/user-view/models/tenant-user-access.model';

@Component({
  selector: 'app-tenant-selector',
  templateUrl: './tenant-selector.component.html',
  styleUrls: ['./tenant-selector.component.scss'],
})
export class TenantSelectorComponent implements OnInit, OnDestroy {
  allTenants$: Observable<ITenantUserAccess[]> = this.store.select(
    TenantUserAccessState.getAllAvailableTenants
  );

  form = new GlobalSearchForm();
  searchableTenants: IdName[];
  filteredTenants: ITenantUserAccess[];
  allAvailableTenants: ITenantUserAccess[];
  mainModulesEnum = MainModulesEnum;
  currentTenantId = getTenantId();
  desktopMode: boolean;

  private subscription = new Subscription();

  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<TenantSelectorComponent>,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.breakpointObserver
        .observe(['(min-width: 650px)'])
        .subscribe((state: BreakpointState) => {
          this.desktopMode = state.matches;
        })
    );
    this.subscription.add(
      this.allTenants$.subscribe((availableTenants: ITenantUserAccess[]) => {
        if (availableTenants) {
          this.filteredTenants = availableTenants;
          this.allAvailableTenants = availableTenants;
          this.searchableTenants = [];
          this.searchableTenants = availableTenants.map((tenant: ITenantUserAccess) => ({
            id: tenant.id,
            name: tenant.name,
          }));
        }
      })
    );

    this.subscription.add(
      this.form.controls.searchTerm.valueChanges.subscribe((searchTerm: string) => {
        this.filteredTenants = this.allAvailableTenants;
        if (searchTerm) {
          this.filteredTenants = this.filteredTenants.filter((tenant: ITenantUserAccess) =>
            tenant.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
          this.allAvailableTenants.forEach((tenant: ITenantUserAccess) => {
            if (
              !this.filteredTenants.some(
                (filteredTenant: ITenantUserAccess) => filteredTenant.id === tenant.id
              )
            ) {
              const isAnyDepMatch = tenant.departmentInfos.some((dep: IDepartmentInfo) =>
                dep.name.toLowerCase().includes(searchTerm.toLowerCase())
              );
              if (isAnyDepMatch) {
                this.filteredTenants.push(tenant);
              }
            }
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectionChanged(tenant: ITenantUserAccess): void {
    this.store.dispatch(new ChangeCurrentSelectedTenant(tenant.id));
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
