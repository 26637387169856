<ng-container [ngSwitch]="module">
  <ng-container *ngSwitchCase="modules.Alerts">
    <!-- ALERTS related template -->
    <app-alert-template [columnName]="columnName" [element]="element"></app-alert-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Certificate">
    <!-- CERTIFICATE related template -->
    <app-certificate-template
      [columnName]="columnName"
      [element]="element"
    ></app-certificate-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Certificates">
    <!-- Certificates related template -->
    <app-certificate-template
      [columnName]="columnName"
      [element]="element"
    ></app-certificate-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Competence">
    <!-- COMPETENCE related template -->
    <app-competence-template
      [columnName]="columnName"
      [element]="element"
      [urlForRouting]="urlForRouting"
    ></app-competence-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.CrewManagement">
    <!-- CREW-MANAGEMENT related template -->
    <app-crew-management-template
      [columnName]="columnName"
      [element]="element"
    ></app-crew-management-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Departments">
    <!-- DEPARTMENT related templates -->
    <app-departments-templates
      [specificTemplateName]="specificTemplateName"
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
      [hideButtons]="hideButtons"
    ></app-departments-templates>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Documents">
    <ng-container *ngTemplateOutlet="documentTemplates"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Document">
    <ng-container *ngTemplateOutlet="documentTemplates"></ng-container>
  </ng-container>
  <ng-template #documentTemplates>
    <app-document-templates
      [specificTemplateName]="specificTemplateName"
      [columnName]="columnName"
      [element]="element"
    ></app-document-templates>
  </ng-template>
  <ng-container *ngSwitchCase="modules.Drills">
    <app-drills-templates
      [specificTemplateName]="specificTemplateName"
      [columnName]="columnName"
      [element]="element"
    ></app-drills-templates> </ng-container
  ><ng-container *ngSwitchCase="modules.Checklist">
    <app-task-template
      [specificTemplateName]="specificTemplateName"
      [columnName]="columnName"
      [element]="element"
    ></app-task-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.FormsLibrary">
    <!-- FORMS LIBRARY related template -->
    <app-forms-library-template
      [columnName]="columnName"
      [element]="element"
    ></app-forms-library-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.NonConformances">
    <!-- NON-CONFORMANCES related template -->
    <app-non-conformance-template
      [columnName]="columnName"
      [element]="element"
    ></app-non-conformance-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.NonConformance">
    <!-- NON-CONFORMANCE related template -->
    <app-non-conformance-template
      [columnName]="columnName"
      [element]="element"
    ></app-non-conformance-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Maintenance">
    <!-- MAINTENANCE related template -->
    <app-maintenance-templates
      [columnName]="columnName"
      [element]="element"
      [specificTemplateName]="specificTemplateName"
      [buttonFunctions]="buttonFunctions"
    ></app-maintenance-templates>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Profile">
    <!-- PROFILE related templates -->
    <app-profile-templates
      [specificTemplateName]="specificTemplateName"
      [columnName]="columnName"
      [element]="element"
      [urlForRouting]="urlForRouting"
    ></app-profile-templates>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Tenant">
    <app-tenant-templates
      [specificTemplateName]="specificTemplateName"
      [columnName]="columnName"
      [element]="element"
      [hideButtons]="hideButtons"
      [buttonFunctions]="buttonFunctions"
    ></app-tenant-templates>
  </ng-container>
  <ng-container *ngSwitchCase="modules.UsersAndPax">
    <!-- USERS related template -->
    <app-users-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
      [urlForRouting]="urlForRouting"
    ></app-users-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.CrewManagementUser">
    <!-- CREW-MANAGEMENT-USER related template -->
    <app-crew-management-user-templates
      [specificTemplateName]="specificTemplateName"
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
    ></app-crew-management-user-templates>
  </ng-container>
  <ng-container *ngSwitchCase="modules.OrderAdmin">
    <!-- ORDER-ADMIN related template -->
    <app-order-admin-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
    ></app-order-admin-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.OrderUser">
    <!-- ORDER-USER related template -->
    <app-order-user-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
    ></app-order-user-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.RunTime">
    <!-- RUN-TIME related template -->
    <app-runtime-template [columnName]="columnName" [element]="element"></app-runtime-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.DueList">
    <!-- DUE-LIST related template -->
    <app-due-list-template [columnName]="columnName" [element]="element"></app-due-list-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Medicines">
    <!-- MEDICINE related template -->
    <app-medicine-template [columnName]="columnName" [element]="element"></app-medicine-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Medicine">
    <!-- MEDICINE related template -->
    <app-medicine-template [columnName]="columnName" [element]="element"></app-medicine-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.Storage">
    <!-- STORAGE related template -->
    <app-storage-template [columnName]="columnName" [element]="element"></app-storage-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.ProductOwnerUsers">
    <!-- PRODUCT OWNER USERS related template -->
    <app-product-owner-users-template
      [columnName]="columnName"
      [element]="element"
    ></app-product-owner-users-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.RiskAssessment">
    <!-- RISK ASSESSMENT related template -->
    <app-risk-assessments-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
    ></app-risk-assessments-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.RiskAssessments">
    <!-- RISK ASSESSMENT related template -->
    <app-risk-assessments-template
      [columnName]="columnName"
      [element]="element"
      [buttonFunctions]="buttonFunctions"
    ></app-risk-assessments-template>
  </ng-container>
  <ng-container *ngSwitchCase="modules.PrivilegeTemplates">
    <!-- PRIVILEGE TEMPLATES related template -->
    <app-privilege-templates-template
      [columnName]="columnName"
      [element]="element"
    ></app-privilege-templates-template>
  </ng-container>
  <!-- QUANTITY TYPES related template -->
  <ng-container *ngSwitchCase="modules.QuantityTypes">
    <app-quantity-types-template
      [columnName]="columnName"
      [element]="element"
    ></app-quantity-types-template>
  </ng-container>
  <!-- DEFAULT fallback -->
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
