import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserRiskAssessmentRoutes } from 'src/app/core/enums/routes.enum';
import {
  RISK_ASSESSMENT_VALUE_DELETE_PRIVILEGE,
  RISK_ASSESSMENT_VALUE_UPDATE_PRIVILEGE,
  RiskAssessmentValueType,
} from 'src/app/risk-assessment-admin/enums/risk-assessment-value-type.enum';
import { ALERT_STATUS_TITLES } from 'src/app/risk-assessment-user/enums/alert-status.enum';
import { RiskAssessmentAction } from 'src/app/risk-assessment-user/enums/risk-assessment-action.enum';
import { TenantUserPrivilegesService } from 'src/app/tenant-selector/services/tenant-user-privileges.service';

@Component({
  selector: 'app-risk-assessments-template',
  templateUrl: './risk-assessments-template.component.html',
  styleUrls: ['./risk-assessments-template.component.scss'],
})
export class RiskAssessmentsTemplateComponent implements OnInit {
  @Input() columnName: string;
  @Input() element: any;
  @Input() buttonFunctions: { [key: string]: () => void };
  @Input() valueType: RiskAssessmentValueType = this.activatedRoute.snapshot.data.valueType;

  trackBy = (index: number) => index;
  id = this.activatedRoute.snapshot.params.id;

  UserRiskAssessmentRoutes = UserRiskAssessmentRoutes;
  RiskAssessmentAction = RiskAssessmentAction;
  ALERT_STATUS_TITLES = ALERT_STATUS_TITLES;
  hasCreatePermimssion: boolean;
  hasUpdatePermission: boolean;
  hasDeletePermission: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private tenantUserPrivileges: TenantUserPrivilegesService
  ) {}

  ngOnInit(): void {
    this.hasCreatePermimssion =
      this.tenantUserPrivileges.userHasPrivilege('RISK-ASSESSMENT_CREATE');
    this.hasUpdatePermission = this.tenantUserPrivileges.userHasPrivilege(
      RISK_ASSESSMENT_VALUE_UPDATE_PRIVILEGE[this.valueType]
    );
    this.hasDeletePermission = this.tenantUserPrivileges.userHasPrivilege(
      RISK_ASSESSMENT_VALUE_DELETE_PRIVILEGE[this.valueType]
    );
  }
}
