import { IdName } from 'src/app/shared/models/id-name';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { getDepartmentId, getTenantId } from 'src/app/services/store-snapshot.service';
import { environment } from 'src/environments/environment';
import { IJobType } from '../models/job-type.model';

const headers = { 'content-type': 'application/json' };

@Injectable({
  providedIn: 'root',
})
export class JobTypeApiService {
  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/MaintenanceJobs/Types`;
  }

  constructor(private http: HttpClient) {}

  loadJobTypes(currentFilter?: MainTableFilterModel): Observable<ApiResponse<IJobType[]>> {
    let params = new HttpParams();
    if (currentFilter) {
      params = params.append('Page', currentFilter.pageIndex.toString());
      params = params.append('PageSize', currentFilter.pageSize.toString());

      if (currentFilter.searchTerm) {
        params = params.append('GlobalSearch', currentFilter.searchTerm);
      }
      if (currentFilter.orderBy && currentFilter.ascDesc) {
        params = params.append(`OrderBy[${currentFilter.orderBy}]`, currentFilter.ascDesc);
      }
    }
    return this.http.get<ApiResponse<IJobType[]>>(this.url, { params });
  }

  loadAllJobTypes(): Observable<IdName[]> {
    const url = `${this.url}/List`;
    return this.http.get<IdName[]>(url);
  }

  loadJobTypeById(id: string): Observable<IJobType> {
    const url = `${this.url}/${id}`;
    return this.http.get<IJobType>(url);
  }

  createJobType(jobType: IJobType): Observable<IJobType> {
    return this.http.post<IJobType>(this.url, jobType, { headers });
  }

  updateJobType(jobType: IJobType): Observable<IJobType> {
    const url = `${this.url}/${jobType.id}`;
    return this.http.put<IJobType>(url, jobType, { headers });
  }

  deleteJobType(id: string): Observable<void> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }
}
