import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AdminAlertRoutes } from 'src/app/core/enums/routes.enum';
import { LoadModules } from 'src/app/core/state/module.actions';
import { ModuleState } from 'src/app/core/state/module.state';
import { HasForm } from 'src/app/shared/guards/unsaved-data.guard';
import { IdName } from 'src/app/shared/models/id-name';
import { AlertForm } from '../../forms/alert-form';
import { IAlertResponse } from '../../models/alert.model';
import { CreateAlert, DeleteAlert, UpdateAlert } from '../../state/alert.actions';
import { AlertState } from '../../state/alert.state';

@Component({
  selector: 'app-alert-add-edit',
  templateUrl: './alert-add-edit.component.html',
  styleUrls: ['./alert-add-edit.component.scss'],
})
export class AlertAddEditComponent implements OnInit, OnDestroy, HasForm {
  getModules$: Observable<IdName[]> = this.store.select(ModuleState.getModules);

  form: AlertForm;
  modules: IdName[];
  adminAlertRoutes = AdminAlertRoutes;
  actions = [CreateAlert, DeleteAlert, UpdateAlert];

  private subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private store: Store) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }

    this.subscription.unsubscribe();

    if (this.form.id.value) {
      this.store.dispatch(new UpdateAlert(this.form.value));
    } else {
      this.store.dispatch(new CreateAlert(this.form.value));
    }
  }

  delete(): void {
    this.store.dispatch(new DeleteAlert(this.form.id.value));
  }

  setDaysValidation(): void {
    if (this.form.days.value) {
      this.form.controls.redAlertDays.enable();
      this.form.controls.yellowAlertDays.enable();
    } else {
      this.form.controls.redAlertDays.disable();
      this.form.controls.yellowAlertDays.disable();
    }
  }

  setHoursValidation(): void {
    if (this.form.hours.value) {
      this.form.controls.redAlertHours.enable();
      this.form.controls.yellowAlertHours.enable();
    } else {
      this.form.controls.redAlertHours.disable();
      this.form.controls.yellowAlertHours.disable();
    }
  }

  private init(): void {
    this.subscription = this.store
      .dispatch(new LoadModules())
      .pipe(
        switchMap(() => this.getModules$),
        switchMap((modules: IdName[]) => {
          this.modules = modules;
          return this.activatedRoute.params;
        }),
        filter((params: Params) => {
          if (params.id === 'new') {
            this.form = new AlertForm();
            this.setDaysValidation();
            this.setHoursValidation();
            return false;
          }
          return true;
        }),
        switchMap((params: Params) => {
          return this.store
            .select(AlertState.getAlertById)
            .pipe(map((filterFn) => filterFn(params.id)));
        }),
        tap((alert: IAlertResponse) => {
          if (alert?.id) {
            this.form = new AlertForm(alert);

            const moduleIds = this.modules
              .filter((module: IdName) =>
                this.form.modules.value?.some((m: string) => m === module.name)
              )
              .map((filteredModule: IdName) => filteredModule.id);

            this.form.controls.modules.setValue(moduleIds);
            this.setDaysValidation();
            this.setHoursValidation();
            this.form.disable();
          }
        })
      )
      .subscribe();
  }
}
