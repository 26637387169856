import { Store } from '@ngxs/store';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Animations } from 'src/app/core/angular-animations/angular-animations';
import { MenuItemModel } from 'src/app/core/models/menu-id.model';
import { LoadSpecificMenuStructure } from 'src/app/core/state/menu-tree-structure.actions';
import { ModuleNumbers } from 'src/app/core/enums/module-numbers.enum';
import { DndDragImageOffsetFunction, DndDropzoneDirective } from 'ngx-drag-drop';
import { Subscription } from 'rxjs';
import { MenuDragDropService } from '../menu-drag-drop.service';

@Component({
  selector: 'app-menu-tree-single',
  templateUrl: './menu-tree-single.component.html',
  styleUrls: ['./menu-tree-single.component.scss'],
  animations: [Animations.toggleContainer, Animations.rotatedState],
})
export class MenuTreeSingleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() item: MenuItemModel;
  @Input() onlyAdd = false;
  @Input() showFunctionalButtons = true;
  @Input() menuItemsAsRouting = false;
  @Input() mainModules = false;
  @Input() tenantId: string;
  @Input() module: number;
  @Input() nextItem?: MenuItemModel;
  @Input() isLast = false;
  @Input() moduleNumber: ModuleNumbers;
  @Input() chosenElementId = '';
  @Input() isTopItem = false;
  @ViewChildren(DndDropzoneDirective) dropZones: QueryList<DndDropzoneDirective>;
  @ViewChild('tdzEl', { read: DndDropzoneDirective }) tdz: DndDropzoneDirective;
  @ViewChild('bdzEl', { read: DndDropzoneDirective }) bdz: DndDropzoneDirective;
  @ViewChild('dzEl', { read: DndDropzoneDirective }) dz: DndDropzoneDirective;

  @Output() readonly removeParent: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly chosenElementClicked: EventEmitter<string> = new EventEmitter<string>();

  hasChildren = false;
  showChildren = false;
  isChosen = false;

  // eslint-disable-next-line no-magic-numbers
  truncateThreshold = 75;
  private subscription = new Subscription();
  constructor(private store: Store, public menuDNDService: MenuDragDropService) {
    this.subscription.add(
      this.menuDNDService.dragStopped.subscribe(() => {
        this.resetDropzones();
      })
    );
  }

  ngOnInit(): void {
    this.isElementChosen();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasChildren = this.checkIfRowHasChildren();
    this.isElementChosen();
  }

  ngOnDestroy(): void {
    this.chosenElementClicked.emit('');
  }

  toggleDropdown(): void {
    this.showChildren = !this.showChildren;
  }

  routeTo(): void {
    if (this.menuItemsAsRouting) {
      this.store.dispatch([
        new LoadSpecificMenuStructure(this.module, this.item.name, this.item.id),
      ]);
      this.chosenElementClicked.emit(this.item.id);
    }
  }

  reactOnShowChildrenChange(showChildren: boolean): void {
    this.showChildren = showChildren;
    this.hasChildren = this.checkIfRowHasChildren();
  }

  emitElementClicked(itemId: string): void {
    this.chosenElementClicked.emit(itemId);
  }

  trackById(index: number, item: MenuItemModel) {
    return item.id;
  }

  dragItemOffsetRight: DndDragImageOffsetFunction = (event: DragEvent, dragItem: Element) => {
    const itemStyle = window.getComputedStyle(dragItem);
    const paddingTop = parseFloat(itemStyle.paddingTop) || 0;
    const paddingLeft = parseFloat(itemStyle.paddingLeft) || 0;
    const borderTop = parseFloat(itemStyle.borderTopWidth) || 0;
    const borderLeft = parseFloat(itemStyle.borderLeftWidth) || 0;

    const x = dragItem.clientWidth - (event.offsetX + paddingLeft + borderLeft);
    return {
      x,
      y: event.offsetY + paddingTop + borderTop,
    };
  };

  onDragStart(event: DragEvent, item: MenuItemModel): void {
    this.menuDNDService.activeItem = item;
  }

  onDragEnd(): void {
    this.resetDropzones();
    this.menuDNDService.activeItem = null;
  }

  onDrop(placement: 'inside' | 'bottom' | 'top'): void {
    this.menuDNDService.onDrop(this.item, placement);
  }

  resetDropzone(dropZone: DndDropzoneDirective): void {
    dropZone?.onDragLeave(new DragEvent('dragleave'));
  }

  resetDropzones(): void {
    this.dropZones.forEach((dropZone) => {
      this.resetDropzone(dropZone);
    });
  }

  resetOtherDropzones(dropZoneToReset: DndDropzoneDirective): void {
    this.dropZones.forEach((dropZone) => {
      if (dropZoneToReset !== dropZone) dropZone.onDragLeave(new DragEvent('dragleave'));
    });
  }

  private isElementChosen(): void {
    this.isChosen = this.isElementChosenRecursively(this.item);
  }

  private checkIfRowHasChildren(): boolean {
    return this.item.children && this.item.children.length > 0;
  }

  // recursively check if an item or any of its children is chosen
  private isElementChosenRecursively(item: MenuItemModel): boolean {
    if (item.id === this.chosenElementId) return true;
    if (item.children && item.children.length > 0) {
      return item.children.some((child) => this.isElementChosenRecursively(child));
    }
    return false;
  }
}
