<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'edit'">
    <button
      mat-button
      (click)="buttonFunctions.edit()"
      [disabled]="!hasUpdatePermission"
      [matTooltip]="hasUpdatePermission ? '' : ('noAccess' | translate)"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'delete'">
    <button
      mat-button
      (click)="buttonFunctions.delete()"
      [disabled]="!hasDeletePermission"
      [matTooltip]="hasDeletePermission ? '' : ('noAccess' | translate)"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'actionDoneBy'">
    <div class="row">
      <mat-icon>perm_identity</mat-icon>
      <p class="text">{{ element.actionDoneBy | fullName }}</p>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'actionDoneAt'">
    {{ element.actionDoneAt | date : 'yyyy-MM-dd HH:mm' }}
  </ng-container>
  <ng-container *ngSwitchCase="'nextExpiry'">
    {{ element.nextExpiry | date : 'yyyy-MM-dd' }}
  </ng-container>
  <ng-container *ngSwitchCase="'lastPerformed'">
    {{ element.lastPerformed | date : 'yyyy-MM-dd HH:mm' }}
  </ng-container>
  <ng-container *ngSwitchCase="'action'">
    {{ element.action | mapRiskAssessmentAction | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'view'">
    <ng-container
      *ngIf="
        element.action === RiskAssessmentAction.Perform ||
        element.action === RiskAssessmentAction.EditAndPerform
      "
    >
      <app-ui-button
        buttonType="secondary"
        text="{{ 'view' | translate }}"
        [routerLink]="UserRiskAssessmentRoutes.Overview + '/' + id + '/version/' + element.id"
      >
      </app-ui-button>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'createFromTemplate'">
    <app-ui-button
      class="create-from-template-button"
      buttonType="secondary"
      [routerLink]="UserRiskAssessmentRoutes.Template + '/' + element.id"
      icon="add"
      text=""
      matTooltipClass="tooltip"
      [disabled]="!hasCreatePermimssion"
      [matTooltip]="
        hasCreatePermimssion
          ? ('createRiskAssessmentFromTemplateDescription' | translate)
          : ('noAccess' | translate)
      "
    >
    </app-ui-button>
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill [name]="ALERT_STATUS_TITLES[element[columnName]]"></app-ui-pill>
  </ng-container>
  <ng-container *ngSwitchCase="'id'">
    {{ element.riskAssessmentId }}
  </ng-container>
  <ng-container *ngSwitchCase="'access'">
    <app-positions-cell [element]="element"></app-positions-cell>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>
</ng-container>
