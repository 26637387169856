/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { RiskAssessmentValueType } from '../enums/risk-assessment-value-type.enum';

export class LoadRiskAssessmentValues {
  static readonly type = '[Risk Assessment Value] Load Risk Assessment Values';
  constructor(public valueType: RiskAssessmentValueType, public filter?: MainTableFilterModel) {}
}

export class CreateRiskAssessmentValue {
  static readonly type = '[Risk Assessment Value] Create Risk Assessment Value';
  constructor(
    public valueType: RiskAssessmentValueType,
    public name: string,
    public filter?: MainTableFilterModel
  ) {}
}

export class UpdateRiskAssessmentValue {
  static readonly type = '[Risk Assessment Value] Update Risk Assessment Value';
  constructor(
    public valueType: RiskAssessmentValueType,
    public id: string,
    public name: string,
    public filter?: MainTableFilterModel
  ) {}
}

export class LoadRiskAssessmentValueById {
  static readonly type = '[Risk Assessment Value] Load Risk Assessment Value By Id';
  constructor(public valueType: RiskAssessmentValueType, public id: string) {}
}

export class DeleteRiskAssessmentValue {
  static readonly type = '[Risk Assessment Value] Delete Risk Assessment Value';
  constructor(
    public valueType: RiskAssessmentValueType,
    public id: string,
    public filter?: MainTableFilterModel
  ) {}
}

export class LoadAllHazards {
  static readonly type = '[Risk Assessment Value] Load All Hazards';
  constructor() {}
}

export class LoadAllConsequences {
  static readonly type = '[Risk Assessment Value] Load All Consequences';
  constructor() {}
}

export class LoadAllLocations {
  static readonly type = '[Risk Assessment Value] Load All Locations';
  constructor() {}
}

export class LoadAllLocationsPerDepartment {
  static readonly type = '[Risk Assessment Value] Load All Locations Per Department';
  constructor(public departmentId: string) {}
}

export class LoadAllOperationTypes {
  static readonly type = '[Risk Assessment Value] Load All Operation Types';
  constructor() {}
}

export class LoadAllMitigatingMeasures {
  static readonly type = '[Risk Assessment Value] Load All Mitigating Measures';
  constructor() {}
}

export class PrintRiskAssessment {
  static readonly type = '[Risk Assessment Value] Print Risk Assessment';
  constructor(public id: string) {}
}
