<div class="top-container" mat-dialog-title>
  <h2 class="title">
    {{ title | translate }} <span>{{ entityNameTitle }}</span>
  </h2>
  <mat-icon *ngIf="showCloseButton" class="pointer-cursor" [mat-dialog-close]="false"
    >close</mat-icon
  >
</div>
<div *ngIf="subtitle" class="top-container">
  <h4>{{ subtitle }}</h4>
</div>
<ng-content></ng-content>

<div mat-dialog-actions class="buttons-container">
  <div *ngIf="form && form.disabled">
    <app-ui-button
      color="primary"
      text="{{ 'edit' | translate }}"
      (click)="edit()"
      [privilege]="editPrivilege"
    >
    </app-ui-button>
    <app-ui-button
      color="warn"
      buttonType="stroked"
      text="{{ 'delete' | translate }}"
      (click)="delete()"
      [privilege]="deletePrivilege"
    >
    </app-ui-button>
  </div>
  <div *ngIf="!form || form.enabled">
    <app-ui-button
      *ngIf="showCancelButton"
      buttonType="secondary"
      text="{{ 'cancel' | translate }}"
      [mat-dialog-close]="false"
    >
    </app-ui-button>
    <app-ui-button
      [buttonType]="confirmButtonType"
      [color]="confirmButtonColor"
      text="{{ confirmButtonText | translate }}"
      (click)="confirm()"
      [privilege]="createPrivilege"
    >
    </app-ui-button>
  </div>
</div>
