import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { getTenantId, getDepartmentId } from 'src/app/services/store-snapshot.service';
import { ALL_DEPS_TOKEN } from 'src/app/tenant-selector/state/tenant-user-access.state';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { ApiResponse } from 'src/app/core/models/api-response';
import { environment } from '../../../environments/environment';
import { IDueListItem } from '../models/due-list-item.model';
import { IDueListPrintRequest } from '../models/due-list-print-request.model';

@Injectable({
  providedIn: 'root',
})
export class DueListApiService {
  headers = { 'content-type': 'application/json' };

  private get url(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/${getDepartmentId()}/dueList`;
  }
  private get dueListUrl(): string {
    return `${environment.apiUrl}/api/${getTenantId()}/dueList`;
  }

  constructor(private http: HttpClient) {}

  getDueListWarnings(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IDueListItem[]>> {
    const depId = getDepartmentId();
    if (depId === null || depId === undefined || depId === '') {
      return of();
    }
    let url = `${this.url}/warnings`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    return this.http.get<ApiResponse<IDueListItem[]>>(url);
  }

  getDueListDueCompetencies(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IDueListItem[]>> {
    let url = `${this.dueListUrl}/competences`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    return this.http.get<ApiResponse<IDueListItem[]>>(url);
  }

  getMyDueListWarnings(
    currentFilter?: MainTableFilterModel
  ): Observable<ApiResponse<IDueListItem[]>> {
    const depId = getDepartmentId();
    if (depId === null || depId === undefined || depId === '') {
      return of();
    }
    let url = `${this.dueListUrl}/myWarnings`;
    if (currentFilter) {
      url = `${url}?Page=${currentFilter.pageIndex}&PageSize=${currentFilter.pageSize}`;
    }
    if (currentFilter?.searchTerm) {
      url = `${url}&GlobalSearch=${currentFilter.searchTerm}`;
    }
    return this.http.get<ApiResponse<IDueListItem[]>>(url);
  }

  print(request?: IDueListPrintRequest): Observable<Blob> {
    const currentDepartmentId = getDepartmentId();
    let printUrl = '';
    if (currentDepartmentId === ALL_DEPS_TOKEN) {
      printUrl = `${this.dueListUrl}/MyWarnings/print/pdf`;
    } else {
      printUrl = `${this.url}/warnings/print/pdf`;
    }
    const object: any = { recordsToPrint: [] };
    request?.recordsToPrint.forEach((value, index) => {
      object.recordsToPrint[`${index}`] = value;
    });
    return this.http.post(printUrl, object, { responseType: 'blob' });
  }
}
