<!-- TODO Illia: unused template, remove it -->
<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchDefault>
    <app-default-cell [element]="element" [columnName]="columnName"></app-default-cell>
  </ng-container>

  <ng-container *ngSwitchCase="'id'">
    {{ element['number'] }}
  </ng-container>

  <ng-container *ngSwitchCase="'createdBy'">
    <div class="flex">
      <mat-icon>perm_identity</mat-icon>
      <p class="text">{{ element[columnName]?.firstName }} {{ element[columnName]?.lastName }}</p>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'updatedBy'">
    <div class="flex">
      <mat-icon>perm_identity</mat-icon>
      <p class="text">{{ element[columnName]?.firstName }} {{ element[columnName]?.lastName }}</p>
    </div>
  </ng-container>
</ng-container>
