import { Directive, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

@Directive()
export abstract class AbstractTableModal<F extends FormGroup, M> {
  form: F;
  constructor(
    public dialogRef: MatDialogRef<M>,
    @Inject(MAT_DIALOG_DATA)
    public data: { form: F; [key: string]: any }
  ) {
    this.form = data.form;
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form);
    } else {
      this.form.updateValueAndValidity();
    }
  }
}
