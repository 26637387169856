import { getTenantId } from 'src/app/services/store-snapshot.service';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdName } from 'src/app/shared/models/id-name';
import { ApiResponse } from 'src/app/core/models/api-response';
import { AddFormsLibraryModel } from '../models/add-forms-library.model';
import { CustomFormModelForList } from '../models/custom-form-for-list.model';
import { AddCustomFormModel } from '../models/add-custom-form.model';
import { ICustomFormBasicsModel } from '../models/custom-form-basics.model';
import { environment } from '../../../environments/environment';
import { IFormsLibraryModel } from '../models/forms-library.model';
import { UpdateFormsLibraryModel } from '../models/update-forms-library.model';
import { UpdateCustomFormModel } from '../models/update-custom-form.model';
import { ICustomFormModel } from '../models/custom-form.model';
import { ICustomFormDefinitionModel } from '../../shared/custom-forms-responses/models/custom-form-definition.model';
import { UpsertCustomFormDefinitionModel } from '../models/upsert-custom-form-definition.model';

@Injectable({
  providedIn: 'root',
})
export class FormsLibraryApiService {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };
  private readonly baseUrl = `${environment.apiUrl}/api`;

  private get formsLibraryUrl() {
    return `${this.baseUrl}/${getTenantId()}/FormsLibrary`;
  }

  private get customFormUrl(): string {
    return `${this.baseUrl}/${getTenantId()}/CustomForm`;
  }

  constructor(private httpClient: HttpClient) {}

  getAllFormsLibraries(): Observable<ApiResponse<IFormsLibraryModel[]>> {
    return this.httpClient.get<ApiResponse<IFormsLibraryModel[]>>(
      `${this.formsLibraryUrl}?Page=0&PageSize=10000`,
      this.httpOptions
    );
  }

  getFormsLibraryById(libraryId: string): Observable<IFormsLibraryModel> {
    const url = `${this.formsLibraryUrl}/${libraryId}`;
    return this.httpClient.get<IFormsLibraryModel>(url, this.httpOptions);
  }

  createFormsLibrary(formLibrary: AddFormsLibraryModel): Observable<IFormsLibraryModel> {
    return this.httpClient.post<IFormsLibraryModel>(
      this.formsLibraryUrl,
      formLibrary,
      this.httpOptions
    );
  }

  deleteFormsLibrary(id: string): Observable<IFormsLibraryModel> {
    const url = `${this.formsLibraryUrl}/${id}`;
    return this.httpClient.delete<IFormsLibraryModel>(url, this.httpOptions);
  }

  updateFormsLibrary(formLibrary: UpdateFormsLibraryModel): Observable<IFormsLibraryModel> {
    return this.httpClient.put<IFormsLibraryModel>(
      this.formsLibraryUrl,
      formLibrary,
      this.httpOptions
    );
  }

  getCustomFormById(id: string): Observable<ICustomFormModel> {
    const url = `${this.customFormUrl}/${id}`;
    return this.httpClient.get<ICustomFormModel>(url, this.httpOptions);
  }

  getCustomFormsByModuleId(moduleId: number): Observable<IdName[]> {
    const url = `${this.customFormUrl}/completed/${moduleId}`;
    return this.httpClient.get<IdName[]>(url, this.httpOptions);
  }

  createCustomForm(customForm: AddCustomFormModel): Observable<CustomFormModelForList> {
    return this.httpClient.post<CustomFormModelForList>(
      this.customFormUrl,
      customForm,
      this.httpOptions
    );
  }

  updateCustomForm(customForm: UpdateCustomFormModel): Observable<ICustomFormBasicsModel> {
    return this.httpClient.put<ICustomFormBasicsModel>(
      this.customFormUrl,
      customForm,
      this.httpOptions
    );
  }

  upsertCustomFormDefinition(model: UpsertCustomFormDefinitionModel): Observable<null> {
    const url = `${this.customFormUrl}/definition/upsert/${model.formId}`;
    return this.httpClient.put<null>(url, model, this.httpOptions);
  }

  deleteCustomForm(formId: string): Observable<IFormsLibraryModel> {
    const url = `${this.customFormUrl}/${formId}`;
    return this.httpClient.delete<IFormsLibraryModel>(url, this.httpOptions);
  }

  getCustomFormStatuses(): Observable<IdName[]> {
    const url = `${this.baseUrl}/Options/CustomFormStatuses`;
    return this.httpClient.get<IdName[]>(url, this.httpOptions);
  }

  getCustomFormDefinitionByFormId(formId: string): Observable<ICustomFormDefinitionModel> {
    const url = `${this.customFormUrl}/definition/${formId}`;
    return this.httpClient.get<ICustomFormDefinitionModel>(url, this.httpOptions);
  }
}
