/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IListDocuments } from 'src/app/document-admin/models/list-documents.model';
import { IDocumentRequest } from '../models/document.model';
import { IDocumentSettingsRequest } from '../models/document-settings.model';
import { DocumentStatus } from '../models/document-status.enum';
import { DocumentAvailability } from '../enums/document-availability.enum';
import { DocumentPrintingLogoType } from '../models/document-printing-logo-type.enum';

export class LoadDocuments {
  static readonly type = '[Documents] Load Documents via Service';
  constructor(public filter?: MainTableFilterModel, public query?: IListDocuments) {}
}

export class LoadDocumentsByMenu {
  static readonly type = '[Documents] Load Documents By Menu via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadDocumentById {
  static readonly type = '[Document] Load a single document';
  constructor(
    public documentId: string,
    public includeDeleted?: boolean,
    public forcedVersionId?: string
  ) {}
}

export class LoadDocumentsForIO {
  static readonly type = '[Documents] Load Documents for IO via Service';
  constructor() {}
}

export class CreateDocument {
  static readonly type = '[Document] Create document';
  constructor(public document: IDocumentRequest) {}
}

export class UpdateDocument {
  static readonly type = '[Document] Update document';
  constructor(public document: IDocumentRequest) {}
}

export class DeleteDocument {
  static readonly type = '[Document] Delete document';
  constructor(public documentId: string) {}
}

export class LoadDocumentVersions {
  static readonly type = '[Document] Load a single document versions';
  constructor(public documentId: string, public includeDeleted?: boolean) {}
}

export class LoadDocumentVersion {
  static readonly type = `[Document] Load document's single version`;
  constructor(
    public documentId: string,
    public documentVersionId: string,
    public includeDeleted?: boolean
  ) {}
}

export class PrintDocumentTree {
  static readonly type = `[Document] Print document tree`;
  constructor(
    public menuId: string,
    public tableOfContents: boolean,
    public printLogoType: DocumentPrintingLogoType,
    public useLargeTenantLogo: boolean,
    public includeDocumentId: boolean,
    public filter: MainTableFilterModel,
    public statuses?: (DocumentStatus | undefined)[],
    public documentAvailability?: DocumentAvailability
  ) {}
}

export class PrintDocument {
  static readonly type = `[Document] Print single document`;
  constructor(
    public menuId: string,
    public documentId: string,
    public tableOfContents: boolean,
    public printLogoType: DocumentPrintingLogoType,
    public useLargeTenantLogo: boolean,
    public includeDocumentId: boolean,
    public forcedVersionId?: string
  ) {}
}

export class PrintTextControlOnly {
  static readonly type = `[Document] Print Text Control only`;
  constructor(public documentId: string) {}
}

export class ApproveDocument {
  static readonly type = `[Document] Approve Document`;
  constructor(
    public documentId: string,
    public menuId: string,
    public approvalStageId?: string,
    public comment?: string
  ) {}
}

export class RejectDocument {
  static readonly type = `[Document] Reject Document`;
  constructor(
    public documentId: string,
    public menuId: string,
    public approvalStageId?: string,
    public comment?: string
  ) {}
}

export class EnableDocument {
  static readonly type = `[Document] Enable Document`;
  constructor(public documentId: string, public menuId: string) {}
}

export class DisableDocument {
  static readonly type = `[Document] Disable Document`;
  constructor(public documentId: string, public menuId: string) {}
}

export class RestoreDocument {
  static readonly type = `[Document] Restore Document`;
  constructor(public documentId: string, public menuId: string) {}
}

export class UpdateDocumentSettings {
  static readonly type = `[Document] Update Document Settings`;
  constructor(public documentSettings: IDocumentSettingsRequest) {}
}

export class LoadDocumentSettings {
  static readonly type = `[Document] Get Document Settings`;
  constructor() {}
}

export class RequestChangeOnDocument {
  static readonly type = `[Document] Create Document Change Request`;
  constructor(public documentId: string, public description: string, public menuId?: string) {}
}

export class AcceptDocumentChangeRequest {
  static readonly type = `[Document] Accept Document Change Request`;
  constructor(
    public documentId: string,
    public reviewComment: string,
    public id?: string,
    public menuId?: string
  ) {}
}

export class RejectDocumentChangeRequest {
  static readonly type = `[Document] Reject Document Change Request`;
  constructor(
    public documentId: string,
    public reviewComment: string,
    public id?: string,
    public menuId?: string
  ) {}
}

export class LoadDocumentCounts {
  static readonly type = `[Document] Load Document Counts`;
  constructor(public menuId?: string) {}
}
