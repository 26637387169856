<div class="main-hashtag-chip-container">
  <app-ui-chip
    *appLoader="!!!(hashtagsLoaded$ | async)"
    label="{{ 'hashtagAdd' | translate }}"
    [items]="hashtags"
    [disabled]="disabled"
    [selectedItems]="chosenHashtags | mapIdNameToString"
    (itemAdded)="addHashtagToList($event)"
    (itemRemoved)="removeHashtagFromList($event)"
  >
  </app-ui-chip>
</div>
