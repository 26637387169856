import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privilege-templates-template',
  templateUrl: './privilege-templates-template.component.html',
})
export class PrivilegeTemplatesTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  constructor(private router: Router) {}
}
