<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchDefault>
    {{ element[columnName] ?? '---' }}
  </ng-container>
  <ng-container *ngSwitchCase="'expiryType'">
    <mat-icon *ngIf="element.expiryType === 'IntervalExpiry' || element.expiryType === 'Both'"
      >schedule</mat-icon
    >
    <mat-icon *ngIf="element.expiryType === 'RuntimeExpiry' || element.expiryType === 'Both'"
      >event_repeat</mat-icon
    >
    <ng-container *ngIf="!element.expiryType">---</ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'location'">
    {{ element?.menu?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'edit'">
    <button mat-button (click)="buttonFunctions.edit()">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'delete'">
    <button mat-button (click)="buttonFunctions.delete()">
      <mat-icon>delete</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'quantity'">
    {{ element?.quantity }} {{ element?.quantityType?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'usedQuantity'">
    {{ element?.usedQuantity }} {{ element?.quantityType?.name }}
  </ng-container>

  <ng-container *ngSwitchCase="'quantityType'">
    {{ element?.quantityType?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'dueDateRuntime'">
    {{ element?.dueDateOrRuntime?.intervalExpiry | date : 'yyyy-MM-dd' }}
    <ng-container *ngIf="element?.dueDateOrRuntime?.runtimeExpiry?.hours">
      | {{ element?.dueDateOrRuntime?.runtimeExpiry?.hours }} h
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'interval'">
    <ng-container *ngIf="element.expiryType === 'IntervalExpiry' || element.expiryType === 'Both'">
      {{ element?.interval?.intervalExpiry }}d
    </ng-container>
    <ng-container *ngIf="element.expiryType === 'Both'">/</ng-container>
    <ng-container *ngIf="element.expiryType === 'RuntimeExpiry' || element.expiryType === 'Both'">
      {{ element?.interval?.runtimeExpiry }}h
    </ng-container>
    <ng-container *ngIf="!element.expiryType">---</ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'timeUntilExpiry'">
    <ng-container *ngIf="element.expiryType === 'IntervalExpiry' || element.expiryType === 'Both'">
      {{ element?.timeUntilExpiry?.intervalExpiry | number : '1.0-0' }}d
    </ng-container>
    <ng-container *ngIf="element.expiryType === 'Both'">or</ng-container>
    <ng-container *ngIf="element.expiryType === 'RuntimeExpiry' || element.expiryType === 'Both'">
      {{ element?.timeUntilExpiry?.runtimeExpiry.timeUntilExpiry }}h at
      {{ element?.timeUntilExpiry?.runtimeExpiry.avgDailyRuntime }}h/d
    </ng-container>
    <ng-container *ngIf="!element.expiryType">---</ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'dueDateOrRuntime'">
    <!-- TODO Illia: Runtime after expiry will show the Runtime Count at expiry in the same manner as with only Runtime Expiry -->
    <ng-container *ngIf="element.expiryType === 'Both'">{{
      expiryDate | date : 'yyyy-MM-dd'
    }}</ng-container>
    <ng-container *ngIf="element.expiryType === 'IntervalExpiry'">
      {{ element?.dueDateOrRuntime?.intervalExpiry | date : 'yyyy-MM-dd' }}
    </ng-container>
    <ng-container *ngIf="element.expiryType === 'RuntimeExpiry'">
      {{ element?.dueDateOrRuntime?.runtimeExpiry.dueDate | date : 'yyyy-MM-dd' }}
    </ng-container>
    <ng-container *ngIf="!element.expiryType">---</ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    <app-ui-pill *ngIf="element[columnName]" [name]="element[columnName]"></app-ui-pill>
  </ng-container>
  <ng-container *ngSwitchCase="'access'">
    <app-positions-cell [element]="element"></app-positions-cell>
  </ng-container>
  <ng-container *ngSwitchCase="'restore'">
    <app-ui-button
      appConfirmDialog
      confirmTitle="restore"
      confirmMessage="youAreRestoring"
      [confirmEntityName]="element.name"
      (confirmed)="restore()"
      buttonType="secondary"
      text="{{ 'restore' | translate }}"
      appStopClickPropagation
      privilege="MAINTENANCE-JOB_RESTORE"
    >
    </app-ui-button>
  </ng-container>
</ng-container>
