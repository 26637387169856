import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EngineForm } from '../../../../../department/forms/vessel-engine.form';
import { PowerUnit } from '../../../../enums/enums';
import { AbstractDepartmentTemplateComponent } from './abstract-department-template-component.directive';

@Component({
  selector: 'app-engine-template',
  templateUrl: './engine-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineTemplateComponent extends AbstractDepartmentTemplateComponent<EngineForm> {
  powerUnit = PowerUnit;
}
