import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays, endOfDay } from 'date-fns';

@Pipe({ name: 'daysBeforeExpired', pure: true, standalone: true })
export class DaysBeforeExpiredPipe implements PipeTransform {
  transform(date?: Date): number | null {
    if (!date) return null;

    const warningEndOfDate = endOfDay(new Date(date));
    const todayEndOfDate = endOfDay(new Date());
    return differenceInDays(warningEndOfDate, todayEndOfDate);
  }
}
