import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-component-history-template',
  templateUrl: './component-history-template.component.html',
  styleUrls: ['./component-history-template.component.scss'],
})
export class ComponentHistoryTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;
}
