/* eslint-disable no-underscore-dangle */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { IdName } from '../../models/id-name';

@Component({
  selector: 'app-ui-table-selection',
  templateUrl: './ui-table-selection.component.html',
  styleUrls: ['./ui-table-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTableSelectionComponent<T extends IdName> implements OnChanges, OnDestroy {
  @Input() set displayedColumns(columns: string[]) {
    this._displayedColumns = [this.selectColName, ...columns];
  }
  @Input() set dataSource(data: T[] | (undefined | null)) {
    if (
      data?.length &&
      this._dataSource?.filteredData?.length &&
      data[0].id !== this._dataSource.filteredData[0].id
    ) {
      this.selection.clear();
    }
    if (data) {
      this._dataSource = new MatTableDataSource<T>(data);
    }
  }
  @Input() set control(control: FormControl<string[]>) {
    this._control = control;
    this.subscription.add(
      this.selection.changed.subscribe(() => {
        this._control.setValue(this.selection.selected.map((selected: T) => selected.id));
        this._control.updateValueAndValidity();
        if (this.controlValuesInitialized) {
          this._control.markAsDirty();
        }
        this.controlValuesInitialized = true;
      })
    );
  }
  @Input() toggleAllEnabled = true;
  @Input() disabled = false;
  selection = new SelectionModel<T>(true, []);
  _dataSource: MatTableDataSource<T>;
  _displayedColumns: string[];
  _control: FormControl<string[]>;
  selectColName = 'select';
  errorsColName = 'errors';
  private controlValuesInitialized = false;
  private subscription = new Subscription();

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dataSource?.currentValue) {
      const initialValue = this._control.value;
      const items = this._dataSource?.data.filter((item) => initialValue.includes(item.id));
      if (items.length) {
        this.selection.select(...items);
      } else {
        this.selection.clear();
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this._dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this._dataSource.data);
  }
  trackBy = (index: number) => index;

  toggleSelection(row: T) {
    if (!this.disabled) {
      this.selection.toggle(row);
    }
  }
}
