import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-crew-management-template',
  templateUrl: './crew-management-template.component.html',
  styleUrls: ['./crew-management-template.component.scss'],
})
export class CrewManagementTemplateComponent {
  @Input() columnName: string;
  @Input() element: any;

  trackBy = (index: number) => index;
}
