import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appendDomain',
  standalone: true,
})
export class AppendDomainPipe implements PipeTransform {
  private readonly domain = 'https://dev.ccomx.app/';

  transform(link?: string): string {
    if (
      this.isLocalhost() &&
      link &&
      link.indexOf('http') === -1 &&
      !link.startsWith('data:image')
    ) {
      return this.prependDomain(link);
    }
    return link || '';
  }

  private isLocalhost(): boolean {
    return window.location.hostname === 'localhost';
  }

  private prependDomain(link: string): string {
    return `${this.domain}${link}`;
  }
}
