import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subscription, map } from 'rxjs';
import { getDepartmentId } from 'src/app/services/store-snapshot.service';
import { IdName } from 'src/app/shared/models/id-name';
import { IDepartmentInfo } from 'src/app/shared/user-view/models/tenant-user-access.model';
import { ChangeCurrentSelectedDepartment } from 'src/app/tenant-selector/state/tenant-user-access.actions';
import {
  TenantUserAccessState,
  ALL_DEPS_TOKEN,
} from 'src/app/tenant-selector/state/tenant-user-access.state';
import { GlobalSearchForm } from '../../forms/global-search-form';

@Component({
  selector: 'app-department-selector-mobile',
  templateUrl: './department-selector-mobile.component.html',
  styleUrls: ['./department-selector-mobile.component.scss'],
})
export class DepartmentSelectorMobileComponent implements OnInit, OnDestroy {
  departments$: Observable<IDepartmentInfo[]> = this.store
    .select(TenantUserAccessState.getAllAvailableDepartments)
    .pipe(map((departments) => [...departments].sort((a, b) => a.name.localeCompare(b.name))));
  selectedDepartment$: Observable<IDepartmentInfo> = this.store.select(
    TenantUserAccessState.currentDepartment
  );

  allDepsIconPath = '/assets/icons/all-departments.svg';
  departmentPlaceHolderIconPath = '/assets/icons/department-placeholder.svg';
  searchableDepartments: IdName[];
  filteredDepartments: IDepartmentInfo[];
  allAvailableDepartments: IDepartmentInfo[];
  currentDepartmentId = getDepartmentId();
  form = new GlobalSearchForm();

  readonly allDepsToken = ALL_DEPS_TOKEN;

  private subscription = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<DepartmentSelectorMobileComponent>,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.departments$.subscribe((availableDepartments: IDepartmentInfo[]) => {
        if (availableDepartments) {
          this.filteredDepartments = availableDepartments;
          this.allAvailableDepartments = availableDepartments;
          this.searchableDepartments = [];
          this.searchableDepartments = availableDepartments.map((tenant: IDepartmentInfo) => ({
            id: tenant.id,
            name: tenant.name,
          }));
        }
      })
    );

    this.subscription.add(
      this.form.controls.searchTerm.valueChanges.subscribe((searchTerm: string) => {
        this.filteredDepartments = this.allAvailableDepartments;
        if (searchTerm) {
          this.filteredDepartments = this.filteredDepartments.filter(
            (department: IDepartmentInfo) =>
              department.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectDepartment(department: IDepartmentInfo): void {
    this.store.dispatch(new ChangeCurrentSelectedDepartment(department.id, true));
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  trackBy = (index: number) => index;
}
