/* eslint-disable max-classes-per-file */

import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IDepartment } from '../models/department.model';

export class LoadDepartments {
  static readonly type = '[Department] Fetch Departments';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllDepartments {
  static readonly type = '[Department] Fetch All Departments';
  constructor() {}
}

export class LoadDepartmentsAccessedByEmployment {
  static readonly type = '[Department] Fetch All Departments Accessed By Employment';
  constructor() {}
}

export class LoadAllDepartmentsForSelectedTenant {
  static readonly type = '[Department] Fetch All Departments For Selected Tenant';
  constructor(public tenantId: string) {}
}

export class LoadAllDepartmentsForMenuId {
  static readonly type = '[Department] Fetch All Departments For Menu Id';
  constructor(public menuId: string) {}
}

export class LoadDepartmentsForUser {
  static readonly type = '[Department] Fetch Departments For User';
  constructor() {}
}

export class LoadUsersForSelectedDepartment {
  static readonly type = '[Department] Fetch Users for currently selected Department';
  constructor() {}
}

export class LoadDepartmentById {
  static readonly type = '[Department] Load Department By Id';
  constructor(public id: string) {}
}

export class LoadDepartmentOptions {
  static readonly type = '[Department] load Department Options';
}

export class LoadDepartmentList {
  static readonly type = '[DepartmentList] load DepartmentList';
  constructor() {}
}

export class CreateDepartment {
  static readonly type = '[Department] Create Department';
  constructor(public department: IDepartment) {}
}

export class UpdateDepartment {
  static readonly type = '[Department] update Department';
  constructor(public department: IDepartment) {}
}

export class DeleteDepartment {
  static readonly type = '[Department] Delete Department';
  constructor(public departmentId: string) {}
}
