<ng-container *ngFor="let navItem of navItems; trackBy: trackBy">
  <ng-container *ngIf="navItem.children?.length === 0; else hasChildrenPages">
    <mat-list-item
      [routerLink]="navItem.route"
      appAccordionLink
      routerLinkActive="active-link"
      (click)="toggleItem(navItem.name)"
    >
      <span>
        <mat-icon class="icon-color">{{ navItem.icon }}</mat-icon>
        <h4 class="side-nav-item">{{ navItem.name | translate | titlecase }}</h4>
      </span>
      <span>
        <app-ui-pill
          *ngIf="navItem.name === 'dueList'"
          [number]="dueListWarningsExpiredSum"
          [expiredDueListWarnings]="true"
          class="due-list-pill"
        ></app-ui-pill
        ><app-ui-pill
          *ngIf="navItem.name === 'dueList'"
          [number]="dueListWarningsExpiringSum"
          [expiredDueListWarnings]="false"
          class="due-list-pill yellow-line"
        ></app-ui-pill>
      </span>
    </mat-list-item>
  </ng-container>
  <ng-template #hasChildrenPages>
    <ng-container *ngIf="isInAdmin; else standardUserRouting">
      <!-- TODO:  *ngIf="isVisible(navItem.module)" this is always true-->
      <mat-list-item [routerLink]="navItem.route" [class.active-link]="navItem === activeNavItem">
        <mat-icon class="icon-color">{{ navItem.icon }}</mat-icon>
        <h4 class="side-nav-item">{{ navItem.name | translate | titlecase }}</h4>
        <mat-icon class="dropdown-arrow">chevron_right</mat-icon>
      </mat-list-item>
      <!-- <div [@toggleContainer]="expandedItems | contains: navItem.name">
        <app-menu-list-recursive
          [navItems]="navItem.children"
          [currentTenantId]="currentTenantId"
          [currentMenuState]="currentMenuState"
          [dueListSummaryNumber]="dueListSummaryNumber"
        ></app-menu-list-recursive>
      </div> -->
    </ng-container>
    <ng-template #standardUserRouting>
      <!-- TODO:  *ngIf="isVisible(navItem.module)" this is always true-->
      <mat-list-item (click)="toggleItem(navItem.name)" routerLinkActive="active-link">
        <mat-icon class="icon-color">{{ navItem.icon }}</mat-icon>
        <h4 class="side-nav-item">{{ navItem.name | translate | titlecase }}</h4>
        <mat-icon class="dropdown-arrow">chevron_right</mat-icon>
      </mat-list-item>
      <!-- <div [@toggleContainer]="expandedItems | contains : navItem.name">
        <app-menu-list-recursive
          [navItems]="navItem.children"
          [currentTenantId]="currentTenantId"
          [currentMenuState]="currentMenuState"
          [dueListSummaryNumber]="dueListSummaryNumber"
        ></app-menu-list-recursive>
      </div> -->
    </ng-template>
  </ng-template>
</ng-container>
