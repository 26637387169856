<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'arrivalDateTime'">
    {{ element.from | date: dateTimeFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'departureDate'">
    {{ element.to | date: dateFormat }}
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
