import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractDepartmentTemplateComponent } from '../departments-template/abstract-department-template-component.directive';
import { ContactForm } from '../../../../../tenant/forms/contact-form';

@Component({
  selector: 'app-contacts-template',
  templateUrl: './contacts-template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsTemplateComponent extends AbstractDepartmentTemplateComponent<ContactForm> {}
