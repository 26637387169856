import { Injectable } from '@angular/core';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import { patch, updateItem, removeItem, append } from '@ngxs/store/operators';
import { tap } from 'rxjs/operators';
import { IAccess, IAccessContext, IRole } from '../models/role.model';
import { RoleService } from '../service/role.service';
import {
  LoadRoles,
  LoadAccessContext,
  LoadAccesses,
  CreateRole,
  DeleteRole,
  UpdateRole,
} from './role.action';

export interface RoleStateModel {
  rolesLoaded: boolean;
  accessLoaded: boolean;
  accessesContextsLoaded: boolean;
  roles: IRole[];
  accessContexts: IAccessContext[];
  access: IAccess[];
}

const ROLE_STATE_TOKEN = new StateToken<RoleStateModel>('role');

@State<RoleStateModel>({
  name: ROLE_STATE_TOKEN,
  defaults: {
    rolesLoaded: false,
    accessLoaded: false,
    accessesContextsLoaded: false,
    roles: [],
    accessContexts: [],
    access: [],
  },
})
@Injectable()
export class RoleState {
  constructor(private service: RoleService) {}

  @Action(LoadRoles, { cancelUncompleted: true })
  loadRoles({ getState, setState }: StateContext<RoleStateModel>) {
    return this.service.getAllRoles().pipe(
      tap((roles: IRole[]) => {
        const state = getState();
        setState({
          ...state,
          rolesLoaded: true,
          roles,
        });
      })
    );
  }

  @Action(LoadAccessContext, { cancelUncompleted: true })
  loadAccessContext({ getState, setState }: StateContext<RoleStateModel>) {
    return this.service.getAccessContexts().pipe(
      tap((accessContexts: IAccessContext[]) => {
        const state = getState();
        setState({
          ...state,
          accessContexts,
        });
      })
    );
  }

  @Action(LoadAccesses, { cancelUncompleted: true })
  loadAccesses({ getState, setState }: StateContext<RoleStateModel>) {
    return this.service.getAccesses().pipe(
      tap((access: IAccess[]) => {
        const state = getState();
        setState({
          ...state,
          accessLoaded: true,
          access,
        });
      })
    );
  }

  @Action(CreateRole, { cancelUncompleted: true })
  createRole(ctx: StateContext<RoleStateModel>, { role }: CreateRole) {
    return this.service.createRole(role).pipe(
      tap((response: IRole) => {
        ctx.setState(
          patch({
            roles: append<IRole>([response]),
          })
        );
      })
    );
  }

  @Action(UpdateRole, { cancelUncompleted: true })
  updateRole(ctx: StateContext<RoleStateModel>, { role }: UpdateRole) {
    return this.service.updateRole(role).pipe(
      tap((response: IRole) => {
        ctx.setState(
          patch({
            roles: updateItem<IRole>(
              (roleToUpdate: IRole | undefined) => roleToUpdate?.id === response.id,
              response
            ),
          })
        );
      })
    );
  }

  @Action(DeleteRole, { cancelUncompleted: true })
  deleteRole(ctx: StateContext<RoleStateModel>, { id }: DeleteRole) {
    return this.service.deleteRole(id).pipe(
      tap(() => {
        ctx.setState(
          patch({
            roles: removeItem<IRole>((roleToDelete: IRole | undefined) => roleToDelete?.id === id),
          })
        );
      })
    );
  }

  @Selector()
  static rolesLoaded(state: RoleStateModel) {
    return state.rolesLoaded;
  }

  @Selector()
  static accessLoaded(state: RoleStateModel) {
    return state.accessLoaded;
  }

  @Selector()
  static getAllRoles(state: RoleStateModel) {
    return state.roles;
  }

  @Selector()
  static getAllAccess(state: RoleStateModel) {
    return state.access;
  }

  @Selector()
  static getAccessContexts(state: RoleStateModel) {
    return state.accessContexts;
  }

  @Selector()
  static accessContextLoaded(state: RoleStateModel) {
    return state.accessesContextsLoaded;
  }
}
