<ng-container [ngSwitch]="columnName">
  <ng-container *ngSwitchCase="'plannedStart'">
    {{ element.from | date : dateTimeFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'plannedEnd'">
    <div class="text-icon-container">
      {{ element.to | date : dateTimeFormat }}
      <ng-container *ngIf="element.signOffOverdue">
        <mat-icon class="warn" [matTooltip]="'signOffOverdueText' | translate">warning</mat-icon>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'shift'">
    {{ element.shift ?? 'all' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'from'">
    {{ element.from | date : dateFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'to'">
    {{ element.to | date : dateFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'position'">
    {{ element.position?.name }}
  </ng-container>
  <ng-container *ngSwitchCase="'period'">
    {{ element | dateDiff }} {{ 'days' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'status'">
    {{ element.status | tripUserStatus | translate }}
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ element[columnName] }}
  </ng-container>
</ng-container>
