<div class="inner-menu-container">
  <mat-list-item
    *ngFor="let navItem of adminInnerItems"
    [routerLink]="navItem.route"
    appAccordionLink
    routerLinkActive="active-link"
  >
    <h4 class="side-nav-item">{{ navItem.name | translate | titlecase }}</h4>
  </mat-list-item>
</div>
