import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IdName } from '../../shared/models/id-name';
import { PriviligeApiService } from '../services/privilige-api.service';
import { LoadAllGroupedPrivileges, LoadAllPrivileges } from './privileges.actions';
import { IPrivilegeModel } from '../models/privilege.model';
import { IPrivilegeGroupModel, IPrivilegeGroupResultModel } from '../models/privilege-group.model';

export interface PriviligeStateModel {
  loaded: boolean;
  privileges: IdName[];
  privilegesGrouped: IPrivilegeGroupModel[];
  privilegesForModule: IPrivilegeModel[];
}

const PRIVILIGE_STATE_TOKEN = new StateToken<PriviligeStateModel>('privileges');

@State<PriviligeStateModel>({
  name: PRIVILIGE_STATE_TOKEN,
  defaults: {
    loaded: false,
    privileges: [],
    privilegesGrouped: [],
    privilegesForModule: [],
  },
})
@Injectable()
export class PriviligeState {
  constructor(private service: PriviligeApiService) {}

  // @Action(LoadAllPrivileges, { cancelUncompleted: true })
  // loadAllPrivileges({ getState, setState }: StateContext<PriviligeStateModel>) {
  //   return this.service.getAllPrivileges().pipe(
  //     tap((privileges: { name: string }[]) => {
  //       const state = getState();
  //       const privilegesMapped = privileges.map((privilege: { name: string }) => {
  //         return { id: privilege.name, name: privilege.name };
  //       });
  //       setState({
  //         ...state,
  //         privileges: privilegesMapped,
  //       });
  //     })
  //   );
  // }

  @Action(LoadAllPrivileges, { cancelUncompleted: true })
  loadAllPrivileges(
    { getState, setState }: StateContext<PriviligeStateModel>,
    { departmentIds, departmentGroupIds }: LoadAllPrivileges
  ) {
    return this.service.getPrivileges(departmentIds, departmentGroupIds).pipe(
      tap((privileges: IPrivilegeModel[]) => {
        const state = getState();
        const privilegesMapped = privileges.map((privilege: IPrivilegeModel) => {
          return { id: privilege.name, name: privilege.name };
        });
        setState({
          ...state,
          privileges: privilegesMapped,
        });
      })
    );
  }

  @Action(LoadAllGroupedPrivileges, { cancelUncompleted: true })
  loadAllGroupedPrivileges({ getState, setState }: StateContext<PriviligeStateModel>) {
    return this.service.getAllGroupedPrivileges().pipe(
      tap((data: IPrivilegeGroupResultModel) => {
        const state = getState();
        setState({
          ...state,
          privilegesGrouped: data.results,
        });
      })
    );
  }

  // @Action(LoadPrivilegesForModule, { cancelUncompleted: true })
  // loadPrivilegesForModule(
  //   { getState, setState }: StateContext<PriviligeStateModel>,
  //   { module, departmentIds, departmentGroupIds }: LoadPrivilegesForModule
  // ) {
  //   return this.service.getPrivilegesForModule(module, departmentIds, departmentGroupIds).pipe(
  //     tap((privileges: string[]) => {
  //       const state = getState();
  //       const privilegesMapped = privileges.map((id: string) => {
  //         return { id, name: id };
  //       });
  //       setState({
  //         ...state,
  //         privileges: privilegesMapped,
  //       });
  //     })
  //   );
  // }
  // TODO later on check if we need it

  static privilegesLoaded(state: PriviligeStateModel) {
    return state.loaded;
  }

  @Selector()
  static getAllPrivileges(state: PriviligeStateModel) {
    return state.privileges;
  }

  @Selector()
  static getAllPrivilegesGrouoed(state: PriviligeStateModel) {
    return state.privilegesGrouped;
  }

  @Selector()
  static getPrivilegesForModule(state: PriviligeStateModel) {
    return state.privilegesForModule;
  }
}
