/* eslint-disable max-classes-per-file */
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';
import { IAccountGroupRequest } from '../models/account-group.model';

export class LoadAccountGroups {
  static readonly type = '[Account Group] Load account groups via Service';
  constructor(public filter?: MainTableFilterModel) {}
}

export class LoadAllAccountGroups {
  static readonly type = '[Account Group] Load All account groups via Service';
  constructor() {}
}

export class LoadAccountGroupById {
  static readonly type = '[Account Group] Load a single account group';
  constructor(public id: string) {}
}

export class CreateAccountGroup {
  static readonly type = '[Account Group] Create account group';
  constructor(public accountGroup: IAccountGroupRequest) {}
}

export class UpdateAccountGroup {
  static readonly type = '[Account Group] Update account group';
  constructor(public accountGroup: IAccountGroupRequest) {}
}

export class DeleteAccountGroup {
  static readonly type = '[Account Group] Delete account group';
  constructor(public id: string) {}
}
