/* eslint-disable max-classes-per-file */
import { IdName } from 'src/app/shared/models/id-name';
import { MainTableFilterModel } from 'src/app/shared/models/main-table-filter.model';

export class LoadCertificateTypes {
  static readonly type = '[Certificate type] Load Certificate Type';
  constructor(public filter?: MainTableFilterModel) {}
}

export class CreateCertificateType {
  static readonly type = '[Certificate type] Create Certificate Type';
  constructor(public certificateType: IdName) {}
}

export class UpdateCertificateType {
  static readonly type = '[Certificate type] Update Certificate Type';
  constructor(public certificateType: IdName) {}
}

export class DeleteCertificateType {
  static readonly type = '[Certificate type] Delete Certificate Type';
  constructor(public id: string) {}
}

export class LoadCertificateTypeById {
  static readonly type = '[Certificate type] Load a single Certificate Type';
  constructor(public id: string) {}
}

export class LoadAllCertificateTypes {
  static readonly type = '[Certificate type] Load All Certificate Type';
  constructor() {}
}
