import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IdName } from '../../models/id-name';

@Injectable({
  providedIn: 'root',
})
export class AttachmentApiService {
  private headers = { 'content-type': 'application/json' };

  constructor(private http: HttpClient) {}

  downloadFileAsBlob(link: string, reportProgress = true): Observable<any> {
    const req = new HttpRequest('GET', link, {
      responseType: 'blob',
      reportProgress,
    });
    return this.http.request<any>(req);
  }

  getAttachmentCategories(): Observable<IdName[]> {
    const url = `${environment.apiUrl}/api/Options/AttachmentCategories`;
    return this.http.get<IdName[]>(url, { headers: this.headers });
  }

  getAttachmentUnallowedFileTypes(): Observable<string[]> {
    const url = `${environment.apiUrl}/api/Options/AttachmentUnallowedFileTypes`;
    return this.http.get<string[]>(url, { headers: this.headers });
  }
}
