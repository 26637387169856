<div class="tenant-selector-main-container" *ngIf="desktopMode; else mobileMode">
  <div class="tenant-search-container">
    <app-ui-autocomplete
      [placeholder]="'search'"
      [options]="searchableTenants"
      [searchTerm]="form.searchTerm"
      [floatLabel]="'always'"
      [showList]="false"
    ></app-ui-autocomplete>
  </div>
  <div class="tenant-list-container">
    <div
      class="tenant-single-container"
      *ngFor="let tenant of filteredTenants"
      (click)="selectionChanged(tenant)"
      [ngClass]="tenant.id === currentTenantId ? 'selected-tenant' : ''"
    >
      <span class="tenant-image">
        <ng-container *ngIf="tenant.logo; else blankLogo">
          <img [src]="tenant.logo | appendDomain" alt="{{ 'tenantLogo' | translate }}" />
        </ng-container>
        <ng-template #blankLogo>
          <div class="blank-logo-div"></div>
        </ng-template>
      </span>
      <span class="tenant-main-info">
        <h3>{{ tenant.name }}</h3>
        <h4>{{ tenant.accountType | translate }}</h4>
      </span>
      <span class="tenant-department-count">
        {{ tenant.departmentInfos?.length }} {{ 'dep.' | translate }}
      </span>
      <span class="tenant-status">
        <app-ui-pill [name]="tenant.status" [module]="mainModulesEnum.Tenant"></app-ui-pill>
      </span>
      <span class="tenant-select-button">
        <app-ui-button buttonType="icon-button" icon="chevron_right"></app-ui-button>
      </span>
    </div>
  </div>
</div>

<ng-template #mobileMode>
  <div class="tenant-selector-main-container-mobile">
    <div class="mobile-dialog-heading">
      <span class="mobile-heading-title">
        {{ 'Tenant' | translate }}
      </span>
      <mat-icon (click)="closeDialog()">close</mat-icon>
    </div>
    <div class="dialog-search-container-mobile">
      <app-ui-autocomplete
        [placeholder]="'search'"
        [options]="searchableTenants"
        [searchTerm]="form.searchTerm"
        [floatLabel]="'always'"
        [showList]="false"
      ></app-ui-autocomplete>
    </div>
    <div class="tenant-list-container">
      <div
        class="tenant-single-container"
        *ngFor="let tenant of filteredTenants"
        (click)="selectionChanged(tenant)"
        [ngClass]="tenant.id === currentTenantId ? 'selected-tenant' : ''"
      >
        <span class="tenant-image">
          <ng-container *ngIf="tenant.logo; else blankLogo">
            <img [src]="tenant.logo | appendDomain" alt="{{ 'tenantLogo' | translate }}" />
          </ng-container>
          <ng-template #blankLogo>
            <div class="blank-logo-div"></div>
          </ng-template>
        </span>
        <span class="tenant-main-info">
          <span>
            <h3>{{ tenant.name }}</h3>
            <app-ui-pill [name]="tenant.status" [module]="mainModulesEnum.Tenant"></app-ui-pill>
          </span>
          <span>
            <h4>{{ tenant.accountType | translate }}</h4>
          </span>
        </span>
      </div>
    </div>
  </div>
</ng-template>
